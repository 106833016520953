import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp, AgRendererComponent } from 'ag-grid-angular';
import { commonhelper } from '../common/commonhelper';
import { ICellRendererComp } from 'ag-grid-community';

@Component({
  selector: 'app-task-comment-content-cellrenderer',
  template: ``,
  styleUrls: ['./task-comment-content-cellrenderer.component.css']
})
export class TaskCommentContentCellrendererComponent extends commonhelper implements ICellRendererComp     {
  public eGui : any;
  public template: string='';

  getGui(): HTMLElement {
    return this.eGui;
  }
  destroy?(): void {
    throw new Error("Method not implemented.");
  }
  afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {
    throw new Error("Method not implemented.");
  }
  init?(params: import("ag-grid-community").ICellRendererParams): void | import("ag-grid-community").Promise<void> {
    // create the cell
    // console.log('init');
    // console.log(params);
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = this.template;
  }


  refresh(params: any): boolean {
    return false;
    //throw new Error("Method not implemented.");
  }


  agInit(params: any): void {
    // console.log('TaskCommentContentCellrendererComponent');
    // console.log(params);
    // console.log(params.data.messageinfo);
    this.template = params.data.messageinfo;
    // console.log(this.template);
  }


}
