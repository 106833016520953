
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { commonHelperService } from '../common/common.service';


@Injectable()
export class NewCustomerMailsSelectTemplatePopupService extends commonHelperService {
    constructor(http: HttpClient) {
        super(http);
    }
    GetNewCustomerMailTemplatesList(postData: any) {
        return this.PostData(Config.OASCustomerMailTemplateAppnURL + 'GetNewCustomerMailTemplatesList', postData)
    }

    DeleteNewCustomerMailTemplate(postData: any) {
        return this.PostData(Config.OASCustomerMailTemplateAppnURL + 'DeleteNewCustomerMailTemplate', postData)
    }


}