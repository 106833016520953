import { Component, OnInit } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-task-information-component-main',
  templateUrl: './view-task-information-component-main.component.html',
  styleUrls: ['./view-task-information-component-main.component.css']
})
export class ViewTaskInformationComponentMainComponent extends commonhelper implements OnInit {

  public ShowGridContent = false;
  public selected = 1;
  constructor(private _Router: Router) {
    super();
  }
  public items: any = [{
    // disabled: true,
    tasktype: 'All',
    temp: 17,
    weather: 'rainy'
  }, {
    disabled: false,
    tasktype: 'Yet to Start',
    temp: 29,
    weather: 'sunny'
  }, {
    disabled: false,
    tasktype: 'On Going',
    temp: 23,
    weather: 'cloudy'
  }, {
    disabled: false,
    tasktype: 'Assisting',
    temp: 19,
    weather: 'cloudy'
  }, {
    disabled: false,
    tasktype: 'Assisting',
    temp: 19,
    weather: 'cloudy'
  }, {
    disabled: false,
    tasktype: 'Assisting',
    temp: 19,
    weather: 'cloudy'
  }
  ];
  ngOnInit() {
  }

}
