

import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogCloseResult,DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';
import { commonHelperService } from '../common/common.service';
import { commonhelper } from '../common/commonhelper';
import { Config } from '../config';
import { PraticesListComponent } from '../pratices-list/pratices-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridSizeChangedEvent } from 'ag-grid-community';

@Component({
  selector: 'app-by-implementer',
  templateUrl: './by-implementer.component.html',
  styleUrls: ['./by-implementer.component.css']
})
export class ByImplementerComponent extends commonhelper  implements OnInit {
  @ViewChild('byImplementerGrid') byImplementerGrid: AgGridNg2;
  @ViewChild("ByImplementer", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  private commonhelperobj: commonHelperService;
  public implementedbyModel:any={};
  CreatedByPersonInfo:any={};
  FromRecords:any;
  PrevLabelCount:any;
  NextLabelCount:any;
  RecordsPerPage:any;
  TotalCountValue:boolean=true;
  TotalCountValueIcon:boolean=false;
  TotalLabelCount:any;
  TotalLabelCountValue:any;
  fromrecordsfromcount:any;
  prevnextbuttons:boolean=true;
  NextButtonDisabled:boolean=false;
  PreviousButtondisabled:boolean=false;
  public practiceInformation:any={};
  public responsiblePersonInfo:any={};
  public rowData=[];
  public SearchStatusIDs:any=[];
  public byImplementerSearchTaskNumber:any;
  public byImplementerSearchTaskSubject:any;
  public byImplementerSelectStatusDropDown: any = [];
  selectedTask:any;
  columnDefs = [
    { headerName: 'Task #', field: 'TaskNumber', width: 90, tooltipField: 'TaskNumber', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Practice Name', field: 'PracticeName', width: 150, tooltipField: 'PracticeName', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Task Type', field: 'TaskType', width: 110, tooltipField: 'TaskType', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Task Subject', field: 'IssueSubject', width: 260, tooltipField: 'IssueSubject', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Task Status', field: 'TaskLatestStatusName', width: 100, tooltipField: 'TaskLatestStatusName', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Priority', field: 'SeverityDesc', width: 80, tooltipField: 'SeverityDesc', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Task Current Working Status', field: 'TaskCurrentWorkingStatus', width: 200, tooltipField: 'TaskCurrentWorkingStatus', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Responsible Person', field: 'ResponsiblePerson', width: 150, tooltipField: 'ResponsiblePerson', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Implementer', field: 'CreatedBy', width: 150, tooltipField: 'CreatedBy', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },

  ];
  onByImplementerGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  constructor(private http: HttpClient,httpClient: HttpClient,private dialogService: DialogService,private route: ActivatedRoute,private _router: Router ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }
  

  ngOnInit() {
    this.route.params.subscribe(params => {
      const data = this.getCommonData(params.info);
    if (this.hasValue(data) && this.hasValue(data.previouslySelectedSearchFilters)) {
      this.byImplementerSearchTaskNumber = data.previouslySelectedSearchFilters.byImplementerSearchTaskNumber;
       this.byImplementerSearchTaskSubject =data.previouslySelectedSearchFilters.byImplementerSearchTaskSubject;
      this.responsiblePersonInfo = data.previouslySelectedSearchFilters.responsiblePersonInfo;
      this.practiceInformation = data.previouslySelectedSearchFilters.practiceInformation;
      this.FromRecords = data.previouslySelectedSearchFilters.StatemaintainFromRecords;
      this.implementedbyModel = data.previouslySelectedSearchFilters.implementedbyModel;
      this.SearchStatusIDs = data.previouslySelectedSearchFilters.SearchStatusIDs;
      this.selectedTask=data.previouslySelectedTaskNumber;
    }
  });
    this.byImplementerGetlist(undefined,undefined)
    this.byImplementGetStatusList()
    setTimeout(function () {
      document.getElementById("txtByImplementerSearchTask").focus();
      },20);
      for (let i = 0; i < this.columnDefs.length; i++) {
        switch (this.columnDefs[i].field) {
          case 'IssueSubject':
            if ($(window).width() > 1700) {
              this.columnDefs[3].width = 615;
              this.columnDefs[6].width = 225;
            } else if ($(window).width() < 1700 && $(window).width() >= 1670) {
              this.columnDefs[3].width = 323;
              this.columnDefs[6].width = 212;
              this.columnDefs[7].width = 196;
            } else if ($(window).width() < 1670 && $(window).width() >= 1550) {
              this.columnDefs[3].width = 325;
              this.columnDefs[6].width = 213;
            } else if ($(window).width() < 1550 && $(window).width() >= 1480) {
              this.columnDefs[3].width = 281;
              this.columnDefs[6].width = 180;
            } else if ($(window).width() < 1480 && $(window).width() >= 1400) {
              this.columnDefs[1].width = 133;
              this.columnDefs[3].width = 224;
            } else if ($(window).width() < 1400 && $(window).width() >= 1300) {
              this.columnDefs[1].width = 132;
              this.columnDefs[3].width = 192;
              this.columnDefs[6].width = 164;
            } else if ($(window).width() < 1300 && $(window).width() >= 1200) {
              this.columnDefs[1].width = 140;
              this.columnDefs[2].width = 146;
              this.columnDefs[3].width = 160;
              this.columnDefs[6].width = 118;
              this.columnDefs[7].width = 114;
            }
            break;
        }
      }
      setTimeout(() => {
        this.statemaintainPreviousSelectedRecord();
      }, 100);
  }
  byImplementGetStatusList(){
      this.commonhelperobj.PostData(Config.OASAppnURL + 'TaskStatusList', {}).subscribe(response => {
    if (this.isError(response)) { return; }

    this.byImplementerSelectStatusDropDown = [];

    response.forEach(element => {
      if (element.TaskStatusId != 4) {
        this.byImplementerSelectStatusDropDown.push({ TaskDescription: element.TaskDescription, TaskStatusId: element.TaskStatusId });
      }
    });


  });
}
  byImplementerSelectImplementerIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Implementer',
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.CreatedByPersonInfo = result;
        if (this.hasValue(this.CreatedByPersonInfo)) {
          this.implementedbyModel.EmployeeId = this.CreatedByPersonInfo.EmployeeId;
          this.implementedbyModel.EmployeeFullName = this.CreatedByPersonInfo.EmployeeFullName;
  
        }
      }
    });
  }
  byImplementerRemoveSelectedImplementerIconClickEvent(){
    this.implementedbyModel={};
  }
  viewTaskInfoPrevNextClick(prevornext){
    
    this.byImplementerGetlist(undefined,prevornext)
}
byImplementerGetlist(isfromsearch,prevornext){
  this.RecordsPerPage = 50;
  if(isfromsearch==true && (prevornext==false||prevornext==undefined) && this.TotalCountValue==false ){
    this.TotalCountValueIcon=false;
    this.TotalCountValue=true;
  }
  if(!this.hasValue(this.FromRecords) || (this.hasValue(isfromsearch) && isfromsearch==true)){
    this.FromRecords=1;
  }
  switch(prevornext){
    case 1:
       //if(this.hasValue(this.PreviousButtondisabled) && this.PreviousButtondisabled==true) return;
      this.FromRecords -=this.RecordsPerPage
      break;
  
  case 2:
     //if(this.hasValue(this.NextButtonDisabled) && this.NextButtonDisabled==true) return;
    this.FromRecords += this.RecordsPerPage
    break;
    case 3:
      this.fromrecordsfromcount = this.FromRecords;
      this.FromRecords=null;
  }
  let statusids=this.SearchStatusIDs.map(item => item.TaskStatusId).join(',') 
  const data = {
    CreatedByUserID:  this.hasValue(this.implementedbyModel.EmployeeId)?this.implementedbyModel.EmployeeId :undefined,
    FromRecords: this.hasValue(this.FromRecords)?this.FromRecords:undefined,
    TaskNumber:this.byImplementerSearchTaskNumber,
    TaskSubject:this.byImplementerSearchTaskSubject,
    TaskLatestStatusList :this.hasValue(statusids)?statusids:undefined,
    ResponsiblePersonUserId:  this.hasValue(this.responsiblePersonInfo.EmployeeId)?this.responsiblePersonInfo.EmployeeId :undefined,
    PracticeId:  this.hasValue(this.practiceInformation.PracticeId)?this.practiceInformation.PracticeId.toString() :undefined,
  }
  this.commonhelperobj.PostData(Config.OASReportsofTasksAppnURL + 'TaskByByImplementer', data)
.subscribe(response => {
  if (this.isError(response)) {
    return;
  }
  
    if(this.hasValue(response) && this.hasValue(response.Count) && response.Count>0){
      this.TotalCountValueIcon = true;
      this.TotalCountValue=false;
      this.TotalLabelCount = response.Count; 
      this.FromRecords=this.fromrecordsfromcount;
      return;
    }
    response.taskByImplementerOutputModels.forEach(TaskListelement => {
      if (this.hasValue(TaskListelement.TaskUsersInfo)) {
        const SplittedUserData = TaskListelement.TaskUsersInfo.split(',');
        if (this.hasValue(SplittedUserData) && SplittedUserData.length > 0) {
          SplittedUserData.forEach(element => {
            const getUserRolesData = element.split('|');
            if (this.hasValue(getUserRolesData) && getUserRolesData.length > 0) {
              if (getUserRolesData[2] === '2') {
                TaskListelement.ResponsiblePerson = getUserRolesData[1];
              }
            }
          });
        }
      }
    });
    this.rowData=response.taskByImplementerOutputModels;
    this.PrevLabelCount = this.FromRecords
      if(this.FromRecords==1){
        this.NextLabelCount = response.taskByImplementerOutputModels.length
      }
      else{
      this.NextLabelCount = this.FromRecords+response.taskByImplementerOutputModels.length-1;
      }
      if(this.FromRecords==1 && response.taskByImplementerOutputModels.length<this.RecordsPerPage){
        this.prevnextbuttons=true;
        this.TotalLabelCountValue=response.taskByImplementerOutputModels.length
      }
      else if(this.FromRecords==1 && response.taskByImplementerOutputModels.length==this.RecordsPerPage){
        this.prevnextbuttons=false;
        this.PreviousButtondisabled=true;
        this.NextButtonDisabled=false;
      }
      else if(response.taskByImplementerOutputModels.length<this.RecordsPerPage){
        this.prevnextbuttons=false;
        this.NextButtonDisabled=true;
        this.PreviousButtondisabled=false;
      }
      else{
  
        this.prevnextbuttons=false;
        this.PreviousButtondisabled=false;
        this.NextButtonDisabled=false;
      }
  });
  
}
byImplementerSelectPracticeIconClickEvent() {
  const dialogRef = this.dialogService.open({
      title: 'Select Practice',
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
  });
  dialogRef.result.subscribe((result) => {
    if (result instanceof DialogCloseResult) {
    } else {
        this.practiceInformation = result;
    }
});
}
byImplementerSelectResponsiblePersonIconClickEvent() {
  const dialogRef = this.dialogService.open({
    title: 'Select User(s)',
    // Show component
    content: ResponsiblepersonUsersListComponent,
    height: 698,
    width: 550,
    appendTo: this.ContainerRef
  });
  dialogRef.result.subscribe((result) => {
    if (result instanceof DialogCloseResult) {
    } else {
      this.responsiblePersonInfo = result;
    }
  });
}
byImplementerRemoveSelectedResponsiblePersonClickEvent(){
  this.responsiblePersonInfo ={};
}
byImplementerRemoveSelectedPracticeClickEvent(){
  this.practiceInformation ={};
}
byImplementerViewClickEvent() {
  let selectedTask=this.getSelectedTaskInfo()
    if (!this.hasValue(selectedTask)) return;
    const PostDataService: any = {
    IsFromEdit: true,
      editmodedata: selectedTask,
      isfrom:3//byimplement
    };
    PostDataService.StatemaintainPreviouslySearchFilters = {
      byImplementerSearchTaskNumber: this.byImplementerSearchTaskNumber,
      byImplementerSearchTaskSubject: this.byImplementerSearchTaskSubject,
      responsiblePersonInfo: this.responsiblePersonInfo,
      practiceInformation: this.practiceInformation,
      StatemaintainFromRecords :this.FromRecords,
      implementedbyModel:this.implementedbyModel,
      SearchStatusIDs:this.SearchStatusIDs,

      
    };
    const outletObj = {};
    outletObj['main'] = ['app-view-full-task-information', this.setCommonData(PostDataService)];
    this._router.navigate(['/home', { outlets: outletObj }]);
  }
  getSelectedTaskInfo(){
    const selectedrow= this.byImplementerGrid.api.getSelectedRows();
    if(!this.hasValue(selectedrow)){
      this.ShowErrorMessage("Please Select Task.");
      document.getElementById("myAppointmentsSelectUser").focus();
      return 0;
    }
    return  selectedrow[0];
  }
    
statemaintainPreviousSelectedRecord(){
  let seletedtasknumber=this.selectedTask
  let storeGridInfo=this.byImplementerGrid.api
  setTimeout(() => {
    this.byImplementerGrid.api.forEachLeafNode(function (node) {
      if (node.data.TaskNumber == seletedtasknumber) {
          node.setSelected(true);
          //Moving Scroll bar to Selected Node
          storeGridInfo.ensureIndexVisible(node.rowIndex+1);
      }
  });
  }, 500);
     
}
}
