import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { _ } from 'ag-grid-community';
import { AdditionalParticipantAndObserversPopupComponentComponent } from '../additional-participant-and-observers-popup-component/additional-participant-and-observers-popup-component.component';
import { commonhelper } from '../common/commonhelper';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';
import { SelectModulePopupComponentComponent } from '../select-module-popup-component/select-module-popup-component.component';
import { NewCustomerMailsAddEditTemplatePopupService } from './new-customer-mails-add-edit-template-popup.service';

@Component({
  selector: 'app-new-customer-mails-add-edit-template-popup',
  templateUrl: './new-customer-mails-add-edit-template-popup.component.html',
  styleUrls: ['./new-customer-mails-add-edit-template-popup.component.css']
})
export class NewCustomerMailsAddEditTemplatePopupComponent extends commonhelper implements OnInit {
  @ViewChild('NewCustomerMailsAddEditTemplatePopup', { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  SelectedTemplatedId: number = 0;
  templateform: FormGroup;
  responsibleEmployeeList: any = [];
  additionalResponsiblePersonList: any = [];
  observersList: any = [];
  forwardedtoList: any = [];
  EmployeeRolesList: any = [];
  ModuleID: number = 0;
  List = [
    { List: "additionalResponsiblePersonList", ListName: "additionalresponsibleperson", type: 4 },
    { List: "observersList", ListName: "observers", type: 3 },
    // { List: "forwardedtoList", ListName: "forwardedto", type: 7 },
    { List: "responsibleEmployeeList", ListName: "responsibleperson", type: 2 }
  ]
  constructor(
    private readonly _addEditTemplateService: NewCustomerMailsAddEditTemplatePopupService,
    private readonly formBuilder: FormBuilder,
    private dialogService: DialogService,
    private dialog: DialogRef,
  ) { super(); }

  async ngOnInit() {
    this.initializeFormControlElements();
    this.setFocusonElement("txtNewCustomerMailsAddEditTemplatePopupSearchTemplate")
    if (this.SelectedTemplatedId > 0) {
      await this.getSelectedTemplateInfo();
      await this.getSelectedTemplateLinkedUsersInfo();
    }
  }
  private initializeFormControlElements() {
    this.templateform = this.formBuilder.group({
      templatename: ["", Validators.required],
      modulename: ["", Validators.required],
      responsibleperson: ["", Validators.required],
      additionalresponsibleperson: [""],
      observers: [""],
      // forwardedto: [""]
    });
  }
  private async getSelectedTemplateInfo() {
    const response = await this._addEditTemplateService.GetNewCustomerMailTemplate({ NewCustomerMailTemplateInfoId: this.SelectedTemplatedId }).toPromise();
    if (this.isError(response)) return;
    this.assignDatatoFieldsinEditMode(response)

  }
  private async getSelectedTemplateLinkedUsersInfo() {
    const response = await this._addEditTemplateService.GetNewCustomerMailTemplateLinkedUsersList({ NewCustomerMailTemplateInfoId: this.SelectedTemplatedId }).toPromise();
    if (this.isError(response)) return;
    this.groupEmployeesBasedonRoles(response.TemplateUserslist)
  }
  async SaveTemplateDetails() {
    if (this.validateMandatoryFields()) return;
    this.buildEmployesRolesList();
    this.templateform.value.ModuleID = this.ModuleID;
    this.templateform.value.NewCustomerMailTemplateInfoId = this.SelectedTemplatedId
    const response = await this._addEditTemplateService.SaveorUpdateNewCustomerMailTemplate(this.templateform.value).toPromise();
    if (this.isError(response)) return;
    this.dialog.close("success");
  }

  private validateMandatoryFields() {
    if (this.templateform.get('templatename').invalid) {
      this.ShowErrorMessage('Please Enter Template Name.');
      this.setFocusonElement("txtNewCustomerMailsAddEditTemplatePopupSearchTemplate");
      return true;
    }
    if (this.templateform.get('modulename').invalid) {
      this.ShowErrorMessage('Please Select Module.');
      this.setFocusonElement("txtCreateNewTaskSearchModule");
      return true;
    }
    if (this.templateform.get('responsibleperson').invalid) {
      this.ShowErrorMessage('Please Select Responsible Person.');
      this.setFocusonElement("newCustomerMailsAddEditTemplatePopupAddNewResponsiblePerson");
      return true;
    }
  }
  private setFocusonElement(id: string) {
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 100);
  }
  assignDatatoFieldsinEditMode(data) {
    this.templateform.patchValue({
      templatename: data.TemplateName,
      modulename: data.ModuleDescription
    });
    this.ModuleID = data.ModuleID
  }
  CancelClickEvent() {
    this.dialog.close();
  }
  newCustomerMailsAddEditTemplatePopupmoduleIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Module',
      // Show component
      content: SelectModulePopupComponentComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result: any) => {
      if (!(result instanceof DialogCloseResult)) {
        if (this.hasValue(result)) {
          this.templateform.patchValue({
            modulename: result.Description,
          })
          this.ModuleID = result.ModuleId
        }
      }
    });
  }
  newCustomerMailsAddEditTemplatePopupRemoveModuleInfo() {
    this.ModuleID = 0,
      this.templateform.patchValue({
        modulename: ""
      })
  }
  newCustomerMailsAddEditTemplatePopupResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Responsible Person',
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if (this.hasValue(result)) {
          this.responsibleEmployeeList = [];
          this.responsibleEmployeeList.push(result);
          this.assignEmployeeNamestoFormControl();
        }
      }
    });

  }

  newCustomerMailsAddEditTemplatePopupRemoveResponsiblePersonInfo() {
    this.responsibleEmployeeList = {};
    this.templateform.patchValue({
      responsibleperson: ""
    })
  }
  selectEmployeeRolesClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select User(s)',
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef
    });

    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData = this.additionalResponsiblePersonList;
    dialogRef.content.instance.StateMaintainObserverPersonsData = this.observersList;
    // dialogRef.content.instance.StateMaintainAssistantPersonsData = this.forwardedtoList;
    dialogRef.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if (this.hasValue(result)) {
          this.additionalResponsiblePersonList = result.SelectedAddlResponsiblePersonsInfo,
            this.observersList = result.SelectedObserversInfo,
            // this.forwardedtoList = result.SelectedAssistantPersonsInfo
            this.assignEmployeeNamestoFormControl();
        }
      }
    });
  }
  // newCustomerMailsAddEditTemplatePopupRemoveAssistantPersonInfo() {
  //   this.forwardedtoList = [];
  //   this.templateform.patchValue({
  //     forwardedto: ""
  //   })
  // }
  newCustomerMailsAddEditTemplatePopupRemoveObserversIconClickEvent() {
    this.observersList = [];
    this.templateform.patchValue({
      observers: ""
    })
  }
  newCustomerMailsAddEditTemplatePopupRemoveAdditionalResponsiblePersonInfo() {
    this.additionalResponsiblePersonList = [];
    this.templateform.patchValue({
      additionalresponsibleperson: ""
    })
  }
  private assignEmployeeNamestoFormControl() {
    this.List.forEach(element => {
      this.assignEmployeeNameswithCommaSeperated(this[element.List], element.ListName)
    });
  }
  assignEmployeeNameswithCommaSeperated(employeesList, formControlName) {
    this.templateform.patchValue({
      [formControlName]: employeesList.map((item) => { return item.EmployeeFullName }).join(", ")
    })
  }
  buildEmployesRolesList() {
    this.List.forEach(element => {
      this.insertEmployeeType(this[element.List], element.type)
    });
    this.templateform.value.EmployeeRolesList = this.EmployeeRolesList;
  }
  insertEmployeeType(employeesList: any = [], type: number) {
    let usersAndRolesModel = {};
    employeesList.forEach(element => {
      usersAndRolesModel = {
        EmployeeId: element.EmployeeId,
        EmployeeType: type,
      };
      this.EmployeeRolesList.push(usersAndRolesModel)
    });
  }

  groupEmployeesBasedonRoles(employeesList) {
    this.List.forEach(element => {
      this[element.List] = employeesList.filter(participant => participant.EmployeeType == element.type)
    });
    this.assignEmployeeNamestoFormControl();
  }
}
