import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { commonhelper } from '../common/commonhelper';
import { Config } from '../config';
import { ScheduledMeetingAddCommentsPopupComponent } from '../scheduled-meeting-add-comments-popup/scheduled-meeting-add-comments-popup.component';
import { ViewAppointmentsPopupService } from './view-appointments-popup.service';

@Component({
  selector: 'app-view-appointments-popup',
  templateUrl: './view-appointments-popup.component.html',
  styleUrls: ['./view-appointments-popup.component.css']
})
export class ViewAppointmentsPopupComponent extends commonhelper implements OnInit {
  @ViewChild('viewAppointmentsGrid') viewAppointmentsGrid: AgGridNg2;
  @ViewChild("viewAppointmentsPopup", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  SelectedAppointmentInfo: any;
  viewAppointmentsModel: any;
  CommentsDataList = [];
  columnDefs = [
    { headerName: 'Date Time', headerTooltip: 'Date Time', field: 'createddateandtime', tooltipField: 'createddateandtime', width: 150, cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Author', headerTooltip: 'Author', field: 'CreatedByName', tooltipField: 'CreatedByName', width: 150, cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Description', headerTooltip: 'Description', field: 'MeetingScheduledComments', tooltipField: 'MeetingScheduledComments', width: 360, cellClass: 'no-border cell-wrap-text', autoHeight: true },
  ];
  onViewAppointmentsGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  constructor(private dialogService: DialogService, private _viewAppointmentsPopupService: ViewAppointmentsPopupService, private dialog: DialogRef,) { super(); }

  ngOnInit() {
    this.viewAppointmentsModel = this.SelectedAppointmentInfo
    this.GetCommentsList();
  }
  // getSelectedAppointmnetInfo() {
  //   this._viewAppointmentsPopupService.GetAppointmentsList({ MeetingScheduledInfoID: this.SelectedAppointmentId }).subscribe(response => {
  //     if (this.isError(response)) return;
  //     this.viewAppointmentsModel = response;
  //   });

  // }
  GetCommentsList() {
    this._viewAppointmentsPopupService.AppointmetnCommentsList({ MeetingScheduledInfoID: this.SelectedAppointmentInfo.MeetingScheduledInfoID }).subscribe(response => {
      if (this.isError(response)) return;
      this.CommentsDataList = response.AppointmentCommentsList;
    });
  }

  viewAppointmentsPopupAddCommentsClick() {
    const dialogRef = this.dialogService.open({
      title: 'Add Comments',
      // Show component
      content: ScheduledMeetingAddCommentsPopupComponent,
      height: 230,
      width: 500,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.UpdateAppointmentsStatusComments({ Comments: result }, this.SelectedAppointmentInfo.MeetingScheduledInfoID, undefined);
        }
      }
    });
  }
  viewAppointmentsPopupEditCommentsClick() {
    let selectedCommentItem = this.getSelectedComment();
    if (!selectedCommentItem) return;
    this._viewAppointmentsPopupService.GetAppointmentCommentInfo({ CommentLinkedId: selectedCommentItem.CommentsLinkedId }).subscribe(response => {
      if (this.isError(response)) return;
      this.ViewAppointmentEditCommentsPopup(response, selectedCommentItem);
    });
  }
  ViewAppointmentEditCommentsPopup(response, selectedCommentItem) {
    const dialogRef = this.dialogService.open({
      title: 'Edit Comments',
      // Show component
      content: ScheduledMeetingAddCommentsPopupComponent,
      height: 230,
      width: 500,
      appendTo: this.ContainerRef
    });

    dialogRef.content.instance.SelectedCommentInfo = response;
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.UpdateAppointmentsStatusComments({ Comments: result }, selectedCommentItem.MeetingScheduledInfoID, selectedCommentItem.CommentsLinkedId);
        }
      }
    });
  }



  UpdateAppointmentsStatusComments(result, MeetingScheduledInfoID, CommentsLinkedId) {
    const postData = result;
    postData.MeetingScheduledInfoID = MeetingScheduledInfoID;
    postData.CommentsLinkedInfoID = CommentsLinkedId;
    let message = CommentsLinkedId > 0 ? "Updated" : "Added"
    this._viewAppointmentsPopupService.UpdateAppointmentStatusComments(postData).subscribe(response => {
      if (this.isError(response)) return;
      this.ShowSuccesseMessage("Comment " + message + " Successfully")
      this.GetCommentsList();
    });
  }
  DeleteAppointmentCommentInfo(postData) {
    this._viewAppointmentsPopupService.DeleteAppointmentCommentInfo(postData).subscribe(response => {
      if (this.isError(response)) return;
      this.ShowSuccesseMessage("Comment Deleted Successfully")
      this.GetCommentsList();
    });
  }
  viewAppointmentsPopupDeleteCommentsClick() {
    let selectedCommentItem = this.getSelectedComment();
    if (!selectedCommentItem) return;
    if (selectedCommentItem.CreatedBy != Config.OASOrgModel.LoggedUserID) {
      this.ShowErrorMessage("Your are not Allowed to Delete Comment Created by Others")
      return;
    }
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please Confirm',
      content: 'Are You Sure to Delete Comment?',
      actions: [
        { text: 'No' },
        { text: 'Yes', primary: true }
      ],
      width: 450,
      height: 250,
      minWidth: 250,
      appendTo: this.ContainerRef
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        //console.log('close');
      } else {
        if (this.hasValue(result)) {
          if (result.text === 'No') return;
          this.DeleteAppointmentCommentInfo({ MeetingScheduledInfoID: selectedCommentItem.MeetingScheduledInfoID, CommentLinkedId: selectedCommentItem.CommentsLinkedId })
        }

      }
    });
  }
  getSelectedComment() {
    const selectedrow = this.viewAppointmentsGrid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.ShowErrorMessage("Please Select Comment")
      return false;
    }
    return selectedrow[0];
  }
  CancelClickEvent() {
    this.dialog.close();
  }

}
