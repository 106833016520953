import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-add-practice-check-list-item-status-popup',
  templateUrl: './add-practice-check-list-item-status-popup.component.html',
  styleUrls: ['./add-practice-check-list-item-status-popup.component.css']
})
export class AddPracticeCheckListItemStatusPopupComponent extends commonhelper implements OnInit {
  public addPracticeCheckListModel: any = {};
  private commonhelperobj: commonHelperService;
  @ViewChild('AddPracticeCheckListItemStatusPopup') AddPracticeCheckListItemStatusPopup: ElementRef;
  constructor(httpClient: HttpClient, private dialogService: DialogService, private dialog: DialogRef,) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }
  public PracticeID: any;
  public checkListItemInfoId: any;
  public addPracticeCheckListItemStatusPopupSelectStatusDefaultItem = { CheckListItemStatusDescription: '-- Select --', CheckListItemStatus_InfoID: null };
  public addPracticeCheckListItemStatusPopupStatusList: any = [
    // { StatusId: 1, Status: 'Pending / Yet to Start' },
    // { StatusId: 2, Status: 'Completed' },
    // { StatusId: 3, Status: 'In Process' },
    // { StatusId: 4, Status: 'N / A' },

  ];
  ngOnInit() {
    setTimeout(() => {
      const elementFound = this.AddPracticeCheckListItemStatusPopup.nativeElement.querySelector('[name="' + 'addPracticeCheckListItemStatusPopupSelectStatusDropdown' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
    }, 100);

    this.getPracticeStatusList();
  }
  addPracticeCheckListItemStatusPopupCancelClickEvent() {
    this.dialog.close();
  }
  public getPracticeStatusList() {
    this.commonhelperobj.PostData(Config.OASPracticeCheckListAppnURL + 'GetPracticeCheckListItemStatusList', {}).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.addPracticeCheckListItemStatusPopupStatusList = serviceResponse.practiceCheckListItemsStatus;
    });
  }
  addPracticeCheckListItemStatusPopupSaveClickEvent() {
    if (this.checkMandatoryValidations()) return;
    var PostDataToService = this.addPracticeCheckListModel;
    PostDataToService.PracticeID = this.PracticeID;
    PostDataToService.PracticeCheckListItemLinkedInfoIDs = this.checkListItemInfoId;
    this.commonhelperobj.PostData(Config.OASPracticeCheckListAppnURL + 'InsertorUpdatePracticeStatusCommentsCheckListItems', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.dialog.close("Success");
    });
  }
  checkMandatoryValidations() {
    if (!this.hasValue(this.addPracticeCheckListModel.CheckListItemLatestStatus) || this.addPracticeCheckListModel.CheckListItemLatestStatus <= 0) {
      this.ShowErrorMessage("Please Select Check List Status")
      const elementFound = this.AddPracticeCheckListItemStatusPopup.nativeElement.querySelector('[name="' + 'addPracticeCheckListItemStatusPopupSelectStatusDropdown' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return true;
    }
  }
}
