import { Component, OnInit, ViewChild } from "@angular/core";
import { commonHelperService } from "../common/common.service";
import { AgGridNg2 } from "ag-grid-angular";
import { GridOptions } from "ag-grid-community";
import { TaskService } from "../services/task.service";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { commonhelper } from "../common/commonhelper";
import { LeftNavService } from "../common/Leftnav.service";
import { HttpClient } from "@angular/common/http";
import { IpopuModel } from "../practice-users-customization/practice-users-customization.interface";

@Component({
  selector: "app-practice-users-list",
  templateUrl: "./practice-users-list.component.html",
  styleUrls: ["./practice-users-list.component.css"],
})
export class PracticeUsersListComponent extends commonhelper implements OnInit {
  public singleSelectioncolumnDefs;
  private commonhelperobj: commonHelperService;
  @ViewChild("usersgrid") usersgrid: AgGridNg2;
  @ViewChild("singleSelectionusersgrid") singleSelectionusersgrid: AgGridNg2;

  public practiceListGridOptions: GridOptions;
  public gridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public selectedRowsString: {};
  private fieldArray: Array<any> = [];
  public userIDs: any = [];
  public rowSelection;
  rowData: any = [];
  public usersModel: any = {};
  private editmodedata: any = {};
  public UsersList: Array<string> = [];
  public overlayNoRowsTemplate;
  public SearchUserName: any;
  public defaultColDef;
  public columnDefs;
  public SelectedUserIDs: any = "";
  public StateMaintainUsersData: any = [];
  public IsFromSingleSelectionToPopup: any = false;
  showSingleSelectionPopup = false;
  callLogSelecctedPracticeId = 0;
  datatoPopup: IpopuModel;
  constructor(
    private taskService: TaskService,
    private dialog: DialogRef,
    private LeftNav: LeftNavService,
    private http: HttpClient
  ) {
    super();

    this.singleSelectioncolumnDefs = [
      // { headerName: "User ID", field: "UserID", width: 120 },
      {
        headerName: "User Name",
        field: "EmployeeName",
        width: 380,
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: true,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
      },
    ];
    // tslint:disable-next-line:max-line-length
    this.overlayNoRowsTemplate =
      "<span style='Font-size:16px;color:#00529B;background-color:#BDE5F8;margin: 50px 0px;padding: 12px;border-radius: 4px;'>Users does not Exist in this Practice.</span>";

    this.columnDefs = [
      // { headerName: 'User ID', field: 'PracticeId' },
      {
        headerName: "User Name",
        field: "EmployeeName",
        width: 280,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
      },
    ];

    this.rowSelection = "multiple";
    this.defaultColDef = { width: 100 };
  }

  ngOnInit() {
    if (this.datatoPopup && this.datatoPopup.PracticeId) {
      this.IsFromSingleSelectionToPopup = true;
    } else {
      this.IsFromSingleSelectionToPopup = this.showSingleSelectionPopup;
    }

    if (!this.IsFromSingleSelectionToPopup) {
      this.LeftNav.currentSelected.subscribe((value) => {
        this.editmodedata = value;
        // console.log(this.editmodedata);
        this.practiceUsersList();
      });
    } else {
      this.practiceUsersList();
    }
    setTimeout(function () {
      document.getElementById("txtPracticeUsersSearchUserName").focus();
    }, 50);
  }
  onSelectionChanged(event) {
    //alert("kk");
    this.fieldArray = [];
    // const selectedRows = this.usersgrid.api.getSelectedRows();
    this.fieldArray = this.usersgrid.api.getSelectedRows();
    // if (this.fieldArray) {
    //   this.userIDs = $.map(this.fieldArray, function (item) {
    //     return item;
    //   }).join(',');
    // }
  }

  onSingleSelectionChanged(event) {
    const selectedRows = this.singleSelectionusersgrid.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      //debugger;
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;
      // this.SelectedModuleInfo = selectedRow.Description;
    });
    // console.log(this.selectedRowsString);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.http
      .get(
        "https://raw.githubusercontent.com/ag-grid/ag-grid/master/packages/ag-grid-docs/src/olympicWinnersSmall.json"
      )
      .subscribe((data) => {
        this.rowData = data;
      });
  }

  practiceUsersList() {
    if (
      this.hasValue(this.editmodedata.UsersArray) &&
      this.editmodedata.UsersArray.length > 0
    ) {
      this.SelectedUserIDs = "";
      this.SelectedUserIDs = $.map(this.editmodedata.UsersArray, function (
        item
      ) {
        return item.UserID;
      }).join(", ");
    }

    const postData = {
      PracticeId:
        this.editmodedata.PracticeId ||
        this.callLogSelecctedPracticeId ||
        this.datatoPopup.PracticeId,
      IssueSupportGroupID: this.editmodedata.IssueSupportGroupID,
      EmployeeName: this.SearchUserName,
      SelectedUserIDs: this.SelectedUserIDs,
    };

    this.taskService.PracticeUsersList(postData).subscribe((response) => {
      if (this.isError(response)) {
        return;
      }
      if (this.hasValue(response) && response.length > 0) {
        this.rowData = response;
      } else {
        this.rowData = [];
      }
    });
  }
  onModelUpdated() {
    if (this.hasValue(this.StateMaintainUsersData)) {
      this.StateMaintainUsersData.forEach((selectedRow, index) => {
        this.usersgrid.api.forEachNode((node) => {
          if (node.data.UserID === parseInt(selectedRow.UserID)) {
            node.setSelected(true);
          }
        });
      });
    }
  }
  assignSelectedInput() {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage("Please Select User.");
      return false;
    }
    this.dialog.close(this.selectedRowsString);
  }
  OKClickEvent() {
    if (this.IsFromSingleSelectionToPopup) {
      this.assignSelectedInput();
      return;
    }
    if (!this.hasValue(this.fieldArray) && this.fieldArray.length <= 0) {
      this.ShowErrorMessage("Please Select User.");
      return false;
    }

    if (this.hasValue(this.fieldArray) && this.fieldArray.length > 0) {
      if (this.editmodedata.UsersArray == undefined)
        this.editmodedata.UsersArray = [];
      this.fieldArray.forEach((element) => {
        this.usersModel = {
          UserID: element.UserID,
          EmployeeName: element.EmployeeName,
          EmployeeID: element.EmployeeID,
        };
        // tslint:disable-next-line:no-shadowed-variable

        // const filteredListItem = this.grep(this.editmodedata.UsersArray, (itemInLoop) => {
        //   return itemInLoop.UserID === this.usersModel.UserID;
        // }, undefined);

        // this.editmodedata.UsersArray = [];
        this.editmodedata.UsersArray.push(this.usersModel);
      });
    }

    if (
      this.hasValue(this.editmodedata.UsersArray) &&
      this.editmodedata.UsersArray.length > 0
    ) {
      // alert(this.editmodedata.UsersArray);
      this.dialog.close(this.editmodedata.UsersArray);
    }
  }

  CancelClickEvent() {
    this.dialog.close();
  }
}
