import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { GridOptions } from 'ag-grid-community';
import { AgGridNg2 } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router'; 
import { commonHelperService } from '../common/common.service';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee } from '../edit-task-customization-based-on-users/edit-task-customization-based-on-users.component';
import { TaskService } from '../services/task.service';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { AgGridModule } from 'ag-grid-angular';
import { IntlService } from '@progress/kendo-angular-intl';
import { EmployeeInfoComponentComponent } from '../employee-info-component/employee-info-component.component';
//  import "ag-grid-enterprise";

@Component({
  selector: 'app-month-summary-report',
  templateUrl: './month-summary-report.component.html',
  styleUrls: ['./month-summary-report.component.css']
})
export class MonthSummaryReportComponent extends commonhelper implements OnInit {

  @ViewChild('MonthSummaryReportGrid') MonthSummaryReportGrid: AgGridNg2;
  public gridOptions: GridOptions;
  public MonthSummaryReportGridOptions: GridOptions;
  private gridApi;
  private defaultColDef;
  private gridColumnApi;
  public DATE = new Date();
  public Day = this.DATE.getDate();
  public CurrentYear = this.DATE.getFullYear();
  public MONTH = this.DATE.getMonth() + 1;
  commonhelperobj: commonHelperService;
  public MonthSummaryReportYearList: any;
  public MonthSummaryReportMonthList: any;
  public MonthSummaryReportYearsList:any;
  public MonthSummaryReportMonthsList:any;
  public CurrentMonth:any;
  public Currentyear:any;
  public MonthSummaryReportrowData = [];
  public months=["JANUARY","FEBRUARY","MARCH","APRIL","MAY","JUNE","JULY","AUGUST","SEPTEMBER","OCTOBER","NOVEMBER","DECEMBER"]
  MonthSummaryReportColumnDefs = [
    { headerName: 'S.No', field: 'MonthSummaryID', width: 84, tooltipField: 'MonthSummaryID', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    // { headerName: 'Office Working Days with Holidays', field: 'TotalWorkingDays', width: 250, tooltipField: 'OfficeWorkingDaysWithHolidays', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Office Working Days', field: 'OfficeOfWorkingday', width: 216, tooltipField: 'OfficeOfWorkingday', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'EWD', field: 'EmployeeWorkingDays', width: 135, tooltipField: 'EmployeeWorkingDays', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Full Days', field: 'FULLDays', width: 149, tooltipField: 'FULLDays', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Full Days Lvs', field: 'FullDayLeaves', width: 152, tooltipField: 'FullDayLeaves', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Half Days Lvs', field: 'HalfDayLeaves', width: 158, tooltipField: 'HalfDayLeaves', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Total Lvs', field: 'TotalLeaves', width: 144, tooltipField: 'TotalLeaves', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Perm.', field: 'PermissionDays', width: 130, tooltipField: 'PermissionDays', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Late Checkin', field: 'LateCheckIns', width: 146, tooltipField: 'LateCheckIns', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    // { headerName: '', field: '', width: 450, cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'CL ', field: 'CasualLeaves_OpeningBalance', width: 144, tooltipField: 'CasualLeaves_OpeningBalance', cellClass: 'no-border cell-wrap-text', autoHeight: true, },
    // { headerName: 'Leaves Applied', field: 'LeavesApplied', width: 183, tooltipField: 'LeavesApplied', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    // { headerName: 'CL Avail.', field: 'CLAvail', width: 120, tooltipField: 'CLAvail', cellClass: 'no-border cell-wrap-text', autoHeight: true, },
    { headerName: 'Comp Off', field: 'CompoffLeaves', width: 155, tooltipField: 'CompoffLeaves', cellClass: 'no-border cell-wrap-text', autoHeight: true, },

  ];
 
 
  dataAccessInformationYearsList: any;

  constructor(private route: ActivatedRoute, httpClient: HttpClient,
    private _Router: Router, private _router: Router, private router: Router,
    private taskService: TaskService,
    private dialogService: DialogService, private intl: IntlService,
  ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
    this.defaultColDef = {
      sortable: true,
      filter: true
    };
  }
  public defaultMonthSummaryReportMonthsList = { MonthDesc: '-- Select Month --', MonthID: null }
  public defaultMonthSummaryReportYearsList = { Year: '-- Select Year --', YearID: null }

  ngOnInit() {
    if (this.Day >= 25) {
      this.MonthSummaryReportYearsList = this.CurrentYear;
      this.MonthSummaryReportMonthsList = this.MONTH + 1;
    }
    else {
      this.MonthSummaryReportYearsList = this.CurrentYear;
      this.MonthSummaryReportMonthsList = this.MONTH;
    }
    this.MonthSummaryreportYearsList();
    this.MonthSummaryreportMonthsList();
    this.MonthSummaryReportInformationMonthDetails();
  }

  // MonthSummaryReporttoExcel() {
  //   var params = {
  //     fileName: "Month summary report",
  //     sheetName: "excelsheet"
  //   };
  //   this.gridApi.exportDataAsExcel(params);
  // }

  // onGridReady(params) {
  //   this.gridApi = params.api;
  //   this.gridColumnApi = params.columnApi;
  //  }

  public MonthSummaryreportYearsList() {


    var PostDataToService = {

    };
    this.commonhelperobj.PostData(Config.OASAccessInformationAppnURL + 'GetAccessInformationYearsModelList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.MonthSummaryReportYearList = serviceResponse;
    });

  }
  public MonthSummaryreportMonthsList() {


    var PostDataToService = {

    };
    this.commonhelperobj.PostData(Config.OASAccessInformationAppnURL + 'GetAccessInformationMonthDetailsModel', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.MonthSummaryReportMonthList = serviceResponse;
    });
  }

  public MonthSummaryReportInformationMonthDetails() {
    //NgModel Values are assigning to the variables to the backend for filtering purpose and the month is decremented by one to the NgModel value to get the selected current month
    this.CurrentMonth = this.months[this.MonthSummaryReportMonthsList - 1]
    this.Currentyear = this.MonthSummaryReportYearsList
    // this.AccessInformationDayDetails()
    /*** The Variables are used for filtering the lists  based on data entered on the respective fields **/
    // alert(this.allReportByResponsibleEmployeeFromDate);
    const PostDataToService = {
      Year: this.MonthSummaryReportYearsList,
      Month: this.MonthSummaryReportMonthsList,
      //  Month : 4,
      //EmployeeInfoID:  this.EmployeeInfoID 

    };
    return this.commonhelperobj.PostData(Config.OASAccessInformationAppnURL + 'EmployeeAttendanceMonthDetails', PostDataToService)
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.MonthSummaryReportrowData = response.EmployeeAttendanceMonthDetailsModelList;
        //this.EmployeeInfoId="";

      });
  }


  monthSummaryReportCloseClick() {
     const outlet = {};
    const clickedItem1: any = {};
    clickedItem1.routename = 'main';
     clickedItem1.routepath = '/home';
    outlet[clickedItem1.routename] = ['app-employee-info-component', this.setCommonData(clickedItem1)];
    this._Router.navigate(['/home', { outlets: { 'main': 'app-employee-info-component' } }]);

    // const PostDataService = {};
    // const outletObj = {};
    // outletObj['main'] = ['app-employee-info-component', this.setCommonData(PostDataService)];
    // this._Router.navigate(['/home', { outlets: outletObj }]);

    // const outletObj = {};
    // const clickedItem: any = {};
    // clickedItem.routename = 'main';
    // clickedItem.routepath = '/home';
    


    // // if (clickedItem.MenuInfoID === 58) {
    // //   clickedItem.IsFromEmployeeOrShiftsInfo = true;
    // // } else {
    // //   clickedItem.IsFromEmployeeOrShiftsInfo = false;
    // // }

    // outletObj[clickedItem.routename] = ['app-view-task-information', this.setCommonData(clickedItem)];

    // this._router.navigate([clickedItem.routepath, { outlets: outletObj }]);
  }

}
