import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { _ } from 'ag-grid-community';
import { commonHelperService } from '../common/common.service';
import { commonhelper } from '../common/commonhelper';
import { Config } from '../config';
import { PracticeUsersListComponent } from '../practice-users-list/practice-users-list.component';
import { PraticesListComponent } from '../pratices-list/pratices-list.component';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';
import { AddEditCallLogDetailsPopupService } from './add-edit-call-log-details-popup.service';

@Component({
  selector: 'app-add-edit-call-log-details-popup',
  templateUrl: './add-edit-call-log-details-popup.component.html',
  styleUrls: ['./add-edit-call-log-details-popup.component.css']
})
export class AddEditCallLogDetailsPopupComponent extends commonhelper implements OnInit {
  @ViewChild('AddEditCallLogDetailsPopup') AddEditCallLogDetailsPopup: ElementRef;
  @ViewChild("AddEditCallLogDetailsPopup", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  private commonhelperobj: commonHelperService;
  practiceInformation: any = [];
  hideCallTransferredto = true;
  previousSelectedTypeId = 0;
  public addEditCallLogDetailsPopupSelectStatusDefaultItem = { Status: '-- Select --', StatusId: null };
  public addEditCallLogDetailsPopupSelectDurationDefaultItem = { Duration: '-- Select --', DurationId: null };
  public addEditCallLogDetailsPopupStatusList: any = [
    { StatusId: 1, Status: 'Completed' },
    { StatusId: 2, Status: 'Transferred' },
    { StatusId: 3, Status: 'CallBack' },

  ];
  public customerCallLogCallTypeListDefaultItem = { CallTypeDescription: '-- Select --', CallTypeInfoID: null };
  public customerCallLogCallTypeList: any = [
    // { CallTypeInfoID: 0, CallTypeDescription: 'All' },
    { CallTypeInfoID: 1, CallTypeDescription: 'Incoming Call' },
    { CallTypeInfoID: 2, CallTypeDescription: 'Outgoing Call' },
  ];
  public addEditCallLogDetailsPopupDurationList: any = [
    { DurationId: 1, Duration: '15min' },
    { DurationId: 2, Duration: '30min' },
    { DurationId: 3, Duration: '45min' },
    { DurationId: 4, Duration: '1hr' },
    { DurationId: 5, Duration: '1hr 15min' },
    { DurationId: 6, Duration: '1hr 30min' },
    { DurationId: 7, Duration: '1hr 45min' },
    { DurationId: 8, Duration: '2hr' },

  ];
  SelectedCallLogId = 0;
  addEdiCallLogsShowHideSaveUpdateButton = false;
  StateMaintainUsersData: any = [];
  usersInformation: any = {};
  public addEditCallLogsModel: any = {};
  CallAnsweredByInfo: any = [];
  CallAnsweredTransferedTO: any = [];
  callTypeDisabled = false;
  constructor(httpClient: HttpClient, private dialogService: DialogService, private dialog: DialogRef, private _addEditCallLogDetailsPopupService: AddEditCallLogDetailsPopupService) { super(); }

  ngOnInit() {
    this.initializeFieldswithRespectiveData();
    this.setfocusonPracticeNameFieldonInit();
    if (this.SelectedCallLogId > 0) {
      this.callTypeDisabled = true;
      this.GetCustomerCallLogs();
      this.addEdiCallLogsShowHideSaveUpdateButton = true;
    }

  }
  setfocusonPracticeNameFieldonInit() {
    setTimeout(function () {
      document.getElementById("addEditCallLogDetailsPopupSelectPracticeName").focus();
    }, 20);
  }
  initializeFieldswithRespectiveData() {
    this.addEditCallLogsModel.CustomerCallLogTypeId = 1;
    this.CustomerCallLogTypeChangeEvent();
    this.addEditCallLogsModel.CustomerCallLogAnsweredTime = this.addEditCallLogsModel.CustomerCallLogAnsweredDate = new Date();
  }
  GetCustomerCallLogs() {
    this._addEditCallLogDetailsPopupService.GetCustomerCallLogs({ CustomerCallLogsID: this.SelectedCallLogId }).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) return;
      this.addEditCallLogsModel = serviceResponse;
      this.CustomerCallLogTypeChangeEvent();
      let startDate = serviceResponse.CustomerCallLogAnsweredDate
      this.addEditCallLogsModel.CustomerCallLogAnsweredDate = new Date(serviceResponse.CustomerCallLogAnsweredDate);
      this.addEditCallLogsModel.CustomerCallLogAnsweredTime = new Date(startDate.concat(" ").concat(serviceResponse.CustomerCallLogAnsweredTime))
      this.GetCustomerCallLogParticipents();
      this.GetCustomerCallLogPracticeUsersList();
    });

  }
  GetCustomerCallLogPracticeUsersList() {
    this._addEditCallLogDetailsPopupService.GetCustomerCallLogPracticeUsersList({ CustomerCallLogsID: this.SelectedCallLogId }).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) return;
      this.addEditCallLogsModel.SelectedUserPersonName = serviceResponse.practiceUsersList[0].EmployeeName
      this.usersInformation = serviceResponse.practiceUsersList[0];
      // this.assignParticipantsNamestongModel(this.usersInformation, "SelectedUserPersonName", "EmployeeName");
    });
  }
  GetCustomerCallLogParticipents() {
    this._addEditCallLogDetailsPopupService.GetCustomerCallLogParticipents({ CustomerCallLogsID: this.SelectedCallLogId }).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) return;
      var element = this;
      _.forEach(serviceResponse.participantsList, function (item: any) {
        if (item.SupportEmployee_Type == 1) {
          element.CallAnsweredByInfo = [item];
          element.addEditCallLogsModel.SelectedEmployeeName = item.EmployeeFullName;
        }
        else {
          element.CallAnsweredTransferedTO = [item];
          element.addEditCallLogsModel.SelectedCallTransferedEmployeeName = item.EmployeeFullName

        }
      });
      // if (serviceResponse.participantsList[0].SupportEmployee_Type == 1) {
      //   this.CallAnsweredByInfo = [serviceResponse.participantsList[0]];
      //   this.addEditCallLogsModel.SelectedEmployeeName = serviceResponse.participantsList[0].EmployeeFullName;
      // }
      // if (this.hasValue(serviceResponse.participantsList[1])) {
      //   this.CallAnsweredTransferedTO = [serviceResponse.participantsList[1]];
      //   this.addEditCallLogsModel.SelectedCallTransferedEmployeeName = serviceResponse.participantsList[1].EmployeeFullName
      // }
      // this.assignParticipantsNamestongModel(this.StateMaintainUsersData, "SelectedEmployeeName", "EmployeeFullName");
    });
  }
  assignParticipantsNamestongModel(usersList, assignto, filteritem) {
    this.addEditCallLogsModel[assignto] = usersList.map(function (item) { return item[filteritem] }).join(", ");


  }
  addEditCallLogDetailsPopupSaveClickEvent() {
    if (this.checkMandatoryValidations()) return;
    this.hasValue(this.CallAnsweredTransferedTO) ? this.CallAnsweredByInfo.push(this.CallAnsweredTransferedTO[0]) : this.CallAnsweredByInfo;
    this.addEditCallLogsModel.participantslist = this.CallAnsweredByInfo;
    this.addEditCallLogsModel.practiceUsersList = this.hasValue(this.usersInformation) ? [this.usersInformation] : [{ EmployeeName: this.addEditCallLogsModel.SelectedUserPersonName, EmployeeId: 0 }];
    if (typeof (this.addEditCallLogsModel.CustomerCallLogAnsweredDate) != "string")
      this.addEditCallLogsModel.CustomerCallLogAnsweredDate = formatDate(this.addEditCallLogsModel.CustomerCallLogAnsweredDate, 'MM/dd/yyy', 'en-US', '+0530');
    if (typeof (this.addEditCallLogsModel.CustomerCallLogAnsweredTime) != "string")
      this.addEditCallLogsModel.CustomerCallLogAnsweredTime = formatDate(this.addEditCallLogsModel.CustomerCallLogAnsweredTime, 'hh:mm a', 'en-US', '+0530')
    this._addEditCallLogDetailsPopupService.SaveorUpdateCustomerCallLogs(this.addEditCallLogsModel).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) return;
      this.dialog.close("Success");
    });
  }
  checkMandatoryValidations() {
    if (!this.hasValue(this.addEditCallLogsModel.SelectedPracticeName)) {
      this.ShowErrorMessage("Please Select Practice Name")
      document.getElementById("addEditCallLogDetailsPopupSelectPracticeName").focus();
      return true;
    }
    if (!this.hasValue(this.addEditCallLogsModel.SelectedUserPersonName)) {
      this.ShowErrorMessage("Please Select Call From")
      document.getElementById("addEditCallLogDetailsPopupSelectCallFrom").focus();
      return true;
    }

    if (!this.hasValue(this.addEditCallLogsModel.SelectedEmployeeName)) {
      this.ShowErrorMessage("Please Select Call Answered By")
      document.getElementById("addEditCallLogDetailsPopupSelectCallAnsweredBy").focus();
      return true;
    }
    if (!this.addEditCallLogsModel.CustomerCallLogAnsweredDate) {
      this.ShowErrorMessage("Please Select / Enter Call Answered Date")
      const elementFound = this.AddEditCallLogDetailsPopup.nativeElement.querySelector('[name="' + 'txtAddEditCallLogDetailsPopupCallAnsweredDate' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return true;
    }
    if (this.addEditCallLogDetailsPopupCallAnsweredDateChange() == false) return true;
    if (!this.addEditCallLogsModel.CustomerCallLogAnsweredTime) {
      this.ShowErrorMessage("Please Select / Enter  Call Answered Time")
      const elementFound = this.AddEditCallLogDetailsPopup.nativeElement.querySelector('[name="' + 'txtAddEditCallLogDetailsPopupAnsweredTime' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return true;
    }
    if (!this.hasValue(this.addEditCallLogsModel.CustomerCallLogStatus) || this.addEditCallLogsModel.CustomerCallLogStatus <= 0) {
      this.ShowErrorMessage('Please Select Status');
      const elementFound = this.AddEditCallLogDetailsPopup.nativeElement.querySelector('[name="' + 'addEditCallLogDetailsPopupSelectStatusDropdown' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return true;
    }
    if (!this.addEditCallLogsModel.CustomerCallLogPurpose || !this.hasValue(this.addEditCallLogsModel.CustomerCallLogPurpose.trimStart())) {
      this.ShowErrorMessage("Please Enter Purpose of Meeting")
      document.getElementById("taAddEditCallLogDetailsPurpose").focus();
      return true;
    }
  }
  addEditCallLogDetailsPopupCallAnsweredDateChange() {
    if (!(this.addEditCallLogstartandEndDateValidations(this.addEditCallLogsModel.CustomerCallLogAnsweredDate, "txtAddEditCallLogDetailsPopupCallAnsweredDate", true))) return false;
  }
  addEditCallLogstartandEndDateValidations(modifiedfromandtoDate: Date, name: string, isfrom: boolean) {
    var today = new Date();
    var toDate = new Date(modifiedfromandtoDate);
    var showfocuselement = false;
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage('Invalid Day, Month or Year Range Detected.');
      const elementFound = this.AddEditCallLogDetailsPopup.nativeElement.querySelector('[name="' + name + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    if (isfrom) {
      var daysDifference;
      let createdDate = this.addEditCallLogsModel.CustomerCallLogCreatedDate || formatDate(today, 'MM/dd/yyy', 'en-US', '+0530');
      daysDifference = this.DateDiffInDays(formatDate(this.addEditCallLogsModel.CustomerCallLogAnsweredDate, 'MM/dd/yyy', 'en-US', '+0530'), createdDate);
      if (this.hasValue(daysDifference) && daysDifference < 0) {
        if (this.hasValue(this.addEditCallLogsModel.CustomerCallLogCreatedDate)) {
          this.ShowErrorMessage('Call Answered Date Should be Greater Than or Equal to Created Date');
        }
        else {
          this.ShowErrorMessage('Call Answered Date  Should be Greater Than or Equal to Current Date');
        }
        const elementFound = this.AddEditCallLogDetailsPopup.nativeElement.querySelector('[name="' + name + '"]');
        if (this.hasValue(elementFound)) {
          elementFound.children[0].children[0].children[0].children[0].focus();
        }
        return false;
      }
    }
    return true;
  }
  addEditCallLogDetailsSelectPracticeNameClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Practice',
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        let previousPracticeId = this.practiceInformation.PracticeId;
        this.practiceInformation = result;
        if (this.hasValue(this.practiceInformation)) {
          if (previousPracticeId != this.practiceInformation.PracticeId) this.hideCallTransferredto ? this.addEditCallLogDetailsRemoveSelectedCallAnsweredByClickEvent() : this.assistantPersonIconRemoveSelectedCallFromClickEvent();
          this.addEditCallLogsModel.SelectedPracticeName = this.practiceInformation.PracticeName;
          this.addEditCallLogsModel.CustomerCallLogPracticeID = this.practiceInformation.PracticeId;
        }
      }
    });
  }
  assistantPersonIconRemoveSelectedCallFromClickEvent() {
    this.usersInformation = [];
    this.addEditCallLogsModel.SelectedUserPersonName = ""
  }
  addEditCallLogDetailsRemoveSelectedPracticeNameClickEvent() {
    this.addEditCallLogsModel.SelectedPracticeName = "";
    this.addEditCallLogsModel.PracticeId = 0;
    this.hideCallTransferredto ? this.addEditCallLogDetailsRemoveSelectedCallAnsweredByClickEvent() : this.assistantPersonIconRemoveSelectedCallFromClickEvent();
  }
  addEditCallLogDetailsRemoveSelectedCallAnsweredByClickEvent() {
    this.CallAnsweredByInfo = [];
    this.addEditCallLogsModel.SelectedEmployeeName = "";
  }
  addEditCallLogDetailsRemoveSelectedCallTransferredToClickEvent() {
    this.CallAnsweredTransferedTO = [];
    this.addEditCallLogsModel.SelectedCallTransferedEmployeeName = "";
  }
  CustomerCallLogTypeChangeEvent() {
    this.hideCallTransferredto = this.addEditCallLogsModel.CustomerCallLogTypeId == 2;
    if (this.previousSelectedTypeId == this.addEditCallLogsModel.CustomerCallLogTypeId) return;
    if (this.hideCallTransferredto) {
      this.addEditCallLogsModel.SelectedUserPersonName = `${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}`
      this.usersInformation = { UserID: Config.OASOrganizationModel.LoggedUserID, EmployeeName: `${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}` }
      this.addEditCallLogDetailsRemoveSelectedCallAnsweredByClickEvent();
      this.addEditCallLogDetailsRemoveSelectedCallTransferredToClickEvent();
    }
    else {
      this.addEditCallLogsModel.SelectedEmployeeName = `${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}`
      this.CallAnsweredByInfo = [{ EmployeeId: Config.OASOrganizationModel.LoggedUserID, EmployeeFullName: `${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}`, SupportEmployee_Type: 1 }]
      this.assistantPersonIconRemoveSelectedCallFromClickEvent();
    }
    this.previousSelectedTypeId = this.addEditCallLogsModel.CustomerCallLogTypeId
  }
  assistantPersonIconSelectCallFromClickEvent() {
    if (!this.hasValue(this.addEditCallLogsModel.SelectedPracticeName) && !this.hideCallTransferredto) {
      this.ShowErrorMessage('Please Select Practice.');
      return false;
    }
    // if (!this.hasValue(this.addEditCallLogsModel.CustomerCallLogTypeId)) {
    //   this.ShowErrorMessage('Please Select Call Type');
    //   return false;
    // }
    const dialogRef = this.dialogService.open({
      title: 'Select Call From',
      content: this.hideCallTransferredto ? ResponsiblepersonUsersListComponent : PracticeUsersListComponent,
      height: this.hideCallTransferredto ? 698 : 676,
      width: this.hideCallTransferredto ? 550 : 350,
      appendTo: this.ContainerRef
    });
    if (!this.hideCallTransferredto) {
      dialogRef.content.instance.showSingleSelectionPopup = true;
      dialogRef.content.instance.callLogSelecctedPracticeId = this.addEditCallLogsModel.CustomerCallLogPracticeID;
    }
    dialogRef.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        debugger
        this.usersInformation = { UserID: result.EmployeeId || result.UserID, EmployeeName: result.EmployeeFullName || result.EmployeeName };
       //console.log(this.usersInformation)
        if (this.hasValue(this.usersInformation)) {

          this.addEditCallLogsModel.SelectedUserPersonName = this.usersInformation.EmployeeName;
        }
      }
    });
  }
  addEditCallLogDetailsSelectCallAnsweredByClickEvent() {
    if (!this.hasValue(this.addEditCallLogsModel.SelectedPracticeName) && this.hideCallTransferredto) {
      this.ShowErrorMessage('Please Select Practice.');
      return false;
    }
    // if (!this.hasValue(this.addEditCallLogsModel.CustomerCallLogTypeId)) {
    //   this.ShowErrorMessage('Please Select Call Type');
    //   return false;
    // }
    const dialogRef = this.dialogService.open({
      title: 'Select Call Answered By',
      content: this.hideCallTransferredto ? PracticeUsersListComponent : ResponsiblepersonUsersListComponent,
      height: this.hideCallTransferredto ? 676 : 698,
      width: this.hideCallTransferredto ? 350 : 550,
      appendTo: this.ContainerRef
    });
    if (this.hideCallTransferredto) {
      dialogRef.content.instance.showSingleSelectionPopup = true;
      dialogRef.content.instance.callLogSelecctedPracticeId = this.addEditCallLogsModel.CustomerCallLogPracticeID;
    }
    dialogRef.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.CallAnsweredByInfo = [];
        debugger;
        this.CallAnsweredByInfo.push({ EmployeeId: result.EmployeeId || result.UserID, EmployeeFullName: result.EmployeeFullName || result.EmployeeName });
        this.CallAnsweredByInfo[0].SupportEmployee_Type = 1
        if (this.hasValue(this.CallAnsweredByInfo)) {
          this.addEditCallLogsModel.SelectedEmployeeName = this.CallAnsweredByInfo[0].EmployeeFullName;
        }
      }
    });
  }
  addEditCallLogDetailsSelectCallTransferredToClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Call Transferred To',
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.CallAnsweredTransferedTO = [];
        this.CallAnsweredTransferedTO.push(result);
        this.CallAnsweredTransferedTO[0].SupportEmployee_Type = 2;
        if (this.hasValue(this.CallAnsweredTransferedTO)) {
          this.addEditCallLogsModel.SelectedCallTransferedEmployeeName = this.CallAnsweredTransferedTO[0].EmployeeFullName;
        }
      }
    });
  }
  addEditCallLogDetailsPopupCancelClickEvent() {
    this.dialog.close();
  }
}
