import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  // selector: 'app-view-icon-cell-renderer',
  // templateUrl: './view-icon-cell-renderer.component.html',
  // styleUrls: ['./view-icon-cell-renderer.component.css']
  selector: 'child-cell',
  template: `<span><i style="color:red;font-size:20px;cursor:pointer" (click)="viewSelectedRecordClickEvent()" class="fa fa-trash"></i></span>`,
  styles: [

  ]
})
export class ViewIconCellRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  public viewSelectedRecordClickEvent() {
  }

  refresh(): boolean {
    return false;
  }

}
