
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogCloseResult,DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { commonHelperService } from '../common/common.service';
import { commonhelper } from '../common/commonhelper';
import { PraticesListComponent } from '../pratices-list/pratices-list.component';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridSizeChangedEvent } from 'ag-grid-community';

@Component({
  selector: 'app-by-status',
  templateUrl: './by-status.component.html',
  styleUrls: ['./by-status.component.css']
})
export class ByStatusComponent extends commonhelper implements OnInit {
   @ViewChild('byStatusGrid') byStatusGrid: AgGridNg2;
  @ViewChild("ByStatus", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  private commonhelperobj: commonHelperService;
  FromRecords:any;
  PrevLabelCount:any;
  NextLabelCount:any;
  RecordsPerPage:any;
  TotalCountValue:boolean=true;
  TotalCountValueIcon:boolean=false;
  TotalLabelCount:any;
  TotalLabelCountValue:any;
  fromrecordsfromcount:any;
  SearchStatusIDs: any = [];
  prevnextbuttons:boolean=true;
  NextButtonDisabled:boolean=false;
  PreviousButtondisabled:boolean=false;
  public viewTaskInfoSelectStatusDropDown: any = [];
  public rowData=[];
  selectedTask:any;
  columnDefs = [
    { headerName: 'Task #', field: 'TaskNumber', width: 90, tooltipField: 'TaskNumber', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Practice Name', field: 'PracticeName', width: 200, tooltipField: 'PracticeName', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Task Type', field: 'TaskType', width: 150, tooltipField: 'TaskType', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Task Subject', field: 'IssueSubject', width: 240, tooltipField: 'IssueSubject', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Priority', field: 'SeverityDesc', width: 80, tooltipField: 'SeverityDesc', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Task Status', field: 'TaskLatestStatusName', width: 100, tooltipField: 'TaskLatestStatusName', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Task Current Working Status', field: 'TaskCurrentWorkingStatus', width: 200, tooltipField: 'TaskCurrentWorkingStatus', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Responsible Person', field: 'ResponsiblePerson', width: 150, tooltipField: 'ResponsiblePerson', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Created By', field: 'CreatedBy', width:120, tooltipField: 'CreatedBy', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
  ];
  onByStatusGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  constructor(private http: HttpClient,httpClient: HttpClient,private dialogService: DialogService,private route: ActivatedRoute,private _router: Router ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }
 
  public practiceInformation:any={};
  public responsiblePersonInfo:any={};
  public byStatusSearchTaskNumber:any;
  public byStatusSearchTaskSubject:any;
  public implementedbyModel:any={};
 
  ngOnInit() {
    this.SearchStatusIDs.push({TaskStatusId:1,TaskDescription:"Yet to Start"})  
    this.route.params.subscribe(params => {
      const data = this.getCommonData(params.info);
    if (this.hasValue(data) && this.hasValue(data.previouslySelectedSearchFilters)) {
      this.byStatusSearchTaskNumber = data.previouslySelectedSearchFilters.byStatusSearchTaskNumber;
       this.byStatusSearchTaskSubject =data.previouslySelectedSearchFilters.byStatusSearchTaskSubject;
      this.responsiblePersonInfo = data.previouslySelectedSearchFilters.responsiblePersonInfo;
      this.practiceInformation = data.previouslySelectedSearchFilters.practiceInformation;
      this.FromRecords = data.previouslySelectedSearchFilters.StatemaintainFromRecords;
      this.implementedbyModel = data.previouslySelectedSearchFilters.implementedbyModel;
      this.SearchStatusIDs = data.previouslySelectedSearchFilters.SearchStatusIDs;
      this.selectedTask=data.previouslySelectedTaskNumber;
    }
  });
    this.getTaskStatusList();
   this.byStatusGetStatusListClick(undefined,undefined);
   setTimeout(function () {
    document.getElementById("byStatusSearchTaskNumber").focus();
    },20);
    
    for (let i = 0; i < this.columnDefs.length; i++) {
      switch (this.columnDefs[i].field) {
        case 'IssueSubject':
          if ($(window).width() > 1700) {
            this.columnDefs[3].width = 467;
            this.columnDefs[6].width = 225;
          } else if ($(window).width() < 1700 && $(window).width() >= 1670) {
            this.columnDefs[3].width = 260;
            this.columnDefs[6].width = 169;
            this.columnDefs[7].width = 150;
          } else if ($(window).width() < 1670 && $(window).width() >= 1550) {
            this.columnDefs[3].width = 191;
            this.columnDefs[6].width = 189;
          } else if ($(window).width() < 1550 && $(window).width() >= 1480) {
            this.columnDefs[1].width = 126;
            this.columnDefs[3].width = 229;
            this.columnDefs[6].width = 156;
          } else if ($(window).width() < 1480 && $(window).width() >= 1400) {
            this.columnDefs[1].width = 109;
            this.columnDefs[3].width = 162;
            this.columnDefs[6].width = 151;
            this.columnDefs[7].width = 135;
          } else if ($(window).width() < 1400 && $(window).width() >= 1300) {
            this.columnDefs[1].width = 114;
            this.columnDefs[3].width = 144;
            this.columnDefs[6].width = 135;
            this.columnDefs[8].width = 135;
          } else if ($(window).width() < 1300 && $(window).width() >= 1200) {
            this.columnDefs[1].width = 140;
            this.columnDefs[2].width = 146;
            this.columnDefs[3].width = 160;
            this.columnDefs[6].width = 118;
            this.columnDefs[8].width = 114;
          }
          break;
      }
    }
    setTimeout(() => {
      this.statemaintainPreviousSelectedRecord();
    }, 100);
  }
  private getTaskStatusList(){
    this.commonhelperobj.PostData(Config.OASAppnURL + 'TaskStatusList', {}).subscribe(response => {
      if (this.isError(response)) { return; }

      this.viewTaskInfoSelectStatusDropDown = [];

      response.forEach(element => {
        if (element.TaskStatusId != 4) {
          this.viewTaskInfoSelectStatusDropDown.push({ TaskDescription: element.TaskDescription, TaskStatusId: element.TaskStatusId });
        }
      });


    });
  }

  viewTaskInfoPrevNextClick(prevornext){
    
      this.byStatusGetStatusListClick(undefined,prevornext)
  }
   byStatusGetStatusListClick(isfromsearch,prevornext){
    this.RecordsPerPage = 50;
    if(isfromsearch==true && (prevornext==false||prevornext==undefined) && this.TotalCountValue==false ){
      this.TotalCountValueIcon=false;
      this.TotalCountValue=true;
    }
    if(!this.hasValue(this.FromRecords) || (this.hasValue(isfromsearch) && isfromsearch==true)){
      this.FromRecords=1;
    }
    switch(prevornext){
      case 1:
         //if(this.hasValue(this.PreviousButtondisabled) && this.PreviousButtondisabled==true) return;
        this.FromRecords -=this.RecordsPerPage
        break;
    
    case 2:
       //if(this.hasValue(this.NextButtonDisabled) && this.NextButtonDisabled==true) return;
      this.FromRecords += this.RecordsPerPage
      break;
      case 3:
        this.fromrecordsfromcount = this.FromRecords;
        this.FromRecords=null;
    }
    let statusids=this.SearchStatusIDs.map(item => item.TaskStatusId).join(',') 
    const data = {
      CreatedByUserID:  this.hasValue(this.implementedbyModel.EmployeeId)?this.implementedbyModel.EmployeeId :undefined,
      FromRecords: this.hasValue(this.FromRecords)?this.FromRecords:undefined,
      TaskNumber:this.byStatusSearchTaskNumber,
      TaskSubject:this.byStatusSearchTaskSubject,
      TaskLatestStatusList :this.hasValue(statusids)?statusids:undefined,
      ResponsiblePersonUserId:  this.hasValue(this.responsiblePersonInfo.EmployeeId)?this.responsiblePersonInfo.EmployeeId :undefined,
      PracticeId:  this.hasValue(this.practiceInformation.PracticeId)?this.practiceInformation.PracticeId.toString() :undefined,
    }
    this.commonhelperobj.PostData(Config.OASReportsofTasksAppnURL + 'TaskByStatus', data)
  .subscribe(response => {
    if (this.isError(response)) {
      return;
    }
    
      if(this.hasValue(response) && this.hasValue(response.Count) && response.Count>0){
        this.TotalCountValueIcon = true;
        this.TotalCountValue=false;
        this.TotalLabelCount = response.Count;
        this.FromRecords=this.fromrecordsfromcount;
        return;
      }
      response.taskListByStatusListOutPutModel.forEach(TaskListelement => {
        if (this.hasValue(TaskListelement.TaskUsersInfo)) {
          const SplittedUserData = TaskListelement.TaskUsersInfo.split(',');
          if (this.hasValue(SplittedUserData) && SplittedUserData.length > 0) {
            SplittedUserData.forEach(element => {
              const getUserRolesData = element.split('|');
              if (this.hasValue(getUserRolesData) && getUserRolesData.length > 0) {
                if (getUserRolesData[2] === '2') {
                  TaskListelement.ResponsiblePerson = getUserRolesData[1];
                }
              }
            });
          }
        }
      });
      this.rowData=response.taskListByStatusListOutPutModel;
      this.PrevLabelCount = this.FromRecords
        if(this.FromRecords==1){
          this.NextLabelCount = response.taskListByStatusListOutPutModel.length
        }
        else{
        this.NextLabelCount = this.FromRecords+response.taskListByStatusListOutPutModel.length-1;
        }
        if(this.FromRecords==1 && response.taskListByStatusListOutPutModel.length<this.RecordsPerPage){
          this.prevnextbuttons=true;
          this.TotalLabelCountValue=response.taskListByStatusListOutPutModel.length
        }
        else if(this.FromRecords==1 && response.taskListByStatusListOutPutModel.length==this.RecordsPerPage){
          this.prevnextbuttons=false;
          this.PreviousButtondisabled=true;
          this.NextButtonDisabled=false;
        }
        else if(response.taskListByStatusListOutPutModel.length<this.RecordsPerPage){
          this.prevnextbuttons=false;
          this.NextButtonDisabled=true;
          this.PreviousButtondisabled=false;
        }
        else{
    
          this.prevnextbuttons=false;
          this.PreviousButtondisabled=false;
          this.NextButtonDisabled=false;
        }
    });
    
  }
  byStatusSelectPracticeIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Practice',
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
  });
  dialogRef.result.subscribe((result) => {
    if (result instanceof DialogCloseResult) {
    } else {
        this.practiceInformation = result;
    }
});
  }
  byStatusSelectResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select User(s)',
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.responsiblePersonInfo = result;
      }
    });
  }
  byStatusRemoveSelectedResponsiblePersonClickEvent(){
    this.responsiblePersonInfo={};
  }
  byStatusSelectCreatedbyIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Implementer',
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.implementedbyModel = result;
      }
    });
  }

    byStatusRemoveSelectedCreatedbyClickEvent(){
      this.implementedbyModel = {};
    }
    byStatusRemoveSelectedPracticeClickEvent(){
      this.practiceInformation={};
    }
  
  byStatusViewClickEvent(){

    let selectedTask=this.getSelectedTaskInfo()
    if (!this.hasValue(selectedTask)) return;
    const PostDataService: any = {
    IsFromEdit: true,
      editmodedata: selectedTask,
      isfrom:1//bystatus
    };
    PostDataService.StatemaintainPreviouslySearchFilters = {
      byImplementerSearchTaskNumber: this.byStatusSearchTaskNumber,
      byImplementerSearchTaskSubject: this.byStatusSearchTaskSubject,
      responsiblePersonInfo: this.responsiblePersonInfo,
      practiceInformation: this.practiceInformation,
      StatemaintainFromRecords :this.FromRecords,
      implementedbyModel:this.implementedbyModel,
      SearchStatusIDs:this.SearchStatusIDs,

      
    };
    const outletObj = {};
    outletObj['main'] = ['app-view-full-task-information', this.setCommonData(PostDataService)];
    this._router.navigate(['/home', { outlets: outletObj }]);
  }
  getSelectedTaskInfo(){
    const selectedrow= this.byStatusGrid.api.getSelectedRows();
    if(!this.hasValue(selectedrow)){
      this.ShowErrorMessage("Please Select Task.");
      document.getElementById("myAppointmentsSelectUser").focus();
      return 0;
    }
    return  selectedrow[0];
 

}
statemaintainPreviousSelectedRecord(){
  let seletedtasknumber=this.selectedTask
  let storeGridInfo=this.byStatusGrid.api
  setTimeout(() => {
    this.byStatusGrid.api.forEachLeafNode(function (node) {
      if (node.data.TaskNumber == seletedtasknumber) {
          node.setSelected(true);
          //Moving Scroll bar to Selected Node
          storeGridInfo.ensureIndexVisible(node.rowIndex+1);
      }
  });
  }, 500);
     
}
}
