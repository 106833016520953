import { Injectable } from '@angular/core';
import { commonHelperService } from '../common/common.service';
import { HttpClient } from '@angular/common/http';
import { User } from '../interfaces/User';
import { Config } from '../config';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})

/**
 * Service to login and signup
 */
export class AuthenticationService extends commonHelperService {

  constructor(http: HttpClient) {
    super(http);
  }

  validateUserLogin(userDetails: User) {
    return this.PostData(Config.OASOrganizationAppnURL + 'ValidateEmployeeLogin', userDetails,undefined,true)
      .pipe(map((response) => {
        // store the returned user details
        const { OrganizationId: OrganizationID, OrganizationName } = response;
        Config.OASOrganizationModel = { OrganizationID, OrganizationName };
        Config.OASOrganizationModel.LoggedUserID = response.EmployeeId;
        Config.OASOrganizationModel.LoggedUserEmailAddress = userDetails.EmailAddress;
        Config.OASOrganizationModel.LoginType = response.LoginType;
        Config.OASOrganizationModel.LoggedUserType = response.LoginType;
        Config.OASOrganizationModel.FirstName = response.FirstName;
        Config.OASOrganizationModel.LastName = response.LastName;
        Config.OASOrganizationModel.LoggedUserName = `${response.FirstName} ${response.LastName}`
        Config.OASOrganizationModel.LoggedUserAliasName = response.LoggedUserAliasName;
        return response;
      }));
  }

}
