import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oas-signup-component',
  templateUrl: './oas-signup-component.component.html',
  styleUrls: ['./oas-signup-component.component.css']
})
export class OasSignupComponentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
