
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';


@Injectable()
export class ViewFullTaskInformationService extends commonHelperService {
    constructor(http: HttpClient) {
        super(http);
    }

    UpdateRBDAndECD(postData: any) {
        return this.PostData(Config.OASAppnURL + 'UpdateRBDAndECD', postData).pipe(((response) => {
            return response;
        }));
    }

    InsertTaskCompletedStatusUpdatedToClientDate(postData: any) {
        return this.PostData(Config.OASAppnURL + 'InsertTaskCompletedStatusUpdatedToClientDate', postData).pipe(((response) => {
            return response;
        }));
    }
    sendRemindertoCustomers(postData: any) {
        return this.PostData(Config.OASAppnURL + 'sendRemindertoCustomers', postData).pipe(((response) => {
            return response;
        }));
    }
}
