import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef } from '@angular/core';
//import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { DialogService, DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { SelectModulePopupComponentComponent } from '../select-module-popup-component/select-module-popup-component.component';
import { PraticesListComponent } from '../pratices-list/pratices-list.component';
import { AdditionalParticipantAndObserversPopupComponentComponent } from '../additional-participant-and-observers-popup-component/additional-participant-and-observers-popup-component.component';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';
import { commonhelper } from '../common/commonhelper';
import { Attachment } from '../interfaces/attachment';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-new-template',
  templateUrl: './create-new-template.component.html',
  styleUrls: ['./create-new-template.component.css']
})
export class CreateNewTemplateComponent extends commonhelper implements OnInit {
  private commonhelperobj: commonHelperService;
  constructor(private dialogService: DialogService, private dialog: DialogRef, httpClient: HttpClient, private _Router: Router,) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }

  @ViewChild('CreateNewTemplateInfo') CreateTaskInfo: ElementRef;

  @ViewChild("CreateNewTemplateInfo", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  public defaultCreateNewTemplateSeverityItem = { SelectSeverity: '- Select -', SeverityID: 0 }
  public createNewTemplateSeverityList = [
    { SelectSeverity: 'High', SeverityID: 1 },
    { SelectSeverity: 'Normal', SeverityID: 2 },
    { SelectSeverity: 'Low', SeverityID: 3 },

  ];

  public TemplateCreateInfoModel: any = {};
  ModuleListInfo: any;
  SelectedTemplateInfo: any = {};
  fullattachmentsList: any;
  taskBodyList: any;
  MainBodyAttachmentsList: string;
  MainBodyImagesAttachmentsList: any[];
  imagesattachmentslist: any[];
  saveupdateButtonname: string;
  AttachmentsList: any[];
  public initialTaskBody = '';
  public createNewTemplateSelectedResponsiblePersonInfo: any = {};
  SelectedPracticeInfo: any = {};

  createTemplateSelectedAdditionalResponsiblePersonInfo: any;
  createTemplatePopupDataFromMultiSelectUserSelectionPopup: any;
  public createTemplateselectedAdditionalResponsiblePersons: any = [];
  createTemplateSelectedAdditionalResponsiblePersonName: string;
  createTemplateSelectedObserversInfo: any = [];
  createTemplateSelectedObserversPersonNames: string;
  createTemplateSelectedAssistantPersonInfo: any = [];
  createTemplateSelectedAssistantPersonName: string = "";
  IsresponsiblePersonAndFwdToSame: boolean = false;

  // public EmployeesAndRolesModelList: Array<string> = [];



  public EmployeesAndRolesModel: any = {};


  public defaultCreateNewTemplateTaskTypeItem = { SelectTaskType: '- Select -', SelectTaskTypeID: 0 }
  public createNewTemplateTaskTypeList = [
    { SelectTaskType: 'Enhancement / New Requirement', SelectTaskTypeID: 1 },
    { SelectTaskType: 'Bug / Issue', SelectTaskTypeID: 2 },
    { SelectTaskType: 'Enquiry', SelectTaskTypeID: 4 },
    { SelectTaskType: 'Form Change', SelectTaskTypeID: 5 },
    { SelectTaskType: 'Customization / Verfication', SelectTaskTypeID: 6 },

  ];
  public defaultCreateNewTemplateCurrentTaskFromItem = { createnewTemplateSelectTaskFromListText: '- Select -', createnewTemplateSelectTaskFromListTextValue: 0 }

  public taskCreateNewTemplateSelectTaskFromList = [
    { createnewTemplateSelectTaskFromListText: 'Call', createnewTemplateSelectTaskFromListTextValue: 1 },
    { createnewTemplateSelectTaskFromListText: 'Email', createnewTemplateSelectTaskFromListTextValue: 2 },
    { createnewTemplateSelectTaskFromListText: 'Live Chat', createnewTemplateSelectTaskFromListTextValue: 3 },
    { createnewTemplateSelectTaskFromListText: 'Sales Chat', createnewTemplateSelectTaskFromListTextValue: 4 },

  ];
  public defaultCreateNewTaskCurrentWorkingStatus = { createNewTaskCurrentWorkingStatus: '--Task Current Working Status-- ', createNewTaskCurrentWorkingStatusID: 0 }

  public createNewTemplateWorkingStatusList = [
    { createNewTaskCurrentWorkingStatus: 'Pending Approval', createNewTaskCurrentWorkingStatusID: 1 },
    { createNewTaskCurrentWorkingStatus: 'Approved', createNewTaskCurrentWorkingStatusID: 2 },
    { createNewTaskCurrentWorkingStatus: 'Approved Development Pending', createNewTaskCurrentWorkingStatusID: 3 },
    { createNewTaskCurrentWorkingStatus: 'Local Alpha Testing Pending', createNewTaskCurrentWorkingStatusID: 4 },
    { createNewTaskCurrentWorkingStatus: 'Review Pending - QA Lead', createNewTaskCurrentWorkingStatusID: 5 },
    { createNewTaskCurrentWorkingStatus: 'Review Pending - BA', createNewTaskCurrentWorkingStatusID: 6 },
    { createNewTaskCurrentWorkingStatus: 'Waiting for Gamma Release', createNewTaskCurrentWorkingStatusID: 7 },
    { createNewTaskCurrentWorkingStatus: 'Waiting for Alpha Release', createNewTaskCurrentWorkingStatusID: 8 },
    { createNewTaskCurrentWorkingStatus: 'Waiting for Beta Release', createNewTaskCurrentWorkingStatusID: 9 },
    { createNewTaskCurrentWorkingStatus: 'Waiting for Productions Release', createNewTaskCurrentWorkingStatusID: 10 },
    { createNewTaskCurrentWorkingStatus: 'Status Updated to Client', createNewTaskCurrentWorkingStatusID: 11 },
    { createNewTaskCurrentWorkingStatus: 'Denied by Domain Team', createNewTaskCurrentWorkingStatusID: 12 },
    { createNewTaskCurrentWorkingStatus: 'Denied and Alternative Suggested', createNewTaskCurrentWorkingStatusID: 13 },
    { createNewTaskCurrentWorkingStatus: 'Denied by Technical Team', createNewTaskCurrentWorkingStatusID: 14 },
    { createNewTaskCurrentWorkingStatus: 'Denied and Alternative Suggested - Technical Team', createNewTaskCurrentWorkingStatusID: 15 },
    { createNewTaskCurrentWorkingStatus: 'Denied and Alternative Suggested - Domain Team', createNewTaskCurrentWorkingStatusID: 16 },
    { createNewTaskCurrentWorkingStatus: 'From Changes Assigned to HTML Team', createNewTaskCurrentWorkingStatusID: 18 },
    { createNewTaskCurrentWorkingStatus: 'Easy Form Changes Completed-Review Pending BA', createNewTaskCurrentWorkingStatusID: 19 },
    { createNewTaskCurrentWorkingStatus: 'Reassigned Form Changes to HTML Team', createNewTaskCurrentWorkingStatusID: 20 },
    { createNewTaskCurrentWorkingStatus: 'CC Customization Completed - Review Pending by BA', createNewTaskCurrentWorkingStatusID: 21 },
    { createNewTaskCurrentWorkingStatus: 'Charge Capture Customization Assigned to CC Team', createNewTaskCurrentWorkingStatusID: 22 },
    { createNewTaskCurrentWorkingStatus: 'New Easy Form Assigned to HTML', createNewTaskCurrentWorkingStatusID: 23 },
    { createNewTaskCurrentWorkingStatus: 'New Easy Form Design Completed - Review Pending BA', createNewTaskCurrentWorkingStatusID: 24 },
    { createNewTaskCurrentWorkingStatus: 'Reassigned CC Customization to CC Team', createNewTaskCurrentWorkingStatusID: 25 },
    { createNewTaskCurrentWorkingStatus: 'Call Back Pending', createNewTaskCurrentWorkingStatusID: 26 },
    { createNewTaskCurrentWorkingStatus: 'Call Back Completed', createNewTaskCurrentWorkingStatusID: 27 },
    { createNewTaskCurrentWorkingStatus: 'Review-postponed', createNewTaskCurrentWorkingStatusID: 28 },
  ];
  ngOnInit() {
    setTimeout(function () {
      document.getElementById("txtCreateNewTaskSearchTemplate").focus();
    }, 10);
    this.saveupdateButtonname = "Save Template"
    if (this.SelectedTemplateInfo.TemplateID) {
      this.saveupdateButtonname = "Update Template"
      this.SelectedTemplateInfotoStatemaintain();
      this.UsersSelectedTemplateInfotoStatemaintain();
      this.UsersSelectedAttachmentsTemplateInfotoStatemaintain();
    }

  }
  CancelClickEvent() {
    this.dialog.close();
  }
  CreateNewTemplatemoduleIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Module',
      // Show component
      content: SelectModulePopupComponentComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.TemplateCreateInfoModel.ModuleName)) {

        } else {
          this.TemplateCreateInfoModel.ModuleName = '';
        }
      } else {
        this.ModuleListInfo = result;
        // console.log(this.ModuleListInfo,"data")
        if (this.hasValue(this.ModuleListInfo)) {
          this.TemplateCreateInfoModel.ModuleId = this.ModuleListInfo.IssueSupportGroupID;
          this.TemplateCreateInfoModel.ModuleName = this.ModuleListInfo.IssueSupportGroupDesc;
        }
      }
    });
  }


  createNewTemplatePracticeIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Practice',
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.TemplateCreateInfoModel.PracticeName)) {

        } else {
          this.TemplateCreateInfoModel.PracticeName = '';
        }
      } else {
        this.SelectedPracticeInfo = result;
        if (this.hasValue(this.SelectedPracticeInfo)) {
          this.TemplateCreateInfoModel.PracticeName = this.SelectedPracticeInfo.PracticeName;
          this.TemplateCreateInfoModel.EHRPracticeID = this.SelectedPracticeInfo.PracticeId;
        }
      }
    });
  }



  createNewTemplateResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Responsible Person',
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.createNewTemplateSelectedResponsiblePersonInfo)) {

        } else {
          this.createNewTemplateSelectedResponsiblePersonInfo = '';
        }
      } else {
        this.createNewTemplateSelectedResponsiblePersonInfo = result;
        if (this.hasValue(this.createNewTemplateSelectedResponsiblePersonInfo)) {
          this.TemplateCreateInfoModel.SelectedResponsiblePerson = this.createNewTemplateSelectedResponsiblePersonInfo.EmployeeId;
        }
      }
    });

  }



  createNewTemplateAdditionalResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select User(s)',
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData = this.createTemplateselectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData = this.createTemplateSelectedObserversInfo;
    // dialogRef.content.instance.StateMaintainAssistantPersonsData = this.createTemplateSelectedAssistantPersonInfo;

    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.createTemplateSelectedAdditionalResponsiblePersonInfo)) {

        } else {
          this.createTemplateSelectedAdditionalResponsiblePersonInfo = '';
        }
      } else {
        //assign popup result data to global variable
        this.createTemplatePopupDataFromMultiSelectUserSelectionPopup = result;

        //********assigning addl responsible resons info************
        this.createTemplateSelectedAdditionalResponsiblePersonInfo = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.createTemplateselectedAdditionalResponsiblePersons = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.createTemplateSelectedAdditionalResponsiblePersonInfo)) {
          this.createTemplateSelectedAdditionalResponsiblePersonName = "";
          this.createTemplateSelectedAdditionalResponsiblePersonInfo.forEach((selectedRow, index) => {
            this.createTemplateSelectedAdditionalResponsiblePersonName += selectedRow.EmployeeFullName + ', ';
          });
          this.createTemplateSelectedAdditionalResponsiblePersonName = this.createTemplateSelectedAdditionalResponsiblePersonName.substring(0, this.createTemplateSelectedAdditionalResponsiblePersonName.length - 2);
        }
        else {
          this.createTemplateSelectedAdditionalResponsiblePersonName = "";
        }

        //*****assigning observers data****************
        //this.createTemplateSelectedObserversInfo = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.createTemplateSelectedObserversInfo = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.createTemplateSelectedObserversInfo)) {
          this.createTemplateSelectedObserversPersonNames = "";
          this.createTemplateSelectedObserversInfo.forEach((selectedRow, index) => {
            this.createTemplateSelectedObserversPersonNames += selectedRow.EmployeeFullName + ', ';
          });
          this.createTemplateSelectedObserversPersonNames = this.createTemplateSelectedObserversPersonNames.substring(0, this.createTemplateSelectedObserversPersonNames.length - 2);
        }
        else {
          this.createTemplateSelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        // this.createTemplateSelectedAssistantPersonInfo = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        // this.createTemplateSelectedAssistantPersonName = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        // if (this.hasValue(this.createTemplateSelectedAssistantPersonInfo)) {
        //   this.createTemplateSelectedAssistantPersonName = "";
        //   this.createTemplateSelectedAssistantPersonInfo.forEach((selectedRow, index) => {
        //     this.createTemplateSelectedAssistantPersonName += selectedRow.EmployeeFullName + ', ';
        //   });
        //   this.createTemplateSelectedAssistantPersonName = this.createTemplateSelectedAssistantPersonName.substring(0, this.createTemplateSelectedAssistantPersonName.length - 2);
        // }
        // else {
        //   this.createTemplateSelectedAssistantPersonName = "";
        // }


      }
    });


  }






  createNewTemplateObserversIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select User(s)',
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData = this.createTemplateselectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData = this.createTemplateSelectedObserversInfo;
    // dialogRef.content.instance.StateMaintainAssistantPersonsData = this.createTemplateSelectedAssistantPersonInfo;

    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.createTemplateSelectedAdditionalResponsiblePersonInfo)) {

        } else {
          this.createTemplateSelectedAdditionalResponsiblePersonInfo = '';
        }
      } else {
        //assign popup result data to global variable
        this.createTemplatePopupDataFromMultiSelectUserSelectionPopup = result;

        //********assigning addl responsible resons info************
        this.createTemplateSelectedAdditionalResponsiblePersonInfo = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.createTemplateselectedAdditionalResponsiblePersons = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.createTemplateSelectedAdditionalResponsiblePersonInfo)) {
          this.createTemplateSelectedAdditionalResponsiblePersonName = "";
          this.createTemplateSelectedAdditionalResponsiblePersonInfo.forEach((selectedRow, index) => {
            this.createTemplateSelectedAdditionalResponsiblePersonName += selectedRow.EmployeeFullName + ', ';
          });
          this.createTemplateSelectedAdditionalResponsiblePersonName = this.createTemplateSelectedAdditionalResponsiblePersonName.substring(0, this.createTemplateSelectedAdditionalResponsiblePersonName.length - 2);
        }
        else {
          this.createTemplateSelectedAdditionalResponsiblePersonName = "";
        }

        //*****assigning observers data****************
        //this.createTemplateSelectedObserversInfo = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.createTemplateSelectedObserversInfo = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.createTemplateSelectedObserversInfo)) {
          this.createTemplateSelectedObserversPersonNames = "";
          this.createTemplateSelectedObserversInfo.forEach((selectedRow, index) => {
            this.createTemplateSelectedObserversPersonNames += selectedRow.EmployeeFullName + ', ';
          });
          this.createTemplateSelectedObserversPersonNames = this.createTemplateSelectedObserversPersonNames.substring(0, this.createTemplateSelectedObserversPersonNames.length - 2);
        }
        else {
          this.createTemplateSelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        // this.createTemplateSelectedAssistantPersonInfo = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        // this.createTemplateSelectedAssistantPersonName = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        // if (this.hasValue(this.createTemplateSelectedAssistantPersonInfo)) {
        //   this.createTemplateSelectedAssistantPersonName = "";
        //   this.createTemplateSelectedAssistantPersonInfo.forEach((selectedRow, index) => {
        //     this.createTemplateSelectedAssistantPersonName += selectedRow.EmployeeFullName + ', ';
        //   });
        //   this.createTemplateSelectedAssistantPersonName = this.createTemplateSelectedAssistantPersonName.substring(0, this.createTemplateSelectedAssistantPersonName.length - 2);
        // }
        // else {
        //   this.createTemplateSelectedAssistantPersonName = "";
        // }


      }
    });


  }





  // createNewTemplateassistantPersonIconClickEvent() {
  //   const dialogRef = this.dialogService.open({
  //     title: 'Select Person to Forward Task',
  //     // Show component
  //     content: AdditionalParticipantAndObserversPopupComponentComponent,
  //     height: 720,
  //     width: 1140,
  //     appendTo: this.ContainerRef
  //   });
  //   dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData = this.createTemplateselectedAdditionalResponsiblePersons;
  //   dialogRef.content.instance.StateMaintainObserverPersonsData = this.createTemplateSelectedObserversInfo;
  //   dialogRef.content.instance.StateMaintainAssistantPersonsData = this.createTemplateSelectedAssistantPersonInfo;

  //   dialogRef.result.subscribe((result) => {
  //     //debugger;
  //     if (result instanceof DialogCloseResult) {
  //       if (this.hasValue(this.createTemplateSelectedAdditionalResponsiblePersonInfo)) {

  //       } else {
  //         this.createTemplateSelectedAdditionalResponsiblePersonInfo = '';
  //       }
  //     } else {
  //       //assign popup result data to global variable
  //       this.createTemplatePopupDataFromMultiSelectUserSelectionPopup = result;

  //       //********assigning addl responsible resons info************
  //       this.createTemplateSelectedAdditionalResponsiblePersonInfo = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
  //       this.createTemplateselectedAdditionalResponsiblePersons = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
  //       if (this.hasValue(this.createTemplateSelectedAdditionalResponsiblePersonInfo)) {
  //         this.createTemplateSelectedAdditionalResponsiblePersonName = "";
  //         this.createTemplateSelectedAdditionalResponsiblePersonInfo.forEach((selectedRow, index) => {
  //           this.createTemplateSelectedAdditionalResponsiblePersonName += selectedRow.EmployeeFullName + ', ';
  //         });
  //         this.createTemplateSelectedAdditionalResponsiblePersonName = this.createTemplateSelectedAdditionalResponsiblePersonName.substring(0, this.createTemplateSelectedAdditionalResponsiblePersonName.length - 2);
  //       }
  //       else {
  //         this.createTemplateSelectedAdditionalResponsiblePersonName = "";
  //       }

  //       //*****assigning observers data****************
  //       //this.createTemplateSelectedObserversInfo = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
  //       this.createTemplateSelectedObserversInfo = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
  //       if (this.hasValue(this.createTemplateSelectedObserversInfo)) {
  //         this.createTemplateSelectedObserversPersonNames = "";
  //         this.createTemplateSelectedObserversInfo.forEach((selectedRow, index) => {
  //           this.createTemplateSelectedObserversPersonNames += selectedRow.EmployeeFullName + ', ';
  //         });
  //         this.createTemplateSelectedObserversPersonNames = this.createTemplateSelectedObserversPersonNames.substring(0, this.createTemplateSelectedObserversPersonNames.length - 2);
  //       }
  //       else {
  //         this.createTemplateSelectedObserversPersonNames = "";
  //       }

  //       //************assigning assistant persons info*************************
  //       this.createTemplateSelectedAssistantPersonInfo = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
  //       this.createTemplateSelectedAssistantPersonName = this.createTemplatePopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
  //       if (this.hasValue(this.createTemplateSelectedAssistantPersonInfo)) {
  //         this.createTemplateSelectedAssistantPersonName = "";
  //         this.createTemplateSelectedAssistantPersonInfo.forEach((selectedRow, index) => {
  //           this.createTemplateSelectedAssistantPersonName += selectedRow.EmployeeFullName + ', ';
  //         });
  //         this.createTemplateSelectedAssistantPersonName = this.createTemplateSelectedAssistantPersonName.substring(0, this.createTemplateSelectedAssistantPersonName.length - 2);
  //       }
  //       else {
  //         this.createTemplateSelectedAssistantPersonName = "";
  //       }


  //     }
  //   });

  // }


  async SaveTemplateDetails() {

    //#region " Validation Block"



    if (!this.hasValue(this.TemplateCreateInfoModel.TemplateName)) {
      this.ShowErrorMessage('Please Enter Template Name');
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector('[name="' + 'CreateTemplateName' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.focus();// elementFound.children[0].focus();
      }
      return;
    }

    if (!this.hasValue(this.TemplateCreateInfoModel.ModuleId)) {
      this.ShowErrorMessage('Please Select Module');
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector('[name="' + 'CreateNewTemplateModuleName' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.focus();// elementFound.children[0].focus();
      }
      return;
    }

    if (!this.hasValue(this.TemplateCreateInfoModel.IssueSubject)) {
      this.ShowErrorMessage('Please Enter Task Subject');
      this.CreateTaskInfo.nativeElement.querySelector('[name="' + 'txtcreatenewTemplateSubjectName' + '"]').focus();
      return;
    }
    if (!this.hasValue(this.TemplateCreateInfoModel.Task_Current_Working_Status_InfoID) || this.TemplateCreateInfoModel.Task_Current_Working_Status_InfoID <= 0) {
      this.ShowErrorMessage('Please Select Current Working Location');
      //this.CreateTaskInfo.nativeElement.querySelector('#TaskAddNewCurrentWokringStatus').focus();
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector('[name="' + 'createNewTemplateTaskCurrentStatus' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.focus();// elementFound.children[0].focus();


      }
      return;
    }


    if (!this.hasValue(this.TemplateCreateInfoModel.SelectedTaskBody)) {
      this.ShowErrorMessage('Please Enter Task Details');
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector('[name="' + 'createNewTemplateTaskAddNewTaskDetails' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[1].children[0].focus();
      }
      return;
    }

    if (!this.hasValue(this.TemplateCreateInfoModel.SelectedResponsiblePerson)) {
      this.ShowErrorMessage('Please Select Responsible Person.');
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector('[name="' + 'createNewTemplateAddNewResponsiblePerson' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }

    // if (this.hasValue(this.createTemplateSelectedAssistantPersonInfo) && this.createTemplateSelectedAssistantPersonInfo.length > 0) {
    //   this.createTemplateSelectedAssistantPersonInfo.forEach((selectedRow, index) => {
    //     if (this.TemplateCreateInfoModel.SelectedResponsiblePerson == selectedRow.EmployeeId) {
    //       this.IsresponsiblePersonAndFwdToSame = true;
    //     }
    //   });
    // }

    // if (this.IsresponsiblePersonAndFwdToSame == true) {
    //   this.ShowErrorMessage('Responsible Person and Forward To Should not be Same.');
    //   this.IsresponsiblePersonAndFwdToSame = false;
    //   return;
    // }


    //#endregion


    //#region "  Task/ Template Body Information getting Block "


    if (this.hasValue(this.TemplateCreateInfoModel.SelectedTaskBody)) {

      const body: Attachment = {
        AttachmentBase64Format: window.btoa(unescape(encodeURIComponent(this.TemplateCreateInfoModel.SelectedTaskBody))),
        AttachmentType: 1,
      };
      this.TemplateCreateInfoModel.AttachmentsModelList = [];
      this.TemplateCreateInfoModel.AttachmentsModelList.push(body)
    }




    //#endregion
    //if (this.hasValue(this.TemplateCreateInfoModel.TaskTypeId)) {
    //this.TemplateCreateInfoModel.TaskTypeId = this.TemplateCreateInfoModel.TaskTypeId;
    //}
    //else {
    //this.TemplateCreateInfoModel.TaskTypeId = undefined;
    //}

    if (this.hasValue(this.TemplateCreateInfoModel.Task_Current_Working_Status_InfoID)) {
      this.TemplateCreateInfoModel.TaskCurrentWorkingStatusInfoID = this.TemplateCreateInfoModel.Task_Current_Working_Status_InfoID;
    }

    if (this.hasValue(this.TemplateCreateInfoModel.TaskCamefromType)) {
      this.TemplateCreateInfoModel.TaskCamefromType = this.TemplateCreateInfoModel.TaskCamefromType.createnewTemplateSelectTaskFromListTextValue;
    }

    this.TemplateCreateInfoModel.EmployeesAndRolesModelList = [];


    //Additional Responsible Person Information 
    if (this.hasValue(this.createTemplateselectedAdditionalResponsiblePersons) && this.createTemplateselectedAdditionalResponsiblePersons.length > 0) {
      this.createTemplateselectedAdditionalResponsiblePersons.forEach(element => {
        this.EmployeesAndRolesModel = {
          EmployeeId: element.EmployeeId,
          RoleID: 4,
        };

        this.TemplateCreateInfoModel.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }




    //Observers Person Information
    if (this.hasValue(this.createTemplateSelectedObserversInfo) && this.createTemplateSelectedObserversInfo.length > 0) {
      this.createTemplateSelectedObserversInfo.forEach(element => {
        this.EmployeesAndRolesModel = {
          EmployeeId: element.EmployeeId,
          RoleID: 3,
        };

        this.TemplateCreateInfoModel.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }


    // Responsible Person Information
    if (this.hasValue(this.TemplateCreateInfoModel.SelectedResponsiblePerson)) {
      this.EmployeesAndRolesModel = {
        EmployeeId: this.TemplateCreateInfoModel.SelectedResponsiblePerson,
        RoleID: 2,
      };
      this.TemplateCreateInfoModel.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);

    }


    //Forwarded Person information
    // if (this.hasValue(this.createTemplateSelectedAssistantPersonInfo) && this.createTemplateSelectedAssistantPersonInfo.length > 0) {
    //   this.createTemplateSelectedAssistantPersonInfo.forEach(Assistantselement => {
    //     this.EmployeesAndRolesModel = {
    //       EmployeeId: Assistantselement.EmployeeId,
    //       RoleID: 7,
    //     };

    //     this.TemplateCreateInfoModel.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
    //   });
    // }

    if (this.TemplateCreateInfoModel.EmployeesAndRolesModelList.length == 0) {
      this.TemplateCreateInfoModel.EmployeesAndRolesModelList = undefined;
    }
    this.TemplateCreateInfoModel.TaskInfoID = this.SelectedTemplateInfo.TemplateID;
    this.commonhelperobj.PostData(Config.OASAppnURL + 'SaveCreateNewTemplateofOASIssueDetails', this.TemplateCreateInfoModel).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) {
        document.getElementById("txtCreateNewTaskSearchTemplate").focus();
        this.TemplateCreateInfoModel.EmployeesAndRolesModelList = [];
        this.TemplateCreateInfoModel.AttachmentsModelList = [];
        return;
      }
      if (!this.hasValue(this.SelectedTemplateInfo.TemplateID)) {
        this.ShowSuccesseMessage('Template Saved Successfully.');

        this.dialog.close();
        const PostDataService = {};
        const outletObj = {};
        outletObj['main'] = ['app-create-new-task-component', this.setCommonData(PostDataService)];
        this._Router.navigate(['/home', { outlets: outletObj }]);

      }
      else {
        this.dialog.close("Success");
      }

    });

  }
  public createNewTemplateRemoveResponsiblePersonInfo() {

    this.createNewTemplateSelectedResponsiblePersonInfo = "";
    this.TemplateCreateInfoModel.SelectedResponsiblePerson = "";
  }
  public createNewTemplateRemoveAdditionalResponsiblePersonInfo() {
    this.createTemplateSelectedAdditionalResponsiblePersonName = "";
    this.createTemplateselectedAdditionalResponsiblePersons = undefined;
  }
  public createNewTemplateRemoveObserversIconClickEvent() {
    this.createTemplateSelectedObserversPersonNames = "";
    this.createTemplateSelectedObserversInfo = undefined;
  }
  // public createNewTemplateRemoveAssistantPersonInfo() {
  //   this.createTemplateSelectedAssistantPersonName = "";
  //   this.createTemplateSelectedAssistantPersonInfo = undefined;
  // }
  public CreateNewTemplateRemoveModuleInfo() {

    this.TemplateCreateInfoModel.ModuleId = undefined;
    this.TemplateCreateInfoModel.ModuleName = '';



  }
  private SelectedTemplateInfotoStatemaintain() {
    var PostDataToService = {
      TemplateID: this.SelectedTemplateInfo.TemplateID
    };
    this.commonhelperobj.PostData(Config.OASAppnURL + 'GetOASSlectedTemplateInfo', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      if (this.hasValue(serviceResponse) && this.hasValue(serviceResponse.oasissuereportfromcustomermodel)) {
        this.CreateNewTaskStateMaintain(serviceResponse.oasissuereportfromcustomermodel);
      }

    });

  }
  private CreateNewTaskStateMaintain(oasissuereportfromcustomermodel) {
    this.TemplateCreateInfoModel.ModuleId = oasissuereportfromcustomermodel.ModuleId;
    this.TemplateCreateInfoModel.ModuleName = oasissuereportfromcustomermodel.ModuleDescription;
    this.TemplateCreateInfoModel.IssueSubject = oasissuereportfromcustomermodel.IssueSubject;
    this.TemplateCreateInfoModel.TemplateName = oasissuereportfromcustomermodel.TemplateName;
    if (this.hasValue(oasissuereportfromcustomermodel.TaskCurrentWorkingStatusInfoID) && oasissuereportfromcustomermodel.TaskCurrentWorkingStatusInfoID > 0)
      this.TemplateCreateInfoModel.Task_Current_Working_Status_InfoID = oasissuereportfromcustomermodel.TaskCurrentWorkingStatusInfoID//{Task_Current_Working_Status_InfoID:oasissuereportfromcustomermodel.TaskCurrentWorkingStatusInfoID, Task_Current_Working_Status:oasissuereportfromcustomermodel.TaskCurrentWorkingStatus}
    if (this.hasValue(oasissuereportfromcustomermodel.TaskTypeId) && oasissuereportfromcustomermodel.TaskTypeId > 0)
      this.TemplateCreateInfoModel.TaskTypeId = oasissuereportfromcustomermodel.TaskTypeId;
    if (this.hasValue(oasissuereportfromcustomermodel.TaskCamefromType) && oasissuereportfromcustomermodel.TaskCamefromType > 0)
      this.TemplateCreateInfoModel.TaskCamefromType = { createnewTemplateSelectTaskFromListText: oasissuereportfromcustomermodel.TaskCamefromTypeDescription, createnewTemplateSelectTaskFromListTextValue: oasissuereportfromcustomermodel.TaskCamefromType }
    if (this.hasValue(oasissuereportfromcustomermodel.TaskSeverityID) && oasissuereportfromcustomermodel.TaskSeverityID > 0)
      this.TemplateCreateInfoModel.IssueSeverityID = oasissuereportfromcustomermodel.TaskSeverityID
  }
  private UsersSelectedTemplateInfotoStatemaintain() {
    var PostDataToService = {
      TemplateInfoID: this.SelectedTemplateInfo.TemplateID
    };
    this.commonhelperobj.PostData(Config.OASAppnURL + 'OasTemplateLinkedUsers', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      if (this.hasValue(serviceResponse)) {
        this.CreateNewTaskStateMaintainofUsers(serviceResponse);
      }

    });

  }

  private CreateNewTaskStateMaintainofUsers(response) {
    response.forEach(TaskListelement => {
      if (this.hasValue(TaskListelement.TaskTypeId)) {
        if (TaskListelement.TaskTypeId == 2) {

          // this.TemplateCreateInfoModel.EmployeeFullName = TaskListelement.EmployeeFullName;
          this.TemplateCreateInfoModel.SelectedResponsiblePerson = TaskListelement.EmployeeId;
          this.createNewTemplateSelectedResponsiblePersonInfo.EmployeeFullName = TaskListelement.EmployeeFullName;

        }
        else if (TaskListelement.TaskTypeId == 3) {
          if (!this.hasValue(this.createTemplateSelectedObserversPersonNames)) {
            this.createTemplateSelectedObserversPersonNames = TaskListelement.EmployeeFullName;
          }
          else {
            this.createTemplateSelectedObserversPersonNames += ', ' + TaskListelement.EmployeeFullName
          }
          this.createTemplateSelectedObserversInfo.push({ EmployeeId: TaskListelement.EmployeeId, EmployeeFullName: TaskListelement.EmployeeFullName })
        }
        // else if (TaskListelement.TaskTypeId == 7) {
        //   if (!this.hasValue(this.createTemplateSelectedAssistantPersonName)) {
        //     this.createTemplateSelectedAssistantPersonName = TaskListelement.EmployeeFullName;
        //   }
        //   else {
        //     this.createTemplateSelectedAssistantPersonName += ', ' + TaskListelement.EmployeeFullName;
        //   }
        //   this.createTemplateSelectedAssistantPersonInfo.push({ EmployeeId: TaskListelement.EmployeeId, EmployeeFullName: TaskListelement.EmployeeFullName })
        // }
        else {
          if (!this.hasValue(this.createTemplateSelectedAdditionalResponsiblePersonName)) {
            this.createTemplateSelectedAdditionalResponsiblePersonName = TaskListelement.EmployeeFullName;
          }
          else {
            this.createTemplateSelectedAdditionalResponsiblePersonName += ', ' + TaskListelement.EmployeeFullName
          }
          this.createTemplateselectedAdditionalResponsiblePersons.push({ EmployeeId: TaskListelement.EmployeeId, EmployeeFullName: TaskListelement.EmployeeFullName })
        }
      }
    })


  }


  private UsersSelectedAttachmentsTemplateInfotoStatemaintain() {
    var PostDataToService = {
      TemplateInfoID: this.SelectedTemplateInfo.TemplateID
    };
    this.commonhelperobj.PostData(Config.OASAppnURL + 'OasTemplateAttachmentList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      if (this.hasValue(serviceResponse) && this.hasValue(serviceResponse.AttachmentsModelList)) {
        //this.CreateNewTaskStateMaintainofAttachmentList(serviceResponse);
        this.fullattachmentsList = serviceResponse.AttachmentsModelList;

        this.taskBodyList = this.fullattachmentsList.filter(item => item.AttachmentType === 1 && item.AttachmentUrlSequence === 0);


        this.commonhelperobj.GetStringBytesFromSignedURL(this.taskBodyList[0].AttachmentURL,
          'text/html').subscribe(successdata2 => {
            this.MainBodyAttachmentsList = '<div>' + successdata2 + '</div>';
            this.MainBodyImagesAttachmentsList = [];
            this.imagesattachmentslist = [];
            this.AttachmentsList = [];
            const GetMainBodyAttachmentsList = $(this.MainBodyAttachmentsList);
            const collectionofAttachments = GetMainBodyAttachmentsList.find('img');
            //GetMainBodyAttachmentsList.find('img').remove();
            let collectionofimgsLength2: any;
            collectionofimgsLength2 = collectionofAttachments.length;
            const elementfieldmainbody = {
              Imgaetag: null,
              attachmentType: null
            };
            const mainbodyinfo = GetMainBodyAttachmentsList.html();

            this.TemplateCreateInfoModel.SelectedTaskBody = this.initialTaskBody = mainbodyinfo;


          });

      }

    });
  }
}