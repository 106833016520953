import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { UploadState } from '../enums/upload-state.enum';

@Injectable({
  providedIn: 'root'
})
export class FileUploadProgressService {


  public uploadSubject = new BehaviorSubject<UploadState>(UploadState.notYetUploaded);

  constructor() { }

  public setUploadState(uploadState: UploadState) {
    this.uploadSubject.next(uploadState);
  }

  public isValidState() {
    const currentUploadState = this.uploadSubject.value;

    return (
      currentUploadState === UploadState.removedAllFiles ||
      currentUploadState === UploadState.uploadComplete ||
      currentUploadState === UploadState.notYetUploaded
    );
  }

}

@Injectable({
  providedIn: 'root'
})
export class CommentFileUploadProgressService {


  public uploadSubject = new BehaviorSubject<UploadState>(UploadState.notYetUploaded);

  constructor() { }

  public setUploadState(uploadState: UploadState) {
    this.uploadSubject.next(uploadState);
  }

  public isValidState() {
    const currentUploadState = this.uploadSubject.value;

    return (
      currentUploadState === UploadState.removedAllFiles ||
      currentUploadState === UploadState.uploadComplete ||
      currentUploadState === UploadState.notYetUploaded
    );
  }

}



