import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
} from "@angular/core";
import { Config } from "../config";
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "@progress/kendo-angular-notification";
import { commonHelperService } from "../common/common.service";
import { commonhelper } from "../common/commonhelper";
import {
  DialogService,
  DialogCloseResult,
  DialogRef,
} from "@progress/kendo-angular-dialog";
import { PraticesListComponent } from "../pratices-list/pratices-list.component";
import { KendoButtonService } from "@progress/kendo-angular-buttons/dist/es2015/button/button.service";
import { TaskService } from "../services/task.service";
import { LeftNavService } from "../common/Leftnav.service";
import * as $ from "jquery";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { SelectEvent, FileInfo } from "@progress/kendo-angular-upload";
import { EmployeeService } from "../employee.service";
import { ResponsiblepersonUsersListComponent } from "../responsibleperson-users-list/responsibleperson-users-list.component";

@Component({
  selector: "app-edit-task-customization-based-on-users",
  templateUrl: "./edit-task-customization-based-on-users.component.html",
  styleUrls: ["./edit-task-customization-based-on-users.component.css"],
})
export class EditTaskCustomizationBasedOnUsersComponent
  extends commonhelper
  implements OnInit
{
  AddTaskCustomztnEmailAddress: any;
  FirstTimeLoad: boolean;
  @ViewChild("editTaskCustomztnInfo") editTaskCustomztnInfo: ElementRef;

  @ViewChild("editTaskCustomztnInfo", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  private commonhelperobj: commonHelperService;
  public editmodedata: any = {};
  public TaskCustomizationModel: any = {};
  private initialResponsiblePerson: any = {};
  // this objects holds the employees selected as participants while creating the view
  public selectedParticpants;
  public responsiblePerson: Employee;
  public responsiblePersonInfo: any = {};
  // this objects holds the employees selected as observers while creating the view
  public selectedObservers;
  public SelectedAssistantPerson;
  public PracticeID: any;
  public PracticeName: any;
  public supportgroup: any;
  public username: any;
  organizationEmployees: any;
  public editmodedatafromlist: any = {};
  public EmployeesAndRolesModelList: Array<any> = [];
  public selectedUsersList: Array<any> = [];
  public SelectedPracticeUserList: Array<any> = [];
  public divSelectedResponsiblePerson: HTMLElement;
  datatoPopup: any;
  constructor(
    httpClient: HttpClient,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private dialog: DialogRef,
    private taskService: TaskService,
    private LeftNav: LeftNavService,
    private employeeService: EmployeeService
  ) {
    super();
    // this.editmodedata = '';
    this.commonhelperobj = new commonHelperService(httpClient);
    // this.LeftNav.data.subscribe(value => {
    //   this.editmodedata = value;
    // });
    // alert(this.editmodedata);
  }

  ngOnInit() {
    if (this.datatoPopup !== undefined) {
      this.editmodedata = this.datatoPopup.senddatamodel;
      this.GetEditModeDataList();
    }

    // setTimeout(() => {
    // this.LeftNav.currentSelected.subscribe(value => {
    //   console.log(this.editmodedata)
    //   this.editmodedata = value;
    //   this.FirstTimeLoad = true;

    // });

    // }, 5);
    // this.GetEditModeDataList();
  }

  GetEditModeDataList() {
    const PostDataToService = {
      PracticeId:
        this.editmodedata.PracticeId != "" ? this.editmodedata.PracticeId : -1,
      IssueSupportGroupID: this.editmodedata.IssueSupportGroupID,
      EHRUserID: this.editmodedata.EHRUserID,
      OASPracticeUserID: this.editmodedata.OASPracticeUserID,
    };
    // const PostDataToService = {
    //   PracticeId: 999,
    //   IssueSupportGroupID: '2018090706023096',
    //   EHRUserID: 8,
    //   OASPracticeUserID: '2018101008515333',
    // };
    this.taskService
      .GetPracticeUsersData(PostDataToService)
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
        if (this.hasValue(response)) {
          this.editmodedatafromlist = response[0];
          // assigning practice name
          this.PracticeID =
            response[0].PracticeId != -1 ? response[0].PracticeId : "";
          this.PracticeName = response[0].PracticeName;
          this.supportgroup = response[0].IssueSupportGroupName;
          this.username = response[0].EmployeeName;
          // auto populate module dropdown
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:label-position
          // tslint:disable-next-line:max-line-length

          // this list contains all the empooyees related to this particluar task
          // Responsible Person, Observer, Participant
          const taskRelatedEmployees = response[0].TaskUsersInfo;
          const {
            responsiblePerson,
            taskObservers,
            taskParticipants,
            taskAssistant,
          } = this.parseEmployeeRoles(taskRelatedEmployees);
          // console.log(this.selectedParticpants)
          this.selectedParticpants = taskParticipants;
          this.selectedObservers = taskObservers;
          this.SelectedAssistantPerson = taskAssistant;
          // this.responsiblePerson = responsiblePerson;
          this.responsiblePerson = this.initialResponsiblePerson =
            responsiblePerson;
          // console.log(this.responsiblePerson);
          this.employeeList().subscribe(() => {});
        }
      });
  }

  parseEmployeeRoles(commaSeparatedEmployees: string) {
    const ROLE = 3;
    // declaration
    let responsiblePerson: Employee;
    const [taskObservers, taskParticipants, taskAssistant]: [
      Employee[],
      Employee[],
      Employee[]
    ] = [[], [], []];

    // get all employee details as list
    const allEmployees = commaSeparatedEmployees.split(",");

    // group employees by roles
    for (const relatedEmployee of allEmployees) {
      const employeeDetails = relatedEmployee.split("|");
      if (employeeDetails[ROLE] === "Responsible Person]") {
        responsiblePerson = this.parseEmployee(employeeDetails);
      } else if (employeeDetails[ROLE] === "Observer]") {
        taskObservers.push(this.parseEmployee(employeeDetails));
      } else if (employeeDetails[ROLE] === "Assistant]") {
        taskAssistant.push(this.parseEmployee(employeeDetails));
      } else {
        taskParticipants.push(this.parseEmployee(employeeDetails));
      }
    }

    return {
      responsiblePerson,
      taskObservers,
      taskParticipants,
      taskAssistant,
    };
  }
  parseEmployee(employeeDetails: string[]): Employee {
    const EMPLOYEE_ID = 0;
    const EMPLOYEE_NAME = 1;

    return {
      EmployeeId: employeeDetails[EMPLOYEE_ID].replace("[", ""),
      EmployeeFullName: employeeDetails[EMPLOYEE_NAME],
    };
  }
  CancelClickEvent() {
    this.dialog.close();
  }

  employeeList(): Observable<Array<any>> {
    const PostDataToService = {};
    return this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "OrganizationEmployeesList",
        PostDataToService
      )
      .pipe(
        map((response) =>
          this.checkForErrorAndAssingToEmployeeListAndReturn(response)
        )
      );
  }
  private checkForErrorAndAssingToEmployeeListAndReturn(response) {
    // check for response error
    if (this.isError(response)) {
      return [];
    }
    // assign to respective list
    this.organizationEmployees = response.EmployeeList;
    // and return
    return this.organizationEmployees;
  }

  // public responsiblePersonClose(): void {
  //   this.GetEditModeDataList();
  //   // this.employeeList().subscribe(() => { });
  // }
  public dropdownclose(): void {
    if (this.FirstTimeLoad === true && !this.hasValue(this.responsiblePerson)) {
      this.responsiblePerson = this.initialResponsiblePerson;
      this.FirstTimeLoad = false;
    }
    // console.log(this.responsiblePerson);
  }
  UpdateTaskCustomizationInfo() {
    // tslint:disable-next-line:no-unused-expression
    if (
      !this.hasValue(this.responsiblePerson) ||
      !this.hasValue(this.responsiblePerson.EmployeeId)
    ) {
      this.ShowErrorMessage("Please Select Responsible Person.");
      const elementFound =
        this.editTaskCustomztnInfo.nativeElement.querySelector(
          '[name="' + "selectedResponsiblePersonName" + '"]'
        );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      // tslint:disable-next-line:max-line-length
      // tslint: disable - next - line: max - line - length
      return false;
    }

    // if (!this.hasValue(this.selectedParticpants) && this.selectedParticpants.length <= 0) {
    //   this.ShowErrorMessage('Please Select Participants.');
    //   // this.divSelectedParticpants = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('#divSelectedParticpants');
    //   // this.divSelectedParticpants.focus();
    //   return false;
    // }

    // if (!this.hasValue(this.selectedObservers) && this.selectedObservers.length <= 0) {
    //   this.ShowErrorMessage('Please Select Observers.');
    //   // this.divSelectedObservers = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('#divSelectedObservers');
    //   // this.divSelectedObservers.focus();
    //   return false;
    // }

    if (
      this.hasValue(this.selectedParticpants) &&
      this.selectedParticpants.length > 0
    ) {
      this.selectedParticpants.forEach((element) => {
        const EmployeesAndRolesModel = {
          EmployeeID: element.EmployeeId,
          RoleID: 4,
        };

        this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
      });
    }

    if (
      this.hasValue(this.selectedObservers) &&
      this.selectedObservers.length > 0
    ) {
      this.selectedObservers.forEach((element) => {
        const EmployeesAndRolesModel = {
          EmployeeID: element.EmployeeId,
          RoleID: 3,
        };

        this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
      });
    }
    if (this.hasValue(this.responsiblePerson)) {
      const EmployeesAndRolesModel = {
        EmployeeID: this.responsiblePerson.EmployeeId,
        RoleID: 2,
      };
      this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
    }

    if (
      this.hasValue(this.SelectedAssistantPerson) &&
      this.SelectedAssistantPerson.length > 0
    ) {
      this.SelectedAssistantPerson.forEach((Assistantselement) => {
        const EmployeesAndRolesModel = {
          EmployeeId: Assistantselement.EmployeeId,
          RoleID: 7,
        };

        this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
      });
    }

    if (this.EmployeesAndRolesModelList.length > 0) {
      this.TaskCustomizationModel.EmployeesAndRolesModelList =
        this.EmployeesAndRolesModelList;
    }
    if (this.hasValue(this.editmodedatafromlist.OASPracticeUserID)) {
      this.TaskCustomizationModel.OASPracticeUserID =
        this.editmodedatafromlist.OASPracticeUserID;
    }
    if (this.hasValue(this.editmodedatafromlist.PracticeId)) {
      this.TaskCustomizationModel.PracticeId =
        this.editmodedatafromlist.PracticeId != ""
          ? this.editmodedatafromlist.PracticeId
          : -1;
    }
    if (this.hasValue(this.editmodedatafromlist.IssueSupportGroupID)) {
      this.TaskCustomizationModel.IssueSupportGroupID =
        this.editmodedatafromlist.IssueSupportGroupID;
    }
    this.SelectedPracticeUserList = [];

    // if (this.hasValue(this.editmodedatafromlist.EHRUserID)) {
    //   const singleEmailAddressModel = {
    //     UserID: this.editmodedatafromlist.EHRUserID
    //   };
    //   this.SelectedPracticeUserList.push(singleEmailAddressModel);
    // }
    if (this.hasValue(this.editmodedatafromlist.EHRUserID)) {
      this.SelectedPracticeUserList.push(this.editmodedatafromlist.EHRUserID);
    }

    if (
      this.hasValue(this.SelectedPracticeUserList) &&
      this.SelectedPracticeUserList.length > 0
    ) {
      this.SelectedPracticeUserList.forEach((element) => {
        const usersmodel = {
          EHRUserIDs: element,
        };

        this.selectedUsersList.push(usersmodel);
      });
    }

    if (
      this.hasValue(this.selectedUsersList) &&
      this.selectedUsersList.length > 0
    ) {
      this.TaskCustomizationModel.selectedUsersList = this.selectedUsersList;
    }

    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "InsertUserCustomizationInformationByUsers",
        this.TaskCustomizationModel
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.ShowSuccesseMessage("Customization Details Updated Successfully.");
        this.TaskCustomizationModel = {};
        this.EmployeesAndRolesModelList = [];
        this.selectedObservers = "";
        this.selectedParticpants = undefined;
        this.selectedObservers = undefined;
        this.responsiblePerson = undefined;
        this.editmodedatafromlist = [];
        this.dialog.close("update");
        this.SelectedPracticeUserList = [];
      });
  }

  onParticipantsChange() {
    this.selectedParticpants = this.employeeService.filterDuplicateEmployees(
      this.selectedParticpants
    );
  }

  onObserversChange() {
    this.selectedObservers = this.employeeService.filterDuplicateEmployees(
      this.selectedObservers
    );
  }

  onAssistantPersonChange() {
    this.SelectedAssistantPerson =
      this.employeeService.filterDuplicateEmployees(
        this.SelectedAssistantPerson
      );
  }

  responsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Responsible Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.responsiblePerson)) {
        } else {
        }
      } else {
        this.responsiblePersonInfo = result;
        if (this.hasValue(this.responsiblePersonInfo)) {
          this.responsiblePerson.EmployeeId =
            this.responsiblePersonInfo.EmployeeId;
          this.responsiblePerson.EmployeeFullName =
            this.responsiblePersonInfo.EmployeeFullName;
        }
      }
    });
  }

  RemoveResponsiblePersonInfo() {
    this.responsiblePerson.EmployeeId = undefined;
    this.responsiblePerson.EmployeeFullName = "";
  }
}
export interface Employee {
  EmployeeId: string;
  EmployeeFullName: string;
}
export interface PracticeEmployee {
  UserId: string;
  UserFullName: string;
  RoleID: string;
}
