import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { timingSafeEqual } from 'crypto';
import { promise } from 'selenium-webdriver';
import { commonhelper } from '../common/commonhelper';
import { NewCustomerMailsAddEditTemplatePopupComponent } from '../new-customer-mails-add-edit-template-popup/new-customer-mails-add-edit-template-popup.component';
import { NewCustomerMailsSelectTemplatePopupService } from './new-customer-mails-select-template-popup.service';

@Component({
  selector: 'app-new-customer-mails-select-template-popup',
  templateUrl: './new-customer-mails-select-template-popup.component.html',
  styleUrls: ['./new-customer-mails-select-template-popup.component.css']
})
export class NewCustomerMailsSelectTemplatePopupComponent extends commonhelper implements OnInit {
  @ViewChild('newCustomerMailsSelectTemplatePopupGrid') _customerMailsSelectTemplateGrid: AgGridNg2;
  @ViewChild('NewCustomerMailsSelectTemplatePopup', { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  templateName: string
  newCustomerMailsSelectTemplatePopupGridRowData: any = [];
  columnDefs = [
    { headerName: 'Select Template', field: 'TemplateName', width: 500, cellClass: 'no-border cell-wrap-text', lockPosition: true, autoHeight: true },
  ];
  onNewCustomerMailsSelectTemplatePopupGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  constructor(
    private _dialogService: DialogService,
    private _dialog: DialogRef,
    private _templateService: NewCustomerMailsSelectTemplatePopupService,
  ) { super(); }

  async ngOnInit() {
    await this.getTemplatesList();
    this.setFocusonField("newCustomerMailsSelectTemplatePopupSearchTemplate")
  }
  async getTemplatesList() {
    const response = await this._templateService.GetNewCustomerMailTemplatesList({ TemplateName: this.templateName }).toPromise();
    if (this.isError(response)) return;
    this.newCustomerMailsSelectTemplatePopupGridRowData = response.NewCustomerMailTemplateList
  }
  setFocusonField(id) {
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 100);
  }
  templateAddClick() {
    this.templateAddEditPopup("Add Template", "Added", 0);
  }
  templateEditClick() {
    let selectedTemplateId = this.getSelectedRecord()
    if (!selectedTemplateId) return;
    this.templateAddEditPopup("Edit Template", "Updated", selectedTemplateId)
  }
  private getSelectedRecord() {
    const selectedrow = this._customerMailsSelectTemplateGrid.api.getSelectedRows()[0];
    if (!this.hasValue(selectedrow) || selectedrow.NewCustomerMailTemplateInfoId <= 0) {
      this.ShowErrorMessage("Please Select Template");
      return false;
    }
    return selectedrow.NewCustomerMailTemplateInfoId
  }
  CancelClickEvent() {
    this._dialog.close();
  }
  OKClickEvent() {
    let selectedTemplateId = this.getSelectedRecord()
    if (!selectedTemplateId) return;
    this._dialog.close(selectedTemplateId)
  }
  templateAddEditPopup(titleName: string, Message: string, templateId: number) {
    const dialogRef = this._dialogService.open({
      title: titleName,
      // Show component
      content: NewCustomerMailsAddEditTemplatePopupComponent,
      height: 450,
      width: 680,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.SelectedTemplatedId = templateId;
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.ShowSuccesseMessage("Template " + Message + " Successfully");
          this.getTemplatesList();
        }
      }
    });
  }

  templateDeleteClick() {
    let selectedTemplateId = this.getSelectedRecord();
    if (!selectedTemplateId) return;
    const dialog: DialogRef = this._dialogService.open({
      title: 'Please Confirm',
      content: 'Are You Sure to Delete Template?',
      actions: [
        { text: 'No' },
        { text: 'Yes', primary: true }
      ],
      width: 450,
      height: 250,
      minWidth: 250,
      appendTo: this.ContainerRef
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        //console.log('close');
      } else {
        if (this.hasValue(result)) {
          if (result.text === 'No') return;
          this.deleteTemplate(selectedTemplateId)
        }

      }
    });
  }
  private async deleteTemplate(templateId) {
    const response = await this._templateService.DeleteNewCustomerMailTemplate({ NewCustomerMailTemplateInfoId: templateId }).toPromise();
    if (this.isError(response)) return;
    this.ShowSuccesseMessage("Template Deleted Successfully");
    this.getTemplatesList();
  }
}
