import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AttachmentService } from '../services/attachment.service';
import { FileTypeEnum } from '../enums/FileTypeEnum.enum';

@Component({
  selector: 'pdf-attachments',
  templateUrl: './pdf-attachments-display.component.html',
  styleUrls: ['./pdf-attachments-display.component.css']
})
export class PdfAttachmentsDisplayComponent implements OnInit {

  @Input('pdfAttachments')
  pdfAttachments

  @Input('showRemoveIcon')
  showRemoveIcon: boolean = false;

  @Input('showDocumentRemoveIcon')
  showDocumentRemoveIcon: boolean = false;

  @Input('showExcelRemoveIcon')
  showExcelRemoveIcon: boolean = false;


  @Output('delete')
  attachmentDeleteEventEmitter = new EventEmitter();
  attachmentDeleteEventEmitterForDcoument = new EventEmitter();
  attachmentDeleteEventEmitterForExcel = new EventEmitter();



  constructor(private attachmentService: AttachmentService) { }

  ngOnInit() {
    // console.log(this.showRemoveIcon);
    // console.log(this.pdfAttachments);
  }

  public  ShowRespectiveICon(pdf: any,attachemnttype:any) {

    if(pdf.AttachmentType===FileTypeEnum.pdf ){
        return "assets/images/pdfimage.png";
      
    }
    else if(pdf.AttachmentType===FileTypeEnum.DOC ||pdf.AttachmentType===FileTypeEnum.DOCX ){
      return "assets/images/document.png";
    
  }//""
  else if(pdf.AttachmentType===FileTypeEnum.XLS || pdf.AttachmentType===FileTypeEnum.XLSX ){
    return "assets/images/ExportToExcelIconBlue.png";
  
}

  }

  public async downloadPdf(pdf: any) {
    const pdfSource = await this.attachmentService.sourceFromUrl(pdf.AttachmentURL);

    this.attachmentService.downloadPdf(pdfSource,pdf);
  }

  public onPdfAttachmentDeleteClickEvent(index) {
    if (index >= 0) {
      this.attachmentDeleteEventEmitter.emit(index);
    }
  }

  // public async downloadDocument(document: any) {
  //   const documentSource = await this.attachmentService.sourceFromUrl(document.AttachmentURL);

  //   this.attachmentService.downloadPdf(documentSource);
  // }

  // public onDocumentAttachmentDeleteClickEvent(index) {
  //   if (index >= 0) {
  //     this.attachmentDeleteEventEmitter.emit(index);
  //   }
  // }

}
