import { Injectable } from "@angular/core";

@Injectable()
export class TaskBucketDetailsService {
  BucketNamesList = [
    { Name: "Login", ID: 1, isCategory: true },
    { Name: "Easy Forms", ID: 2, isCategory: true },
    { Name: "Erx Requets", ID: 3, isCategory: true },
    { Name: "Order Rx/Medications", ID: 14, isCategory: true },
    { Name: "Labs/X-Rays/Vaccines", ID: 4, isCategory: true },
    { Name: "Reports", ID: 5, isCategory: true },
    { Name: "Remainders", ID: 6, isCategory: true },
    { Name: "Fax/Document Auto Uploader", ID: 7, isCategory: true },
    { Name: "Document Management", ID: 8, isCategory: true },
    { Name: "Appointment Scheduler (1 to 1 & Group)", ID: 9, isCategory: true },
    { Name: "CCDA/MIPS/Quality Measures", ID: 10, isCategory: false },
    { Name: "Billing", ID: 11, isCategory: false },
    { Name: "New Enhacments", ID: 12, isCategory: false },
    { Name: "Admission Details", ID: 15, isCategory: true },
    { Name: "Bed Census", ID: 16, isCategory: true },
    { Name: "Demographics", ID: 17, isCategory: true },

    { Name: "New Client Registation", ID: 18, isCategory: true },
    { Name: "Other", ID: 13, isCategory: true },
  ];
  BucketSubCategoryList = [
    { Name: "Bug", ID: 1, BucketID: -1 },
    { Name: "Concerns", ID: 2, BucketID: -1 },
    { Name: "New Login", ID: 3, BucketID: 1 },
    { Name: "Users Inactivate/Suspended", ID: 4, BucketID: 1 },
    // { Name: "Easy Forms", ID: 5, BucketID: 2 },
    { Name: "New Easy Form Design ", ID: 6, BucketID: 2 },
    { Name: "Existing Form Changes", ID: 7, BucketID: 2 },
    { Name: "HCI Mapping ", ID: 8, BucketID: 2 },
    { Name: "Form Forwarding", ID: 9, BucketID: 2 },
    { Name: "Easy Form Permissions ", ID: 10, BucketID: 2 },
    { Name: "Erx/EPCS Enabling ", ID: 11, BucketID: 3 },
    { Name: "Interface", ID: 12, BucketID: 4 },
    { Name: "New Report", ID: 13, BucketID: 5 },
    { Name: "Existing Report Changes", ID: 14, BucketID: 5 },
    { Name: "New Report", ID: 15, BucketID: 6 },
    { Name: "Existing Reminder Changes", ID: 16, BucketID: 6 },
    { Name: "New Fax Integartion", ID: 17, BucketID: 7 },
  ];
}
