import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../interfaces/User';
import { AuthenticationService } from '../services/authentication';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';
import { commonhelper } from '../common/commonhelper';

@Component({
  selector: 'app-oas-login-component',
  templateUrl: './oas-login-component.component.html',
  styleUrls: ['./oas-login-component.component.css']
})
export class OasLoginComponentComponent extends commonhelper implements OnInit {


  @ViewChild('loginForm')
  loginForm: ElementRef;

  userLoginDetails: User = {};


  constructor(private _router: Router, private loginService: AuthenticationService, private common: commonHelperService) {
    super();
  }

  ngOnInit() {
    this.loginForm.nativeElement.querySelector('#email').focus();
    // this.userLoginDetails.EmailAddress = 'lakshmi.bathina@adaptamed.org';
    // this.userLoginDetails.Password = 'pa55w0rd!';
  }

  SignupClickEvent() {
    this._router.navigateByUrl('/Signup');
  }


  SigninClickEvent() {

    if (!this.hasValue(this.userLoginDetails.EmailAddress)) {
      this.common.ShowErrorMessage(`Please Enter Username`);
      this.loginForm.nativeElement.querySelector('#email').focus();
      return false;
    }

    if (!this.hasValue(this.userLoginDetails.Password)) {
      this.common.ShowErrorMessage(`Please Enter Password`);
      this.loginForm.nativeElement.querySelector('#password').focus();
      return false;
    }

    if (this.hasValue(this.userLoginDetails.EmailAddress)) {
      this.userLoginDetails.EmailAddress = this.userLoginDetails.EmailAddress.trim().toString();
    }

    const { valid, message } = this.loginDetailsValid(this.userLoginDetails);

    if (!valid) {
      this.common.ShowErrorMessage(message);
      return false;
    }

    this.loginService.validateUserLogin(this.userLoginDetails).subscribe(response => {
      if (this.loginService.isError(response)) {
        return false;
      }

      // invalid credentials
      if (!response.EmployeeId) {
        this.common.ShowErrorMessage(`Invalid Username or Password`);
        return false;
      }

      this._router.navigateByUrl('/home');
    });

  }

  /**
   * Validate employee username and password
   * @param userLoginDetails Object containing username and password of user
   * @returns true if given details are valid false otherwise
   * @author Sanjay Idpuganti
   */
  private loginDetailsValid(userLoginDetails: User): { valid: boolean, message: string } {


    if (Object.keys(userLoginDetails).length === 0) {
      return { valid: false, message: 'Invalid Login Details' };
    }

    if (!userLoginDetails.EmailAddress && this.isValidEmailAddress()) {
      return { valid: false, message: 'Invalid Email Address' };
    }

    if (!userLoginDetails.Password) {
      return { valid: false, message: 'Please enter password' };
    }

    return { valid: true, message: undefined };
  }

  /**
   * Validate user email address
   * @author Sanjay Idpuganti
   */
  private isValidEmailAddress(): boolean {
    const emailHTMLField = this.loginForm.nativeElement.querySelector('#email');
    return this.common.validateEmail(emailHTMLField);
  }

}



