
import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { formatDate } from '@angular/common';
import { DialogService, DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { PraticesListComponent } from '../pratices-list/pratices-list.component';
import { commonHelperService } from '../common/common.service';
import { HttpClient } from '@angular/common/http';
import { commonhelper } from '../common/commonhelper';
import { LeftNavService } from '../common/Leftnav.service';
import { Config } from '../config';
import { PracticeUsersListComponent } from '../practice-users-list/practice-users-list.component';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';
import { config } from 'process';
import { _ } from 'ag-grid-community';
import { EmployeesCustomizationComponent } from '../employees-customization/employees-customization.component';
import { PracticeUsersCustomizationComponent } from '../practice-users-customization/practice-users-customization.component';

@Component({
  selector: 'app-add-edit-appointments-popup',
  templateUrl: './add-edit-appointments-popup.component.html',
  styleUrls: ['./add-edit-appointments-popup.component.css']
})
export class AddEditAppointmentsPopupComponent extends commonhelper implements OnInit {
  @ViewChild('AddEditAppointments') AddEditAppointments: ElementRef;
  @ViewChild("AddEditAppointments", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  // public value: time = new Date(2000, 2, 10, 10, 30, 0);
  public addEditAppointmentsModel: any = {};
  constructor(httpClient: HttpClient, private dialogService: DialogService, private dialog: DialogRef, private LeftNav: LeftNavService) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }
  public addEditAppointmentsSelectStatusDefaultItem = { Status: '-- Select --', StatusId: null };
  public addEditAppointmentsSelectDurationDefaultItem = { Duration: '-- Select --', DurationId: null };
  public addEditAppointmentsstarttime: any;
  private commonhelperobj: commonHelperService;
  public addEditAppointmentsDate: any;
  practiceInformation: any = {};
  appointmentCreatedDate: any;
  usersInformation: any = {};
  SelectedAppointmentId = 0;
  AppointmentId = 0;
  EventId: any;
  StateMaintainUsersData: any = [];
  Attendees = [];
  public addEditAppointmentShowHideSaveUpdateButton: boolean = false;
  public addEditAppointmentsStatusList: any = [
    { StatusId: 1, Status: 'Pending' },
    { StatusId: 2, Status: 'Completed' },
    { StatusId: 3, Status: 'Cancelled' },

  ];
  public addEditAppointmentsDurationList: any = [
    { DurationId: 1, Duration: '15min' },
    { DurationId: 2, Duration: '30min' },
    { DurationId: 3, Duration: '45min' },
    { DurationId: 4, Duration: '1hr' },
    { DurationId: 5, Duration: '1hr 15min' },
    { DurationId: 6, Duration: '1hr 30min' },
    { DurationId: 7, Duration: '1hr 45min' },
    { DurationId: 8, Duration: '2hr' },

  ];
  ngOnInit() {

    setTimeout(function () {
      document.getElementById("addEditAppointmentsPopupSelectPracticeName").focus();
    }, 20);
    this.addEditAppointmentsModel.SelectedEmployeeName = `${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}`
    this.StateMaintainUsersData = [{ EmployeeId: Config.OASOrganizationModel.LoggedUserID, EmployeeFullName: `${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}` }]
    if (this.SelectedAppointmentId > 0) {
      this.getSelectedAppointmnetInfo();
      this.addEditAppointmentShowHideSaveUpdateButton = true;
    }

  }
  getSelectedPracticeUsersList() {
    this.commonhelperobj.PostData(Config.OASAppointmentsInformationAppnURL + 'GetPracticeUsersList', { MeetingScheduledInfoID: this.SelectedAppointmentId })
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.usersInformation = response.practiceUsersList;
        this.assignParticipantsNamestongModel(this.usersInformation, "SelectedUserPersonName", "EmployeeName");
      });
  }
  getSelectedParticipantsList() {
    this.commonhelperobj.PostData(Config.OASAppointmentsInformationAppnURL + 'GetParticipents', { MeetingScheduledInfoID: this.SelectedAppointmentId })
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.StateMaintainUsersData = response.participantsModelList;
        this.assignParticipantsNamestongModel(this.StateMaintainUsersData, "SelectedEmployeeName", "EmployeeFullName");
      });
  }
  getSelectedAppointmnetInfo() {
    this.commonhelperobj.PostData(Config.OASAppointmentsInformationAppnURL + 'GetAppointments', { MeetingScheduledInfoID: this.SelectedAppointmentId })
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.addEditAppointmentsModel = response;
        this.appointmentCreatedDate = response.addEditAppointmentsCreateDate;
        let startDate = response.addEditAppointmentsStartDate, endDate = response.addEditAppointmentsEndDate;
        this.addEditAppointmentsModel.addEditAppointmentsStartDate = new Date(response.addEditAppointmentsStartDate);
        if (this.hasValue(response.addEditAppointmentsEndDate))
          this.addEditAppointmentsModel.addEditAppointmentsEndDate = new Date(response.addEditAppointmentsEndDate);
        this.addEditAppointmentsModel.addEditAppointmentsstarttime = new Date(startDate.concat(" ").concat(response.addEditAppointmentsstarttime))
        if (this.hasValue(endDate) && this.hasValue(response.addEditAppointmentsendtime))
          this.addEditAppointmentsModel.addEditAppointmentsendtime = new Date(endDate.concat(" ").concat(response.addEditAppointmentsendtime))
        this.getSelectedParticipantsList();
        this.getSelectedPracticeUsersList();
      });

  }
  addEditAppointmentsPopupCancelClickEvent() {

    this.dialog.close();
  }
  addEditAppointmentsPopupSaveClickEvent(SendInvitation) {
    if (this.checkMandatoryValidations()) return;
    this.addEditAppointmentsModel.MeetingScheduledInfoID = this.SelectedAppointmentId;
    this.addEditAppointmentsModel.participantslist = this.StateMaintainUsersData;
    this.addEditAppointmentsModel.practiceUsersList = this.usersInformation;
    if (typeof (this.addEditAppointmentsModel.addEditAppointmentsStartDate) != "string")
      this.addEditAppointmentsModel.addEditAppointmentsStartDate = formatDate(this.addEditAppointmentsModel.addEditAppointmentsStartDate, 'MM/dd/yyy', 'en-US', '+0530');
    if (this.hasValue(this.addEditAppointmentsModel.addEditAppointmentsEndDate) && typeof (this.addEditAppointmentsModel.addEditAppointmentsEndDate) != "string" && this.addEditAppointmentsModel.addEditAppointmentsEndDate != "")
      this.addEditAppointmentsModel.addEditAppointmentsEndDate = formatDate(this.addEditAppointmentsModel.addEditAppointmentsEndDate, 'MM/dd/yyy', 'en-US', '+0530');
    if (typeof (this.addEditAppointmentsModel.addEditAppointmentsstarttime) != "string")
      this.addEditAppointmentsModel.addEditAppointmentsstarttime = formatDate(this.addEditAppointmentsModel.addEditAppointmentsstarttime, 'hh:mm a', 'en-US', '+0530')
    if (this.hasValue(this.addEditAppointmentsModel.addEditAppointmentsendtime) && typeof (this.addEditAppointmentsModel.addEditAppointmentsendtime) != "string" && this.addEditAppointmentsModel.addEditAppointmentsendtime != "")
      this.addEditAppointmentsModel.addEditAppointmentsendtime = formatDate(this.addEditAppointmentsModel.addEditAppointmentsendtime, 'hh:mm a', 'en-US', '+0530')
    return this.commonhelperobj.PostData(Config.OASAppointmentsInformationAppnURL + 'SaveorUpdateAppointments', this.addEditAppointmentsModel)
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        if (SendInvitation && this.addEditAppointmentsModel.appointmentStatusId != 2) {
          this.AppointmentId = response.ResponseID
          this.checkWhetherEmailAuthenticatedorNot();
        }
        else {
          this.dialog.close("Success");
        }
      });


    // let time=formatDate(this.addEditAppointmentsstarttime,'hh:mm a', 'en-US', '+0530')
    // this.parseDatTime(formatDate(this.addEditAppointmentsDate,'MM/dd/yyy','en-US', '+0530'),time)

  }
  checkMandatoryValidations() {

    if (!this.hasValue(this.addEditAppointmentsModel.SelectedPracticeName)) {
      this.ShowErrorMessage("Please Select Practice Name")
      document.getElementById("addEditAppointmentsPopupSelectPracticeName").focus();
      return true;
    }
    if (!this.hasValue(this.addEditAppointmentsModel.SelectedUserPersonName)) {
      this.ShowErrorMessage("Please Select User(s)")
      document.getElementById("addEditAppointmentsPopupSelectUserName").focus();
      return true;
    }

    if (!this.hasValue(this.addEditAppointmentsModel.SelectedEmployeeName)) {
      this.ShowErrorMessage("Please Select Employee(s)")
      document.getElementById("addEditAppointmentsPopupSelectEmployeeName").focus();
      return true;
    }
    if (!this.hasValue(this.addEditAppointmentsModel.appointmentStatusId) || this.addEditAppointmentsModel.appointmentStatusId <= 0) {
      this.ShowErrorMessage('Please Select Status');
      //this.CreateTaskInfo.nativeElement.querySelector('#TaskAddNewCurrentWokringStatus').focus();
      const elementFound = this.AddEditAppointments.nativeElement.querySelector('[name="' + 'addEditAppointmentsSelectStatusDropdown' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return true;
    }
    if (!this.addEditAppointmentsModel.addEditAppointmentsStartDate) {
      this.ShowErrorMessage("Please Select / Enter Schedule Start Date")
      const elementFound = this.AddEditAppointments.nativeElement.querySelector('[name="' + 'txtAddEditAppointmentsStartDate' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return true;
    }
    if (this.addEditAppointmentsStartdateChange() == false) return true;
    if (this.hasValue(this.addEditAppointmentsModel.addEditAppointmentsEndDate)) {
      if (this.addEditAppointmentsEndateChange() == false) return true;
    }
    // if(!this.addEditAppointmentsModel.addEditAppointmentsEndDate){
    //   this.ShowErrorMessage("Please Enter Shedule End Date")
    //   const elementFound = this.AddEditAppointments.nativeElement.querySelector('[name="' + 'txtAddEditAppointmentsEndDate' + '"]');
    //       if (this.hasValue(elementFound)) {
    //         elementFound.children[0].children[0].children[0].children[0].focus();
    //       }
    //   return true;
    // }
    if (!this.addEditAppointmentsModel.addEditAppointmentsstarttime) {
      this.ShowErrorMessage("Please Select / Enter Schedule Start Time")
      const elementFound = this.AddEditAppointments.nativeElement.querySelector('[name="' + 'txtAddEditAppointmentsStartTime' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return true;
    }
    // if(!this.addEditAppointmentsModel.addEditAppointmentsendtime){
    //   this.ShowErrorMessage("Please Enter End Time")
    //   const elementFound = this.AddEditAppointments.nativeElement.querySelector('[name="' + 'txtAddEditAppointmentsEndTime' + '"]');
    //       if (this.hasValue(elementFound)) {
    //         elementFound.children[0].children[0].children[0].children[0].focus();
    //       }
    //       return true;
    // }
    if (this.hasValue(this.addEditAppointmentsModel.addEditAppointmentsendtime) && !this.hasValue(this.addEditAppointmentsModel.addEditAppointmentsEndDate)) {
      this.ShowErrorMessage("Please Select / Enter Schedule End Date")
      const elementFound = this.AddEditAppointments.nativeElement.querySelector('[name="' + 'txtAddEditAppointmentsEndDate' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return true;
    }

    // if(this.hasValue(this.addEditAppointmentsModel.addEditAppointmentsstarttime) && this.hasValue(this.addEditAppointmentsModel.addEditAppointmentsendtime)){
    //   let startDate=formatDate(this.addEditAppointmentsModel.addEditAppointmentsStartDate,'MM/dd/yyy','en-US', '+0530');
    //   let endDate=formatDate(this.addEditAppointmentsModel.addEditAppointmentsEndDate,'MM/dd/yyy','en-US', '+0530');
    //   let startTime=formatDate(this.addEditAppointmentsModel.addEditAppointmentsstarttime,'hh:mm a', 'en-US', '+0530')
    //   let endTime=formatDate(this.addEditAppointmentsModel.addEditAppointmentsendtime,'hh:mm a', 'en-US', '+0530')
    //   let parsedStartTime=new Date(startDate.concat(" ").concat(startTime));
    //   let parsedEndTime=new Date(endDate.concat(" ").concat(endTime));
    // //   if(parsedStartTime>parsedEndTime){
    // //   this.ShowErrorMessage("Schedule Start Time Should Be Less than Schedule End Time")
    // //   const elementFound = this.AddEditAppointments.nativeElement.querySelector('[name="' + 'txtAddEditAppointmentsStartTime' + '"]');
    // //   if (this.hasValue(elementFound)) {
    // //     elementFound.children[0].children[0].children[0].children[0].focus();
    // //   }
    // //   return true;
    // // }
    // }
    if (!this.addEditAppointmentsModel.addEditAppointmentsPurpose) {
      this.ShowErrorMessage("Please Enter Purpose of Meeting")
      document.getElementById("taAddEditAppointmentsPurpose").focus();
      return true;
    }
    if (!this.addEditAppointmentsModel.addEditAppointmentsMeetingSubject) {
      this.ShowErrorMessage("Please Enter Metting Subject")
      document.getElementById("taAddEditAppointmentsSubject").focus();
      return true;
    }
  }

  checkWhetherEmailAuthenticatedorNot() {
    //this.commonhelperobj.PostData('https://itslocal.ehryourway.com/OASApplicationMainAPI/api/GoogleAuth', { PracticeUserId: Config.OASOrgModel.OrganizationID, EmailAddress: "support@ehryourway.com" })
    this.commonhelperobj.PostData('https://supportprod.ehryourway.com/OASApplicationMainAPI/api/GoogleAuth', { PracticeUserId: Config.OASOrgModel.OrganizationID, EmailAddress: "support@ehryourway.com" })
    //this.commonhelperobj.PostData('http://localhost/OASApplicationMainAPI/api/GoogleAuth', { PracticeUserId: Config.OASOrgModel.OrganizationID, EmailAddress: "charithamathrala999@gmail.com" })
      .subscribe(response => {
        if (this.isError(response)) return;
        if (response.Message == "User Authenticated") this.buildAttendeesInfo();
        else {
          window.open(response.RedirectUri)
          this.buildAttendeesInfo();
        }
      });
  }
  buildAttendeesInfo() {
    if (this.SelectedAppointmentId > 0) this.GetGoogleCalenderEvent();
    this.getPracticeUserEmailslist();
  }
  getPracticeUserEmailslist() {
    this.commonhelperobj.PostData(Config.OASAppointmentsInformationAppnURL + 'GetPracticeUserEmails', { PracticeId: this.addEditAppointmentsModel.PracticeId, PracticeUsersIds: this.buildUserIdsandEmployeeIds(this.usersInformation, "UserID") })
      .subscribe(response => {
        if (this.isError(response)) return;
        if (response.practiceUserEmailsList.length > 0)
          this.buildAttendeesEmails(response.practiceUserEmailsList);
        this.getSupportEmployeeEmailslist();
      });
  }

  buildUserIdsandEmployeeIds(usersList, filteritem) {
    let buildIdsArray = []
    usersList.forEach(element => {
      buildIdsArray.push(element[filteritem].toString())
    });
    return buildIdsArray;

  }
  getSupportEmployeeEmailslist() {
    // this.StateMaintainUsersData.push({ EmployeeId: Config.OASOrganizationModel.LoggedUserID, EmployeeFullName: `${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}` })
    const postData = {
      SupportEmployeeIds: this.buildUserIdsandEmployeeIds(this.StateMaintainUsersData, "EmployeeId"),
    }
    this.commonhelperobj.PostData(Config.OASAppointmentsInformationAppnURL + 'GetSupportEmpoyeeEmails', postData)
      .subscribe(response => {
        if (this.isError(response)) return;
        if (response.SupportEmployeeEmailsList.length > 0)
          this.buildAttendeesEmails(response.SupportEmployeeEmailsList);
        this.sendAppointmentInvitationThroughGoogleCalender();
      });
  }
  buildAttendeesEmails(EmailsList) {
    EmailsList.forEach(element => {
      if (this.hasValue(element.UserEmail) && element.UserEmail.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"))
        this.Attendees.push(element.UserEmail)
    });
  }
  sendAppointmentInvitationThroughGoogleCalender() {
    let startDate = this.addEditAppointmentsModel.addEditAppointmentsStartDate + " " + this.addEditAppointmentsModel.addEditAppointmentsstarttime;
    let DateTime = new Date(startDate);    // converting dateformat to IST (Tue Jun 11 2019 22:30:00 GMT+0530 (India Standard Time)) --->    "06/11/2019 09:30 PM"
    let EndTimeCaluclated = DateTime.setMinutes(DateTime.getMinutes() + parseInt(this.getAppointmentDuration() + ""));
    let EndTimeCaluclatedTime = formatDate(new Date(EndTimeCaluclated), 'hh:mm a', 'en-US', '+0530');     // CONVERTD AS HOURS AND MINUTES FORMAT 
    let EndTimeCaluclatedDate = formatDate(new Date(EndTimeCaluclated), 'MM/dd/yyy', 'en-US', '+0530');  // CONVERTD AS MONTH DATE YEAR FORMAT 
    let enddate = EndTimeCaluclatedDate + " " + EndTimeCaluclatedTime;
    // $scope.NewAppointmentSchedulerModel.EndTime = EndTimeCaluclatedTime;   //  CALCULATED END TIME ASSIGNED TO THE VARIABLE 
    //let isttoutctimezone = formatDate(new Date(EndTimeCaluclated), 'hh:mm a', 'en-US', 'UTC'); 
    let ISTtoUtcStartTime = formatDate(startDate, 'MM/dd/yyy h:mm:ss a', 'en-US', 'UTC');
    let ISTtoUtcEndTime = formatDate(enddate, 'MM/dd/yyy h:mm:ss a', 'en-US', 'UTC');
    let GoogleCalenderInputModel = {
      startTime: ISTtoUtcStartTime,
      endTime: ISTtoUtcEndTime,
      summary: this.addEditAppointmentsModel.addEditAppointmentsMeetingSubject,
      attendees: this.addEditAppointmentsModel.appointmentStatusId == 3 ? "charithamathrala999@gmail.com" : this.Attendees,
      calendarId: "support@ehryourway.com",//"charithamathrala999@gmail.com",
      practiceUserId: Config.OASOrgModel.OrganizationID,
      Description: this.addEditAppointmentsModel.addEditAppointmentsPurpose,
      EventId: this.EventId
    }
    // let AppointmentsUserEmployeeModel = {
    //   PrcticeId: this.practiceInformation.PracticeId,
    //   UserIds: this.usersInformation.map(function (item) { return item.UserId }).join(", "),
    //   EmployeeIds: this.StateMaintainUsersData.map(function (item) { return item.EmployeeId }).join(", "),
    //   CreatedBy: Config.OASOrgModel.LoggedUserID
    // }
    // const PostData = {
    //   GoogleCalenderInputModel: GoogleCalenderInputModel,
    // }
    let methodname = this.hasValue(this.EventId) ? "UpdateGoogleCalenderInvitation" : "SendGoogleCalenderInvitation"
    // return this.commonhelperobj.PostData("https://itslocal.ehryourway.com/OASApplicationMainAPI/GoogleCalender/" + methodname, GoogleCalenderInputModel)
     return this.commonhelperobj.PostData("https://supportprod.ehryourway.com/OASApplicationMainAPI/GoogleCalender/" + methodname, GoogleCalenderInputModel)
    //return this.commonhelperobj.PostData("http://localhost/OASApplicationMainAPI/GoogleCalender/" + methodname, GoogleCalenderInputModel)
      .subscribe(response => {
        if (this.isError(response)) return;
        this.saveGoogleCalenderEvent(response.EventId);
      });
  }

  saveGoogleCalenderEvent(eventid) {
    const postData = {
      AppointmentId: this.SelectedAppointmentId || this.AppointmentId,
      EventId: eventid,
      isAppointmentEvent: this.SelectedAppointmentId > 0 ? true : false
    }
    this.commonhelperobj.PostData(Config.OASAppointmentsInformationAppnURL + 'SaveUpdateGoogleCalenderEvent', postData)
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.dialog.close("Success");
      });
  }

  GetGoogleCalenderEvent() {
    const postData = {
      AppointmentId: this.SelectedAppointmentId,
    }
    this.commonhelperobj.PostData(Config.OASAppointmentsInformationAppnURL + 'GetGoogleCalenderEvent', postData)
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.EventId = response.EventId
      });
  }
  addEditAppointmentsSelectPracticeNameClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Practice',
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });

    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        let previousPracticeId = this.practiceInformation.PracticeId;
        this.practiceInformation = result;
        if (this.hasValue(this.practiceInformation)) {
          if (previousPracticeId != this.practiceInformation.PracticeId) this.assistantPersonIconRemoveSelectedUserNameClickEvent();
          this.addEditAppointmentsModel.SelectedPracticeName = this.practiceInformation.PracticeName;
          this.addEditAppointmentsModel.PracticeId = this.practiceInformation.PracticeId;
        }
      }
    });
  }
  assistantPersonIconSelectUserNameClickEvent() {
    if (!this.hasValue(this.addEditAppointmentsModel.PracticeId)) {
      this.ShowErrorMessage('Please Select Practice.');
      return false;
    }
    // let senddatamodel = {};
    // senddatamodel = {
    //   PracticeId: this.addEditAppointmentsModel.PracticeId,
    // };
    // setTimeout(() => {
    //   this.LeftNav.GetEditModeData(senddatamodel);
    // }, 10);

    const dialogRef = this.dialogService.open({
      title: 'Practice Users Information',
      content: PracticeUsersCustomizationComponent,// PracticeUsersListComponent,
      height: 698,
      width: 700,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.datatoPopup =
    {
      StateMaintainUsersData: this.usersInformation,
      PracticeId: this.addEditAppointmentsModel.PracticeId,
    }
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.usersInformation = result;
        if (this.hasValue(this.usersInformation)) {
          this.assignParticipantsNamestongModel(this.usersInformation, "SelectedUserPersonName", "EmployeeName")
        }
      }
    });
  }

  assistantPersonIconRemoveSelectedUserNameClickEvent() {
    this.usersInformation = [];
    this.addEditAppointmentsModel.SelectedUserPersonName = ""
    this.addEditAppointmentsModel.SelectedUserId = 0;

  }
  addEditAppointmentsRemoveSelectedPracticeNameClickEvent() {
    this.addEditAppointmentsModel.SelectedPracticeName = "";
    this.addEditAppointmentsModel.PracticeId = 0;
    this.assistantPersonIconRemoveSelectedUserNameClickEvent();
  }
  addEditAppointmentsRemoveSelectedEmployeeNameClickEvent() {
    this.StateMaintainUsersData = [];
    this.addEditAppointmentsModel.SelectedEmployeeId = "";
    this.addEditAppointmentsModel.SelectedEmployeeName = "";
  }
  addEditAppointmentsSelectEmployeeNameClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Employee(s)',
      content: EmployeesCustomizationComponent,
      height: 698,
      width: 700,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.StateMaintainUsersData = this.StateMaintainUsersData;
    dialogRef.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult)) {
        this.StateMaintainUsersData = result;
        if (this.hasValue(this.StateMaintainUsersData)) {
          this.assignParticipantsNamestongModel(this.StateMaintainUsersData, "SelectedEmployeeName", "EmployeeFullName")
        }
      }
    });
  }
  assignParticipantsNamestongModel(usersList, assignto, filteritem) {
    this.addEditAppointmentsModel[assignto] = usersList.map(function (item) { return item[filteritem] }).join(", ");
  }
  addEditAppointmentsStartdateChange() {
    if (!(this.addEditAppointmentStartandEndDateValidations(this.addEditAppointmentsModel.addEditAppointmentsStartDate, "txtAddEditAppointmentsStartDate", true))) return false;
  }
  addEditAppointmentsEndateChange() {

    if (!(this.addEditAppointmentStartandEndDateValidations(this.addEditAppointmentsModel.addEditAppointmentsEndDate, "txtAddEditAppointmentsEndDate", false))) return false;
  }
  addEditAppointmentStartandEndDateValidations(modifiedfromandtoDate: Date, name: string, isfrom: boolean) {
    var today = new Date();
    var toDate = new Date(modifiedfromandtoDate);
    var showfocuselement = false;
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage('Invalid Day, Month or Year Range Detected.');
      const elementFound = this.AddEditAppointments.nativeElement.querySelector('[name="' + name + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    if (isfrom) {
      var daysDifference;
      let createdDate = this.appointmentCreatedDate || formatDate(today, 'MM/dd/yyy', 'en-US', '+0530');
      daysDifference = this.DateDiffInDays(formatDate(this.addEditAppointmentsModel.addEditAppointmentsStartDate, 'MM/dd/yyy', 'en-US', '+0530'), createdDate);
      if (this.hasValue(daysDifference) && daysDifference < 0) {
        if (this.hasValue(this.appointmentCreatedDate)) {
          this.ShowErrorMessage('Schedule Start Date Should be Greater Than or Equal to Created Date');
        }
        else {
          this.ShowErrorMessage('Schedule Start Date Should be Greater Than or Equal to Current Date');
        }
        const elementFound = this.AddEditAppointments.nativeElement.querySelector('[name="' + name + '"]');
        if (this.hasValue(elementFound)) {
          elementFound.children[0].children[0].children[0].children[0].focus();
        }
        return false;
      }
      //  daysDifference = this.DateDiffInDays( this.addEditAppointmentsModel.addEditAppointmentsEndDate, this.addEditAppointmentsModel.addEditAppointmentsStartDate); 
      // if(this.hasValue(daysDifference)&&daysDifference<0 ){ 
      //     if(this.hasValue(this.addEditAppointmentsModel.addEditAppointmentsEndDate)){ 
      //   this.ShowErrorMessage('Schedule Start Date Should be Less Than or Equal to Schedule End Date'); 
      //   const elementFound = this.AddEditAppointments.nativeElement.querySelector('[name="' + name + '"]');
      //  if (this.hasValue(elementFound)) {
      //    elementFound.children[0].children[0].children[0].children[0].focus();
      //  }
      //   return false;
      //   } 
      //  } 
    }
    return true;
  }
  getAppointmentDuration() {
    let durationinMinutes;
    switch (this.addEditAppointmentsModel.appointmentDurationId) {
      case 1:
        durationinMinutes = 15
        break;
      case 2:
        durationinMinutes = 30
        break;
      case 3:
        durationinMinutes = 45
        break;
      case 4:
        durationinMinutes = 60
        break;
      case 5:
        durationinMinutes = 75
        break;
      case 6:
        durationinMinutes = 90
        break;
      case 7:
        durationinMinutes = 105
        break;
      case 8:
        durationinMinutes = 120
        break;
      default:
        durationinMinutes = 120
        break;
    }
    return durationinMinutes;
  }
}


