import { Component, OnInit } from "@angular/core";
import { FormControl, Validator, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { commonHelperService } from "../common/common.service";
import { IntlService } from "@progress/kendo-angular-intl";
import { commonhelper } from "../common/commonhelper";
import { Config } from "../config";
import { TaskService } from "../services/task.service";

@Component({
  selector: "app-add-task-type-popup",
  templateUrl: "./add-task-type-popup.component.html",
  styleUrls: ["./add-task-type-popup.component.css"],
})
export class AddTaskTypePopupComponent extends commonhelper implements OnInit {
  taskTypeType = new FormControl(887, Validators.required);
  taskTyeList: any=[];
  datatoPopup: any;

  constructor(
    private _dialog: DialogRef,
    private _Router: Router,
    private intl: IntlService,
    private taskService: TaskService,
    private commonhelperobj: commonHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.GetTaskTypes();

  }

  CreateTaskCancelClick(message: string | undefined) {
    this._dialog.close(message);
  }
  GetTaskTypes() {
    this.taskService.taskTypeList().subscribe((response) => {
      if (this.isError(response)) {
        return;
      }
      this.taskTyeList = response;

      if (this.datatoPopup.Value !== undefined) {
        let TaskTypeFromPopup = this.taskTyeList.find(
          (x) =>
            x.TaskTypeDescription.toLocaleLowerCase() ===
            this.datatoPopup.Value.toLocaleLowerCase()
        );
        if (TaskTypeFromPopup !== undefined) {
          this.taskTypeType.setValue(TaskTypeFromPopup.TaskTypeId);
        }
      }
    });
  }
  SaveTaskDetails() {
    let Payload = {
      TaskType: this.taskTypeType.value,
      TaskInfoID: this.datatoPopup.TaskInfoID,
    };
    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "UpdateTaskTypeDetails",
        Payload
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          this._dialog.close(undefined);
          return;
        } else {
          this._dialog.close(serviceResponse);
        }
      });
  }
}
