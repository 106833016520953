export class Config {


    static OASOrganizationModel: any;
    static EMRPracticeModelForOAS: any;
    static OASWCFCallingURl: string;
    static CommonStoredData: any = {};
    private static AutoLoadModulesInformation: any = {};

    // 1 - for local 2- for QA 3- for production
    static environmentType = 1;

    static WebRootPath = window.location.href;


    static MAX_SIZE = 2_000_000;

    static get OASEmployeeURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI';
    }
    static get OASPracticeURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/PracticeusersApi/';
    }
    static get OASOverDueURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/OverDueReport/';
    }
    static get OASCustomerMailTemplateAppnURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/NewCustomerMailTemplate/';
    }
    //
    // static get OASOrganizationURL(): string {
    //     return Config.OASWCFCURL + 'OAS_App_API/api/Organization/';
    // }

    // static get OASDesignationsURL(): string {
    //     return Config.OASWCFCURL + 'OAS_App_API/api/Designations/';
    // }

    // static get OASFormsAndFieldsInfoURL(): string {
    //     return Config.OASWCFCURL + 'OAS_App_API/api/FormsAndFieldsInfo/';
    // }


    static get OASAppnURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/OasAppMainAPi/';
    }

    static get OASModulesAppnURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/Modules/';
    }
    static get OASProjectsAppnURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/Projects/';
    }
    static get OASOrganizationAppnURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/Employees/';
    }
    static get OASAccessInformationAppnURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/AccessInformation/';
    }
    static get OASEmployeesInformationAppnURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/EmployeesInformation/';
    }
    static get OASAppointmentsInformationAppnURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/MyAppointments/';
    }
    static get OASCallLogsInformationAppnURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/CustomerCallLogs/';
    }
    static get OASReportsofTasksAppnURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/ReportOfTasks/';
    }
    static get OASPracticeCheckListItemsAppnURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/PracticeMasterCheckList/';
    }
    static get OASPracticeCheckListAppnURL(): string {
        return Config.OASWCFCURL + 'OASApplicationMainAPI/PracticeCheckList/';
    }

    static get OASWCFCURL(): string {
        if (window.location.href.indexOf('localhost') > -1) {
            return 'http://localhost/';
        }
        else if (window.location.href.indexOf('support') > -1) {
            return 'https://globalwebserver1.ehryourway.com/';
        }
        else if (window.location.href.indexOf('crm') > -1) {
            return 'https://globalwebserver1.ehryourway.com/';
        }
        else {
            return "https://globalwebserverlocal.ehryourway.com/";
        }

    }

    static set OASWCFCURL(theTokenURL: string) {
        Config.OASWCFCallingURl = theTokenURL;
    }


    static get OASOrgModel(): any { return Config.OASOrganizationModel; }

    static set OASOrgModel(theToken: any) {
        Config.OASOrganizationModel = theToken;
    }

    static hasActiveToken() {
        return Config.OASOrgModel;
    }

    static ResetOASOrganizationModel() {
        Config.OASOrgModel = '';
    }

    static set OASAutoLoadModulesInformation(info: any) {
        Config.AutoLoadModulesInformation = info;
    }

    static get OASAutoLoadModulesInformation(): any {
        return Config.AutoLoadModulesInformation;
    }

}
