
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { commonHelperService } from '../common/common.service';


@Injectable()
export class NewCustomerMailsAddEditTemplatePopupService extends commonHelperService {
    constructor(http: HttpClient) {
        super(http);
    }
    GetNewCustomerMailTemplate(postData: any) {
        return this.PostData(Config.OASCustomerMailTemplateAppnURL + 'GetNewCustomerMailTemplate', postData)
    }

    SaveorUpdateNewCustomerMailTemplate(postData: any) {
        return this.PostData(Config.OASCustomerMailTemplateAppnURL + 'SaveorUpdateNewCustomerMailTemplate', postData)
    }

    GetNewCustomerMailTemplateLinkedUsersList(postData: any) {
        return this.PostData(Config.OASCustomerMailTemplateAppnURL + 'GetNewCustomerMailTemplateLinkedUsersList', postData)
    }


}