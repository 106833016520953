import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  DialogCloseResult,
  DialogService,
} from "@progress/kendo-angular-dialog";
import { IntlService, formatDate } from "@progress/kendo-angular-intl";
import { AgGridNg2 } from "ag-grid-angular";
import { GridSizeChangedEvent } from "ag-grid-community";
import { commonHelperService } from "../common/common.service";
import { commonhelper } from "../common/commonhelper";
import { ExcelService } from "../common/excel.service";
import { Config } from "../config";
import { PracticeUsersListComponent } from "../practice-users-list/practice-users-list.component";
import { PraticesListComponent } from "../pratices-list/pratices-list.component";
import { ResponsiblepersonUsersListComponent } from "../responsibleperson-users-list/responsibleperson-users-list.component";
import { TaskService } from "../services/task.service";
import { TaskBucketDetailsService } from "../shared/services/task-bucket-details";
import { StatisticsReportService } from "./statistics-report.service";

@Component({
  selector: "app-statistics-report",
  templateUrl: "./statistics-report.component.html",
  styleUrls: ["./statistics-report.component.css"],
})
export class StatisticsReportComponent extends commonhelper implements OnInit {
  @ViewChild("statisticsReportTicketsGrid")
  statisticsReportTicketsGrid: AgGridNg2;
  @ViewChild("StatisticsReportTickets", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;

  @ViewChild("StatisticsReportTickets") StatisticsReportTickets: ElementRef;
  private commonhelperobj: commonHelperService;
  public taskTypeList: any = [];
  FromRecords: any;
  PrevLabelCount: any;
  NextLabelCount: any;
  RecordsPerPage: any;
  TotalCountValue: boolean = true;
  TotalCountValueIcon: boolean = false;
  TotalLabelCount: any;
  TotalLabelCountValue: any;
  fromrecordsfromcount: any;
  prevnextbuttons: boolean = true;
  ECDDate: any;
  CreatedFromDate: any = new Date();
  CreatedToDate: any = new Date();
  public CreatedToTime: any = new Date();
  public CreatedFromTime: any = new Date("Tue Apr 11 2000 00:00:00 GMT+0530");
  NextButtonDisabled: boolean = false;
  PreviousButtondisabled: boolean = false;
  public NewCurrentWorkingStatusList: any = [];
  public statisticsReportTicketsSelectStatusDropDown = [];
  public statisticsReportTicketsCurrentWorkingStatus: any = [];
  public statisticsReportTicketsSelectedTaskType: any;
  public selectedTaskBucket: any;
  public selectedTaskBucketCategory: any;
  public practiceInformation: any = {};
  public responsiblePersonInfo: any = {};
  assignedByInfo: any = {};
  public statisticsReportTicketsRowData = [];
  SearchStatusIDs: any = [];
  public statisticsReportTicketsSearchTaskNumber: any;
  public statisticsReportTicketsSearchTaskSubject: any;
  selectedTask: any;
  clickedtab: number = 0;
  EHRPracticeID: number = 0;
  public items: any = [
    {
      tasktype: "All Customer Tickets",
      taskMenutypeid: 1,
      myclass: "main-buttons-item-link",
    },
    {
      tasktype: "All EHR to Customer",
      taskMenutypeid: 2,
      myclass: "main-buttons-item-link",
    },
  ];
  columnDefs = [
    {
      headerName: "Task #",
      field: "TaskNumber",
      width: 90,
      tooltipField: "TaskNumber",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Customer Name",
      field: "PracticeName",
      width: 180,
      tooltipField: "PracticeName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Task Type",
      field: "TaskType",
      width: 150,
      tooltipField: "TaskType",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Task Subject",
      field: "IssueSubject",
      width: 240,
      tooltipField: "IssueSubject",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Priority",
      field: "SeverityDesc",
      width: 90,
      tooltipField: "SeverityDesc",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Task Status",
      field: "TaskLatestStatusName",
      width: 100,
      tooltipField: "TaskLatestStatusName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Responsible Person",
      field: "ResponsiblePersonDesc",
      width: 180,
      tooltipField: "ResponsiblePerson",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Created Date",
      field: "IssueCreatedOn",
      width: 150,
      tooltipField: "IssueCreatedOn",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      hide: false,
      cellStyle: { textAlign: "center" },
    },

    {
      headerName: "Created By",
      field: "CreatedBy",
      width: 180,
      tooltipField: "CreatedBy",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Latest Responsed From",
      field: "LatestResponsedFrom",
      hide: false,
      minWidth: 180,
      maxWidth: 250,
      tooltipField: "Latest Responsed From",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Assigned By",
      field: "RoutingDoneBy",
      width: 130,
      tooltipField: "RoutingDoneBy",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },

    {
      headerName: "ECD",
      field: "ExpectedDate",
      minWidth: 100,
      maxWidth: 200,
      tooltipField: "ExpectedDate",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Rating",
      field: "Rating",
      width: 100,
      tooltipField: "Rating",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Reminders Sent to Customer",
      field: "CustomerReminder_Sent_Count",
      width: 150,
      tooltipField: "CustomerReminder_Sent_Count",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
  ];
  TaskBucketList = [];
  TaskBucketCategoryList = [];
  onStatisticsReportTicketsGridSizeChanged(params: GridSizeChangedEvent) {
    // params.api.sizeColumnsToFit();
  }
  public defaultCurrentWorkingStatusItem = {
    Task_Current_Working_Status: "-- Task Current Working Status --",
    Task_Current_Working_Status_InfoID: null,
  };
  public defaultTaskTypeItem = {
    TaskTypeDescription: "-- Search Task Type --",
    TaskTypeId: null,
  };
  public defaultTaskBUcket = {
    Name: "-- Select --",
    ID: null,
  };
  public defaultTaskBUcketCategory = {
    Name: "-- Select --",
    ID: null,
  };
  constructor(
    private intl: IntlService,
    private http: HttpClient,
    private readonly _excelService: ExcelService,
    private readonly _statisticsReportService: StatisticsReportService,
    httpClient: HttpClient,
    private taskService: TaskService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private _router: Router,
    private _taskBucketDetailsService: TaskBucketDetailsService
  ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
    this.TaskBucketList = this._taskBucketDetailsService.BucketNamesList;
  }

  ngOnInit() {
    this.getTaskStatusList();
    this.GetTaskTypes();
    this.GetCurrentStatusList();
    this.route.params.subscribe((params) => {
      const data = this.getCommonData(params.info);
      if (
        this.hasValue(data) &&
        this.hasValue(data.previouslySelectedSearchFilters)
      ) {
        this.statisticsReportTicketsSearchTaskNumber =
          data.previouslySelectedSearchFilters.statisticsReportTicketsSearchTaskNumber;
        this.statisticsReportTicketsSearchTaskSubject =
          data.previouslySelectedSearchFilters.statisticsReportTicketsSearchTaskSubject;
        this.responsiblePersonInfo =
          data.previouslySelectedSearchFilters.responsiblePersonInfo;
        this.assignedByInfo =
          data.previouslySelectedSearchFilters.assignedByInfo;
        this.practiceInformation =
          data.previouslySelectedSearchFilters.practiceInformation;
        this.FromRecords =
          data.previouslySelectedSearchFilters.StatemaintainFromRecords;
        this.SearchStatusIDs =
          data.previouslySelectedSearchFilters.SearchStatusIDs;
        this.statisticsReportTicketsCurrentWorkingStatus =
          data.previouslySelectedSearchFilters.statisticsReportTicketsCurrentWorkingStatus;
        this.statisticsReportTicketsSelectedTaskType =
          data.previouslySelectedSearchFilters.statisticsReportTicketsSelectedTaskType;
        this.selectedTaskBucket =
          data.previouslySelectedSearchFilters.selectedTaskBucket;
        this.selectedTaskBucketCategory =
          data.previouslySelectedSearchFilters.selectedTaskBucketCategory;
        this.CreatedFromDate =
          data.previouslySelectedSearchFilters.CreatedFromDate;
        this.CreatedToDate = data.previouslySelectedSearchFilters.CreatedToDate;
        this.CreatedFromTime =
          data.previouslySelectedSearchFilters.CreatedFromTime;
        this.CreatedToTime = data.previouslySelectedSearchFilters.CreatedToTime;
        this.ECDDate = data.previouslySelectedSearchFilters.ECDDate;
        this.OnclickGetPreviousRecord(this.selectedTaskBucket);
        // this.isfromStatemaintainFilter=true;
        // this.CustomerTicketsButtonClick(
        //   this.items[data.previouslySelectedSearchFilters.clickedtab - 1],
        //   false
        // );
        this.selectedTask = data.previouslySelectedTaskNumber;
      } else {
        // this.CustomerTicketsButtonClick(this.items[0], true);
      }
    });
    this.statisticsReportTicketsGetMajorTopicsListClick(
      undefined,
      undefined,
      false
    );
    setTimeout(() => {
      document.getElementById("txtStatisticsReportTicketsSearchTask").focus();
      this.statemaintainPreviousSelectedRecord();
    }, 100);
    this.items[0].myclass = "MainButtonsHoverClass main-buttons-item-link";
  }

  OnclickChangeTaskBucket(event) {
    if (event !== undefined && event.ID !== null) {
      let TaskBucketSubcategoryList =
        this._taskBucketDetailsService.BucketSubCategoryList;
      this.TaskBucketCategoryList = TaskBucketSubcategoryList.filter(
        (ele) =>
          ele.BucketID === event.ID || (ele.BucketID === -1 && event.ID != 12)
      );
      this.selectedTaskBucketCategory = this.defaultTaskBUcketCategory;
      // console.log("this is you")
    } else {
      // console.log("this is me")
      this.selectedTaskBucketCategory = this.defaultTaskBUcketCategory;
    }
  }

  OnclickGetPreviousRecord(event) {
    if (event !== undefined && event.ID !== null) {
      let TaskBucketSubcategoryList =
        this._taskBucketDetailsService.BucketSubCategoryList;
      this.TaskBucketCategoryList = TaskBucketSubcategoryList.filter(
        (ele) =>
          ele.BucketID === event.ID || (ele.BucketID === -1 && event.ID != 12)
      );
      this.selectedTaskBucketCategory = this.selectedTaskBucketCategory;
      // console.log("this is you")
    } else {
      // console.log("this is me")
      this.selectedTaskBucketCategory = this.defaultTaskBUcketCategory;
    }
  }

  statisticsReportTicketsExportToExcelClick(exoportoexcel) {
    this.statisticsReportTicketsGetMajorTopicsListClick(
      undefined,
      undefined,
      exoportoexcel
    );
  }
  statisticsReportTicketsPrevNextClick(prevornext) {
    this.statisticsReportTicketsGetMajorTopicsListClick(
      undefined,
      prevornext,
      false
    );
  }
  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "yyyyMMdd")}` : "";
  }
  private formatDateValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "MM/dd/yyyy")}` : "";
  }

  statisticsReportTicketsGetMajorTopicsListClick(
    isfromsearch,
    prevornext,
    exoportoexcel
  ) {
    this.RecordsPerPage = 50;
    if (exoportoexcel == false) {
      if (
        isfromsearch == true &&
        (prevornext == false || prevornext == undefined) &&
        this.TotalCountValue == false
      ) {
        this.TotalCountValueIcon = false;
        this.TotalCountValue = true;
      }
      if (
        !this.hasValue(this.FromRecords) ||
        (this.hasValue(isfromsearch) && isfromsearch == true)
      ) {
        this.FromRecords = 1;
      }
      switch (prevornext) {
        case 1:
          //if(this.hasValue(this.PreviousButtondisabled) && this.PreviousButtondisabled==true) return;
          this.FromRecords -= this.RecordsPerPage;
          break;

        case 2:
          //if(this.hasValue(this.NextButtonDisabled) && this.NextButtonDisabled==true) return;
          this.FromRecords += this.RecordsPerPage;
          break;
        case 3:
          this.fromrecordsfromcount = this.FromRecords;
          this.FromRecords = null;
      }
    }
    let statusids = this.SearchStatusIDs.map((item) => item.TaskStatusId).join(
      ","
    );

    // console.log(this.clickedtab, "DataSTore");
    const data: any = {};

    if (this.applyclassesforTasksCreatedDatesSelected(data) == false) return;

    // if(this.selectedTaskBucket && this.selectedTaskBucketCategory)

    (data.FromRecords = this.hasValue(this.FromRecords)
      ? this.FromRecords
      : undefined),
      (data.TaskNumber = this.statisticsReportTicketsSearchTaskNumber),
      (data.IssueSubject = this.statisticsReportTicketsSearchTaskSubject),
      (data.TaskLatestStatusList = this.hasValue(statusids)
        ? statusids
        : undefined),
      (data.ResponsibleUserID = this.hasValue(
        this.responsiblePersonInfo.EmployeeId
      )
        ? this.responsiblePersonInfo.EmployeeId
        : undefined),
      (data.RoutingDoneBy = this.hasValue(this.assignedByInfo.EmployeeId)
        ? this.assignedByInfo.EmployeeId
        : undefined),
      (data.PracticeIDsList = this.hasValue(this.practiceInformation.PracticeId)
        ? this.practiceInformation.PracticeId.toString()
        : undefined),
      (data.TaskCurrentWorkingStatusInfoIDs = this.hasValue(
        this.statisticsReportTicketsCurrentWorkingStatus
      )
        ? [this.statisticsReportTicketsCurrentWorkingStatus]
        : []),
      (data.TaskTypeId = this.statisticsReportTicketsSelectedTaskType),
      (data.NeedtoShowAllTasks = 1),
      (data.ExpectedDate = this.hasValue(this.ECDDate)
        ? this.formatValue(this.ECDDate)
        : null);

    data.TaskBucketID = this.selectedTaskBucket
      ? this.selectedTaskBucket.ID
      : null;
    data.TaskBucketCategory = this.selectedTaskBucketCategory
      ? this.selectedTaskBucketCategory.ID
      : null;
    if (exoportoexcel == true) {
      data.TaskBucketID = this.selectedTaskBucket
        ? this.selectedTaskBucket.ID
        : null;
      data.TaskBucketCategory = this.selectedTaskBucketCategory
        ? this.selectedTaskBucketCategory.ID
        : null;
      let CallingCommonHelperOBj: any;
      (data.IsTaskCreatedEHR = true),
        (CallingCommonHelperOBj = this.commonhelperobj.PostData(
          Config.OASOrganizationAppnURL + "StatisticsReportListExport",
          data
        ));

      CallingCommonHelperOBj.subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        if (
          this.hasValue(serviceResponse) &&
          this.hasValue(serviceResponse.EmployeeTaskListModelList) &&
          serviceResponse.EmployeeTaskListModelList.length > 0
        ) {
          this._excelService.exportAsExcelFile(
            this._statisticsReportService.ExcelColumnsInfo,
            serviceResponse.EmployeeTaskListModelList,
            "StatisticsReportTicketsReport"
          );
          //  window.open(serviceResponse.AttachmentsURL);
        }
      });
      return;
    }

    // let CallingAPi: any;
    // if (this.clickedtab === 2) {
    //   (data.IsTaskCreatedEHR = null),
    //     (CallingAPi = this.taskService.gettaskList(data));
    // } else {
    //   (data.IsTaskCreatedEHR = true),
    //     (CallingAPi = this.taskService.taskList(data));
    // }
    (data.IsTaskCreatedEHR = true),
      (data.TaskBucketID = this.selectedTaskBucket
        ? this.selectedTaskBucket.ID
        : null);
    data.TaskBucketCategory = this.selectedTaskBucketCategory
      ? this.selectedTaskBucketCategory.ID
      : null;
    this.taskService.statisticsReportList(data).subscribe((response) => {
      if (this.commonhelperobj.isError(response)) {
        return;
      }
      if (
        this.hasValue(response) &&
        this.hasValue(response.Count) &&
        response.Count > 0
      ) {
        this.TotalCountValueIcon = true;
        this.TotalCountValue = false;
        this.TotalLabelCount = response.Count;
        this.FromRecords = this.fromrecordsfromcount;
        return;
      }
      response.EmployeeTaskListModelList.forEach((TaskListelement) => {
        if (this.hasValue(TaskListelement.TaskUsersInfo)) {
          const SplittedUserData = TaskListelement.TaskUsersInfo.split(",");
          if (this.hasValue(SplittedUserData) && SplittedUserData.length > 0) {
            //TaskListelement.ParticipantsDesc = "";
            TaskListelement.AssistantsDesc = "";
            // tslint:disable-next-line:no-shadowed-variable
            SplittedUserData.forEach((element) => {
              const getUserRolesData = element.split("|");
              if (
                this.hasValue(getUserRolesData) &&
                getUserRolesData.length > 0
              ) {
                if (getUserRolesData[2] === "2") {
                  TaskListelement.ResponsiblePersonDesc = getUserRolesData[1];
                }
                if (getUserRolesData[2] === "7") {
                  // if (!this.hasValue(TaskListelement.ParticipantsDesc)) {
                  //   TaskListelement.ParticipantsDesc = "";
                  // }
                  // TaskListelement.ParticipantsDesc += getUserRolesData[1] + ', ';
                  TaskListelement.AssistantsDesc += getUserRolesData[1] + ", ";
                }
              }
            });
            // TaskListelement.ParticipantsDesc = TaskListelement.ParticipantsDesc.substring(0, TaskListelement.ParticipantsDesc.length - 2);
            TaskListelement.AssistantsDesc =
              TaskListelement.AssistantsDesc.substring(
                0,
                TaskListelement.AssistantsDesc.length - 2
              );
          }
        }
      });
      this.statisticsReportTicketsRowData = response.EmployeeTaskListModelList;

      this.PrevLabelCount = this.FromRecords;
      if (this.FromRecords == 1) {
        this.NextLabelCount = response.EmployeeTaskListModelList.length;
      } else {
        this.NextLabelCount =
          this.FromRecords + response.EmployeeTaskListModelList.length - 1;
      }
      if (
        this.FromRecords == 1 &&
        response.EmployeeTaskListModelList.length < this.RecordsPerPage
      ) {
        this.prevnextbuttons = true;
        this.TotalLabelCountValue = response.EmployeeTaskListModelList.length;
      } else if (
        this.FromRecords == 1 &&
        response.EmployeeTaskListModelList.length == this.RecordsPerPage
      ) {
        this.prevnextbuttons = false;
        this.PreviousButtondisabled = true;
        this.NextButtonDisabled = false;
      } else if (
        response.EmployeeTaskListModelList.length < this.RecordsPerPage
      ) {
        this.prevnextbuttons = false;
        this.NextButtonDisabled = true;
        this.PreviousButtondisabled = false;
      } else {
        this.prevnextbuttons = false;
        this.PreviousButtondisabled = false;
        this.NextButtonDisabled = false;
      }
      //this.statemaintainPreviousSelectedRecord();
    });
  }
  private GetCurrentStatusList() {
    var PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "NewCurrentWorkingStatusList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.NewCurrentWorkingStatusList =
          serviceResponse.newCurrentWorkingStatusListModelList;
      });
  }
  private GetTaskTypes() {
    this.taskService.taskTypeList().subscribe((response) => {
      if (this.isError(response)) {
        return;
      }
      this.taskTypeList = response;
    });
  }
  private getTaskStatusList() {
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "TaskStatusList", {})
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }

        this.statisticsReportTicketsSelectStatusDropDown = [];

        response.forEach((element) => {
          if (element.TaskStatusId != 4) {
            this.statisticsReportTicketsSelectStatusDropDown.push({
              TaskDescription: element.TaskDescription,
              TaskStatusId: element.TaskStatusId,
            });
          }
        });
      });
  }

  // #region  "IF need uncomment it"

  statisticsReportTicketsViewClickEvent() {
    let selectedTask = this.getSelectedTaskInfo();

    if (selectedTask.ReadorUnread === 1) {
      this.OnclickMarkasReadorUnReadFN(selectedTask.TaskInfoID, false, false);
    }

    if (!this.hasValue(selectedTask) || selectedTask <= 0) return;
    const PostDataService: any = {
      IsFromEdit: true,
      editmodedata: selectedTask,
      isfrom: 10, //by statistics
      selectedTicketsList: this.statisticsReportTicketsRowData,
    };
    // if (this.isFromSetByMe === true) {
    //   PostDataService.IsFromSetByMe = true;
    // }
    PostDataService.StatemaintainPreviouslySearchFilters = {
      statisticsReportTicketsSearchTaskNumber:
        this.statisticsReportTicketsSearchTaskNumber,
      statisticsReportTicketsSearchTaskSubject:
        this.statisticsReportTicketsSearchTaskSubject,
      responsiblePersonInfo: this.responsiblePersonInfo,
      assignedByInfo: this.assignedByInfo,
      practiceInformation: this.practiceInformation,
      StatemaintainFromRecords: this.FromRecords,
      SearchStatusIDs: this.SearchStatusIDs,
      statisticsReportTicketsSelectedTaskType:
        this.statisticsReportTicketsSelectedTaskType,
      selectedTaskBucket: this.selectedTaskBucket,
      selectedTaskBucketCategory: this.selectedTaskBucketCategory,
      statisticsReportTicketsCurrentWorkingStatus:
        this.statisticsReportTicketsCurrentWorkingStatus,
      CreatedFromDate: this.CreatedFromDate,
      CreatedToDate: this.CreatedToDate,
      CreatedToTime: this.CreatedToTime,
      CreatedFromTime: this.CreatedFromTime,
      ECDDate: this.ECDDate,
    };
    const outletObj = {};
    outletObj["main"] = [
      "app-view-full-task-information",
      this.setCommonData(PostDataService),
    ];
    this._router.navigate(["/home", { outlets: outletObj }]);
  }
  // #endregion

  getSelectedTaskInfo() {
    const selectedrow = this.statisticsReportTicketsGrid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.ShowErrorMessage("Please Select Task.");
      document.getElementById("myAppointmentsSelectUser").focus();
      return 0;
    }
    return selectedrow[0];
  }
  statisticsReportTicketsSelectPracticeIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Pratice",
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.practiceInformation = result;
        // console.log(this.practiceInformation);
        this.EHRPracticeID = this.practiceInformation.PracticeId;
      }
    });
  }
  statisticsReportTicketsRemoveSelectedPracticeClickEvent() {
    this.practiceInformation = {};
    this.EHRPracticeID = 0;
  }
  statisticsReportTicketsRemoveSelectedDatesClickEvent(number) {
    if (number === 1) {
      this.CreatedFromDate = "";
      this.CreatedFromTime = "";
    } else if (number === 2) {
      this.CreatedToDate = "";
      this.CreatedToTime = "";
    } else {
      this.ECDDate = "";
    }
  }
  statisticsReportTicketsSelectResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.responsiblePersonInfo = result;
      }
    });
  }
  statisticsReportTicketsRemoveSelectedResponsiblePersonClickEvent() {
    this.responsiblePersonInfo = {};
  }
  statemaintainPreviousSelectedRecord() {
    let seletedtasknumber = this.selectedTask;
    let storeGridInfo = this.statisticsReportTicketsGrid.api;
    setTimeout(() => {
      this.statisticsReportTicketsGrid.api.forEachLeafNode(function (node) {
        if (node.data.TaskNumber == seletedtasknumber) {
          node.setSelected(true);
          //Moving Scroll bar to Selected Node
          storeGridInfo.ensureIndexVisible(node.rowIndex + 1);
        }
      });
    }, 500);
  }
  statisticsReportTicketsSelectAssignedByIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Assigned By",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.assignedByInfo = result;
      }
    });
  }
  statisticsReportTicketsRemoveSelectedAssignedByClickEvent() {
    this.assignedByInfo = {};
  }

  // CustomerTicketsButtonClick(item, isfromPageInit) {
  //   // this.clickedtab = item.taskMenutypeid;
  //   // console.log(this.clickedtab, "is clicked");
  //   if (isfromPageInit) this.FromRecords = 1;
  //   setTimeout(() => {
  //     for (let i = 0; i < this.items.length; i++) {
  //       this.items[i].myclass = "main-buttons-item-link";
  //     }
  //     document.getElementById("txtStatisticsReportTicketsSearchTask").focus();
  //     item.myclass = "MainButtonsHoverClass main-buttons-item-link";
  //   }, 20);

  //   this.EHRPracticeID = 0;
  //   this.responsiblePersonInfo = 0;
  //   this.assignedByInfo = 0;
  //   this.practiceInformation = 0;

  //   this.statisticsReportTicketsGetMajorTopicsListClick(
  //     undefined,
  //     undefined,
  //     false
  //   );
  // }

  //Added By Suman
  tasksCreatedFromDateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedFromDate,
        "CreatedFromDate",
        true
      )
    )
      return false;
  }

  tasksCreatedTODateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedToDate,
        "CreatedToDate",
        false
      )
    )
      return false;
  }

  createdFromDateToDateValidations(
    modifiedfromandtoDate: Date,
    name: string,
    isfrom: boolean
  ) {
    var today = new Date();
    var toDate = new Date(modifiedfromandtoDate);
    var showfocuselement = false;
    const daysDifference = this.DateDiffInDays(
      isfrom ? this.CreatedToDate : today,
      isfrom ? this.CreatedFromDate : this.CreatedToDate
    );
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage("Invalid Day, Month or Year Range Detected.");
      showfocuselement = true;
    }
    if (this.hasValue(daysDifference) && daysDifference < 0) {
      if (isfrom) {
        this.ShowErrorMessage(
          "Created From Date Should be Less Than or Equal to Created To Date"
        );
        this.CreatedFromDate = "";
      } else {
        this.CreatedToDate = "";
        this.ShowErrorMessage("Future Dates Not allowed");
      }
      showfocuselement = true;
    }
    if (showfocuselement) {
      const elementFound =
        this.StatisticsReportTickets.nativeElement.querySelector(
          '[name="' + name + '"]'
        );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    return true;
  }

  private applyclassesforTasksCreatedDatesSelected(data: any) {
    if (this.hasValue(this.CreatedFromDate)) {
      if (!this.hasValue(this.CreatedToDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created To Date.");
        return false;
      }
    }
    if (this.hasValue(this.CreatedToDate)) {
      if (!this.hasValue(this.CreatedFromDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created From Date.");
        return false;
      }
    }
    if (this.hasValue(this.CreatedFromDate)) {
      let fromTime = this.CreatedFromTime
        ? formatDate(this.CreatedFromTime, "HH:mm:ss", "en-US", "+0530")
        : "00:00:00";
      data.createdFromDate =
        this.formatDateValue(this.CreatedFromDate) + " " + fromTime;
    } else {
      data.createdFromDate = "";
    }
    if (this.hasValue(this.CreatedToDate)) {
      let toTime = this.CreatedToTime
        ? formatDate(this.CreatedToTime, "HH:mm:ss", "en-US", "+0530")
        : "00:00:00";
      data.createdToDate =
        this.formatDateValue(this.CreatedToDate) + " " + toTime;
    } else {
      data.createdToDate = "";
    }
    if (this.tasksCreatedFromDateChange() == false) return false;
    if (this.tasksCreatedTODateChange() == false) return false;
  }

  getRowStyle(params) {
    // console.log(params, "params");
    if (params.data.ReadorUnread === 1) {
      return { "font-weight": "900" };
    } else {
      return { "font-weight": "500" };
    }
  }

  OnClickOpenandClose(isTrueorFalse) {
    let selectedTask = this.getSelectedTaskInfo();
    if (selectedTask.ReadorUnread !== isTrueorFalse)
      this.OnclickMarkasReadorUnReadFN(
        selectedTask.TaskInfoID,
        isTrueorFalse,
        true
      );
  }

  OnclickMarkasReadorUnReadFN(selectedID, isTrueorFalse, isRefresh) {
    let payload = {
      TaskInfoID: selectedID,
      TicketReadorUnread: isTrueorFalse,
    };
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "UpdatedTicketReadandUnReadStatus", payload)
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
        if (isRefresh === true) {
          this.statisticsReportTicketsGetMajorTopicsListClick(
            undefined,
            undefined,
            false
          );
        }
      });
  }
}
