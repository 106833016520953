import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { Config } from '../config';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { TaskService } from '../services/task.service';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { TimePickerModule } from '@progress/kendo-angular-dateinputs';
@Component({
  selector: 'app-pratices-list',
  templateUrl: './pratices-list.component.html',
  styleUrls: ['./pratices-list.component.css']
})
export class PraticesListComponent extends commonhelper implements OnInit {
  private commonhelperobj: commonHelperService;
  @ViewChild('practicegrid') practicegrid: AgGridNg2;
  public practiceListGridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public selectedRowsString: {};
  public SearchPracticeID: any = '';
  public SearchPracticeName: any = '';
  public overlayNoRowsTemplate;
  columnDefs = [
    { headerName: 'Practice ID', field: 'PracticeId', width: 140, cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Practice Name', field: 'PracticeName', width: 360, cellClass: 'no-border cell-wrap-text', autoHeight: true },
  ];
  rowData = [];
  // rowSelection = 'single';
  constructor(private taskService: TaskService, private dialogService: DialogService, private dialog: DialogRef) {
    super();
    this.overlayNoRowsTemplate = '<span style=\'Font-size:16px;color:#00529B;background-color:#BDE5F8;margin: 50px 0px;padding: 12px;border-radius: 4px;\'>No Data Available</span>';
  }

  ngOnInit() {
    this.employeeTaskList(true);
    setTimeout(function () {
      document.getElementById("divSearchPracticeName").focus();
    }, 50);
  }
  onSelectionChanged(event) {
    const selectedRows = this.practicegrid.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;

    });
    // console.log(this.selectedRowsString);
  }
  employeeTaskList(isfrom) {
    let postData;


    if (this.hasValue(this.SearchPracticeID)) {
      this.SearchPracticeID = this.SearchPracticeID;
    } else {
      this.SearchPracticeID = undefined;
    }

    if (this.hasValue(this.SearchPracticeName)) {
      this.SearchPracticeName = this.SearchPracticeName;
    } else {
      this.SearchPracticeName = '';
    }
    postData = {
      PracticeId: this.SearchPracticeID,
      PracticeName: this.SearchPracticeName
    };

    this.taskService.PracticesList(postData).subscribe(response => {
      if (this.isError(response)) {
        return;
      }
      this.rowData = response;
      this.selectedRowsString = '';
      if(isfrom)
       document.getElementById("divSearchPracticeName").focus();
    });
  }
  OKClickEvent() {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage('Please Select Practice Information');
      document.getElementById("divSearchPracticeName").focus();
      return false;
    }
    if (this.hasValue(this.selectedRowsString)) {
      this.dialog.close(this.selectedRowsString);
    }
  }
  CancelClickEvent() {
    this.dialog.close();
  }
  onlyNumberKey(event) {
    return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

}
