import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  DialogCloseResult,
  DialogRef,
  DialogService,
} from "@progress/kendo-angular-dialog";
import { IntlService } from "@progress/kendo-angular-intl";
import {
  CancelEvent,
  FileInfo,
  RemoveEvent,
  SelectEvent,
} from "@progress/kendo-angular-upload";
import { AdditionalAndObserversForPracticePopupComponent } from "../additional-and-observers-for-practice-popup/additional-and-observers-for-practice-popup.component";
import { AdditionalParticipantAndObserversPopupComponentComponent } from "../additional-participant-and-observers-popup-component/additional-participant-and-observers-popup-component.component";
import { commonHelperService } from "../common/common.service";
import { commonhelper } from "../common/commonhelper";
import { Config } from "../config";
import { CreateNewTemplateComponent } from "../create-new-template/create-new-template.component";
import { TaskEventTypeEnum } from "../enums/TaskHistoryChangeEvent.enum.";
import { UploadState } from "../enums/upload-state.enum";
import { Attachment } from "../interfaces/attachment";
import { TaskChangeEvent } from "../interfaces/TaskChangeEvent";
import { PracticeUserCcPersonPopupComponent } from "../practice-user-cc-person-popup/practice-user-cc-person-popup.component";
import { PracticeUsersCustomizationComponent } from "../practice-users-customization/practice-users-customization.component";
import { PracticeUsersListComponent } from "../practice-users-list/practice-users-list.component";
import { PraticesListComponent } from "../pratices-list/pratices-list.component";
import { ResponsiblepersonUsersListComponent } from "../responsibleperson-users-list/responsibleperson-users-list.component";
import { SaveTemplateNamePopupComponent } from "../save-template-name-popup/save-template-name-popup.component";
import { SelectModulePopupComponentComponent } from "../select-module-popup-component/select-module-popup-component.component";
import { AttachmentService } from "../services/attachment.service";
import { FileUploadProgressService } from "../services/file-upload-progress.service";
import { TaskService } from "../services/task.service";
import { CreateTaskService } from "../shared/services/create-ticket.service";

@Component({
  selector: "app-create-new-customer-task",
  templateUrl: "./create-new-customer-task.component.html",
  styleUrls: ["./create-new-customer-task.component.css"],
})
export class CreateNewCustomerTaskComponent
  extends commonhelper
  implements OnInit
{
  @ViewChild("CreateNewCustomerTaskInfo")
  CreateNewCustomerTaskInfoEleRef: ElementRef;
  @ViewChild("TaskAddNewProjectName") TaskAddNewProjectNamefield: ElementRef;
  @ViewChild("CreateNewCustomerTaskInfo", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  createNewTemplatePopupWidth = 1500;
  createNewTemplatePopupHeight = 698;
  TaskAddInfoModel: any = {};
  ModuleListInfo: any;
  SeverityList: Array<string> = [];
  taskTypeList: any;
  SelectedPracticeInfo: any = {};
  SelectedResponsiblePersonInfo: any = {};
  NewCurrentWorkingStatusList: any;
  SelectedAdditionalResponsiblePersonName: string;
  SelectedCCPersonName: string;
  SelectedPracticeUserList: any = [];
  selectedAdditionalResponsiblePersons: any = [];
  selectedCCPersons: any = [];
  SelectedAdditionalResponsiblePersonInfo: any;
  SelectedCCPersonInfo: any;
  SelectedObservers: any = [];
  SelectedAssistantPerson: any = [];
  PopupDataFromMultiSelectUserSelectionPopup: any;
  SelectedObserversInfo: any;
  SelectedObserversPersonNames: string;
  SelectedAssistantPersonInfo: any = {};
  SelectedAssistantPersonName: string = "";
  taskAddNewTaskSelectTaskFromList = [
    { selectTaskFromListText: "Call", selectTaskFromListTextValue: 1 },
    { selectTaskFromListText: "Email", selectTaskFromListTextValue: 2 },
    { selectTaskFromListText: "Live Chat", selectTaskFromListTextValue: 3 },
    { selectTaskFromListText: "Sales Chat", selectTaskFromListTextValue: 4 },
  ];
  filesCount = 0;
  myFiles: Array<FileInfo> = [];
  defaultResponsibleItem = {
    EmployeeFullName: "-- Select --",
    EmployeeId: null,
  };
  defaultProjectItem = { Description: "-- Select --", ProjectId: null };
  defaultModuleItem = { Description: "-- Select --", ModuleId: null };
  defaultSeverityItem = {
    IssueSeverityDesc: "-- Select --",
    IssueSeverityID: null,
  };
  defaultTaskTypeItem = {
    TaskTypeDescription: "-- Select --",
    TaskTypeId: null,
  };
  defaultTaskAssignedViaItem = {
    TaskAssignedViaDesc: "-- Select --",
    TaskAssignedViaID: null,
  };
  defaultCurrentTaskItem = {
    Task_Current_Working_Status: "--Task Current Working Status--",
    Task_Current_Working_Status_InfoID: null,
  };
  defaultCurrentTaskFromItem = {
    selectTaskFromListText: "- Select -",
    selectTaskFromListTextValue: 0,
  };
  EmployeesAndRolesModelList: Array<string> = [];
  IntimationEmailUserIDs: Array<number> = [];
  EmployeesAndRolesModel: any = {};
  AttamentsContainerforImagesFromEditor = [] as any;
  IsresponsiblePersonAndFwdToSame: boolean = false;
  fromInternalTicketNavigation = false;
  createNewTaskButtonsShowHide: boolean;

  EHRPracticeID: number = -1;
  ResponsiblePerson: number = -1;
  // tslint:disable-next-line:member-ordering
  uploadSaveUrl = "saveUrl"; // should represent an actual API endpoint
  // tslint:disable-next-line:member-ordering
  uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint

  constructor(
    private dialogService: DialogService,
    private commonhelperobj: commonHelperService,
    private taskService: TaskService,
    private uploadStateService: FileUploadProgressService,
    private attachmentsService: AttachmentService,
    private intl: IntlService,
    private _dialog: DialogRef,
    private _Router: Router,
    private _createNewTaskService: CreateTaskService
  ) {
    super();
    setTimeout(() => {
      if (this.fromInternalTicketNavigation) {
        debugger;
        this.createNewTaskButtonsShowHide = true;
      }
    }, 500);
  }
  ngOnInit() {
    this.GetSeverityList();
    this.GetTaskTypes();
    this.GetCurrentStatusList();
  }

  //#region " TODO DOUBT "
  // createNewTaskCreateNewTemplateLinkClick() {
  //   this.addinternalpopupmethodCalling();
  //   const dialogRef = this.dialogService.open({
  //     title: 'Create New Template',
  //     content: CreateNewTemplateComponent,
  //     height: this.createNewTemplatePopupHeight,
  //     width: this.createNewTemplatePopupWidth,
  //     appendTo: this.ContainerRef
  //   });
  // }
  // private addinternalpopupmethodCalling() {
  //   if ($(window).width() >= 1920) {
  //     this.createNewTemplatePopupWidth = 1850
  //     this.createNewTemplatePopupHeight = 740
  //   }
  //   else if ($(window).width() >= 1680 && $(window).width() <= 1720) {
  //     this.createNewTemplatePopupWidth = 1600
  //     this.createNewTemplatePopupHeight = 740
  //   }

  // }
  //#endregion

  //#region " Module POPUP "
  CreateTaskmoduleIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Module",
      // Show component
      content: SelectModulePopupComponentComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.TaskAddInfoModel.ModuleName)) {
        } else {
          this.TaskAddInfoModel.ModuleName = "";
        }
      } else {
        this.ModuleListInfo = result;
        if (this.hasValue(this.ModuleListInfo)) {
          // this.TaskAddInfoModel.ModuleId = this.ModuleListInfo.ModuleId;
          // this.TaskAddInfoModel.ModuleName = this.ModuleListInfo.Description;
          this.TaskAddInfoModel.ModuleId =
            this.ModuleListInfo.IssueSupportGroupID;
          this.TaskAddInfoModel.ModuleName =
            this.ModuleListInfo.IssueSupportGroupDesc;
        }
      }
    });
  }
  CreateTaskRemoveModuleInfo() {
    this.TaskAddInfoModel.ModuleId = undefined;
    this.TaskAddInfoModel.ModuleName = "";
    document.getElementById("spanSelectModuleIcon").focus();
  }
  //#endregion

  //#region " Severity List"
  GetSeverityList() {
    const PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "OASIssueSeverityListView",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.SeverityList = serviceResponse;
      });
  }
  //#endregion

  //#region " Task Types"
  private GetTaskTypes() {
    this.taskService.taskTypeList().subscribe((response) => {
      if (this.isError(response)) {
        return;
      }
      this.taskTypeList = response;
    });
  }
  //#endregion

  //#region  " Required Date "
  onRequiredByDateChange(value: Date) {
    if (!this.hasValue(this.TaskAddInfoModel.RequiredByDate)) {
      this.TaskAddInfoModel.RequiredByDate = "";
      this.CreateNewCustomerTaskInfoEleRef.nativeElement.querySelector(
        "#TaskAddNewExpectedDeliveryDate"
      ).value = "";
      const elementFound =
        this.CreateNewCustomerTaskInfoEleRef.nativeElement.querySelector(
          '[name="' + "TaskAddNewExpectedDeliveryDatename" + '"]'
        );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].value = "";
      }
      return;
    }

    var today = new Date();
    var requiredByDate = new Date(this.TaskAddInfoModel.RequiredByDate);
    const daysDifference = this.DateDiffInDays(
      today,
      this.TaskAddInfoModel.RequiredByDate
    );
    const yearsDifference = requiredByDate.getFullYear() - today.getFullYear();

    if (this.hasValue(yearsDifference) && yearsDifference > 100) {
      this.ShowErrorMessage("Invalid Day, Month or Year Range Detected.");
      const elementFound =
        this.CreateNewCustomerTaskInfoEleRef.nativeElement.querySelector(
          '[name="' + "TaskAddNewExpectedDeliveryDatename" + '"]'
        );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return;
    }
  }
  //#endregion

  //#region  " Practice Info "
  practiceIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Practice",
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.TaskAddInfoModel.PracticeName)) {
        } else {
          this.TaskAddInfoModel.PracticeName = "";
        }
      } else {
        this.SelectedPracticeInfo = result;
        if (this.hasValue(this.SelectedPracticeInfo)) {
          this.TaskAddInfoModel.PracticeName =
            this.SelectedPracticeInfo.PracticeName;
          this.TaskAddInfoModel.EHRPracticeID =
            this.SelectedPracticeInfo.PracticeId;
          this.EHRPracticeID = this.SelectedPracticeInfo.PracticeId;
        }
      }
    });
  }
  RemovePracticeInfo() {
    this.SelectedPracticeInfo.PracticeName = "";
    this.SelectedPracticeInfo.EHRPracticeID = undefined;
    this.TaskAddInfoModel.EHRPracticeID = undefined;
    this.TaskAddInfoModel.PracticeName = "";
    document.getElementById("spanSelectPracticeIcon").focus();
  }
  //#endregion

  //#region " StatusList"
  private GetCurrentStatusList() {
    var PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "NewCurrentWorkingStatusList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.NewCurrentWorkingStatusList =
          serviceResponse.newCurrentWorkingStatusListModelList;
      });
  }
  //#endregion

  //#region " Kendo Handlers "
  public selectEventHandler(event: SelectEvent): void {
    this.uploadStateService.setUploadState(UploadState.uploadInProgress);
    this.filesCount += event.files.length;
  }
  cancelEventHandler(event: CancelEvent) {
    this.filesCount--;
    if (this.filesCount === 0) {
      this.uploadStateService.setUploadState(UploadState.removedAllFiles);
    }
  }
  public removeEventHandler(event: RemoveEvent) {
    this.filesCount--;
    if (this.filesCount === 0) {
      this.uploadStateService.setUploadState(UploadState.removedAllFiles);
    }
  }
  public completeEventHandler(event: Event) {
    this.uploadStateService.setUploadState(UploadState.uploadComplete);
  }
  //#endregion

  //#region " Responsible "
  responsiblePersonIconClickEvent() {
    // console.log(this.EHRPracticeID <= 0);
    if (this.EHRPracticeID <= 0) {
      this.ShowErrorMessage("Please Select Practice Dertails.");
      return;
    }
    const dialogRef = this.dialogService.open({
      title: "Select Responsible Person",
      // Show component
      content: PracticeUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      PracticeId: this.TaskAddInfoModel.EHRPracticeID,
    };
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedResponsiblePersonInfo)) {
        } else {
          this.SelectedResponsiblePersonInfo = "";
        }
      } else {
        this.SelectedResponsiblePersonInfo = result;
        if (this.hasValue(this.SelectedResponsiblePersonInfo)) {
          // console.log(result,"Result");

          this.TaskAddInfoModel.SelectedResponsiblePerson =
            this.SelectedResponsiblePersonInfo.UserID;
          this.SelectedResponsiblePersonInfo.EmployeeId =
            this.SelectedResponsiblePersonInfo.UserID;
          this.SelectedResponsiblePersonInfo.EmployeeFullName =
            this.SelectedResponsiblePersonInfo.EmployeeName;
          this.ResponsiblePerson = this.SelectedResponsiblePersonInfo.UserID;
        }
      }
    });
  }

  RemoveResponsiblePersonInfo() {
    this.TaskAddInfoModel.SelectedResponsiblePerson = undefined;
    this.SelectedResponsiblePersonInfo.EmployeeId = undefined;
    this.SelectedResponsiblePersonInfo.EmployeeFullName = "";
  }
  //#endregion

  //#region  " Additional Responsible Person "
  AdditionalResponsiblePersonIconClickEvent() {
    // console.log(this.EHRPracticeID <= 0);
    // if (this.EHRPracticeID <= 0) {
    //   this.ShowErrorMessage("Please Select Practice Dertails.");
    //   return;
    // }
    // if (this.ResponsiblePerson <= 0) {
    //   this.ShowErrorMessage("Please Select Responsible Person Dertails.");
    //   return;
    // }
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    // dialogRef.content.instance.datatoPopup = {
    //   PracticeId: this.TaskAddInfoModel.EHRPracticeID,
    // };
    //dialogRef.content.instance.IsFromForwardToPopup = true;
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData =
      this.selectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData =
      this.SelectedObservers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData =
      this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      // console.log(result);
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
        } else {
          this.SelectedAdditionalResponsiblePersonInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        //********assigning addl responsible resons info************
        this.SelectedAdditionalResponsiblePersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.selectedAdditionalResponsiblePersons =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );
          this.SelectedAdditionalResponsiblePersonName =
            this.SelectedAdditionalResponsiblePersonName.substring(
              0,
              this.SelectedAdditionalResponsiblePersonName.length - 2
            );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        //*****assigning observers data****************
        this.SelectedObserversInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.SelectedObservers =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedObserversPersonNames =
            this.SelectedObserversPersonNames.substring(
              0,
              this.SelectedObserversPersonNames.length - 2
            );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        this.SelectedAssistantPersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        this.SelectedAssistantPerson =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedAssistantPersonName =
            this.SelectedAssistantPersonName.substring(
              0,
              this.SelectedAssistantPersonName.length - 2
            );
        } else {
          this.SelectedAssistantPersonName = "";
        }
      }
    });
  }

  RemoveAdditionalResponsiblePersonInfo() {
    this.SelectedAdditionalResponsiblePersonName = "";
    this.selectedAdditionalResponsiblePersons = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }

  //#endregion

  //#region " Observer Details "
  ObserversIconClickEvent() {
    // console.log(this.EHRPracticeID <= 0);
    // if (this.EHRPracticeID <= 0) {
    //   this.ShowErrorMessage("Please Select Practice Dertails.");
    //   return;
    // }
    // if (this.ResponsiblePerson <= 0) {
    //   this.ShowErrorMessage("Please Select Responsible Person Dertails.");
    //   return;
    // }
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    // dialogRef.content.instance.IsFromForwardToPopup = true;
    // dialogRef.content.instance.datatoPopup = {
    //   PracticeId: this.TaskAddInfoModel.EHRPracticeID,
    // };
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData =
      this.selectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData =
      this.SelectedObservers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData =
      this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      // console.log(result);
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedObserversInfo)) {
        } else {
          this.SelectedObserversInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        //********assigning addl responsible resons info************
        this.SelectedAdditionalResponsiblePersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.selectedAdditionalResponsiblePersons =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );
          this.SelectedAdditionalResponsiblePersonName =
            this.SelectedAdditionalResponsiblePersonName.substring(
              0,
              this.SelectedAdditionalResponsiblePersonName.length - 2
            );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        //*****assigning observers data****************
        this.SelectedObserversInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.SelectedObservers =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedObserversPersonNames =
            this.SelectedObserversPersonNames.substring(
              0,
              this.SelectedObserversPersonNames.length - 2
            );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        this.SelectedAssistantPersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        this.SelectedAssistantPerson =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedAssistantPersonName =
            this.SelectedAssistantPersonName.substring(
              0,
              this.SelectedAssistantPersonName.length - 2
            );
        } else {
          this.SelectedAssistantPersonName = "";
        }
      }
    });
  }
  RemoveObserversIconClickEvent() {
    this.SelectedObserversPersonNames = "";
    this.SelectedObservers = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }
  //#endregion

  //#region " Save Task"
  // Save Task Info
  async SaveTaskDetails(isTemplate) {
    // alert(this.TaskAddInfoModel.SelectedTaskBody.value);

    if (!this.hasValue(this.TaskAddInfoModel.ModuleId)) {
      this.ShowErrorMessage("Please Select Module");
      const elementFound =
        this.CreateNewCustomerTaskInfoEleRef.nativeElement.querySelector(
          '[name="' + "TaskAddNewModuleName" + '"]'
        );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }

    if (this.EHRPracticeID <= 0) {
      this.ShowErrorMessage("Please Select Practice Dertails.");
      return;
    }

    if (!this.hasValue(this.TaskAddInfoModel.IssueSubject)) {
      this.ShowErrorMessage("Please Enter Task Subject");
      this.CreateNewCustomerTaskInfoEleRef.nativeElement
        .querySelector("#TaskAddNewSubject")
        .focus();
      return;
    }
    // if (
    //   !this.hasValue(this.TaskAddInfoModel.Task_Current_Working_Status_InfoID)
    // ) {
    //   // this.ShowErrorMessage("Please Select Current Working Location");
    //   //this.CreateNewCustomerTaskInfoEleRef.nativeElement.querySelector('#TaskAddNewCurrentWokringStatus').focus();
    //   const elementFound = this.CreateNewCustomerTaskInfoEleRef.nativeElement.querySelector(
    //     '[name="' + "createNewTaskCurrentStatus" + '"]'
    //   );
    //   // if (this.hasValue(elementFound)) {
    //   //   elementFound.children[0].focus();
    //   // }
    //   return;
    // }

    if (!this.hasValue(this.TaskAddInfoModel.SelectedTaskBody)) {
      this.ShowErrorMessage("Please Enter Task Details");
      const elementFound =
        this.CreateNewCustomerTaskInfoEleRef.nativeElement.querySelector(
          '[name="' + "TaskAddNewTaskDetails" + '"]'
        );
      if (this.hasValue(elementFound)) {
        elementFound.children[1].children[0].focus();
      }
      return;
    }

    if (!this.hasValue(this.TaskAddInfoModel.SelectedResponsiblePerson)) {
      this.ShowErrorMessage("Please Select Responsible Person.");
      const elementFound =
        this.CreateNewCustomerTaskInfoEleRef.nativeElement.querySelector(
          '[name="' + "TaskAddNewResponsiblePerson" + '"]'
        );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }

    const daysDifference = this.DateDiffInDays(
      new Date(),
      this.TaskAddInfoModel.RequiredByDate
    );
    if (this.hasValue(daysDifference) && daysDifference > 0) {
      this.ShowErrorMessage(
        "Required By Date Should be Greater than or Equal to Current Date."
      );
      const elementFound =
        this.CreateNewCustomerTaskInfoEleRef.nativeElement.querySelector(
          '[name="' + "TaskAddNewExpectedDeliveryDatename" + '"]'
        );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return;
    }

    if (!this.uploadStateService.isValidState()) {
      this.ShowErrorMessage("Please Wait till the files are uploaded");
      return;
    }

    let attachmentContainer: Array<Attachment> =
      await this.attachmentsService.processUploadAttachments(this.myFiles);

    if (
      this.attachmentsService.duplicatesExists(
        attachmentContainer as Array<Attachment>
      )
    ) {
      this.ShowErrorMessage(
        "Duplicates Exists in Uploaded Attachments Please Try Again."
      );
      return;
    }

    if (
      !this.attachmentsService.uploadedAttachmentsCompatable(
        attachmentContainer
      )
    ) {
      this.ShowErrorMessage(
        "Uploaded attachments contain files other than JPG,JPEG,PNG,DocX,Doc,XLSX,XLs and PDF files."
      );
      this.myFiles = [];
      return;
    }

    if (
      this.hasValue(this.SelectedAssistantPerson) &&
      this.SelectedAssistantPerson.length > 0
    ) {
      this.SelectedAssistantPerson.forEach((selectedRow, index) => {
        if (
          this.SelectedResponsiblePersonInfo.EmployeeId ==
          selectedRow.EmployeeId
        ) {
          this.IsresponsiblePersonAndFwdToSame = true;
        }
      });
    }

    // Promise.all(promises).then(() => {
    if (this.hasValue(this.TaskAddInfoModel.SelectedTaskBody)) {
      const body: Attachment = {
        AttachmentBase64Format: window.btoa(
          unescape(encodeURIComponent(this.TaskAddInfoModel.SelectedTaskBody))
        ),
        AttachmentType: 1,
      };

      attachmentContainer.push(body);
    }
    if (
      this.hasValue(this.AttamentsContainerforImagesFromEditor) &&
      this.AttamentsContainerforImagesFromEditor.length > 0
    ) {
      for (
        let index2 = 0;
        index2 < this.AttamentsContainerforImagesFromEditor.length;
        index2++
      ) {
        const Attachment = {
          AttachmentBase64Format:
            this.AttamentsContainerforImagesFromEditor[index2]
              .AttachmentBase64Format,
          AttachmentType: 2,
        };

        attachmentContainer.push(Attachment);
      }
    }

    if (
      this.hasValue(this.selectedAdditionalResponsiblePersons) &&
      this.selectedAdditionalResponsiblePersons.length > 0
    ) {
      // console.log(this.selectedAdditionalResponsiblePersons);
      this.selectedAdditionalResponsiblePersons.forEach((element) => {
        this.EmployeesAndRolesModel = {
          EmployeeId: element.EmployeeId,
          RoleID: 4,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }

    if (
      this.hasValue(this.SelectedObservers) &&
      this.SelectedObservers.length > 0
    ) {
      // console.log(this.SelectedObservers);
      this.SelectedObservers.forEach((element) => {
        // console.log(element);
        this.EmployeesAndRolesModel = {
          EmployeeId: element.EmployeeId,
          RoleID: 3,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }

    // console.log(this.TaskAddInfoModel);
    if (this.hasValue(this.TaskAddInfoModel.SelectedResponsiblePerson)) {
      this.EmployeesAndRolesModel = {
        EmployeeId: this.TaskAddInfoModel.SelectedResponsiblePerson,
        RoleID: 2,
      };
      this.IntimationEmailUserIDs.push(
        this.TaskAddInfoModel.SelectedResponsiblePerson
      );
      this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
    }
    if (
      this.hasValue(this.selectedCCPersons) &&
      this.selectedCCPersons.length > 0
    ) {
      // console.log(this.SelectedObservers);
      this.selectedCCPersons.forEach((element) => {
        // console.log(element);
        this.EmployeesAndRolesModel = {
          EmployeeId: element.UserID,
          RoleID: 5,
        };
        this.IntimationEmailUserIDs.push(element.UserID);
        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }
    if (
      this.hasValue(this.SelectedAssistantPerson) &&
      this.SelectedAssistantPerson.length > 0
    ) {
      this.SelectedAssistantPerson.forEach((Assistantselement) => {
        this.EmployeesAndRolesModel = {
          EmployeeId: Assistantselement.EmployeeID,
          RoleID: 7,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }

    if (this.EmployeesAndRolesModelList.length > 0) {
      this.TaskAddInfoModel.EmployeesAndRolesModelList =
        this.EmployeesAndRolesModelList;
    }

    if (attachmentContainer.length > 0) {
      this.TaskAddInfoModel.AttachmentsModelList = attachmentContainer;
    }

    this.TaskAddInfoModel.taskChangeEvents = [
      {
        Description: "Task Created",
        EventId: TaskEventTypeEnum.TaskCreated,
        TaskInfoId: 0,
      },
    ] as TaskChangeEvent[];
    if (
      this.hasValue(this.TaskAddInfoModel.TaskTypeId) &&
      this.hasValue(this.TaskAddInfoModel.TaskTypeId.TaskTypeId)
    ) {
      this.TaskAddInfoModel.TaskTypeId =
        this.TaskAddInfoModel.TaskTypeId.TaskTypeId;
    }
    // else {
    //   this.TaskAddInfoModel.TaskTypeId = undefined;
    // }
    this.TaskAddInfoModel.IsPublicTask = false;
    this.TaskAddInfoModel.IsTaskCreatedfromEHR = false;

    if (this.hasValue(this.TaskAddInfoModel.RequiredByDate)) {
      this.TaskAddInfoModel.RequiredByDate = this.formatValue(
        this.TaskAddInfoModel.RequiredByDate
      );
    }

    if (
      this.hasValue(this.TaskAddInfoModel.Task_Current_Working_Status_InfoID) &&
      this.hasValue(
        this.TaskAddInfoModel.Task_Current_Working_Status_InfoID
          .Task_Current_Working_Status_InfoID
      )
    ) {
      this.TaskAddInfoModel.Task_Current_Working_Status_InfoID =
        this.TaskAddInfoModel.Task_Current_Working_Status_InfoID.Task_Current_Working_Status_InfoID;
    }

    if (
      this.hasValue(this.TaskAddInfoModel.TaskCamefromType) &&
      this.hasValue(
        this.TaskAddInfoModel.TaskCamefromType.selectTaskFromListTextValue
      )
    ) {
      this.TaskAddInfoModel.TaskCamefromType =
        this.TaskAddInfoModel.TaskCamefromType.selectTaskFromListTextValue;
    }
    this.TaskAddInfoModel.IsTaskFrom = 3;
    this.TaskAddInfoModel.IssueSupportGroupID = this.TaskAddInfoModel.ModuleId;
    //Module ID not Required
    this.TaskAddInfoModel.ModuleId = null;
    // console.log(this.TaskAddInfoModel);
    setTimeout(() => {
      this.commonhelperobj
        .PostData(
          Config.OASAppnURL + "SaveReportOASIssueDetails",
          this.TaskAddInfoModel
        )
        .subscribe((serviceResponse) => {
          if (this.isError(serviceResponse)) {
            this.EmployeesAndRolesModelList = [];
            attachmentContainer = [];

            return;
          }
          let responseID: any = [];
          responseID.push(serviceResponse.ResponseID);
          this.sendTicketNotificationtoEhrPracticeEmployees(
            serviceResponse.ResponseID,
            this.IntimationEmailUserIDs,
            isTemplate
          );
          this.ShowSuccesseMessage("Task Details Saved Successfully.");
          this.CreateTaskCancelClick("Success");
        });
      // }
    }, 100);
  }

  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "d")}` : "";
  }

  CreateTaskCancelClick(message: string | undefined) {
    if (this.fromInternalTicketNavigation) {
      this._dialog.close(message);
    } else {
      const PostDataService = {};
      const outletObj = {};
      outletObj["main"] = [
        "app-common-message-info",
        this.setCommonData(PostDataService),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    }
  }

  private sendTicketNotificationtoEhrPracticeEmployees(
    TaskNumber,
    EmployeeIds,
    isTemplate
  ): void {
    this._createNewTaskService
      .sendTicketNotificationtoEhrPracticeEmployees({
        EmployeeIds: EmployeeIds,
        TaskNumber: TaskNumber,
        IssueSeverityID: this.TaskAddInfoModel.IssueSeverityID,
        isTemplateEnable:isTemplate
      })
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
      });
  }
  //#endregion

  // user selection
  userIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select CC Person",
      content: PracticeUserCcPersonPopupComponent, //PracticeUsersListComponent,
      height: 698,
      width: 700,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      StateMaintainUsersData: this.SelectedPracticeUserList,
      PracticeId: this.EHRPracticeID,
      //IssueSupportGroupID: this.TaskCustomizationModel.IssueSupportGroupID,
    };
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        this.SelectedCCPersonName = "";
        this.SelectedPracticeUserList = [];
      } else {
        this.SelectedPracticeUserList = [];
        this.SelectedPracticeUserList = result;
      }
      // console.log(this.SelectedPracticeUserList);
      this.SelectedCCPersonInfo = this.SelectedPracticeUserList;
      this.selectedCCPersons = this.SelectedPracticeUserList;
      if (this.hasValue(this.SelectedCCPersonInfo)) {
        this.SelectedCCPersonName = "";
        this.SelectedCCPersonInfo.forEach((selectedRow, index) => {
          this.SelectedCCPersonName += selectedRow.EmployeeName + ", ";
        });
        this.SelectedCCPersonName = this.SelectedCCPersonName.substring(
          0,
          this.SelectedCCPersonName.length - 2
        );
      } else {
        this.SelectedCCPersonName = "";
      }
    });
  }

  onclickRemoveCCPerson() {
    this.SelectedCCPersonName = "";
    this.SelectedCCPersonInfo = [];
    this.selectedCCPersons = [];
  }
}
