import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { commonHelperService } from "src/app/common/common.service";
import { Config } from "src/app/config";

@Injectable()
export class CreateTaskService extends commonHelperService {
  constructor(http: HttpClient) {
    super(http);
  }

  sendInternalTicketNotificationtoEmployees(postData: Iemployee) {
    return this.PostData(
      Config.OASAppnURL + "sendInternalTicketNotificationtoEmployees",
      postData
    ).pipe((response) => {
      return response;
    });
  }

  sendTicketNotificationtoEhrPracticeEmployees(postData: Iemployee) {
    return this.PostData(
      Config.OASAppnURL + "sendTicketNotificationtoEHRPracticeUsers",
      postData
    ).pipe((response) => {
      return response;
    });
  }
}

export interface Iemployee {
  EmployeeIds?: Array<number>;
  EmployeeId?: number | string;
  TaskNumber: number;
  IssueSeverityID: number;
  isTemplateEnable?:boolean;
}
