import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';;
import { DialogRef } from '@progress/kendo-angular-dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TaskService } from '../services/task.service';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { DateInputComponent } from '@progress/kendo-angular-dateinputs';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-qareview-details-popup',
  templateUrl: './qareview-details-popup.component.html',
  styleUrls: ['./qareview-details-popup.component.css']
})
export class QAReviewDetailsPopupComponent implements OnInit {

  @ViewChild('QAReviewForm') QAReviewForm: ElementRef;

  @ViewChild('qaReviewDetailsSelectDate') qaReviewDetailsSelectDate: DateInputComponent;

  @ViewChild('txtQAreviewDetailsITSTaskNumber') txtQAreviewDetailsITSTaskNumber: NumericTextBoxComponent;
  @ViewChild('txtQAreviewDetailsNoofFunctionalityIssues') txtQAreviewDetailsNoofFunctionalityIssues: NumericTextBoxComponent;
  @ViewChild('txtQAreviewDetailsNoofUIIssues') txtQAreviewDetailsNoofUIIssues: NumericTextBoxComponent;
  @ViewChild('txtQAreviewDetailsNoofRunTimeErrors') txtQAreviewDetailsNoofRunTimeErrors: NumericTextBoxComponent;
  @ViewChild('txtQAreviewDetailsTotalNumberofNavigations') txtQAreviewDetailsTotalNumberofNavigations: NumericTextBoxComponent;
  
  selectedQATaskToAddReview: any;

  qAReviewFormGroup: FormGroup;

  // QA Lead
  qAreviewDetailsSelectQAModuleLeadList: any[];
  qAreviewDetailsSelectQAModuleLeadDefaultItem = { EmployeeFullName: '-- Select QA Team Lead --', EmployeeId: null };

  // C# Lead
  qAreviewDetailsSelectCSharpTeamLeadList: any[];
  qAreviewDetailsSelectCSharpTeamLeadDefaultItem = { EmployeeFullName: '-- Select C# Team Lead --', EmployeeId: null };

  // UI Developer
  qAreviewDetailsSelectUIDeveloperList: any[];
  qAreviewDetailsSelectUIDeveloperDefaultItem = { EmployeeFullName: '-- Select UI Developer --', EmployeeId: null };
 
  // Angular Developer
  qAreviewDetailsSelectAngularDeveloperList: any[];
  qAreviewDetailsSelectAngularDeveloperDefaultItem = { EmployeeFullName: '-- Select Angular Developer Lead --', EmployeeId: null };

  // C# Developer
  qAreviewDetailsSelectCSharpDeveloperList: any[];
  qAreviewDetailsSelectCSharpDeveloperDefaultItem = { EmployeeFullName: '-- Select C# Developer Lead --', EmployeeId: null };

  constructor(
    private dialog: DialogRef,
    private taskService: TaskService,
    private commonHelper: commonhelper,
    private commonHelperService: commonHelperService) { }

  async ngOnInit() {
    this.initializeEmptyQAGroup();

    await this.getQATeamLeadData();
    await this.getCSharpTeamLeadData();
    await this.getUIDeveloperData();
    await this.getAngularDeveloperData();
    await this.getCSharpDeveloperData();
    await this.getSavedQAReview();
  }

  // API Call to get QA Team lead Data
  private async getQATeamLeadData() {
    const data = {
      IsTaskReviewLeadEmployee: false,
      TaskReviewDepartmentInfoID: 2
    }
    const response = await this.taskService.taskReviewTeamLeadList(data).toPromise()
    if (this.commonHelper.isError(response)) {
      return;
    }
    this.qAreviewDetailsSelectQAModuleLeadList = response;
  }

  // API Call to get C# Team lead Data
  private async getCSharpTeamLeadData() {
    const data = {
      IsTaskReviewLeadEmployee: false,
      TaskReviewDepartmentInfoID: 1
    }
    const response = await this.taskService.taskReviewTeamLeadList(data).toPromise()
    if (this.commonHelper.isError(response)) {
      return;
    }
    this.qAreviewDetailsSelectCSharpTeamLeadList = response;
  }

  // API Call to get UI Developer Data
  private async getUIDeveloperData() {
    const data = {
      IsTaskReviewLeadEmployee: true,
      TaskReviewDepartmentInfoID: 4
    }
    const response = await this.taskService.taskReviewTeamLeadList(data).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }
    this.qAreviewDetailsSelectUIDeveloperList = response;
  }

  // API Call to get Angular Developer Data
  private async getAngularDeveloperData() {
    const data = {
      IsTaskReviewLeadEmployee: true,
      TaskReviewDepartmentInfoID: 3
    }
    const response = await this.taskService.taskReviewTeamLeadList(data).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }
    this.qAreviewDetailsSelectAngularDeveloperList = response;
  }

  // API Call to get CSharp Developer Data
  private async getCSharpDeveloperData() {
    const data = {
      IsTaskReviewLeadEmployee: true,
      TaskReviewDepartmentInfoID: 1
    }
    const response = await this.taskService.taskReviewTeamLeadList(data).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }
    this.qAreviewDetailsSelectCSharpDeveloperList = response;
  }

  // Get Previouslgy saved QA Review Data
  private async getSavedQAReview() {

    const preparePayloadToGetTaskReview = {
      TaskNumber: this.selectedQATaskToAddReview.TaskNumber,
      TaskDoneByTeamLeadId: 0,
      TaskDoneByEmployeeId: 0,
    }
    const response = await this.taskService.getTaskReviewAPIAction(preparePayloadToGetTaskReview).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }
  }

  initializeEmptyQAGroup() {
    this.qAReviewFormGroup = new FormGroup({
      qAReviewDetailsEnterDateFormControl: new FormControl(null, Validators.required),
      qAReviewDetailsEnterTaskNameFormControl: new FormControl(this.selectedQATaskToAddReview.IssueSubject, Validators.required),
      qAreviewDetailsITSTaskNumberFormControl: new FormControl(null, Validators.required),

      qAreviewDetailsSelectQAModuleLeadFormControl: new FormControl(null, Validators.required),
      qAreviewDetailsSelectCSharpTeamLeadFormControl: new FormControl(null, Validators.required),
      qAreviewDetailsSelectUIDeveloperFormControl: new FormControl(null, Validators.required),
      qAReviewDetailsSelectAngularDeveloperFormControl: new FormControl(null, Validators.required),
      qAreviewDetailsSelectCSharpDeveloperFormControl: new FormControl(null, Validators.required),

      qAReviewDetailsNoofFunctionalityIssuesFormControl: new FormControl(null, Validators.required),
      qAreviewDetailsNoofUIIssuesFormControl: new FormControl(null, Validators.required),
      qAreviewDetailsNoofRunTimeErrorsFormControl: new FormControl(null, Validators.required),
      qAreviewDetailsTotalNumberofNavigationsFormControl: new FormControl(null, Validators.required),

      qAreviewDetailsNavigationsCoveredbyDeveloperFormControl: new FormControl(null, Validators.required),
      qAreviewDetailsNavigationsMissedbyDeveloperFormControl: new FormControl(null, Validators.required),
      qAreviewDetailsQALeadOverallCommentsaboutCurrentTaskFormControl: new FormControl(null, Validators.required),
    })
  }

  checkAllMandatoryFieldsAreFilled() {
    
    if (this.qAReviewFormGroup.get('qAReviewDetailsEnterDateFormControl').invalid) {
      this.qaReviewDetailsSelectDate.focus()
      this.commonHelperService.ShowErrorMessage('Select Date.');
      return true;
    } 

    if (this.qAReviewFormGroup.get('qAReviewDetailsEnterTaskNameFormControl').invalid) {
      this.QAReviewForm.nativeElement.querySelector(`#txtQAreviewDetailsEnterTaskName`).focus();    
      this.commonHelperService.ShowErrorMessage('Fill Task Name same as Devops name.');
      return true;
    }

    if (this.qAReviewFormGroup.get('qAreviewDetailsITSTaskNumberFormControl').invalid) {
      this.txtQAreviewDetailsITSTaskNumber.focus()
      this.commonHelperService.ShowErrorMessage('Add task ITS Number.');
      return true;
    }
    
    if (this.qAReviewFormGroup.get('qAreviewDetailsSelectQAModuleLeadFormControl').invalid) {
      const elementFound = this.QAReviewForm.nativeElement.querySelector('[name="' + 'qAreviewDetailsSelectQAModuleLeadDropdown' + '"]');
      elementFound.children[0].focus();
      this.commonHelperService.ShowErrorMessage('Add QA Team Lead.');
      return true;
    }
  
    if (this.qAReviewFormGroup.get('qAreviewDetailsSelectCSharpTeamLeadFormControl').invalid) {
      const elementFound = this.QAReviewForm.nativeElement.querySelector('[name="' + 'qAreviewDetailsSelectCSharpTeamLeadDropdown' + '"]');
      elementFound.children[0].focus();
      this.commonHelperService.ShowErrorMessage('Add C# Team Lead.');
      return true;
    }
  
    if (this.qAReviewFormGroup.get('qAreviewDetailsSelectUIDeveloperFormControl').invalid) {
      const elementFound = this.QAReviewForm.nativeElement.querySelector('[name="' + 'qAreviewDetailsSelectUIDeveloperDropdown' + '"]');
      elementFound.children[0].focus();
      this.commonHelperService.ShowErrorMessage('Add UI Developer.');
      return true;
    }
  
    if (this.qAReviewFormGroup.get('qAReviewDetailsSelectAngularDeveloperFormControl').invalid) {
      const elementFound = this.QAReviewForm.nativeElement.querySelector('[name="' + 'qAreviewDetailsSelectAngularDeveloperDropdown' + '"]');
      elementFound.children[0].focus();
      this.commonHelperService.ShowErrorMessage('Add Angular Developer.');
      return true;
    }
  
    if (this.qAReviewFormGroup.get('qAreviewDetailsSelectCSharpDeveloperFormControl').invalid) {
      const elementFound = this.QAReviewForm.nativeElement.querySelector('[name="' + 'qAreviewDetailsSelectCSharpDeveloperDropdown' + '"]');
      elementFound.children[0].focus();
      this.commonHelperService.ShowErrorMessage('Add C# Developer.');
      return true;
    }
    
    if (this.qAReviewFormGroup.get('qAReviewDetailsNoofFunctionalityIssuesFormControl').invalid) {
      this.txtQAreviewDetailsNoofFunctionalityIssues.focus()
      this.commonHelperService.ShowErrorMessage('Add No. of Functionality issues reported.');
      return true;
    }
    
    if (this.qAReviewFormGroup.get('qAreviewDetailsNoofUIIssuesFormControl').invalid) {
      this.txtQAreviewDetailsNoofUIIssues.focus()
      this.commonHelperService.ShowErrorMessage('Add No. of UI issues reported.');
      return true;
    }

    if (this.qAReviewFormGroup.get('qAreviewDetailsNoofRunTimeErrorsFormControl').invalid) {
      this.txtQAreviewDetailsNoofRunTimeErrors.focus()
      this.commonHelperService.ShowErrorMessage('Add No. of Run Time Errors occured.');
      return true;
    }

    if (this.qAReviewFormGroup.get('qAreviewDetailsTotalNumberofNavigationsFormControl').invalid) {
      this.txtQAreviewDetailsTotalNumberofNavigations.focus()
      this.commonHelperService.ShowErrorMessage('Add No. of Navigation Form Controls.');
      return true;
    }

    if (this.qAReviewFormGroup.get('qAreviewDetailsNavigationsCoveredbyDeveloperFormControl').invalid) {
      this.QAReviewForm.nativeElement.querySelector(`#txtQAreviewDetailsNavigationsCoveredbyDeveloper`).focus();    
      this.commonHelperService.ShowErrorMessage('Add Task Coding Standards commenting score.');
      return true;
    }

    if (this.qAReviewFormGroup.get('qAreviewDetailsNavigationsMissedbyDeveloperFormControl').invalid) {
      this.QAReviewForm.nativeElement.querySelector(`#txtQAreviewDetailsNavigationsMissedbyDeveloper`).focus();    
      this.commonHelperService.ShowErrorMessage('Add Task Lead Comments.');
      return true;
    }

    if (this.qAReviewFormGroup.get('qAreviewDetailsQALeadOverallCommentsaboutCurrentTaskFormControl').invalid) {
      this.QAReviewForm.nativeElement.querySelector(`#txtQAreviewDetailsQALeadOverallCommentsaboutCurrentTask`).focus();    
      this.commonHelperService.ShowErrorMessage('Add Task Lead Comments.');
      return true;
    }
  }

  async qAreviewDetailsSaveClick() {
    if (this.checkAllMandatoryFieldsAreFilled()) {
      return;
    }

    const preparePayloadForInsertReview = {
      TaskInfoId: this.selectedQATaskToAddReview.TaskInfoID,
      TaskNameFromDevops: this.qAReviewFormGroup.value.qAReviewDetailsEnterTaskNameFormControl,
      ITSTaskNumber: this.qAReviewFormGroup.value.qAreviewDetailsITSTaskNumberFormControl,
      TaskReviewDepartmentInfoId: 2, // For UI Review id is 4
      QATaskDonebyTeamLeadID: this.qAReviewFormGroup.value.qAreviewDetailsSelectQAModuleLeadFormControl,
      // QATaskDonebyEmployeeID: this.qAReviewFormGroup.value.UIFormGroupTaskNameFormControl,
      CSharpTaskDonebyTeamLeadID: this.qAReviewFormGroup.value.qAreviewDetailsSelectCSharpTeamLeadFormControl,
      UITaskDonebyTeamLeadID: this.qAReviewFormGroup.value.qAreviewDetailsSelectUIDeveloperFormControl,
      AngularTaskDonebyTeamLeadID: this.qAReviewFormGroup.value.qAReviewDetailsSelectAngularDeveloperFormControl,
      CSharpTaskDonebyEmployeeID: this.qAReviewFormGroup.value.qAreviewDetailsSelectCSharpDeveloperFormControl,
      NoOfFunctionalIssues: this.qAReviewFormGroup.value.qAReviewDetailsNoofFunctionalityIssuesFormControl,
      NoOfUIIssues: this.qAReviewFormGroup.value.qAreviewDetailsNoofUIIssuesFormControl,
      NoOfRunTimeErrors: this.qAReviewFormGroup.value.qAreviewDetailsNoofRunTimeErrorsFormControl,
      TotalNoOfNavigations: this.qAReviewFormGroup.value.qAreviewDetailsTotalNumberofNavigationsFormControl,
      NavigationsCoveredbyDeveloper: this.qAReviewFormGroup.value.qAreviewDetailsNavigationsCoveredbyDeveloperFormControl,
      NavigationsMissedbyDeveloper: this.qAReviewFormGroup.value.qAreviewDetailsNavigationsMissedbyDeveloperFormControl,
      TeamLeadOverAllComments: this.qAReviewFormGroup.value.qAreviewDetailsQALeadOverallCommentsaboutCurrentTaskFormControl,
      TaskDate: this.qAReviewFormGroup.value.qAReviewDetailsEnterDateFormControl ? this.qAReviewFormGroup.value.qAReviewDetailsEnterDateFormControl.toLocaleDateString() : '',
    }

    // console.log(preparePayloadForInsertReview);
    const response = await this.taskService.QATaskReviewInsertAPIAction(preparePayloadForInsertReview).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }

    this.commonHelperService.ShowErrorMessage('Task Review Added Successfully.');
    this.dialog.close();
  }

  qAreviewDetailsCancelClickEvent() {
    this.dialog.close();
  }

  qaReviewDetailsSelectDateBlurEvent(event) { }

}
