import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  DialogCloseResult,
  DialogRef,
  DialogService,
} from "@progress/kendo-angular-dialog";
import { PraticesListComponent } from "../pratices-list/pratices-list.component";
import { commonHelperService } from "../common/common.service";
import { commonhelper } from "../common/commonhelper";
import { Config } from "../config";
import { AgGridNg2 } from "ag-grid-angular";
import { ActivatedRoute, Router } from "@angular/router";
import { TaskService } from "../services/task.service";
import { ResponsiblepersonUsersListComponent } from "../responsibleperson-users-list/responsibleperson-users-list.component";
import {
  GetContextMenuItemsParams,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
  MenuItemDef,
} from "ag-grid-community";
import { CustomerTicketsService } from "./customer-tickets.service";
import { ExcelService } from "../common/excel.service";
import { IntlService, formatDate } from "@progress/kendo-angular-intl";
import { CreateNewCustomerTaskComponent } from "../create-new-customer-task/create-new-customer-task.component";
import { PracticeUsersListComponent } from "../practice-users-list/practice-users-list.component";
import { AddTaskTypePopupComponent } from "../add-task-type-popup/add-task-type-popup.component";
import { param } from "jquery";
import { CreateNewCustomerTicketPopUpComponent } from "../create-new-customer-ticket-pop-up/create-new-customer-ticket-pop-up.component";

import { SelectModulePopupComponentComponent } from "../select-module-popup-component/select-module-popup-component.component";

@Component({
  selector: "app-customer-tickets",
  templateUrl: "./customer-tickets.component.html",
  styleUrls: ["./customer-tickets.component.css"],
})
export class CustomerTicketsComponent extends commonhelper implements OnInit {
  @ViewChild("customerTicketsGrid") byCustomerGrid: AgGridNg2;
  @ViewChild("CustomerTickets") CustomerTickets: ElementRef;
  @ViewChild("CustomerTickets", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  private commonhelperobj: commonHelperService;
  NewCurrentWorkingStatusList: any;
  myclass: any;
  isCompletedTickets = false;
  isfromStatemaintainFilter: boolean = false;
  public taskTypeList: any;
  customerTicketsCurrentWorkingStatus: any;
  customerTicketsSelectedTaskType: any;
  customerTicketsSelectedType: any;
  public rowClassRules;
  FromRecords: any;
  PrevLabelCount: any;
  NextLabelCount: any;
  RecordsPerPage: any;
  TotalCountValue: boolean = true;
  TotalCountValueIcon: boolean = false;
  TotalLabelCount: any;
  TotalLabelCountValue: any;
  fromrecordsfromcount: any;
  IssueSupportGroupDesc: any;
  prevnextbuttons: boolean = true;
  NextButtonDisabled: boolean = false;
  PreviousButtondisabled: boolean = false;
  public customerTicketsSelectStatusDropDown: any = [];
  public practiceInformation: any = {};
  public responsiblePersonInfo: any = {};
  assignedByInfo: any = {};
  public customerTicketsRowData = [];
  SearchStatusIDs: any = [];
  ModuleName: any;
  ModuleId: any;
  ECDDate: any;
  CreatedFromDate: any;
  CreatedToDate: any;
  public CreatedToTime: any = new Date();
  public CreatedFromTime: any = new Date("Tue Apr 11 2000 00:00:00 GMT+0530");
  public customerTicketsSearchTaskNumber: any;
  public customerTicketsSearchTaskSubject: any;
  selectedTask: any;
  EHRPracticeID: number = 0;
  public items: any = [
    {
      tasktype: "All Tickets",
      taskMenutypeid: 1,
      myclass: "main-buttons-item-link",
    },
    {
      tasktype: "My Tickets",
      taskMenutypeid: 2,
      myclass: "main-buttons-item-link",
    },
    {
      tasktype: "Review Pending",
      taskMenutypeid: 3,
      myclass: "main-buttons-item-link",
    },
    {
      tasktype: "Accepted",
      taskMenutypeid: 4,
      myclass: "main-buttons-item-link",
    },
    {
      tasktype: "Denied",
      taskMenutypeid: 5,
      myclass: "main-buttons-item-link",
    },
    {
      tasktype: "EHR to Customer",
      taskMenutypeid: 6,
      myclass: "main-buttons-item-link",
    },
  ];
  columnDefs = [
    {
      headerName: "Task #",
      field: "TaskNumber",
      width: 90,
      tooltipField: "TaskNumber",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Customer Name",
      field: "PracticeName",
      width: 200,
      tooltipField: "PracticeName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Module Name",
      field: "ModuleName",
      width: 200,
      tooltipField: "ModuleName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Task Type",
      field: "TaskType",
      width: 150,
      tooltipField: "TaskType",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Task Subject",
      field: "IssueSubject",
      width: 240,
      tooltipField: "IssueSubject",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Priority",
      field: "SeverityDesc",
      width: 90,
      tooltipField: "SeverityDesc",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Task Status",
      field: "TaskLatestStatusName",
      width: 100,
      tooltipField: "TaskLatestStatusName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Responsible Person",
      field: "ResponsiblePersonDesc",
      width: 180,
      tooltipField: "ResponsiblePerson",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Created Date",
      field: "IssueCreatedOn",
      width: 150,
      tooltipField: "IssueCreatedOn",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      hide: false,
      cellStyle: { textAlign: "center" },
    },

    {
      headerName: "Created By",
      field: "CreatedBy",
      width: 180,
      tooltipField: "CreatedBy",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Latest Responsed From",
      field: "LatestResponsedFrom",
      hide: false,
      minWidth: 180,
      maxWidth: 250,
      tooltipField: "Latest Responsed From",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Assigned By",
      field: "RoutingDoneBy",
      width: 130,
      tooltipField: "RoutingDoneBy",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },

    {
      headerName: "RBD",
      field: "RequiredByDate",
      hide: true,
      width: 100,
      tooltipField: "RequiredByDate",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "ECD",
      field: "ExpectedDate",
      hide: false,
      width: 100,
      tooltipField: "ExpectedDate",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Task Updated Date",
      headerTooltip: "Task Updated Date",
      field: "TaskCompletedDate",
      hide: true,
      width: 150,
      tooltipField: "TaskCompletedDate",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Reminders Sent to Customer",
      headerTooltip: "Reminders Sent to Customer",
      field: "CustomerReminder_Sent_Count",
      width: 150,
      tooltipField: "CustomerReminder_Sent_Count",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
  ];
  gridApi: any;
  gridColumnApi: any;
  gridOptions: GridOptions = {};

  onCustomerTicketsGridSizeChanged(params: GridSizeChangedEvent) {
    // params.api.sizeColumnsToFit();
  }

  public defaultCurrentWorkingStatusItem = {
    Task_Current_Working_Status: "-- Task Current Working Status --",
    Task_Current_Working_Status_InfoID: null,
  };
  public defaultTaskTypeItem = {
    TaskTypeDescription: "-- Search Task Type --",
    TaskTypeId: null,
  };
  public defaultTickeyTypeItem = {
    TicketTypeDescription: "All",
    TicketTypeId: -1,
  };

  ticketTypeList = [
    { TicketTypeDescription: "Customer to EHR", TicketTypeId: 2 },
    { TicketTypeDescription: "EHR to Customer", TicketTypeId: 3 },
  ];

  clickedtab: any;

  addinternalpopupheight = 700;
  addinternalpopupwidth = 1500;

  constructor(
    private intl: IntlService,
    private http: HttpClient,
    private readonly _excelService: ExcelService,
    private readonly _customerTicketsService: CustomerTicketsService,
    private taskService: TaskService,
    httpClient: HttpClient,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private _router: Router
  ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }

  ngOnInit() {
    this.getTaskStatusList();
    this.GetTaskTypes();
    this.GetCurrentStatusList();
    this.route.params.subscribe((params) => {
      const data = this.getCommonData(params.info);
      if (
        this.hasValue(data) &&
        this.hasValue(data.previouslySelectedSearchFilters)
      ) {
        // console.log(data.previouslySelectedSearchFilters.responsiblePersonInfo)
        this.customerTicketsSearchTaskNumber =
          data.previouslySelectedSearchFilters.customerTicketsSearchTaskNumber;
        this.customerTicketsSearchTaskSubject =
          data.previouslySelectedSearchFilters.customerTicketsSearchTaskSubject;
        this.responsiblePersonInfo =
          data.previouslySelectedSearchFilters.responsiblePersonInfo;
        this.assignedByInfo =
          data.previouslySelectedSearchFilters.assignedByInfo;
        this.practiceInformation =
          data.previouslySelectedSearchFilters.practiceInformation;
        this.FromRecords =
          data.previouslySelectedSearchFilters.StatemaintainFromRecords;
        this.SearchStatusIDs =
          data.previouslySelectedSearchFilters.SearchStatusIDs;
        this.customerTicketsCurrentWorkingStatus =
          data.previouslySelectedSearchFilters.customerTicketsCurrentWorkingStatus;
        this.customerTicketsSelectedTaskType =
          data.previouslySelectedSearchFilters.customerTicketsSelectedTaskType;
        this.customerTicketsSelectedType =
          data.previouslySelectedSearchFilters.customerTicketsSelectedType;
        this.CreatedFromDate =
          data.previouslySelectedSearchFilters.CreatedFromDate;
        this.CreatedToDate = data.previouslySelectedSearchFilters.CreatedToDate;
        this.CreatedFromTime =
          data.previouslySelectedSearchFilters.CreatedFromTime;
        this.CreatedToTime = data.previouslySelectedSearchFilters.CreatedToTime;
        this.ECDDate = data.previouslySelectedSearchFilters.ECDDate;
        this.ModuleId = data.previouslySelectedSearchFilters.ModuleId;

        this.isfromStatemaintainFilter = true;
        this.CustomerTicketsButtonClick(
          this.items[data.previouslySelectedSearchFilters.clickedtab - 1],
          false
        );
        this.selectedTask = data.previouslySelectedTaskNumber;
      } else {
        this.CustomerTicketsButtonClick(this.items[1], true);
      }
    });

    setTimeout(function () {
      document.getElementById("txtCustomerTicketsSearchTask").focus();
    }, 20);
    this.items[1].myclass = "MainButtonsHoverClass main-buttons-item-link";

    for (let i = 0; i < this.columnDefs.length; i++) {
      switch (this.columnDefs[i].field) {
        case "IssueSubject":
          if ($(window).width() > 1720) {
            this.columnDefs[4].width = 553;
            this.columnDefs[6].width = 225;
          } else if ($(window).width() < 1720 && $(window).width() >= 1680) {
            this.columnDefs[4].width = 458;
          } else if ($(window).width() < 1680 && $(window).width() >= 1670) {
            this.columnDefs[4].width = 397;
            this.columnDefs[6].width = 169;
            this.columnDefs[7].width = 150;
          } else if ($(window).width() < 1670 && $(window).width() >= 1550) {
            this.columnDefs[4].width = 285;
            this.columnDefs[6].width = 189;
          } else if ($(window).width() < 1550 && $(window).width() >= 1480) {
            this.columnDefs[1].width = 135;
            this.columnDefs[4].width = 301;
            this.columnDefs[6].width = 180;
          } else if ($(window).width() < 1480 && $(window).width() >= 1400) {
            this.columnDefs[1].width = 109;
            this.columnDefs[4].width = 302;
            this.columnDefs[6].width = 151;
            this.columnDefs[7].width = 135;
          } else if ($(window).width() < 1400 && $(window).width() >= 1390) {
            this.columnDefs[1].width = 137;
            this.columnDefs[2].width = 116;
            this.columnDefs[4].width = 206;
          } else if ($(window).width() < 1390 && $(window).width() >= 1300) {
            this.columnDefs[1].width = 114;
            this.columnDefs[4].width = 170;
            this.columnDefs[6].width = 135;
            this.columnDefs[8].width = 135;
          } else if ($(window).width() < 1300 && $(window).width() >= 1200) {
            this.columnDefs[1].width = 113;
            this.columnDefs[2].width = 117;
            this.columnDefs[4].width = 160;
            this.columnDefs[6].width = 118;
            this.columnDefs[8].width = 140;
          }
          break;
      }
    }
    setTimeout(() => {
      this.isfromStatemaintainFilter = false;
      this.statemaintainPreviousSelectedRecord();
    }, 100);
  }

  getContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    if (
      params.node.data.TaskLatestStatusName === "Yet to Start" ||
      params.node.data.TaskLatestStatusName === "On Going"
    ) {
      var result: (string | MenuItemDef)[] = [
        {
          // custom item
          name: "Update Task Type",
          action: function () {
            params.context.UpdateTaskTypeDetails(
              params.node.data.TaskType,
              params.node.data.TaskInfoID
            );
          },

          cssClasses: ["redFont", "bold"],
        },
        {
          // custom item
          name: "Change Module Name",
          action: function () {
            params.context.UpdateModuleDetails(params.node.data.TaskInfoID);
          },

          cssClasses: ["redFont", "bold"],
        },
      ];
    }
    return result;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridOptions.context = this;
  }

  private getTaskStatusList() {
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "TaskStatusList", {})
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }

        this.customerTicketsSelectStatusDropDown = [];

        response.forEach((element) => {
          if (
            element.TaskStatusId == 1 ||
            element.TaskStatusId == 2 ||
            element.TaskStatusId == 5 ||
            element.TaskStatusId == 6 ||
            element.TaskStatusId == 7 ||
            element.TaskStatusId == 8
          ) {
            this.customerTicketsSelectStatusDropDown.push({
              TaskDescription: element.TaskDescription,
              TaskStatusId: element.TaskStatusId,
            });
          }
        });
      });
  }

  customerTicketsSelectPracticeIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Customer",
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.practiceInformation = result;
        // console.log(this.EHRPracticeID);
        this.EHRPracticeID = this.practiceInformation.PracticeId;
      }
    });
  }

  customerTicketsRemoveSelectedPracticeClickEvent() {
    this.practiceInformation = {};
    this.EHRPracticeID = 0;
  }
  customerTicketsSelectResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      FromInternalTickets: true,
      IsSelectionType: "multiple",
    };
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        let responsiblePersonInfo: any = result;
        this.responsiblePersonInfo.EmployeeId = [];
        this.responsiblePersonInfo.EmployeeFullName = "";
        responsiblePersonInfo.forEach((ele) => {
          this.responsiblePersonInfo.EmployeeFullName =
            this.responsiblePersonInfo.EmployeeFullName +
            "," +
            ele.EmployeeFullName;
          this.responsiblePersonInfo.EmployeeId.push(ele.EmployeeId);
        });
      }
    });
  }
  customerTicketsRemoveSelectedResponsiblePersonClickEvent() {
    this.responsiblePersonInfo = {};
  }
  customerTicketsExportToExcelClick(exoportoexcel) {
    this.customerTicketsGetMajorTopicsListClick(
      undefined,
      undefined,
      exoportoexcel
    );
  }
  customerTicketsPrevNextClick(prevornext) {
    this.customerTicketsGetMajorTopicsListClick(undefined, prevornext, false);
  }

  CreateTaskmoduleIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Module",
      // Show component
      content: SelectModulePopupComponentComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.IssueSupportGroupDesc)) {
        } else {
          this.IssueSupportGroupDesc = "";
        }
      } else {
        let moduleDetails: any = result;
        if (this.hasValue(result)) {
          this.ModuleId = moduleDetails.IssueSupportGroupID;
          this.ModuleName = moduleDetails.IssueSupportGroupDesc;

          // console.log(this.ModuleId);
        }
      }
    });
  }
  CreateTaskRemoveModuleInfo() {
    this.ModuleId = undefined;
    this.ModuleName = "";
    document.getElementById("spanSelectModuleIcon").focus();
  }

  customerTicketsGetMajorTopicsListClick(
    isfromsearch,
    prevornext,
    exoportoexcel,
    callingType?: number
  ) {
    // console.log(this.clickedtab);
    this.RecordsPerPage = 50;
    if (exoportoexcel == false) {
      if (
        isfromsearch == true &&
        (prevornext == false || prevornext == undefined) &&
        this.TotalCountValue == false
      ) {
        this.TotalCountValueIcon = false;
        this.TotalCountValue = true;
      }
      if (
        !this.hasValue(this.FromRecords) ||
        (this.hasValue(isfromsearch) && isfromsearch == true)
      ) {
        this.FromRecords = 1;
      }
      switch (prevornext) {
        case 1:
          //if(this.hasValue(this.PreviousButtondisabled) && this.PreviousButtondisabled==true) return;
          this.FromRecords -= this.RecordsPerPage;
          break;

        case 2:
          //if(this.hasValue(this.NextButtonDisabled) && this.NextButtonDisabled==true) return;
          this.FromRecords += this.RecordsPerPage;
          break;
        case 3:
          this.fromrecordsfromcount = this.FromRecords;
          this.FromRecords = null;
      }
    }
    let statusids = this.SearchStatusIDs.map((item) => item.TaskStatusId).join(
      ","
    );
    // console.log(this.ModuleId, "moduleID");
    // console.log(statusids, "statusids");
    const data: any = {};
    if (this.applyclassesforTasksCreatedDatesSelected(data) == false) return;
    let CallingAPi: any;
    //added by suman
    // console.log(callingType);
    // if (callingType === 1) {
    //   (data.IsTaskFromIds = "2,3"), (data.IsTaskCreatedEHR = null);
    //   CallingAPi = this.taskService.gettaskList(data);
    // } else

    if (this.clickedtab === 6) {
      (data.IsTaskFromIds = "3"), (data.IsTaskCreatedEHR = null);
      CallingAPi = this.taskService.gettaskList(data);
    } else {
      data.IsTaskCreatedEHR = true;
      CallingAPi = this.taskService.taskList(data);
    }

    (data.SupportGroupIDs = [this.ModuleId]),
      (data.FromRecords = this.hasValue(this.FromRecords)
        ? this.FromRecords
        : undefined),
      (data.TaskNumber = this.customerTicketsSearchTaskNumber),
      (data.IssueSubject = this.customerTicketsSearchTaskSubject),
      (data.TaskLatestStatusList = this.hasValue(statusids)
        ? statusids
        : undefined),
      (data.ExpectedDate = this.hasValue(this.ECDDate)
        ? this.formatValue(this.ECDDate)
        : null),
        (data.ResponsibleUserID=this.hasValue(
          this.responsiblePersonInfo.EmployeeId
        )
          ? this.responsiblePersonInfo.EmployeeId
          : undefined),
      (data.ResponsibleUserIDs = this.hasValue(
        this.responsiblePersonInfo.EmployeeId
      )
        ? this.responsiblePersonInfo.EmployeeId
        : undefined),
        (data.CreatedByUserID=Config.OASOrganizationModel.LoggedUserID),
        (data.SupportGroupID = this.ModuleId),
      (data.RoutingDoneBy = this.hasValue(this.assignedByInfo.EmployeeId)
        ? this.assignedByInfo.EmployeeId
        : undefined),
      (data.PracticeIDsList = this.hasValue(this.practiceInformation.PracticeId)
        ? this.practiceInformation.PracticeId.toString()
        : undefined),
      (data.TaskCurrentWorkingStatusInfoIDs = this.hasValue(
        this.customerTicketsCurrentWorkingStatus
      )
        ? [this.customerTicketsCurrentWorkingStatus]
        : []),
      (data.TaskTypeId = this.customerTicketsSelectedTaskType);

    if (exoportoexcel == true) {
      let CallingCommonHelperOBj: any;
      if (this.clickedtab !== 6) {
        (data.IsTaskCreatedEHR = true),
          (CallingCommonHelperOBj = this.commonhelperobj.PostData(
            Config.OASOrganizationAppnURL + "EmployeeTaskListExport",
            data
          ));
      } else {
        data.IsTaskCreatedEHR = null;
        data.NeedtoShowAllTasks = 1;
        CallingCommonHelperOBj = this.commonhelperobj.PostData(
          Config.OASOrganizationAppnURL + "EhrToCutomerTaskListExport",
          data
        );
      }
      CallingCommonHelperOBj.subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        if (
          this.hasValue(serviceResponse) &&
          this.hasValue(serviceResponse.EmployeeTaskListModelList) &&
          serviceResponse.EmployeeTaskListModelList.length > 0
        ) {
          this._excelService.exportAsExcelFile(
            this._customerTicketsService.ExcelColumnsInfo,
            serviceResponse.EmployeeTaskListModelList,
            this.clickedtab !== 6
              ? "CustomerTicketsReport"
              : "EHRtoCustomerTicketsReport"
          );
          // window.open(serviceResponse.AttachmentsURL);
        }
      });
      return;
    }

    //Refer Above Logic for Service
    CallingAPi.subscribe((response) => {
      if (this.commonhelperobj.isError(response)) {
        return;
      }
      this.applyrowclassrules();
      if (
        this.hasValue(response) &&
        this.hasValue(response.Count) &&
        response.Count > 0
      ) {
        this.TotalCountValueIcon = true;
        this.TotalCountValue = false;
        this.TotalLabelCount = response.Count;
        this.FromRecords = this.fromrecordsfromcount;
        return;
      }
      response.EmployeeTaskListModelList.forEach((TaskListelement) => {
        if (this.hasValue(TaskListelement.TaskUsersInfo)) {
          const SplittedUserData = TaskListelement.TaskUsersInfo.split("],");
          if (this.hasValue(SplittedUserData) && SplittedUserData.length > 0) {
            //TaskListelement.ParticipantsDesc = "";
            TaskListelement.AssistantsDesc = "";
            // tslint:disable-next-line:no-shadowed-variable
            SplittedUserData.forEach((element) => {
              const getUserRolesData = element.split("|");
              // console.log(getUserRolesData);
              if (
                this.hasValue(getUserRolesData) &&
                getUserRolesData.length > 0
              ) {
                if (getUserRolesData[2] === "2") {
                  TaskListelement.ResponsiblePersonDesc = getUserRolesData[1];
                }
                if (getUserRolesData[2] === "7") {
                  // if (!this.hasValue(TaskListelement.ParticipantsDesc)) {
                  //   TaskListelement.ParticipantsDesc = "";
                  // }
                  // TaskListelement.ParticipantsDesc += getUserRolesData[1] + ', ';
                  TaskListelement.AssistantsDesc += getUserRolesData[1] + ", ";
                }
              }
            });
            // TaskListelement.ParticipantsDesc = TaskListelement.ParticipantsDesc.substring(0, TaskListelement.ParticipantsDesc.length - 2);
            TaskListelement.AssistantsDesc =
              TaskListelement.AssistantsDesc.substring(
                0,
                TaskListelement.AssistantsDesc.length - 2
              );
          }
        }
      });
      this.byCustomerGrid.columnApi.setColumnsVisible(
        ["RequiredByDate", "TaskCompletedDate"],
        this.isCompletedTickets
      );
      this.customerTicketsRowData = response.EmployeeTaskListModelList;

      this.PrevLabelCount = this.FromRecords;
      if (this.FromRecords == 1) {
        this.NextLabelCount = response.EmployeeTaskListModelList.length;
      } else {
        this.NextLabelCount =
          this.FromRecords + response.EmployeeTaskListModelList.length - 1;
      }
      if (
        this.FromRecords == 1 &&
        response.EmployeeTaskListModelList.length < this.RecordsPerPage
      ) {
        this.prevnextbuttons = true;
        this.TotalLabelCountValue = response.EmployeeTaskListModelList.length;
      } else if (
        this.FromRecords == 1 &&
        response.EmployeeTaskListModelList.length == this.RecordsPerPage
      ) {
        this.prevnextbuttons = false;
        this.PreviousButtondisabled = true;
        this.NextButtonDisabled = false;
      } else if (
        response.EmployeeTaskListModelList.length < this.RecordsPerPage
      ) {
        this.prevnextbuttons = false;
        this.NextButtonDisabled = true;
        this.PreviousButtondisabled = false;
      } else {
        this.prevnextbuttons = false;
        this.PreviousButtondisabled = false;
        this.NextButtonDisabled = false;
      }
      //this.statemaintainPreviousSelectedRecord();
    });
  }

  OnClickOpenandClose(isTrueorFalse) {
    let selectedTask = this.getSelectedTaskInfo();
    if (selectedTask.ReadorUnread !== isTrueorFalse)
      this.OnclickMarkasReadorUnReadFN(
        selectedTask.TaskInfoID,
        isTrueorFalse,
        true
      );
  }
  OnclickMarkasReadorUnReadFN(selectedID, isTrueorFalse, isRefresh) {
    let payload = {
      TaskInfoID: selectedID,
      TicketReadorUnread: isTrueorFalse,
    };
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "UpdatedTicketReadandUnReadStatus", payload)
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
        if (isRefresh === true) {
          this.customerTicketsGetMajorTopicsListClick(
            undefined,
            undefined,
            false
          );
        }
      });
  }
  private GetCurrentStatusList() {
    var PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "NewCurrentWorkingStatusList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.NewCurrentWorkingStatusList =
          serviceResponse.newCurrentWorkingStatusListModelList;
      });
  }
  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "yyyyMMdd")}` : "";
  }
  private formatDateValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "MM/dd/yyyy")}` : "";
  }
  private GetTaskTypes() {
    this.taskService.taskTypeList().subscribe((response) => {
      if (this.isError(response)) {
        return;
      }
      this.taskTypeList = response;
    });
  }

  customerTicketsViewClickEvent() {
    let selectedTask = this.getSelectedTaskInfo();
    if (!this.hasValue(selectedTask) || selectedTask <= 0) return;
    const PostDataService: any = {
      IsFromEdit: true,
      editmodedata: selectedTask,
      isfrom: 6, //bycustomertickets
      selectedTicketsList: this.customerTicketsRowData,
    };
    // if (this.isFromSetByMe === true) {
    //   PostDataService.IsFromSetByMe = true;
    // }
    PostDataService.StatemaintainPreviouslySearchFilters = {
      customerTicketsSearchTaskNumber: this.customerTicketsSearchTaskNumber,
      customerTicketsSearchTaskSubject: this.customerTicketsSearchTaskSubject,
      responsiblePersonInfo: this.responsiblePersonInfo,
      assignedByInfo: this.assignedByInfo,
      practiceInformation: this.practiceInformation,
      StatemaintainFromRecords: this.FromRecords,
      SearchStatusIDs: this.SearchStatusIDs,
      customerTicketsSelectedTaskType: this.customerTicketsSelectedTaskType,
      customerTicketsCurrentWorkingStatus:
        this.customerTicketsCurrentWorkingStatus,
      clickedtab: this.clickedtab,
      CreatedFromDate: this.CreatedFromDate,
      CreatedToDate: this.CreatedToDate,
      CreatedFromTime: this.CreatedFromTime,
      CreatedToTime: this.CreatedToTime,
      ECDDate: this.ECDDate,
      ModuleId: this.ModuleId,
    };
    const outletObj = {};
    outletObj["main"] = [
      "app-view-full-task-information",
      this.setCommonData(PostDataService),
    ];
    this._router.navigate(["/home", { outlets: outletObj }]);
  }
  getSelectedTaskInfo() {
    const selectedrow = this.byCustomerGrid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.ShowErrorMessage("Please Select Task.");
      document.getElementById("txtCustomerTicketsSearchTask").focus();
      return 0;
    }
    return selectedrow[0];
  }

  // customerTicketsEditButtonClickEvent(){
  //   let selectedTask=this.getSelectedTaskInfo()
  //   if (!this.hasValue(selectedTask)) return;
  //   //this.selectedRowsString.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount;
  //   // object contains data that is to be sent to another route
  //   const dataToRoute = {
  //     data: selectedTask,

  //   };

  //   if (!this.isEligibleToEdit(selectedTask)) {
  //     this.ShowErrorMessage('You Are Not Allowed to Edit Task(s) Created by Others');
  //     return;
  //   }

  //   if (this.isTaskCompleted(selectedTask)) {
  //     this.ShowErrorMessage('Cannot Edit Completed or Review Pending Tasks');
  //     return;
  //   }

  //   const outletObj = {};
  //   outletObj['main'] = ['app-edit-task-information', this.setCommonData(dataToRoute)];
  //   this._router.navigate(['/home', { outlets: outletObj }]);
  // }
  //  /**
  //    * Check if the current logged in user is allowed to edit the current task
  //    * @author Sanjay Idpuganti
  //    */
  //   isEligibleToEdit(selectedData: any): boolean {

  //     if (selectedData && selectedData.TaskCreatedByUserId) {
  //       return Config.OASOrgModel.LoggedUserID === selectedData.TaskCreatedByUserId;
  //     }

  //   }

  //   isTaskCompleted(selectedData: any): boolean {

  //     if (selectedData && selectedData.TaskLatestStatusName) {
  //       return ['Review Pending', 'Completed'].some(taskStatus => taskStatus === selectedData.TaskLatestStatusName);
  //     }

  //   }
  applyrowclassrules() {
    // }
    this.rowClassRules = {
      // 'sick-days-warning': function (params) {
      //   // tslint:disable-next-line:prefer-const
      //   const statuspending = params.data.TaskLatestStatusName;
      //   return statuspending === 'Yet to Start';
      // },
      "sick-days-breach": function (params) {
        // tslint:disable-next-line:prefer-const
        const statuspending = params.data.TaskLatestStatusName;
        return statuspending === "On Going";
      },
      "sick-days-completed": function (params) {
        // tslint:disable-next-line:prefer-const
        const statuspending = params.data.TaskLatestStatusName;
        return statuspending === "Accepted by Customer";
      },
      "sick-days-overdue": function (params) {
        // tslint:disable-next-line:prefer-const
        const statuspending = params.data.IsOverdueTask;
        return statuspending === true;
      },
    };
  }

  CustomerTicketsButtonClick(item, isfromPageInit) {
    this.clickedtab = item.taskMenutypeid;
    // console.log(this.clickedtab, "is clicked");
    if (isfromPageInit) this.FromRecords = 1;
    setTimeout(() => {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].myclass = "main-buttons-item-link";
      }
      document.getElementById("txtCustomerTicketsSearchTask").focus();
      item.myclass = "MainButtonsHoverClass main-buttons-item-link";
    }, 20);

    switch (item.taskMenutypeid) {
      case 1:
        this.customerTicketsAllTicketsLinkClick();
        break;
      case 2:
        this.customerTicketsMyTicketsLinkClick();
        break;
      case 3:
        this.customerTicketsReviewPendingTicketsLinkClick();
        break;
      case 4:
        this.customerTicketsAcceptedTicketsLinkClick();
        break;
      case 5:
        this.customerTicketsDeninedTicketsLinkClick();
        break;
      case 6:
        this.customerTicketsEhrtoCustomerLinkClick();
        break;
    }
  }
  customerTicketsAllTicketsLinkClick() {
    this.isCompletedTickets = false;
    if (!this.isfromStatemaintainFilter) {
      this.SearchStatusIDs = [
        { TaskDescription: "Yet to Start", TaskStatusId: 1 },
        { TaskDescription: "On Going", TaskStatusId: 2 },
        { TaskDescription: "Denied by Customer", TaskStatusId: 7 },
        { TaskDescription: "On-hold", TaskStatusId: 8 },
      ];
      this.responsiblePersonInfo = {};
    }
    //Here Calling Type Indicates Index of the bUtton Click
    this.customerTicketsGetMajorTopicsListClick(undefined, undefined, false);
  }

  customerTicketsEhrtoCustomerLinkClick() {
    this.isCompletedTickets = false;
    if (!this.isfromStatemaintainFilter) {
      this.SearchStatusIDs = [];
      this.responsiblePersonInfo = {};
    }
    //Here Calling Type Indicates Index of the bUtton Click
    this.customerTicketsGetMajorTopicsListClick(undefined, undefined, false);
  }

  customerTicketsMyTicketsLinkClick() {
    this.isCompletedTickets = false;
    if (!this.isfromStatemaintainFilter) {
      this.SearchStatusIDs = [
        { TaskDescription: "Yet to Start", TaskStatusId: 1 },
        { TaskDescription: "On Going", TaskStatusId: 2 },
        { TaskDescription: "Denied by Customer", TaskStatusId: 7 },
        { TaskDescription: "On-hold", TaskStatusId: 8 },
      ];
      this.responsiblePersonInfo.EmployeeId = [];
      this.responsiblePersonInfo.EmployeeId.push(
        Config.OASOrganizationModel.LoggedUserID
      );
    }
    this.customerTicketsGetMajorTopicsListClick(undefined, undefined, false);
  }

  customerTicketsReviewPendingTicketsLinkClick() {
    this.isCompletedTickets = false;
    if (!this.isfromStatemaintainFilter) {
      this.SearchStatusIDs = [
        { TaskDescription: "Review Pending", TaskStatusId: 5 },
      ];
      this.responsiblePersonInfo.EmployeeId = [];
      this.responsiblePersonInfo.EmployeeId.push(
        Config.OASOrganizationModel.LoggedUserID
      );
      this.responsiblePersonInfo.EmployeeFullName = `${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}`;
    }
    this.customerTicketsGetMajorTopicsListClick(undefined, undefined, false);
  }

  customerTicketsAcceptedTicketsLinkClick() {
    this.isCompletedTickets = true;
    if (!this.isfromStatemaintainFilter) {
      this.SearchStatusIDs = [
        { TaskDescription: "Accepted by Customer", TaskStatusId: 6 },
      ];
      this.responsiblePersonInfo.EmployeeId = [];
      this.responsiblePersonInfo.EmployeeId.push(
        Config.OASOrganizationModel.LoggedUserID
      );
      this.responsiblePersonInfo.EmployeeFullName = `${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}`;
    }
    this.customerTicketsGetMajorTopicsListClick(undefined, undefined, false);
  }
  customerTicketsDeninedTicketsLinkClick() {
    this.isCompletedTickets = false;
    if (!this.isfromStatemaintainFilter) {
      this.SearchStatusIDs = [
        { TaskDescription: "Denined by Customer", TaskStatusId: 7 },
      ];
      this.responsiblePersonInfo.EmployeeId = [];
      this.responsiblePersonInfo.EmployeeId.push(
        Config.OASOrganizationModel.LoggedUserID
      );
      this.responsiblePersonInfo.EmployeeFullName = `${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}`;
    }
    this.customerTicketsGetMajorTopicsListClick(undefined, undefined, false);
  }
  statemaintainPreviousSelectedRecord() {
    let seletedtasknumber = this.selectedTask;
    let storeGridInfo = this.byCustomerGrid.api;
    setTimeout(() => {
      this.byCustomerGrid.api.forEachLeafNode(function (node) {
        if (node.data.TaskNumber == seletedtasknumber) {
          node.setSelected(true);
          //Moving Scroll bar to Selected Node
          storeGridInfo.ensureIndexVisible(node.rowIndex + 1);
        }
      });
    }, 500);
  }

  customerTicketsSelectAssignedByIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: this.clickedtab === 6 ? "Select Created By" : "Select Assigned By",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.assignedByInfo = result;
      }
    });
  }
  customerTicketsRemoveSelectedAssignedByClickEvent() {
    this.assignedByInfo = {};
  }
  allCustomerTicketsRemoveSelectedDatesClickEvent(number) {
    if (number === 1) {
      this.CreatedFromDate = "";
      this.CreatedFromTime = "";
    } else if (number === 2) {
      this.CreatedToDate = "";
      this.CreatedToTime = "";
    } else {
      this.ECDDate = "";
    }
  }

  //Added By Suman
  tasksCreatedFromDateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedFromDate,
        "CreatedFromDate",
        true
      )
    )
      return false;
  }
  tasksCreatedTODateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedToDate,
        "CreatedToDate",
        false
      )
    )
      return false;
  }

  createdFromDateToDateValidations(
    modifiedfromandtoDate: Date,
    name: string,
    isfrom: boolean
  ) {
    var today = new Date();
    var toDate = new Date(modifiedfromandtoDate);
    var showfocuselement = false;
    const daysDifference = this.DateDiffInDays(
      isfrom ? this.CreatedToDate : today,
      isfrom ? this.CreatedFromDate : this.CreatedToDate
    );
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage("Invalid Day, Month or Year Range Detected.");
      showfocuselement = true;
    }
    if (this.hasValue(daysDifference) && daysDifference < 0) {
      if (isfrom) {
        this.ShowErrorMessage(
          "Created From Date Should be Less Than or Equal to Created To Date"
        );
        this.CreatedFromDate = "";
      } else {
        this.ShowErrorMessage("Future Dates Not allowed");
        this.CreatedToDate = "";
      }
      showfocuselement = true;
    }
    if (showfocuselement) {
      const elementFound = this.CustomerTickets.nativeElement.querySelector(
        '[name="' + name + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    return true;
  }

  private applyclassesforTasksCreatedDatesSelected(data: any) {
    if (this.hasValue(this.CreatedFromDate)) {
      if (!this.hasValue(this.CreatedToDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created To Date.");
        return false;
      }
    }
    if (this.hasValue(this.CreatedToDate)) {
      if (!this.hasValue(this.CreatedFromDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created From Date.");
        return false;
      }
    }

    if (this.hasValue(this.CreatedFromDate)) {
      let fromTime = this.CreatedFromTime
        ? formatDate(this.CreatedFromTime, "HH:mm:ss", "en-US", "+0530")
        : "00:00";
      data.createdFromDate =
        this.formatDateValue(this.CreatedFromDate) + " " + fromTime;
    } else {
      data.createdFromDate = "";
    }
    if (this.hasValue(this.CreatedToDate)) {
      let toTime = this.CreatedToTime
        ? formatDate(this.CreatedToTime, "HH:mm:ss", "en-US", "+0530")
        : "00:00";
      data.createdToDate =
        this.formatDateValue(this.CreatedToDate) + " " + toTime;
    } else {
      data.createdToDate = "";
    }

    if (this.tasksCreatedFromDateChange() == false) return false;
    if (this.tasksCreatedTODateChange() == false) return false;
  }

  AddNewTicketClickEvent() {
    let selectedTask = this.getSelectedTaskInfo();
    if (!this.hasValue(selectedTask) || selectedTask <= 0) return;
    this.addinternalpopupmethodCalling();
    const dialogRef = this.dialogService.open({
      title: "Create Customer Task",
      // Show component
      content: CreateNewCustomerTicketPopUpComponent,
      width: this.addinternalpopupwidth,
      height: this.addinternalpopupheight,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      senddatamodel: this.getSelectedTaskInfo(),
    };
    dialogRef.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult)) {
        if (result) {
          this.customerTicketsGetMajorTopicsListClick(
            undefined,
            undefined,
            false
          );
        }
      }
    });
  }

  AddCustometTicketClickEvent() {
    this.addinternalpopupmethodCalling();
    const dialogRef = this.dialogService.open({
      title: "Create Customer Task",
      // Show component
      content: CreateNewCustomerTaskComponent,
      width: this.addinternalpopupwidth,
      height: this.addinternalpopupheight,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.fromInternalTicketNavigation = true;
    dialogRef.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult)) {
        if (result) {
          this.customerTicketsGetMajorTopicsListClick(
            undefined,
            undefined,
            false
          );
        }
      }
    });
  }
  private addinternalpopupmethodCalling() {
    if ($(window).width() >= 1920) {
      this.addinternalpopupwidth = 1850;
      this.addinternalpopupheight = 900;
    } else if ($(window).width() >= 1680 && $(window).width() <= 1720) {
      this.addinternalpopupwidth = 1600;
      this.addinternalpopupheight = 800;
    }
  }

  OnBtnClickUpdateTask() {
    let selectedrow: any = this.byCustomerGrid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.ShowErrorMessage("Please Select Task.");
      document.getElementById("txtCustomerTicketsSearchTask").focus();
      return;
    }
    // console.log(selectedrow, "SelectedRow");

    this.UpdateTaskTypeDetails(
      selectedrow[0].TaskType,
      selectedrow[0].TaskInfoID
    );
  }

  OnBtnClickUpdateModule() {
    const selectedrow = this.byCustomerGrid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.ShowErrorMessage("Please Select Task.");
      document.getElementById("txtCustomerTicketsSearchTask").focus();
      return;
    }
    this.UpdateModuleDetails(selectedrow[0].TaskInfoID);
  }

  UpdateTaskTypeDetails(TaskName, TaskInfoID) {
    const dialogRef = this.dialogService.open({
      title: "Update Task Type",
      // Show component
      content: AddTaskTypePopupComponent,
      width: 400,
      height: 300,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      Value: TaskName.trim("") !== "" ? TaskName : undefined,
      TaskInfoID: TaskInfoID,
    };
    dialogRef.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult)) {
        if (result !== undefined) {
          this.customerTicketsGetMajorTopicsListClick(true, undefined, false);
        }
      }
    });
  }

  UpdateModuleDetails(TaskInfoID) {
    const dialogRef = this.dialogService.open({
      title: "Change Module Name",
      // Show component
      content: SelectModulePopupComponentComponent,
      width: 550,
      height: 680,
      appendTo: this.ContainerRef,
    });
    // dialogRef.content.instance.datatoPopup = {
    //   Value: ModuleName.trim("")!==""?ModuleName:undefined,
    //   TaskInfoID:TaskInfoID
    // };
    dialogRef.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult)) {
        if (result !== undefined) {
          // console.log(result);
          this.UpdateModuleData(result, TaskInfoID);
        }
      }
    });
  }

  UpdateModuleData(SelectedItem: any, TaskInfoID: number) {
    let Payload = {
      ModuleID: SelectedItem.IssueSupportGroupID,
      TaskInfoID: TaskInfoID,
    };

    this.commonhelperobj
      .PostData(Config.OASOrganizationAppnURL + "UpdateModuleDetails", Payload)
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          this.ShowErrorMessage(serviceResponse);
          return;
        } else {
          this.customerTicketsGetMajorTopicsListClick(true, undefined, false);
        }
      });
  }

  //#region " Responsible "
  responsiblePersonIconClickEvent() {
    // console.log(this.EHRPracticeID <= 0);
    if (this.EHRPracticeID <= 0) {
      this.ShowErrorMessage("Please Select Practice Dertails.");
      return;
    }
    const dialogRef = this.dialogService.open({
      title: "Select Responsible Person",
      // Show component
      content: PracticeUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      PracticeId: this.EHRPracticeID,
    };
    dialogRef.result.subscribe((result) => {
      //debugger;
      // console.log(result, "result");
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.responsiblePersonInfo)) {
        } else {
          this.responsiblePersonInfo = "";
        }
      } else {
        this.responsiblePersonInfo = result;
        if (this.hasValue(this.responsiblePersonInfo)) {
          // console.log(result);
          // this.TaskAddInfoModel.SelectedResponsiblePerson = this.SelectedResponsiblePersonInfo.EmployeeID;
          this.responsiblePersonInfo.EmployeeId =
            this.responsiblePersonInfo.UserID;
          this.responsiblePersonInfo.EmployeeFullName =
            this.responsiblePersonInfo.EmployeeName;
          // this.ResponsiblePerson = this.SelectedResponsiblePersonInfo.EmployeeId;
        }
      }
    });
  }

  RemoveResponsiblePersonInfo() {
    // this.TaskAddInfoModel.SelectedResponsiblePerson = undefined;
    this.responsiblePersonInfo.EmployeeId = undefined;
    this.responsiblePersonInfo.EmployeeFullName = "";
  }
  //#endregion

  getRowStyle(params) {
    // console.log(params, "params");
    if (params.data.ReadorUnread === 1) {
      return { "font-weight": "900" };
    } else {
      return { "font-weight": "500" };
    }
  }
}
