import { Directive, AfterViewInit, ElementRef, NgModule } from '@angular/core';

@Directive({
    selector: '[appFocusOnDefault]'
})
export class FocusOnDefaultDirective implements AfterViewInit {
    constructor(private host: ElementRef) { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.host.nativeElement.focus();
        }, 500);
    }
}
@NgModule({
    declarations: [FocusOnDefaultDirective],
    exports: [FocusOnDefaultDirective]
})

export class FocusOnDefaultDirectiveModule { }
