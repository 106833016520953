import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { commonhelper } from '../common/commonhelper';
import { ExcelService } from '../common/excel.service';
import { PraticesListComponent } from '../pratices-list/pratices-list.component';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';
import { TaskService } from '../services/task.service';
import { OverDueReportService } from './over-due-report.service';

@Component({
  selector: 'app-over-due-report',
  templateUrl: './over-due-report.component.html',
  styleUrls: ['./over-due-report.component.css']
})
export class OverDueReportComponent extends commonhelper implements OnInit {

  columnDefs = [
    { headerName: 'Task #', field: 'TaskNumber', width: 90, tooltipField: 'TaskNumber', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Customer Name', field: 'PracticeName', width: 180, tooltipField: 'PracticeName', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Task Type', field: 'TaskType', width: 150, tooltipField: 'TaskType', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Task Subject', field: 'TaskSubject', width: 240, tooltipField: 'TaskSubject', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Priority', field: 'Priority', width: 90, tooltipField: 'Priority', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Task Status', field: 'TaskLatestStatusName', width: 100, tooltipField: 'TaskLatestStatusName', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Responsible Person', field: 'ResponsiblePersonDesc', width: 150, tooltipField: 'ResponsiblePersonDesc', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    {
      headerName: 'Created Date', field: 'CreatedDate', width: 100, tooltipField: 'CreatedDate',
      cellClass: 'no-border cell-wrap-text', autoHeight: true, hide: false, cellStyle: { textAlign: 'center' }
    },

    {
      headerName: 'Created By', field: 'CreatedBy', width: 100, tooltipField: 'CreatedBy',
      cellClass: 'no-border cell-wrap-text', autoHeight: true,
    },
    { headerName: 'OverDue Days', field: 'Days', width: 120, tooltipField: 'Days', cellClass: 'no-border cell-wrap-text', autoHeight: true, },
  ]


  onOverDueReportGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  @ViewChild('overDueReportGrid') overDueReportGrid: AgGridNg2;
  @ViewChild('OverDueReport') OverDueReport: ElementRef;
  @ViewChild("OverDueReport", { read: ViewContainerRef })

  ContainerRef: ViewContainerRef;
  public overDueReportRowData = [];
  public NewCurrentWorkingStatusList = [];
  public taskTypeList = [];
  public overDueReportSelectStatusDropDown = [];
  public practiceInformation: any = {};
  public responsiblePersonInfo: any = {};
  overDueReportSearchTaskNumber: any;
  overDueReportSearchTaskSubject: any;
  overDueReportCurrentWorkingStatus: any;
  overDueReportSelectedTaskType: any;
  selectedTask: number;
  SearchStatusIDs: any = [];
  constructor(private dialogService: DialogService, private readonly route: ActivatedRoute, private readonly _router: Router, private readonly _excelService: ExcelService, private taskService: TaskService, private _overDueReportService: OverDueReportService) { super(); }
  public defaultCurrentWorkingStatusItem = { Task_Current_Working_Status: '-- Task Current Working Status --', Task_Current_Working_Status_InfoID: null };
  public defaultTaskTypeItem = { TaskTypeDescription: '-- Search Task Type --', TaskTypeId: null };
  ngOnInit() {
    this.getFilteredDataToStatemaintain();
    this.getOverDueTasksList();
    this.getTaskStatusList();
    this.GetTaskTypes();
    this.GetCurrentStatusList();
    this.setInitialFocusonField();

  }
  getFilteredDataToStatemaintain() {
    this.route.params.subscribe(params => {
      const data = this.getCommonData(params.info);
      if (this.hasValue(data) && this.hasValue(data.previouslySelectedSearchFilters)) {
        this.overDueReportSearchTaskNumber = data.previouslySelectedSearchFilters.overDueReportSearchTaskNumber;
        this.overDueReportSearchTaskSubject = data.previouslySelectedSearchFilters.overDueReportSearchTaskSubject;
        this.responsiblePersonInfo = data.previouslySelectedSearchFilters.responsiblePersonInfo;
        this.practiceInformation = data.previouslySelectedSearchFilters.practiceInformation;
        this.SearchStatusIDs = data.previouslySelectedSearchFilters.SearchStatusIDs;
        this.overDueReportCurrentWorkingStatus = data.previouslySelectedSearchFilters.overDueReportCurrentWorkingStatus;
        this.overDueReportSelectedTaskType = data.previouslySelectedSearchFilters.overDueReportSelectedTaskType;
        this.selectedTask = data.previouslySelectedTaskNumber
      }
    });
  }
  onModelUpdated() {
    this.overDueReportGrid.api.forEachLeafNode((node) => {
      if (node.data.TaskNumber == this.selectedTask) {
        node.setSelected(true);
        //Moving Scroll bar to Selected Node
        this.overDueReportGrid.api.ensureIndexVisible(node.rowIndex + 1);
      }
    });
  }
  setInitialFocusonField() {
    setTimeout(() => {
      document.getElementById("txtOverDueReportSearchTask").focus();
    }, 100);
  }
  getOverDueTasksList() {
    let statusids = this.SearchStatusIDs.map(item => item.TaskStatusId).join(',')
    const data = {
      TaskNumber: this.overDueReportSearchTaskNumber,
      IssueSubject: this.overDueReportSearchTaskSubject,
      TaskLatestStatusList: this.hasValue(statusids) ? statusids : undefined,
      ResponsibleUserID: this.hasValue(this.responsiblePersonInfo.EmployeeId) ? this.responsiblePersonInfo.EmployeeId : undefined,
      PracticeIDsList: this.hasValue(this.practiceInformation.PracticeId) ? this.practiceInformation.PracticeId.toString() : undefined,
      TaskCurrentWorkingStatusInfoID: this.overDueReportCurrentWorkingStatus,
      TaskTypeId: this.overDueReportSelectedTaskType,
    }
    this._overDueReportService.GetOverDueTasksList(data).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) return;
      this.overDueReportRowData = serviceResponse.overDueTasksList
    })
  }
  private GetCurrentStatusList() {
    this._overDueReportService.NewCurrentWorkingStatusList({}).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) return;
      this.NewCurrentWorkingStatusList = serviceResponse.newCurrentWorkingStatusListModelList;
    })
  }
  overDueReportExportToExcelClick() {
    if (this.overDueReportRowData.length <= 0) return;
    this._excelService.exportAsExcelFile(this._overDueReportService.ExcelColumnsInfo, this.overDueReportRowData, "OverDueReport");
  }
  private GetTaskTypes() {
    this.taskService.taskTypeList().subscribe(response => {
      if (this.isError(response)) {
        return;
      }
      this.taskTypeList = response;
    });
  }
  private getTaskStatusList() {
    this._overDueReportService.TaskStatusList({}).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) return;
      this.overDueReportRowData = serviceResponse.overDueTasksList
      this.overDueReportSelectStatusDropDown = [];

      serviceResponse.forEach(element => {
        if (element.TaskStatusId == 1 || element.TaskStatusId == 2 || element.TaskStatusId == 7 || element.TaskStatusId == 8) {
          this.overDueReportSelectStatusDropDown.push({ TaskDescription: element.TaskDescription, TaskStatusId: element.TaskStatusId });
        }
      });
    });
  }
  overDueReportSelectPracticeIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Pratice',
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.practiceInformation = result;
      }
    });
  }
  overDueReportRemoveSelectedPracticeClickEvent() {
    this.practiceInformation = {};
  }
  overDueReportRemoveSelectedResponsiblePersonClickEvent() {
    this.responsiblePersonInfo = {};
  }
  overDueReportViewClickEvent() {
    let selectedTask = this.getSelectedTaskInfo()
    if (!this.hasValue(selectedTask) || selectedTask <= 0) return;
    const outletObj = {};
    outletObj['main'] = ['app-view-full-task-information',
      this.setCommonData(this.buildModeltoStatemaintainwhenViewClick(selectedTask))];
    this._router.navigate(['/home', { outlets: outletObj }]);
  }
  buildModeltoStatemaintainwhenViewClick(selectedTask) {
    const PostDataService: any = {
      IsFromEdit: true,
      editmodedata: selectedTask,
      isfrom: 9//over due report
    };
    PostDataService.StatemaintainPreviouslySearchFilters = {
      overDueReportSearchTaskNumber: this.overDueReportSearchTaskNumber,
      overDueReportSearchTaskSubject: this.overDueReportSearchTaskSubject,
      responsiblePersonInfo: this.responsiblePersonInfo,
      practiceInformation: this.practiceInformation,
      SearchStatusIDs: this.SearchStatusIDs,
      overDueReportSelectedTaskType: this.overDueReportSelectedTaskType,
      overDueReportCurrentWorkingStatus: this.overDueReportCurrentWorkingStatus,
    };
    return PostDataService;
  }
  getSelectedTaskInfo() {
    const selectedrow = this.overDueReportGrid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.ShowErrorMessage("Please Select Task.");
      document.getElementById("txtOverDueReportSearchTask").focus();
      return 0;
    }
    return selectedrow[0];
  }
  overDueReportSelectResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select User(s)',
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {

        this.responsiblePersonInfo = result;
      }
    });
  }
}
