import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Config } from "../config";
import { commonhelper } from "../common/commonhelper";
import { commonHelperService } from "../common/common.service";
import { LeftNavService } from "../common/Leftnav.service";
import {
  DialogService,
  DialogCloseResult,
  DialogAction,
} from "@progress/kendo-angular-dialog";
import { ChangePasswordPopupComponentComponent } from "../change-password-popup-component/change-password-popup-component.component";
import { HttpClient } from "@angular/common/http";
import { AddEmployeeInfoPopupComponent } from "../add-employee-info-popup/add-employee-info-popup.component";
import { ItemComponent } from "@progress/kendo-angular-menu";
@Component({
  selector: "app-oas-main-dashboard-component",
  templateUrl: "./oas-main-dashboard-component.component.html",
  styleUrls: ["./oas-main-dashboard-component.component.css"],
})
export class OasMainDashboardComponentComponent
  extends commonhelper
  implements OnInit
{
  IsCallFromEHRApplication: boolean;
  changePaswordModel: any;
  @ViewChild("divOasDashboardinfo") divaasDashboardinfo: ElementRef;
  @ViewChild("divOasDashboardinfo", { read: ViewContainerRef })
  public containerRef: ViewContainerRef;
  public CurrentSelectedLeftNav: any;
  LeftSideFoldersListNew: any;
  private commonhelperobj: commonHelperService;
  @ViewChild("sidebar") sidebar: ElementRef;
  public LeftSidesubFoldersListNew = [
    { subFolderItem: "By Status", subFolderItemId: 111 },
    { subFolderItem: "By Customer", subFolderItemId: 112 },
    { subFolderItem: "By Implementer", subFolderItemId: 113 },
    { subFolderItem: "By Repsonsible Person", subFolderItemId: 114 },
    { subFolderItem: "By Major Topics", subFolderItemId: 115 },
    // {subFolderItem:"By Sub Topics",subFolderItemId:115},
  ];
  userEmailAddress: string;
  userFirstAndLastName: string;
  IsFromEmployeeInfo: any = false;
  SelectedEmployeeInformation: any;
  ReportofTasksListShowHide: boolean;
  oasMainDashboardMenuShowHide: boolean;
  oasMainDashboardMenuCollapseShowHide: boolean;
  oasMainDashboardMenuExpandShowHide: boolean;
  constructor(
    private _Router: Router,
    private LeftNav: LeftNavService,
    private dialogService: DialogService,
    private http: HttpClient
  ) {
    super();
    this.commonhelperobj = new commonHelperService(http);
  }
  ngOnInit() {
    // this.GetNavMenusList();
    if (this.hasValue(Config.OASOrganizationModel)) {
      this.IsCallFromEHRApplication = false;
      this.userEmailAddress =
        Config.OASOrganizationModel.LoggedUserEmailAddress;
      this.userFirstAndLastName = `${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}`;
    } else {
      this.IsCallFromEHRApplication = true;
      this.userEmailAddress = Config.EMRPracticeModelForOAS.FullName;
      this.userFirstAndLastName = `${Config.EMRPracticeModelForOAS.FullName}`;
    }
    if ($(window).width() < 1000) {
      document.getElementById("slide-out").style.display = "none";
    }
    // this.LeftNav.currentSelected.subscribe(this.setSelectedNav);

    this.LeftNav.currentSelected.subscribe((value) => {
      //debugger;
      this.CurrentSelectedLeftNav = value;
      if (this.CurrentSelectedLeftNav != null) {
        if (this.CurrentSelectedLeftNav.SelectedDashBoardWidgetId != null) {
          // let elemtInstance: any = this.sidebar.nativeElement.querySelector('#divTaskInfoLeftNav');
          const elemtInstance: HTMLElement = document.getElementById(
            "divTaskInfoLeftNav"
          ) as HTMLElement;
          // const elemtInstance: HTMLElement = document.getElementById('divTaskInfoLeftNav') as HTMLElement;
          elemtInstance.click();
        }
      } else {
        this.loadCommonInfoOnLoad();
      }
    });
    this.loadCommonInfoOnLoad();
    if (!$("html").hasClass("no-touch")) {
      $(document).bind("touchstart", function (e) {
        $(".dropdown-content").css("display", "none");
        // $("#slide-out").css('display','none');
        //Close filters drop-downs if user taps ANYWHERE in the page
      });
    }
    this.LeftSideFoldersList();
    this.ReportofTasksListShowHide = true;
    //this.oasMainDashboardMenuShowHide: false;
    //.oasMainDashboardMenuCollapseShowHide: boolean;
    oasMainDashboardMenuExpandShowHide: true;
  }
  private LeftSideFoldersList() {
    var PostDataToService = {};
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "LeftSideFoldersList", PostDataToService)
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        // this.LeftSideFoldersListNew.LeftSideSubFoldesList=[]
        this.LeftSideFoldersListNew =
          serviceResponse.LeftSideFolderListModelList;
      });
    console.log(this.LeftSideFoldersListNew,"sidenav")
  }

  myFunctionClick() {
    // document.getElementById("myDropdown").classList.toggle("show");
    document.getElementById("myDropdown").style.display = "block";
  }

  oasMobileMenuClick() {
    document.getElementById("slide-out").style.display = "block";
    document.getElementById("slide-out").style.display = "block";
  }
  closeInMobile() {
    document.getElementById("slide-out").style.display = "none";
    this.LeftNav.currentSelected.subscribe(this.setSelectedNav);
    this.loadCommonInfoOnLoad();
  }
  setSelectedNav(item) {
    this.CurrentSelectedLeftNav = item;

    if (this.CurrentSelectedLeftNav != null) {
      if (this.CurrentSelectedLeftNav.SelectedDashBoardWidgetId != null) {
        // let elemtInstance: any = this.sidebar.nativeElement.querySelector('#divTaskInfoLeftNav');
        // let elemtInstance: HTMLElement = document.getElementById('divTaskInfoLeftNav') as HTMLElement;
        const elemtInstance: HTMLElement = document.getElementById(
          "divTaskInfoLeftNav"
        ) as HTMLElement;
        elemtInstance.click();
      }
    } else {
      this.loadCommonInfoOnLoad();
    }
  }
  EmployeeInfoClickEvent() {
    const outletObj = {};
    // this._Router.navigate(['/home', { outlets: outletObj }]);
    this._Router.navigate([
      "/home",
      { outlets: { main: "app-employee-info-component" } },
    ]);
  }
  AddTaskInfoClickEvent() {
    const outletObj = {};
    const clickedItem: any = {};
    clickedItem.routename = "main";
    clickedItem.routepath = "/home";

    // if (clickedItem.MenuInfoID === 58) {
    //   clickedItem.IsFromEmployeeOrShiftsInfo = true;
    // } else {
    //   clickedItem.IsFromEmployeeOrShiftsInfo = false;
    // }
    outletObj[clickedItem.routename] = [
      "app-view-task-information",
      this.setCommonData(clickedItem),
    ];
    this._Router.navigate([clickedItem.routepath, { outlets: outletObj }]);

    // const outletObj = {};
    // // this._Router.navigate(['/home', { outlets: outletObj }]);
    // this._Router.navigate(['/home', { outlets: { 'main': 'app-view-task-information' } }]);
  }
  DashboardInfoClickEvent() {
    const outletObj = {};
    // this._Router.navigate(['/home', { outlets: outletObj }]);
    this._Router.navigate([
      "/home",
      { outlets: { main: "app-employee-dashboard-view" } },
    ]);
  }
  ProjectHieracyInfoClickEvent() {
    const outletObj = {};
    // this._Router.navigate(['/home', { outlets: outletObj }]);
    this._Router.navigate([
      "/home",
      { outlets: { main: "app-project-hierarchy-info" } },
    ]);
  }
  CreateTaskClickEvent() {
    const outletObj = {};
    // this._Router.navigate(['/home', { outlets: outletObj }]);
    this._Router.navigate([
      "/home",
      { outlets: { main: "app-create-new-task-component" } },
    ]);
  }

  ReturnToHomePage() {
    this._Router.navigateByUrl("/");
  }
  // OnListClick(clickedItem: any, parentMenuItem: any, curEvent: any) {
  //   if (this.hasValue(clickedItem) && this.hasValue(clickedItem.MenuComponentName)) {

  //     const outletObj = {};
  //     clickedItem.routename = 'main';
  //     clickedItem.routepath = '/home';
  //     setTimeout(() => {
  //       // if (!curEvent.currentTarget.classList.contains("show"))
  //       if (document.getElementById('divQuickAddSettings') !== undefined && document.getElementById('divQuickAddSettings') !== null) {
  //         document.getElementById('divQuickAddSettings').classList.remove('show');
  //       }
  //     }, 60);

  //     //  $(".rhttablst li").css("background: #26c6da !important");
  //     outletObj[clickedItem.routename] = [clickedItem.MenuComponentName, this.setCommonData(clickedItem)];

  //     // working
  //     this._router.navigate([clickedItem.routepath, { outlets: outletObj }]);

  //     // this.homePageNavMenusList.forEach(elementItem => {
  //     //   elementItem.listselected = "";
  //     // });
  //     const allElements = this.homePage.nativeElement.querySelectorAll('[submenuli]');
  //     allElements.forEach(element => {
  //       if (element.classList.contains('active')) {
  //         element.classList.remove('active');
  //       }
  //     });
  //     // document.getElementById("divQuickAddSettings").classList.remove('show');
  //     this.navmenuComponent.OnListClick(clickedItem, null, curEvent);

  //   }
  // }
  /**
   * When user clicks on side nav Task Information
   */
  // onTaskInformationSideNavClickEvent(selectedWidgetId?) {
  //   debugger;
  //   const outletObj3 = {};
  //   const clickedItem2: any = {};
  //   clickedItem2.routename = 'main';
  //   clickedItem2.routepath = '/home';
  //   outletObj3[clickedItem2.routename] = ['app-view-task-information', this.setCommonData(clickedItem2)];
  //   this._Router.navigate([clickedItem2.routepath, { outlets: outletObj3 }]);
  // }
  loadSubFoldersWindow(clickedItem) {
    // console.log(clickedItem);
    if (clickedItem === 1) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "by-status.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 2) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "by-customer.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 3) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "by-implementer.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 4) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "by-repsonsible-person.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 5) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "by-major-topics.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    }
    // else if (clickedItem === 6) {
    //   const dataToRoute = { };
    //   const outletObj = {};
    //   outletObj['main'] = ['by-sub-topics.component', this.setCommonData(dataToRoute)];
    //   this._Router.navigate(['/home', { outlets: outletObj }]);
    // }
    else if (clickedItem === 6 || clickedItem === 13) {
      const outletObj = {};
      const dataToRoute = {
        NeedtoShowAllTasks: clickedItem === 6 ? false : true,
      };
      outletObj["main"] = [
        "app-view-task-information",
        this.setCommonData(dataToRoute),
      ];
      delete this.CurrentSelectedLeftNav;
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 7) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "customer-tickets.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 8) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "easy-form-task-report.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 9) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "all-customer-tickets.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 10) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "all-appointments.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 11) {
      const dataToRoute = {
        isFromAllCustomerCallLog: true,
      };
      const outletObj = {};
      outletObj["main"] = [
        "customer-call-log.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 12) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "over-due-report.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 14) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "statistics-report.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 15) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "not-responded-tickets-report-list.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 16) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "consolidated-tickets-report.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    } else if (clickedItem === 17) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "support-statistics-main-view.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    }
    else if (clickedItem === 18) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "customer-ticlets-count-by-practice-wise.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    }
    else if (clickedItem === 19) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "ticket-monitoring-report.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    }
    else if (clickedItem === 20) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "app-replied-all-tickets-to-customer.component",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    }
    else if (clickedItem === 21) {
      const dataToRoute = {};
      const outletObj = {};
      outletObj["main"] = [
        "app-customer-replied-morethan-three-comments-fortickets",
        this.setCommonData(dataToRoute),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    }
  }

  OnListClick(clickedItem: any, curEvent: any, issubitem: boolean) {
    if (this.IsFromEmployeeInfo == true) {
      this.oasMainDashboardFromEmployeNavigation();
    } else {
      this.applyActiveClassForselectedTab(curEvent, issubitem);
    }
    if (issubitem) {
      this.loadSubFoldersWindow(clickedItem);
      return;
    }

    if (this.hasValue(clickedItem)) {
      clickedItem = parseInt(clickedItem);
      if (clickedItem === 11) {
        this.ReportofTasksListShowHide = !this.ReportofTasksListShowHide;
        if (this.ReportofTasksListShowHide) {
          this.loadCommonInfoOnLoad();
        }
      } else if (!issubitem) {
        this.ReportofTasksListShowHide = true;
      }
      if (clickedItem === 1) {
        const outletObj = {};
        this._Router.navigate([
          "/home",
          { outlets: { main: "app-employee-dashboard-view" } },
        ]);
      } else if (clickedItem === 2) {
        const outletObj = {};
        const clickedItem1: any = {};
        clickedItem1.routename = "main";
        clickedItem1.routepath = "/home";
        outletObj[clickedItem1.routename] = [
          "app-employee-info-component",
          this.setCommonData(clickedItem1),
        ];
        this._Router.navigate([
          "/home",
          { outlets: { main: "app-employee-info-component" } },
        ]);
      } else if (clickedItem === 3) {
        const outletObj = {};
        this._Router.navigate([
          "/home",
          { outlets: { main: "app-project-hierarchy-info" } },
        ]);
      } else if (clickedItem === 5) {
        const outletObj = {};
        this._Router.navigate([
          "/home",
          { outlets: { main: "app-create-new-task-component" } },
        ]);
      } else if (clickedItem === 6) {
        const outletObj = {};
        this._Router.navigate([
          "/home",
          { outlets: { main: "app-task-customization-information" } },
        ]);
      } else if (clickedItem === 7) {
        const outletObj = {};
        this._Router.navigate([
          "/home",
          { outlets: { main: "app-uncustomized-task-list" } },
        ]);
      } else if (clickedItem === 8) {
        const outletObj = {};
        this._Router.navigate([
          "/home",
          {
            outlets: {
              main: "all-tasks-summary-by-forwarded-to-me-bucket.component",
            },
          },
        ]);
      } else if (clickedItem === 9) {
        const outletObj = {};
        this._Router.navigate([
          "/home",
          { outlets: { main: "report-by-responsible-employee.component" } },
        ]);
      } else if (clickedItem === 10) {
        const dataToRoute = {};
        const outletObj = {};
        outletObj["main"] = [
          "access-information.component",
          this.setCommonData(dataToRoute),
        ];
        this._Router.navigate(["/home", { outlets: outletObj }]);
        // this.selectedRowsString = undefined;
      } else if (clickedItem === 12) {
        const outletObj = {};
        this._Router.navigate([
          "/home",
          { outlets: { main: "my-appointments.component" } },
        ]);
      } else if (clickedItem === 13) {
        const outletObj = {};
        this._Router.navigate([
          "/home",
          { outlets: { main: "practice-status-check-list.component" } },
        ]);
      } else if (clickedItem === 14) {
        const dataToRoute = {
          isFromAllCustomerCallLog: false,
        };
        const outletObj = {};
        outletObj["main"] = [
          "customer-call-log.component",
          this.setCommonData(dataToRoute),
        ];
        this._Router.navigate(["/home", { outlets: outletObj }]);

        // const outletObj = {};
        // this._Router.navigate(['/home', { outlets: { 'main': 'customer-call-log.component' } }]);
      }
    }

    if ($(window).width() < 1000) {
      document.getElementById("slide-out").style.display = "none";
    }
  }

  // TicketInformationClick(){
  //   const outletObj = {};
  //   this._Router.navigate(['/home', { outlets: { 'main': 'ticket-information.component' } }]);
  // }

  loadCommonInfoOnLoad() {
    const PostDataService = {};
    const outletObj = {};
    outletObj["main"] = [
      "app-common-message-info",
      this.setCommonData(PostDataService),
    ];
    this._Router.navigate(["/home", { outlets: outletObj }]);
  }

  LoadAccessInfoFromEmployeeForm(SelectedEmployeeInformation) {
    this.IsFromEmployeeInfo = true;
    this.SelectedEmployeeInformation = SelectedEmployeeInformation;
    const elementFound2 = this.divaasDashboardinfo.nativeElement.querySelector(
      '[id="' + "menulist" + '"]'
    );
    elementFound2.click();
    // this.divaasDashboardinfo.nativeElement
    // $( ".list-group-item list-group-item-action" ).trigger( "click" );
  }
  ChangePasswordClick() {
    const dialogRef = this.dialogService.open({
      title: "Change Password",
      // Show component
      content: ChangePasswordPopupComponentComponent,
      height: 698,
      width: 550,
      appendTo: this.containerRef,
    });
    dialogRef.content.instance.userEmailAddress = this.userEmailAddress;
    // dialogRef.content.instance.EmployeeID = this.userEmailAddress;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.changePaswordModel)) {
        } else {
          this.changePaswordModel = {};
        }
      } else {
        if (this.hasValue(result)) {
          this.changePaswordModel = result;
        }
      }
    });
  }

  oasMainDashboardProfileClick() {
    const dialogRef = this.dialogService.open({
      title: "Employee Info",
      // Show component
      content: AddEmployeeInfoPopupComponent,
      height: 698,
      width: 700,
      appendTo: this.containerRef,
    });
    dialogRef.content.instance.oasMainDashboardProfile =
      Config.OASOrganizationModel.LoggedUserID;
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        return;
      }
    });
  }

  oasMainDashboardMenuCollapseClick() {
    this.oasMainDashboardMenuShowHide = true;
    this.oasMainDashboardMenuCollapseShowHide = true;
    //this.oasMainDashboardMenuExpandShowHide=false;
  }
  oasMainDashboardMenuExpandClick() {
    this.oasMainDashboardMenuShowHide = false;
    //  this.oasMainDashboardMenuExpandShowHide=true;
    this.oasMainDashboardMenuCollapseShowHide = false;
  }
  oasMainDashboardFromEmployeNavigation() {
    const outletObj = {};
    outletObj["main"] = [
      "access-information.component",
      this.setCommonData(this.SelectedEmployeeInformation),
    ];
    this._Router.navigate(["/home", { outlets: outletObj }]);
    this.IsFromEmployeeInfo = false;
    const allElements =
      this.sidebar.nativeElement.querySelectorAll("[submenuli]");

    allElements.forEach((element) => {
      if (element.classList.contains("active")) {
        element.classList.remove("active");
      }
    });
    if (Config.OASOrganizationModel.LoginType == 1) {
      if (this.hasValue(allElements) && allElements[9] != null) {
        allElements[9].classList.add("active");
      }

      // this.selectedRowsString = undefined;
    } else {
      if (this.hasValue(allElements) && allElements[7] != null) {
        allElements[7].classList.add("active");
      }
    }
  }
  applyActiveClassForselectedTab(curEvent, issubitem) {
    const mainElements =
      this.sidebar.nativeElement.querySelectorAll("[submenuli]");
    mainElements.forEach((element) => {
      this.applyActiveClass(element);
      if (element.children[2] != undefined && !issubitem) {
        element.children[2].style = "display : none";
        // element.children[2].className="submenu closed"
      }
    });
    const subfolderElements =
      this.sidebar.nativeElement.querySelectorAll("[subfoldermenuli]");
    subfolderElements.forEach((element) => {
      this.applyActiveClass(element);
    });
    if (this.hasValue(curEvent) && curEvent.currentTarget != null) {
      if (curEvent.currentTarget && curEvent.currentTarget.children[2]) {
        //(parseInt(clickedItem)== 4 ||parseInt(clickedItem) == 11)
        if (curEvent.currentTarget.children[2].className == "submenu closed") {
          curEvent.currentTarget.children[2].className = "submenu opened";
          curEvent.currentTarget.children[2].style = "display : block";
        } else {
          curEvent.currentTarget.children[2].className = "submenu closed";
          curEvent.currentTarget.children[2].style = "display : none";
          this.loadCommonInfoOnLoad();
        }
      } else {
        curEvent.currentTarget.classList.add("active");
      }
    }
    mainElements.forEach((element) => {
      // this.applyActiveClass(element)
      if (element.children[2]) {
        var style = window.getComputedStyle(element.children[2]);
        if (style.display == "none") {
          // &&  element.children[2].style.contains("display : none")
          element.children[2].className = "submenu closed";
          this.loadCommonInfoOnLoad();
        }
      }
    });
  }
  applyActiveClass(element) {
    if (element.classList && element.classList.contains("active")) {
      element.classList.remove("active");
    }
  }
}
