import { SUPER_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { commonhelper } from '../common/commonhelper';

@Component({
  selector: 'app-scheduled-meeting-add-comments-popup',
  templateUrl: './scheduled-meeting-add-comments-popup.component.html',
  styleUrls: ['./scheduled-meeting-add-comments-popup.component.css']
})
export class ScheduledMeetingAddCommentsPopupComponent extends commonhelper implements OnInit {
  SelectedCommentInfo: any;
  saveUpdateButton="Save"
  constructor(private dialogService: DialogService, private dialog: DialogRef,) { super() }
  scheduledMeetingAddCommentsPopupEnteredComments: any;
  ngOnInit() {
    this.setfocusonCommentsField();
    if (this.hasValue(this.SelectedCommentInfo)) {
      this.scheduledMeetingAddCommentsPopupEnteredComments=this.SelectedCommentInfo.MeetingScheduledComments||this.SelectedCommentInfo.CustomerCallLogComments
      this.saveUpdateButton="Update"
    }
  }
  scheduledMeetingAddCommentsPopupSaveClickEvent() {
    if (this.validateMandatoryFields()) return;
    this.dialog.close(this.scheduledMeetingAddCommentsPopupEnteredComments);
  }
  validateMandatoryFields() {
    if (!this.hasValue(this.scheduledMeetingAddCommentsPopupEnteredComments) || !this.hasValue(this.scheduledMeetingAddCommentsPopupEnteredComments.trimStart())) {
      this.ShowErrorMessage("Please Enter Comments")
      this.setfocusonCommentsField();
      return true;
    }
  }
  setfocusonCommentsField() {
    setTimeout(() => {
      document.getElementById("taScheduledMeetingAddCommentsPopupComments").focus();
    }, 100);

  }
  scheduledMeetingAddCommentsPopupCancelClickEvent() {
    this.dialog.close();
  }
}
