import { Component, OnInit, ViewChild } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { isError } from 'util';
import { FormsModule, FormControl } from '@angular/forms';
import { element } from 'protractor';


@Component({
  selector: 'app-view-full-image-component',
  templateUrl: './view-full-image-component.component.html',
  styleUrls: ['./view-full-image-component.component.css']
})
export class ViewFullImageComponentComponent extends commonhelper implements OnInit {
  @ViewChild('divTaskFullImg') divTaskFullImg;
  private commonHelperobj: commonHelperService;
  public ParentProjectsModel: any;
  constructor(private http: HttpClient) {
    super();
    this.commonHelperobj = new commonHelperService(http);
  }
  dialogOpened: any;
  ngOnInit() {
    let elemtInstance: any = this.divTaskFullImg.nativeElement.querySelector('#divTaskAttachment');
    elemtInstance = $(elemtInstance);
    // alert(elemtInstance);
    this.TasksLoadImageURL(this.ParentProjectsModel.SelectedImage);
  }
  closeClickEvent() {

  }
  TasksLoadImageURL(imageURL) {
    let elemtInstance: any = this.divTaskFullImg.nativeElement.querySelector('#divTaskAttachment');
    elemtInstance = $(elemtInstance);
    elemtInstance.append('<img src=\'' + imageURL + '\' alt=\'Task Attachment\' id=\'TaskAttachment\' />');
  }
}
