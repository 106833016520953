import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@progress/kendo-angular-notification';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { TaskService } from '../services/task.service';
import { LeftNavService } from '../common/Leftnav.service';

@Component({
  selector: 'app-enter-multiple-email-address-popup',
  templateUrl: './enter-multiple-email-address-popup.component.html',
  styleUrls: ['./enter-multiple-email-address-popup.component.css']
})
export class EnterMultipleEmailAddressPopupComponent extends commonhelper implements OnInit {
  @ViewChild('EnterEmailAddress') EnterEmailAddress: ElementRef;
  public commonhelperobj: commonHelperService;
  public TaskCustomizationModel: any = {};
  public PushEntriedEmailAddress: Array<any> = [];
  public emailslist: any = {};
  public getEnteredEmailAddress: any;
  public usersModel: any = {};
  private editmodedata: any = {};
  public HideSaveButton = true;

  // tslint:disable-next-line:max-line-length
  constructor(httpClient: HttpClient, private notificationService: NotificationService, private dialogService: DialogService, private dialog: DialogRef, private taskService: TaskService, private LeftNav: LeftNavService) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }
  ngOnInit() {
  }

  SaveFieldValue() {
    if (!this.hasValue(this.TaskCustomizationModel.EmailAddress)) {
      this.ShowErrorMessage('Please Enter Email Address.');
      const elementFound2 = this.EnterEmailAddress.nativeElement.querySelector('[id="' + 'divEmailAddress' + '"]');
      elementFound2.focus();
    }
    const elementFound = this.EnterEmailAddress.nativeElement.querySelector('#divEmailAddress');
    if ((this.validateEmail(elementFound)) === false) {
      return false;
    } else {

      this.ValidateEmailCustomizationAddress(elementFound);
    }

  }
  emailAddressBlurEvent() {
    this.SaveFieldValue();
  }
  ValidateEmailCustomizationAddress(elementFound) {
    const postData: any = {
      EHREMailIDs: this.TaskCustomizationModel.EmailAddress
    };
    this.commonhelperobj.PostData(Config.OASAppnURL + 'ValidateEmailCustomization', postData)
      .subscribe(serviceResponse => {
        if (this.isError(serviceResponse)) {
          return false;
        } else {
          if (this.hasValue(this.TaskCustomizationModel.EmailAddress)) {
            this.emailslist = {
              EHREMailIDs: this.TaskCustomizationModel.EmailAddress
            };

            this.PushEntriedEmailAddress.push(this.emailslist);
            setTimeout(() => {
              if (this.PushEntriedEmailAddress.length > 0) {
                this.HideSaveButton = false;
                this.TaskCustomizationModel.EmailAddress = '';
                elementFound.focus();
              }
            }, 10);

          }
        }
      });
  }
  deleteAllEmails() {
    this.PushEntriedEmailAddress = [];
    this.TaskCustomizationModel.EmailAddress = '';
  }
  deleteFieldValue(index) {
    this.PushEntriedEmailAddress.splice(index, 1);
  }

  CancelClickEvent() {
    this.dialog.close();
  }
  OKClickEvent() {
    if (!this.hasValue(this.TaskCustomizationModel.EmailAddress) && this.PushEntriedEmailAddress.length <= 0) {
      this.ShowErrorMessage('Please Enter Email Address.');
      const elementFound2 = this.EnterEmailAddress.nativeElement.querySelector('[id="' + 'divEmailAddress' + '"]');
      elementFound2.focus();
      return false;
    }
    this.ValidateEmailAddress();
  }
  ValidateEmailAddress() {

    const postData: any = {
      EHREMailIDs: this.TaskCustomizationModel.EmailAddress
    };
    this.commonhelperobj.PostData(Config.OASAppnURL + 'ValidateEmailCustomization', postData)
      .subscribe(serviceResponse => {
        if (this.isError(serviceResponse)) {
          return false;
        } else {
          if (!this.hasValue(this.PushEntriedEmailAddress) && this.PushEntriedEmailAddress.length <= 0) {
            this.ShowErrorMessage('Please Enter Email Address.');
            const elementFound2 = this.EnterEmailAddress.nativeElement.querySelector('[id="' + 'divEmailAddress' + '"]');
            elementFound2.focus();
            return false;
          }
          this.dialog.close(this.PushEntriedEmailAddress);
        }
      });
  }
}


