import { Component, OnInit, ViewChild, Output } from '@angular/core';
import { TaskService } from '../services/task.service';
import { commonhelper } from '../common/commonhelper';
import { Router } from '@angular/router';
import { Config } from '../config';
import { OasMainDashboardComponentComponent } from '../oas-main-dashboard-component/oas-main-dashboard-component.component';
import { EventEmitter } from 'events';
import { LeftNavService } from '../common/Leftnav.service';

@Component({
  selector: 'app-employee-dashboard-view',
  templateUrl: './employee-dashboard-view.component.html',
  styleUrls: ['./employee-dashboard-view.component.css']
})
export class EmployeeDashboardViewComponent extends commonhelper implements OnInit {


  pendingTaskCount = 0;
  overDueTaskCount = 0;

  teamPendingTasksCount = 0;
  teamOverdueTasksCount = 0;

  employeeType: number;

  @Output() DashBoardClickEvent = new EventEmitter();

  constructor(private _router: Router, private taskService: TaskService, private LeftNav: LeftNavService) {
    super();
  }

  ngOnInit() {
    this.taskService.taskCount().subscribe(response => {
      response.TaskList.forEach(task => {
        if (task.Status === 'Overdue') {
          this.overDueTaskCount += task.TaskCount;
        } else if (task.Status === 'Pending') {
          this.pendingTaskCount += task.TaskCount;
        }
      });
    });

    this.employeeType = Config.OASOrganizationModel.LoginType;

    if (this.employeeType !== 1) {
      this.taskService.teamTaskCount().subscribe(response => {
        response.TaskList.forEach(task => {

          if (task.StatusId === 1) {
            this.teamPendingTasksCount += task.TaskCount;
          } else if (task.StatusId === 2) {
            this.teamOverdueTasksCount += task.TaskCount;
          }

        });
      });
    }
  }

  ViewTaskInfoNavClickEvent(SelectedWidgetID, isTeamLead = false) {

    const outletObj = {};
    const clickedItem: any = {};
    clickedItem.routename = 'main';
    clickedItem.routepath = '/home';

    if (this.hasValue(SelectedWidgetID)) {
      clickedItem.SelectedDashBoardWidgetId = SelectedWidgetID;
      clickedItem.IsTeamLead = isTeamLead;
    }
    // if (clickedItem.MenuInfoID === 58) {
    //   clickedItem.IsFromEmployeeOrShiftsInfo = true;
    // } else {
    //   clickedItem.IsFromEmployeeOrShiftsInfo = false;
    // }

    // outletObj[clickedItem.routename] = ['app-view-task-information', this.setCommonData(clickedItem)];

    // this._router.navigate([clickedItem.routepath, { outlets: outletObj }]);

    this.LeftNav.OnLeftnavchange(clickedItem);

  }

}
