import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { GridOptions } from 'ag-grid-community';
import { AgGridNg2 } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { commonHelperService } from '../common/common.service';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee } from '../edit-task-customization-based-on-users/edit-task-customization-based-on-users.component';
import { TaskService } from '../services/task.service';
import { DialogCloseResult, DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { AgGridModule } from 'ag-grid-angular';
import { IntlService } from '@progress/kendo-angular-intl';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-edit-access-details-popup',
  templateUrl: './edit-access-details-popup.component.html',
  styleUrls: ['./edit-access-details-popup.component.css']
})

export class EditAccessDetailsPopupComponent extends commonhelper implements OnInit {
  @ViewChild('divEditAccessDetailsPopup') divEditAccessDetailsPopup: ElementRef;
  // public value: Date= new Date(2000, 2, 10, 13, 30, 0);
  editAccessDetailsDeviceGroupList: any;
  editAccessDetailsDeviceList: any;
  public SelectedAccesInformation: any;
  public AccessInformationModel: any = {};
  private commonhelperobj: commonHelperService;
  isDisabled: boolean
  IsDisabled: boolean
  Disabled: boolean;
  public timeformat: any;
  public status1: boolean;
  public status2: boolean;
  public TodayDetails: any;
  public UpdateAccessInformation: any = {};
  public ActionTimeId: any;
  SelectedDate: Date;
  // NextDate:Date;
  DateToConsider: any;
  TimeFormat: any;
  DevicesList: any;
  public SelectedAccessInformationforAdd: any;
  // public AccessInformationDayDetailsForToday:any;
  // public AccessInformationDayDetailsGet:any;
  public AccessInformationmodel: any = {};
  public AccessInformationModelList: Array<string> = [];
  editAccessDetailsPopupSaveButtonText: any;
  editAccessDetailsPopupDeviceHideInAdd: any;
  editAccessDetailsDateLabel: string;
  DevicesListCheckInTime: any;
  DevicesListCheckOutTime: any;
  //public NextDate = this.AccessInformationModel.DateToConsider(this.AccessInformationModel.DateToConsider - 24 * 60 * 60 * 1000);
  //public NextDate = new Date(this.AccessInformationModel.DateToConsider - 24 * 60 * 60 * 1000);

  //public TIME:any;
  // public checkintime:any;
  constructor(private httpClient: HttpClient, http: HttpClient,
    private dialogService: DialogService,
    private dialog: DialogRef, private intl: IntlService) {
    super();
    this.commonhelperobj = new commonHelperService(http);

  }
  public defaultEditAccessDetailsDeviceGroupItem = { AccessDeviceGroup: '-- Select Access Device Group --', AccessDeviceGroupID: null }
  public defaultEditAccessDetailsDeviceItem = { AccessDevice: '-- Select Access Device --', AccessDeviceID: null }
  private parseDate(dateString: string): Date {
    if (!this.hasValue(dateString) || dateString.indexOf('/') === dateString.lastIndexOf('/')) {
      return undefined;
    }
    const [month, day, year] = dateString.split('/').map(dateFragment => +dateFragment);
    return new Date(year, month - 1, day);
  }
  public date = new Date();
  // public Year = this.date.getFullYear();
  // public Month = this.date.getMonth();
  // public day = this.date.getDay();
  // public hours = this.date.getHours();
  // public minutes = this.date.getMinutes();
  // public seconds = this.date.getSeconds();
  ngOnInit() {


    // this.AccessDetailsDeviceList()

    this.AccessEditInformation();
    if (this.hasValue(this.SelectedAccesInformation.CheckInTime)) {
      this.isDisabled = true;
      if (this.isDisabled == true) {
        this.status1 = true;
      }
      else {
        this.status1 = false;
      }
    }
    if (this.hasValue(this.SelectedAccesInformation.CheckOutTime)) {
      this.IsDisabled = true;
      if (this.IsDisabled == true) {
        this.status2 = true;
      }
      else {
        this.status2 = false;
      }
    }
    // this.AccessInformationModel.DatetoConsider=this.formatValue(this.AccessInformationModel.DateToConsider);
    // if ( this.AccessInformationModel.DatetoConsider == this.SelectedAccesInformation.DateToConsider) {
    if (this.status2 == true) {
      this.AccessInformationModel.editAccessDetailsPopupCheckoutTime = parseInt(this.AccessInformationModel.editAccessDetailsPopupCheckOutTime.substring(0, 2))
      if (this.AccessInformationModel.editAccessDetailsPopupCheckoutTime > 8) {
        this.Disabled = true;
      }
      // }
    }
    setTimeout(function () {
      $("#txtEditAccessDetailsPopupCheckInTime").focus();
      // $(".EditAccessDetailsDateWidthClass").addClass("col-12 col-md-6 col-sm-12 col-xs-12").removeClass("col-12 col-md-6 col-sm-12 col-xs-12");
    }, 50);
    if (this.hasValue(this.SelectedAccesInformation)) {
      this.editAccessDetailsPopupSaveButtonText = "Update";
      this.editAccessDetailsPopupDeviceHideInAdd = false;
      this.editAccessDetailsDateLabel = "Date";
      $(".EditAccessDetailsDateWidthClass").addClass("col-12 col-md-12 col-sm-12 col-xs-12").removeClass("col-12 col-md-6 col-sm-12 col-xs-12");
    }
    else {
      this.editAccessDetailsPopupSaveButtonText = "Save";
      this.editAccessDetailsPopupDeviceHideInAdd = true;
      this.editAccessDetailsDateLabel = "Check in Date";
      $(".EditAccessDetailsDateWidthClass").removeClass("col-12 col-md-6 col-sm-12 col-xs-12").addClass("col-12 col-md-6 col-sm-12 col-xs-12");
    }

    this.AccessDetailsDeviceGroupList();
  }
  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, 'd')}` : '';
  }


  public AccessEditInformation() {

    //const time =  this.SelectedAccesInformation.CheckInTime

    //var datetime = new Date('1970-01-01T' + time + 'Z');
    // const time1 = parseInt(time[0])
    // const time2 = parseInt(time[1])
    // const time3 = parseInt(time[2])
    if (this.hasValue(this.SelectedAccesInformation)) {
      this.AccessInformationModel.DateToConsider = this.parseDate(this.SelectedAccesInformation.DateToConsider)
      //this.AccessInformationModel.editAccessDetailsPopupCheckInTime=new Date(this.Year,this.Month,this.day,this.hours,this.minutes,this.seconds)
      this.AccessInformationModel.editAccessDetailsPopupCheckInTime = this.SelectedAccesInformation.CheckInTime;
      this.AccessInformationModel.editAccessDetailsPopupCheckOutTime = this.SelectedAccesInformation.CheckOutTime;
      this.AccessInformationModel.DeviceGroupID = this.SelectedAccesInformation.DeviceGroupID;
      this.AccessDetailsDeviceList();
      //this.OnAccessDevicesList(this.AccessInformationModel.DeviceGroupID);
    }
    else {
      this.AccessInformationModel.DateToConsider = this.parseDate(this.SelectedAccessInformationforAdd.DateToConsider)

      this.AccessInformationModel.CheckoutDateToConsider = this.parseDate(this.SelectedAccessInformationforAdd.DateToConsider)
      //this.AccessInformationModel.DeviceGroupID=
    }
  }

  OnAccessDevicesList(value) {
    this.AccessInformationModel.DeviceGroupID = value.AccessDeviceGroupID
    //  this.AccessInformationModel.DeviceGroupID=this.AccessInformationModel.DeviceGroupID
    this.AccessDetailsDeviceList()
    //this.AccessInformationModel.DeviceGroupID=this.AccessInformationModel.DeviceGroupID
    //this.AccessInformationModel.DeviceGroupID=value.AccessDeviceGroupID
  }

  public editAccessDetailsUpdateClick() {
    if (this.hasValue(this.SelectedAccesInformation)) {
      if (!this.hasValue(this.AccessInformationModel.DateToConsider)) {
        this.ShowErrorMessage('Please Enter Date');
        return;
      }
      if (this.hasValue(this.AccessInformationModel.DateToConsider)) {
        //   this.NextDate = new Date(this.AccessInformationModel.DateToConsider);
        // this.NextDate.setDate(this.AccessInformationModel.DateToConsider.getDay() + 1);
        this.AccessInformationModel.DateToConsider = this.formatValue(this.AccessInformationModel.DateToConsider);
        this.DateToConsider = this.parseDate(this.SelectedAccesInformation.DateToConsider)
        this.DateToConsider = this.formatValue(this.DateToConsider);
        this.SelectedDate = new Date(this.DateToConsider);

        const daysDifference = this.DateDiffInDays(this.AccessInformationModel.DateToConsider, this.SelectedDate);
        if (daysDifference == 1 || daysDifference == 0) {

        }
        else {
          this.ShowErrorMessage('Date Should be One Day Greater than or Equal to Selected Date');
          return;
        }
      }
    }
    if (this.hasValue(this.SelectedAccessInformationforAdd)) {
      if (!this.hasValue(this.AccessInformationModel.DateToConsider)) {
        this.ShowErrorMessage('Please Enter Date');
        return;
      }
      if (this.hasValue(this.AccessInformationModel.DateToConsider)) {
        //   this.NextDate = new Date(this.AccessInformationModel.DateToConsider);
        // this.NextDate.setDate(this.AccessInformationModel.DateToConsider.getDay() + 1);
        this.AccessInformationModel.DateToConsider = this.formatValue(this.AccessInformationModel.DateToConsider);
        this.DateToConsider = this.parseDate(this.SelectedAccessInformationforAdd.DateToConsider)
        this.DateToConsider = this.formatValue(this.DateToConsider);
        this.SelectedDate = new Date(this.DateToConsider);
        const daysDifference = this.DateDiffInDays(this.AccessInformationModel.DateToConsider, this.SelectedDate);
        if (daysDifference == 1 || daysDifference == 0) {

        }
        else {
          this.ShowErrorMessage('Check In Date Should be One Day Greater than or Equal to Selected Date');
          const elementFound = this.divEditAccessDetailsPopup.nativeElement.querySelector('[name="' + 'divEditAccessDetailsPopupDate' + '"]');
          if (this.hasValue(elementFound)) {
            elementFound.children[0].children[0].children[0].children[0].focus();
          }
          return;
        }
      }
      if (!this.hasValue(this.AccessInformationModel.CheckoutDateToConsider)) {
        this.ShowErrorMessage('Please Enter Date');
        return;
      }
      if (this.hasValue(this.AccessInformationModel.CheckoutDateToConsider)) {
        //   this.NextDate = new Date(this.AccessInformationModel.DateToConsider);
        // this.NextDate.setDate(this.AccessInformationModel.DateToConsider.getDay() + 1);
        this.AccessInformationModel.CheckoutDateToConsider = this.formatValue(this.AccessInformationModel.CheckoutDateToConsider);
        this.DateToConsider = this.parseDate(this.SelectedAccessInformationforAdd.DateToConsider)
        this.DateToConsider = this.formatValue(this.DateToConsider);
        this.SelectedDate = new Date(this.DateToConsider);

        const daysDifference = this.DateDiffInDays(this.AccessInformationModel.CheckoutDateToConsider, this.SelectedDate);
        if (daysDifference == 1 || daysDifference == 0) {

        }
        else {
          this.ShowErrorMessage('Check Out Date Should be One Day Greater than or Equal to Selected Date');
          const elementFound = this.divEditAccessDetailsPopup.nativeElement.querySelector('[name="' + 'divEditAccessDetailsPopupCheckoutDate' + '"]');
          if (this.hasValue(elementFound)) {
            elementFound.children[0].children[0].children[0].children[0].focus();
          }
          return;
        }
      }


    }
    if (!this.hasValue(this.AccessInformationModel.editAccessDetailsPopupCheckInTime)) {
      this.ShowErrorMessage('Please Enter Check In Time');
      document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
      return;
    }
    if (this.hasValue(this.AccessInformationModel.editAccessDetailsPopupCheckInTime)) {
      if (this.AccessInformationModel.editAccessDetailsPopupCheckInTime[2] != ":" || this.AccessInformationModel.editAccessDetailsPopupCheckInTime[5] != ":") {
        this.ShowErrorMessage("Time Format Should be HH:MM:SS");
        document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
        return;
      }
      if (this.AccessInformationModel.editAccessDetailsPopupCheckInTime.length != 8) {
        this.ShowErrorMessage("Time Format Should be HH:MM:SS");
        document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
        return;
      }
      if (this.hasValue(this.SelectedAccesInformation)) {
        var HoursCheckInTime = parseInt(this.AccessInformationModel.editAccessDetailsPopupCheckInTime.substring(0, 2));
        var MinutesCheckInTime = parseInt(this.AccessInformationModel.editAccessDetailsPopupCheckInTime.substring(3, 5));
        var SecondsCheckInTime = parseInt(this.AccessInformationModel.editAccessDetailsPopupCheckInTime.substring(6, 8));
        var HoursCheckOutTime = parseInt(this.AccessInformationModel.editAccessDetailsPopupCheckOutTime.substring(0, 2));
        var MinutesCheckOutTime = parseInt(this.AccessInformationModel.editAccessDetailsPopupCheckOutTime.substring(3, 5));
        var SecondsCheckOutTime = parseInt(this.AccessInformationModel.editAccessDetailsPopupCheckOutTime.substring(6, 8));
      }
      else {
        var HoursCheckInTime = parseInt(this.AccessInformationModel.editAccessDetailsPopupCheckInTime.substring(0, 2));
        var MinutesCheckInTime = parseInt(this.AccessInformationModel.editAccessDetailsPopupCheckInTime.substring(3, 5));
        var SecondsCheckInTime = parseInt(this.AccessInformationModel.editAccessDetailsPopupCheckInTime.substring(6, 8));
      }
      if (HoursCheckInTime > 23) {
        this.ShowErrorMessage("Time Format Should be 24 Hours Format")
        document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
        return;
      }
      if (MinutesCheckInTime > 59) {
        this.ShowErrorMessage("Minutes Should be Less than or Eqaul to 59")
        document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
        return;
      }
      if (SecondsCheckInTime > 59) {
        this.ShowErrorMessage("Seconds Should be Less than or Eqaul to  59")
        document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
        return;
      }
      if (this.hasValue(this.AccessInformationModel.DateToConsider)) {
        if (this.AccessInformationModel.DateToConsider == this.DateToConsider) {
          if (this.status2 == true) {
            if (HoursCheckInTime < 8) {
              this.ShowErrorMessage("Check In Time Should be Greater than or Equal to Eight");
              document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
              return;
            }
            // if (HoursCheckOutTime >= 8) {
            //   if (HoursCheckInTime < 8) {
            //     this.ShowErrorMessage("Check In Time Should be Greater than or Equal to Eight");
            //     document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
            //     return;
            //   }
            // }
            // if (HoursCheckOutTime == 8) { 
            //   if (MinutesCheckOutTime == 0) {
            //     if (HoursCheckInTime < 8) {
            //       this.ShowErrorMessage("Check In Time Should be Greater than or Equal to Eight");
            //       document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
            //       return;
            //     }
            //   }
            //   if (SecondsCheckOutTime == 0) {
            //     if (HoursCheckInTime < 8) {
            //       this.ShowErrorMessage("Check In Time Should be Greater than or Equal to Eight");
            //       document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
            //       return;
            //     }
            //   }
            // }


            if (HoursCheckOutTime >= 8) {

              if (HoursCheckInTime > HoursCheckOutTime) {
                this.ShowErrorMessage("Check In Time Should be Less than Check Out Time");
                document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
                return;
              }
              if (HoursCheckOutTime == HoursCheckInTime) {
                if (MinutesCheckInTime > MinutesCheckOutTime) {
                  this.ShowErrorMessage("Check In Time Should be Less than Check Out Time");
                  document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
                  return;
                }
                if (MinutesCheckInTime == MinutesCheckOutTime) {
                  if (SecondsCheckInTime >= SecondsCheckOutTime) {
                    this.ShowErrorMessage("Check In Time Should be Less than  Check Out Time");
                    document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
                    return;
                  }
                }

              }
            }
          }

        }
        else {
          if (this.status2 == true) {
            if (HoursCheckOutTime <= 8) {
              if (HoursCheckInTime > 8) {
                this.ShowErrorMessage("Check In Time Should be Less than or Equal to Eight");
                document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
                return;
              }


              if (HoursCheckInTime > HoursCheckOutTime) {
                this.ShowErrorMessage("Check In Time Should be Less than Check Out Time");
                document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
                return;
              }
              if (HoursCheckOutTime == HoursCheckInTime) {
                if (MinutesCheckInTime > MinutesCheckOutTime) {
                  this.ShowErrorMessage("Check In Time Should be Less than Check Out Time");
                  document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
                  return;
                }
                if (MinutesCheckInTime == MinutesCheckOutTime) {
                  if (SecondsCheckInTime >= SecondsCheckOutTime) {
                    this.ShowErrorMessage("Check In Time Should be Less than  Check Out Time");
                    document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
                    return;
                  }
                }


              }
            }
          }
        }
        if (this.AccessInformationModel.DateToConsider == this.DateToConsider) {
          if (this.status1 == true) {
            if(HoursCheckInTime<8){
              if (this.AccessInformationModel.DateToConsider == this.DateToConsider) {
                this.ShowErrorMessage("Please Select Next Day Date");
                const elementFound = this.divEditAccessDetailsPopup.nativeElement.querySelector('[name="' + 'divEditAccessDetailsPopupDate' + '"]');
                if (this.hasValue(elementFound)) {
                  elementFound.children[0].children[0].children[0].children[0].focus();
                }
                return;
            }
          }
            if (HoursCheckInTime < 8) {
              if (HoursCheckOutTime > 8) {
                this.ShowErrorMessage("Check Out Time Should be Less than or Equal to Eight");
                document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                return;
              }

              if (HoursCheckOutTime < HoursCheckInTime) {
                this.ShowErrorMessage("Check Out Time Should be Greater than Check In Time");
                document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                return;
              }
              if (HoursCheckOutTime == HoursCheckInTime) {
                if (MinutesCheckOutTime < MinutesCheckInTime) {
                  this.ShowErrorMessage("Check Out Time Should be Greater than Check In Time");
                  document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                  return;
                }
                if (MinutesCheckInTime == MinutesCheckOutTime) {
                  if (SecondsCheckOutTime <= SecondsCheckInTime) {
                    this.ShowErrorMessage("Check Out Time Should be Greater than Check In Time");
                    document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                    return;
                  }
                }

              }

            }
            else {
              if (HoursCheckOutTime < HoursCheckInTime) {
                this.ShowErrorMessage("Check Out Time Should be Greater than Check In Time");
                document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                return;
              }
              if (HoursCheckOutTime == HoursCheckInTime) {
                if (MinutesCheckOutTime < MinutesCheckInTime) {
                  this.ShowErrorMessage("Check Out Time Should be Greater than Check In Time");
                  document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                  return;
                }
                if (MinutesCheckInTime == MinutesCheckOutTime) {
                  if (SecondsCheckOutTime <= SecondsCheckInTime) {
                    this.ShowErrorMessage("Check Out Time Should be Greater than Check In Time");
                    document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                    return;
                  }
                }

              }
            }
          }
        }
        else {
          if (this.status1 == true) {
            if (HoursCheckInTime < 8) {
              if (HoursCheckOutTime > 8) {
                this.ShowErrorMessage("Check Out Time Should be Less than or Equal to Eight");
                document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                return;
              }
              if (HoursCheckOutTime == 8) {
                if (HoursCheckOutTime == 8) {
                  if (MinutesCheckOutTime != 0) {
                    this.ShowErrorMessage("Check Out Time Should be Less than or Equal to Eight");
                    document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                    return;
                  }
                  if (SecondsCheckOutTime != 0) {
                    this.ShowErrorMessage("Check Out Time Should be Less than or Equal to Eight");
                    document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                    return;
                  }
                }
              }
              if (HoursCheckOutTime < HoursCheckInTime) {
                this.ShowErrorMessage("Check Out Time Should be Greater than Check In Time");
                document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                return;
              }
              if (HoursCheckOutTime == HoursCheckInTime) {
                if (MinutesCheckOutTime < MinutesCheckInTime) {
                  this.ShowErrorMessage("Check Out Time Should be Greater than Check In Time");
                  document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                  return;
                }
                if (MinutesCheckInTime == MinutesCheckOutTime) {
                  if (SecondsCheckOutTime <= SecondsCheckInTime) {
                    this.ShowErrorMessage("Check Out Time Should be Greater than Check In Time");
                    document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                    return;
                  }
                }

              }


            }
            if (HoursCheckOutTime > 8) {
              this.ShowErrorMessage("Check Out Time Should be Less than or Equal to Eight");
              document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
              return;
            }
            if (HoursCheckOutTime == 8) {
              if (MinutesCheckOutTime != 0) {
                this.ShowErrorMessage("Check Out Time Should be Less than or Equal to Eight");
                document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                return;
              }
              if (SecondsCheckOutTime != 0) {
                this.ShowErrorMessage("Check Out Time Should be Less than or Equal to Eight");
                document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                return;
              }
            }
          }
        }

      }

      // if (HoursCheckInTime == 23) {
      //   if(MinutesCheckInTime !=0){
      //     this.ShowErrorMessage("Time Format Should be 24 Hours Format");
      //     document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
      //     return;
      //   }
      //   if(SecondsCheckInTime !=0){
      //     this.ShowErrorMessage("Time Format Should be 24 Hours Format");
      //     document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
      //     return;
      //   }
      // }
    }



    if (!this.hasValue(this.AccessInformationModel.editAccessDetailsPopupCheckOutTime)) {
      this.ShowErrorMessage('Please Enter Check Out Time');
      document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
      return;
    }
    if (this.hasValue(this.AccessInformationModel.editAccessDetailsPopupCheckOutTime)) {
      if (this.AccessInformationModel.editAccessDetailsPopupCheckOutTime[2] != ":" || this.AccessInformationModel.editAccessDetailsPopupCheckOutTime[5] != ":") {
        this.ShowErrorMessage("Time Format Should be HH:MM:SS");
        document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
        return;
      }
      if (this.AccessInformationModel.editAccessDetailsPopupCheckOutTime.length != 8) {
        this.ShowErrorMessage("Time Format Should be HH:MM:SS");
        document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
        return;
      }

      var HoursCheckOutTime = parseInt(this.AccessInformationModel.editAccessDetailsPopupCheckOutTime.substring(0, 2));
      var MinutesCheckOutTime = parseInt(this.AccessInformationModel.editAccessDetailsPopupCheckOutTime.substring(3, 5));
      var SecondsCheckOutTime = parseInt(this.AccessInformationModel.editAccessDetailsPopupCheckOutTime.substring(6, 8));
      if (HoursCheckOutTime > 23) {
        this.ShowErrorMessage("Time Format Should Be 24 Hours Format")
        document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
        return;
      }
      if (MinutesCheckOutTime > 59) {
        this.ShowErrorMessage("Minutes Should be Less than or Eqaul to 59")
        document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
        return;
      }
      if (SecondsCheckOutTime > 59) {
        this.ShowErrorMessage("Seconds Should be Less than or Eqaul to  59")
        document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
        return;
      }
      if (!this.hasValue(this.SelectedAccesInformation)) {
        if(this.DateToConsider !=this.AccessInformationModel.DateToConsider){
          if(this.AccessInformationModel.DateToConsider !=this.AccessInformationModel.CheckoutDateToConsider){
            this.ShowErrorMessage("Please Select Next Day Date")
            const elementFound = this.divEditAccessDetailsPopup.nativeElement.querySelector('[name="' + 'divEditAccessDetailsPopupCheckoutDate' + '"]');
            if (this.hasValue(elementFound)) {
              elementFound.children[0].children[0].children[0].children[0].focus();
            }
            return;
          }
        }
        if ((this.AccessInformationModel.DateToConsider == this.AccessInformationModel.CheckoutDateToConsider)) {
          if (this.AccessInformationModel.DateToConsider == this.DateToConsider) {
            if (HoursCheckInTime < 8) {
              this.ShowErrorMessage("Check In Time Should be Greater than or Equal to Eight");
              document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
              return;
            }
          }
          else {
            if (HoursCheckInTime > 8) {
              this.ShowErrorMessage("Check In Time Should be Less than or Equal to Eight");
              document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
              return;
            }
          }
        }
        if (this.DateToConsider != this.AccessInformationModel.DateToConsider) {
          if (this.DateToConsider != this.AccessInformationModel.CheckoutDateToConsider) {

            if (HoursCheckInTime > 8) {
              this.ShowErrorMessage("Check In Time Should be Less than or Equal to Eight");
              document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
              return;
            }
            if (HoursCheckInTime == 8) {
              if (MinutesCheckInTime != 0) {
                this.ShowErrorMessage("Check In Time Should be Less than or Equal to Eight");
                document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
                return;
              }
              if (SecondsCheckInTime != 0) {
                this.ShowErrorMessage("Check In Time Should be Less than or Equal to Eight");
                document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
                return;
              }
            }
            if (HoursCheckOutTime > 8) {
              this.ShowErrorMessage("Check Out Time Should be Less than or Equal to Eight");
              document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
              return;
            }
            if (HoursCheckOutTime == 8) {
              if (MinutesCheckOutTime != 0) {
                this.ShowErrorMessage("Check Out Time Should be Less than or Equal to Eight");
                document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                return;
              }
              if (SecondsCheckOutTime != 0) {
                this.ShowErrorMessage("Check Out Time Should be Less than or Equal to Eight");
                document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
                return;
              }
            }

          }
        }
      }
      if (this.AccessInformationModel.DateToConsider == this.AccessInformationModel.CheckoutDateToConsider) {
        if (HoursCheckOutTime < HoursCheckInTime) {
          this.ShowErrorMessage("Check Out Time Should be Greater than Check In Time");
          document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
          return;
        }
        if (HoursCheckOutTime == HoursCheckInTime) {
          if (MinutesCheckOutTime < MinutesCheckInTime) {
            this.ShowErrorMessage("Check Out Time Should be Greater than Check In Time");
            document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
            return;
          }
          if (MinutesCheckInTime == MinutesCheckOutTime) {
            if (SecondsCheckOutTime <= SecondsCheckInTime) {
              this.ShowErrorMessage("Check Out Time Should be Greater than Check In Time");
              document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
              return;
            }
          }

        }

        // if (HoursCheckOutTime == 24) {
        //   if(MinutesCheckOutTime !=0){
        //     this.ShowErrorMessage("Time Format Should be 24 Hours Format");
        //     document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
        //     return;
        //   }
        //   if(SecondsCheckOutTime !=0){
        //     this.ShowErrorMessage("Time Format Should be 24 Hours Format");
        //     document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
        //     return;
        //   }
        // }
      }
      else {
        if(this.AccessInformationModel.DateToConsider==this.DateToConsider){
          if(HoursCheckInTime<8){
            this.ShowErrorMessage("Check In Time Should be Greater than or Equal to Eight");
                document.getElementById("txtEditAccessDetailsPopupCheckInTime").focus();
                return;
          }
        }
        if (!this.hasValue(this.SelectedAccesInformation)) {
          if (HoursCheckOutTime > 8) {
            this.ShowErrorMessage("Check Out Time Should be Less than or Equal to Eight");
            document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
            return;
          }
          if (HoursCheckOutTime == 8) {
            if (MinutesCheckOutTime != 0) {
              this.ShowErrorMessage("Check Out Time Should be Less than or Equal to Eight");
              document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
              return;
            }
            if (SecondsCheckOutTime != 0) {
              this.ShowErrorMessage("Check Out Time Should be Less than or Equal to Eight");
              document.getElementById("txtEditAccessDetailsPopupCheckOutTime").focus();
              return;
            }
          }
        }
      }

    }
    if (!this.hasValue(this.AccessInformationModel.DeviceGroupID)) {
      this.ShowErrorMessage('Please Select Device Group');
      const elementFound = this.divEditAccessDetailsPopup.nativeElement.querySelector('[name="' + 'divEditAccessDetailsDeviceGroup' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }
    if (this.hasValue(this.SelectedAccesInformation)) {
      if (!this.hasValue(this.AccessInformationModel.DeviceID)) {
        this.ShowErrorMessage('Please Select Device ');
        const elementFound = this.divEditAccessDetailsPopup.nativeElement.querySelector('[name="' + 'divEditAccessDetailsDevice' + '"]');
        if (this.hasValue(elementFound)) {
          elementFound.children[0].focus();
        }
        return;
      }
    }

    if (this.hasValue(this.SelectedAccesInformation)) {
      if (this.AccessInformationModel.DeviceGroupID == 1) {
        if (this.status1 == true) {
          if (this.AccessInformationModel.DeviceID != 2) {
            this.ShowErrorMessage("Please Select Main Office Enterance Out")
            return;
          }
        }
        else {
          if (this.AccessInformationModel.DeviceID != 1) {
            this.ShowErrorMessage("Please Select Main Office Entrance In")
            return;
          }

        }
      }
      if (this.AccessInformationModel.DeviceGroupID == 2) {
        if (this.status1 == true) {
          if (this.AccessInformationModel.DeviceID != 4) {
            this.ShowErrorMessage("Please Select Cafeteria Entrance Out")
            return;
          }
        }
        else {
          if (this.AccessInformationModel.DeviceID != 3) {
            this.ShowErrorMessage("Please Select Cafeteria Entrance In")
            return;
          }
        }

      }
    }
    // else {
    //   if (this.AccessInformationModel.DeviceGroupID == 1) {
    //     if (this.AccessInformationModel.DeviceID == 3 || this.AccessInformationModel.DeviceID == 4) {
    //       this.ShowErrorMessage("Please Select Mainenterance In or Mainenterance Out")
    //       return;
    //     }
    //   }
    //   if (this.AccessInformationModel.DeviceGroupID == 2) {
    //     if (this.AccessInformationModel.DeviceID == 1 || this.AccessInformationModel.DeviceID == 2) {
    //       this.ShowErrorMessage("Please Select Cafeteria In or Cafeteria Out")
    //       return;
    //     }
    //   }

    // }
    this.AccessInformationModel.DateToConsider = this.formatValue(this.AccessInformationModel.DateToConsider)
    this.AccessInformationModel.CheckoutDateToConsider = this.formatValue(this.AccessInformationModel.CheckoutDateToConsider)
    if (this.hasValue(this.SelectedAccesInformation)) {
      if (this.status1 == true) {
        this.AccessInformationModel.editAccessDetailsPopupCheckInTime = null;
      }
      else {
        this.ActionTimeId = this.SelectedAccesInformation.CheckOutTimeID
      }
      if (this.status2 == true) {
        this.AccessInformationModel.editAccessDetailsPopupCheckOutTime = null;
      }
      else {
        this.ActionTimeId = this.SelectedAccesInformation.CheckInTimeID
      }

      this.AccessInformationmodel = {
        DeviceID: this.AccessInformationModel.DeviceID,
        editAccessDetailsPopupCheckInTime: this.AccessInformationModel.editAccessDetailsPopupCheckInTime,
        editAccessDetailsPopupCheckOutTime: this.AccessInformationModel.editAccessDetailsPopupCheckOutTime,
        DateToConsider: this.AccessInformationModel.DateToConsider,
        DeviceGroupID: this.AccessInformationModel.DeviceGroupID,
        ActionDateID: this.SelectedAccesInformation.DateToConsiderID,
        DateToConsiderID: this.SelectedAccesInformation.DateToConsiderID,
        //ActionTimeId: this.ActionTimeId,
        EmployeeID: this.SelectedAccesInformation.EmployeeID
      };
      this.AccessInformationModelList.push(this.AccessInformationmodel);
    }

    else {
      if (this.hasValue(this.AccessInformationModel.editAccessDetailsPopupCheckInTime)) {
        //this.AccessDetailsDeviceList()
        this.AccessInformationmodel = {

          DeviceID: this.DevicesListCheckInTime,
          editAccessDetailsPopupCheckInTime: this.AccessInformationModel.editAccessDetailsPopupCheckInTime,
          // editAccessDetailsPopupCheckOutTime: this.AccessInformationModel.editAccessDetailsPopupCheckOutTime,
          DateToConsider: this.AccessInformationModel.DateToConsider,
          DeviceGroupID: this.AccessInformationModel.DeviceGroupID,
          ActionDateID: this.SelectedAccessInformationforAdd.DateToConsiderID,
          // ActionTimeId: this.ActionTimeId,
          EmployeeID: this.SelectedAccessInformationforAdd.EmployeeID,
          DateToConsiderID: this.SelectedAccessInformationforAdd.DateToConsiderID
        };
        this.AccessInformationModelList.push(this.AccessInformationmodel);
      }


      if (this.hasValue(this.AccessInformationModel.editAccessDetailsPopupCheckOutTime)) {
        //this.AccessDetailsDeviceList()
        this.AccessInformationmodel = {
          DeviceID: this.DevicesListCheckOutTime,
          //editAccessDetailsPopupCheckInTime: this.AccessInformationModel.editAccessDetailsPopupCheckInTime,
          editAccessDetailsPopupCheckOutTime: this.AccessInformationModel.editAccessDetailsPopupCheckOutTime,
          DateToConsider: this.AccessInformationModel.CheckoutDateToConsider,
          DeviceGroupID: this.AccessInformationModel.DeviceGroupID,
          ActionDateID: this.SelectedAccessInformationforAdd.DateToConsiderID,
          // ActionTimeId: this.ActionTimeId,
          EmployeeID: this.SelectedAccessInformationforAdd.EmployeeID,
          DateToConsiderID: this.SelectedAccessInformationforAdd.DateToConsiderID
        };
        this.AccessInformationModelList.push(this.AccessInformationmodel);
      }

    }
    //this.AccessInformationModelList.push(this.AccessInformationModel);
    if (this.AccessInformationModelList.length > 0) {
      this.UpdateAccessInformation.AccessInformationModelList = this.AccessInformationModelList
    }
    this.commonhelperobj.PostData(Config.OASAccessInformationAppnURL + 'UpdateAccessInformation', this.UpdateAccessInformation).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) {
        this.AccessInformationModelList = [];
        this.UpdateAccessInformation.AccessInformationModelList = [];
        return;
      }
      // this.ShowSuccesseMessage('Access Details updated Successfully.');
      if (this.hasValue(this.SelectedAccesInformation)) {
        this.ShowSuccesseMessage('Access Details updated Successfully.');
      }
      else {
        this.ShowSuccesseMessage('Access Details Added Successfully.');
      }
      //this.dialog.close();
      this.AccessInformationModelList = [];
      this.UpdateAccessInformation.AccessInformationModelList = [];
      this.dialog.close();
    })

  }

  private AccessDetailsDeviceGroupList() {

    var PostDataToService = {

    };
    this.commonhelperobj.PostData(Config.OASAccessInformationAppnURL + 'AccessDeviceGroupList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.editAccessDetailsDeviceGroupList = serviceResponse.AccessDeviceListModelList;
      if (this.hasValue(this.SelectedAccesInformation)) {
        // this.OnAccessDevicesList(this.AccessInformationModel.DeviceGroupID)
      }
    });

  }

  private AccessDetailsDeviceList() {

    var PostDataToService = {
      AccessDeviceGroupID: this.AccessInformationModel.DeviceGroupID
    };
    this.commonhelperobj.PostData(Config.OASAccessInformationAppnURL + 'AccessDeviceList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.editAccessDetailsDeviceList = serviceResponse.AccessDeviceListModelList;
      //this.DevicesList=serviceResponse.AccessDeviceListModelList.Access_DeviceID;
      for (let DevicesList = 0; DevicesList < this.editAccessDetailsDeviceList.length; DevicesList++) {
        if (this.AccessInformationModel.DeviceGroupID == 1) {
          if (this.editAccessDetailsDeviceList[DevicesList].AccessDeviceID == 1) {
            this.DevicesListCheckInTime = this.editAccessDetailsDeviceList[DevicesList].AccessDeviceID
          }
          else {
            this.DevicesListCheckOutTime = this.editAccessDetailsDeviceList[DevicesList].AccessDeviceID
          }
        }

        else {
          if (this.editAccessDetailsDeviceList[DevicesList].AccessDeviceID == 3) {
            this.DevicesListCheckInTime = this.editAccessDetailsDeviceList[DevicesList].AccessDeviceID
          }
          else {
            this.DevicesListCheckOutTime = this.editAccessDetailsDeviceList[DevicesList].AccessDeviceID
          }
        }
      }

    });

  }
  editAccessDetailsCancelClickEvent() {
    this.dialog.close();
  }


}
