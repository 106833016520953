import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { commonhelper } from '../common/commonhelper';
import { AgGridNg2 } from 'ag-grid-angular';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';
@Component({
  selector: 'app-practice-status-check-list-item-history-popup',
  templateUrl: './practice-status-check-list-item-history-popup.component.html',
  styleUrls: ['./practice-status-check-list-item-history-popup.component.css']
})
export class PracticeStatusCheckListItemHistoryPopupComponent extends commonhelper implements OnInit {
  private commonhelperobj: commonHelperService;
  public rowData=[];
  checkListItemInfoId:any;
  @ViewChild('practiceStatusCheckListSelectCheckListGrid') practiceStatusCheckListSelectCheckListGrid: AgGridNg2;
  columnDefs = [
    { headerName: 'Check List Item', field: 'CheckListItemName',tooltipField: 'CheckListItemName', width: 150, },
    { headerName: 'Status', field: 'CheckListItemStatusDescription',width: 150,tooltipField: 'CheckListItemStatusDescription', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Comments', field: 'CheckListItemLstestStatusComments', width: 200, tooltipField: 'CheckListItemLstestStatusComments', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Modified By', field: 'ModifiedBy', width: 150, tooltipField: 'ModifiedBy', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Modified Date', field: 'ModifiedDate', width: 150, tooltipField: 'ModifiedDate', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
  ];
   practiceStatusCheckListItemHistoryGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  @ViewChild("PracticeStatusCheckListItemHistoryPopup", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
 constructor(private http: HttpClient,private dialogService: DialogService,httpClient: HttpClient, private dialog: DialogRef,) {super();
  this.commonhelperobj = new commonHelperService(httpClient); }


  ngOnInit() {
    this.getPracticeStatusCheckListItemHistoryList();
  }
  public getPracticeStatusCheckListItemHistoryList(){

    this.commonhelperobj.PostData(Config.OASPracticeCheckListAppnURL + 'GetPracticeCheckListItemsHistoryList', {PracticeCheckListItemLinkedInfoID:this.checkListItemInfoId}).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.rowData = serviceResponse.practiceCheckListHistoryOutPutModels;
    });
  }
  practiceStatusCheckListItemHistoryCloseClickEvent() {
    this.dialog.close();
  }
}
