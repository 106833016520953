import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { commonHelperService } from '../common/common.service';
import { GridOptions } from 'ag-grid-community';
import { AgGridNg2 } from 'ag-grid-angular';
import { TaskService } from '../services/task.service';
import { DialogService, DialogRef,DialogCloseResult } from '@progress/kendo-angular-dialog';
import { Config } from '../config';
import { HttpClient } from '@angular/common/http';
import { commonhelper } from '../common/commonhelper';
import { CreateNewTemplateComponent } from '../create-new-template/create-new-template.component';

@Component({
  selector: 'app-select-template-popup',
  templateUrl: './select-template-popup.component.html',
  styleUrls: ['./select-template-popup.component.css']
})
export class SelectTemplatePopupComponent extends commonhelper implements OnInit {

 
  private commonhelperobj: commonHelperService;


  @ViewChild("SelectTemplateInfo", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  @ViewChild('SelectTemplatePopupgrid') SelectTemplatePopupgrid: AgGridNg2;
  public selectTemplatePopupListGridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public selectedRowsString= undefined;
  selectTemplatePopupSearchTemplateModuleName = "";

  
  public SearchTemplateName: any = '';
  public SelectedTemplateInfo: any = '';
 
  public overlayNoRowsTemplate;
  columnDefs = [
    { headerName: 'Select Template', field: 'TemplateName', width: 500, cellClass: 'no-border cell-wrap-text', autoHeight: true },
  ];
  rowData = [];
  //hasValue: any;
  // rowSelection = 'single';
  constructor(private taskService: TaskService, private dialogService: DialogService, private dialog: DialogRef,httpClient: HttpClient,) {
    super();
    //super();
    this.overlayNoRowsTemplate = '<span style=\'Font-size:16px;color:#00529B;background-color:#BDE5F8;margin: 50px 0px;padding: 12px;border-radius: 4px;\'>No Data Available</span>';
    this.commonhelperobj = new commonHelperService(httpClient);
  }
  ngOnInit() {
    
   this. GetTemplateDetails()
   setTimeout(function () {
    document.getElementById("selectTemplatePopupSearchTemplate").focus();
  }, 20);
   
  }

  //OKClickEvent() {
   // if (!this.hasValue(this.selectedRowsString)) {
   //   this.ShowErrorMessage('Please Select Practice Information');
   //   document.getElementById("divSearchPracticeID").focus();
  //    return false;
  //  }
  //  if (this.hasValue(this.selectedRowsString)) {
  //    this.dialog.close(this.selectedRowsString);
    //}
  //}


  onSelectionChanged(event) {
    const selectedRows = this.SelectTemplatePopupgrid.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      //debugger;
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;
      this.SelectedTemplateInfo = selectedRow.Description;

    });
    // console.log(this.selectedRowsString);
    setTimeout(function () {
      document.getElementById("selectTemplatePopupSearchTemplate").focus();
    }, 10);

  }

  OKClickEvent() {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage('Please Select Template');
      document.getElementById("divSearchUser").focus();
      return false;
    }
    if (this.hasValue(this.selectedRowsString)) {
      setTimeout(() => {
        this.dialog.close(this.selectedRowsString);
      });
    }
  }

  // ShowErrorMessage(arg0: string) {
  //   throw new Error("Method not implemented.");
  // }

  
  CancelClickEvent() {
    this.dialog.close();
  }


  async GetTemplateDetails() {

    var PostDataToService = {
      TemplateName:this.selectTemplatePopupSearchTemplateModuleName
    };

    this.commonhelperobj.PostData(Config.OASAppnURL + 'GetOASTemplateList', PostDataToService).subscribe(serviceResponse => {
      
      if (this.isError(serviceResponse)) {
        return;
      }
        if (this.hasValue(serviceResponse)) {
          this.rowData = serviceResponse;
          this.selectedRowsString = '';
        } else {
          this.rowData = [];
  
        }
    });

  }
  public selectTemplatePpopupClickEvent(){
    if(!this.hasValue(this.selectedRowsString.TemplateID)){
      this.ShowErrorMessage("Please Select Template to Delete.");
      document.getElementById("selectTemplatePopupSearchTemplate").focus();
      return false;
    }
    var PostDataToService = {
      TemplateInfoID:this.selectedRowsString.TemplateID
    };
    this.commonhelperobj.PostData(Config.OASAppnURL + 'OASTemplateDelete', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.ShowSuccesseMessage('Template Deleted Successfully.');
      this.GetTemplateDetails();
     
    });
  }
  
  selectTemplatePopupEditTempleteClickEvent(){
    if(!this.hasValue(this.selectedRowsString.TemplateID)){
      this.ShowErrorMessage("Please Select Template to Edit.");
      document.getElementById("selectTemplatePopupSearchTemplate").focus();
      return false;
    }
    const dialogRef = this.dialogService.open({
      title: 'Edit Template',
      content: CreateNewTemplateComponent,
      height: 698,
      width: 1500,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.SelectedTemplateInfo=this.selectedRowsString;
    dialogRef.result.subscribe((result) => {
    if (result instanceof DialogCloseResult) {
    } else {
        if(this.hasValue(result)){
          this.ShowSuccesseMessage("Tempalte Details Updated Successfully");
          this.GetTemplateDetails();
        }
    }
});
  }


}
