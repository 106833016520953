import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-customer-add-ticket-popup',
  templateUrl: './customer-add-ticket-popup.component.html',
  styleUrls: ['./customer-add-ticket-popup.component.css']
})
export class CustomerAddTicketPopupComponent implements OnInit {
  public customerAddTicketPopupIssuePrioritydefaultItem ={ IssuePriority: 'High', IssuePriorityID: null };
  public customerAddTicketPopupIssuePriorityList: any = [
    { IssuePriorityID: 1, IssuePriority: 'Normal' },
    { IssuePriorityID: 1, IssuePriority: 'Low' },
  ];
  constructor(private dialog: DialogRef) { }

  ngOnInit() {
  }
  customerAddTicketPopupCloseClickEvent() {
    this.dialog.close();
  }
}
