import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { SelectModulePopupComponentComponent } from "../select-module-popup-component/select-module-popup-component.component";
import {
  DialogCloseResult,
  DialogComponent,
  DialogRef,
  DialogService,
} from "@progress/kendo-angular-dialog";
import { commonhelper } from "../common/commonhelper";
import { Config } from "../config";
import { commonHelperService } from "../common/common.service";
import { HttpClient } from "@angular/common/http";
import { PraticesListComponent } from "../pratices-list/pratices-list.component";
import {
  CancelEvent,
  FileInfo,
  RemoveEvent,
  SelectEvent,
} from "@progress/kendo-angular-upload";
import { UploadState } from "../enums/upload-state.enum";
import { FileUploadProgressService } from "../services/file-upload-progress.service";
import { ResponsiblepersonUsersListComponent } from "../responsibleperson-users-list/responsibleperson-users-list.component";
import { AdditionalParticipantAndObserversPopupComponentComponent } from "../additional-participant-and-observers-popup-component/additional-participant-and-observers-popup-component.component";
import { Attachment } from "../interfaces/attachment";
import { AttachmentService } from "../services/attachment.service";
import { SaveTemplateNamePopupComponent } from "../save-template-name-popup/save-template-name-popup.component";
import { TaskEventTypeEnum } from "../enums/TaskHistoryChangeEvent.enum.";
import { IntlService } from "@progress/kendo-angular-intl";
import { TaskChangeEvent } from "../interfaces/TaskChangeEvent";
import { Router } from "@angular/router";
import { CreateTaskService } from "../shared/services/create-ticket.service";
import { TaskService } from "../services/task.service";
import { DomSanitizer } from "@angular/platform-browser";
import { EmployeeService } from "../employee.service";

@Component({
  selector: "app-create-new-customer-ticket-pop-up",
  templateUrl: "./create-new-customer-ticket-pop-up.component.html",
  styleUrls: ["./create-new-customer-ticket-pop-up.component.css"],
  providers: [FileUploadProgressService, DialogComponent],
})
export class CreateNewCustomerTicketPopUpComponent
  extends commonhelper
  implements OnInit
{
  @ViewChild("CreateTaskInfo") CreateTaskInfo: ElementRef;

  @ViewChild("TaskAddNewProjectName") TaskAddNewProjectNamefield: ElementRef;

  createNewTemplatePopupWidth = 1500;

  createNewTemplatePopupHeight = 698;

  @ViewChild("CreateTaskInfo", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  filesCount = 0;
  TaskTypeId;
  TaskAddInfoModel: any = {};
  ModuleListInfo: any = {};
  SeverityList: Array<string> = [];
  SelectedPracticeInfo: any = {};
  SelectedResponsiblePersonInfo: any = {};
  NewCurrentWorkingStatusList: any;
  PracticeEmployeesAndRolesModelList: any = [];
  SelectedAdditionalResponsiblePersonInfo: any;
  SelectedObserversInfo: any;
  SelectedObserversPersonNames: string;
  SelectedAdditionalResponsiblePersonName: string;
  selectedAdditionalResponsiblePersons: any = [];
  SelectedObservers: any = [];
  SelectedAssistantPerson: any = [];
  SelectedAssistantPersonInfo: any = {};
  SelectedAssistantPersonName: string = "";
  AttamentsContainerforImagesFromEditor = [] as any;
  IsresponsiblePersonAndFwdToSame: boolean = false;
  PopupDataFromMultiSelectUserSelectionPopup: any;
  EmployeesAndRolesModel: any = {};
  myFiles: Array<FileInfo> = [];
  fromInternalTicketNavigation = false;
  EmployeesAndRolesModelList: Array<string> = [];
  TaskUserInfo: any;
  practiceUserInfo: any;
  TaskCreatedByUserId: any;
  AttachmentIDs: Array<number> = [];
  // tslint:disable-next-line:member-ordering
  uploadSaveUrl = "saveUrl"; // should represent an actual API endpoint
  // tslint:disable-next-line:member-ordering
  uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint

  //newly added
  public dialogOpened;
  public createNewTaskButtonsShowHide;

  defaultSeverityItem = {
    IssueSeverityDesc: "-- Select --",
    IssueSeverityID: null,
  };
  defaultTaskTypeItem = {
    TaskTypeDescription: "-- Select --",
    TaskTypeId: null,
  };
  defaultCurrentTaskItem = {
    Task_Current_Working_Status: "--Task Current Working Status--",
    Task_Current_Working_Status_InfoID: null,
  };
  defaultCurrentTaskFromItem = {
    selectTaskFromListText: "- Select -",
    selectTaskFromListTextValue: 0,
  };

  taskTypeList: any;

  taskAddNewTaskSelectTaskFromList = [
    { selectTaskFromListText: "Call", selectTaskFromListTextValue: 1 },
    { selectTaskFromListText: "Email", selectTaskFromListTextValue: 2 },
    { selectTaskFromListText: "Live Chat", selectTaskFromListTextValue: 3 },
    { selectTaskFromListText: "Sales Chat", selectTaskFromListTextValue: 4 },
  ];
  datatoPopup: any;
  TaskDetailsModel: any;
  imagesattachmentslist: Attachment[];
  fullattachmentsList: Attachment[];
  pdfAttachments: Attachment[];
  AttachmentsList: Attachment[];
  imgeurl: any;
  ViewAttachmentHeader: any;
  taskImageSRC: any;
  constructor(
    private dialogService: DialogService,
    private commonhelperobj: commonHelperService,
    private httpClient: HttpClient,
    private uploadStateService: FileUploadProgressService,
    private attachmentsService: AttachmentService,
    private _dialog: DialogRef,
    private intl: IntlService,
    private taskService: TaskService,
    private domSanitizer: DomSanitizer,
    public _Router: Router,
    public _createNewTaskService: CreateTaskService,
    private employeeService: EmployeeService,
    private injector: Injector
  ) {
    super();
    this.uploadStateService = <FileUploadProgressService>(
      this.injector.get(FileUploadProgressService)
    );
    this.attachmentsService = <AttachmentService>(
      this.injector.get(AttachmentService)
    );
  }

  ngOnInit() {
    this.GetSeverityList();
    this.GetCurrentStatusList();
    this.GetTaskTypes();
    // console.log(this.datatoPopup);

    this.TaskDetailsModel = this.datatoPopup.senddatamodel;
    if (this.datatoPopup !== undefined) {
      this.TaskCreatedByUserId =
        this.datatoPopup.senddatamodel.TaskCreatedByUserId;
      this.GetTaskDetailsforEdit(this.datatoPopup.senddatamodel);
      this.GetTaskAttachmentList(this.datatoPopup.senddatamodel);
    }
    // this.TaskAddInfoModel.TaskTypeId=this.datatoPopup.
  }
  GetTaskTypes() {
    this.taskService.taskTypeList().subscribe((response) => {
      if (this.isError(response)) {
        return;
      }
      this.taskTypeList = response;
      // console.log(this.taskTypeList);
    });
  }
  GetSeverityList() {
    const PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "OASIssueSeverityListView",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.SeverityList = serviceResponse;
      });
  }

  onRequiredByDateChange(value: Date) {
    // let dateeee = this.formatValue(this.TaskAddInfoModel.RequiredByDate);

    // //return value ? ` - ${this.intl.formatDate(value, 'd')}` : '';
    // console.log(dateeee);
    // console.log(this.TaskAddInfoModel.RequiredByDate);

    if (!this.hasValue(this.TaskAddInfoModel.RequiredByDate)) {
      this.TaskAddInfoModel.RequiredByDate = "";
      this.CreateTaskInfo.nativeElement.querySelector(
        "#TaskAddNewExpectedDeliveryDate"
      ).value = "";
      //   this.ShowErrorMessage('Invalid Day, Month or Year Range Detected.');
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewExpectedDeliveryDatename" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].value = "";
      }
      // this.TaskAddInfoModel.RequiredByDate = undefined;
      return;
    }

    // if (this.hasValue(this.TaskAddInfoModel.RequiredByDate) && this.TaskAddInfoModel.RequiredByDate <= 0) {
    //   this.TaskAddInfoModel.RequiredByDate = '';
    //   return;
    // }
    var today = new Date();
    var requiredByDate = new Date(this.TaskAddInfoModel.RequiredByDate);
    const daysDifference = this.DateDiffInDays(
      today,
      this.TaskAddInfoModel.RequiredByDate
    );
    const yearsDifference = requiredByDate.getFullYear() - today.getFullYear();

    if (this.hasValue(yearsDifference) && yearsDifference > 100) {
      this.ShowErrorMessage("Invalid Day, Month or Year Range Detected.");
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewExpectedDeliveryDatename" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return;
    }
  }

  CreateTaskmoduleIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Module",
      // Show component
      content: SelectModulePopupComponentComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.TaskAddInfoModel.IssueSupportGroupDesc)) {
        } else {
          this.TaskAddInfoModel.IssueSupportGroupDesc = "";
        }
      } else {
        this.ModuleListInfo = result;
        if (this.hasValue(this.ModuleListInfo)) {
          this.TaskAddInfoModel.ModuleId =
            this.ModuleListInfo.IssueSupportGroupID;
          this.TaskAddInfoModel.ModuleName =
            this.ModuleListInfo.IssueSupportGroupDesc;
        }
      }
    });
  }
  CreateTaskRemoveModuleInfo() {
    this.TaskAddInfoModel.ModuleId = undefined;
    this.TaskAddInfoModel.ModuleName = "";
    document.getElementById("spanSelectModuleIcon").focus();
  }

  practiceDetailsClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Practice",
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.TaskAddInfoModel.PracticeName)) {
        } else {
          this.TaskAddInfoModel.PracticeName = "";
        }
      } else {
        this.SelectedPracticeInfo = result;
        if (this.hasValue(this.SelectedPracticeInfo)) {
          this.TaskAddInfoModel.PracticeName =
            this.SelectedPracticeInfo.PracticeName;
          // this.TaskAddInfoModel.EHRPracticeID =
          //   this.SelectedPracticeInfo.PracticeId;
        }
      }
    });
  }

  RemovePracticeInfo() {
    this.SelectedPracticeInfo.PracticeName = "";
    this.SelectedPracticeInfo.EHRPracticeID = undefined;
    this.TaskAddInfoModel.EHRPracticeID = undefined;
    this.TaskAddInfoModel.PracticeName = "";
    document.getElementById("spanSelectPracticeIcon").focus();
  }

  GetCurrentStatusList() {
    var PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "NewCurrentWorkingStatusList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.NewCurrentWorkingStatusList =
          serviceResponse.newCurrentWorkingStatusListModelList;
      });
  }

  responsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Responsible Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedResponsiblePersonInfo)) {
        } else {
          this.SelectedResponsiblePersonInfo = "";
        }
      } else {
        this.SelectedResponsiblePersonInfo = result;
        if (this.hasValue(this.SelectedResponsiblePersonInfo)) {
          this.TaskAddInfoModel.SelectedResponsiblePerson =
            this.SelectedResponsiblePersonInfo.EmployeeId;
          this.SelectedResponsiblePersonInfo.EmployeeId =
            this.SelectedResponsiblePersonInfo.EmployeeId;
          this.SelectedResponsiblePersonInfo.EmployeeFullName =
            this.SelectedResponsiblePersonInfo.EmployeeFullName;
        }
      }
    });
  }

  RemoveResponsiblePersonInfo() {
    this.TaskAddInfoModel.SelectedResponsiblePerson = undefined;
    this.SelectedResponsiblePersonInfo.EmployeeId = undefined;
    this.SelectedResponsiblePersonInfo.EmployeeFullName = "";
  }

  AdditionalResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    //dialogRef.content.instance.IsFromForwardToPopup = true;
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData =
      this.selectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData =
      this.SelectedObservers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData =
      this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
        } else {
          this.SelectedAdditionalResponsiblePersonInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        //********assigning addl responsible resons info************
        this.SelectedAdditionalResponsiblePersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.selectedAdditionalResponsiblePersons =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );
          this.SelectedAdditionalResponsiblePersonName =
            this.SelectedAdditionalResponsiblePersonName.substring(
              0,
              this.SelectedAdditionalResponsiblePersonName.length - 2
            );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        //*****assigning observers data****************
        this.SelectedObserversInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.SelectedObservers =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedObserversPersonNames =
            this.SelectedObserversPersonNames.substring(
              0,
              this.SelectedObserversPersonNames.length - 2
            );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        this.SelectedAssistantPersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        this.SelectedAssistantPerson =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedAssistantPersonName =
            this.SelectedAssistantPersonName.substring(
              0,
              this.SelectedAssistantPersonName.length - 2
            );
        } else {
          this.SelectedAssistantPersonName = "";
        }
      }
    });
  }

  RemoveAdditionalResponsiblePersonInfo() {
    this.SelectedAdditionalResponsiblePersonName = "";
    this.selectedAdditionalResponsiblePersons = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }

  ObserversIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    // dialogRef.content.instance.IsFromForwardToPopup = true;
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData =
      this.selectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData =
      this.SelectedObservers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData =
      this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedObserversInfo)) {
        } else {
          this.SelectedObserversInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        //********assigning addl responsible resons info************
        this.SelectedAdditionalResponsiblePersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.selectedAdditionalResponsiblePersons =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );
          this.SelectedAdditionalResponsiblePersonName =
            this.SelectedAdditionalResponsiblePersonName.substring(
              0,
              this.SelectedAdditionalResponsiblePersonName.length - 2
            );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        //*****assigning observers data****************
        this.SelectedObserversInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.SelectedObservers =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedObserversPersonNames =
            this.SelectedObserversPersonNames.substring(
              0,
              this.SelectedObserversPersonNames.length - 2
            );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        this.SelectedAssistantPersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        this.SelectedAssistantPerson =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedAssistantPersonName =
            this.SelectedAssistantPersonName.substring(
              0,
              this.SelectedAssistantPersonName.length - 2
            );
        } else {
          this.SelectedAssistantPersonName = "";
        }
      }
    });
  }

  RemoveObserversIconClickEvent() {
    this.SelectedObserversPersonNames = "";
    this.SelectedObservers = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }



  // Save Task Info
  async SaveTaskDetails(isTemplate) {
    // alert(this.TaskAddInfoModel.SelectedTaskBody.value);
    // console.log(this.TaskAddInfoModel);
    this.TaskAddInfoModel.EHRPracticeID=this.TaskAddInfoModel.EHRPracticeID;
    if (!this.hasValue(this.TaskAddInfoModel.ModuleId)) {
      this.ShowErrorMessage("Please Select Module");
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewModuleName" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    } else {
      this.TaskAddInfoModel.IssueSupportGroupID =
        this.TaskAddInfoModel.ModuleId;
    }

    if (!this.hasValue(this.TaskAddInfoModel.IssueSubject)) {
      this.ShowErrorMessage("Please Enter Task Subject");
      this.CreateTaskInfo.nativeElement
        .querySelector("#TaskAddNewSubject")
        .focus();
      return;
    }

    if (!this.hasValue(this.TaskAddInfoModel.SelectedTaskBody)) {
      this.ShowErrorMessage("Please Enter Task Details");
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewTaskDetails" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[1].children[0].focus();
      }
      return;
    }

    if (!this.hasValue(this.TaskAddInfoModel.SelectedResponsiblePerson)) {
      this.ShowErrorMessage("Please Select Responsible Person.");
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewResponsiblePerson" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }

    const daysDifference = this.DateDiffInDays(
      new Date(),
      this.TaskAddInfoModel.RequiredByDate
    );

    if (this.hasValue(daysDifference) && daysDifference > 0) {
      this.ShowErrorMessage(
        "Required By Date Should be Greater than or Equal to Current Date."
      );
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewExpectedDeliveryDatename" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return;
    }

    if (!this.uploadStateService.isValidState()) {
      this.ShowErrorMessage("Please Wait till the files are uploaded");
      return;
    }

    let attachmentContainer: Array<Attachment> = [];

    //   await this.attachmentsService.processUploadAttachments(this.myFiles);

    // if (
    //   this.attachmentsService.duplicatesExists(
    //     attachmentContainer as Array<Attachment>
    //   )
    // ) {
    //   this.ShowErrorMessage(
    //     "Duplicates Exists in Uploaded Attachments Please Try Again."
    //   );
    //   return;
    // }

    // if (
    //   !this.attachmentsService.uploadedAttachmentsCompatable(
    //     attachmentContainer
    //   )
    // ) {
    //   this.ShowErrorMessage(
    //     "Uploaded attachments contain files other than JPG,JPEG,PNG,DocX,Doc,XLSX,XLs and PDF files."
    //   );
    //   this.myFiles = [];
    //   return;
    // }

    if (
      this.hasValue(this.SelectedAssistantPerson) &&
      this.SelectedAssistantPerson.length > 0
    ) {
      this.SelectedAssistantPerson.forEach((selectedRow, index) => {
        if (
          this.SelectedResponsiblePersonInfo.EmployeeId ==
          selectedRow.EmployeeId
        ) {
          this.IsresponsiblePersonAndFwdToSame = true;
        }
      });
    }

    // Promise.all(promises).then(() => {
    if (this.hasValue(this.TaskAddInfoModel.SelectedTaskBody)) {
      const body: Attachment = {
        AttachmentBase64Format: window.btoa(
          unescape(encodeURIComponent(this.TaskAddInfoModel.SelectedTaskBody))
        ),
        AttachmentType: 1,
      };

      attachmentContainer.push(body);
    }

    if (
      this.hasValue(this.selectedAdditionalResponsiblePersons) &&
      this.selectedAdditionalResponsiblePersons.length > 0
    ) {
      this.selectedAdditionalResponsiblePersons.forEach((element) => {
        this.EmployeesAndRolesModel = {
          EmployeeId: element.EmployeeId,
          RoleID: 4,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }
    if (this.PracticeEmployeesAndRolesModelList.length > 0)
      this.TaskAddInfoModel.PracticeEmployeesAndRolesModelList =
        this.PracticeEmployeesAndRolesModelList;

    if (this.AttachmentIDs.length > 0) {
      this.TaskAddInfoModel.AttachmentIDs = this.AttachmentIDs;
    }

    this.TaskAddInfoModel.CreatedByID = this.TaskCreatedByUserId;

    if (
      this.hasValue(this.SelectedObservers) &&
      this.SelectedObservers.length > 0
    ) {
      this.SelectedObservers.forEach((element) => {
        this.EmployeesAndRolesModel = {
          EmployeeId: element.EmployeeId,
          RoleID: 3,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }

    // console.log(this.TaskAddInfoModel);
    if (this.hasValue(this.TaskAddInfoModel.SelectedResponsiblePerson)) {
      this.EmployeesAndRolesModel = {
        EmployeeId: this.TaskAddInfoModel.SelectedResponsiblePerson,
        RoleID: 2,
      };
      this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
    }

    if (
      this.hasValue(this.SelectedAssistantPerson) &&
      this.SelectedAssistantPerson.length > 0
    ) {
      this.SelectedAssistantPerson.forEach((Assistantselement) => {
        this.EmployeesAndRolesModel = {
          EmployeeId: Assistantselement.EmployeeId,
          RoleID: 7,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }

    if (this.EmployeesAndRolesModelList.length > 0) {
      this.TaskAddInfoModel.EmployeesAndRolesModelList =
        this.EmployeesAndRolesModelList;
    }

    if (attachmentContainer.length > 0) {
      this.TaskAddInfoModel.AttachmentsModelList = attachmentContainer;
    }

    this.TaskAddInfoModel.taskChangeEvents = [
      {
        Description: "Task Created",
        EventId: TaskEventTypeEnum.TaskCreated,
        TaskInfoId: 0,
      },
    ] as TaskChangeEvent[];
    if (
      this.hasValue(this.TaskAddInfoModel.TaskTypeId) &&
      this.hasValue(this.TaskAddInfoModel.TaskTypeId.TaskTypeId)
    ) {
      this.TaskAddInfoModel.TaskTypeId =
        this.TaskAddInfoModel.TaskTypeId.TaskTypeId;
    }
    // else {
    //   this.TaskAddInfoModel.TaskTypeId = undefined;
    // }
    this.TaskAddInfoModel.IsPublicTask = false;
    this.TaskAddInfoModel.IsTaskCreatedfromEHR = false;
    if (this.hasValue(this.TaskAddInfoModel.RequiredByDate)) {
      this.TaskAddInfoModel.RequiredByDate = this.formatValue(
        this.TaskAddInfoModel.RequiredByDate
      );
    }

    if (
      this.hasValue(this.TaskAddInfoModel.Task_Current_Working_Status_InfoID) &&
      this.hasValue(
        this.TaskAddInfoModel.Task_Current_Working_Status_InfoID
          .Task_Current_Working_Status_InfoID
      )
    ) {
      this.TaskAddInfoModel.Task_Current_Working_Status_InfoID =
        this.TaskAddInfoModel.Task_Current_Working_Status_InfoID.Task_Current_Working_Status_InfoID;
    }

    if (
      this.hasValue(this.TaskAddInfoModel.TaskCamefromType) &&
      this.hasValue(
        this.TaskAddInfoModel.TaskCamefromType.selectTaskFromListTextValue
      )
    ) {
      this.TaskAddInfoModel.TaskCamefromType =
        this.TaskAddInfoModel.TaskCamefromType.selectTaskFromListTextValue;
    }
    // 1 -> means- saving Internal Issues
    this.TaskAddInfoModel.IsTaskFrom = 2;

    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "SaveNewCustomerTicketDetails",
        this.TaskAddInfoModel
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          this.EmployeesAndRolesModelList = [];
          attachmentContainer = [];
          return;
        }
        this.sendInternalTicketNotificationtoEmployees(
          serviceResponse.ResponseID,
        );
        this.ShowSuccesseMessage("Task Details Saved Successfully.");
        this._dialog.close(serviceResponse)
        const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
          '[name="' + "TaskAddNewProjectName" + '"]'
        );
        if (this.hasValue(elementFound)) {
          elementFound.children[0].focus();
        }
        this.EmployeesAndRolesModelList = [];
        attachmentContainer = [];
      });

    // });
    // }, 100);
  }

  CreateTaskCancelClick(message: string | undefined) {
    if (this.fromInternalTicketNavigation) {
      this._dialog.close(message);
    } else {
      this._dialog.close(message);
    }
  }

  formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "d")}` : "";
  }

  sendInternalTicketNotificationtoEmployees(TaskNumber): void {
    this._createNewTaskService
      .sendInternalTicketNotificationtoEmployees({
        EmployeeIds: [this.TaskAddInfoModel.SelectedResponsiblePerson],
        TaskNumber: TaskNumber,
        IssueSeverityID: this.TaskAddInfoModel.IssueSeverityID,

      })
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
      });
  }


  GetTaskDetailsforEdit(data) {
    var postData = {
      TaskInfoID: data.TaskInfoID,
    };
    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "GetTaskDetailsforEdit",
        postData
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        if (this.hasValue(serviceResponse)) {
          this.TaskAddInfoModel.PracticeName = serviceResponse.PracticeName;
          this.TaskAddInfoModel.PracticeId = serviceResponse.PracticeID;
          this.TaskAddInfoModel.IssueSubject = serviceResponse.Subject;
          // this.TaskTypeId = serviceResponse.TaskTypeID;
          this.TaskAddInfoModel.TaskTypeId = serviceResponse.TaskTypeID;
          this.TaskAddInfoModel.IssueSeverityID = serviceResponse.SeverityID;
          this.TaskAddInfoModel.ModuleName = serviceResponse.SupportGroupName;
          this.TaskAddInfoModel.ModuleId = serviceResponse.SupportGroupID;
          this.TaskUserInfo = serviceResponse.UsersInfo;
          this.practiceUserInfo = serviceResponse.PracticeUsersInfo;
          // console.log(serviceResponse);

          const {
            responsiblePerson,
            taskObservers,
            taskParticipants,
            taskAssistant,
          } = this.employeeService.parseEmployeeRoles(this.TaskUserInfo + ",");

          const { Observers, CreatedBy } =
            this.employeeService.parsePracticeEmployeeRoles(
              this.practiceUserInfo + ","
            );

          // CreatedBy.forEach((ele) => {
          //   this.PracticeEmployeesAndRolesModelList.push({
          //     EHRPracticeUserID: ele.UserId,
          //     RoleID: ele.RoleID,
          //   });
          // });
          Observers.forEach((ele) => {
            this.PracticeEmployeesAndRolesModelList.push({
              EHRPracticeUserID: ele.UserId,
              RoleID: ele.RoleID,
            });
          });
          // console.log(this.PracticeEmployeesAndRolesModelList);
          this.SelectedResponsiblePersonInfo.EmployeeFullName =
            responsiblePerson.EmployeeFullName;
          this.TaskAddInfoModel.SelectedResponsiblePerson =
            responsiblePerson.EmployeeId;
          this.SelectedResponsiblePersonInfo.EmployeeId =
            responsiblePerson.EmployeeId;

          this.SelectedAdditionalResponsiblePersonInfo = taskParticipants;
          this.selectedAdditionalResponsiblePersons = taskParticipants;
          if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
            this.SelectedAdditionalResponsiblePersonName = "";
            this.SelectedAdditionalResponsiblePersonInfo.forEach(
              (selectedRow, index) => {
                this.SelectedAdditionalResponsiblePersonName +=
                  selectedRow.EmployeeFullName + ", ";
              }
            );
            this.SelectedAdditionalResponsiblePersonName =
              this.SelectedAdditionalResponsiblePersonName.substring(
                0,
                this.SelectedAdditionalResponsiblePersonName.length - 2
              );
            // console.log(
            //   responsiblePerson,
            //   taskObservers,
            //   taskParticipants,
            //   taskAssistant
            // );
            // this.TaskInformationtostatemaintain(serviceResponse);

            // this.commonhelperobj
            //   .GetStringBytesFromSignedURL(
            //     serviceResponse.AttachmentURL,
            //     "text/html"
            //   )
            //   .subscribe(async (successdata2) => {
            //     // MainBodyAttachmentsList contains all the html data which came from http request

            //     // this.taskDescription = successdata2;

            //     let MainBodyAttachmentsList = "<div>" + successdata2 + "</div>";

            //     // unused object
            //     let MainBodyImagesAttachmentsList = [];

            //     // this array is used to hold attachments of the main body
            //     let imagesattachmentslist = [];

            //     // array to hold main body's attachment
            //     let AttachmentsList = [];

            //     // from MainBodyAttachmentsList which contains html data convert it to jquery object
            //     const GetMainBodyAttachmentsList = $(MainBodyAttachmentsList);

            //     // collectionofAttachments holds all the images in html
            //     const collectionofAttachments =
            //       GetMainBodyAttachmentsList.find("img");

            //     // as images are in collectionofAttachments object remove images from original html
            //     //GetMainBodyAttachmentsList.find('img').remove();

            //     // from all images get count of images
            //     const collectionofimgsLength2 = collectionofAttachments.length;
            //     const elementfieldmainbody = {
            //       Imgaetag: null,
            //       attachmentType: null,
            //       AttachmentName: "",
            //     };

            //     // mainbodyinfo holds html except images
            //     let mainbodyinfo = GetMainBodyAttachmentsList.html();

            //     mainbodyinfo = mainbodyinfo
            //       .replace("SafeValue must use [property]=binding:", "")
            //       .replace("(see http://g.co/ng/security#xss)", "");
            //     // this global object is used to display html of task body
            //     let TaskDescription = this.sanitizedTaskDescription(
            //       mainbodyinfo
            //     ) as any;

            //     this.TaskAddInfoModel.SelectedTaskBody = successdata2;
            //     // this.getAttachments().then(() => this.getCommentsAttachments());
            //   });
          } else {
            this.SelectedAdditionalResponsiblePersonName = "";
          }
          //*****assigning observers data****************
          this.SelectedObserversInfo = taskObservers;
          this.SelectedObservers = taskObservers;
          if (this.hasValue(this.SelectedObserversInfo)) {
            this.SelectedObserversPersonNames = "";
            this.SelectedObserversInfo.forEach((selectedRow, index) => {
              this.SelectedObserversPersonNames +=
                selectedRow.EmployeeFullName + ", ";
            });
            this.SelectedObserversPersonNames =
              this.SelectedObserversPersonNames.substring(
                0,
                this.SelectedObserversPersonNames.length - 2
              );
          } else {
            this.SelectedObserversPersonNames = "";
          }
          // console.log(this.TaskAddInfoModel);
          //this.initializeTaskCategoriesCount();
        }
      });
  }

  private sanitizedTaskDescription(str: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(str);
  }

  GetTaskAttachmentList(data) {
    const PostDataToService = {
      TaskInfoID: data.TaskInfoID,
    };

    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "GetTaskAttachmentDetailsForEdit",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.fullattachmentsList = serviceResponse.AttachmentsDetailsList;

        let taskBodyList = this.fullattachmentsList.filter(
          (item) => item.AttachmentType === 1
        );

        this.commonhelperobj
          .GetStringBytesFromSignedURL(
            taskBodyList[0].AttachmentURL,
            "text/html"
          )
          .subscribe(async (successdata2) => {
            this.TaskAddInfoModel.SelectedTaskBody = successdata2;

            this.getAttachments();
          });
      });
  }
  async getAttachments() {
    let abc = [];
    // assigning image attachments
    this.imagesattachmentslist =
      this.attachmentsService.imageNewTicketAttachments(
        this.fullattachmentsList
      );

    this.pdfAttachments = this.attachmentsService.pdfNewticketAttachments(
      this.fullattachmentsList
    );

    if (this.imagesattachmentslist.length > 0)
      this.imagesattachmentslist.forEach((x) =>
        this.AttachmentIDs.push(x.AttachmentID)
      );

    if (this.pdfAttachments.length > 0)
      this.pdfAttachments.forEach((x) =>
        this.AttachmentIDs.push(x.AttachmentID)
      );

    // console.log(this.AttachmentIDs, "length");
    if (this.imagesattachmentslist.length > 0) {
      let newAttchmentsList = [];
      for (let index = 0; index < this.imagesattachmentslist.length; index++) {
        const attachmentType = this.imagesattachmentslist[index].AttachmentType;

        const sourceUrl = this.imagesattachmentslist[index].AttachmentURL;
        let AttachmentName = "";
        if (this.hasValue(this.imagesattachmentslist[index].AttachmentName)) {
          AttachmentName = this.imagesattachmentslist[index].AttachmentName;
        } else {
          AttachmentName = "";
        }

        const source = await this.attachmentsService.sourceFromUrl(sourceUrl);
        const element = {
          Imgaetag: "data:image/png;base64" + "," + source,
          attachmentType: attachmentType,
          AttachmentName: AttachmentName,
        };
        newAttchmentsList.push(element);
        this.AttachmentsList = newAttchmentsList;

        // console.log(this.AttachmentsList, "Attachement");
      }
    }
  }
  open(component, imgeurl, ImageInfo) {
    if (ImageInfo.attachmentType === 10) {
      const downloadLink = document.createElement("a");
      downloadLink.href = imgeurl;
      downloadLink.download = ImageInfo.AttachmentName;
      downloadLink.click();
    } else {
      this[component + "Opened"] = true;
      //console.log(imgeurl);
      let AttachmentName = "";
      if (this.hasValue(ImageInfo) && this.hasValue(ImageInfo.AttachmentName)) {
        AttachmentName = ImageInfo.AttachmentName;
      } else {
        AttachmentName = "View Attachment";
      }
      this.imgeurl = imgeurl;
      this.TasksLoadImageURL(this.imgeurl, AttachmentName);
    }
  }

  TasksLoadImageURL(imageURL, AttachmentName) {
    // let elemtInstance: any = this.ViewFullTaskInfo.nativeElement.querySelector('#divTaskAttachment');
    // elemtInstance = $(elemtInstance);
    // elemtInstance.append('<img src=\'' + imageURL + '\' alt=\'Task Attachment\' id=\'TaskAttachment\' />');
    this.ViewAttachmentHeader = AttachmentName;
    this.taskImageSRC = imageURL;
  }

  close(component) {
    this[component + "Opened"] = false;
  }

  // getCommentsAttachments() {
  //   return this.getCommentsText()
  //     .then(() => this.renderCommentAttachments())
  //     .catch(() => console.log("No Comments"));
  // }
  //#region  " Upload Docs"
  selectEventHandler(event: SelectEvent): void {
    this.uploadStateService.setUploadState(UploadState.uploadInProgress);
    this.filesCount += event.files.length;
  }

  cancelEventHandler(event: CancelEvent) {
    this.filesCount--;
    if (this.filesCount === 0) {
      this.uploadStateService.setUploadState(UploadState.removedAllFiles);
    }
  }

  removeEventHandler(event: RemoveEvent) {
    this.filesCount--;
    if (this.filesCount === 0) {
      this.uploadStateService.setUploadState(UploadState.removedAllFiles);
    }
  }

  completeEventHandler(event: Event) {
    this.uploadStateService.setUploadState(UploadState.uploadComplete);
  }
  //#endregion
}
