import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { GridApi, GridSizeChangedEvent } from 'ag-grid-community';
import { commonhelper } from '../common/commonhelper';
import { DeleteSelectedPersonRendererComponent } from '../delete-selected-person-renderer/delete-selected-person-renderer.component';
import { MoveIconCellRendererComponent } from '../move-icon-cell-renderer/move-icon-cell-renderer.component';
import { CloneService } from '../shared/services/clone.service';
import { EmployeesListService } from '../shared/services/employees-list.service';
import { IEmployee } from '../shared/types/employee-mode';

@Component({
  selector: 'app-employees-customization',
  templateUrl: './employees-customization.component.html',
  styleUrls: ['./employees-customization.component.css']
})

export class EmployeesCustomizationComponent extends commonhelper implements OnInit {
  @ViewChild('employeeSearch') _employeeSearch: ElementRef;
  private selectEmployeGrid: GridApi;
  private selectedEmployeerid: GridApi;
  
  rowData: IEmployee[] = [];
  SelectedEmployeesRowData: IEmployee[] = [];
  StateMaintainUsersData: IEmployee[] = [];
  EmployeeName: string;
  selectedEmployees = new Set();
  constructor(
    private readonly dialog: DialogRef,
    private readonly _employeesListService: EmployeesListService,
    private readonly clonerService: CloneService) {
    super();
  };
  ngOnInit(): void {
    this.variablesInitialize();
    this.getEmployeesList();
  }
  variablesInitialize(): void {
    this.SelectedEmployeesRowData = this.clonerService.deepClone(this.StateMaintainUsersData);
  }
  onemployeesCustomizationGridSizeChanged(params: GridSizeChangedEvent): void {
    params.api.sizeColumnsToFit();
  }
  onSelectEmployeeGridReady(params) {
    this.selectEmployeGrid = params.api; // To access the grids API
  }
  onSelectedEmployeesGridReady(params) {
    this.selectedEmployeerid = params.api; // To access the grids API
  }
  context = {
    componentParent: this
  }
  defaultColDef = {
    suppressMenu: true,
    lockPosition: true,
  }
  SelectUsercolumnDefs = [
    {
      headerName: 'Employee(s)', field: 'EmployeeFullName',
      headerTooltip: 'Employee(s)',
      width: 200, tooltipField: 'EmployeeFullName',
      cellClass: 'no-border cell-wrap-text',
      autoHeight: true, cellStyle: { textAlign: 'left' },
    },

    {
      width: 40,
      headerComponent: "MoveIconCellRendererComponent",
      cellRenderer: "MoveIconCellRendererComponent",
      colId: "params",
    }

  ];
  SelectedUsercolumnDefs = [
    {
      headerName: 'Selected Employee(s)',
      field: 'EmployeeFullName',
      headerTooltip: 'Selected Employee(s)',
      width: 180, tooltipField: 'EmployeeFullName',
      cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' }
    },
    {
      width: 40,
      headerComponent: "DeleteSelectedPersonRendererComponent",
      cellRenderer: "DeleteSelectedPersonRendererComponent",
      colId: "params",
    }

  ];
  frameworkComponents = {
    DeleteSelectedPersonRendererComponent: DeleteSelectedPersonRendererComponent,
    MoveIconCellRendererComponent: MoveIconCellRendererComponent
  };
  getEmployeesList(): void {
    this._employeesListService.OrganizationEmployeesList({
      EmployeeFullName: this.EmployeeName
    }).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.filterNonSelectedEmployees(serviceResponse.EmployeeList);

    });
  }
  getFilteredEmployeesList(): void {
    this.selectEmployeGrid.setQuickFilter(this.EmployeeName);
    this._employeeSearch.nativeElement.focus();
  }
  filterNonSelectedEmployees(EmployeesList: IEmployee[]): void {
    this.assignSelectedEmployeestoaSet();
    this.assignNonSelectedEmployeestoGrid(EmployeesList);
  }

  assignSelectedEmployeestoaSet(): void {
    this.selectedEmployees = new Set();
    this.SelectedEmployeesRowData.forEach(element => {
      if (element.EmployeeId) {
        this.selectedEmployees.add(parseInt(element.EmployeeId))
      }
    });
  }
  assignNonSelectedEmployeestoGrid(EmployeesList: IEmployee[]): void {
    this.rowData = EmployeesList.filter(element => { return !this.selectedEmployees.has(parseInt(element.EmployeeId)) })
  }
  CancelClickEvent(): void {
    this.dialog.close();
  }
  DeleteSelectedAddlResponsiblePerson(params: any): void {
    if (params.data) {
      this.perforMoveandRemoveOperations(params, "SelectedEmployeesRowData", "rowData")
    }
    else {
      this.rowData.push.apply(this.rowData, this.SelectedEmployeesRowData);
      this.SelectedEmployeesRowData = [];
    }
    this.assignDatatoGrids();
  }
  MoveSelectedAddlResponsiblePerson(params: any): void {
    if (params.data) {
      this.perforMoveandRemoveOperations(params, "rowData", "SelectedEmployeesRowData")
    }
    else {
      this.selectEmployeGrid.forEachNodeAfterFilter(node => this.SelectedEmployeesRowData.push(node.data))
      this.filterNonSelectedEmployees(this.rowData);
    }
    this.assignDatatoGrids();
  }
  perforMoveandRemoveOperations(params: { data: IEmployee; rowIndex: number; }, fromRowData: string, toRowData: string): void {
    this[fromRowData] = this[fromRowData].filter(item => item.EmployeeId != params.data.EmployeeId)
    this[toRowData].push(params.data)
  }
  assignDatatoGrids(): void {
    this.selectEmployeGrid.setRowData(this.rowData);
    this.selectedEmployeerid.setRowData(this.SelectedEmployeesRowData);
  }
  OKClickEvent(): void {
    if (this.checkWetherEmployeeisSelectedornot()) return;
    this.dialog.close(this.SelectedEmployeesRowData)
  }
  checkWetherEmployeeisSelectedornot(): boolean {
    if (this.SelectedEmployeesRowData.length <= 0) {
      this.ShowErrorMessage('Please Select Employee(s).');
      return true;
    }
    return false;
  }
}
