import { Injectable } from '@angular/core';


@Injectable()
export class ByMajorTopicsService {
    constructor() { }
    ExcelColumnsInfo = [
        { header: "Task #", key: "TaskNumber", width: 10 },
        { header: "Practice Name", key: "PracticeName", width: 35 },
        { header: "Task Type", key: "TaskType", width: 35 },
        { header: "Task Subject", key: "IssueSubject", width: 60 },
        { header: "Task Status", key: "TaskLatestStatusName", width: 22 },
        { header: "Priority", key: "SeverityDesc", width: 15 },
        { header: "Task Current Working Status", key: "TaskCurrentWorkingStatus", width:47 },
        { header: "Responsible Person", key: "ResponsbilerPerson", width: 31 },
        { header: "Created By", key: "CreatedBy", width: 31 },
        { header: "Major Topics", key: "SupportGroup", width: 20 }
    ]

}

