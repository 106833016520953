import { Injectable } from '@angular/core';


@Injectable()
export class ViewTaskInformationService {
    constructor() { }
    ExcelColumnsInfo = [
        { header: "Task #", key: "TaskNumber", width: 10 },
        { header: "Practice", key: "PracticeName", width: 30 },
        { header: "Task Type", key: "TaskType", width: 30 },
        { header: "Subject", key: "IssueSubject", width: 60 },
        { header: "Priority", key: "SeverityDesc", width: 10 },
        { header: "Task Status", key: "TaskLatestStatusName", width: 17 },
        { header: "Responsible Person", key: "ResponsbilerPerson", width: 30 },
        { header: "Created Date", key: "IssueCreatedOn", width: 22},
        { header: "Created By", key: "CreatedBy", width: 30 },
        // { header: "Forwarded to", key: "ForwardedTo", width: 25 }
    ]

}

