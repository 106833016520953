import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-add-practice-check-list-item-comments-popup',
  templateUrl: './add-practice-check-list-item-comments-popup.component.html',
  styleUrls: ['./add-practice-check-list-item-comments-popup.component.css']
})
export class AddPracticeCheckListItemCommentsPopupComponent extends commonhelper implements OnInit {
  private commonhelperobj: commonHelperService;
  @ViewChild('AddPracticeCheckListItemCommentsPopup') AddPracticeCheckListItemCommentsPopup: ElementRef;
  constructor(httpClient: HttpClient, private dialogService: DialogService, private dialog: DialogRef,) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }
  public addPracticeCheckListModel: any = {};
  checkListItemInfoId: any;
  PracticeID: any;
  ngOnInit() {
    setTimeout(() => {
      document.getElementById("taAddPracticeCheckListItemCommentsPopupComments").focus();
    }, 100);

  }
  addPracticeCheckListItemCommentsPopupCancelClickEvent() {
    this.dialog.close();
  }
  addPracticeCheckListItemCommentsPopupSaveClickEvent() {
    if (this.checkMandatoryValidations()) return;
    var PostDataToService = this.addPracticeCheckListModel;
    PostDataToService.PracticeID = this.PracticeID;
    PostDataToService.PracticeCheckListItemLinkedInfoIDs = this.checkListItemInfoId;
    this.commonhelperobj.PostData(Config.OASPracticeCheckListAppnURL + 'InsertorUpdatePracticeStatusCommentsCheckListItems', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.dialog.close("Success");
    });
  }
  checkMandatoryValidations() {
    if (!this.hasValue(this.addPracticeCheckListModel.CheckListItemLstestStatusComments)||!this.hasValue(this.addPracticeCheckListModel.CheckListItemLstestStatusComments.trimStart())) {
      this.ShowErrorMessage("Please Enter Comments")
      document.getElementById("taAddPracticeCheckListItemCommentsPopupComments").focus();
      return true;
    }
  }
}
