import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { commonHelperService } from "../common/common.service";
import { commonhelper } from "../common/commonhelper";
import { Config } from "../config";
import { HttpClient } from "@angular/common/http";
import {
  DialogService,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import { PraticesListComponent } from "../pratices-list/pratices-list.component";
import { ResponsiblepersonUsersListComponent } from "../responsibleperson-users-list/responsibleperson-users-list.component";
import { ActivatedRoute, Router } from "@angular/router";
import { AgGridNg2 } from "ag-grid-angular";
import { GridSizeChangedEvent } from "ag-grid-community";
import { ByMajorTopicsService } from "./by-major-topics.service";
import { ExcelService } from "../common/excel.service";
@Component({
  selector: "app-by-major-topics",
  templateUrl: "./by-major-topics.component.html",
  styleUrls: ["./by-major-topics.component.css"],
})
export class ByMajorTopicsComponent extends commonhelper implements OnInit {
  @ViewChild("byMajorTopicsGrid") byMajorTopicsGrid: AgGridNg2;
  public supportGroupsList: Array<string> = [];
  private commonhelperobj: commonHelperService;
  FromRecords: any;
  PrevLabelCount: any;
  NextLabelCount: any;
  RecordsPerPage: any;
  selectedTask: any;
  TotalCountValue: boolean = true;
  TotalCountValueIcon: boolean = false;
  TotalLabelCount: any;
  TotalLabelCountValue: any;
  fromrecordsfromcount: any;
  prevnextbuttons: boolean = true;
  NextButtonDisabled: boolean = false;
  PreviousButtondisabled: boolean = false;
  public byCustomerComponent: any = {};
  public rowData = [];
  public divIssueSupportGroupID: HTMLElement;
  public bymajorTopicsModel: any = {};
  SearchStatusIDs: any = [];
  public practiceInformation: any = {};
  public responsiblePersonInfo: any = {};
  public byMajorTopicsSearchTaskNumber: any;
  public byMajorTopicsSearchTaskSubject: any;
  public implementedbyModel: any = {};
  public viewTaskInfoSelectStatusDropDown: any = [];
  @ViewChild("ByMajorTopics", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  columnDefs = [
    {
      headerName: "Task #",
      field: "TaskNumber",
      width: 90,
      tooltipField: "TaskNumber",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Practice Name",
      field: "PracticeName",
      width: 150,
      tooltipField: "PracticeName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Task Type",
      field: "TaskType",
      width: 110,
      tooltipField: "TaskType",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Task Subject",
      field: "IssueSubject",
      width: 260,
      tooltipField: "IssueSubject",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Task Status",
      field: "TaskLatestStatusName",
      width: 100,
      tooltipField: "TaskLatestStatusName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Priority",
      field: "SeverityDesc",
      width: 80,
      tooltipField: "SeverityDesc",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Task Current Working Status",
      field: "TaskCurrentWorkingStatus",
      width: 200,
      tooltipField: "TaskCurrentWorkingStatus",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Responsible Person",
      field: "ResponsiblePerson",
      width: 150,
      tooltipField: "ResponsiblePerson",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Created By",
      field: "CreatedBy",
      width: 150,
      tooltipField: "CreatedBy",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Major Topics",
      field: "SupportGroup",
      width: 150,
      tooltipField: "SupportGroup",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
  ];
  onByMajorTopicsGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  constructor(
    private http: HttpClient,
    private readonly _excelService: ExcelService,
    private readonly _byMajorTopicsService: ByMajorTopicsService,
    httpClient: HttpClient,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private _router: Router
  ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }
  public defaultSupportGroupItem = {
    IssueSupportGroupDesc: "-- Select --",
    IssueSupportGroupID: null,
  };
  ngOnInit() {
    this.route.params.subscribe((params) => {
      const data = this.getCommonData(params.info);
      if (
        this.hasValue(data) &&
        this.hasValue(data.previouslySelectedSearchFilters)
      ) {
        this.byMajorTopicsSearchTaskNumber =
          data.previouslySelectedSearchFilters.byMajorTopicsSearchTaskNumber;
        this.byMajorTopicsSearchTaskSubject =
          data.previouslySelectedSearchFilters.byMajorTopicsSearchTaskSubject;
        this.responsiblePersonInfo =
          data.previouslySelectedSearchFilters.responsiblePersonInfo;
        this.practiceInformation =
          data.previouslySelectedSearchFilters.practiceInformation;
        this.FromRecords =
          data.previouslySelectedSearchFilters.StatemaintainFromRecords;
        this.implementedbyModel =
          data.previouslySelectedSearchFilters.implementedbyModel;
        this.SearchStatusIDs =
          data.previouslySelectedSearchFilters.SearchStatusIDs;
        this.selectedTask = data.previouslySelectedTaskNumber;
      }
    });
    this.getTaskStatusList();
    this.GetSupportGroupsList();
    this.byMajorTopicsGetMajorTopicsListClick(undefined, undefined, false);
    setTimeout(function () {
      document.getElementById("txtByMajorTopicsSearchTask").focus();
    }, 20);

    for (let i = 0; i < this.columnDefs.length; i++) {
      switch (this.columnDefs[i].field) {
        case "IssueSubject":
          if ($(window).width() > 1700) {
            this.columnDefs[3].width = 467;
            this.columnDefs[6].width = 225;
          } else if ($(window).width() < 1700 && $(window).width() >= 1670) {
            this.columnDefs[3].width = 260;
            this.columnDefs[6].width = 169;
            this.columnDefs[7].width = 150;
          } else if ($(window).width() < 1670 && $(window).width() >= 1550) {
            this.columnDefs[3].width = 191;
            this.columnDefs[6].width = 189;
          } else if ($(window).width() < 1550 && $(window).width() >= 1480) {
            this.columnDefs[1].width = 126;
            this.columnDefs[3].width = 205;
            this.columnDefs[6].width = 180;
          } else if ($(window).width() < 1480 && $(window).width() >= 1400) {
            this.columnDefs[1].width = 109;
            this.columnDefs[3].width = 162;
            this.columnDefs[6].width = 151;
            this.columnDefs[7].width = 135;
          } else if ($(window).width() < 1400 && $(window).width() >= 1300) {
            this.columnDefs[1].width = 114;
            this.columnDefs[3].width = 144;
            this.columnDefs[6].width = 135;
            this.columnDefs[8].width = 135;
          } else if ($(window).width() < 1300 && $(window).width() >= 1200) {
            this.columnDefs[1].width = 140;
            this.columnDefs[2].width = 146;
            this.columnDefs[3].width = 160;
            this.columnDefs[6].width = 118;
            this.columnDefs[8].width = 114;
          }
          break;
      }
    }
    setTimeout(() => {
      this.statemaintainPreviousSelectedRecord();
    }, 100);
  }
  private getTaskStatusList() {
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "TaskStatusList", {})
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }

        this.viewTaskInfoSelectStatusDropDown = [];

        response.forEach((element) => {
          if (element.TaskStatusId != 4) {
            this.viewTaskInfoSelectStatusDropDown.push({
              TaskDescription: element.TaskDescription,
              TaskStatusId: element.TaskStatusId,
            });
          }
        });
      });
  }

  GetSupportGroupsList() {
    const PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "OASIssueSupportGroupsListView",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.supportGroupsList = serviceResponse;
      });
  }
  byMajorTopicsExportToExcelClick(exoportoexcel) {
    this.byMajorTopicsGetMajorTopicsListClick(
      undefined,
      undefined,
      exoportoexcel
    );
  }
  viewTaskInfoPrevNextClick(prevornext) {
    this.byMajorTopicsGetMajorTopicsListClick(undefined, prevornext, false);
  }
  byMajorTopicsGetMajorTopicsListClick(
    isfromsearch,
    prevornext,
    exoportoexcel
  ) {
    this.RecordsPerPage = 50;
    if (exoportoexcel == false) {
      if (
        isfromsearch == true &&
        (prevornext == false || prevornext == undefined) &&
        this.TotalCountValue == false
      ) {
        this.TotalCountValueIcon = false;
        this.TotalCountValue = true;
      }
      if (
        !this.hasValue(this.FromRecords) ||
        (this.hasValue(isfromsearch) && isfromsearch == true)
      ) {
        this.FromRecords = 1;
      }
      switch (prevornext) {
        case 1:
          //if(this.hasValue(this.PreviousButtondisabled) && this.PreviousButtondisabled==true) return;
          this.FromRecords -= this.RecordsPerPage;
          break;

        case 2:
          //if(this.hasValue(this.NextButtonDisabled) && this.NextButtonDisabled==true) return;
          this.FromRecords += this.RecordsPerPage;
          break;
        case 3:
          this.fromrecordsfromcount = this.FromRecords;
          this.FromRecords = null;
      }
    }
    let statusids = this.SearchStatusIDs.map((item) => item.TaskStatusId).join(
      ","
    );
    const data = {
      CreatedByUserIDs: this.hasValue(this.implementedbyModel.EmployeeId)
        ? this.implementedbyModel.EmployeeId
        : undefined,
      FromRecords: this.hasValue(this.FromRecords)
        ? this.FromRecords
        : undefined,
      TaskNumber: this.byMajorTopicsSearchTaskNumber,
      TaskSubject: this.byMajorTopicsSearchTaskSubject,
      TaskLatestStatusList: this.hasValue(statusids) ? statusids : undefined,
      ResponsibleUserIDs: this.hasValue(
        this.responsiblePersonInfo.EmployeeId
      )
        ? this.responsiblePersonInfo.EmployeeId
        : undefined,
      PracticeId: this.hasValue(this.practiceInformation.PracticeId)
        ? this.practiceInformation.PracticeId.toString()
        : undefined,
      SupportGroupIDs: this.hasValue(
        this.bymajorTopicsModel.IssueSupportGroupID
      )
        ? this.bymajorTopicsModel.IssueSupportGroupID
        : undefined,
    };
    if (exoportoexcel == true) {
      this.commonhelperobj
        .PostData(
          Config.OASReportsofTasksAppnURL + "TaskByMajorTopicsExcelReport",
          data
        )
        .subscribe((serviceResponse) => {
          if (this.isError(serviceResponse)) {
            return;
          }
          if (
            this.hasValue(serviceResponse) &&
            this.hasValue(serviceResponse.taskByMajorTopicsOutputModels) &&
            serviceResponse.taskByMajorTopicsOutputModels.length > 0
          ) {
            this._excelService.exportAsExcelFile(
              this._byMajorTopicsService.ExcelColumnsInfo,
              serviceResponse.taskByMajorTopicsOutputModels,
              "ByMajorTopicsReport"
            );
            // window.open(serviceResponse);
          }
        });
      return;
    }
    this.commonhelperobj
      .PostData(Config.OASReportsofTasksAppnURL + "TaskByMajorTopics", data)
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }

        if (
          this.hasValue(response) &&
          this.hasValue(response.Count) &&
          response.Count > 0
        ) {
          this.TotalCountValueIcon = true;
          this.TotalCountValue = false;
          this.TotalLabelCount = response.Count;
          this.FromRecords = this.fromrecordsfromcount;
          return;
        }
        response.taskByMajorTopicsOutputModels.forEach((TaskListelement) => {
          if (this.hasValue(TaskListelement.TaskUsersInfo)) {
            const SplittedUserData = TaskListelement.TaskUsersInfo.split(",");
            if (
              this.hasValue(SplittedUserData) &&
              SplittedUserData.length > 0
            ) {
              SplittedUserData.forEach((element) => {
                const getUserRolesData = element.split("|");
                if (
                  this.hasValue(getUserRolesData) &&
                  getUserRolesData.length > 0
                ) {
                  if (getUserRolesData[2] === "2") {
                    TaskListelement.ResponsiblePerson = getUserRolesData[1];
                  }
                }
              });
            }
          }
        });
        this.rowData = response.taskByMajorTopicsOutputModels;
        this.PrevLabelCount = this.FromRecords;
        if (this.FromRecords == 1) {
          this.NextLabelCount = response.taskByMajorTopicsOutputModels.length;
        } else {
          this.NextLabelCount =
            this.FromRecords +
            response.taskByMajorTopicsOutputModels.length -
            1;
        }
        if (
          this.FromRecords == 1 &&
          response.taskByMajorTopicsOutputModels.length < this.RecordsPerPage
        ) {
          this.prevnextbuttons = true;
          this.TotalLabelCountValue =
            response.taskByMajorTopicsOutputModels.length;
        } else if (
          this.FromRecords == 1 &&
          response.taskByMajorTopicsOutputModels.length == this.RecordsPerPage
        ) {
          this.prevnextbuttons = false;
          this.PreviousButtondisabled = true;
          this.NextButtonDisabled = false;
        } else if (
          response.taskByMajorTopicsOutputModels.length < this.RecordsPerPage
        ) {
          this.prevnextbuttons = false;
          this.NextButtonDisabled = true;
          this.PreviousButtondisabled = false;
        } else {
          this.prevnextbuttons = false;
          this.PreviousButtondisabled = false;
          this.NextButtonDisabled = false;
        }
      });
  }
  byMajorTopicsSelectPracticeIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Practice",
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.practiceInformation = result;
      }
    });
  }
  byMajorTopicsSelectResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      FromInternalTickets: true,
      IsSelectionType: "multiple",
    };
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        let responsiblePersonInfo: any = result;
        this.responsiblePersonInfo.EmployeeId = [];
        this.responsiblePersonInfo.EmployeeFullName = "";
        responsiblePersonInfo.forEach((ele) => {
          this.responsiblePersonInfo.EmployeeFullName =
            this.responsiblePersonInfo.EmployeeFullName +
            "," +
            ele.EmployeeFullName;
          this.responsiblePersonInfo.EmployeeId.push(ele.EmployeeId);
        });
      }
    });
  }
  byMajorTopicsSelectCreatedbyIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select CreatedBy Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      FromInternalTickets: true,
      IsSelectionType: "multiple",
    };
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        let implementedbyModel: any = result;

        this.implementedbyModel.EmployeeId = [];
        this.implementedbyModel.EmployeeFullName = "";

        implementedbyModel.forEach((ele) => {
          this.implementedbyModel.EmployeeFullName =
            this.implementedbyModel.EmployeeFullName +
            "," +
            ele.EmployeeFullName;
          this.implementedbyModel.EmployeeId.push(ele.EmployeeId);
        });
      }
    });
  }
  byMajorTopicsRemoveSelectedCreatedbyClickEvent() {
    this.implementedbyModel = {};
  }
  byMajorTopicsRemoveSelectedResponsiblePersonClickEvent() {
    this.responsiblePersonInfo = {};
  }
  byMajorTopicsRemoveSelectedPracticeClickEvent() {
    this.practiceInformation = {};
  }
  byMajorTopicsViewClickEvent() {
    let selectedTask = this.getSelectedTaskInfo();
    if (!this.hasValue(selectedTask)) return;
    const PostDataService: any = {
      IsFromEdit: true,
      editmodedata: selectedTask,
      isfrom: 5, //bymajortopics
    };
    PostDataService.StatemaintainPreviouslySearchFilters = {
      byImplementerSearchTaskNumber: this.byMajorTopicsSearchTaskNumber,
      byImplementerSearchTaskSubject: this.byMajorTopicsSearchTaskSubject,
      responsiblePersonInfo: this.responsiblePersonInfo,
      practiceInformation: this.practiceInformation,
      StatemaintainFromRecords: this.FromRecords,
      implementedbyModel: this.implementedbyModel,
      SearchStatusIDs: this.SearchStatusIDs,
    };
    const outletObj = {};
    outletObj["main"] = [
      "app-view-full-task-information",
      this.setCommonData(PostDataService),
    ];
    this._router.navigate(["/home", { outlets: outletObj }]);
  }
  getSelectedTaskInfo() {
    const selectedrow = this.byMajorTopicsGrid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.ShowErrorMessage("Please Select Task.");
      document.getElementById("myAppointmentsSelectUser").focus();
      return 0;
    }
    return selectedrow[0];
  }
  statemaintainPreviousSelectedRecord() {
    let seletedtasknumber = this.selectedTask;
    let storeGridInfo = this.byMajorTopicsGrid.api;
    setTimeout(() => {
      this.byMajorTopicsGrid.api.forEachLeafNode(function (node) {
        if (node.data.TaskNumber == seletedtasknumber) {
          node.setSelected(true);
          //Moving Scroll bar to Selected Node
          storeGridInfo.ensureIndexVisible(node.rowIndex + 1);
        }
      });
    }, 500);
  }
}
