import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { commonhelper } from '../common/commonhelper';
import { Config } from '../config';
import { ScheduledMeetingAddCommentsPopupComponent } from '../scheduled-meeting-add-comments-popup/scheduled-meeting-add-comments-popup.component';
import { CustomerCallLogViewCommentsPopupService } from './customer-call-log-view-comments-popup.service';

@Component({
  selector: 'app-customer-call-log-view-comments-popup',
  templateUrl: './customer-call-log-view-comments-popup.component.html',
  styleUrls: ['./customer-call-log-view-comments-popup.component.css']
})
export class CustomerCallLogViewCommentsPopupComponent extends commonhelper implements OnInit {
  @ViewChild('customerCallLogViewCommentsPopupGrid') customerCallLogViewCommentsPopupGrid: AgGridNg2;
  @ViewChild("customerCallLogViewCommentsPopup", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  SelectedCustomerCallLogInfo: any;
  customerCallLogViewCommentsList = [];
  columnDefs = [
    { headerName: 'Date Time', headerTooltip: 'Date Time', field: 'createddateandtime', tooltipField: 'createddateandtime', width: 150, cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Author', headerTooltip: 'Author', field: 'CreatedByName', tooltipField: 'CreatedByName', width: 150, cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Description', headerTooltip: 'Description', field: 'CustomerCallLogComments', tooltipField: 'CustomerCallLogComments', width: 360, cellClass: 'no-border cell-wrap-text', autoHeight: true },
  ];
  onCustomerCallLogViewCommentsPopupGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  constructor(private dialogService: DialogService, private _customerCallLogViewCommentsPopupService: CustomerCallLogViewCommentsPopupService, private dialog: DialogRef,) { super(); }

  ngOnInit() {
    this.CallLogsCommentsList();
  }
  CallLogsCommentsList() {
    this._customerCallLogViewCommentsPopupService.CallLogsCommentsList({ CustomerCallLogsID: this.SelectedCustomerCallLogInfo.CustomerCallLogsID }).subscribe(response => {
      if (this.isError(response)) return;
      this.customerCallLogViewCommentsList = response.CallLogCommentsList;
    });
  }

  customerCallLogViewCommentsPopupAddCommentsClick() {
    const dialogRef = this.dialogService.open({
      title: 'Add Comments',
      // Show component
      content: ScheduledMeetingAddCommentsPopupComponent,
      height: 230,
      width: 500,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.UpdateCallLogsStatusComments({ Comments: result }, this.SelectedCustomerCallLogInfo.CustomerCallLogsID, undefined);
        }
      }
    });
  }
  customerCallLogViewCommentsPopupEditCommentsClick() {
    let selectedCommentItem = this.getSelectedComment();
    if (!selectedCommentItem) return;
    this._customerCallLogViewCommentsPopupService.GetCallLogsCommentInfo({ CommentLinkedId: selectedCommentItem.CommentsLinkedId }).subscribe(response => {
      if (this.isError(response)) return;
      this.customerCallLogEditCommentsPopup(response, selectedCommentItem);
    });
  }
  customerCallLogEditCommentsPopup(response, selectedCommentItem) {
    const dialogRef = this.dialogService.open({
      title: 'Edit Comments',
      // Show component
      content: ScheduledMeetingAddCommentsPopupComponent,
      height: 230,
      width: 500,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.SelectedCommentInfo = response
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.UpdateCallLogsStatusComments({ Comments: result }, selectedCommentItem.CustomerCallLogsID, selectedCommentItem.CommentsLinkedId);
        }
      }
    });
  }



  UpdateCallLogsStatusComments(result, CustomerCallLogsID, CommentsLinkedId) {
    const postData = result;
    postData.Customer_CallLogs_ID = CustomerCallLogsID;
    postData.CommentsLinkedId = CommentsLinkedId;
    let message = CommentsLinkedId > 0 ? "Updated" : "Added"
    this._customerCallLogViewCommentsPopupService.UpdateCallLogsStatusComments(postData).subscribe(response => {
      if (this.isError(response)) return;
      this.ShowSuccesseMessage("Comment " + message + " Successfully")
      this.CallLogsCommentsList();
    });
  }
  DeleteCallLogsCommentInfo(postData) {
    this._customerCallLogViewCommentsPopupService.DeleteCallLogsCommentInfo(postData).subscribe(response => {
      if (this.isError(response)) return;
      this.ShowErrorMessage("Comment Deleted Successfully")
      this.CallLogsCommentsList();
    });
  }
  customerCallLogViewCommentsPopupDeleteCommentsClick() {
    let selectedCommentItem = this.getSelectedComment();
    if (!selectedCommentItem) return;
    if (selectedCommentItem.CreatedBy != Config.OASOrgModel.LoggedUserID) {
      this.ShowErrorMessage("Your are not Allowed to Delete Comment Created by Others")
      return;
    }
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please Confirm',
      content: 'Are You Sure to Delete Comment?',
      actions: [
        { text: 'No' },
        { text: 'Yes', primary: true }
      ],
      width: 450,
      height: 250,
      minWidth: 250,
      appendTo: this.ContainerRef
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        //console.log('close');
      } else {
        if (this.hasValue(result)) {
          if (result.text === 'No') return;
          this.DeleteCallLogsCommentInfo({ CommentsLinkedId: selectedCommentItem.CommentsLinkedId })
        }

      }
    });
  }
  getSelectedComment() {
    const selectedrow = this.customerCallLogViewCommentsPopupGrid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.ShowErrorMessage("Please Select Comment")
      return false;
    }
    return selectedrow[0];
  }
  CancelClickEvent() {
    this.dialog.close();
  }

}
