import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { AgGridNg2 } from "ag-grid-angular";
import { GridSizeChangedEvent } from "ag-grid-community";
import { commonhelper } from "../common/commonhelper";
import { DeleteSelectedPersonRendererComponent } from "../delete-selected-person-renderer/delete-selected-person-renderer.component";
import { MoveIconCellRendererComponent } from "../move-icon-cell-renderer/move-icon-cell-renderer.component";
import { CloneService } from "../shared/services/clone.service";
import {
  IpopuModel,
  IUsersInputModel,
  IUsersModel,
} from "./practice-users-customization.interface";
import { PracticeUsersCustomizationService } from "./practice-users-customization.service";

@Component({
  selector: "app-practice-users-customization",
  templateUrl: "./practice-users-customization.component.html",
  styleUrls: ["./practice-users-customization.component.css"],
})
export class PracticeUsersCustomizationComponent extends commonhelper
  implements OnInit {
  @ViewChild("userSearch") _userSearch: ElementRef;
  @ViewChild("practiceUserscustomizationSelectPracticeGrid")
  selectUserGrid: AgGridNg2;
  @ViewChild("practiceUserscustomizationSelectedPracticeGrid")
  selectedUserGrid: AgGridNg2;
  rowData: IUsersModel[] = [];
  SelectedUsersRowData: IUsersModel[] = [];
  datatoPopup: IpopuModel;
  UserName: string;
  constructor(
    private readonly clonerService: CloneService,
    private readonly _practiceUsersCustomizationService: PracticeUsersCustomizationService,
    private readonly dialog: DialogRef
  ) {
    super();
  }
  ngOnInit(): void {
    this.variablesInitialize();
    this.getPracticeUsersList();
  }
  variablesInitialize(): void {
    this.SelectedUsersRowData = this.clonerService.deepClone(
      this.datatoPopup.StateMaintainUsersData
    );
  }

  getPracticeUsersList() {
    //TODO
    // console.log(this.buildUsersListInputModel());
    this._practiceUsersCustomizationService
      .getPracticeUsersList(this.buildUsersListInputModel())
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.rowData = serviceResponse || [];
        this._userSearch.nativeElement.focus();
      });
  }

  buildUsersListInputModel(): IUsersInputModel {
    return {
      PracticeId: this.datatoPopup.PracticeId,
      EmployeeName: this.UserName,
      IssueSupportGroupID: this.datatoPopup.IssueSupportGroupID,
      SelectedUserIDs: $.map(this.SelectedUsersRowData, (item) => {
        return item.UserID;
      }).join(", "),
    };
  }
  onpracticeUserscustomizationGridSizeChanged(
    params: GridSizeChangedEvent
  ): void {
    params.api.sizeColumnsToFit();
  }
  context = {
    componentParent: this,
  };
  defaultColDef = {
    suppressMenu: true,
    lockPosition: true,
  };
  SelectPracticecolumnDefs = [
    {
      headerName: "Select User(s)",
      field: "EmployeeName",
      headerTooltip: "Select User(s)",
      width: 255,
      tooltipField: "EmployeeName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },

    {
      width: 60,
      headerComponent: "MoveIconCellRendererComponent",
      cellRenderer: "MoveIconCellRendererComponent",
      colId: "params",
    },
  ];
  SelectedPracticecolumnDefs = [
    {
      headerName: "Selected User(s)",
      field: "EmployeeName",
      headerTooltip: "Selected User(s)",
      width: 280,
      tooltipField: "EmployeeName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      width: 60,
      headerComponent: "DeleteSelectedPersonRendererComponent",
      cellRenderer: "DeleteSelectedPersonRendererComponent",
      colId: "params",
    },
  ];
  frameworkComponents = {
    DeleteSelectedPersonRendererComponent: DeleteSelectedPersonRendererComponent,
    MoveIconCellRendererComponent: MoveIconCellRendererComponent,
  };
  DeleteSelectedAddlResponsiblePerson(params: any): void {
    this.perforMoveandRemoveOperations(
      params,
      "SelectedUsersRowData",
      "rowData"
    );
    this.assignDatatoGrids();
  }
  MoveSelectedAddlResponsiblePerson(params: any): void {
    this.perforMoveandRemoveOperations(
      params,
      "rowData",
      "SelectedUsersRowData"
    );
    this.assignDatatoGrids();
  }
  perforMoveandRemoveOperations(
    params: { data: IUsersModel; rowIndex: number },
    fromRowData: string,
    toRowData: string
  ): void {
    if (params.data) {
      this[fromRowData] = this[fromRowData].filter(
        (item) => item.UserID != params.data.UserID
      );
      this[toRowData].push(params.data);
    } else {
      this[toRowData].push.apply(this[toRowData], this[fromRowData]);
      this[fromRowData] = [];
    }
  }
  assignDatatoGrids(): void {
    this.selectUserGrid.api.setRowData(this.rowData);
    this.selectedUserGrid.api.setRowData(this.SelectedUsersRowData);
  }

  OKClickEvent(): void {
    if (this.checkWetherEmployeeisSelectedornot()) return;
    this.dialog.close(this.SelectedUsersRowData);
  }
  checkWetherEmployeeisSelectedornot(): boolean {
    if (this.SelectedUsersRowData.length <= 0) {
      this.ShowErrorMessage("Please Select User(s).");
      return true;
    }
    return false;
  }
  CancelClickEvent() {
    this.dialog.close();
  }
}
