import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { commonhelper } from "../common/commonhelper";
import { Config } from "../config";
import { commonHelperService } from "../common/common.service";
import { DialogRef } from "@progress/kendo-angular-dialog";

@Component({
  selector: "app-view-consolidated-taskdetails",
  templateUrl: "./view-consolidated-taskdetails.component.html",
  styleUrls: ["./view-consolidated-taskdetails.component.css"],
})
export class ViewConsolidatedTaskdetailsComponent extends commonhelper
  implements OnInit {
  formInformation: any;
  SelectedTaskInfo: any;
  value=new Date  ();
  datatoPopup:any;
  items = [

  ];
  constructor(private _router: Router, private route: ActivatedRoute,   private dialog: DialogRef,  private commonhelperobj: commonHelperService) {
    super();
  }

  ngOnInit() {
    // this.route.params.subscribe((params) => {
    //   console.log(params.info);
    //   const dataCame = this.getCommonData(params.info);
    //   console.log(dataCame);
    //   this.formInformation = dataCame;
    //   this.ViewFullTaskInformation(this.formInformation);
    // });
    // console.log(this.datatoPopup)
    this.ViewFullTaskInformation(this.datatoPopup)
  }

  // onClickBack() {
  //   const outletObj = {};
  //   const clickedItem: any = {};
  //   clickedItem.routename = "main";
  //   clickedItem.routepath = "/home";
  //   clickedItem.previouslySelectedSearchFilters = this.formInformation.StatemaintainPreviouslySearchFilters;
  //   clickedItem.SelctedGridData=this.formInformation
  //   outletObj[clickedItem.routename] = [
  //     "consolidated-tickets-report.component",
  //     this.setCommonData(clickedItem),
  //   ];
  //   console.log();
  //   this._router.navigate([clickedItem.routepath, { outlets: outletObj }]);
  // }
  // OnClickViewTask(item) {
  //   console.log(item);
  //   this.SelectedTaskInfo = item;
  // }

  CancelClickEvent(){
this.dialog.close()
  }
 ViewFullTaskInformation(data) {
    var postData = {
      CreateDate: data.taskCreatedDate,
      TaskBucketID: data.TaskBucketID,
      TaskBucketCategory: data.TaskBUcketCategory,
      TaskCreatedFromDateandTime:data.taskCreatedFromTime,
      TaskCreatedToDateandTime:data.taskCreatedToTime
    };
    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "ConsilidatedTaskNumberList",
        postData
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }else{
          this.items=serviceResponse.consolidatedTaskNumbersList
        }
      //  console.log(serviceResponse)


        //this.initializeTaskCategoriesCount();
      });
  }
}
