import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { PraticesListComponent } from "../pratices-list/pratices-list.component";
import {
  DialogCloseResult,
  DialogService,
} from "@progress/kendo-angular-dialog";
import { commonHelperService } from "../common/common.service";
import { HttpClient } from "@angular/common/http";
import { Config } from "../config";
import { commonhelper } from "../common/commonhelper";
import { SupportStatisticsViewComponent } from "../support-statistics-view/support-statistics-view.component";
import * as moment from "moment";
import { IntlService, formatDate } from "@progress/kendo-angular-intl";

@Component({
  selector: "app-support-statistics-main-view",
  templateUrl: "./support-statistics-main-view.component.html",
  styleUrls: ["./support-statistics-main-view.component.css"],
})
export class SupportStatisticsMainViewComponent
  extends commonhelper
  implements OnInit
{
  @ViewChild("StatisticsReportTickets")
  StatisticsReportTickets: ElementRef;
  @ViewChild("StatisticsReportTickets", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  CreatedFromDate: any = new Date();
  CreatedToDate: any = new Date();
  public CreatedToTime: any = new Date();
  public CreatedFromTime: any = new Date("Tue Apr 11 2000 00:00:00 GMT+0530");
  public practiceInformation: any = {};
  SupportRelatedList: any = [];

  private commonhelperobj: commonHelperService;
  constructor(
    private intl: IntlService,
    private dialogService: DialogService,
    httpClient: HttpClient
  ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }

  ngOnInit() {
    this.getTaskSupportRelatedList();
  }

  statisticsReportTicketsSelectPracticeIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Pratice",
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.practiceInformation = result;
        // console.log(this.practiceInformation);
        // this.EHRPracticeID = this.practiceInformation.PracticeId;
      }
    });
  }
  statisticsReportTicketsRemoveSelectedPracticeClickEvent() {
    this.practiceInformation = {};
  }

  statisticsReportTicketsRemoveSelectedDatesClickEvent(number) {
    if (number === 1) {
      this.CreatedFromDate = "";
      this.CreatedFromTime = "";
    } else if (number === 2) {
      this.CreatedToDate = "";
      this.CreatedToTime = "";
    }
  }

  getTaskSupportRelatedList() {
    let fromTime = this.CreatedFromTime
      ? formatDate(this.CreatedFromTime, "HH:mm:ss", "en-US", "+0530")
      : "00:00:00";
    let toTime = this.CreatedToTime
      ? formatDate(this.CreatedToTime, "HH:mm:ss", "en-US", "+0530")
      : "00:00:00";

    let createdFromDate =
      this.formatDateValue(this.CreatedFromDate) + " " + fromTime;
    let createdToDate = this.formatDateValue(this.CreatedToDate) + " " + toTime;

    let data = {
      createdFromDate: createdFromDate,
      createdToDate: createdToDate,
      PracticeID: this.practiceInformation.PracticeId,
    };
    if (this.applyclassesforTasksCreatedDatesSelected(data) == false) return;

    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "ConsolidatedSupportRelatedReport",
        data
      )
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
        this.SupportRelatedList = response.SupportRelatedList;
      });
  }




  private applyclassesforTasksCreatedDatesSelected(data: any) {
    if (this.hasValue(this.CreatedFromDate)) {
      if (!this.hasValue(this.CreatedToDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created To Date.");
        return false;
      }
    }
    if (this.hasValue(this.CreatedToDate)) {
      if (!this.hasValue(this.CreatedFromDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created From Date.");
        return false;
      }
    }
    // console.log(this.CreatedFromTime, this.CreatedToTime, "TImes");
    data.createdFromDate =
      this.formatDateValue(this.CreatedFromDate) +
      " " +
      formatDate(this.CreatedFromTime, "HH:mm:ss", "en-US", "+0530");
    data.createdToDate =
      this.formatDateValue(this.CreatedToDate) +
      " " +
      formatDate(this.CreatedToTime, "HH:mm:ss", "en-US", "+0530");

    if (this.tasksCreatedFromDateChange() == false) return false;
    if (this.tasksCreatedTODateChange() == false) return false;
  }

  tasksCreatedFromDateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedFromDate,
        "CreatedFromDate",
        true
      )
    )
      return false;
  }

  tasksCreatedTODateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedToDate,
        "CreatedToDate",
        false
      )
    )
      return false;
  }

  createdFromDateToDateValidations(
    modifiedfromandtoDate: Date,
    name: string,
    isfrom: boolean
  ) {
    var today = new Date();
    var toDate = new Date(modifiedfromandtoDate);
    var showfocuselement = false;
    const daysDifference = this.DateDiffInDays(
      isfrom ? this.CreatedToDate : today,
      isfrom ? this.CreatedFromDate : this.CreatedToDate
    );
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage("Invalid Day, Month or Year Range Detected.");
      showfocuselement = true;
    }
    if (this.hasValue(daysDifference) && daysDifference < 0) {
      if (isfrom) {
        this.ShowErrorMessage(
          "Created From Date Should be Less Than or Equal to Created To Date"
        );
        this.CreatedFromDate = "";
      } else {
        this.CreatedToDate = "";
        this.ShowErrorMessage("Future Dates Not allowed");
      }
      showfocuselement = true;
    }
    if (showfocuselement) {
      const elementFound = this.StatisticsReportTickets.nativeElement.querySelector(
        '[name="' + name + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    return true;
  }

  OpenView(number) {

    if (number.SupportGroupID > 0 && number.SupportGroupID > 0) {
      let fromTime = this.CreatedFromTime
      ? formatDate(this.CreatedFromTime, "HH:mm:ss", "en-US", "+0530")
      : "00:00:00";
    let toTime = this.CreatedToTime
      ? formatDate(this.CreatedToTime, "HH:mm:ss", "en-US", "+0530")
      : "00:00:00";

    let createdFromDate =
      this.formatDateValue(this.CreatedFromDate) + " " + fromTime;
    let createdToDate =
      this.formatDateValue(this.CreatedToDate) + " " + toTime;

      let data={
        createdFromDate: createdFromDate,
        createdToDate: createdToDate,
        PracticeID: this.practiceInformation.PracticeId,
        SupportGroupID: number.SupportGroupID,
      }
      if (this.applyclassesforTasksCreatedDatesSelected(data) == false) return ;


      const dialogRef = this.dialogService.open({
        title: number.SupportGroup,
        // Show component
        content: SupportStatisticsViewComponent,
        height: 698,
        width: 780,
        appendTo: this.ContainerRef,
      });

   dialogRef.content.instance.datatoPopup = {
      ...data
    };


      dialogRef.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
        } else {
          // this.assignedByInfo = result;
        }
      });
    }
  }

  private formatDateValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "MM/dd/yyyy")}` : "";
  }
}
