import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { GridOptions } from 'ag-grid-community';
import { AgGridNg2 } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { commonHelperService } from '../common/common.service';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee } from '../edit-task-customization-based-on-users/edit-task-customization-based-on-users.component';
import { TaskService } from '../services/task.service';
import { DialogCloseResult, DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { AgGridModule } from 'ag-grid-angular';
import { IntlService } from '@progress/kendo-angular-intl';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
//import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-add-employee-info-popup',
  templateUrl: './add-employee-info-popup.component.html',
  styleUrls: ['./add-employee-info-popup.component.css']
})
export class AddEmployeeInfoPopupComponent extends commonhelper implements OnInit {
  @ViewChild('AddEmployeeInfo') AddEmployeeInfo: ElementRef;
  private commonhelperobj: commonHelperService;
  public FirstNameField: HTMLElement;
  public SelectedEmployeeEditInformation: any;
  public oasMainDashboardProfile: any;
  public EmployeeInformationModellist: any;
  public EmployeeInfoId: any;
  public DisabledButton: boolean
  addEmployeeInfoDesignationList: any;
  addEmployeeInfoDepartmentList: any;
  public NumberPattern: any
  addEmployeeInfoGenderList: any;
  addEmployeeInfoBloodGroupList: any;
  addEmployeeInfoStateList: any;
  addEmployeeInfoDistrictList: any;
  addEmployeeInfoCityList: any;
  addEmployeeInfoJobTypeList: any;
  employeeDesignationIDList: any;
  employeeTypeList: any;
  public AddEmployeeInfoModel: any = {};
  addEmployeeInfoButtonName: string;
  SelectedGender: any;
  StateListValue: boolean;
  CityListValue: boolean;
  public EmployeeInfoID: any;
  // public divGenderID: HTMLElement;
  public divStateID: HTMLElement;
  public divDistrictID: HTMLElement;
  public divCityID: HTMLElement;
  public divDepartmentID: HTMLElement;
  public divDesignationID: HTMLElement;
  public divJobTypeID: HTMLElement;
  public divDateofjoining: HTMLElement;
  isDisabled: boolean;
  IsDisabled: boolean;
  ISDisabled:boolean;
  Disabled:boolean;
  // public ree:any = /^[a-zA-Z ]*$/;
  // public AddEmployeeInfoModel: any = {};
  constructor(httpClient: HttpClient,
    private _Router: Router,
    private taskService: TaskService,
    private dialogService: DialogService, private intl: IntlService, private dialog: DialogRef
  ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);

  }

  @ViewChild('ViewAccessInformationPopup') ViewAccessInformationPopup: ElementRef;
  //@ViewChild("myDropdown") myDropdown: DropDownListComponent;

  public defaultAddEmployeeInfoGenderItem = { GenderDescription: '-- Select Gender --', GenderID: null }
  public defaultAddEmployeeInfoBloodGroupItem = { BloodGroup: '-- Select Blood Group --', BloodGroupID: null }
  public defaultAddEmployeeInfoStateItem = { State: '-- Select State --', StateID: null }
  public defaultAddEmployeeInfoDistrictItem = { District: '-- Select District --', DistrictID: null }
  public defaultAddEmployeeInfoCityItem = { City: '-- Select City --', CityID: null }
  public defaultAddEmployeeInfoDesignationItem = { Designation: '-- Select Designation --', DesiginationId: null }
  public defaultAddEmployeeInfoDepartmentItem = { Department: '-- Select Department --', DepartmentID: null }
  public defaultAddEmployeeInfoJobTypeItem = { JobTypeDescription: '-- Select Job Type --', JobTypeID: null }
  public defaultAddEmployeeDesignationItem = { DesignationName: '-- Select Employee Designation --', Employee_Designation_ID: null }
  public defaultAddEmployeeTypeItem = { LoginType_Desc: '-- Select Employee Type --', LoginTypeID: null }


  ngOnInit() {
    // if(this.SelectedEmployeeEditInformation==""){
    // }
    //   else{
    //}
    this.addEmployeeInfoButtonName = "Save";
    this.EmployeeEditInformation();
    this.GetGenderList();
    this.GetBloodGroupList();
    this.GetStateList();
    this.GetDistrictList();
    this.GetCityList();
    this.GetDepartmentList();
    this.GetDesignationList();
    this.GetJobTypeList();
    this.GetEmployeeDesignationList();
    this.GetEmployeeTypeList();
    setTimeout(function () {
      $("#txtAddEmployeeInfoFirstName").focus();
    }, 50);

    if (this.hasValue(this.SelectedEmployeeEditInformation) && (this.DisabledButton === false)) {
      this.IsDisabled = true;

     
    }
    if (this.hasValue(this.oasMainDashboardProfile)){
      this.IsDisabled = true;
      this.Disabled=true;

    }
  }

  // addEmployeeInfoCityList = [ 
  // {"CityDescription":"Vijayawada","CityID":"1"},
  // {"CityDescription":"Hyderabad","CityID":"2"}
  // ]

  //   //Job Type
  //   addEmployeeInfoJobTypeList = [
  //   {"JobTypeDescription":"Temporary","JobTypeID":"1"},
  //   {"JobTypeDescription":"Part time","JobTypeID":"2"}
  //     ]
  // if(!this.hasValue(this.SelectedEmployeeEditInformation)){

  // }
  // 
  //this.AddEmployeeInfoModel.addEmployeeInfoDateOfBirth = this.parseDate(this.SelectedEmployeeEditInformation.DateofBirth); 



  private parseDate(dateString: string): Date {
    if (!this.hasValue(dateString) || dateString.indexOf('/') === dateString.lastIndexOf('/')) {
      return undefined;
    }
    const [month, day, year] = dateString.split('/').map(dateFragment => +dateFragment);
    return new Date(year, month - 1, day);
  }

  public EmployeeEditInformation() {
    if (this.hasValue(this.SelectedEmployeeEditInformation) || this.hasValue(this.oasMainDashboardProfile)) {
      if (this.hasValue(this.SelectedEmployeeEditInformation)) {
        this.EmployeeInfoID = this.SelectedEmployeeEditInformation.EmployeeInfoID
      }
      else {
        this.EmployeeInfoID = this.oasMainDashboardProfile
      }

      var PostDataToService = {
        EmployeeInfoID: this.EmployeeInfoID

      };
      this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'GetEmployeInformation', PostDataToService).subscribe(serviceResponse => {
        if (this.isError(serviceResponse)) { return; }

        // this.AddEmployeeInfoModel.DateOfBirth=this.parseDate(this.SelectedEmployeeEditInformation.DateOfBirth);
        //  this.AddEmployeeInfoModel = this.SelectedEmployeeEditInformation;
        this.addEmployeeInfoButtonName = "Update";
        this.AddEmployeeInfoModel=serviceResponse;
        // this.AddEmployeeInfoModel.FirstName = serviceResponse.FirstName;
        // this.AddEmployeeInfoModel.MiddleName = serviceResponse.MiddleName;
        // this.AddEmployeeInfoModel.LastName = serviceResponse.LastName;
        this.AddEmployeeInfoModel.GenderID = serviceResponse.GenderID;
        this.AddEmployeeInfoModel.BloodGroupID = serviceResponse.BloodGroupID;
        this.AddEmployeeInfoModel.DateOfBirth = this.parseDate(serviceResponse.DateOfBirth);
        this.AddEmployeeInfoModel.StateID = serviceResponse.StateID;
        this.AddEmployeeInfoModel.DistrictID = serviceResponse.DistrictID
        this.AddEmployeeInfoModel.CityID = serviceResponse.CityID
        // this.AddEmployeeInfoModel.Mobile = serviceResponse.Mobile
        // this.AddEmployeeInfoModel.PersonalMail = serviceResponse.PersonalMail
        // this.AddEmployeeInfoModel.AccessID = serviceResponse.AccessID
        this.AddEmployeeInfoModel.EmployeeNumber = "KJS00" + serviceResponse.AccessID
        this.AddEmployeeInfoModel.DateOfJoining = this.parseDate(serviceResponse.DateOfJoining);
        this.AddEmployeeInfoModel.DepartmentID = serviceResponse.DepartmentID
        this.AddEmployeeInfoModel.DesignationID = serviceResponse.DesignationID
        // this.AddEmployeeInfoModel.Street1 = serviceResponse.Street1
        // this.AddEmployeeInfoModel.Street2 = serviceResponse.Street2
        // this.AddEmployeeInfoModel.SecondaryMobile = serviceResponse.SecondaryMobile
        this.AddEmployeeInfoModel.JobTypeID = serviceResponse.JobTypeID
        // this.AddEmployeeInfoModel.ProfessionalEmailAddress = serviceResponse.ProfessionalEmailAddress
        // this.AddEmployeeInfoModel.Password = serviceResponse.Password
        // this.AddEmployeeInfoModel.Address = serviceResponse.Address
        // this.AddEmployeeInfoModel.PinCode = serviceResponse.PinCode
        this.AddEmployeeInfoModel.Employee_Designation_ID = serviceResponse.Employee_Designation_ID
        this.AddEmployeeInfoModel.LoginTypeID = serviceResponse.LoginType
        // this.AddEmployeeInfoModel.FatherName = serviceResponse.FatherName
        // this.AddEmployeeInfoModel.MotherName=serviceResponse.MotherName
        // this.AddEmployeeInfoModel.MarriageDate=this.parseDate(serviceResponse.MarriageDate)
        this.GetDistrictList();
        this.GetCityList();
        this.GetDesignationList();
      });
    }


  }
  // private parseDate(dateString: string): Date {
  //   if (!this.hasValue(dateString) || dateString.indexOf('/') === dateString.lastIndexOf('/')) {
  //     return undefined;
  //   }

  //   const [month, day, year] = dateString.split('/').map(dateFragment => +dateFragment);
  //   return new Date(year, month - 1, day);
  // }
  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, 'd')}` : '';
  }
  onDateOfJoiningChange(value: Date) {
    // let dateeee = this.formatValue(this.TaskAddInfoModel.ExpectedDate);

    // //return value ? ` - ${this.intl.formatDate(value, 'd')}` : '';
    // console.log(dateeee);
    // console.log(this.TaskAddInfoModel.ExpectedDate);

    if (!this.hasValue(this.AddEmployeeInfoModel.DateOfJoining)) {
      this.AddEmployeeInfoModel.DateOfJoining = '';
      // this.CreateTaskInfo.nativeElement.querySelector('#TaskAddNewExpectedDeliveryDate').value = '';
      // //   this.ShowErrorMessage('Invalid Day, Month or Year Range Detected.');
      // const elementFound = this.CreateTaskInfo.nativeElement.querySelector('[name="' + 'TaskAddNewExpectedDeliveryDatename' + '"]');
      // if (this.hasValue(elementFound)) {
      //   elementFound.children[0].children[0].children[0].children[0].value = "";
      // }
      // this.TaskAddInfoModel.ExpectedDate = undefined;
      return;
    }

    // if (this.hasValue(this.TaskAddInfoModel.ExpectedDate) && this.TaskAddInfoModel.ExpectedDate <= 0) {
    //   this.TaskAddInfoModel.ExpectedDate = '';
    //   return;
    // }
    var today = new Date();
    var DateofJoining = new Date(this.AddEmployeeInfoModel.DateOfJoining);
    var DateofBirth = new Date(this.AddEmployeeInfoModel.DateOfBirth)
    const daysDifference = this.DateDiffInDays(today, this.AddEmployeeInfoModel.DateOfJoining);
    const DateOfBirthyearsDifference = DateofBirth.getFullYear() - today.getFullYear();
    const DateofJoiningyearsDifference = DateofJoining.getFullYear() - today.getFullYear();
    // if (this.hasValue(daysDifference) && daysDifference > 0) {
    //   this.ShowErrorMessage('Expected Delivery Date should be greater than or equal to current date.');
    //   const elementFound = this.CreateTaskInfo.nativeElement.querySelector('[name="' + 'TaskAddNewExpectedDeliveryDatename' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].children[0].children[0].children[0].focus();
    //   }
    //   return;
    // }
    if (this.hasValue(DateofJoiningyearsDifference) && DateofJoiningyearsDifference > 100) {
      this.ShowErrorMessage('Invalid Day, Month or Year Range Detected.');
      // const elementFound = this.CreateTaskInfo.nativeElement.querySelector('[name="' + 'TaskAddNewExpectedDeliveryDatename' + '"]');
      // if (this.hasValue(elementFound)) {
      //   elementFound.children[0].children[0].children[0].children[0].focus();
      // }
      const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'txtAddEmployeeInfoDateOfJoining' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      //this.ViewAccessInformationPopup.nativeElement.querySelector('#txtAddEmployeeInfoDateOfJoining').focus();
      return false;
    }

  }

  onDateOfBirthChange(value: Date) {
    if (!this.hasValue(this.AddEmployeeInfoModel.DateOfBirth)) {
      this.AddEmployeeInfoModel.DateOfBirth = '';
      return;
    }
    var today = new Date();
    var DateofBirth = new Date(this.AddEmployeeInfoModel.DateOfBirth);
    const DateOfBirthyearsDifference = today.getFullYear() - DateofBirth.getFullYear();
    if (this.hasValue(DateOfBirthyearsDifference)) {
      if (DateOfBirthyearsDifference > 100) {
        this.ShowErrorMessage('Invalid Day, Month or Year Range Detected.');
        const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'txtAddEmployeeInfoDateOfBirthname' + '"]');
        if (this.hasValue(elementFound)) {
          elementFound.children[0].children[0].children[0].children[0].focus();
        }
        // this.ViewAccessInformationPopup.nativeElement.querySelector('txtAddEmployeeInfoDateOfBirth').focus();
        return false;
      }

    }
  }
  onStateList(value) {
    this.GetDistrictList()
  }
  onDistrictList(value) {
    this.GetCityList()
  }
  onDepartmentList(value){
    this.GetDesignationList();
  }
  onPersonalMail(value: string) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.hasValue(this.AddEmployeeInfoModel.PersonalMail)) {
      if (re.test(this.AddEmployeeInfoModel.PersonalMail) == false) {
        this.ShowErrorMessage('Please Enter Valid Email Address');
        document.getElementById("txtAddEmployeeInfoPersonalEMail").focus();
        return false;
      }
    }

  }
  onFirstName(value: string) {
    //var re = /^[a-zA-Z]+$/;
    var re = /^[a-zA-Z ]*$/;
    if (this.hasValue(this.AddEmployeeInfoModel.FirstName)) {
      if (re.test(this.AddEmployeeInfoModel.FirstName) == false) {
        this.ShowErrorMessage('Name Must be in Alphabets Only');
        document.getElementById("txtAddEmployeeInfoFirstName").focus();
        return false;
      }
    }

  }
  onEmployeeNumber(value: string) {
    this.AddEmployeeInfoModel.EmployeeNumber = "KJS00" + this.AddEmployeeInfoModel.AccessID;
  }
  onMiddleName(value: string) {
    //var re = /^[a-zA-Z]+$/;
    var re = /^[a-zA-Z ]*$/;
    if (this.hasValue(this.AddEmployeeInfoModel.MiddleName)) {
      if (re.test(this.AddEmployeeInfoModel.MiddleName) == false) {
        this.ShowErrorMessage('Name Must be in Alphabets Only');
        document.getElementById("txtAddEmployeeInfoMiddleName").focus();
        return false;
      }
    }

  }
  onLastName(value: string) {
    //var re = /^[a-zA-Z]+$/;
    var re = /^[a-zA-Z ]*$/;
    if (this.hasValue(this.AddEmployeeInfoModel.LastName)) {
      if (re.test(this.AddEmployeeInfoModel.LastName) == false) {
        this.ShowErrorMessage('Name Must be in Alphabets Only');
        document.getElementById("txtAddEmployeeInfoLastName").focus();
        return false;
      }
    }

  }
  // onMotherName(value:string){
  //   if(this.hasValue(this.AddEmployeeInfoModel.MotherName)){
  //     if(this.ree.test(this.AddEmployeeInfoModel.MotherName)==false){
  //       this.ShowErrorMessage('Name Must be in Alphabets Only');
  //       document.getElementById("txtAddEmployeeInfoMotherName").focus();
  //       return false;
  //     }
  //   }
  // }
  // onFatherName(value:string){
  //   if(this.hasValue(this.AddEmployeeInfoModel.FatherName)){
  //     if(this.ree.test(this.AddEmployeeInfoModel.FatherName)==false){
  //       this.ShowErrorMessage('Name Must be in Alphabets Only');
  //       document.getElementById("txtAddEmployeeInfoFatherName").focus();
  //       return false;
  //     }
  //   }
  // }

  onZipCode(value: string) {
    var re = /^\d{6}$/;
    if (this.hasValue(this.AddEmployeeInfoModel.PinCode)) {
      if (re.test(this.AddEmployeeInfoModel.PinCode) == false) {
        this.ShowErrorMessage('Please Enter Six Digits ZipCode');
        document.getElementById("txtAddEmployeeInfoZipCode").focus();
        return false;
      }
    }
  }
  onMobilePhone(value: string) {
    //var re = /^\(?\d{3}\)?-?\s*-?\d{4}$/;
    var re = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (this.hasValue(this.AddEmployeeInfoModel.Mobile)) {
      if (re.test(this.AddEmployeeInfoModel.Mobile) == false) {
        this.ShowErrorMessage('Please Enter Valid Phone Number');
        document.getElementById("txtAddEmployeeInfoMobilePhone").focus();
        return false;
      }
    }
  }
  onSecondaryMobile(value: string) {
    //var re = /^\(?\d{3}\)?-?\s*-?\d{4}$/;
    var re = /^(\+\d{1,3}[- ]?)?\d{10}$/
    if (this.hasValue(this.AddEmployeeInfoModel.SecondaryMobile)) {
      if (re.test(this.AddEmployeeInfoModel.SecondaryMobile) == false) {
        this.ShowErrorMessage('Please Enter Valid Phone Number');
        document.getElementById("txtAddEmployeeInfoSecondaryMobile").focus();
        return false;
      }
    }
  }
  onAccessID(value: string) {
    //var re = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    // var re =/^\d{10}$/;
    var re = /^\d{4}$/;
    if (this.hasValue(this.AddEmployeeInfoModel.AccessID)) {
      if (re.test(this.AddEmployeeInfoModel.AccessID) == false) {
        this.ShowErrorMessage('Please Enter Four Digits AccessID');
        document.getElementById("txtAddEmployeeInfoAccessID").focus();
        return false;
      }
      if (this.hasValue(this.AddEmployeeInfoModel.AccessID)) {
        this.AddEmployeeInfoModel.EmployeeNumber = "KJS00" + this.AddEmployeeInfoModel.AccessID;
      }
    }
  }
  onProfessionalMail(value: string) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.hasValue(this.AddEmployeeInfoModel.ProfessionalEmailAddress)) {
      if (re.test(this.AddEmployeeInfoModel.ProfessionalEmailAddress) == false) {
        this.ShowErrorMessage('Please Enter Valid Email Address');
        document.getElementById("txtAddEmployeeInfoProfessionalEmailAddress").focus();
        return false;
      }
    }
  }
  onITSPassword(value: any) {
    //var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    // var re = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    // var re = /(?=.*\d)(?=.*[a-z])(?=.*[!@$%^*_|]).{8}/;
    var re = /^(?=.*[a-zA-Z])(?=.*?[0-9])(?=.*[!@#$%^&*+=]).{8,}$/;
    if (this.hasValue(this.AddEmployeeInfoModel.Password)) {
      if (this.AddEmployeeInfoModel.Password.length < 8) {
        this.ShowErrorMessage("Password Should Contains Minimum Eight Characters");
        return false;
      }
      if (re.test(this.AddEmployeeInfoModel.Password) == false) {
        //this.ShowErrorMessage('The Password Must Contain atleast One lowercase, One uppercase, One numeric and a Special Character');
        this.ShowErrorMessage("Password Should Contain Atleast One letter, One Number and One Special Character");
        document.getElementById("txtAddEmployeeInfoITSPassword").focus();
        return false;
      }
    }
  }
  addEmployeeInfoSaveClick() {

    // if(this.hasValue(this.AddEmployeeInfoModel.BloodGroupID))
    // {
    // this.AddEmployeeInfoModel.BloodGroupID= this.AddEmployeeInfoModel.BloodGroupID.BloodGroupID
    // }
    if (!this.hasValue(this.AddEmployeeInfoModel.FirstName)) {
      this.ShowErrorMessage('Please Enter First Name');

      document.getElementById("txtAddEmployeeInfoFirstName").focus();
      return;
    }
    else {
      if (this.onFirstName(this.AddEmployeeInfoModel.FirstName) == false) {
        return;
      }
    }

    if (this.hasValue(this.AddEmployeeInfoModel.MiddleName)) {
      if (this.onMiddleName(this.AddEmployeeInfoModel.MiddleName) == false) {
        return;
      }

    }


    if (!this.hasValue(this.AddEmployeeInfoModel.LastName)) {
      this.ShowErrorMessage('Please Enter last Name');
      document.getElementById("txtAddEmployeeInfoLastName").focus();
      // this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforLastName').focus();
      return;
    }
    else {
      if (this.onLastName(this.AddEmployeeInfoModel.LastName) == false) {
        return false;
      }
    }
    if (!this.hasValue(this.AddEmployeeInfoModel.GenderID)) {
      this.ShowErrorMessage('Please Select Gender');
      // document.getElementById("myDropdown").focus();
      const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'divGenderName' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }
    // else{
    //   this.AddEmployeeInfoModel.GenderID=this.AddEmployeeInfoModel.genderid.GenderID
    // }
    if (!this.hasValue(this.AddEmployeeInfoModel.DateOfBirth)) {
      this.ShowErrorMessage('Please Enter Date of Birth');
      // document.getElementById("txtAddEmployeeInfoDateOfBirth").focus();
      const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'txtAddEmployeeInfoDateOfBirthname' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      // this.ViewAccessInformationPopup.nativeElement.querySelector('txtAddEmployeeInfoDateOfBirth').focus();
      return;
    }
    else {
      this.AddEmployeeInfoModel.DateOfBirth = this.formatValue(this.AddEmployeeInfoModel.DateOfBirth)
    }
    const daysDifference = this.DateDiffInDays(new Date(), this.AddEmployeeInfoModel.DateOfJoining);
    const DaysDifference = this.DateDiffInDays(new Date(), this.AddEmployeeInfoModel.DateOfBirth);

    if (this.hasValue(DaysDifference)) {
      if (DaysDifference <= daysDifference) {
        this.ShowErrorMessage('Date of Birth Should Not be Greater than or Equal to Date of Joining ');
        // const elementFound = this.CreateTaskInfo.nativeElement.querySelector('[name="' + 'TaskAddNewExpectedDeliveryDatename' + '"]');
        // if (this.hasValue(elementFound)) {
        //   elementFound.children[0].children[0].children[0].children[0].focus();
        // }
        const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'txtAddEmployeeInfoDateOfBirthname' + '"]');
        if (this.hasValue(elementFound)) {
          elementFound.children[0].children[0].children[0].children[0].focus();
        }
        return;
      }
      if (DaysDifference <= 0) {
        this.ShowErrorMessage('Date of Birth Should Not be Greater than or equal to Current Date ');
        const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'txtAddEmployeeInfoDateOfBirthname' + '"]');
        if (this.hasValue(elementFound)) {
          elementFound.children[0].children[0].children[0].children[0].focus();
        }
        return;
      }
    }
    if (!this.hasValue(this.AddEmployeeInfoModel.Address)) {
      this.ShowErrorMessage('Please Enter Address');
      document.getElementById("txtAddEmployeeInfoAddress").focus();
      //this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforFirstName').focus();
      return;
    }
    if (!this.hasValue(this.AddEmployeeInfoModel.Street1)) {
      this.ShowErrorMessage('Please Enter Street1');
      document.getElementById("txtAddEmployeeInfoStreet1").focus();
      // this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforFirstName').focus();
      return;
    }
    if (!this.hasValue(this.AddEmployeeInfoModel.StateID)) {
      this.ShowErrorMessage('Please Select State');
      const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'divStateName' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      // document.getElementById("StateDescription").focus();
      //this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforFirstName').focus();
      return;
    }
    // else{
    //   this.AddEmployeeInfoModel.StateID= this.AddEmployeeInfoModel.Stateid.StateID
    // }
    if (!this.hasValue(this.AddEmployeeInfoModel.DistrictID)) {
      this.ShowErrorMessage('Please Select District');

      const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'divDistrictName' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      // document.getElementById("DistrictDescription").focus();
      //this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforFirstName').focus();
      return;
    }
    // else{
    //   this.AddEmployeeInfoModel.DistrictID=this.AddEmployeeInfoModel.districtid.DistrictID
    // }
    if (!this.hasValue(this.AddEmployeeInfoModel.CityID)) {
      this.ShowErrorMessage('Please Select City ');
      const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'divCityName' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      // document.getElementById("CityDescription").focus();
      // this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforFirstName').focus();
      return;
    }
    // else{
    //   this.AddEmployeeInfoModel.CityID= this.AddEmployeeInfoModel.Cityid.CityID
    // }
    if (this.hasValue(this.AddEmployeeInfoModel.PinCode)) {
      if (this.onZipCode(this.AddEmployeeInfoModel.PinCode) == false) {
        return;
      }
    }
    if (!this.hasValue(this.AddEmployeeInfoModel.Mobile)) {
      this.ShowErrorMessage('Please Enter Mobile Number');
      document.getElementById("txtAddEmployeeInfoMobilePhone").focus();
      this.ViewAccessInformationPopup.nativeElement.querySelector('txtAddEmployeeInfoMobilePhone').focus();
      return;
    }
    else {

      if (this.onMobilePhone(this.AddEmployeeInfoModel.Mobile) == false) {
        return;
      }
    }
    if (this.hasValue(this.AddEmployeeInfoModel.SecondaryMobile)) {
      if (this.onSecondaryMobile(this.AddEmployeeInfoModel.SecondaryMobile) == false) {
        return;
      }
    }

    if (!this.hasValue(this.AddEmployeeInfoModel.PersonalMail)) {
      this.ShowErrorMessage('Please Enter Email');
      document.getElementById("txtAddEmployeeInfoPersonalEMail").focus();
      // this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforFirstName').focus();
      return;
    }
    else {

      if (this.onPersonalMail(this.AddEmployeeInfoModel.PersonalMail) == false) {
        return;
      }
    }
    if (!this.hasValue(this.AddEmployeeInfoModel.AccessID)) {
      this.ShowErrorMessage('Please Enter AccessID');
      document.getElementById("txtAddEmployeeInfoAccessID").focus();
      //this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforFirstName').focus();
      return;

    }
    else (this.hasValue(this.AddEmployeeInfoModel.AccessID))
    {
      if (this.hasValue(this.EmployeeInformationModellist)) {
        for (let AccessIDfound = 0; AccessIDfound < this.EmployeeInformationModellist.length; AccessIDfound++) {
          if (this.EmployeeInformationModellist[AccessIDfound].AccessID == this.AddEmployeeInfoModel.AccessID) {
            this.ShowErrorMessage('AccessId already exits');
            document.getElementById("txtAddEmployeeInfoAccessID").focus();
            return;

          }

        }

      }

    }
    if (this.hasValue(this.AddEmployeeInfoModel.AccessID)) {

      if (this.onAccessID(this.AddEmployeeInfoModel.AccessID) == false) {
        return;
      }
    }
    if (!this.hasValue(this.AddEmployeeInfoModel.EmployeeNumber)) {


      this.AddEmployeeInfoModel.EmployeeNumber == "KJS00" + this.AddEmployeeInfoModel.AccessID;

    }

    if (!this.hasValue(this.AddEmployeeInfoModel.DateOfJoining)) {
      this.ShowErrorMessage('Please Enter Date of Joining');
      //document.getElementById("txtAddEmployeeInfoDateOfJoining").focus();
      const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'txtAddEmployeeInfoDateOfJoining' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      //this.ViewAccessInformationPopup.nativeElement.querySelector('#txtAddEmployeeInfoDateOfJoining').focus();
      return;
    }
    else {
      this.AddEmployeeInfoModel.DateOfJoining = this.formatValue(this.AddEmployeeInfoModel.DateOfJoining)
    }
    //const daysDifference = this.DateDiffInDays(new Date(), this.AddEmployeeInfoModel.DateOfJoining);

    if (!this.hasValue(this.AddEmployeeInfoModel.DepartmentID)) {
      this.ShowErrorMessage('Please Select Department');
      // document.getElementById("DepartmentDescription").focus();
      const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'divDepartmentName' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }

      //this.ViewAccessInformationPopup.nativeElement.querySelector('#DepartmentDescription').focus();
      return;
    }
    // else{
    //   this.AddEmployeeInfoModel.DepartmentID= this.AddEmployeeInfoModel.Departmentid.DepartmentID
    // }
    if (!this.hasValue(this.AddEmployeeInfoModel.DesignationID)) {
      this.ShowErrorMessage('Please Select Designation');
      const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'divDesignationName' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus()
      }
      //document.getElementById("DesignationDescription").focus();
      //this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforFirstName').focus();
      return;
    }
    // else{
    //   this.AddEmployeeInfoModel.DesignationID=this.AddEmployeeInfoModel.Designationid.DesiginationId
    // }
    if (!this.hasValue(this.AddEmployeeInfoModel.JobTypeID)) {
      this.ShowErrorMessage('Please Select Job Type');
      //document.getElementById("JobTypeDescription").focus();

      const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'divJobTypeName' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      //this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforFirstName').focus();
      return;
    }
    // else{
    //   this.AddEmployeeInfoModel.JobTypeID=this.AddEmployeeInfoModel.jobTypeid.JobTypeID
    // }
    if (!this.hasValue(this.AddEmployeeInfoModel.ProfessionalEmailAddress)) {
      this.ShowErrorMessage('Please Enter Professional Email');
      document.getElementById("txtAddEmployeeInfoProfessionalEmailAddress").focus();
      //this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforFirstName').focus();
      return;
    }
    else {

      if (this.onProfessionalMail(this.AddEmployeeInfoModel.ProfessionalEmailAddress) == false) {
        return;
      }

    }
    if (!this.hasValue(this.AddEmployeeInfoModel.Password)) {
      this.ShowErrorMessage('Please Enter ITS Password');
      document.getElementById("txtAddEmployeeInfoITSPassword").focus();
      //this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforFirstName').focus();
      return;
    }
    else {
      if (this.onITSPassword(this.AddEmployeeInfoModel.Password) == false) {
        return;
      }
    }
    if (!this.hasValue(this.AddEmployeeInfoModel.Employee_Designation_ID)) {
      this.ShowErrorMessage('Please Select Employee Designation Type');
      //document.getElementById("JobTypeDescription").focus();

      const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'divEmployeeDesignationID' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      //this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforFirstName').focus();
      return;
    }
    if (!this.hasValue(this.AddEmployeeInfoModel.LoginTypeID)) {
      this.ShowErrorMessage('Please Select Employee Type');
      //document.getElementById("JobTypeDescription").focus();

      const elementFound = this.AddEmployeeInfo.nativeElement.querySelector('[name="' + 'divAddEmployeeInfoEmployeeType' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      //this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforFirstName').focus();
      return;
    }
    // if (!this.hasValue(this.AddEmployeeInfoModel.MotherName)) {
    //   this.ShowErrorMessage('Please Enter Mother Name');
    //   document.getElementById("txtAddEmployeeInfoMotherName").focus();
    //   // this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforLastName').focus();
    //   return;
    // }
    // else {
    //   if (this.onMotherName(this.AddEmployeeInfoModel.MotherName) == false) {
    //     return false;
    //   }
    // }

    // if (!this.hasValue(this.AddEmployeeInfoModel.FatherName)) {
    //   this.ShowErrorMessage('Please Enter Father Name');
    //   document.getElementById("txtAddEmployeeInfoFatherName").focus();
    //   // this.ViewAccessInformationPopup.nativeElement.querySelector('#EmployeeInforLastName').focus();
    //   return;
    // }
    // else {
    //   if (this.onFatherName(this.AddEmployeeInfoModel.FatherName) == false) {
    //     return false;
    //   }
    // }

    // if(this.hasValue(this.AddEmployeeInfoModel.MarriageDate)){
    //   this.AddEmployeeInfoModel.MarriageDate=this.formatValue(this.AddEmployeeInfoModel.MarriageDate)
    // }
    // let PostData = {
    //   employeesinformationaddmodel: this.AddEmployeeInfoModel,
    // }
    //employeesinformationaddmodel=this.AddEmployeeInfoModel;
    // let postdata={
    //   FirstName:"lakshmi",
    // }
    //postdata = this.AddEmployeeInfoModel;
    // if(this.hasValue(this.SelectedEmployeeEditInformation)){
    //   this.AddEmployeeInfoModel.Password="";
    // }
    if (!this.hasValue(this.SelectedEmployeeEditInformation)&&!(this.hasValue(this.oasMainDashboardProfile))) {
      this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'SaveEmployeeAddDetails', this.AddEmployeeInfoModel).subscribe(serviceResponse => {
        if (this.isError(serviceResponse)) {
          this.AddEmployeeInfoModel = {};
          return;
        }
        this.ShowSuccesseMessage('Employee Details Saved Successfully.');

        this.dialog.close();
      })
    }
    //if (this.hasValue(this.SelectedEmployeeEditInformation) || this.hasValue(this.oasMainDashboardProfile)) {
      else{
      if (this.hasValue(this.SelectedEmployeeEditInformation)) {
        this.EmployeeInfoId = this.SelectedEmployeeEditInformation.EmployeeInfoID
      } else {
        this.EmployeeInfoId = this.oasMainDashboardProfile
      }

      this.AddEmployeeInfoModel.EmployeeInfoID = this.EmployeeInfoId

      //this.AddEmployeeInfoModel = this.SelectedEmployeeEditInformation.EmployeeInfoID
      this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'UpdateEmployeeAddDetails', this.AddEmployeeInfoModel).subscribe(serviceResponse => {
        if (this.isError(serviceResponse)) {
          this.AddEmployeeInfoModel = {};
          return;

        }
        this.ShowSuccesseMessage('Employee Details Updated Successfully.');

        this.dialog.close();
      })
    }
  };
  private GetGenderList() {

    var PostDataToService = {

    };
    this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'EmployeeInformationGenderList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.addEmployeeInfoGenderList = serviceResponse.GenderListModelList;
    });

  }

  private GetBloodGroupList() {

    var PostDataToService = {

    };
    this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'EmployeeInformationBloodGroupList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.addEmployeeInfoBloodGroupList = serviceResponse.BloodGroupModelList;
    });

  }
  private GetStateList() {
    // this.StateListValue=true;
    var PostDataToService = {

    };
    this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'EmployeeInformationStateList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.addEmployeeInfoStateList = serviceResponse.StateListModelList;
      if (this.hasValue(this.SelectedEmployeeEditInformation) || this.hasValue(this.oasMainDashboardProfile)) {
        this.onStateList(this.AddEmployeeInfoModel.StateID)
       // this.AddEmployeeInfoModel.Stateid = this.SelectedEmployeeEditInformation.Stateid.StateID
      }

    });


  }
  private GetDistrictList() {
    // this.CityListValue=true;
    // if(this.hasValue(this.SelectedEmployeeEditInformation)){
    //   if(this.hasValue(this.StateListValue)==true){
    //     this.AddEmployeeInfoModel.DistrictID=null;
    //   }
    // }

    var PostDataToService = {
      StateID: this.AddEmployeeInfoModel.StateID
    };
    this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'EmployeeInformationDistrictList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.addEmployeeInfoDistrictList = serviceResponse.DistrictListModelList;


    });

  }
  private GetCityList() {
    // if(this.hasValue(this.SelectedEmployeeEditInformation)){
    //   if(this.hasValue(this.StateListValue)==true){
    //     this.AddEmployeeInfoModel.CityID=null;
    //   }
    // }
    var PostDataToService = {
      DistrictID: this.AddEmployeeInfoModel.DistrictID
    };
    this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'EmployeeInformationCityList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.addEmployeeInfoCityList = serviceResponse.CityListModelList;
    });

  }
  private GetDepartmentList() {

    var PostDataToService = {

    };
    this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'EmployeeInformationDepartmentList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.addEmployeeInfoDepartmentList = serviceResponse.DepartmentListModelList;
    });

  }
  private GetDesignationList() {

    var PostDataToService = {
      DepartmentID : this.AddEmployeeInfoModel.DepartmentID
    };
    this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'EmployeeInformationDesignationList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.addEmployeeInfoDesignationList = serviceResponse.DesignationListModelList;
    });

  }
  private GetJobTypeList() {

    var PostDataToService = {

    };
    this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'EmployeeInformationJobTypeList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.addEmployeeInfoJobTypeList = serviceResponse.JobListModelList;
    });

  }

  private GetEmployeeDesignationList() {

    var PostDataToService = {

    };
    this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'EmployeeDesignationList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.employeeDesignationIDList = serviceResponse.EmployeeDesignationModelList;
    });

  }

  private GetEmployeeTypeList() {

    var PostDataToService = {

    };
    this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'EmployeeLoginTypeList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.employeeTypeList = serviceResponse.EmployeeTypeModelList;
    });

  }



  addEmployeeInfoCancelClickEvent() {
    this.dialog.close();
  }

}
