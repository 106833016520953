import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
} from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { AgGridNg2 } from "ag-grid-angular";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { commonHelperService } from "../common/common.service";
import { commonhelper, Safe, innerHTMLExists } from "../common/commonhelper";
import { map, tap } from "rxjs/operators";
import { pipe, forkJoin } from "rxjs";
import { Observable, observable } from "rxjs";
import { Config } from "../config";
import { Employee } from "../edit-task-information/edit-task-information.component";
import { Location } from "@angular/common";
import { EmployeeService } from "../employee.service";
import { SelectEvent } from "@progress/kendo-angular-layout";
import {
  FileInfo,
  RemoveEvent,
  CancelEvent,
} from "@progress/kendo-angular-upload";
import { DomSanitizer } from "@angular/platform-browser";
import { UploadState } from "../enums/upload-state.enum";
import {
  FileUploadProgressService,
  CommentFileUploadProgressService,
} from "../services/file-upload-progress.service";
import { TaskEventTypeEnum } from "../enums/TaskHistoryChangeEvent.enum.";
import { TaskChangeEvent } from "../interfaces/TaskChangeEvent";
import { AttachmentService } from "../services/attachment.service";
import { Attachment } from "../interfaces/attachment";
import { TaskService } from "../services/task.service";
import {
  DialogService,
  DialogCloseResult,
  DialogAction,
} from "@progress/kendo-angular-dialog";
import { ResponsiblepersonUsersListComponent } from "../responsibleperson-users-list/responsibleperson-users-list.component";
import { UncustomizedtaskCommentsComponent } from "../uncustomizedtask-comments/uncustomizedtask-comments.component";
import { TaskCommentAttachmentsCellrendererComponent } from "../task-comment-attachments-cellrenderer/task-comment-attachments-cellrenderer.component";
import { TaskCommentContentCellrendererComponent } from "../task-comment-content-cellrenderer/task-comment-content-cellrenderer.component";
import { SelectModulePopupComponentComponent } from "../select-module-popup-component/select-module-popup-component.component";
import { IntlService } from "@progress/kendo-angular-intl";
import { AdditionalParticipantAndObserversPopupComponentComponent } from "../additional-participant-and-observers-popup-component/additional-participant-and-observers-popup-component.component";
import { NewCustomerMailsSelectTemplatePopupComponent } from "../new-customer-mails-select-template-popup/new-customer-mails-select-template-popup.component";
import { EdituncustomizedtasklistService } from "./edituncustomizedtasklist.service";
import { TaskBucketDetailsService } from "../shared/services/task-bucket-details";
import { type } from "os";
// import { Safe } from '../common/commonhelper';

@Component({
  selector: "app-edituncustomizedtasklist",
  templateUrl: "./edituncustomizedtasklist.component.html",
  styleUrls: ["./edituncustomizedtasklist.component.css"],
  providers: [FileUploadProgressService, CommentFileUploadProgressService],
})
export class EdituncustomizedtasklistComponent extends commonhelper
  implements OnInit {
  SelectedAssistantPerson: any = [];
  IsresponsiblePersonAndFwdToSame: boolean = false;
  SelectedAssistantPersonName: string = "";
  PopupDataFromMultiSelectUserSelectionPopup: any;
  SelectedAssistantPersonInfo: any = {};
  SelectedObserversPersonNames: string;
  IsEditUncustomizedObserversInfoChanged: boolean;
  SelectedObserversInfo: any;
  SelectedAdditionalResponsiblePersonName: string;
  IsEditUncustomizedAdditionalResponsiblePersonInfoChanged: boolean;
  SelectedAdditionalResponsiblePersonInfo: any;
  TaskSubject: string;
  ShowHideSendresponsetoCustomerBtnFromEmail: boolean;
  isDisabled: boolean;

  ModuleInfo: DialogAction;
  // mainly used to post data to backend
  private commonhelperobj: commonHelperService;

  @ViewChild("EditUncustomizedTaskList") EditUncustomizedTaskList: ElementRef;

  @ViewChild("EditUncustomizedTaskList", { read: ViewContainerRef })
  public ContainerRef: ViewContainerRef;

  @ViewChild("viewCommentsGrid") viewCommentsGrid: AgGridNg2;
  public commentsGridOptions: GridOptions;
  public commentsGridRowData = [];
  isfromsendresponsetoCustomer: boolean = true;
  public viewModel = {
    observers: [],
    participants: [],
    responsiblePerson: {},
    ModuleInfo: {},
    priority: "",
    commentBody: "",
    AttachmentsModelList: [],
  } as any;
  responsiblePersonInfo: any;
  ModuleListInfo: any;
  organizationEmployees: Array<Employee>;
  List = [
    { List: "observers", ListName: "SelectedObserversPersonNames", type: 3 },
    {
      List: "participants",
      ListName: "SelectedAdditionalResponsiblePersonName",
      type: 4,
    },
    {
      List: "SelectedAssistantPerson",
      ListName: "SelectedAssistantPersonName",
      type: 7,
    },
    { List: "responsiblePersonInfo", ListName: "", type: 2 },
  ];
  VieworLoadUncustomizedpreviousCommentsBlock = false;
  showUncustomizedEditorOnClick = true;
  showUncustomizedCommentOnClick = false;
  IsuncustomizedEmailsTaskList = false;
  TaskDescriptionHideFromEmail = true;
  fullattachmentsList: any;
  commentscount: any = 0;
  ifCommentsCountisLessThanOne: boolean;
  taskBodyList: any;
  MainBodyAttachmentsList: string;
  MainBodyImagesAttachmentsList: any[];
  imagesattachmentslist: any[];
  AttachmentsList: any[] = [];
  TaskDescription: any;
  public AttachmentsHeaderHide: any = true;
  AttachmentslableHide = true;
  srcextractedlist: any;
  messagesList: any;
  CommentsAttachmentsList: string;
  CommentsImagesAttachmentsList: any;
  AttachmentsModelList: any = [];

  commentAttachmentsModel: any = [];

  commentsHide;
  dialogOpened;
  showHistoryGrid: any = false;

  myCommentsFiles: Array<FileInfo> = [];

  uploadSaveUrl = "saveUrl"; // should represent an actual API endpoint
  uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint
  taskImageSRC: any;
  taskDescription: any;
  moduleList: any = [];
  projectList: any = [];
  taskTypeList: any = [];
  TaskBucketList = [];
  TaskBucketCategoryList = [];
  IsEnableTaskCategory = true;
  public uploadedFiles = {} as any;
  // commentAttachmentsToUpload: any = [];
  filesCount: number;

  commentFilesCount = 0;

  @ViewChild("unCustomizedViewHistoryGrid")
  unCustomizedViewHistoryGrid: AgGridNg2;

  public gridOptions: GridOptions;

  public defaultUnCustomizedProjectItem = {
    Description: "-- Select --",
    ProjectId: null,
  };

  public defaultUnCustomizedModuleItem = {
    Description: "-- Select --",
    ModuleId: null,
  };

  public defaultUnCustomizedTaskTypeItem = {
    IssueSupportGroupDesc: "-- Select --",
    IssueSupportGroupID: null,
  };

  public defaultTaskBucketItem = {
    Name: "-- Select --",
    ID: null,
  };
  public defaultTaskBucketCategoryItem = {
    Name: "-- Select --",
    ID: null,
  };

  public defaultUnCustomizedResponsibleItem = {
    EmployeeFullName: "-- Select --",
    EmployeeId: null,
  };

  public pdfAttachments: Attachment[];
  historicalEventsCount: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private location: Location,
    private employeeService: EmployeeService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private uploadStateService: FileUploadProgressService,
    private commentFileUploadStateService: CommentFileUploadProgressService,
    private attachmentsService: AttachmentService,
    private taskService: TaskService,
    private dialogService: DialogService,
    private intl: IntlService,
    private _unCustomizedTaskListService: EdituncustomizedtasklistService,
    public _taskBucketDetailsService: TaskBucketDetailsService
  ) {
    super();
    this.commonhelperobj = new commonHelperService(http);
    this.TaskBucketList = this._taskBucketDetailsService.BucketNamesList;

    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this,
      },
      defaultColDef: {
        resizable: true,
      },
    };

    this.commentsGridOptions = <GridOptions>{
      context: {
        componentParent: this,
      },
      defaultColDef: {
        resizable: true,
      },
    };
  }

  columnDefs: any = [];

  rowData = [
    {
      DateID: "20/06/1998",
      CreatedBy: "ssss",
      UpdateDescription: "Update Description",
      Update: "anusha.chiruvolu@adaptamed.org",
    },
  ];

  onColumnResized() {
    //console.log('onColumnResized');
    this.gridOptions.api.resetRowHeights();
  }

  rowSelection = "single";

  commentsGridColumnDefs = [
    {
      headerName: "Created Date",
      field: "CommentCreatedOn",
      width: 150,
      tooltipField: "CommentCreatedOn",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellRenderer: this.dateTimeFormatCellRenderer,
    },
    {
      headerName: "Created By",
      field: "CreatedBy",
      width: 200,
      tooltipField: "CreatedBy",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Attachments",
      field: "commentsattachmentslist",
      width: 400,
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellRenderer: this.commentAttachmentCellRenderer,
      //cellRendererFramework:TaskCommentAttachmentsCellrendererComponent
    },
    {
      headerName: "Content",
      field: "messageinfo",
      width: 800,
      tooltipField: "messageinfo",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellRenderer: this.commentContentCellRenderer,
    },
  ];

  //to display the content in rich text HTML
  commentContentCellRenderer(params) {
    return params.value;
  }

  commentAttachmentCellRenderer(params) {
    //console.log('commentAttachmentCellRenderer');
    //console.log(params);

    var commentsattachmentslist: any = params.value;
    //console.log(commentsattachmentslist);
    //console.log(commentsattachmentslist.length);
    var resultElement = document.createElement("span");
    /*commentsattachmentslist.array.forEach(element => {
        var imageElement = document.createElement("img");
        imageElement.src = element.Imgaetag;
        imageElement.height = 25;
        imageElement.width = 25;
        resultElement.appendChild(imageElement);
    });*/
    for (var i = 0; i < commentsattachmentslist.length; i++) {
      var imageElement = document.createElement("img");
      imageElement.src = commentsattachmentslist[i].Imgaetag;
      imageElement.height = 25;
      imageElement.width = 25;
      var clickEventString =
        "open('dialog', " + commentsattachmentslist[i].Imgaetag + ")";
      // console.log('clickEventString - ' + clickEventString);
      imageElement.setAttribute("onclick", clickEventString); // 'alert("You picked the Queen of Hearts!")');
      resultElement.appendChild(imageElement);
    }

    return resultElement;

    /*var template : string ='';
    commentsattachmentslist.forEach(element => {
      template = template + "<span style='margin-right: 15px;'> " +
            "<img [src]='" + element.Imgaetag + "' height='25' width='25' style='cursor: pointer;' " +
            "(click)='open('dialog'," + element.Imgaetag + ")/>" +
          "</span>"
      debugger;*/
    /*template = template + "<span style='margin-right: 15px;'> " +
          "<a (click)=open('dialog'," + element.Imgaetag + ") <i class='fa fa-file-image-o' aria-hidden='true'></i></a>" +
        "</span>";
  });
  console.log(template);
  return template;*/
  }

  createImageSpan(imageMultiplier, image) {
    var resultElement = document.createElement("span");
    for (var i = 0; i < imageMultiplier; i++) {
      var imageElement = document.createElement("img");
      imageElement.src =
        "https://raw.githubusercontent.com/ag-grid/ag-grid/master/packages/ag-grid-docs/src/images/" +
        image;
      resultElement.appendChild(imageElement);
    }
    return resultElement;
  }

  ngOnInit() {
    $("#div-task-history").addClass("CommentsBackgroungClass");
    this.route.params.subscribe((routeParams) => {
      // get data passed from calling route
      const data = this.getCommonData(routeParams.info)["data"];

      this.IsuncustomizedEmailsTaskList = data.IsFromEmailUncustomizedList;

      if (
        this.hasValue(this.IsuncustomizedEmailsTaskList) &&
        this.IsuncustomizedEmailsTaskList === true
      ) {
        this.TaskDescriptionHideFromEmail = false;
        this.isDisabled = false;
        this.ShowHideSendresponsetoCustomerBtnFromEmail = true;
      } else {
        this.TaskDescriptionHideFromEmail = false;
        this.isDisabled = true;
        this.ShowHideSendresponsetoCustomerBtnFromEmail = false;
      }

      // alert(this.IsuncustomizedEmailsTaskList);

      // this.viewModel.IssueCreatedOn = this.formatDateTime(data.IssueCreatedOn + ' UTC', "MM/dd/yyyy hh:mm tt");
      this.viewModel.IssueCreatedOn = this.formatDateTime(
        data.IssueCreatedOn,
        "MM/dd/yyyy hh:mm tt"
      );
      this.viewModel.IssueSubject = data.IssueSubject;
      this.viewModel.SeverityDesc = data.SeverityDesc;
      this.viewModel.SupportGroupDesc = data.SupportGroup;
      this.viewModel.SupportGroupID = data.IssueSupportGroupID;
      this.viewModel.TaskTypeDesc=data.TaskType;
// console.log(data.TaskType)
      this.TaskSubject = "Task #" + data.TaskNumber + "-" + data.IssueSubject;

      const [responsiblePerson, taskParticipants, taskObservers] = [{}, [], []];

      this.viewModel.participants = taskParticipants;
      this.viewModel.observers = taskObservers;
      this.viewModel.responsiblePerson = responsiblePerson;

      this.viewModel.TaskInfoID = data.TaskInfoID;
      this.viewModel.CreatedBy = data.CreatedBy;
      this.viewModel.PracticeName = data.PracticeName;
      this.viewModel.EHRPracticeID = data.EHRPracticeID;
      this.viewModel.TaskTypeId = data.TaskTypeId;
      this.viewModel.IsPublicTask = data.IsPublicTask;
      this.viewModel.UserId = data.UserId;
      this.viewModel.TaskNumber = data.TaskNumber;

      // console.log(this.viewModel,"ViewModel")
      this.employeeList().subscribe();

      this.GetTaskBodyList();

      //this.loadUncustomizedPreviousCommentsClick(false);

      this.getModules().subscribe();

      this.getProjects().subscribe();


      // tap(() => this.viewModel.TaskType = this.taskTypeList.find(task => +task.TaskTypeId === data.TaskTypeId))

      //https://itslocal.ehryourway.com/OASApplicationMainAPI/OasAppMainAPi/OASIssueSupportGroupsListView

      this.getSupportedRelatedTypes().subscribe(() => {
        this.viewModel.TaskType = this.taskTypeList.find(
          (task) => +task.IssueSupportGroupID === data.IssueSupportGroupID
        );
        // console.log(this.viewModel.TaskType,this.taskTypeList)
      });


      this.populateHistoryTable();
    });
  }

  uncustomizedTaskListSelectTemplateLinkClick() {
    const dialogRef = this.dialogService.open({
      title: "Select Template",
      // Show component
      content: NewCustomerMailsSelectTemplatePopupComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if (this.hasValue(result)) {
          this.getSelectedTemplateInfo(result);
          this.getSelectedTemplateLinkedUsersInfo(result);
        }
      }
    });
  }
  private async getSelectedTemplateInfo(SelectedTemplatedId) {
    const response = await this._unCustomizedTaskListService
      .GetNewCustomerMailTemplate({
        NewCustomerMailTemplateInfoId: SelectedTemplatedId,
      })
      .toPromise();
    if (this.isError(response)) return;
    this.assignDatatoFieldsinEditMode(response);
  }
  private async getSelectedTemplateLinkedUsersInfo(SelectedTemplatedId) {
    const response = await this._unCustomizedTaskListService
      .GetNewCustomerMailTemplateLinkedUsersList({
        NewCustomerMailTemplateInfoId: SelectedTemplatedId,
      })
      .toPromise();
    if (this.isError(response)) return;
    this.groupEmployeesBasedonRoles(response.TemplateUserslist);
  }
  private assignDatatoFieldsinEditMode(data) {
    (this.viewModel.ModuleName = data.ModuleDescription),
      (this.viewModel.ModuleId = data.ModuleID);
  }
  private groupEmployeesBasedonRoles(employeesList) {
    this.List.forEach((element) => {
      this[element.List] = this.viewModel[element.List] = employeesList.filter(
        (participant) => participant.EmployeeType == element.type
      );
    });
    this.assignEmployeeNamestoFormControl();
  }
  private assignEmployeeNamestoFormControl() {
    this.List.forEach((element) => {
      this.assignEmployeeNameswithCommaSeperated(
        this[element.List],
        element.ListName
      );
    });
    this.viewModel.responsiblePerson.EmployeeId = this.responsiblePersonInfo[0].EmployeeId;
    this.viewModel.responsiblePerson.EmployeeFullName = this.responsiblePersonInfo[0].EmployeeFullName;
  }
  assignEmployeeNameswithCommaSeperated(employeesList, elementName) {
    this[elementName] = employeesList
      .map(function (item) {
        return item.EmployeeFullName;
      })
      .join(", ");
  }
  private populateHistoryTable() {
    this.columnDefs = [
      {
        headerName: "Date",
        field: "CreatedOn",
        tooltipField: "CreatedOn",
        minWidth: 80,
        maxWidth: 80,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
        cellRenderer: this.dateTimeFormatCellRenderer,
      },
      {
        headerName: "Created By",
        field: "CreatedBy",
        tooltipField: "CreatedBy",
        minWidth: 140,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
      },
      {
        headerName: "Update Disposition",
        field: "EventName",
        tooltipField: "EventName",
        minWidth: 160,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
      },
      {
        headerName: "Update",
        field: "Description",
        tooltipField: "Description",
        width: 530,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
      },
    ];

    this.commonhelperobj
      .PostData(`${Config.OASAppnURL}TaskHistoryList`, {
        TaskInfoID: this.viewModel.TaskInfoID,
      })
      .subscribe((response) => {
        const history = response.TaskHistoryList as Array<any>;

        history.forEach((event) => {
          event.Description = this.parseEventDescription(event.Description);
        });

        this.rowData = history;
        this.gridOptions.onGridReady = () => {
          // this.gridOptions.columnApi.autoSizeAllColumns();
          // this.gridOptions.api.sizeColumnsToFit();
          // window.onresize = (() =>
          //   this.gridOptions.api.sizeColumnsToFit()
          // )
        };
        this.historicalEventsCount = response.TaskHistoryList.length;
      });
  }

  /**
   * @author Sanjay Idpuganti
   */
  private parseEventDescription(description: string): string {
    if (!description.includes(description)) {
      return description;
    }

    if (!description.includes("-->")) {
      return description;
    }

    const [previous, changed] = description
      .split("-->")
      .map((value) => value.trim());

    const previousContents: Array<string> = [];
    const changedContents: Array<string> = [];

    if (previous) {
      // contains only one value
      if (previous.includes(",")) {
        previous
          .split(",")
          .map((content) => previousContents.push(content.split("|")[1]));
      } else {
        previousContents.push(previous.split("|")[1]);
      }
    }

    if (changed) {
      // contains only one value
      if (changed.includes(",")) {
        changed
          .split(",")
          .map((content) => changedContents.push(content.split("|")[1]));
      } else {
        changedContents.push(changed.split("|")[1]);
      }
    }

    let previousDescription = "";

    if (previousContents.length === 1) {
      previousDescription = previousContents[0];
    } else if (previousContents.length > 1) {
      previousDescription = previousContents.join(",");
    }

    let changedDescription = "";

    if (changedContents.length === 1) {
      changedDescription = changedContents[0];
    } else if (changedContents.length > 1) {
      changedDescription = changedContents.join(",");
    }

    if (!changedDescription && previousDescription) {
      return `Removed ${previousDescription}`;
    }

    if (changedDescription && !previousDescription) {
      return `Added ${changedDescription}`;
    }

    return previousDescription + " --> " + changedDescription;
  }

  onSelectionChanged(event) {}

  LoadPreviousCommentsInformation() {
    const postData = {} as any;
    if (
      this.hasValue(this.viewModel) &&
      this.hasValue(this.viewModel.TaskInfoID)
    ) {
      postData.TaskInfoID = this.viewModel.TaskInfoID;
      postData.isnotfromEhr = true;
    }
    (postData.LoggedInFromPractice = this.isfromsendresponsetoCustomer),
      this.commonhelperobj
        .PostData(
          Config.OASOrganizationAppnURL + "EmployeeTaskAttachmentList2",
          postData
        )
        .subscribe((serviceResponse) => {
          if (this.isError(serviceResponse)) {
            return;
          }

          // get all attachments of the task
          // TODO: Make local object
          this.fullattachmentsList = serviceResponse.AttachmentsModelList;

          this.getCommentsAttachments();
          // this.getCommentsImagesAndAttachments();
        });
  }

  loadUncustomizedPreviousCommentsClick(IsLoadPreviousComments) {
    if (IsLoadPreviousComments === true) {
      this.VieworLoadUncustomizedpreviousCommentsBlock = true;
      this.LoadPreviousCommentsInformation();
    } else {
      this.VieworLoadUncustomizedpreviousCommentsBlock = false;
      this.GetTaskBodyList();
    }
  }

  addCommentUncustomizedClickEvent() {
    this.showUncustomizedEditorOnClick = false;
    this.showUncustomizedCommentOnClick = true;
  }

  cancelUncustomizedCommentClickEvent() {
    this.showUncustomizedEditorOnClick = true;
    this.showUncustomizedCommentOnClick = false;

    this.myCommentsFiles = [];

    this.viewModel.commentBody = "";
  }

  /**
   * Get organization employees
   * @author Sanjay Idpuganti
   */
  employeeList(): Observable<Array<any>> {
    const PostDataToService = {};
    return this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "OrganizationEmployeesList",
        PostDataToService
      )
      .pipe(
        map((response) =>
          this.checkForErrorAndAssingToEmployeeListAndReturn(response)
        )
      );
  }

  private checkForErrorAndAssingToEmployeeListAndReturn(response) {
    // check for response error
    if (this.isError(response)) {
      return [];
    }
    // assign to respective list
    this.organizationEmployees = response.EmployeeList;
    // and return
    //console.log(this.organizationEmployees);
    return this.organizationEmployees;
  }

  async onCommentSendButtonClick() {
    if (!this.commentFileUploadStateService.isValidState()) {
      this.ShowErrorMessage("Please Wait till the files are uploaded");
      return;
    }

    const postData = {
      TaskInfoID: this.viewModel.TaskInfoID,
      AttachmentsModelList: [],
    } as any;

    const commentAttachmentContainer = await this.attachmentsService.processUploadAttachments(
      this.myCommentsFiles
    );
    //console.log(commentAttachmentContainer);

    if (
      !this.hasValue(this.viewModel.commentBody) ||
      !innerHTMLExists(this.viewModel.commentBody, "p")
    ) {
      this.ShowErrorMessage("Please Enter Comment Details");
      const elementFound = this.EditUncustomizedTaskList.nativeElement.querySelector(
        '[name="' + "EditUncustomizedTaskCommentsDesc" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[1].children[0].focus();
      }
      return;
    }

    if (this.hasValue(this.viewModel.commentBody)) {
      const attachment = {
        AttachmentBase64Format: window.btoa(
          unescape(encodeURIComponent(this.viewModel.commentBody))
        ),
        AttachmentType: 1,
      };

      commentAttachmentContainer.push(attachment);

      if (
        this.attachmentsService.duplicatesExists(
          commentAttachmentContainer as Array<Attachment>
        )
      ) {
        this.ShowErrorMessage(
          "Duplicates Exists in Uploaded Attachments Please Try Again."
        );
        return;
      }

      postData.AttachmentsModelList = commentAttachmentContainer;

      const taskChangeEvent: TaskChangeEvent = {
        Description: `Comment Added`,
        EventId: TaskEventTypeEnum.Comments,
        TaskInfoId: this.viewModel.TaskInfoId,
      };

      postData.TaskChangeEvents = [taskChangeEvent];

      this.commonhelperobj
        .PostData(Config.OASAppnURL + "InsertCommentsInformation", postData)
        .subscribe((serviceResponse) => {
          if (this.isError(serviceResponse)) {
            return;
          }
          this.ShowSuccesseMessage("Task Comments Added SuccessFully.");
          this.myCommentsFiles = [];
          this.viewModel.commentBody = "";
          this.viewModel.attachments = [];

          this.showUncustomizedEditorOnClick = true;
          this.showUncustomizedCommentOnClick = false;

          this.VieworLoadUncustomizedpreviousCommentsBlock = false;

          this.GetTaskBodyList();

          this.populateHistoryTable();
        });
    }
  }

  editUncustomizedResponsiblePersonClose() {}

  // private loadTaskComments() {
  //   const postData = {
  //     TaskInfoID: this.viewModel.TaskInfoID
  //   } as any;

  //   this.commonhelperobj.PostData(Config.OASOrganizationAppnURL + 'EmployeeTaskAttachmentList1', postData).subscribe(serviceResponse => {
  //     if (this.isError(serviceResponse)) { return; }

  //     const comments = serviceResponse.AttachmentsModelList
  //       .filter(comment => comment.AttachmentType === 1 && comment.AttachmentUrlSequence >= 1);

  //     if (comments.length === 0) {
  //       return;
  //     }

  //     for (const comment of comments) {
  //       this.commonhelperobj.GetStringBytesFromSignedURL(comment.AttachmentURL, 'text/html')
  //         .subscribe(successData => {

  //           const commentAttachments = `<div> ${successData} </div>`;

  //           // object holds all the attachments / images of the comment
  //           const commentAttachments$ = $(commentAttachments).find('img');

  //           // as all the attachments / images of comment are stored in other object
  //           // remove all the images
  //           $(commentAttachments).find('img').remove();

  //           // this is used to display comment body
  //           comment.commentHTML = $(commentAttachments).html();

  //           for (let attachmentIndex = 0; attachmentIndex < commentAttachments$.length; attachmentIndex++) {
  //             const element = {
  //               imageTag: commentAttachments$[attachmentIndex].attributes['src'].value,
  //             };

  //             comment.attachments.push(this.cloneObject(element));
  //           }
  //         });
  //     }

  //   });
  // }

  onParticipantsChange() {
    this.viewModel.participants = this.employeeService.filterDuplicateEmployees(
      this.viewModel.participants
    );
  }

  onObserversChange() {
    this.viewModel.observers = this.employeeService.filterDuplicateEmployees(
      this.viewModel.observers
    );
  }

  open(component, imgeurl, Attchement) {
    // console.log(Attchement);
    if (Attchement.attachmentType === 10) {
      const downloadLink = document.createElement("a");
      downloadLink.href = imgeurl;
      downloadLink.download = Attchement.AttachmentName;
      downloadLink.click();
    } else {
      this[component + "Opened"] = true;

      this.TasksLoadImageURL(imgeurl);
    }
  }

  TasksLoadImageURL(imageURL) {
    this.taskImageSRC = imageURL;
  }

  close(component) {
    this[component + "Opened"] = false;
  }

  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "d")}` : "";
  }

  async onSaveButtonClickEvent() {
    if (
      !this.uploadStateService.isValidState() ||
      !this.commentFileUploadStateService.isValidState()
    ) {
      this.ShowErrorMessage("Please Wait till the files are uploaded");
      return;
    }

    // if (!this.hasValue(this.viewModel.ProjectId)) {
    //   this.ShowErrorMessage('Please select a project');
    //   const elementFound = this.EditUncustomizedTaskList.nativeElement.querySelector('[name="' + 'EditUncustomizedTaskProjectName' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].focus();
    //   }
    //   return;
    // }

    if (!this.hasValue(this.viewModel.TaskType.IssueSupportGroupID)) {
      this.ShowErrorMessage("Please Select a Module");
      const elementFound = this.EditUncustomizedTaskList.nativeElement.querySelector(
        '[name="' + "EditUncustomizedTaskSelectTaskType" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }

    if (
      this.hasValue(this.IsuncustomizedEmailsTaskList) &&
      this.IsuncustomizedEmailsTaskList === true
    ) {
      if (
        !this.hasValue(this.viewModel.TaskType) ||
        !this.hasValue(this.viewModel.TaskType.TaskTypeId)
      ) {
        this.ShowErrorMessage("Please Select Task Type.");
        // const elementFound = this.EditUncustomizedTaskList.nativeElement.querySelector('[name="' + 'EditUncustomizedTaskModuleName' + '"]');
        // if (this.hasValue(elementFound)) {
        //   elementFound.children[0].focus();
        // }
        return;
      }
    }

    //debugger;
    if (
      !this.hasValue(this.viewModel.responsiblePerson) ||
      !this.hasValue(this.viewModel.responsiblePerson.EmployeeId)
    ) {
      this.ShowErrorMessage("Please Select Responsible Person.");
      // tslint:disable-next-line:max-line-length
      const elementFound = this.EditUncustomizedTaskList.nativeElement.querySelector(
        '[name="' + "EditUncustomizedTaskResponsiblePerson" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }

    if (this.createdDateGreaterThanRequiredByDate()) {
      this.ShowErrorMessage(
        "Required By Date Should be Greater than or Equal to Created Date"
      );
      const elementFound = this.EditUncustomizedTaskList.nativeElement.querySelector(
        '[name="' + "EditUncustomizedTaskRequiredByDate" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return;
    }

    // const daysDifference = this.DateDiffInDays(new Date(), this.viewModel.RequiredByDate);
    // if (this.hasValue(daysDifference) && daysDifference > 0) {
    //   this.ShowErrorMessage('Expected Delivery Date Should be Greater than or Equal to Current Date.');
    //   const elementFound = this.EditUncustomizedTaskList.nativeElement.querySelector('[name="' + 'TaskAddNewExpectedDeliveryDatename' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].children[0].children[0].children[0].focus();
    //   }
    //   return;
    // }

    var today = new Date();
    var requiredbyDate = new Date(this.viewModel.RequiredByDate);
    const yearsDifference = requiredbyDate.getFullYear() - today.getFullYear();

    if (this.hasValue(yearsDifference) && yearsDifference > 100) {
      this.ShowErrorMessage("Specify a valid Required By Date.");
      const elementFound = this.EditUncustomizedTaskList.nativeElement.querySelector(
        '[name="' + "EditUncustomizedTaskRequiredByDate" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return;
    }

    // if (this.viewModel.BucketName === undefined) {
    //   this.ShowErrorMessage("Please Select Task Bucket ");
    //   return;
    // }

    // if (this.viewModel.TaskBucketCategory === undefined) {
    //   this.ShowErrorMessage("Please Select Task Bucket Category ");
    //   return;
    // }

    // if (!this.hasValue(this.viewModel.IssueSubject)) {
    //   this.ShowErrorMessage('Please enter task description');
    //   const elementFound = this.EditUncustomizedTaskList.nativeElement.querySelector('[name="' + 'EditUncustomizedTaskTaskDetails' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[1].children[0].focus();
    //   }
    //   return;
    // }
    // let TaskBucketCategoryID;
    // if (this.viewModel.BucketName.ID == 12) {
    //   TaskBucketCategoryID = 18; //for only Case 12
    // } else {
    //   TaskBucketCategoryID = this.viewModel.TaskBucketCategory.ID;
    // }
    // console.log(this.viewModel.TaskType,"test")
    const postData = {
      IsUncustomizedTask: false,
      TaskInfoID: this.viewModel.TaskInfoID,
      EmployeesAndRolesModelList: this.getRelatedEmployees(),
      //TaskTypeId: this.viewModel.TaskType.TaskTypeId,
      IssueSubject: this.viewModel.IssueSubject,
      IssueSeverityID: this.getSeverityId(this.viewModel.SeverityDesc),
      // AttachmentsModelList: this.AttachmentsList,
      ModuleId: this.viewModel.ModuleId,
      ProjectId: this.viewModel.ProjectId,
      IsAttachmentUpload: false,
      // ExpectedDate: this.viewModel.RequiredByDate,
      EHRPracticeID: this.viewModel.EHRPracticeID,
      IsPublicTask: this.viewModel.IsPublicTask,
      IsTaskCreatedfromEHR: true,
      IssueSupportGroupID: this.viewModel.TaskType.IssueSupportGroupID,
      ResponsiblePersonId: this.viewModel.responsiblePerson.EmployeeId,
      isfromnewCustomerMails: true,
      UserId: this.viewModel.UserId,
      TaskNumber: this.viewModel.TaskNumber,
      IsTaskFrom: 2,
      RoutingDoneBy: Config.OASOrgModel.LoggedUserID,
      // TaskBucketID: this.viewModel.BucketName.ID,

      // TaskBucketCategory: TaskBucketCategoryID,
    } as any;
    //debugger;

    if (this.hasValue(this.viewModel.RequiredByDate)) {
      this.viewModel.RequiredByDate = this.formatValue(
        this.viewModel.RequiredByDate
      );
    }

    if (this.hasValue(this.viewModel.RequiredByDate)) {
      postData.RequiredByDate = this.viewModel.RequiredByDate;
    }

    let attachmentsContainer = await this.attachmentsService.processUploadAttachments(
      this.uploadedFiles
    );
    const attachmentModels = this.AttachmentsList.map((attchmt) =>
      this.attachmentsService.convertToAttachmentModel(attchmt)
    );
    attachmentsContainer = attachmentsContainer.concat(attachmentModels);

    if (
      this.attachmentsService.duplicatesExists(
        this.AttachmentsModelList as Array<Attachment>
      )
    ) {
      this.ShowErrorMessage(
        "Duplicates Exists in Uploaded Attachments Please Try Again."
      );
      return;
    }

    postData.AttachmentsModelList = attachmentsContainer.concat([
      {
        AttachmentType: 1,
        AttachmentBase64Format: btoa(
          unescape(encodeURIComponent(this.viewModel.SelectedTaskBody))
        ),
      },
    ]);
    postData.TaskTypeId=this.viewModel.TaskTypeId;

    const { FirstName, LastName } = Config.OASOrgModel;
    postData.TaskChangeEvents = [
      {
        Description: `Task Assigned by ${FirstName} ${LastName}`,
        EventId: TaskEventTypeEnum.TaskAssigned,
        TaskInfoId: this.viewModel.TaskInfoID,
      },
    ] as TaskChangeEvent[];

    setTimeout(() => {
      if (this.hasValue(this.pdfAttachments)) {
        postData.AttachmentsModelList = postData.AttachmentsModelList.concat(
          this.pdfAttachments
        );
      }

      postData.AttachmentsModelList = [];
      this.commonhelperobj
        .PostData(Config.OASAppnURL + "SaveReportOASIssueDetails", postData)
        .subscribe((serviceResponse) => {
          if (this.isError(serviceResponse)) {
            return;
          }

          this.location.back();

          // const PostDataService = {};
          // const outletObj = {};
          // outletObj['main'] = ['app-common-message-info', this.setCommonData(PostDataService)];
          // this.router.navigate(['/home', { outlets: outletObj }]);
        });
    }, 270);
    //debugger;
  }

  private getRelatedEmployees() {
    const employees = [];

    employees.push({
      EmployeeId: this.viewModel.responsiblePerson.EmployeeId,
      RoleID: 2,
    });

    if (this.viewModel.participants && this.viewModel.participants.length > 0) {
      this.viewModel.participants
        .filter((participant) => participant.EmployeeId)
        .forEach((participant) =>
          employees.push({ EmployeeId: participant.EmployeeId, RoleID: 4 })
        );
    }

    if (this.viewModel.observers && this.viewModel.observers.length > 0) {
      this.viewModel.observers
        .filter((observer) => observer.EmployeeId)
        .forEach((observer) =>
          employees.push({ EmployeeId: observer.EmployeeId, RoleID: 3 })
        );
    }
    if (
      this.SelectedAssistantPerson &&
      this.SelectedAssistantPerson.length > 0
    ) {
      this.SelectedAssistantPerson.filter(
        (assistant) => assistant.EmployeeId
      ).forEach((assistant) =>
        employees.push({ EmployeeId: assistant.EmployeeId, RoleID: 7 })
      );
    }

    // check if current user is already in observers list
    // if not add the current user to observers list

    // check if the current user exists in observers list
    // if not add him / her to observers list

    //debugger;
    if (
      !employees.find(
        (employee) =>
          +employee.EmployeeId === Config.OASOrgModel.LoggedUserID &&
          +employee.RoleID === 3
      )
    ) {
      employees.push({
        EmployeeId: Config.OASOrgModel.LoggedUserID,
        RoleID: 3,
      });
    }

    return employees;
  }

  private isDateInPast(dateToCheck) {
    const daysDifference = this.DateDiffInDays(new Date(), dateToCheck);
    return this.hasValue(daysDifference) && daysDifference > 0;
  }

  getSeverityId(
    severityDescription: "High" | "Normal" | "Low" | "Urgent"
  ): number {
    switch (severityDescription) {
      case "High":
        return 1;
      case "Normal":
        return 2;
      case "Low":
        return 3;
      case "Urgent":
        return 4;
      default:
        return undefined;
    }
  }

  onCancelButtonClickEvent() {
    this.viewModel.responsiblePerson = {};
    this.viewModel.ModuleInfo = {};
    this.viewModel.participants = [];
    this.viewModel.observers = [];
    this.viewModel.ModuleId = undefined;
    this.viewModel.ProjectId = undefined;
    this.viewModel.RequiredByDate = undefined;
    this.location.back();
  }

  // processUploadedCommentFiles() {
  //   return new Promise((resolve, reject) => {
  //     if (this.hasValue(this.myCommentsFiles) && this.myCommentsFiles.length > 0) {

  //       for (let uploadedFileIndex = 0; uploadedFileIndex < this.myCommentsFiles.length; uploadedFileIndex++) {

  //         const uploadedFile = this.myCommentsFiles[uploadedFileIndex];

  //         if (!uploadedFile.validationErrors) {
  //           const reader = new FileReader();

  //           // asyncronously triggered when file loads
  //           reader.onload = (ev) => {
  //             const UploadedFileSrc = (ev.target as any).result;
  //             const UplaodedFileInBase64Format = window.btoa(unescape(encodeURIComponent(UploadedFileSrc.split('base64,')[1])));

  //             let FileType = 0;
  //             if (uploadedFile.extension.toLocaleLowerCase() === '.png') {
  //               FileType = 2;
  //             } else if (uploadedFile.extension.toLocaleLowerCase() === '.jpeg') {
  //               FileType = 3;
  //             } else if (uploadedFile.extension.toLocaleLowerCase() === '.jpg') {
  //               FileType = 4;
  //             } else if (uploadedFile.extension.toLocaleLowerCase() === '.pdf') {
  //               FileType = 5;
  //             }

  //             const commentAttachment = {
  //               AttachmentBase64Format: UplaodedFileInBase64Format,
  //               AttachmentType: FileType,
  //             };

  //             this.commentAttachmentsToUpload.push(commentAttachment);

  //             // this.commentAttachmentsModel.push(this.AttachmentsModel);

  //             if (this.commentAttachmentsToUpload.length === this.myCommentsFiles.length) {
  //               // when all files are processed (i.e) pushed to array stop the promise
  //               resolve('All files processed');
  //             }
  //           };

  //           reader.readAsDataURL(uploadedFile.rawFile);
  //         }
  //       }
  //     } else {
  //       resolve('No files to process');
  //     }
  //   });

  // }

  private sanitizedTaskDescription(str: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(str);
  }

  GetTaskBodyList() {
    //this.AttachmentsHeaderHide = false;
    const PostDataToService = {
      TaskInfoID: this.viewModel.TaskInfoID,
      LoggedInFromPractice: this.isfromsendresponsetoCustomer,
      isnotfromEhr: true,
    };
    // tslint:disable-next-line:max-line-length
    // Comments Added
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "EmployeeTaskAttachmentList1",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.fullattachmentsList = serviceResponse.AttachmentsModelList;
        this.commentscount = this.hasValue(this.fullattachmentsList[0])
          ? this.fullattachmentsList[0].CommentsCount
          : 0;
        // tslint:disable-next-line:radix
        if (parseInt(this.commentscount) > 1) {
          this.ifCommentsCountisLessThanOne = true;
        }
        this.taskBodyList = this.fullattachmentsList.filter(
          (item) =>
            item.AttachmentType === 1 && item.AttachmentUrlSequence === 0
        );

        this.commonhelperobj
          .GetStringBytesFromSignedURL(
            this.taskBodyList[0].AttachmentURL,
            "text/html"
          )
          .subscribe(async (successdata2) => {
            // MainBodyAttachmentsList contains all the html data which came from http request

            this.taskDescription = successdata2;

            // this.taskDescription = this.sanitized.bypassSecurityTrustHtml(successdata2);

            this.MainBodyAttachmentsList = "<div>" + successdata2 + "</div>";

            // unused object
            this.MainBodyImagesAttachmentsList = [];

            // this array is used to hold attachments of the main body
            this.imagesattachmentslist = [];

            // array to hold main body's attachment
            this.AttachmentsList = [];

            // from MainBodyAttachmentsList which contains html data convert it to jquery object
            const GetMainBodyAttachmentsList = $(this.MainBodyAttachmentsList);

            // collectionofAttachments holds all the images in html
            const collectionofAttachments = GetMainBodyAttachmentsList.find(
              "img"
            );

            // as images are in collectionofAttachments object remove images from original html
            GetMainBodyAttachmentsList.find("img").remove();

            // from all images get count of images
            const collectionofimgsLength2 = collectionofAttachments.length;
            const elementfieldmainbody = {
              Imgaetag: null,
              attachmentType: null,
              // AttachmentName: '',
            };

            // mainbodyinfo holds html except images
            let mainbodyinfo = GetMainBodyAttachmentsList.html();

            // mainbodyinfo = mainbodyinfo.replace('SafeValue must use [property]=binding:', '')
            //   .replace('(see http://g.co/ng/security#xss)', '')
            // // this global object is used to display html of task body
            // this.TaskDescription = this.sanitizedTaskDescription(mainbodyinfo) as any;

            // this global object is used to display html of task body

            if (
              this.hasValue(this.IsuncustomizedEmailsTaskList) &&
              this.IsuncustomizedEmailsTaskList === true
            ) {
              var isBase64String = this.isBase64(mainbodyinfo);
              if (isBase64String == true) {
                mainbodyinfo = atob(mainbodyinfo);
                if (mainbodyinfo.match(/<img/)) {
                  mainbodyinfo = mainbodyinfo.replace(
                    /<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,
                    ""
                  );
                }
              } else {
                mainbodyinfo = mainbodyinfo;
              }

              mainbodyinfo = mainbodyinfo
                .replace("SafeValue must use [property]=binding:", "")
                .replace("(see http://g.co/ng/security#xss)", "");
              this.TaskDescription = this.domSanitizer.bypassSecurityTrustHtml(
                mainbodyinfo
              );
              // this.TaskDescription = this.domSanitizer.bypassSecurityTrustHtml(this.TaskDescription);
              // this.TaskDescription = mainbodyinfo;
              this.viewModel.SelectedTaskBody = this.TaskDescription;
            } else {
              this.TaskDescription = mainbodyinfo;
              this.viewModel.SelectedTaskBody = this.TaskDescription;
            }
            // setTimeout(() => {
            for (let index2 = 0; index2 < collectionofimgsLength2; index2++) {
              // get image data and add it to temporary object
              elementfieldmainbody.Imgaetag =
                collectionofAttachments[index2].attributes["src"].value;
              elementfieldmainbody.attachmentType = 2;
              // let AttachmentName = '';
              // if (this.hasValue(collectionofimgsLength2[index2].AttachmentName)) {
              //   AttachmentName = collectionofimgsLength2[index2].AttachmentName;
              // }
              // else {
              //   AttachmentName = '';
              // }
              // elementfieldmainbody.AttachmentName = AttachmentName;
              // push the temporary object to attachment list
              this.AttachmentsList.push(this.cloneObject(elementfieldmainbody));

              this.AttachmentsHeaderHide = true;
              this.AttachmentslableHide = false;
            }

            // }, 100);

            this.getAttachments().then(() => this.getCommentsAttachments());
          });
      });
  }

  isBase64(str) {
    if (str === "" || str.trim() === "") {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }

  /**
   * Get organization modules from database
   * @author Sanjay Idpuganti
   */
  getModules(): Observable<Array<any>> {
    const PostDataToService = {};
    return this.commonhelperobj
      .PostData(
        Config.OASModulesAppnURL + "GetProjectModules",
        PostDataToService
      )
      .pipe(
        map((response) => {
          // check for response error
          if (this.isError(response)) {
            return [];
          }
          // assign to respective list
          this.moduleList = response.ModuleList;
          // and return
          return this.moduleList;
        })
      );
  }

  GetTaskTypes(): Observable<Array<any>> {
    const PostDataToService = {};
    return this.commonhelperobj
      .PostData(Config.OASAppnURL + "OASIssueSupportGroupsListView", PostDataToService)
      .pipe(
        map((response) => {
          // check for response error
          if (this.isError(response)) {
            return [];
          }
          // assign to respective list
          this.taskTypeList = response;
          // and return
          return this.taskTypeList;
        })
      );
  }

  /**
   * Get organization projects from database
   * @author Sanjay Idpuganti
   */
  getProjects(): Observable<Array<any>> {
    const PostDataToService = {};
    return this.commonhelperobj
      .PostData(
        Config.OASProjectsAppnURL + "GetProjectsList",
        PostDataToService
      )
      .pipe(
        map((response) => {
          // check for response error
          if (this.isError(response)) {
            return [];
          }
          // assign to respective list
          this.projectList = response.ProjectList;
          // and return
          return this.projectList;
        })
      );
  }

  getSupportedRelatedTypes(): Observable<Array<any>> {
    return this.taskService.supportRelatedList().pipe(
      map((response) => {
        if (this.isError(response)) {
          return [];
        }
        this.taskTypeList = response;
        // console.log(this.taskTypeList,"Gettign")
        return this.taskTypeList;
      })
    );
  }

  async getAttachments() {
    // assigning image attachments
    // console.log(this.fullattachmentsList, "Attachments");
    this.imagesattachmentslist = this.fullattachmentsList.filter(
      (item) =>
        (item.AttachmentType === 2 ||
          item.AttachmentType === 3 ||
          item.AttachmentType === 4 ||
          item.AttachmentType === 10) &&
        item.AttachmentUrlSequence === 0
    );

    this.pdfAttachments = this.attachmentsService.pdfAttachments(
      this.fullattachmentsList
    );

    if (this.imagesattachmentslist.length > 0) {
      // setTimeout(() => {
      for (let index = 0; index < this.imagesattachmentslist.length; index++) {
        const SelectedAttachmentType = this.imagesattachmentslist[index]
          .AttachmentType;
        // tslint:disable-next-line:max-line-length
        const urlsrcslist = this.imagesattachmentslist[index].AttachmentURL;

        const source = await this.sourceFromUrl(urlsrcslist);

        let AttachmentName = "";
        if (this.hasValue(this.imagesattachmentslist[index].AttachmentName)) {
          AttachmentName = this.imagesattachmentslist[index].AttachmentName;
        } else {
          AttachmentName = "";
        }

        const element = {
          Imgaetag: "data:image/png;base64" + "," + source,
          attachmentType: SelectedAttachmentType,
          AttachmentName: AttachmentName,
        };

        this.AttachmentsList.push(element);
        // console.log(this.AttachmentsList, "Attachment");
        // const attachment = {
        //   AttachmentBase64Format: window.btoa(unescape(encodeURIComponent(element.Imgaetag.split('base64,')[1]))),
        //   AttachmentType: SelectedAttachmentType,
        // };
      }
    }
  }

  public selectEventHandler(e: SelectEvent): void {
    this.uploadStateService.setUploadState(UploadState.uploadInProgress);
    this.filesCount += (<any>e).files.length;
  }

  public completeEventHandler(event: Event) {
    this.uploadStateService.setUploadState(UploadState.uploadComplete);
  }

  public removeEventHandler(event: RemoveEvent) {
    this.filesCount--;
    if (this.filesCount === 0) {
      this.uploadStateService.setUploadState(UploadState.removedAllFiles);
    }
  }

  cancelEventHandler(event: CancelEvent) {
    this.filesCount--;
    if (this.filesCount === 0) {
      this.uploadStateService.setUploadState(UploadState.removedAllFiles);
    }
  }

  commentSelectEventHandler(event) {
    this.commentFileUploadStateService.setUploadState(
      UploadState.uploadInProgress
    );
    this.commentFilesCount += (<any>event).files.length;
  }

  commentRemoveEventHandler(event) {
    this.commentFilesCount--;
    if (this.commentFilesCount === 0) {
      this.commentFileUploadStateService.setUploadState(
        UploadState.removedAllFiles
      );
    }
  }

  commentCompleteEventHandler(event) {
    this.commentFileUploadStateService.setUploadState(
      UploadState.uploadComplete
    );
  }

  // /**
  //  * Process the files uploaded by the user
  //  * @author Sanjay Idpuganti
  //  */
  // processUploadedFiles() {
  //   return new Promise((resolve, reject) => {
  //     if (this.hasValue(this.uploadedFiles) && this.uploadedFiles.length > 0) {

  //       for (let uploadedFileIndex = 0; uploadedFileIndex < this.uploadedFiles.length; uploadedFileIndex++) {

  //         const uploadedFile = this.uploadedFiles[uploadedFileIndex];

  //         if (!uploadedFile.validationErrors) {
  //           const reader = new FileReader();

  //           // asyncronously triggered when file loads
  //           reader.onload = (ev) => {
  //             const UploadedFileSrc = (ev.target as any).result;
  //             const UplaodedFileInBase64Format = window.btoa(unescape(encodeURIComponent(UploadedFileSrc.split('base64,')[1])));

  //             let FileType = 0;
  //             if (uploadedFile.extension.toLocaleLowerCase() === '.png') {
  //               FileType = 2;
  //             } else if (uploadedFile.extension.toLocaleLowerCase() === '.jpeg') {
  //               FileType = 3;
  //             } else if (uploadedFile.extension.toLocaleLowerCase() === '.jpg') {
  //               FileType = 4;
  //             }

  //             this.AttachmentsModel = {
  //               AttachmentBase64Format: UplaodedFileInBase64Format,
  //               AttachmentType: FileType,
  //             };

  //             if (uploadedFileIndex === this.uploadedFiles.length - 1) {
  //               // when all files are processed (i.e) pushed to array stop the promise
  //               resolve('All files processed');
  //             }
  //           };

  //           reader.readAsDataURL(uploadedFile.rawFile);
  //         }
  //       }
  //     } else {
  //       resolve('No files to process');
  //     }
  //   });

  // }

  getCommentsAttachments() {
    return this.getCommentsText()
      .then(() => this.renderCommentAttachments())
      .catch(() => console.log("No Comments"));
  }

  onRequiredByDateChange(event: Event) {
    if (this.createdDateGreaterThanRequiredByDate()) {
      this.ShowErrorMessage(
        "Required By Date Should be Greater than or Equal to Current Date"
      );
      return;
    }
  }

  private createdDateGreaterThanRequiredByDate(): boolean {
    if (this.hasValue(this.viewModel.RequiredByDate)) {
      //const date1 = this.hasValue((this.viewModel.IssueCreatedOn)) ? new Date(this.formatDateTime(this.viewModel.IssueCreatedOn + ' UTC', "MM/dd/yyyy")) : "";
      //date1.setHours(0, 0, 0);

      const date1 = new Date(this.viewModel.IssueCreatedOn);
      date1.setHours(0, 0, 0);

      return date1 > new Date(this.viewModel.RequiredByDate);
    }
  }

  getCommentsText() {
    // setting comments list
    this.messagesList = this.fullattachmentsList.filter(
      (item) => item.AttachmentType === 1 && item.AttachmentUrlSequence >= 1
    );
    const subscriptions = [];

    return new Promise((resolve, reject) => {
      if (this.messagesList.length > 0) {
        const MessagesLength = this.messagesList.length;

        for (let index = 0; index < MessagesLength; index++) {
          subscriptions.push(
            this.commonhelperobj.GetStringBytesFromSignedURL(
              this.messagesList[index].AttachmentURL,
              "text/html"
            )
          );
        }

        forkJoin(subscriptions).subscribe((response) => {
          for (
            let responseIndex = 0;
            responseIndex < response.length;
            responseIndex++
          ) {
            const responseData = response[responseIndex];
            this.CommentsAttachmentsList = "<div>" + responseData + "</div>";

            const GetCommentsAttachmentsList = $(this.CommentsAttachmentsList);
            const collectionofAttachments = GetCommentsAttachmentsList.find(
              "img"
            );
            GetCommentsAttachmentsList.find("img").remove();
            let collectionofimgsLength2: any;
            collectionofimgsLength2 = collectionofAttachments.length;
            this.messagesList[responseIndex].commentsattachmentslist = [];
            const elementfield2 = {
              Imgaetag: null,
              AttachmentName: "",
            };

            for (let index2 = 0; index2 < collectionofimgsLength2; index2++) {
              elementfield2.Imgaetag =
                collectionofAttachments[index2].attributes["src"].value;
              // let AttachmentName = '';
              // if (this.hasValue(collectionofAttachments[index2].AttachmentName)) {
              //   AttachmentName = collectionofAttachments[index2].AttachmentName;
              // }
              // else {
              //   AttachmentName = '';
              // }
              this.messagesList[responseIndex].commentsattachmentslist.push(
                this.cloneObject(elementfield2)
              );
            }

            const commentsinfo = GetCommentsAttachmentsList.html();
            this.messagesList[responseIndex].messageinfo = commentsinfo;
            this.messagesList[
              responseIndex
            ].CommentCreatedOn = this.messagesList[
              responseIndex
            ].CommentCreatedOn; //this.formatDateTime(new Date(this.messagesList[responseIndex].CommentCreatedOn + ' UTC'), "MM/dd/yyyy hh:mm tt");

            if (responseIndex === response.length - 1) {
              resolve();
            }
            //debugger;
            this.commentsGridRowData = this.messagesList;
            //console.log('this.commentsGridRowData');
            //console.log(this.commentsGridRowData);
          }
        });
      } else {
        reject("No Comments");
      }
    });
  }

  // getCommentsImagesAndAttachments() {
  //   // assigning image attachments
  //   const elementfield = {
  //     Imgaetag: null,
  //   };

  //   this.CommentsImagesAttachmentsList = this.fullattachmentsList
  //     .filter(
  //       item => item.AttachmentType === 2 || item.AttachmentType === 3 ||
  //         item.AttachmentType === 4 && item.AttachmentUrlSequence >= 1
  //     );

  //   if (this.hasValue(this.messagesList) &&
  //     this.messagesList.length > 0 && this.hasValue(this.CommentsImagesAttachmentsList) &&
  //     this.CommentsImagesAttachmentsList.length > 0
  //   ) {

  //     // messagesList --> comments array
  //     // CommentsImagesAttachmentsList --> images array
  //     this.messagesList.forEach(MessageElement => {
  //       this.CommentsImagesAttachmentsList.forEach(async Attachmentelement => {

  //         if (MessageElement.AttachmentUrlSequence === Attachmentelement.AttachmentUrlSequence) {
  //           const urlsrcslist = Attachmentelement.AttachmentURL;

  //           await $.get(urlsrcslist).then(success => {

  //             this.srcextractedlist = success;
  //             elementfield.Imgaetag = 'data:image/png;base64' + ',' + this.srcextractedlist;

  //             const onlyfields = this.grep(MessageElement.commentsattachmentslist, function (item) {
  //               return item.AttachmentType === Attachmentelement.AttachmentType &&
  //                 item.AttachmentUrlSequence === Attachmentelement.AttachmentUrlSequence
  //                 && item.Imgaetag === elementfield.Imgaetag;
  //             }, null);

  //             if (onlyfields.length <= 0) {
  //               MessageElement.commentsattachmentslist.push(this.cloneObject(elementfield));
  //             }

  //           }).promise();
  //         }

  //       });
  //     });
  //   }
  // }

  /**
   * If the url is valid return the response in form of promise
   * @param sourceUrl The google data store url which returns base64 image
   */
  sourceFromUrl = function (sourceUrl: string): Promise<string> | undefined {
    if (!sourceUrl) {
      return undefined;
    }

    return $.get(sourceUrl).promise();
  };

  /**
   * Render comment images and attachments of the current task in HTML
   * @author Sanjay Idpuganti
   */
  private renderCommentAttachments() {
    /**
     * check if there are any attachments to render images
     */
    let doNotRenderImages = function () {
      return !(
        this.hasValue(this.messagesList) &&
        this.messagesList.length > 0 &&
        ((this.hasValue(commentAttachments) && commentAttachments.length > 0) ||
          (this.hasValue(pdfAttachments) && pdfAttachments.length > 0))
      );
    };

    /**
     * From all the attachments return the attachments that are in comments of the current task
     */
    let getCommentAttachments = function () {
      return this.fullattachmentsList.filter(
        (item) =>
          item.AttachmentType === 2 ||
          item.AttachmentType === 3 ||
          item.AttachmentType === 10 ||
          (item.AttachmentType === 4 && item.AttachmentUrlSequence >= 1)
      );
    };

    let getCommentPdfAttachments = function () {
      return this.fullattachmentsList.filter(
        (attachment) =>
          (attachment.AttachmentType === 5 ||
            attachment.AttachmentType === 6 ||
            attachment.AttachmentType === 7 ||
            attachment.AttachmentType === 8 ||
            attachment.AttachmentType === 9) &&
          attachment.AttachmentUrlSequence > 0
      );
    };

    /**
     * Check if attachments array of task comment has commentAttachment
     * @param comment Task comment
     * @param commentAttachment Attachment of any comment
     * @param element object containing ImgaeTag property which is later user to bind to html
     */
    let imageAlreadyExistsInComment = function (
      comment,
      commentAttachment,
      element
    ) {
      const result = this.grep(
        comment.commentsattachmentslist,
        function (item) {
          return (
            item.AttachmentType === commentAttachment.AttachmentType &&
            item.AttachmentUrlSequence ===
              commentAttachment.AttachmentUrlSequence &&
            item.Imgaetag === element.Imgaetag
          );
        },
        null
      );

      return result.length > 0;
    };

    getCommentAttachments = getCommentAttachments.bind(this);
    doNotRenderImages = doNotRenderImages.bind(this);
    imageAlreadyExistsInComment = imageAlreadyExistsInComment.bind(this);
    getCommentPdfAttachments = getCommentPdfAttachments.bind(this);

    // array of attachment that belong to comments of the current attachment
    const commentAttachments = getCommentAttachments();
    const pdfAttachments = getCommentPdfAttachments();

    if (doNotRenderImages()) {
      return;
    }

    for (const comment of this.messagesList) {
      for (const commentAttachment of commentAttachments) {
        if (
          comment.AttachmentUrlSequence ===
          commentAttachment.AttachmentUrlSequence
        ) {
          const sourceUrl = commentAttachment.AttachmentURL;

          this.sourceFromUrl(sourceUrl).then((imageSource) => {
            const element = {
              Imgaetag: "data:image/png;base64" + "," + imageSource,
            };

            if (
              !imageAlreadyExistsInComment(comment, commentAttachment, element)
            ) {
              comment.commentsattachmentslist.push(this.cloneObject(element));

              this.AttachmentsHeaderHide = true;
              this.AttachmentslableHide = false;
            }
          });
        }
      }

      for (const pdfAttachment of pdfAttachments) {
        if (
          comment.AttachmentUrlSequence === pdfAttachment.AttachmentUrlSequence
        ) {
          if (!comment.pdfAttachments) {
            comment.pdfAttachments = [];
          }
          comment.pdfAttachments.push(pdfAttachment);

          this.AttachmentsHeaderHide = true;
          this.AttachmentslableHide = false;
        }
      }
    }
  }

  onBackButtonClickEvent() {
    this.location.back();
  }
  commentsclick() {
    this.showHistoryGrid = false;
    this.commentsHide = false;
    $("#div-task-history").addClass("CommentsBackgroungClass");
    $("#div-task-comments").removeClass("CommentsBackgroungClass");
  }
  historyclick() {
    this.populateHistoryTable();
    this.commentsHide = true;
    this.showHistoryGrid = true;
    $("#div-task-comments").addClass("CommentsBackgroungClass");
    $("#div-task-history").removeClass("CommentsBackgroungClass");
  }

  responsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Responsible Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.viewModel.responsiblePerson)) {
        } else {
          this.viewModel.responsiblePerson = "";
        }
      } else {
        this.responsiblePersonInfo = result;
        if (this.hasValue(this.responsiblePersonInfo)) {
          this.viewModel.responsiblePerson.EmployeeId = this.responsiblePersonInfo.EmployeeId;
          this.viewModel.responsiblePerson.EmployeeFullName = this.responsiblePersonInfo.EmployeeFullName;
        }
      }
    });
  }

  RemoveResponsiblePersonInfo() {
    this.viewModel.responsiblePerson.EmployeeId = undefined;
    this.viewModel.responsiblePerson.EmployeeFullName = "";
  }

  moduleIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Module",
      // Show component
      content: SelectModulePopupComponentComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.viewModel.ModuleInfo)) {
        } else {
          this.viewModel.ModuleInfo = "";
        }
      } else {
        this.ModuleListInfo = result;
        if (this.hasValue(this.ModuleListInfo)) {
          this.viewModel.ModuleInfo = this.ModuleListInfo;
          this.viewModel.ModuleId = this.ModuleListInfo.ModuleId;
          this.viewModel.ModuleName = this.ModuleListInfo.Description;
        }
      }
    });
  }

  RemoveModuleInfo() {
    this.viewModel.ModuleInfo = undefined;
    this.viewModel.ModuleId = undefined;
    this.viewModel.ModuleName = "";
  }

  editUncustomizedTaskInfoAdditionalResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData = this.viewModel.participants;
    dialogRef.content.instance.StateMaintainObserverPersonsData = this.viewModel.observers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData = this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
        } else {
          this.SelectedAdditionalResponsiblePersonInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;
        this.SelectedAdditionalResponsiblePersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.IsEditUncustomizedAdditionalResponsiblePersonInfoChanged = true;
        this.viewModel.participants = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );

          this.SelectedAdditionalResponsiblePersonName = this.SelectedAdditionalResponsiblePersonName.substring(
            0,
            this.SelectedAdditionalResponsiblePersonName.length - 2
          );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        this.SelectedObserversInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.IsEditUncustomizedObserversInfoChanged = true;
        this.viewModel.observers = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });

          this.SelectedObserversPersonNames = this.SelectedObserversPersonNames.substring(
            0,
            this.SelectedObserversPersonNames.length - 2
          );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        this.SelectedAssistantPersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        this.SelectedAssistantPerson = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedAssistantPersonName = this.SelectedAssistantPersonName.substring(
            0,
            this.SelectedAssistantPersonName.length - 2
          );
        } else {
          this.SelectedAssistantPersonName = "";
        }
      }
    });
  }

  EditUncustomizedTaskInfoRemoveAdditionalResponsiblePersonInfo() {
    this.SelectedAdditionalResponsiblePersonName = "";
    this.viewModel.participants = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }

  editUncustomizedTaskObserversIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Users(s)",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData = this.viewModel.participants;
    dialogRef.content.instance.StateMaintainObserverPersonsData = this.viewModel.observers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData = this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedObserversInfo)) {
        } else {
          this.SelectedObserversInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        this.SelectedAdditionalResponsiblePersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.IsEditUncustomizedAdditionalResponsiblePersonInfoChanged = true;
        this.viewModel.participants = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );

          this.SelectedAdditionalResponsiblePersonName = this.SelectedAdditionalResponsiblePersonName.substring(
            0,
            this.SelectedAdditionalResponsiblePersonName.length - 2
          );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        this.SelectedObserversInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.IsEditUncustomizedObserversInfoChanged = true;
        this.viewModel.observers = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });

          this.SelectedObserversPersonNames = this.SelectedObserversPersonNames.substring(
            0,
            this.SelectedObserversPersonNames.length - 2
          );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        this.SelectedAssistantPersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        this.SelectedAssistantPerson = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedAssistantPersonName = this.SelectedAssistantPersonName.substring(
            0,
            this.SelectedAssistantPersonName.length - 2
          );
        } else {
          this.SelectedAssistantPersonName = "";
        }
      }
    });
  }

  editUncustomizedTaskRemoveObserversIconClickEvent() {
    this.SelectedObserversPersonNames = "";
    this.viewModel.observers = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }

  assistantPersonIconClickEvent() {
    //this.IsresponsiblePersonAndFwdToSame = false;
    const dialogRef = this.dialogService.open({
      title: "Select Person to Forward Task",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    // dialogRef.content.instance.IsFromForwardToPopup = true;
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData = this.viewModel.participants;
    dialogRef.content.instance.StateMaintainObserverPersonsData = this.viewModel.observers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData = this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;

      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
        } else {
          this.SelectedAssistantPersonInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        //********assigning addl responsible resons info************
        this.SelectedAdditionalResponsiblePersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.IsEditUncustomizedAdditionalResponsiblePersonInfoChanged = true;
        this.viewModel.participants = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );

          this.SelectedAdditionalResponsiblePersonName = this.SelectedAdditionalResponsiblePersonName.substring(
            0,
            this.SelectedAdditionalResponsiblePersonName.length - 2
          );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        //*****assigning observers data****************
        this.SelectedObserversInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.IsEditUncustomizedObserversInfoChanged = true;
        this.viewModel.observers = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });

          this.SelectedObserversPersonNames = this.SelectedObserversPersonNames.substring(
            0,
            this.SelectedObserversPersonNames.length - 2
          );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        this.SelectedAssistantPersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        this.SelectedAssistantPerson = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedAssistantPersonName = this.SelectedAssistantPersonName.substring(
            0,
            this.SelectedAssistantPersonName.length - 2
          );
        } else {
          this.SelectedAssistantPersonName = "";
        }
      }
    });
  }

  RemoveAssistantPersonInfo() {
    this.SelectedAssistantPersonName = "";
    this.SelectedAssistantPerson = undefined;
    this.IsresponsiblePersonAndFwdToSame = false;
  }

  OnClickEnableTaskBucketCategory(event) {
    if (event !== undefined && event.ID != 12) {
      let selectednumber = event.ID;
      this.IsEnableTaskCategory = false;
      let TaskSubList: Array<any> = this._taskBucketDetailsService
        .BucketSubCategoryList;
      this.TaskBucketCategoryList = TaskSubList.filter(
        (ele) => ele.BucketID === selectednumber || ele.BucketID === -1
      );

      // console.log(this.TaskBucketCategoryList, this.IsEnableTaskCategory);
    }
    else{
      this.IsEnableTaskCategory = true;
      this.viewModel.TaskBucketCategory={ID:18}
    }
  }
}

function hexToBase64(str) {
  return btoa(
    String.fromCharCode.apply(
      null,
      str
        .replace(/\r|\n/g, "")
        .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
        .replace(/ +$/, "")
        .split(" ")
    )
  );
}
