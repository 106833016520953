import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import {DialogService,DialogRef } from '@progress/kendo-angular-dialog';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';

@Component({
  selector: 'app-check-list-item-popup',
  templateUrl: './check-list-item-popup.component.html',
  styleUrls: ['./check-list-item-popup.component.css']
})
export class CheckListItemPopupComponent extends commonhelper implements OnInit {
  @ViewChild('checkListItemPopupGrid') checkListItemPopupGrid: AgGridNg2;
  private commonhelperobj: commonHelperService;
  public rowData=[];
  columnDefs = [
    { headerName: 'Check List Item', field: 'CheckListItemName', width: 90, tooltipField: 'CheckListItemName', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
  ];
  onCheckListItemPopupGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  checkListItemPopupSearchCheckListItemModel:any;
  constructor(private http: HttpClient,private dialogService: DialogService,httpClient: HttpClient, private dialog: DialogRef,) {super();
    this.commonhelperobj = new commonHelperService(httpClient); }
  ngOnInit() {
    this.getPracticeStatusCheckListItemsMasterList();
    setTimeout(function () {
      document.getElementById("checkListItemPopupSearchCheckListItem").focus();
      },20);
  }
  checkListItemPopupCancelClickEvent() {
    this.dialog.close();
  }
  getPracticeStatusCheckListItemsMasterList(){
   const postdatatoserice={
    CheckListItemName:this.checkListItemPopupSearchCheckListItemModel
   }
    return this.commonhelperobj.PostData(Config.OASPracticeCheckListItemsAppnURL + 'GetPracticeMasterCheckListItemsList',postdatatoserice)
    .subscribe(response => {
      if (this.isError(response)) {
        return;
      }
      this.rowData = response.practiceCheckMasterListItemsOutputModels;
      setTimeout(function () {
        document.getElementById("checkListItemPopupSearchCheckListItem").focus();
        },20);
    });
  
  }
  checkListItemPopupOKClickEvent(){
    let selectrow=this.checkListItemPopupGrid.api.getSelectedRows()[0];
    if (!this.hasValue(selectrow)) {
      this.ShowErrorMessage('Please Select Check List Item');
      document.getElementById("checkListItemPopupSearchCheckListItem").focus();
      return false;
    }
   
      this.dialog.close(selectrow);
    
  }
}
