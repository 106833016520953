import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TaskService } from '../services/task.service';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import { DateInputComponent } from '@progress/kendo-angular-dateinputs';

@Component({
  selector: 'app-uireview-details-popup',
  templateUrl: './uireview-details-popup.component.html',
  styleUrls: ['./uireview-details-popup.component.css']
})
export class UIReviewDetailsPopupComponent implements OnInit {

  @ViewChild('uiReviewForm') uiReviewForm: ElementRef;

  @ViewChild('txtUIreviewDetailsTaskQualityScoreIncludingScenariosNaviagationVerification') uiReviewFormQualityScoreKendoElement: NumericTextBoxComponent;
  @ViewChild('txtUIreviewDetailsNoofIssuesReportedbyQAforthisTask') uiReviewFormNoOfIssuesKendoElement: NumericTextBoxComponent;
  @ViewChild('txtUIreviewDetailsTaskAvailabilityScore') uiReviewFormAvailibilityScoreKendoElement: NumericTextBoxComponent;
  @ViewChild('uiReviewDetailsSelectDate') uiReviewDetailsSelectDate: DateInputComponent;
  
  selectedUITaskToAddReview: any;

  uIReviewFormGroup: FormGroup;

  uiReviewDetailsSelectTeamLeadList: any[];
  uiReviewDetailsSelectDeveloperList: any[];

  public UIReviewDefaultTeamLeadItem = { EmployeeFullName: '-- Select Team Lead --', EmployeeId: null };

  constructor(
    private dialog: DialogRef,
    private taskService: TaskService,
    private commonHelper: commonhelper,
    private commonHelperService: commonHelperService) { }

  async ngOnInit() {
    this.initializeEmptyUIFormGroup();

    await this.getUITeamLeadData();
    await this.getUIDeveloperData();
    await this.getSavedUIReview();
  }

  // API Call to get UI Team lead Data
  private async getUITeamLeadData() {
    const data = {
      IsTaskReviewLeadEmployee: false,
      TaskReviewDepartmentInfoID: 4
    }
    const response = await this.taskService.taskReviewTeamLeadList(data).toPromise()
    if (this.commonHelper.isError(response)) {
      return;
    }
    this.uiReviewDetailsSelectTeamLeadList = response;
  }

  // API Call to get UI Developer Data
  private async getUIDeveloperData() {
    const data = {
      IsTaskReviewLeadEmployee: true,
      TaskReviewDepartmentInfoID: 4
    }
    const response = await this.taskService.taskReviewTeamLeadList(data).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }
    this.uiReviewDetailsSelectDeveloperList = response;
  }

  private async getSavedUIReview() {

    const preparePayloadToGetTaskReview = {
      TaskNumber: this.selectedUITaskToAddReview.TaskInfoID,
      TaskDoneByTeamLeadId: 2020040206074757,
      TaskDoneByEmployeeId: 2019022822551956,
    }
    const response = await this.taskService.getTaskReviewAPIAction(preparePayloadToGetTaskReview).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }
  }

  private initializeEmptyUIFormGroup() {
    this.uIReviewFormGroup = new FormGroup({
      UIFormGroupDateFormControl: new FormControl(null, Validators.required),
      UIFormGroupTaskNameFormControl: new FormControl(this.selectedUITaskToAddReview.IssueSubject, Validators.required),
      UIFormGroupTaskDescriptionFormControl: new FormControl(null, Validators.required),
      UIFormGroupTeamLeadFormControl: new FormControl(null, Validators.required),
      UIFormGroupNoOfIssuesReportedFormControl: new FormControl(null, Validators.required),
      UIFormGroupTaskQualityScoreFormControl: new FormControl(null, Validators.required),
      UIFormGroupTaskAvailabilityScoreFormControl: new FormControl(null, Validators.required),
      UIFormGroupTaskCodingStandardsFormControl: new FormControl(null, Validators.required),
      UIFormGroupTeamLeadCommentsFormControl: new FormControl(null, Validators.required),
    })
  }

  checkAllMandatoryFieldsAreFilled() {
    
    if (this.uIReviewFormGroup.get('UIFormGroupDateFormControl').invalid) {
      // const elementFound = this.uiReviewForm.nativeElement.querySelector('[name="' + 'uiReviewDetailsSelectDate' + '"]');
      // elementFound.children[0].focus();
      this.uiReviewDetailsSelectDate.focus()
      this.commonHelperService.ShowErrorMessage('Select Date.');
      return true;
    } 

    if (this.uIReviewFormGroup.get('UIFormGroupTaskNameFormControl').invalid) {
      this.uiReviewForm.nativeElement.querySelector(`#txtUIreviewDetailsEnterTaskName`).focus();    
      this.commonHelperService.ShowErrorMessage('Fill Task Name same as Devops name.');
      return true;
    }

    if (this.uIReviewFormGroup.get('UIFormGroupTaskDescriptionFormControl').invalid) {
      this.uiReviewForm.nativeElement.querySelector(`#txtUIreviewDetailsEnterTaskDescription`).focus();    
      this.commonHelperService.ShowErrorMessage('Fill Task Description.');
      return true;
    }

    if (this.uIReviewFormGroup.get('UIFormGroupTeamLeadFormControl').invalid) {
      const elementFound = this.uiReviewForm.nativeElement.querySelector('[name="' + 'UIreviewDetailsSelectTeamLeadDropdown' + '"]');
      elementFound.children[0].focus();
      this.commonHelperService.ShowErrorMessage('Add Team Lead.');
      return true;
    }
    
    if (this.uIReviewFormGroup.get('UIFormGroupNoOfIssuesReportedFormControl').invalid) {
      this.uiReviewFormNoOfIssuesKendoElement.focus()
      this.commonHelperService.ShowErrorMessage('Add No. of issues reported.');
      return true;
    }

    if (this.uIReviewFormGroup.get('UIFormGroupTaskQualityScoreFormControl').invalid) {
      this.uiReviewFormQualityScoreKendoElement.focus()
      this.commonHelperService.ShowErrorMessage('Add task Quality score.');
      return true;
    }

    if (this.uIReviewFormGroup.get('UIFormGroupTaskAvailabilityScoreFormControl').invalid) {
      this.uiReviewFormAvailibilityScoreKendoElement.focus()
      this.commonHelperService.ShowErrorMessage('Add task availability score.');
      return true;
    }

    if (this.uIReviewFormGroup.get('UIFormGroupTaskCodingStandardsFormControl').invalid) {
      this.uiReviewForm.nativeElement.querySelector(`#txtUIreviewDetailsTaskCodingStandardsCommentingScore`).focus();    
      this.commonHelperService.ShowErrorMessage('Add Task Coding Standards commenting score.');
      return true;
    }

    if (this.uIReviewFormGroup.get('UIFormGroupTeamLeadCommentsFormControl').invalid) {
      this.uiReviewForm.nativeElement.querySelector(`#txtUIreviewDetailsTeamLeadOverallCommentsaboutcurrenttask`).focus();    
      this.commonHelperService.ShowErrorMessage('Add Task Lead Comments.');
      return true;
    }
  }

  async UIreviewDetailsSaveClick() {
    if (this.checkAllMandatoryFieldsAreFilled()) {
      return;
    }

    const preparePayloadForInsertReview = {
      TaskInfoId: this.selectedUITaskToAddReview.TaskInfoID,
      TaskNameFromDevops: this.uIReviewFormGroup.value.UIFormGroupTaskNameFormControl,
      TaskReviewDepartmentInfoId: 4, // For UI Review id is 4
      UITaskDonebyTeamLeadID: this.uIReviewFormGroup.value.UIFormGroupTeamLeadFormControl,
      // UITaskDonebyEmployeeID: this.uIReviewFormGroup.value.angularReviewDetailsSelectDeveloper,
      NoIssuesReportedByQa: this.uIReviewFormGroup.value.UIFormGroupNoOfIssuesReportedFormControl,
      TaskQualityScore: this.uIReviewFormGroup.value.UIFormGroupTaskQualityScoreFormControl,
      TaskAvailabilityScore: this.uIReviewFormGroup.value.UIFormGroupTaskAvailabilityScoreFormControl,
      TaskCodingStandardsAndCommenting: this.uIReviewFormGroup.value.UIFormGroupTaskCodingStandardsFormControl,
      TeamLeadOverAllComments: this.uIReviewFormGroup.value.UIFormGroupTeamLeadCommentsFormControl,
      TaskDescription: this.uIReviewFormGroup.value.UIFormGroupTaskDescriptionFormControl,
      TaskDate: this.uIReviewFormGroup.value.UIFormGroupDateFormControl ? this.uIReviewFormGroup.value.UIFormGroupDateFormControl.toLocaleDateString() : '',
    }
    
    // console.log(preparePayloadForInsertReview);
    const response = await this.taskService.UITaskReviewInsertAPIAction(preparePayloadForInsertReview).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }

    this.commonHelperService.ShowErrorMessage('Task Review Added Successfully.');
    this.dialog.close();
  }

  UIreviewDetailsCancelClickEvent() {
    this.dialog.close();
  }

  UIReviewDetailsSelectDateBlur(event: any) {

  }
}
