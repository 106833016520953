import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp, AgRendererComponent } from 'ag-grid-angular';
import { commonhelper } from '../common/commonhelper';

@Component({
  selector: 'app-task-comment-attachments-cellrenderer',
  styleUrls: ['./task-comment-attachments-cellrenderer.component.css'],  
  template: `{{getTemplate()}}`,
})
export class TaskCommentAttachmentsCellrendererComponent extends commonhelper implements ICellRendererAngularComp  {

  refresh(params: any): boolean {
    return true;
    //throw new Error("Method not implemented.");
  } 
  public template: string='';
  public commentsattachmentslist:any=[];

  agInit(params: any): void {
    //console.log('TaskCommentAttachmentsCellrendererComponent');
    //console.log(params);
    
      this.commentsattachmentslist = params.value;
      //console.log(this.commentsattachmentslist);
      //console.log(this.commentsattachmentslist.length);
      if(this.commentsattachmentslist.length>0){
        //console.log(this.commentsattachmentslist.length);
        this.commentsattachmentslist.forEach(element => {
          this.template+= "<span style='margin-right: 15px;'> " +
                "<img [src]='" + element.Imgaetag + "' height='25' width='25' style='cursor: pointer;' " +
                  "(click)='open('dialog'," + element.Imgaetag + ")/>" +
              "</span>"
        });
      }
  }
  getTemplate(){
    return this.template;
  }
}

export class Attachment {
  Imgaetag : string;
}
