import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
} from "@angular/core";
import * as $ from "jquery";
import { commonhelper } from "../common/commonhelper";

import { commonHelperService } from "../common/common.service";
import { Config } from "../config";
// import { commonhelper } from '../common/commonhelper';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import {
  FileRestrictions,
  SelectEvent,
  ClearEvent,
  RemoveEvent,
  FileInfo,
  CancelEvent,
} from "@progress/kendo-angular-upload";
import { AngularFontAwesomeComponent } from "angular-font-awesome";
import { NotificationService } from "@progress/kendo-angular-notification";
import { TaskChangeEvent } from "../interfaces/TaskChangeEvent";
import { TaskEventTypeEnum } from "../enums/TaskHistoryChangeEvent.enum.";
import { UploadService } from "@progress/kendo-angular-upload/dist/es2015/upload.service";
import { FileUploadProgressService } from "../services/file-upload-progress.service";
import { UploadState } from "../enums/upload-state.enum";
import { Base64StringSizeCalculator } from "../classes/base64-string-size-calculator.class";
import { AttachmentService } from "../services/attachment.service";
import { Attachment } from "../interfaces/attachment";
import { Injector } from "@angular/core";
import { TaskService } from "../services/task.service";
import {
  DialogService,
  DialogCloseResult,
  DialogRef,
  DialogComponent,
} from "@progress/kendo-angular-dialog";
import { ResponsiblepersonUsersListComponent } from "../responsibleperson-users-list/responsibleperson-users-list.component";
import { PraticesListComponent } from "../pratices-list/pratices-list.component";
import { SelectModulePopupComponentComponent } from "../select-module-popup-component/select-module-popup-component.component";
import { LeftNavService } from "../common/Leftnav.service";
import { PracticeUsersListComponent } from "../practice-users-list/practice-users-list.component";
// import Quill from 'quill';
import { IntlService } from "@progress/kendo-angular-intl";
import { AdditionalParticipantAndObserversPopupComponentComponent } from "../additional-participant-and-observers-popup-component/additional-participant-and-observers-popup-component.component";
import { PracticeusersinformationcomponentComponent } from "../practiceusersinformationcomponent/practiceusersinformationcomponent.component";
import { SelectTemplatePopupComponent } from "../select-template-popup/select-template-popup.component";
import { CreateNewTemplateComponent } from "../create-new-template/create-new-template.component";
import { SaveTemplateNamePopupComponent } from "../save-template-name-popup/save-template-name-popup.component";
import { CreateTaskService } from "../shared/services/create-ticket.service";
import * as _ from "lodash";
import { EmployeeService } from "../employee.service";
import { debug } from "console";

@Component({
  selector: "app-create-new-task-component",
  templateUrl: "./create-new-task-component.component.html",
  styleUrls: ["./create-new-task-component.component.css"],
  providers: [FileUploadProgressService, DialogComponent],
})
export class CreateNewTaskComponentComponent
  extends commonhelper
  implements OnInit {
  @ViewChild("CreateTaskInfo") CreateTaskInfo: ElementRef;

  @ViewChild("TaskAddNewProjectName") TaskAddNewProjectNamefield: ElementRef;
  createNewTemplatePopupWidth = 1500;
  createNewTemplatePopupHeight = 698;
  @ViewChild("CreateTaskInfo", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  PopupDataFromMultiSelectUserSelectionPopup: any;
  SelectedAssistantPersonInfo: any = {};
  IsresponsiblePersonAndFwdToSame: boolean = false;
  SelectedAssistantPerson: any = [];
  SelectedAssistantPersonName: string = "";
  SelectedObserversInfo: any;
  SelectedObserversPersonNames: string;
  SelectedAdditionalResponsiblePersonName: string;
  IsAdditionalResponsiblePersonInfoChanged: boolean;
  SelectedAdditionalResponsiblePersonInfo: any;
  originalImageUrl: any;
  public isTaskAddNewProjectNameFocused: any = false;
  expecteddateselected: any;
  private commonhelperobj: commonHelperService;

  public TaskAddInfoModel: any = {};

  public downloadUrl = null;
  public AttamentsContainerforImagesFromEditor = [] as any;
  private filesCount = 0;
  public selectedtemplate: any = {};
  public SelectedTemplateModel: any = {};

  myFiles: Array<FileInfo> = [];

  ModuleListInfo: any;
  NewCurrentWorkingStatusList: any;
  fullattachmentsList: any;
  taskBodyList: any;
  MainBodyAttachmentsList: string;
  MainBodyImagesAttachmentsList: any[];
  imagesattachmentslist: any[];

  AttachmentsList: any[];
  public initialTaskBody = "";
  private readonly notificationService: NotificationService;
  private readonly _Router: Router;
  private readonly uploadStateService: FileUploadProgressService;
  private readonly _employeeService: EmployeeService;
  private readonly attachmentsService: AttachmentService;
  private readonly taskService: TaskService;
  private readonly dialogService: DialogService;
  private readonly intl: IntlService;
  private readonly LeftNav: LeftNavService;
  private readonly _createNewTaskService: CreateTaskService;
  private readonly httpClient: HttpClient;
  private _dialog: any;
  createNewTaskButtonsShowHide: boolean;

  constructor(private injector: Injector) {
    super();
    setTimeout(() => {
      if (this.fromInternalTicketNavigation) {
        debugger;
        this._dialog = <DialogRef>this.injector.get(DialogRef);
        // console.log(this._dialog);
        this.createNewTaskButtonsShowHide = true;
      }
    }, 500);
    this.notificationService = <NotificationService>(
      this.injector.get(NotificationService)
    );
    this._Router = <Router>this.injector.get(Router);
    this.uploadStateService = <FileUploadProgressService>(
      this.injector.get(FileUploadProgressService)
    );
    this.attachmentsService = <AttachmentService>(
      this.injector.get(AttachmentService)
    );
    this._employeeService = <EmployeeService>this.injector.get(EmployeeService);
    this.taskService = <TaskService>this.injector.get(TaskService);
    this.dialogService = <DialogService>this.injector.get(DialogService);
    this.intl = <IntlService>this.injector.get(IntlService);
    this.LeftNav = <LeftNavService>this.injector.get(LeftNavService);
    this._createNewTaskService = <CreateTaskService>(
      this.injector.get(CreateTaskService)
    );
    this.httpClient = <HttpClient>this.injector.get(HttpClient);
    this.commonhelperobj = new commonHelperService(this.httpClient);
  }
  // constructor(httpClient: HttpClient,
  //   private notificationService: NotificationService,
  //   private _Router: Router,
  //   private uploadStateService: FileUploadProgressService,
  //   private attachmentsService: AttachmentService,
  //   private _employeeService: EmployeeService,
  //   private taskService: TaskService,
  //   private dialogService: DialogService,
  //   private intl: IntlService,
  //   private LeftNav: LeftNavService,
  //   //private _dialog: DialogRef,
  //   private readonly _createNewTaskService: CreateTaskService
  // ) {
  //   super();

  // }

  // tslint:disable-next-line:member-ordering
  uploadSaveUrl = "saveUrl"; // should represent an actual API endpoint
  // tslint:disable-next-line:member-ordering
  uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint

  myRestrictions: FileRestrictions = {
    maxFileSize: 100000,
  };

  public attachmentContainerfromEditor: Array<Attachment> = [];

  // tslint:disable-next-line:member-ordering
  public projectsList: Array<string> = [];

  public ModulesList: Array<string> = [];

  public AssignedViaTypesList: Array<string> = [];

  public SeverityList: Array<string> = [];

  // public taskTypeList: Array<string> = [];
  public taskTypeList: any = [];

  public taskAssignedViaList: Array<string> = [];

  public EmployeeListInfo: Array<string> = [];

  public EmployeeObserversListInfo: Array<string> = [];

  public EmployeeResponsbilePersonListInfo: Array<string> = [];

  public selectedAdditionalResponsiblePersons: any = [];

  public SelectedObservers: any = [];

  public SelectedResponsiblePerson: any = [];

  public SelectedResponsiblePersonInfo: any = {};

  public SelectedQAPersonInfo: any = {};

  public SelectedCSharpPersonInfo: any = {};

  public SelectedDBPersonInfo: any = {};

  public SelectedJSPersonInfo: any = {};

  public SelectedPracticeInfo: any = {};

  public SelectedPracticeUserInformation: any = {};

  // public AttachmentsModelList: Array<any> = [];

  public EmployeesAndRolesModelList: Array<string> = [];

  public AttachmentsModel: any = {};
  fromInternalTicketNavigation = false;

  public EmployeesAndRolesModel: any = {};
  public createNewTaskType: any;

  public targetSrc: string;
  // public value = ['Description', 'ModuleId'];

  public defaultResponsibleItem = {
    EmployeeFullName: "-- Select --",
    EmployeeId: null,
  };

  public defaultProjectItem = { Description: "-- Select --", ProjectId: null };

  public defaultModuleItem = { Description: "-- Select --", ModuleId: null };

  public defaultSeverityItem = {
    IssueSeverityDesc: "-- Select --",
    IssueSeverityID: null,
  };

  public defaultTaskTypeItem = {
    TaskTypeDescription: "-- Select --",
    TaskTypeId: null,
  };
  public defaultTaskAssignedViaItem = {
    TaskAssignedViaDesc: "-- Select --",
    TaskAssignedViaID: null,
  };
  public defaultCurrentTaskItem = {
    Task_Current_Working_Status: "--Task Current Working Status--",
    Task_Current_Working_Status_InfoID: null,
  };

  public defaultCurrentTaskFromItem = {
    selectTaskFromListText: "- Select -",
    selectTaskFromListTextValue: 0,
  };

  public taskAddNewTaskSelectTaskFromList = [
    { selectTaskFromListText: "Call", selectTaskFromListTextValue: 1 },
    { selectTaskFromListText: "Email", selectTaskFromListTextValue: 2 },
    { selectTaskFromListText: "Live Chat", selectTaskFromListTextValue: 3 },
    { selectTaskFromListText: "Sales Chat", selectTaskFromListTextValue: 4 },
  ];
  public listItems1 = [
    { text: "Small", value: 1 },
    { text: "Medium", value: 2 },
    { text: "Large", value: 3 },
    { text: "Xtra Large", value: 4 },
  ];

  public CurrentTaskList = [
    { CurrentTaskDesc: "Pending", CurrentTaskID: 1 },
    { CurrentTaskDesc: "Completed", CurrentTaskID: 2 },
  ];

  public selectedTaskType = {
    TaskTypeDescription: "Task",
    TaskTypeId: 1,
  };

  ngOnInit() {
    let elemtInstance: any =
      this.CreateTaskInfo.nativeElement.querySelector("#summernoteeditor1");

    elemtInstance = $(elemtInstance);

    const elemtInstance1: any = $(".summernote");

    // elemtInstance1.summernote({
    //   height: 200, // set editor height
    //   minHeight: null, // set minimum height of editor
    //   maxHeight: null, // set maximum height of editor
    //   focus: false // set focus to editable area after initializing summernote
    // });
    // elemtInstance.summernote({
    //   height: 200, // set editor height
    //   minHeight: null, // set minimum height of editor
    //   maxHeight: null, // set maximum height of editor
    //   focus: false // set focus to editable area after initializing summernote
    // });

    this.GetTaskTypes();

    this.GetProjectsList();

    this.GetModulesList();

    this.GetSeverityList();

    this.GetEmployeesList();

    //this.GetTaskAssignedViaTypesList();

    this.GetCurrentStatusList();

    this.TaskAddInfoModel.SelectedTaskBody = "";
    setTimeout(() => {
      // $("#TaskAddNewProject").removeClass(".k-dropdown-wrap k-state-default").addClass("k-dropdown .k-state-focused");
      // $(".k-dropdown-wrap k-state-default").addClass("k-dropdown .k-state-focused");
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewProjectName" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
    }, 100);
  }

  /**
   * Get task types list from database
   * @author Sanjay Idpuganti
   */
  private GetTaskTypes() {
    this.taskService.taskTypeList().subscribe((response) => {
      if (this.isError(response)) {
        return;
      }
      this.taskTypeList = response;
    });
  }

  private GetCurrentStatusList() {
    var PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "NewCurrentWorkingStatusList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.NewCurrentWorkingStatusList =
          serviceResponse.newCurrentWorkingStatusListModelList;
      });

    // this.CurrentStatusService.NewCurrentWorkingStatusList().subscribe(response =>{

    //   if(this.isError(response)){
    //   return;
    // }
  }

  // selectEventHandler(SelectEvent) {
  //   // e.files.forEach((file) => this.myUploadedFiles.push(file));
  //   const that = this;

  //   SelectEvent.files.forEach((file) => {

  //     if (!file.validationErrors) {
  //       const reader = new FileReader();
  //       reader.onload = function (SelectEvent) {
  //         // const image = {
  //         //   src: SelectEvent.target,
  //         //   uid: file.uid
  //         // };
  //         const src = SelectEvent.target;
  //         alert(src);
  //       };
  //     }
  //   });

  // }

  public selectEventHandler(event: SelectEvent): void {
    // if (this.attachmentsService.alreadyExist(event.files, this.myFiles)) {
    //   this.ShowErrorMessage('Selected File(s) is already Uploaded');
    //   event.preventDefault();
    //   return;
    // }

    // if (!this.attachmentsService.areCompatableExtension(event.files.map(file => file.name))) {
    //   this.ShowErrorMessage('Selected File ' + event.files[0].name + ' is not supported for Uploading.Only JPG, PNG and PDF files are allowed');
    //   event.preventDefault();
    //   return;
    // }

    this.uploadStateService.setUploadState(UploadState.uploadInProgress);
    this.filesCount += event.files.length;
  }

  public completeEventHandler(event: Event) {
    this.uploadStateService.setUploadState(UploadState.uploadComplete);
  }

  public removeEventHandler(event: RemoveEvent) {
    this.filesCount--;
    if (this.filesCount === 0) {
      this.uploadStateService.setUploadState(UploadState.removedAllFiles);
    }
  }

  cancelEventHandler(event: CancelEvent) {
    this.filesCount--;
    if (this.filesCount === 0) {
      this.uploadStateService.setUploadState(UploadState.removedAllFiles);
    }
  }

  GetProjectsList() {
    const PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASProjectsAppnURL + "GetProjectsList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.projectsList = serviceResponse.ProjectList;
      });
  }

  GetModulesList() {
    const PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASModulesAppnURL + "GetProjectModules",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.ModulesList = serviceResponse.ModuleList;
      });
  }

  GetSeverityList() {
    const PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "OASIssueSeverityListView",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.SeverityList = serviceResponse;
      });
  }

  GetEmployeesList() {
    const PostDataToService = {};
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "OrganizationEmployeesList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.EmployeeListInfo = serviceResponse.EmployeeList;
        this.EmployeeObserversListInfo = serviceResponse.EmployeeList;
        this.EmployeeResponsbilePersonListInfo = serviceResponse.EmployeeList;
      });
  }

  // GetTaskAssignedViaTypesList() {
  //   const PostDataToService = {};
  //   this.commonhelperobj.PostData(Config.OASModulesAppnURL + 'GetTaskAssignedViaTypesList', PostDataToService).subscribe(serviceResponse => {
  //     if (this.isError(serviceResponse)) { return; }
  //     this.AssignedViaTypesList = serviceResponse.AssignedViaTypesModuleList;
  //   });
  // }

  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "d")}` : "";
  }

  onRequiredByDateChange(value: Date) {
    // let dateeee = this.formatValue(this.TaskAddInfoModel.RequiredByDate);

    // //return value ? ` - ${this.intl.formatDate(value, 'd')}` : '';
    // console.log(dateeee);
    // console.log(this.TaskAddInfoModel.RequiredByDate);

    if (!this.hasValue(this.TaskAddInfoModel.RequiredByDate)) {
      this.TaskAddInfoModel.RequiredByDate = "";
      this.CreateTaskInfo.nativeElement.querySelector(
        "#TaskAddNewExpectedDeliveryDate"
      ).value = "";
      //   this.ShowErrorMessage('Invalid Day, Month or Year Range Detected.');
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewExpectedDeliveryDatename" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].value = "";
      }
      // this.TaskAddInfoModel.RequiredByDate = undefined;
      return;
    }

    // if (this.hasValue(this.TaskAddInfoModel.RequiredByDate) && this.TaskAddInfoModel.RequiredByDate <= 0) {
    //   this.TaskAddInfoModel.RequiredByDate = '';
    //   return;
    // }
    var today = new Date();
    var requiredByDate = new Date(this.TaskAddInfoModel.RequiredByDate);
    const daysDifference = this.DateDiffInDays(
      today,
      this.TaskAddInfoModel.RequiredByDate
    );
    const yearsDifference = requiredByDate.getFullYear() - today.getFullYear();
    // if (this.hasValue(daysDifference) && daysDifference > 0) {
    //   this.ShowErrorMessage('Expected Delivery Date should be greater than or equal to current date.');
    //   const elementFound = this.CreateTaskInfo.nativeElement.querySelector('[name="' + 'TaskAddNewExpectedDeliveryDatename' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].children[0].children[0].children[0].focus();
    //   }
    //   return;
    // }
    if (this.hasValue(yearsDifference) && yearsDifference > 100) {
      this.ShowErrorMessage("Invalid Day, Month or Year Range Detected.");
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewExpectedDeliveryDatename" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return;
    }
  }

  // processUploadedFiles() {
  //   return new Promise((resolve) => {
  //     if (this.hasValue(this.myFiles) && this.myFiles.length > 0) {

  //       // let totalFilesSize: number;

  //       for (let uploadedFileIndex = 0; uploadedFileIndex < this.myFiles.length; uploadedFileIndex++) {

  //         const uploadedFile = this.myFiles[uploadedFileIndex];

  //         if (!uploadedFile.validationErrors) {
  //           const reader = new FileReader();

  //           // asyncronously triggered when file loads
  //           reader.onload = (ev) => {
  //             const UploadedFileSrc = (ev.target as any).result;

  //             const UplaodedFileInBase64Format = window.btoa(unescape(encodeURIComponent(UploadedFileSrc.split('base64,')[1])));

  //             let FileType = this.attachmentsService.calculateAttachmentType(uploadedFile.extension);

  //             this.AttachmentsModel = {
  //               AttachmentBase64Format: UplaodedFileInBase64Format,
  //               AttachmentType: FileType,
  //             };

  //             this.AttachmentsModelList.push(this.AttachmentsModel);

  //             if (uploadedFileIndex === this.myFiles.length - 1) {
  //               // when all files are processed (i.e) pushed to array stop the promise
  //               resolve('All files processed');
  //             }
  //           };

  //           reader.readAsDataURL(uploadedFile.rawFile);
  //         }
  //       }
  //     } else {
  //       resolve('No files to process');
  //     }
  //   });

  // }
  updateDownloadHref(value) {
    return new Promise((resolve, reject) => {
      var blob = value;
      var blobUrl = blob;
      const attachmentContainerfromEditor: Array<Attachment> = [];
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";

      xhr.onload = () => {
        var recoveredBlob = xhr.response;

        var reader = new FileReader();

        reader.onload = () => {
          var blobAsDataUrl = reader.result;
          window.location = blobAsDataUrl;

          const base64 = window.btoa(
            unescape(encodeURIComponent(blobAsDataUrl.split("base64,")[1]))
          );
          // if (this.hasValue(base64)) {
          const fileType = 2;
          // from extension dtermine the fileType
          // here fileType is an number 2 - png, 5 - pdf etc

          var attachment = {
            AttachmentBase64Format: base64,
            AttachmentType: fileType,
            AttachmentName: "",
          };

          this.AttamentsContainerforImagesFromEditor.push(attachment);
          attachmentContainerfromEditor.push(attachment);
          // }

          resolve(attachmentContainerfromEditor);
        };

        reader.readAsDataURL(recoveredBlob);
      };

      xhr.open("GET", blobUrl);
      xhr.send();
      // this.AttamentsContainerforImagesFromEditor = attachmentContainerfromEditor;
    });
  }

  imageupdateDownloadHref(value) {
    return new Promise((resolve, reject) => {
      var blob = value;
      var blobUrl = blob;
      this.originalImageUrl = "";
      const attachmentContainerfromEditor: Array<Attachment> = [];
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";

      xhr.onload = () => {
        var recoveredBlob = xhr.response;

        var reader = new FileReader();

        reader.onload = () => {
          var blobAsDataUrl = reader.result;
          window.location = blobAsDataUrl;

          // const base64 = window.btoa(unescape(encodeURIComponent(blobAsDataUrl.split('base64,')[1])));
          // // if (this.hasValue(base64)) {
          // const fileType = 2
          // // from extension dtermine the fileType
          // // here fileType is an number 2 - png, 5 - pdf etc

          // var attachment = {
          //   AttachmentBase64Format: base64,
          //   AttachmentType: fileType,
          //   AttachmentName: "",
          // }
          this.originalImageUrl = blobAsDataUrl;
          // this.AttamentsContainerforImagesFromEditor.push(attachment);
          // attachmentContainerfromEditor.push(attachment);
          // }

          resolve(attachmentContainerfromEditor);
        };

        reader.readAsDataURL(recoveredBlob);
      };

      xhr.open("GET", blobUrl);
      xhr.send();
      // this.AttamentsContainerforImagesFromEditor = attachmentContainerfromEditor;
    });
  }

  actionCompleteHandler(e) {
    var m,
      urls = [],
      rex = /<img.*?src="([^">]*\/([^">]*?))".*?>/g;
    var ImagesinEditorList = [];
    var div = document.createElement("div");
    div.innerHTML = this.TaskAddInfoModel.SelectedTaskBody;
    var imagesinEditor = div.getElementsByTagName("img");
    ImagesinEditorList.push(imagesinEditor);
    ImagesinEditorList.forEach((element) => {
      // console.log(element);
    });

    //check string has image tag or not
    const GetMainBodyAttachmentsList = $(
      this.TaskAddInfoModel.SelectedTaskBody
    );
    const collectionofAttachments = GetMainBodyAttachmentsList.find("img");
    let collectionofimgsLength2: any;
    collectionofimgsLength2 = collectionofAttachments.length;
    for (let index2 = 0; index2 < collectionofimgsLength2; index2++) {
      if (
        collectionofAttachments[index2].attributes["src"].value.includes(
          "blob"
        ) == true
      ) {
        this.imageupdateDownloadHref(
          collectionofAttachments[index2].attributes["src"].value
        ).then(() => {
          collectionofAttachments[index2].attributes["src"].value =
            this.originalImageUrl;
          this.TaskAddInfoModel.SelectedTaskBody.replace(
            /<img[^>]*>/g,
            this.originalImageUrl
          );
        });
      }
    }
    //console.log(this.TaskAddInfoModel.SelectedTaskBody);
    //replace blob url to base 64 url

    //find image and replace with base64 src value

    // if (rex.test(this.TaskAddInfoModel.SelectedTaskBody) == true) {
    //   const GetMainBodyAttachmentsList = $(this.TaskAddInfoModel.SelectedTaskBody);
    //   const collectionofAttachments = GetMainBodyAttachmentsList.find('img');
    //   let collectionofimgsLength2: any;
    //   collectionofimgsLength2 = collectionofAttachments.length;
    //   for (let index2 = 0; index2 < collectionofimgsLength2; index2++) {
    //     if (collectionofAttachments[index2].attributes['src'].value.includes("blob") == true) {
    //       this.imageupdateDownloadHref(collectionofAttachments[index2].attributes['src'].value).then(() => {
    //         collectionofAttachments[index2].attributes['src'].value = this.originalImageUrl;
    //         this.TaskAddInfoModel.SelectedTaskBody.replace(/<img[^>]*>/g, this.originalImageUrl);
    //       });
    //     }
    //   }

    // }

    while ((m = rex.exec(this.TaskAddInfoModel.SelectedTaskBody))) {
      urls.push(m[1]);
    }
    //console.log(urls);

    //console.log(this.TaskAddInfoModel.SelectedTaskBody);
  }

  // Save Task Info
  async SaveTaskDetails(IsFromSaveAndCreateAnother) {
    // alert(this.TaskAddInfoModel.SelectedTaskBody.value);

    if (!this.hasValue(this.TaskAddInfoModel.ModuleId)) {
      this.ShowErrorMessage("Please Select Module");
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewModuleName" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }

    if (!this.hasValue(this.TaskAddInfoModel.IssueSubject)) {
      this.ShowErrorMessage("Please Enter Task Subject");
      this.CreateTaskInfo.nativeElement
        .querySelector("#TaskAddNewSubject")
        .focus();
      return;
    }
    if (
      !this.hasValue(this.TaskAddInfoModel.Task_Current_Working_Status_InfoID)
    ) {
      this.ShowErrorMessage("Please Select Current Working Location");
      //this.CreateTaskInfo.nativeElement.querySelector('#TaskAddNewCurrentWokringStatus').focus();
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "createNewTaskCurrentStatus" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }

    // if (!this.hasValue(this.TaskAddInfoModel.IssueSubject)) {
    //   //console.log('editorTextValue - ' + editorTextValue);
    //   //allowable characters for taskSubject - 512. If the first sentence has characters>512, setting the length to 512
    //   var i = this.TaskAddInfoModel.SelectedTaskBody.indexOf(".");
    //   if (i < 0 || i > 50)
    //     i = 50;
    //   this.TaskAddInfoModel.IssueSubject = this.TaskAddInfoModel.SelectedTaskBody.substring(0, i);
    //   //console.log('$scope.SelectedIssueSubject - ' + $scope.SelectedIssueSubject);
    // }

    // if (!this.hasValue(this.TaskAddInfoModel.IssueSubject)) {
    //   this.TaskAddInfoModel.IssueSubject = "(No Subject)";
    // }

    if (!this.hasValue(this.TaskAddInfoModel.SelectedTaskBody)) {
      this.ShowErrorMessage("Please Enter Task Details");
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewTaskDetails" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[1].children[0].focus();
      }
      return;
    }

    if (!this.hasValue(this.TaskAddInfoModel.SelectedQAPerson)) {
      this.ShowErrorMessage("Please Select QA Person.");
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewQAPerson" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }
    
    if (!this.hasValue(this.TaskAddInfoModel.SelectedResponsiblePerson)) {
      this.ShowErrorMessage("Please Select Responsible Person.");
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewResponsiblePerson" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }


    // if (!this.hasValue(this.TaskAddInfoModel.SelectedCSharpPerson)) {
    //   this.ShowErrorMessage("Please Select C# Person.");
    //   const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
    //     '[name="' + "TaskAddNewCSharpPerson" + '"]'
    //   );
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].focus();
    //   }
    //   return;
    // }

    // if (!this.hasValue(this.TaskAddInfoModel.SelectedDBPerson)) {
    //   this.ShowErrorMessage("Please Select DB Person.");
    //   const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
    //     '[name="' + "TaskAddNewDBPerson" + '"]'
    //   );
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].focus();
    //   }
    //   return;
    // }

    // if (!this.hasValue(this.TaskAddInfoModel.SelectedJSPerson)) {
    //   this.ShowErrorMessage("Please Select Angular JS Person.");
    //   const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
    //     '[name="' + "TaskAddNewJSPerson" + '"]'
    //   );
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].focus();
    //   }
    //   return;
    // }

    const daysDifference = this.DateDiffInDays(
      new Date(),
      this.TaskAddInfoModel.RequiredByDate
    );
    if (this.hasValue(daysDifference) && daysDifference > 0) {
      this.ShowErrorMessage(
        "Required By Date Should be Greater than or Equal to Current Date."
      );
      const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
        '[name="' + "TaskAddNewExpectedDeliveryDatename" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return;
    }

    if (!this.uploadStateService.isValidState()) {
      this.ShowErrorMessage("Please Wait till the files are uploaded");
      return;
    }

    let attachmentContainer: Array<Attachment> =
      await this.attachmentsService.processUploadAttachments(this.myFiles);

    if (
      this.attachmentsService.duplicatesExists(
        attachmentContainer as Array<Attachment>
      )
    ) {
      this.ShowErrorMessage(
        "Duplicates Exists in Uploaded Attachments Please Try Again."
      );
      return;
    }

    if (
      !this.attachmentsService.uploadedAttachmentsCompatable(
        attachmentContainer
      )
    ) {
      this.ShowErrorMessage(
        "Uploaded attachments contain files other than JPG,JPEG,PNG,DocX,Doc,XLSX,XLs and PDF files."
      );
      this.myFiles = [];
      return;
    }

    if (
      this.hasValue(this.SelectedAssistantPerson) &&
      this.SelectedAssistantPerson.length > 0
    ) {
      this.SelectedAssistantPerson.forEach((selectedRow, index) => {
        if (
          this.SelectedResponsiblePersonInfo.EmployeeId ==
          selectedRow.EmployeeId
        ) {
          this.IsresponsiblePersonAndFwdToSame = true;
        }
      });
    }

    // if (this.IsresponsiblePersonAndFwdToSame == true) {
    //   this.ShowErrorMessage('Responsible Person and Forward To Should not be Same.');
    //   this.IsresponsiblePersonAndFwdToSame = false;
    //   return;
    // }

    // const GetMainBodyAttachmentsList = $(this.TaskAddInfoModel.SelectedTaskBody);

    // const collectionofAttachments = GetMainBodyAttachmentsList.find('img');
    // let collectionofimgsLength2: any;
    // collectionofimgsLength2 = collectionofAttachments.length;

    // var imgRegex = new RegExp("<img[^>]*?>", "g");

    // this.TaskAddInfoModel.SelectedTaskBody = this.TaskAddInfoModel.SelectedTaskBody.replace(this.TaskAddInfoModel.SelectedTaskBody.match(imgRegex), "")

    // var promises = [];
    // for (let index2 = 0; index2 < collectionofimgsLength2; index2++) {
    //   promises.push(this.updateDownloadHref(collectionofAttachments[index2].attributes['src'].value));
    // }

    // Promise.all(promises).then(() => {
    if (this.hasValue(this.TaskAddInfoModel.SelectedTaskBody)) {
      const body: Attachment = {
        AttachmentBase64Format: window.btoa(
          unescape(encodeURIComponent(this.TaskAddInfoModel.SelectedTaskBody))
        ),
        AttachmentType: 1,
      };

      attachmentContainer.push(body);
    }
    if (
      this.hasValue(this.AttamentsContainerforImagesFromEditor) &&
      this.AttamentsContainerforImagesFromEditor.length > 0
    ) {
      for (
        let index2 = 0;
        index2 < this.AttamentsContainerforImagesFromEditor.length;
        index2++
      ) {
        const Attachment = {
          AttachmentBase64Format:
            this.AttamentsContainerforImagesFromEditor[index2]
              .AttachmentBase64Format,
          AttachmentType: 2,
        };

        attachmentContainer.push(Attachment);
      }
    }

    if (
      this.hasValue(this.selectedAdditionalResponsiblePersons) &&
      this.selectedAdditionalResponsiblePersons.length > 0
    ) {
      this.selectedAdditionalResponsiblePersons.forEach((element) => {
        this.EmployeesAndRolesModel = {
          EmployeeId: element.EmployeeId,
          RoleID: 4,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }

    if (
      this.hasValue(this.SelectedObservers) &&
      this.SelectedObservers.length > 0
    ) {
      this.SelectedObservers.forEach((element) => {
        this.EmployeesAndRolesModel = {
          EmployeeId: element.EmployeeId,
          RoleID: 3,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }

    // console.log(this.TaskAddInfoModel);
    if (this.hasValue(this.TaskAddInfoModel.SelectedResponsiblePerson)) {
      this.EmployeesAndRolesModel = {
        EmployeeId: this.TaskAddInfoModel.SelectedResponsiblePerson,
        RoleID: 2,
      };
      this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
    }

    //QA
    if (this.hasValue(this.TaskAddInfoModel.SelectedQAPerson)) {
      this.EmployeesAndRolesModel = {
        EmployeeId: this.TaskAddInfoModel.SelectedQAPerson,
        RoleID: 8,
      };
      this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
    }
    //C#
    if (this.hasValue(this.TaskAddInfoModel.SelectedCSharpPerson)) {
      this.EmployeesAndRolesModel = {
        EmployeeId: this.TaskAddInfoModel.SelectedCSharpPerson,
        RoleID: 9,
      };
      this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
    }
    //DB
    if (this.hasValue(this.TaskAddInfoModel.SelectedDBPerson)) {
      this.EmployeesAndRolesModel = {
        EmployeeId: this.TaskAddInfoModel.SelectedDBPerson,
        RoleID: 10,
      };
      this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
    }
    //JS
    if (this.hasValue(this.TaskAddInfoModel.SelectedJSPerson)) {
      this.EmployeesAndRolesModel = {
        EmployeeId: this.TaskAddInfoModel.SelectedJSPerson,
        RoleID: 11,
      };
      this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
    }

    if (
      this.hasValue(this.SelectedAssistantPerson) &&
      this.SelectedAssistantPerson.length > 0
    ) {
      this.SelectedAssistantPerson.forEach((Assistantselement) => {
        this.EmployeesAndRolesModel = {
          EmployeeId: Assistantselement.EmployeeId,
          RoleID: 7,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }

    if (this.EmployeesAndRolesModelList.length > 0) {
      this.TaskAddInfoModel.EmployeesAndRolesModelList =
        this.EmployeesAndRolesModelList;
    }

    if (attachmentContainer.length > 0) {
      this.TaskAddInfoModel.AttachmentsModelList = attachmentContainer;
    }

    this.TaskAddInfoModel.taskChangeEvents = [
      {
        Description: "Task Created",
        EventId: TaskEventTypeEnum.TaskCreated,
        TaskInfoId: 0,
      },
    ] as TaskChangeEvent[];
    if (
      this.hasValue(this.TaskAddInfoModel.TaskTypeId) &&
      this.hasValue(this.TaskAddInfoModel.TaskTypeId.TaskTypeId)
    ) {
      this.TaskAddInfoModel.TaskTypeId =
        this.TaskAddInfoModel.TaskTypeId.TaskTypeId;
    }
    // else {
    //   this.TaskAddInfoModel.TaskTypeId = undefined;
    // }
    this.TaskAddInfoModel.IsPublicTask = false;
    this.TaskAddInfoModel.IsTaskCreatedfromEHR = false;
    if (this.hasValue(this.TaskAddInfoModel.RequiredByDate)) {
      this.TaskAddInfoModel.RequiredByDate = this.formatValue(
        this.TaskAddInfoModel.RequiredByDate
      );
    }

    if (
      this.hasValue(this.TaskAddInfoModel.Task_Current_Working_Status_InfoID) &&
      this.hasValue(
        this.TaskAddInfoModel.Task_Current_Working_Status_InfoID
          .Task_Current_Working_Status_InfoID
      )
    ) {
      this.TaskAddInfoModel.Task_Current_Working_Status_InfoID =
        this.TaskAddInfoModel.Task_Current_Working_Status_InfoID.Task_Current_Working_Status_InfoID;
    }

    if (
      this.hasValue(this.TaskAddInfoModel.TaskCamefromType) &&
      this.hasValue(
        this.TaskAddInfoModel.TaskCamefromType.selectTaskFromListTextValue
      )
    ) {
      this.TaskAddInfoModel.TaskCamefromType =
        this.TaskAddInfoModel.TaskCamefromType.selectTaskFromListTextValue;
    }
    // 1 -> means- saving Internal Issues
    this.TaskAddInfoModel.IsTaskFrom = 1;
    this.TaskAddInfoModel.IssueSupportGroupID =
      this.TaskAddInfoModel.ModuleId;
    //Module ID not Required
    this.TaskAddInfoModel.ModuleId = null;
    // console.log(this.TaskAddInfoModel.RequiredByDate);
    // setTimeout(() => {
    // tslint:disable-next-line:max-line-length
    if (IsFromSaveAndCreateAnother == 3) {
      const dialogRef = this.dialogService.open({
        title: "Template Name",
        content: SaveTemplateNamePopupComponent,
        height: 698,
        width: 550,
        appendTo: this.ContainerRef,
      });
      dialogRef.content.instance.SaveTemplateInformation =
        this.TaskAddInfoModel;
      dialogRef.result.subscribe((result) => {
        //debugger;
        if (result instanceof DialogCloseResult) {
          this.EmployeesAndRolesModelList = [];
          attachmentContainer = [];
        } else {
          this.ShowSuccesseMessage("Template Saved Successfully.");

          this.CreateTaskCancelClick("Success");

          //       if(this.hasValue(result)){
          //         this.TaskAddInfoModel.TemplateName=result;

          //   this.commonhelperobj.PostData(Config.OASAppnURL + 'SaveCreateNewTemplateofOASIssueDetails', this.TaskAddInfoModel).subscribe(serviceResponse => {
          //     if (this.isError(serviceResponse)) {
          //       this.EmployeesAndRolesModelList = [];
          //       attachmentContainer = [];
          //       return;
          //     }
          //     this.ShowSuccesseMessage('Template Saved Successfully.');
          //     const PostDataService = {};
          //     const outletObj = {};
          //     outletObj['main'] = ['app-common-message-info', this.setCommonData(PostDataService)];
          //     this._Router.navigate(['/home', { outlets: outletObj }]);

          //   });

          // }
        }
      });
    } else {
      this.commonhelperobj
        .PostData(
          Config.OASAppnURL + "SaveReportOASIssueDetails",
          this.TaskAddInfoModel
        )
        .subscribe((serviceResponse) => {
          if (this.isError(serviceResponse)) {
            this.EmployeesAndRolesModelList = [];
            attachmentContainer = [];
            return;
          }
          this.sendInternalTicketNotificationtoEmployees(
            serviceResponse.ResponseID
          );
          this.ShowSuccesseMessage("Task Details Saved Successfully.");
          const elementFound = this.CreateTaskInfo.nativeElement.querySelector(
            '[name="' + "TaskAddNewProjectName" + '"]'
          );
          if (this.hasValue(elementFound)) {
            elementFound.children[0].focus();
          }
          this.EmployeesAndRolesModelList = [];
          attachmentContainer = [];
          if (IsFromSaveAndCreateAnother === 2) {
            this.SelectedAdditionalResponsiblePersonName = "";
            this.SelectedObserversPersonNames = "";
            this.SelectedAssistantPersonName = "";
            this.SelectedAssistantPerson = undefined;
            //debugger;
            this.myFiles = [];
            this.TaskAddInfoModel.IssueSubject = "";
            this.TaskAddInfoModel.SelectedTaskBody = "";
            this.TaskAddInfoModel.IssueSeverityID = null;
            this.TaskAddInfoModel.TaskTypeId = null;
            //this.SelectedPracticeInfo.PracticeName = "";
            // this.TaskAddInfoModel = {};
            this.TaskAddInfoModel.RequiredByDate = "";
            this.SelectedResponsiblePersonInfo.EmployeeFullName = "";
            this.EmployeesAndRolesModelList = [];
            attachmentContainer = [];
            this.SelectedObservers = [];
            this.selectedAdditionalResponsiblePersons = [];
            this.SelectedPracticeInfo = undefined;
            this.SelectedResponsiblePersonInfo.EmployeeId = undefined;
            this.TaskAddInfoModel.SelectedResponsiblePerson = undefined;

            this.SelectedQAPersonInfo.EmployeeFullName = "";
            this.SelectedQAPersonInfo.EmployeeId = undefined;
            this.TaskAddInfoModel.SelectedQAPerson = undefined;

            this.SelectedCSharpPersonInfo.EmployeeFullName = "";
            this.SelectedCSharpPersonInfo.EmployeeId = undefined;
            this.TaskAddInfoModel.SelectedCSharpPerson = undefined;

            this.SelectedDBPersonInfo.EmployeeFullName = "";
            this.SelectedDBPersonInfo.EmployeeId = undefined;
            this.TaskAddInfoModel.SelectedDBPerson = undefined;

            this.SelectedJSPersonInfo.EmployeeFullName = "";
            this.SelectedJSPersonInfo.EmployeeId = undefined;
            this.TaskAddInfoModel.SelectedJSPerson = undefined;


            this.EmployeesAndRolesModelList = [];
            this.TaskAddInfoModel.AttachmentsModelList = [];
            this.TaskAddInfoModel = {};
          } else {
            this.CreateTaskCancelClick("Success");
          }
        });
    }

    // });
    // }, 100);
  }

  CreateTaskCancelClick(message: string | undefined) {
    if (this.fromInternalTicketNavigation) {
      this._dialog.close(message);
    } else {
      const PostDataService = {};
      const outletObj = {};
      outletObj["main"] = [
        "app-common-message-info",
        this.setCommonData(PostDataService),
      ];
      this._Router.navigate(["/home", { outlets: outletObj }]);
    }
  }

  responsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Responsible Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedResponsiblePersonInfo)) {
        } else {
          this.SelectedResponsiblePersonInfo = "";
        }
      } else {
        this.SelectedResponsiblePersonInfo = result;
        if (this.hasValue(this.SelectedResponsiblePersonInfo)) {
          this.TaskAddInfoModel.SelectedResponsiblePerson =
            this.SelectedResponsiblePersonInfo.EmployeeId;
          this.SelectedResponsiblePersonInfo.EmployeeId =
            this.SelectedResponsiblePersonInfo.EmployeeId;
          this.SelectedResponsiblePersonInfo.EmployeeFullName =
            this.SelectedResponsiblePersonInfo.EmployeeFullName;
        }
      }
    });
  }

  RemoveResponsiblePersonInfo() {
    this.TaskAddInfoModel.SelectedResponsiblePerson = undefined;
    this.SelectedResponsiblePersonInfo.EmployeeId = undefined;
    this.SelectedResponsiblePersonInfo.EmployeeFullName = "";
  }

  //#region  " QA "
  qaPersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select QA Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedQAPersonInfo)) {
        } else {
          this.SelectedQAPersonInfo = "";
        }
      } else {
        this.SelectedQAPersonInfo = result;
        if (this.hasValue(this.SelectedQAPersonInfo)) {
          this.TaskAddInfoModel.SelectedQAPerson =
            this.SelectedQAPersonInfo.EmployeeId;
          this.SelectedQAPersonInfo.EmployeeId =
            this.SelectedQAPersonInfo.EmployeeId;
          this.SelectedQAPersonInfo.EmployeeFullName =
            this.SelectedQAPersonInfo.EmployeeFullName;
        }
      }
    });
  }

  RemoveQAPersonInfo() {
    this.TaskAddInfoModel.SelectedQAPerson = undefined;
    this.SelectedQAPersonInfo.EmployeeId = undefined;
    this.SelectedQAPersonInfo.EmployeeFullName = "";
  }
  //#endregion


  //#region  " C# "
  csharpPersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select C# Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedCSharpPersonInfo)) {
        } else {
          this.SelectedCSharpPersonInfo = "";
        }
      } else {
        this.SelectedCSharpPersonInfo = result;
        if (this.hasValue(this.SelectedCSharpPersonInfo)) {
          this.TaskAddInfoModel.SelectedCSharpPerson =
            this.SelectedCSharpPersonInfo.EmployeeId;
          this.SelectedCSharpPersonInfo.EmployeeId =
            this.SelectedCSharpPersonInfo.EmployeeId;
          this.SelectedCSharpPersonInfo.EmployeeFullName =
            this.SelectedCSharpPersonInfo.EmployeeFullName;
        }
      }
    });
  }

  RemoveCSharpPersonInfo() {
    this.TaskAddInfoModel.SelectedCSharpPerson = undefined;
    this.SelectedCSharpPersonInfo.EmployeeId = undefined;
    this.SelectedCSharpPersonInfo.EmployeeFullName = "";
  }
  //#endregion


  //#region  " DB "
  dbPersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select DB Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedDBPersonInfo)) {
        } else {
          this.SelectedDBPersonInfo = "";
        }
      } else {
        this.SelectedDBPersonInfo = result;
        if (this.hasValue(this.SelectedDBPersonInfo)) {
          this.TaskAddInfoModel.SelectedDBPerson =
            this.SelectedDBPersonInfo.EmployeeId;
          this.SelectedDBPersonInfo.EmployeeId =
            this.SelectedDBPersonInfo.EmployeeId;
          this.SelectedDBPersonInfo.EmployeeFullName =
            this.SelectedDBPersonInfo.EmployeeFullName;
        }
      }
    });
  }

  RemoveDBPersonInfo() {
    this.TaskAddInfoModel.SelectedDBPerson = undefined;
    this.SelectedDBPersonInfo.EmployeeId = undefined;
    this.SelectedDBPersonInfo.EmployeeFullName = "";
  }
  //#endregion

  //#region  " JS "
  jsPersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Angular JS Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedJSPersonInfo)) {
        } else {
          this.SelectedJSPersonInfo = "";
        }
      } else {
        this.SelectedJSPersonInfo = result;
        if (this.hasValue(this.SelectedJSPersonInfo)) {
          this.TaskAddInfoModel.SelectedJSPerson =
            this.SelectedJSPersonInfo.EmployeeId;
          this.SelectedJSPersonInfo.EmployeeId =
            this.SelectedJSPersonInfo.EmployeeId;
          this.SelectedJSPersonInfo.EmployeeFullName =
            this.SelectedJSPersonInfo.EmployeeFullName;
        }
      }
    });
  }

  RemoveJSPersonInfo() {
    this.TaskAddInfoModel.SelectedJSPerson = undefined;
    this.SelectedJSPersonInfo.EmployeeId = undefined;
    this.SelectedJSPersonInfo.EmployeeFullName = "";
  }
  //#endregion

  practiceIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Practice",
      // Show component
      content: PraticesListComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.TaskAddInfoModel.PracticeName)) {
        } else {
          this.TaskAddInfoModel.PracticeName = "";
        }
      } else {
        this.SelectedPracticeInfo = result;
        if (this.hasValue(this.SelectedPracticeInfo)) {
          this.TaskAddInfoModel.PracticeName =
            this.SelectedPracticeInfo.PracticeName;
          this.TaskAddInfoModel.EHRPracticeID =
            this.SelectedPracticeInfo.PracticeId;
        }
      }
    });
  }
  createNewTaskCreateNewTemplateLinkClick() {
    this.addinternalpopupmethodCalling();
    const dialogRef = this.dialogService.open({
      title: "Create New Template",
      // Show component
      content: CreateNewTemplateComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: this.createNewTemplatePopupHeight,
      width: this.createNewTemplatePopupWidth,
      appendTo: this.ContainerRef,
    });
  }
  private addinternalpopupmethodCalling() {
    if ($(window).width() >= 1920) {
      this.createNewTemplatePopupWidth = 1850;
      this.createNewTemplatePopupHeight = 740;
    } else if ($(window).width() >= 1680 && $(window).width() <= 1720) {
      this.createNewTemplatePopupWidth = 1600;
      this.createNewTemplatePopupHeight = 740;
    }
  }
  RemovePracticeInfo() {
    this.SelectedPracticeInfo.PracticeName = "";
    this.SelectedPracticeInfo.EHRPracticeID = undefined;
    this.TaskAddInfoModel.EHRPracticeID = undefined;
    this.TaskAddInfoModel.PracticeName = "";
    document.getElementById("spanSelectPracticeIcon").focus();
  }

  CreateTaskmoduleIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Module",
      // Show component
      content: SelectModulePopupComponentComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.TaskAddInfoModel.ModuleName)) {
        } else {
          this.TaskAddInfoModel.ModuleName = "";
        }
      } else {
        this.ModuleListInfo = result;
        if (this.hasValue(this.ModuleListInfo)) {
          // this.TaskAddInfoModel.ModuleId = this.ModuleListInfo.ModuleId;
          // this.TaskAddInfoModel.ModuleName = this.ModuleListInfo.Description;
          this.TaskAddInfoModel.ModuleId =
            this.ModuleListInfo.IssueSupportGroupID;
          this.TaskAddInfoModel.ModuleName =
            this.ModuleListInfo.IssueSupportGroupDesc;
        }
      }
    });
  }

  CreateTaskRemoveModuleInfo() {
    this.TaskAddInfoModel.ModuleId = undefined;
    this.TaskAddInfoModel.ModuleName = "";
    document.getElementById("spanSelectModuleIcon").focus();
  }

  createTaskuserIconClickEvent() {
    if (!this.hasValue(this.TaskAddInfoModel.PracticeName)) {
      this.ShowErrorMessage("Please Select Practice.");
      return false;
    }

    let senddatamodel = {};
    senddatamodel = {
      PracticeId: this.TaskAddInfoModel.EHRPracticeID,
      UsersArray: this.SelectedPracticeUserInformation,
    };
    setTimeout(() => {
      this.LeftNav.GetEditModeData(senddatamodel);
    }, 10);

    const dialogRef = this.dialogService.open({
      title: "Practice Users Information",
      // Show component
      content: PracticeusersinformationcomponentComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 696,
      width: 350,
      appendTo: this.ContainerRef,
    });
    //dialogRef.content.instance.IsFromSingleSelectionToPopup = true;
    dialogRef.result.subscribe((result) => {
      // if (result instanceof DialogCloseResult) {
      // } else {
      //   // this.SelectedPracticeUserList = [];
      //   this.SelectedPracticeUserList = result;
      //   this.TaskAddInfoModel.SelectedPracticeUsers = result.EmployeeFullName;
      // }

      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.TaskAddInfoModel.TaskAssignedViaUser)) {
        } else {
          this.TaskAddInfoModel.TaskAssignedViaUser = "";
        }
      } else {
        this.SelectedPracticeUserInformation = result;
        if (this.hasValue(this.SelectedPracticeUserInformation)) {
          this.TaskAddInfoModel.TaskAssignedViaUser =
            this.SelectedPracticeUserInformation.EmployeeName;
          // this.TaskAddInfoModel.EHRPracticeID = this.SelectedPracticeInfo.PracticeId;
        }
      }
    });
  }

  RemovePracticeUsersInfo() {
    this.SelectedPracticeUserInformation = undefined;
    this.TaskAddInfoModel.TaskAssignedViaUser = "";
  }

  AdditionalResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    //dialogRef.content.instance.IsFromForwardToPopup = true;
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData =
      this.selectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData =
      this.SelectedObservers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData =
      this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
        } else {
          this.SelectedAdditionalResponsiblePersonInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        //********assigning addl responsible resons info************
        this.SelectedAdditionalResponsiblePersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.selectedAdditionalResponsiblePersons =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );
          this.SelectedAdditionalResponsiblePersonName =
            this.SelectedAdditionalResponsiblePersonName.substring(
              0,
              this.SelectedAdditionalResponsiblePersonName.length - 2
            );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        //*****assigning observers data****************
        this.SelectedObserversInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.SelectedObservers =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedObserversPersonNames =
            this.SelectedObserversPersonNames.substring(
              0,
              this.SelectedObserversPersonNames.length - 2
            );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        this.SelectedAssistantPersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        this.SelectedAssistantPerson =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedAssistantPersonName =
            this.SelectedAssistantPersonName.substring(
              0,
              this.SelectedAssistantPersonName.length - 2
            );
        } else {
          this.SelectedAssistantPersonName = "";
        }
      }
    });
  }

  RemoveAdditionalResponsiblePersonInfo() {
    this.SelectedAdditionalResponsiblePersonName = "";
    this.selectedAdditionalResponsiblePersons = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }

  ObserversIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    // dialogRef.content.instance.IsFromForwardToPopup = true;
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData =
      this.selectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData =
      this.SelectedObservers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData =
      this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedObserversInfo)) {
        } else {
          this.SelectedObserversInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        //********assigning addl responsible resons info************
        this.SelectedAdditionalResponsiblePersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.selectedAdditionalResponsiblePersons =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );
          this.SelectedAdditionalResponsiblePersonName =
            this.SelectedAdditionalResponsiblePersonName.substring(
              0,
              this.SelectedAdditionalResponsiblePersonName.length - 2
            );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        //*****assigning observers data****************
        this.SelectedObserversInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.SelectedObservers =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedObserversPersonNames =
            this.SelectedObserversPersonNames.substring(
              0,
              this.SelectedObserversPersonNames.length - 2
            );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        this.SelectedAssistantPersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        this.SelectedAssistantPerson =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedAssistantPersonName =
            this.SelectedAssistantPersonName.substring(
              0,
              this.SelectedAssistantPersonName.length - 2
            );
        } else {
          this.SelectedAssistantPersonName = "";
        }
      }
    });
  }

  RemoveObserversIconClickEvent() {
    this.SelectedObserversPersonNames = "";
    this.SelectedObservers = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }

  // assistantPersonIconClickEvent() {
  //   //this.IsresponsiblePersonAndFwdToSame = false;
  //   const dialogRef = this.dialogService.open({
  //     title: 'Select Person to Forward Task',
  //     // Show component
  //     content: AdditionalParticipantAndObserversPopupComponentComponent,
  //     height: 720,
  //     width: 1140,
  //     appendTo: this.ContainerRef,
  //   });
  //   // dialogRef.content.instance.IsFromForwardToPopup = true;
  //   dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData = this.selectedAdditionalResponsiblePersons;
  //   dialogRef.content.instance.StateMaintainObserverPersonsData = this.SelectedObservers;
  //   dialogRef.content.instance.StateMaintainAssistantPersonsData = this.SelectedAssistantPerson;
  //   dialogRef.result.subscribe((result) => {
  //     //debugger;

  //     if (result instanceof DialogCloseResult) {
  //       if (this.hasValue(this.SelectedAssistantPersonInfo)) {

  //       } else {
  //         this.SelectedAssistantPersonInfo = '';
  //       }
  //     } else {
  //       //assign popup result data to global variable
  //       this.PopupDataFromMultiSelectUserSelectionPopup = result;

  //       //********assigning addl responsible resons info************
  //       this.SelectedAdditionalResponsiblePersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
  //       this.selectedAdditionalResponsiblePersons = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
  //       if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
  //         this.SelectedAdditionalResponsiblePersonName = "";
  //         this.SelectedAdditionalResponsiblePersonInfo.forEach((selectedRow, index) => {
  //           this.SelectedAdditionalResponsiblePersonName += selectedRow.EmployeeFullName + ', ';
  //         });
  //         this.SelectedAdditionalResponsiblePersonName = this.SelectedAdditionalResponsiblePersonName.substring(0, this.SelectedAdditionalResponsiblePersonName.length - 2);
  //       }
  //       else {
  //         this.SelectedAdditionalResponsiblePersonName = "";
  //       }

  //       //*****assigning observers data****************
  //       this.SelectedObserversInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
  //       this.SelectedObservers = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
  //       if (this.hasValue(this.SelectedObserversInfo)) {
  //         this.SelectedObserversPersonNames = "";
  //         this.SelectedObserversInfo.forEach((selectedRow, index) => {
  //           this.SelectedObserversPersonNames += selectedRow.EmployeeFullName + ', ';
  //         });
  //         this.SelectedObserversPersonNames = this.SelectedObserversPersonNames.substring(0, this.SelectedObserversPersonNames.length - 2);
  //       }
  //       else {
  //         this.SelectedObserversPersonNames = "";
  //       }

  //       //************assigning assistant persons info*************************
  //       this.SelectedAssistantPersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
  //       this.SelectedAssistantPerson = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
  //       if (this.hasValue(this.SelectedAssistantPersonInfo)) {
  //         this.SelectedAssistantPersonName = "";
  //         this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
  //           this.SelectedAssistantPersonName += selectedRow.EmployeeFullName + ', ';
  //         });
  //         this.SelectedAssistantPersonName = this.SelectedAssistantPersonName.substring(0, this.SelectedAssistantPersonName.length - 2);
  //       }
  //       else {
  //         this.SelectedAssistantPersonName = "";
  //       }

  //     }
  //   });
  // }

  RemoveAssistantPersonInfo() {
    this.SelectedAssistantPersonName = "";
    this.SelectedAssistantPerson = undefined;
    this.IsresponsiblePersonAndFwdToSame = false;
  }
  createNewTaskSelectTemplateLinkClick() {
    const dialogRef = this.dialogService.open({
      title: "Select Template",
      // Show component
      content: SelectTemplatePopupComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
      } else {
        this.SelectedObserversPersonNames = "";
        this.SelectedAssistantPersonName = "";
        this.SelectedAdditionalResponsiblePersonName = "";
        this.SelectedResponsiblePersonInfo.EmployeeFullName = "";
        this.SelectedResponsiblePersonInfo.EmployeeId = "";
        this.TaskAddInfoModel.SelectedResponsiblePerson = undefined;

        this.SelectedQAPersonInfo.EmployeeFullName = "";
        this.SelectedQAPersonInfo.EmployeeId = "";
        this.TaskAddInfoModel.SelectedQAPerson = undefined;

        this.SelectedCSharpPersonInfo.EmployeeFullName = "";
        this.SelectedCSharpPersonInfo.EmployeeId = "";
        this.TaskAddInfoModel.SelectedCSharpPerson = undefined;

        this.SelectedDBPersonInfo.EmployeeFullName = "";
        this.SelectedDBPersonInfo.EmployeeId = "";
        this.TaskAddInfoModel.SelectedDBPerson = undefined;

        this.SelectedJSPersonInfo.EmployeeFullName = "";
        this.SelectedJSPersonInfo.EmployeeId = "";
        this.TaskAddInfoModel.SelectedJSPerson = undefined;

        this.SelectedObservers = [];
        this.selectedAdditionalResponsiblePersons = [];
        this.SelectedAssistantPerson = [];
        this.myFiles = [];
        this.TaskAddInfoModel.IssueSubject = "";
        this.TaskAddInfoModel.SelectedTaskBody = "";
        this.TaskAddInfoModel.IssueSeverityID = null;
        this.TaskAddInfoModel.TaskTypeId = null;
        //this.SelectedPracticeInfo.PracticeName = "";
        this.SelectedPracticeInfo = undefined;
        this.TaskAddInfoModel.RequiredByDate = "";
        this.EmployeesAndRolesModelList = [];
        //attachmentContainer = [];
        this.TaskAddInfoModel.AttachmentsModelList = [];
        this.TaskAddInfoModel = {};
        this.selectedtemplate = result;

        if (
          this.hasValue(this.selectedtemplate) &&
          this.hasValue(this.selectedtemplate.TemplateID)
        ) {
          this.SelectedTemplateInfotoStatemaintain(
            this.selectedtemplate.TemplateID
          );
          this.UsersSelectedTemplateInfotoStatemaintain(
            this.selectedtemplate.TemplateID
          );
          this.UsersSelectedAttachmentsTemplateInfotoStatemaintain(
            this.selectedtemplate.TemplateID
          );
        }
      }
    });
  }

  //#region "    STATE MAINTAINENCE     "

  private SelectedTemplateInfotoStatemaintain(SelectedTemplateId) {
    var PostDataToService = {
      TemplateID: SelectedTemplateId,
    };
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "GetOASSlectedTemplateInfo",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        if (
          this.hasValue(serviceResponse) &&
          this.hasValue(serviceResponse.oasissuereportfromcustomermodel)
        ) {
          this.CreateNewTaskStateMaintain(
            serviceResponse.oasissuereportfromcustomermodel
          );
        }
      });
  }

  private CreateNewTaskStateMaintain(oasissuereportfromcustomermodel) {
    this.TaskAddInfoModel.ModuleId = oasissuereportfromcustomermodel.ModuleId;
    this.TaskAddInfoModel.ModuleName =
      oasissuereportfromcustomermodel.ModuleDescription;
    this.TaskAddInfoModel.IssueSubject =
      oasissuereportfromcustomermodel.IssueSubject;
    if (
      this.hasValue(
        oasissuereportfromcustomermodel.TaskCurrentWorkingStatusInfoID
      ) &&
      oasissuereportfromcustomermodel.TaskCurrentWorkingStatusInfoID > 0
    )
      this.TaskAddInfoModel.Task_Current_Working_Status_InfoID = {
        Task_Current_Working_Status_InfoID:
          oasissuereportfromcustomermodel.TaskCurrentWorkingStatusInfoID,
        Task_Current_Working_Status:
          oasissuereportfromcustomermodel.TaskCurrentWorkingStatus,
      };
    if (
      this.hasValue(oasissuereportfromcustomermodel.TaskTypeId) &&
      oasissuereportfromcustomermodel.TaskTypeId > 0
    )
      this.TaskAddInfoModel.TaskTypeId = {
        TaskTypeDescription: oasissuereportfromcustomermodel.TaskType,
        TaskTypeId: oasissuereportfromcustomermodel.TaskTypeId,
      };
    if (
      this.hasValue(oasissuereportfromcustomermodel.TaskCamefromType) &&
      oasissuereportfromcustomermodel.TaskCamefromType > 0
    )
      this.TaskAddInfoModel.TaskCamefromType = {
        selectTaskFromListText:
          oasissuereportfromcustomermodel.TaskCamefromTypeDescription,
        selectTaskFromListTextValue:
          oasissuereportfromcustomermodel.TaskCamefromType,
      };
    if (
      this.hasValue(oasissuereportfromcustomermodel.TaskSeverityID) &&
      oasissuereportfromcustomermodel.TaskSeverityID > 0
    )
      this.TaskAddInfoModel.IssueSeverityID =
        oasissuereportfromcustomermodel.TaskSeverityID;
  }
  private UsersSelectedTemplateInfotoStatemaintain(SelectedTemplateId) {
    var PostDataToService = {
      TemplateInfoID: SelectedTemplateId,
    };
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "OasTemplateLinkedUsers", PostDataToService)
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        if (this.hasValue(serviceResponse)) {
          this.CreateNewTaskStateMaintainofUsers(serviceResponse);
        }
      });
  }

  private CreateNewTaskStateMaintainofUsers(response) {
    response.forEach((TaskListelement) => {
      if (this.hasValue(TaskListelement.TaskTypeId)) {
        if (TaskListelement.TaskTypeId == 2) {
          this.SelectedResponsiblePersonInfo.EmployeeFullName =
            TaskListelement.EmployeeFullName;
          this.SelectedResponsiblePersonInfo.EmployeeId =
            TaskListelement.EmployeeId;
          this.TaskAddInfoModel.SelectedResponsiblePerson =
            TaskListelement.EmployeeId;
        } else if (TaskListelement.TaskTypeId == 3) {
          if (!this.hasValue(this.SelectedObserversPersonNames)) {
            this.SelectedObserversPersonNames =
              TaskListelement.EmployeeFullName;
          } else {
            this.SelectedObserversPersonNames +=
              ", " + TaskListelement.EmployeeFullName;
          }
          this.SelectedObservers.push({
            EmployeeId: TaskListelement.EmployeeId,
            EmployeeFullName: TaskListelement.EmployeeFullName,
          });
        } else if (TaskListelement.TaskTypeId == 7) {
          if (!this.hasValue(this.SelectedAssistantPersonName)) {
            this.SelectedAssistantPersonName = TaskListelement.EmployeeFullName;
          } else {
            this.SelectedAssistantPersonName +=
              ", " + TaskListelement.EmployeeFullName;
          }
          this.SelectedAssistantPerson.push({
            EmployeeId: TaskListelement.EmployeeId,
            EmployeeFullName: TaskListelement.EmployeeFullName,
          });
        } else {
          if (!this.hasValue(this.SelectedAdditionalResponsiblePersonName)) {
            this.SelectedAdditionalResponsiblePersonName =
              TaskListelement.EmployeeFullName;
          } else {
            this.SelectedAdditionalResponsiblePersonName +=
              ", " + TaskListelement.EmployeeFullName;
          }
          this.selectedAdditionalResponsiblePersons.push({
            EmployeeId: TaskListelement.EmployeeId,
            EmployeeFullName: TaskListelement.EmployeeFullName,
          });
        }
      }
    });
  }

  private UsersSelectedAttachmentsTemplateInfotoStatemaintain(
    SelectedTemplateId
  ) {
    var PostDataToService = {
      TemplateInfoID: SelectedTemplateId,
    };
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "OasTemplateAttachmentList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        if (
          this.hasValue(serviceResponse) &&
          this.hasValue(serviceResponse.AttachmentsModelList)
        ) {
          //this.CreateNewTaskStateMaintainofAttachmentList(serviceResponse);
          this.fullattachmentsList = serviceResponse.AttachmentsModelList;

          // let commentscount;
          // if (this.fullattachmentsList.length > 0) {
          //   commentscount = this.fullattachmentsList[0].CommentsCount;
          // }
          // tslint:disable-next-line:radix
          // if (parseInt(commentscount) > 1) {
          //   this.ifCommentsCountisLessThanOne = true;
          // }
          this.taskBodyList = this.fullattachmentsList.filter(
            (item) =>
              item.AttachmentType === 1 && item.AttachmentUrlSequence === 0
          );

          this.commonhelperobj
            .GetStringBytesFromSignedURL(
              this.taskBodyList[0].AttachmentURL,
              "text/html"
            )
            .subscribe((successdata2) => {
              this.MainBodyAttachmentsList = "<div>" + successdata2 + "</div>";
              this.MainBodyImagesAttachmentsList = [];
              this.imagesattachmentslist = [];
              this.AttachmentsList = [];
              const GetMainBodyAttachmentsList = $(
                this.MainBodyAttachmentsList
              );
              const collectionofAttachments =
                GetMainBodyAttachmentsList.find("img");
              //GetMainBodyAttachmentsList.find('img').remove();
              let collectionofimgsLength2: any;
              collectionofimgsLength2 = collectionofAttachments.length;
              const elementfieldmainbody = {
                Imgaetag: null,
                attachmentType: null,
              };
              const mainbodyinfo = GetMainBodyAttachmentsList.html();

              this.TaskAddInfoModel.SelectedTaskBody = this.initialTaskBody =
                mainbodyinfo;
            });
        }
      });
  }

  //   private populate(data: any) {
  //     // subject

  //     this.TaskAddInfoModel.TaskLatestStatusID = data.TaskLatestStatusID;

  //     this.TaskAddInfoModel.ModuleId = this.ModuleListInfo.ModuleId;
  //     this.TaskAddInfoModel.ModuleName = this.ModuleListInfo.Description;

  //     // auto populate severity dropdown
  //     this.TaskAddInfoModel.severity = {
  //       IssueSeverityDesc: data.SeverityDesc,
  //       IssueSeverityID: this.calculateSeverityId(data.SeverityDesc)
  //     };

  //     if(this.hasValue(data.TaskTypeId) && data.TaskTypeId > 0) {

  //   this. TaskAddInfoModel.TaskTypeId = {TaskTypeDescription: data.TaskTypeIddesc, TaskTypeId: data.TaskTypeId};

  //     }

  //    //
  //    this.TaskAddInfoModel.IssueSubject = data.IssueSubject;

  // //taskEditInfoModel.TaskCamefromType
  // if(this.hasValue(data.TaskCamefromType) && data.TaskCamefromType > 0) {

  //   this.TaskAddInfoModel.TaskCamefromType= {selectTaskFromListText:data.TaskCamefromTypeDescription,selectTaskFromListTextValue: data.TaskCamefromType };
  // }

  // //Task_Current_Working_Status: '--Select Current Working Status--',Task_Current_Working_Status_InfoID:null

  //   }

  private AssignResposibleAndAdditionalPersonName() {
    this.SelectedAdditionalResponsiblePersonInfo =
      this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
    this.selectedAdditionalResponsiblePersons =
      this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
    if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
      this.SelectedAdditionalResponsiblePersonName = "";
      this.SelectedAdditionalResponsiblePersonInfo.forEach(
        (selectedRow, index) => {
          this.SelectedAdditionalResponsiblePersonName +=
            selectedRow.EmployeeFullName + ", ";
        }
      );
      this.SelectedAdditionalResponsiblePersonName =
        this.SelectedAdditionalResponsiblePersonName.substring(
          0,
          this.SelectedAdditionalResponsiblePersonName.length - 2
        );
    } else {
      this.SelectedAdditionalResponsiblePersonName = "";
    }
  }

  private AssignObservesInfo() {
    //*****assigning observers data****************
    this.SelectedObserversInfo =
      this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
    this.SelectedObservers =
      this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
    if (this.hasValue(this.SelectedObserversInfo)) {
      this.SelectedObserversPersonNames = "";
      this.SelectedObserversInfo.forEach((selectedRow, index) => {
        this.SelectedObserversPersonNames +=
          selectedRow.EmployeeFullName + ", ";
      });
      this.SelectedObserversPersonNames =
        this.SelectedObserversPersonNames.substring(
          0,
          this.SelectedObserversPersonNames.length - 2
        );
    } else {
      this.SelectedObserversPersonNames = "";
    }
  }

  // private AssignForwardedtoInformation() {
  //   //************assigning assistant persons info*************************
  //   this.SelectedAssistantPersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
  //   this.SelectedAssistantPerson = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
  //   if (this.hasValue(this.SelectedAssistantPersonInfo)) {
  //     this.SelectedAssistantPersonName = "";
  //     this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
  //       this.SelectedAssistantPersonName += selectedRow.EmployeeFullName + ', ';
  //     });
  //     this.SelectedAssistantPersonName = this.SelectedAssistantPersonName.substring(0, this.SelectedAssistantPersonName.length - 2);
  //   }
  //   else {
  //     this.SelectedAssistantPersonName = "";
  //   }
  // }
  /**
   * TODO: Replace it with data from database
   * Return id of severity
   * @param severityDescription from description string return it's respective id
   */
  private calculateSeverityId(severityDescription: "High" | "Normal" | "Low") {
    if (severityDescription === "High") {
      return 1;
    } else if (severityDescription === "Normal") {
      return 2;
    } else if (severityDescription === "Low") {
      return 3;
    }
  }

  private sendInternalTicketNotificationtoEmployees(TaskNumber): void {
    this._createNewTaskService
      .sendInternalTicketNotificationtoEmployees({

        EmployeeIds: [this.TaskAddInfoModel.SelectedResponsiblePerson,
        this.TaskAddInfoModel.SelectedCSharpPerson,
        this.TaskAddInfoModel.SelectedQAPerson,
        this.TaskAddInfoModel.SelectedJSPerson,
        this.TaskAddInfoModel.SelectedDBPerson].filter((value, index, self) => {
          return self.indexOf(value) === index;
        }),
        TaskNumber: TaskNumber,
        IssueSeverityID: this.TaskAddInfoModel.IssueSeverityID,
      })
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
      });
  }
}
