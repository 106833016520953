import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'child-cell',
  templateUrl: './move-icon-cell-renderer.component.html',
  styles: [
  ]
})
export class MoveIconCellRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  public DeleteSelectedPerson() {
    this.params.context.componentParent.MoveSelectedAddlResponsiblePerson(this.params)
  }

  refresh(): boolean {
    return false;
  }
}
