import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[trim-whitespaces]'
})
export class TrimWhitespacesDirective {

  constructor() { }


  /**
   * When the input value contains only white spaces remove them
   * @param event event which triggers when user enters keyboard characters
   */
  @HostListener('keyup', ['$event'])
  onKeyPress(event: Event) {
    const inputElement: HTMLInputElement = event.target as HTMLInputElement;

    if (inputElement.value) {
      inputElement.value = inputElement.value.replace(/^\s+/g, '');
    }

  }

}
