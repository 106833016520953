import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import 'ag-grid-enterprise';
import { AgGridNg2 } from 'ag-grid-angular';
import { Config } from '../config';
import { CellClickedEvent, GridSizeChangedEvent } from 'ag-grid-community';
import { AddEditCallLogDetailsPopupComponent } from '../add-edit-call-log-details-popup/add-edit-call-log-details-popup.component';
import { commonHelperService } from '../common/common.service';
import { commonhelper } from '../common/commonhelper';
import { PraticesListComponent } from '../pratices-list/pratices-list.component';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';
import { CustomerCallLogService } from './customer-call-log.service';
import { formatDate } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ActivatedRoute, Router } from '@angular/router';
import { ScheduledMeetingChangeStatusPopupComponent } from '../scheduled-meeting-change-status-popup/scheduled-meeting-change-status-popup.component';
import { ScheduledMeetingAddCommentsPopupComponent } from '../scheduled-meeting-add-comments-popup/scheduled-meeting-add-comments-popup.component';
import { CustomerCallLogViewCommentsPopupComponent } from '../customer-call-log-view-comments-popup/customer-call-log-view-comments-popup.component';
import { ExcelService } from '../common/excel.service';

@Component({
  selector: 'app-customer-call-log',
  templateUrl: './customer-call-log.component.html',
  styleUrls: ['./customer-call-log.component.css']
})
export class CustomerCallLogComponent extends commonhelper implements OnInit {
  ContextMenuItems = [];
  columnDefs = [
    { headerName: 'ID', headerTooltip: 'ID', field: 'CustomerCallLogsID', lockPosition: true, width: 105, tooltipField: 'CustomerCallLogsID', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Practice Name', headerTooltip: 'Practice Name', lockPosition: true, field: 'PracticeName', width: 290, tooltipField: 'PracticeName', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'From', headerTooltip: 'From', lockPosition: true, field: 'ParticipentUserNames', width: 200, tooltipField: 'ParticipentUserNames', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Answered Date', headerTooltip: 'Answered Date', lockPosition: true, field: 'CustomerCallLogAnsweredDate', width: 150, tooltipField: 'CustomerCallLogAnsweredDate', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Answered By', headerTooltip: 'Answered By', lockPosition: true, field: 'CallAnsweredBy', width: 240, tooltipField: 'CallAnsweredBy', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Transferred To', headerTooltip: 'Transferred To', lockPosition: true, field: 'CallTransferedTo', width: 240, tooltipField: 'CallTransferedTo', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    {
      headerName: 'Status', headerTooltip: 'Status', lockPosition: true,
      cellRenderer: function (params) {
        params.value = params.value ? params.value : "Select Status"
        return '<a>' + params.value + '</a>';
      },
      field: 'CustomerCallLogStatus', width: 150, tooltipField: 'CustomerCallLogStatus', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' }
    },
    { headerName: 'Duration', headerTooltip: 'Duration', lockPosition: true, field: 'Duration', width: 140, tooltipField: 'Duration', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Purpose', headerTooltip: 'Purpose', lockPosition: true, field: 'CustomerCallLogPurpose', width: 300, tooltipField: 'CustomerCallLogPurpose', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Created Date', headerTooltip: 'Created Date', lockPosition: true, field: 'CreatedDate', tooltipField: 'CreatedDate', width: 215, cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Call Type', headerTooltip: 'Call Type', lockPosition: true, field: 'CallType', tooltipField: 'CallType', width: 215, cellClass: 'no-border cell-wrap-text', autoHeight: true, hidden: true },
    // {
    //   headerName: 'View / Add Comments', headerTooltip: 'View / Add Comments', lockPosition: true,
    //   cellRenderer: function (params) {
    //     return '<span><i class="fa fa-eye" title="View / Add Comments" style="font-size:25px;cursor:pointer"></i></span>';
    //   },
    //   field: 'View', width: 105, cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'center' }
    // },

    // { headerName: 'Comments', field: 'CustomerCallLogComments', width: 240, tooltipField: 'CustomerCallLogComments', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },

  ];

  onCustomerCallLogGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  constructor(private http: HttpClient, private _excelService: ExcelService, private dialogService: DialogService, private route: ActivatedRoute, private _router: Router, httpClient: HttpClient, private _customerCallLogService: CustomerCallLogService) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }
  @ViewChild('customerCallLogGrid') customerCallLogGrid: AgGridNg2;
  @ViewChild('CustomerCallLog') CustomerCallLog: ElementRef;
  @ViewChild("CustomerCallLog", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  private commonhelperobj: commonHelperService;
  public customerCallLogRowData = [];
  public practiceInformation: any = {};
  public CallAnsweredByInfo: any = {};
  public customerCallLogFromDate: any;
  public customerCallLogToDate: any;
  public showHideCustomerCalllogAddEditDeleteButtons: boolean;
  public customerCallLogHeading: any;
  public gridApi;
  public gridColumnApi;
  CustomerCallLogTypeId = 0;
  addEditCallLogDetailsPopupStatusList: any = [
    { StatusId: 1, Status: 'Completed' },
    { StatusId: 2, Status: 'Transferred' },
    { StatusId: 3, Status: 'CallBack' },

  ];

  public customerCallLogCallTypeListDefaultItem = { CallTypeDescription: '-- Select --', CallTypeInfoID: null };
  public customerCallLogCallTypeList: any = [
    { CallTypeInfoID: 0, CallTypeDescription: 'All' },
    { CallTypeInfoID: 1, CallTypeDescription: 'Incoming Call' },
    { CallTypeInfoID: 2, CallTypeDescription: 'Outgoing Call' },
  ];
  ngOnInit() {
    this.route.params.subscribe(params => {
      const data = this.getCommonData(params.info);
      if (this.hasValue(data))
        this.initializeFieldswithRespectiveData(data.isFromAllCustomerCallLog);
      this.GetCustomerCallLogsList();
      this.setfocusonUserFieldonInit();
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  initializeFieldswithRespectiveData(isFromAllCustomerCallLog) {
    this.customerCallLogFromDate = new Date(Date.now() - (31 * 24) * 60 * 60 * 1000);
    this.customerCallLogToDate = new Date(Date.now());
    this.showHideCustomerCalllogAddEditDeleteButtons = isFromAllCustomerCallLog
    this.customerCallLogHeading = isFromAllCustomerCallLog ? "All Customers Call Log" : "Customer Call Log"
  }
  GetCustomerCallLogsList() {
    if (this.validateMandatoryFields()) return;
    this._customerCallLogService.GetCustomerCallLogsList(this.buildInputModelTogetCustomerCallLogsList()).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) return;
      this.customerCallLogGrid.columnApi.setColumnsVisible(['CallType'], this.CustomerCallLogTypeId === 0)
      this.customerCallLogGrid.columnApi.setColumnsVisible(['CallTransferedTo'], this.CustomerCallLogTypeId !== 2)
      if (this.hasValue(this.gridApi))
        this.gridApi.sizeColumnsToFit();
      this.customerCallLogRowData = serviceResponse.CustomerCallLogsList;

    });

  }
  AllCallLogsExportToExcelClick() {
    if (this.customerCallLogRowData.length <= 0) return;
    this._excelService.exportAsExcelFile(this._customerCallLogService.ExcelColumnsInfo, this.customerCallLogRowData, "AllCustomerCallLogsReport");
  }
  setfocusonUserFieldonInit() {
    setTimeout(function () {
      document.getElementById("customerCallLogSelectUser").focus();
    }, 20);
  }
  onCellClicked(event: any) {
    if (event.column.colId == "CustomerCallLogStatus") {
      this.CustomerCallLogsUpdateStatusClickEvent();
      return;
    }
    this.onCellClickedGetContextMenus(event);
  }

  getContextMenuItems = () => {
    return [{
      name: 'View / Add Comments',
      action: () => this.CustomerCallLogsViewClickEvent(),
      cssClasses: ['contextMenuStyles'],
    },
    ]

  }

  onColumnResized(params) {
    debugger;
    setTimeout(() => {
      params.api.resetRowHeights();
    }, 300);

  }
  onCellClickedGetContextMenus(params: CellClickedEvent): void {
    const { rowIndex, node, column, event, value } = params;
    const cell = params.api['rowRenderer'].rowCompsByIndex[rowIndex].getRenderedCellForColumn(column);
    cell.beans.contextMenuFactory.showMenu(node, column, value, event);
    this.onColumnResized(params);
  }
  CustomerCallLogsUpdateStatusClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Status',
      // Show component
      content: ScheduledMeetingChangeStatusPopupComponent,
      height: 320,
      width: 500,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.StatusList = this.addEditCallLogDetailsPopupStatusList
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.UpdateCallLogsStatusComments(result, "Status Updated Successfully");
        }
      }
    });
  }
  CustomerCallLogsAddCommentsClick() {
    let selectedCallLogItem = this.getSelectedCallLog("", false);
    if (!selectedCallLogItem) return;
    const dialogRef = this.dialogService.open({
      title: 'Add Comments',
      // Show component
      content: ScheduledMeetingAddCommentsPopupComponent,
      height: 230,
      width: 500,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.UpdateCallLogsStatusComments({ Comments: result }, "Comments Added Successfully");
        }
      }
    });
  }
  validateMandatoryFields() {
    if (!this.hasValue(this.customerCallLogFromDate)) {
      this.focusonDateFields("Please Enter From Date", "txtCustomerCallLogFromDate");
      return true;
    }
    if (!this.hasValue(this.customerCallLogToDate)) {
      this.focusonDateFields("Please Enter To Date", "txtCustomerCallLogToDate");
      return true;
    }
    if (this.customerCallLogFromDateChange() == false) return true;
    if (this.customerCallLogToDateChange() == false) return true;
    return false;
  }
  focusonDateFields(message: string, focusField: string) {
    this.ShowErrorMessage(message)
    const elementFound = this.CustomerCallLog.nativeElement.querySelector('[name="' + focusField + '"]');
    if (this.hasValue(elementFound)) {
      elementFound.children[0].children[0].children[0].children[0].focus();
    }
  }
  focusonTextFields(message: string, focusField: string) {
    this.ShowErrorMessage(message)
    document.getElementById(focusField).focus();
  }
  buildInputModelTogetCustomerCallLogsList() {
    return {
      CustomerCallLogPracticeID: this.practiceInformation.PracticeId,
      CallAnsweredBy: this.CallAnsweredByInfo.EmployeeId,
      FromDate: formatDate(this.customerCallLogFromDate, 'MM/dd/yyy', 'en-US', '+0530'),
      ToDate: formatDate(this.customerCallLogToDate, 'MM/dd/yyy', 'en-US', '+0530'),
      NeedtoShowAllTasks: this.showHideCustomerCalllogAddEditDeleteButtons == true ? 1 : 0,
      CallType: this.CustomerCallLogTypeId
    }
  }
  customerCallLogFromDateChange() {
    if (!(this.customerCallLogsFromDateToDateValidations(this.customerCallLogFromDate, "txtCustomerCallLogFromDate", true))) return false;
  }
  customerCallLogToDateChange() {

    if (!(this.customerCallLogsFromDateToDateValidations(this.customerCallLogToDate, "txtCustomerCallLogToDate", false))) return false;
  }
  customerCallLogsFromDateToDateValidations(modifiedfromandtoDate: Date, name: string, isfrom: boolean) {
    let today = new Date();
    let toDate = new Date(modifiedfromandtoDate);
    let validationMessage;
    const daysDifference = this.DateDiffInDays(this.customerCallLogToDate, this.customerCallLogFromDate);
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      validationMessage = 'Invalid Day, Month or Year Range Detected.';
    }
    if (this.hasValue(daysDifference) && daysDifference < 0) {
      if (isfrom) {
        validationMessage = 'From Date Should be Less Than or Equal to To Date';
      }
    }
    if (this.hasValue(validationMessage)) { this.focusonDateFields(validationMessage, name); return false; }
    return true;
  }
  getSelectedCallLog(message, isfromLoggedUser) {
    const selectedrow = this.customerCallLogGrid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.focusonTextFields("Please Select Call Log.", "myAppointmentsSelectUser");
      return false;
    }
    if (selectedrow[0].CreatedBy != Config.OASOrganizationModel.LoggedUserID && isfromLoggedUser) {
      this.focusonTextFields("You Are Not Allowed to " + message + " Call Log(s) Created by Others", "myAppointmentsSelectUser")
      return false;
    }
    return selectedrow[0];
  }
  customerCallLogSelectUserIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Practice',
      // Show component
      content: PraticesListComponent,
      height: 665,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.practiceInformation = result;
      }
    });
  }
  customerCallLogRemoveSelectedUserIconClickEvent() {
    this.practiceInformation = {};
  }
  customerCallLogSelectEmployeeIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Employee(s)',
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.CallAnsweredByInfo = result;
      }
    });
  }
  public customerCallLogRemoveSelectedEmployeeIconClickEvent() {
    this.CallAnsweredByInfo = {};
  }
  customerCallLogAddClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Add Call Log Details',
      // Show component
      content: AddEditCallLogDetailsPopupComponent,
      height: 690,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.ShowSuccesseMessage("Call Log Saved Successfully");
          this.GetCustomerCallLogsList();
        }
      }
    });
  }
  customerCallLogEditClickEvent() {
    let selectedCallLogItem = this.getSelectedCallLog("Edit", true);
    if (!selectedCallLogItem) return;
    const dialogRef = this.dialogService.open({
      title: 'Edit Call Log Details',
      // Show component
      content: AddEditCallLogDetailsPopupComponent,
      height: 690,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.SelectedCallLogId = selectedCallLogItem.CustomerCallLogsID;
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.ShowSuccesseMessage("Call Log Updated Successfully");
          this.GetCustomerCallLogsList();
        }
      }
    });
  }
  customerCallLogDeleteClickEvent() {
    let selectedCallLogItem = this.getSelectedCallLog("Delete", true);
    if (!selectedCallLogItem) return;
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please Confirm',
      content: 'Are You Sure to Delete Call Log?',
      actions: [
        { text: 'No' },
        { text: 'Yes', primary: true }
      ],
      width: 450,
      height: 250,
      minWidth: 250,
      appendTo: this.ContainerRef
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        //console.log('close');
      } else {
        if (this.hasValue(result)) {
          if (result.text === 'No') return;
          this.DeleteCustomerCallLog(selectedCallLogItem.CustomerCallLogsID)
        }

      }
    });
  }
  DeleteCustomerCallLog(callLogId) {
    this._customerCallLogService.DeleteCustomerCallLog({ CustomerCallLogsID: callLogId }).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) return;
      this.ShowSuccesseMessage("Call Log Deleted Successfully");
      this.GetCustomerCallLogsList();
    });
  }
  CustomerCallLogsViewClickEvent() {
    let selectedCallLogItem = this.getSelectedCallLog("", false);
    if (!selectedCallLogItem) return;
    const dialogRef = this.dialogService.open({
      title: 'View Comments',
      // Show component
      content: CustomerCallLogViewCommentsPopupComponent,
      height: 500,
      width: 560,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.SelectedCustomerCallLogInfo = selectedCallLogItem;
  }
  UpdateCallLogsStatusComments(result, message) {
    const postData = result;
    postData.Customer_CallLogs_ID = this.getSelectedCallLog("", false).CustomerCallLogsID
    this.commonhelperobj.PostData(Config.OASCallLogsInformationAppnURL + 'UpdateCallLogsStatusComments', postData)
      .subscribe(response => {
        if (this.isError(response)) return;
        this.ShowSuccesseMessage(message)
        this.GetCustomerCallLogsList();
      });

  }
}
