import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: 'child-cell',
  templateUrl: './delete-selected-person-renderer.component.html',
  styles: [

  ]
})
export class DeleteSelectedPersonRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  public DeleteSelectedPerson() {
    this.params.context.componentParent.DeleteSelectedAddlResponsiblePerson(this.params)
  }

  refresh(): boolean {
    return false;
  }

}
