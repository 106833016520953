import { Component, OnInit } from "@angular/core";
import { commonHelperService } from "../common/common.service";
import { HttpClient } from "@angular/common/http";
import { DialogService } from "@progress/kendo-angular-dialog";
import { commonhelper } from "../common/commonhelper";
import { Config } from "../config";
@Component({
  selector: "app-support-statistics-view",
  templateUrl: "./support-statistics-view.component.html",
  styleUrls: ["./support-statistics-view.component.css"],
})
export class SupportStatisticsViewComponent
  extends commonhelper
  implements OnInit
{
  datatoPopup: any;
  SupportRelatedCategoryList: any = [];
  private commonhelperobj: commonHelperService;

  constructor(private dialogService: DialogService, httpClient: HttpClient) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }

  ngOnInit() {
    this.getTaskSupportCategorytList(this.datatoPopup);
  }
  getTaskSupportCategorytList(datatoPopup) {
    let data = {
      createdFromDate: datatoPopup.createdFromDate,
      createdToDate: datatoPopup.createdToDate,
      PracticeID: datatoPopup.PracticeID,
      SupportGroupID: datatoPopup.SupportGroupID,
    };

    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL +
          "ConsolidatedSupportRelatedCategoryReport",
        data
      )
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
        this.SupportRelatedCategoryList = response.SupportRelatedCategoryList;
      });
  }
}
