
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { commonHelperService } from '../common/common.service';


@Injectable()
export class AddEditCallLogDetailsPopupService extends commonHelperService {
    constructor(http: HttpClient) {
        super(http);
    }

    SaveorUpdateCustomerCallLogs(postData: any) {
        return this.PostData(Config.OASCallLogsInformationAppnURL + 'SaveorUpdateCustomerCallLogs', postData).pipe(((response) => {
            return response;
        }));
    }

    GetCustomerCallLogs(postData: any) {
        return this.PostData(Config.OASCallLogsInformationAppnURL + 'GetCustomerCallLogs', postData).pipe(((response) => {
            return response;
        }));
    }

    GetCustomerCallLogPracticeUsersList(postData: any) {
        return this.PostData(Config.OASCallLogsInformationAppnURL + 'GetCustomerCallLogPracticeUsersList', postData).pipe(((response) => {
            return response;
        }));
    }
    GetCustomerCallLogParticipents(postData: any) {
        return this.PostData(Config.OASCallLogsInformationAppnURL + 'GetCustomerCallLogParticipents', postData).pipe(((response) => {
            return response;
        }));
    }


}