import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
declare var kendo: any;

@Component({
  selector: 'app-add-task-information',
  templateUrl: './add-task-information.component.html',
  styleUrls: ['./add-task-information.component.css']
})
export class AddTaskInformationComponent implements OnInit {
  @ViewChild('questionEditor') questionEditor: ElementRef;
  constructor() { }


  ngOnInit() {

  }


}
