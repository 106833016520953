import { Injectable } from '@angular/core';
import { Subject } from 'rxjs-compat';

@Injectable()
export class LeftNavService {

    private dashboardSource = new Subject<any>();
    currentSelected = this.dashboardSource.asObservable();


    // private getEditModeData = new Subject<any>();
    // data = this.getEditModeData.asObservable();

    constructor() { }

    OnLeftnavchange(item: any) {
        this.dashboardSource.next(item);
    }

    GetEditModeData(item: any) {
        this.dashboardSource.next(item);
    }

}
