import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { AgGridNg2 } from "ag-grid-angular";
import { commonHelperService } from "../common/common.service";
import { commonhelper } from "../common/commonhelper";
import { PraticesListComponent } from "../pratices-list/pratices-list.component";
import {
  DialogCloseResult,
  DialogService,
} from "@progress/kendo-angular-dialog";
import { IntlService } from "@progress/kendo-angular-intl";
import { HttpClient } from "@angular/common/http";
import { TaskService } from "../services/task.service";
import { GridSizeChangedEvent } from "ag-grid-community";

@Component({
  selector: "app-customer-ticlets-count-by-practice-wise",
  templateUrl: "./customer-ticlets-count-by-practice-wise.component.html",
  styleUrls: ["./customer-ticlets-count-by-practice-wise.component.css"],
})
export class CustomerTicletsCountByPracticeWiseComponent
  extends commonhelper
  implements OnInit
{
  @ViewChild("CustomerTicletsCountByPracticeWiseGrid")
  CustomerTicletsCountByPracticeWiseGrid: AgGridNg2;

  @ViewChild("CustomerTicletsCountByPracticeWise", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;

  @ViewChild("CustomerTicletsCountByPracticeWise")
  CustomerTicletsCountByPracticeWise: ElementRef;

  CreatedFromDate: any = new Date();
  CreatedToDate: any = new Date();
  public practiceInformation: any = {};
  private commonhelperobj: commonHelperService;
  columnDefs = [
    {
      headerName: "Practice ID",
      field: "PracticeID",
      width: 100,
      tooltipField: "Practice ID",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Customer Name",
      field: "PracticeName",
      width: 280,
      tooltipField: "PracticeName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Count",
      field: "Count",
      width: 90,
      tooltipField: "Count",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  CustomerTicletsCountByPracticeWiseRowData = [];
  EHRPracticeID: number;
  constructor(
    private intl: IntlService,
    private taskService: TaskService,
    private dialogService: DialogService,
    httpClient: HttpClient
  ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }

  ngOnInit() {
    this.getCustomerTicketsCountByPracticeWise();
  }

  CustomerTicletsCountByPracticeWiseSelectPracticeIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Pratice",
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.practiceInformation = result;
        // console.log(this.practiceInformation);
        this.EHRPracticeID = this.practiceInformation.PracticeId;
      }
    });
  }
  CustomerTicletsCountByPracticeWiseRemoveSelectedPracticeClickEvent() {
    this.practiceInformation = {};
    this.EHRPracticeID = 0;
  }

  tasksCreatedFromDateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedFromDate,
        "CreatedFromDate",
        true
      )
    )
      return false;
  }

  tasksCreatedTODateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedToDate,
        "CreatedToDate",
        false
      )
    )
      return false;
  }

  createdFromDateToDateValidations(
    modifiedfromandtoDate: Date,
    name: string,
    isfrom: boolean
  ) {
    var today = new Date();
    var toDate = new Date(modifiedfromandtoDate);
    var showfocuselement = false;
    const daysDifference = this.DateDiffInDays(
      isfrom ? this.CreatedToDate : today,
      isfrom ? this.CreatedFromDate : this.CreatedToDate
    );
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage("Invalid Day, Month or Year Range Detected.");
      showfocuselement = true;
    }
    if (this.hasValue(daysDifference) && daysDifference < 0) {
      if (isfrom) {
        this.ShowErrorMessage(
          "Created From Date Should be Less Than or Equal to Created To Date"
        );
        this.CreatedFromDate = "";
      } else {
        this.CreatedToDate = "";
        this.ShowErrorMessage("Future Dates Not allowed");
      }
      showfocuselement = true;
    }
    if (showfocuselement) {
      const elementFound =
        this.CustomerTicletsCountByPracticeWise.nativeElement.querySelector(
          '[name="' + name + '"]'
        );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    return true;
  }

  ClearFromandTODate(number) {
    if (number === 1) {
      this.CreatedFromDate = "";
    } else if (number === 2) {
      this.CreatedToDate = "";
    }
  }

  getCustomerTicketsCountByPracticeWise() {
    const data: any = {};
    if (this.applyclassesforTasksCreatedDatesSelected(data) == false) return;

    (data.PracticeID = this.practiceInformation.PracticeId),
      this.taskService
        .customerTicketCountbyPracticeWise(data)
        .subscribe((response) => {
          if (this.commonhelperobj.isError(response)) {
            return;
          }
          if (this.hasValue(response)&& response.CustomerTicketsCountByPracticeDetailsList.length>0) {
            // console.log(response);
            this.CustomerTicletsCountByPracticeWiseRowData =
              response.CustomerTicketsCountByPracticeDetailsList;
          }else{ this.CustomerTicletsCountByPracticeWiseRowData= []}
        });
  }

  private applyclassesforTasksCreatedDatesSelected(data: any) {
    if (this.hasValue(this.CreatedFromDate)) {
      if (!this.hasValue(this.CreatedToDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created To Date.");
        return false;
      }
    }
    if (this.hasValue(this.CreatedToDate)) {
      if (!this.hasValue(this.CreatedFromDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created From Date.");
        return false;
      }
    }
    data.CreatedFromDate = this.formatDateValue(this.CreatedFromDate);
    data.CreatedToDate=this.formatDateValue(this.CreatedToDate);
    if (this.tasksCreatedFromDateChange() == false) return false;
    if (this.tasksCreatedTODateChange() == false) return false;
  }


  private formatDateValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "MM/dd/yyyy")}` : "";
  }
  onTicketMonitoringReportTicketsGridSizeChanged(params: GridSizeChangedEvent) {
    // params.api.sizeColumnsToFit();
  }
}
