import { Injectable } from '@angular/core';
import { Base64StringSizeCalculator } from '../classes/base64-string-size-calculator.class';
import { Config } from '../config';
import { Attachment } from '../interfaces/attachment';
import { FileTypeEnum } from '../enums/FileTypeEnum.enum';
import * as $ from 'jquery';
import { FileInfo } from '@progress/kendo-angular-upload';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor() { }

  // isAttachmentsSizeValid(attachments): boolean {

  //   const attachmentsSize = calculateTotalSize(attachments);

  //   return attachmentsSize < Config.MAX_SIZE;
  // }

  /**
   * Check if duplicates exists in list of attachments
   * @param attachments list of attachments to check for duplicate base64 string
   * @returns true if duplicates exists
   */
  duplicatesExists(attachments: Array<Attachment>): boolean {

    const duplicatesExists = attachments
      .map(attachment => attachment.AttachmentBase64Format)
      .filter(findFromIndex).length > 0

    if (duplicatesExists && attachments.length > 0) {
      return true;
    }


    return false;
  }


  uploadedAttachmentsCompatable(uploadedAttachments: Array<Attachment>): boolean {

    const lengthOfCompatableAttachments = uploadedAttachments
      .map(attachment => attachment.AttachmentType)
      .filter(attachmentType => this.isCompatableAttachment(attachmentType))
      .length;

    return lengthOfCompatableAttachments === uploadedAttachments.length;
  }


  isCompatableAttachment(attachmentType: number) {

    return [1, 2, 3, 4, 5,6,7,8,9,10].some(supportedType => supportedType === attachmentType);

  }

  areCompatableExtension(extensions: string[]) {
    if (extensions && extensions.length > 0) {
      return extensions.every(extension => this.isCompatableExtension(extension));
    }
  }

  isCompatableExtension(extension: string) {
    const attachmentType = this.calculateAttachmentType(extension);

    if (attachmentType) {
      return this.isCompatableAttachment(attachmentType);
    }
    return false;
  }


  /**
   * Determine the type id of extension
   * @param extension extension to determine id
   */
  calculateAttachmentType(extension: string): FileTypeEnum {

    if (extension.toLocaleLowerCase() === '.png') {
      return FileTypeEnum.png
    }
    if (extension.toLocaleLowerCase() === '.jpeg') {
      return FileTypeEnum.jpeg;
    }
    if (extension.toLocaleLowerCase() === '.jpg') {
      return FileTypeEnum.jpg;
    }
    if (extension.toLocaleLowerCase() === '.pdf') {
      return FileTypeEnum.pdf;
    }
    if (extension.toLocaleLowerCase() === '.xlsx') {
      return FileTypeEnum.XLSX;
    }
    if (extension.toLocaleLowerCase() === '.xls') {
      return FileTypeEnum.XLS;
    }
    if (extension.toLocaleLowerCase() === '.doc') {
      return FileTypeEnum.DOC;
    }
    if (extension.toLocaleLowerCase() === '.docx') {
      return FileTypeEnum.DOCX;
    }
    if (extension.toLocaleLowerCase() === '.mp4') {
      return FileTypeEnum.MP4;
    }
  }

  pdfAttachments(attachments: Array<Attachment>) {

    if (attachments && attachments.length > 0) {
      return attachments.filter(attachment => (attachment.AttachmentType === 5 || attachment.AttachmentType === 6 ||
        attachment.AttachmentType === 7 || attachment.AttachmentType === 8 || attachment.AttachmentType === 9) && attachment.AttachmentUrlSequence === 0);
    }

    return [];
  }
  pdfNewticketAttachments(attachments: Array<Attachment>) {

    if (attachments && attachments.length > 0) {
      return attachments.filter(attachment => attachment.AttachmentType === 5 || attachment.AttachmentType === 6 ||
        attachment.AttachmentType === 7 || attachment.AttachmentType === 8 || attachment.AttachmentType === 9 );
    }

    return [];
  }
  commentPdfAttachments(attachments: Array<Attachment>) {
    if (attachments && attachments.length > 0) {
      return attachments.filter(attachment => (attachment.AttachmentType === 5 || attachment.AttachmentType === 6 ||
        attachment.AttachmentType === 7 || attachment.AttachmentType === 8 || attachment.AttachmentType === 9) && attachment.AttachmentUrlSequence > 0);
    }

    return [];
  }


  downloadPdf(source: string,pdf: any) {//
    var base64str = source;
    // decode base64 string, remove space for IE compatibility
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    var blob = null;



    // create the blob object with content-type "application/pdf"
    if(pdf.AttachmentType === 6){//XLSX
      blob = new Blob([view], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
   }
   else  if(pdf.AttachmentType === 7){//XLS
    blob = new Blob([view], { type: "application/vnd.ms-excel" });
  }
  else  if(pdf.AttachmentType === 8){//DOC
    blob = new Blob([view], { type: "application/msword" });
  }
  else  if(pdf.AttachmentType === 9){//DOCX
    blob = new Blob([view], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
  }
  else  if(pdf.AttachmentType === 10){//MP4
    blob = new Blob([view], { type: "application/mp4" });
  }
 else {
      blob = new Blob([view], { type: "application/pdf" });
    }

    var url = URL.createObjectURL(blob);

    const pdfDownloadAnchor = document.createElement('a') as any;
    pdfDownloadAnchor.href = url;
    pdfDownloadAnchor.download = pdf.AttachmentName;//`oas-${Date.now()}`;
    pdfDownloadAnchor.style = 'visibility:hidden';
    pdfDownloadAnchor.click();

    (pdfDownloadAnchor as HTMLAnchorElement).remove();
  }

  alreadyExist(selectedFiles: FileInfo[], uploadedFiles: FileInfo[]) {
    if (uploadedFiles && uploadedFiles.length > 0) {
      return uploadedFiles.some(uploadedFile => !!selectedFiles.find(selectedFile => selectedFile.name === uploadedFile.name));
    }
    return false;
  }

  imageAttachments(attachments: Array<Attachment>) {
    if (attachments && attachments.length > 0) {
      return attachments.filter(attachment => [2, 3, 4,10].find(attachmentType => attachmentType === attachment.AttachmentType) && attachment.AttachmentUrlSequence === 0);
    }

    return [];
  }

  imageNewTicketAttachments(attachments: Array<Attachment>) {
    if (attachments && attachments.length > 0) {
      return attachments.filter(attachment => [2, 3, 4,10].find(attachmentType => attachmentType === attachment.AttachmentType) );
    }

    return [];
  }



  /**
   * If the url is valid return the response in form of promise
   * @param sourceUrl The google data store url which returns base64 image
   */
  sourceFromUrl(sourceUrl: string): Promise<string> | undefined {
    if (!sourceUrl) {
      return undefined;
    }

    return $.get(sourceUrl).promise();
  }


  convertToAttachmentModel(attachment) {
    return {
      AttachmentBase64Format: btoa(unescape(encodeURIComponent(attachment.Imgaetag.split('base64,')[1]))),
      AttachmentType: attachment.attachmentType
    }
  }

  
  convertCommentToAttachmentModel(attachment) {
    // console.log(attachment,"Service")
    return {
      AttachmentBase64Format: btoa(unescape(encodeURIComponent(attachment.Imgaetag.split('base64,')[1]))),
      AttachmentType: attachment.attachmentType,
      AttchementName:attachment.AttachmentName
    }
  }

  async convertPdfToAttachmentModel(pdfAttachment: Attachment) {
    const AttachmentBase64Format = await this.sourceFromUrl(pdfAttachment.AttachmentURL);

    return {
      AttachmentBase64Format: btoa(unescape(encodeURIComponent(AttachmentBase64Format))),
      AttachmentType: 5,
    }

  }


  /**
   * Convert the uploaded files to required format and return the convert file array
   * @param uploadedFiles The files uploaded by the user by kendo upload
   * @author Sanjay Idpuganti
   */
  processUploadAttachments(uploadedFiles: Array<FileInfo>): Promise<Attachment[]> {

    if (!uploadedFiles || !uploadedFiles.length) {
      return Promise.resolve([]);
    }

    // container array to hold converted files
    const attachmentContainer: Array<Attachment> = [];

    return new Promise((resolve) => {

      // call recursive function which mutates the attachmentContainer array in each recursive step
      // the calling recusive promise resolve attachmentContainer which is mutated in every recursive step
      resolve(
        this._processUploadedAttachments(uploadedFiles, attachmentContainer)
      );

    });
  }

  _processUploadedAttachments(uploadedFiles: Array<FileInfo>, attachmentContainer: Array<Attachment>): Promise<Attachment[]> {
    return new Promise((resolve) => {
      // recursion base case
      // when the uploaded files array is empty that means the attachmentContainer is populated with converted data
      if (uploadedFiles && uploadedFiles.length === 0) {
        // return the converted attachments array
        return resolve(attachmentContainer);
      }

      // take the first file from the array
      const uploadedFile = uploadedFiles[0];

      const reader = new FileReader();

      reader.readAsDataURL(uploadedFile.rawFile);

      /**
       * When reader completes the loading of the file
       * remove the current file and recursively call the array with files except the fist one
       */
      reader.onloadend = (event: ProgressEvent) => {
        // base64 data of the processing file
        const uploadedFileSource = (event.target as any).result;
        // converted to required base 64 format
        const base64 = window.btoa(unescape(encodeURIComponent(uploadedFileSource.split('base64,')[1])));
        // from extension dtermine the fileType
        // here fileType is an number 2 - png, 5 - pdf etc
        let fileType = this.calculateAttachmentType(uploadedFile.extension);
        let filename = uploadedFile.name;

        // from fileType and base64 format make an object
        const attachment = {
          AttachmentBase64Format: base64,
          AttachmentType: fileType,
          AttachmentName: filename,
        };

        // push the converted array to the attachmentContainer array
        attachmentContainer.push(attachment);
        // remove the first / processing  file from the array
        uploadedFiles.splice(0, 1);

        const result = this._processUploadedAttachments(uploadedFiles, attachmentContainer);
        // without this line the files would not be processed and the algorithm would return empty array
        resolve(result);
      }
    })

  }


  processUploadedAttachments(uploadedFiles: Array<FileInfo>): Promise<Array<Attachment>> {
    const attachmentContainer = [];

    const fileReaders = {};

    return new Promise((resolve) => {
      if (uploadedFiles && uploadedFiles.length > 0) {

        for (let uploadedFileIndex = 0; uploadedFileIndex < uploadedFiles.length; uploadedFileIndex++) {

          const uploadedFile = uploadedFiles[uploadedFileIndex];

          if (!uploadedFile.validationErrors) {

            fileReaders[uploadedFileIndex] = new FileReader();

            // asyncronously triggered when file loads
            // tempobj["reader" + uploadedFileIndex].onload = (ev) => {
            //   const UploadedFileSrc = (ev.target as any).result;

            //   const base64 = window.btoa(unescape(encodeURIComponent(UploadedFileSrc.split('base64,')[1])));

            //   let fileType = this.calculateAttachmentType(uploadedFile.extension);

            //   const attachment = {
            //     AttachmentBase64Format: base64,
            //     AttachmentType: fileType,
            //   };

            //   attachmentContainer.push(attachment);

            //   if (uploadedFileIndex === uploadedFiles.length - 1) {
            //     // when all files are processed (i.e) pushed to array stop the promise
            //     resolve(attachmentContainer);
            //   }
            // };

            fileReaders[uploadedFileIndex].readAsDataURL(uploadedFile.rawFile);
          }
        }

        Object.keys(fileReaders).forEach((key, index) => {

          const fileReader = fileReaders[key];
          const UploadedFileSrc = fileReader.result;
          const base64 = window.btoa(unescape(encodeURIComponent(UploadedFileSrc.split('base64,')[1])));

          const extension = uploadedFiles[index].extension;
          const fileType = this.calculateAttachmentType(extension);

          const attachment = {
            AttachmentBase64Format: base64,
            AttachmentType: fileType,
          };

          attachmentContainer.push(attachment);
        });


        resolve(attachmentContainer);

      } else {
        resolve([]);
      }
    });
  }
}



const calculateTotalSize = function (attachmentList: Array<any>) {
  return attachmentList
    .map(attachment => attachment.AttachmentBase64Format)
    .map(base64String => Base64StringSizeCalculator.calculate(base64String))
    .reduce((x, y) => x + y, 0);
};


const findFromIndex = function <T>(key: T, index: number, list: Array<T>): boolean {

  if (index + 1 >= list.length) {
    return false;
  }

  return list.slice(index + 1).includes(key);
}
