import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-selection-login-page',
  templateUrl: './user-selection-login-page.component.html',
  styleUrls: ['./user-selection-login-page.component.css']
})
export class UserSelectionLoginPageComponent implements OnInit {

  constructor(private _router: Router) { }

  ngOnInit() {
  }
  // userSelectionLoginPageEHRUserLoginClickEvent() {
  //   this._router.navigateByUrl('/Login');
  // }
  userSelectionLoginPageSupportEmployeeLoginClickEvent() {
    this._router.navigateByUrl('/Login');
  }
}
