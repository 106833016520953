import { Component, OnInit } from '@angular/core';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';
import { TaskService } from '../services/task.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';


@Component({
  selector: 'app-save-template-name-popup',
  templateUrl: './save-template-name-popup.component.html',
  styleUrls: ['./save-template-name-popup.component.css']
})
  export class SaveTemplateNamePopupComponent extends commonhelper implements OnInit {
    private commonhelperobj: commonHelperService;
    saveTemplateNamePopupTemplateNameModel="";
    public SaveTemplateInformation: any;
  constructor(private taskService: TaskService, private dialogService: DialogService, private dialog: DialogRef,httpClient: HttpClient,private _Router: Router,) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }

  ngOnInit() {
    setTimeout(function () {
      document.getElementById("saveTemplateNamePopupTemplateName").focus();
    }, 20);
    
  }
  saveTemplateNamePopupCancelClickEvent() {
    this.dialog.close();
  }
   saveTemplateNamePopupSaveClickEvent(){
    if (!this.hasValue(this.saveTemplateNamePopupTemplateNameModel)) {
      this.ShowErrorMessage('Please Enter Template Name');
      document.getElementById("saveTemplateNamePopupTemplateName").focus();
      return false;
    }
     else{
    //    var PostData={
    //      TemplateName:this.saveTemplateNamePopupTemplateNameModel
    //    }
       this.SaveTemplateInformation.TemplateName=this.saveTemplateNamePopupTemplateNameModel
      this.commonhelperobj.PostData(Config.OASAppnURL + 'SaveCreateNewTemplateofOASIssueDetails', this.SaveTemplateInformation).subscribe(serviceResponse => {
      
        if (this.isError(serviceResponse)) {
          document.getElementById("saveTemplateNamePopupTemplateName").focus();
          return;
        }
        else{
          // setTimeout(() => {
            this.dialog.close(this.saveTemplateNamePopupTemplateNameModel);
          // },10);
          
      
            }
            
          });
      
    }
   
  }
  
  
}
