import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { commonhelper } from "./common/commonhelper";
import { HttpClient } from "@angular/common/http";
import {
  Employee,
  PracticeEmployee,
} from "./edit-task-customization-based-on-users/edit-task-customization-based-on-users.component";

@Injectable({
  providedIn: "root",
})
export class EmployeeService {
  constructor() {}

  /**
   *
   * @param commaSeparatedEmployees comma separated employees
   * @author Sanjay Idpuganti
   * @returns object containing employees grouped by role
   */
  parseEmployeeRoles(commaSeparatedEmployees: string) {
    const ROLE = 3;
    // declaration
    let responsiblePerson: Employee;

    // let taskAssistant: Employee;
    const [taskObservers, taskParticipants, taskAssistant, taskQAPerson,taskCSharpPerson,taskDbPerson,taskJsPerson,CreatedBy]: [
      Employee[],
      Employee[],
      Employee[],
      
      Employee[],
      Employee[],
      Employee[],
      Employee[],
      
      
      PracticeEmployee[]
    ] = [[], [], [], [],[],[],[],[]];

    // get all employee details as list
    const allEmployees = commaSeparatedEmployees.split("],");

    // group employees by roles (i.e Observer, Responsible, Participant)
    for (const relatedEmployee of allEmployees) {
      const employeeDetails = relatedEmployee.split("|");
      if (employeeDetails[ROLE] === "Responsible Person") {
        responsiblePerson = this.parseEmployee(employeeDetails);
      } else if (employeeDetails[ROLE] === "Observer") {
        taskObservers.push(this.parseEmployee(employeeDetails));
      } else if (employeeDetails[ROLE] === "Assistant") {
        taskAssistant.push(this.parseEmployee(employeeDetails));
      } else if (employeeDetails[ROLE] === "Participant") {
        taskParticipants.push(this.parseEmployee(employeeDetails));
      } else if (employeeDetails[ROLE] === "QAPerson") {
        taskQAPerson.push(this.parseEmployee(employeeDetails));
      } else if (employeeDetails[ROLE] === "CSharpPerson") {
        taskCSharpPerson.push(this.parseEmployee(employeeDetails));
      } else if (employeeDetails[ROLE] === "DBPerson") {
        taskDbPerson.push(this.parseEmployee(employeeDetails));
      } else if (employeeDetails[ROLE] === "JSPerson") {
        taskJsPerson.push(this.parseEmployee(employeeDetails));
      }
      else if (employeeDetails[ROLE] === "Created By") {
        CreatedBy.push(this.parsePrivateEmployee(employeeDetails));
      }
      
    }

    return {
      responsiblePerson,
      taskObservers,
      taskParticipants,
      taskAssistant,
      CreatedBy,
      taskQAPerson,taskCSharpPerson,taskDbPerson,taskJsPerson
    };
  }

  parsePracticeEmployeeRoles(commaSeparatedEmployees: string) {
    const ROLE = 3;
    // let taskAssistant: Employee;
    const [Observers, CreatedBy]: [PracticeEmployee[], PracticeEmployee[]] = [
      [],
      [],
    ];

    // get all employee details as list
    const allEmployees = commaSeparatedEmployees.split("],");

    // group employees by roles (i.e Observer, Responsible, Participant)
    for (const relatedEmployee of allEmployees) {
      const employeeDetails = relatedEmployee.split("|");
      if (employeeDetails[ROLE]) {
        Observers.push(this.parsePrivateEmployee(employeeDetails));
      }
      // else if (employeeDetails[ROLE] === "Created By") {
      //   CreatedBy.push(this.parsePrivateEmployee(employeeDetails));
      // }
    }

    return {
      Observers,
      CreatedBy,
    };
  }

  private parseEmployee(employeeDetails: string[]): Employee {
    const EMPLOYEE_ID = 0;
    const EMPLOYEE_NAME = 1;

    return {
      EmployeeId: employeeDetails[EMPLOYEE_ID].replace("[", ""),
      EmployeeFullName: employeeDetails[EMPLOYEE_NAME],
    };
  }
  private parsePrivateEmployee(employeeDetails: string[]): PracticeEmployee {
    const EMPLOYEE_ID = 0;
    const EMPLOYEE_NAME = 1;
    const ROLE_ID = 2;
    return {
      UserId: employeeDetails[EMPLOYEE_ID].replace("[", ""),
      UserFullName: employeeDetails[EMPLOYEE_NAME],
      RoleID: employeeDetails[ROLE_ID],
    };
  }
  /**
   * Filter out any duplicate employees selected
   * @param employees Array of employees to filter
   * The filtering is done on employee id
   * @author Sanjay Idpuganti
   */
  public filterDuplicateEmployees(employees: Array<Employee>): Array<Employee> {
    // array which holds dictinct employees
    // i.e this array doesn't contain any employee whose id repeats 2 times
    const distinctEmployeesContainer: Array<Employee> = [];

    for (const employee of employees) {
      if (
        !distinctEmployeesContainer.find(
          (distinctEmployee) =>
            +distinctEmployee.EmployeeId === +employee.EmployeeId
        )
      ) {
        distinctEmployeesContainer.push(employee);
      }
    }

    return distinctEmployeesContainer;
  }
}
