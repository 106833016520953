import { Component, OnInit } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { Config } from '../config';
import { commonHelperService } from '../common/common.service';
import { HttpClient } from '@angular/common/http';
import { GridOptions } from 'ag-grid-community';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-responsible-history-details',
  templateUrl: './responsible-history-details.component.html',
  styleUrls: ['./responsible-history-details.component.css']
})
export class ResponsibleHistoryDetailsComponent extends commonhelper
implements OnInit {
  columnDefs: any = [];
  rowData:any=[];
  private commonhelperobj: commonHelperService;
  
  public gridOptions: GridOptions;
  datatoPopup: any;
  TaskInfoID:number;
  constructor( private dialog: DialogRef,
    private http: HttpClient,) {
   
    super();
    this.commonhelperobj = new commonHelperService(http);
    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this,
      },
      defaultColDef: {
        resizable: true,
      },
    };
  }

  ngOnInit() {
    if(this.datatoPopup!==undefined){
      console.log(this.datatoPopup,this.datatoPopup)
      this.TaskInfoID= this.datatoPopup.TaskInfoID;
      this.populateHistoryTable()
      }
  }

  populateHistoryTable() {
    this.columnDefs = [
      {
        headerName: "Date",
        field: "CreatedOn",
        tooltipField: "Modified On",
        minWidth: 80,
        maxWidth: 80,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
        cellRenderer: this.dateTimeFormatCellRenderer,
      },
      {
        headerName: "Modified By",
        field: "CreatedBy",
        tooltipField: "Modified By",
        minWidth: 140,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
      },
      {
        headerName: "Update Disposition",
        field: "EventName",
        tooltipField: "EventName",
        minWidth: 160,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
      },
      {
        headerName: "Update",
        field: "Description",
        tooltipField: "Description",
        width: 300,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
      },
    ];

    this.commonhelperobj
      .PostData(`${Config.OASAppnURL}TaskResponsibleHistoryList`, {
        TaskInfoID: this.TaskInfoID,
      })
      .subscribe((response) => {
        const history = response.TaskHistoryList as Array<any>;

        history.forEach((event) => {
          event.Description = this.parseEventDescription(event.Description);
        });

        this.rowData = history;
        this.gridOptions.onGridReady = () => {
        
        };        
       
      });
  }

  public onSelectionChanged(event) {
    // console.log(event);
  }

  
  private parseEventDescription(description: string): string {
    if (!description.includes(description)) {
      return description;
    }

    if (!description.includes("-->")) {
      return description;
    }

    const [previous, changed] = description
      .split("-->")
      .map((value) => value.trim());

    const previousContents: Array<string> = [];
    const changedContents: Array<string> = [];

    if (previous) {
      // contains only one value
      if (previous.includes(",")) {
        previous
          .split(",")
          .map((content) => previousContents.push(content.split("|")[1]));
      } else {
        previousContents.push(previous.split("|")[1]);
      }
    }

    if (changed) {
      // contains only one value
      if (changed.includes(",")) {
        changed
          .split(",")
          .map((content) => changedContents.push(content.split("|")[1]));
      } else {
        changedContents.push(changed.split("|")[1]);
      }
    }

    let previousDescription = "";

    if (previousContents.length === 1) {
      previousDescription = previousContents[0];
    } else if (previousContents.length > 1) {
      previousDescription = previousContents.join(",");
    }

    let changedDescription = "";

    if (changedContents.length === 1) {
      changedDescription = changedContents[0];
    } else if (changedContents.length > 1) {
      changedDescription = changedContents.join(",");
    }

    if (!changedDescription && previousDescription) {
      return `Removed ${previousDescription}`;
    }

    if (changedDescription && !previousDescription) {
      return `Added ${changedDescription}`;
    }

    return previousDescription + " --> " + changedDescription;
  }
}
