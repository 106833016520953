import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-support-employee-login-page',
  templateUrl: './support-employee-login-page.component.html',
  styleUrls: ['./support-employee-login-page.component.css']
})
export class SupportEmployeeLoginPageComponent implements OnInit {

  constructor(private _router: Router) { }

  ngOnInit() {
  }
  SupportEmployeeLoginPageSignupClickEvent() {
    this._router.navigateByUrl('/Signup');
  }
}
