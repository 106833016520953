import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { GridOptions } from 'ag-grid-community';
import { AgGridNg2 } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { commonHelperService } from '../common/common.service';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee } from '../edit-task-customization-based-on-users/edit-task-customization-based-on-users.component';
import { TaskService } from '../services/task.service';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { AgGridModule } from 'ag-grid-angular';
import { ViewAccessInformationPopupComponent } from '../view-access-information-popup/view-access-information-popup.component';
import { IntlService } from '@progress/kendo-angular-intl';



@Component({
  selector: 'app-access-information',
  templateUrl: './access-information.component.html',
  styleUrls: ['./access-information.component.css']
})
export class AccessInformationComponent extends commonhelper implements OnInit {
  @ViewChild('AccessInformation') AccessInformation: AgGridNg2;

  @ViewChild('AccessInformationDayWiseGrid') AccessInformationDayWiseGrid: AgGridNg2;
//Declaring all the variables with respective Data types 
  public gridOptions: GridOptions;
  public AccessInformationGridOptions: GridOptions;
  public AccessInformationDayWiseGridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public selectedRowsString = undefined;
  public overlayNoRowsTemplate;
  private commonhelperobj: commonHelperService;
  public daywisedetailsinfo: any;
  public AccessInformationrowData = [];
  public AccessInformationDayWiserowData = [];
  public Date: any;
//public EmployeeInfoID:any;
  public DATE = new Date();
  FirstTimeLoad: boolean;
  public MonthFromDate = this.DATE.getMonth()
  // public DATE = new Date();
 public MONTH = this.DATE.getMonth()+1;
 public SelectedEmployeeAccessInformation:any;
 public months=["JANUARY","FEBRUARY","MARCH","APRIL","MAY","JUNE","JULY","AUGUST","SEPTEMBER","OCTOBER","NOVEMBER","DECEMBER"]
 //public CurrentMonth =this.months[this.MonthFromDate];
 //public CurrentMonth=this.months[this.AccessInformationMonthsList]
 public CurrentMonth :any;
  public CurrentYear = this.DATE.getFullYear();
  public Currentyear:any;
  private EmployeeInfoId: any;
  public EmployeeInfoID:any;
  public firstname:any;
  public lastname:any;
  public FullName:any;
  accessInformationSelectDate: Date;
  public Day=this.DATE.getDate();
  dataAccessInformationMonthList: any;
  public dataAccessInformationYearsList:any;
  public AccessInformationYearsList:any;
  public AccessInformationMonthsList:any;

  //public fullname= Config.OASOrganizationModel.FullName
  // public AccessInformationDayDetailsGet;
  // public AccessInformationDayDetailsForToday;

  // public defaultAllTasksSummaryByForwardedToMeBucketTaskType = { TaskTypeName: '-- Task Type --', TaskTypeId: null }

  /** Parameters are binding to the field of rowdata( AccessInformationColumnDefs) to show the list on the front-end side*/

  AccessInformationColumnDefs = [
    { headerName: 'S.No', field: 'MonthSummaryID', width: 84, tooltipField: 'MonthSummaryID', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    // { headerName: 'Office Working Days with Holidays', field: 'TotalWorkingDays', width: 250, tooltipField: 'OfficeWorkingDaysWithHolidays', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Office Working Days', field: 'OfficeOfWorkingday', width: 216, tooltipField: 'OfficeOfWorkingday', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'EWD', field: 'EmployeeWorkingDays', width: 135, tooltipField: 'EmployeeWorkingDays', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Full Days', field: 'FULLDays', width: 149, tooltipField: 'FULLDays', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Full Days Lvs', field: 'FullDayLeaves', width: 152, tooltipField: 'FullDayLeaves', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Half Days Lvs', field: 'HalfDayLeaves', width: 158, tooltipField: 'HalfDayLeaves', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Total Lvs', field: 'TotalLeaves', width: 144, tooltipField: 'TotalLeaves', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Perm.', field: 'PermissionDays', width: 130, tooltipField: 'PermissionDays', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Late Checkin', field: 'LateCheckIns', width: 146, tooltipField: 'LateCheckIns', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    // { headerName: '', field: '', width: 450, cellClass: 'no-border cell-wrap-text', autoHeight: true },
  { headerName: 'CL ', field: 'CasualLeaves_OpeningBalance', width: 144, tooltipField: 'CasualLeaves_OpeningBalance', cellClass: 'no-border cell-wrap-text', autoHeight: true, },
   // { headerName: 'Leaves Applied', field: 'LeavesApplied', width: 183, tooltipField: 'LeavesApplied', cellClass: 'no-border cell-wrap-text', autoHeight: true },
   // { headerName: 'CL Avail.', field: 'CLAvail', width: 120, tooltipField: 'CLAvail', cellClass: 'no-border cell-wrap-text', autoHeight: true, },
  { headerName: 'Comp Off', field: 'CompoffLeaves', width: 155, tooltipField: 'CompoffLeaves', cellClass: 'no-border cell-wrap-text', autoHeight: true, },

  ];


  // AccessInformationrowData = [
  //   {
  //     SNO: '1', OfficeWorkingDaysWithHolidays: '17', OfficeWorkingDays: '14', EWD: '7', FullDays: '8',
  //     HalfDays: '0', Lvs: '7	', Perm: '3', LateCheckin: '5', CLOpeningBal: '0.00', LeavesApplied: 'LP: 2.50 , CO: 1.00 , LOP: 6.00', CLAvail: '0.00'
  //   },
  // ];

 /** Parameters are binding to the field of rowdata( AccessInformationDayWiseColumnDefs) to show the list on the front-end side*/
  AccessInformationDayWiseColumnDefs = [
    { headerName: 'Date', field: 'DateToConsider', width: 125, tooltipField: 'DateToConsider', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Working Hours', field: 'WorkingHours_String', width: 170, tooltipField: 'WorkingHours_String', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Cafeteria Hours', field: 'CafetariaHours_String', width: 169, tooltipField: 'CafetariaHours_String', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    //{ headerName: 'Out Side Hours', field: 'OutSideHours', width: 150, tooltipField: 'OutSideHours', cellClass: 'no-border cell-wrap-text', autoHeight: true },
  { headerName: 'Status', field: 'DayAttendanceStatus', width: 183, tooltipField: 'DayAttendanceStatus', cellClass: 'no-border cell-wrap-text', autoHeight: true },
   // { headerName: 'Checkin-Time', field: 'IsEmployeeLate', width: 150, tooltipField: 'IsEmployeeLate', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    // { headerName: 'sick days', field: 'sickdays', width: 120, tooltipField: 'sickdays', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    // { headerName: '', field: '', width: 1024, cellClass: 'no-border cell-wrap-text', autoHeight: true },

  ];

/** rowClassRules are used to assign required colors to the certain rows   */
  rowClassRules = {
    // "sick-days-warning": function (params) {
    //   var numSickDays = params.data.sickDays;
    //   return numSickDays > 5 && numSickDays <= 7;
    // },
    // "sick-days-breach": "data.sickDays > 8"
    'sick-days-overdue': function (params) {
      // tslint:disable-next-line:prefer-const
      const STATUS = params.data.DayAttendanceStatus;
      return STATUS === 'Leave';
    },
    'yellowcolor': function (params) {
      // tslint:disable-next-line:prefer-const
      const STATUS = params.data.DayAttendanceStatus;
      return STATUS === 'Permission';
    },
    'sick-days-completed': function (params) {
      // tslint:disable-next-line:prefer-const
      const STATUS = params.data.DayAttendanceStatus;
      if(STATUS === 'Week_Off' || STATUS === 'Holiday')
     // const STATUS = params.data.DayAttendanceStatus;
      return STATUS
    },
    // 'color': function (params) {
    //   // tslint:disable-next-line:prefer-const
    //   const checkintime = params.data.IsEmployeeLate;
    //   if (checkintime == 1) {
    //     return checkintime

    //   }
    // },

  };
  /**By default assigning null value to the dropdown of defaultAccessInformationMonthsList */
  public defaultAccessInformationMonthsList = { MonthDesc:'-- Select Month --', MonthID: null }
  /**By default assigning null value to the dropdown of defaultAccessInformationYearsList */
  public defaultAccessInformationYearsList={Year:'-- Select Year --', YearID:null}
  // this array is used by the grid to render data in html
  public rowData = [];
/**Declaring all the services on the constructor that have been used in this component */
  constructor(private route: ActivatedRoute,httpClient: HttpClient,
    private _Router: Router,private _router: Router,
    private taskService: TaskService,
    private dialogService: DialogService, private intl: IntlService,
  ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);


  }
  
  ngOnInit() {

  /**Calling all the methods when the page loads  */
    this.route.params.subscribe(routeParams => {
      // get data passed from calling route
      const  data = this.getCommonData(routeParams.info)['data'];
      if(this.hasValue(data)){
      this.FirstTimeLoad = true;
      this.EmployeeInfoId=data.EmployeeInfoID;
      // this.firstname=data.FirstName;
      // this.lastname = data.Lastname;
     this.FullName= `${data.FirstName} ${data.LastName}`
      }
    // this.populate(data);
     // this.GetCurrentStatusList(data);
     // this.previousResponsibleperson = this.initialResponsiblePerson;
     
    });
    if(this.FirstTimeLoad==true){
      this.EmployeeInfoID= this.EmployeeInfoId
    }
    else{
      this.EmployeeInfoID= Config.OASOrganizationModel.LoggedUserID;
    }
    if(this.Day>=25 && this.MONTH==12){
      this.AccessInformationYearsList=this.CurrentYear+1;
    this.AccessInformationMonthsList=1;
    }
   else if(this.Day>=25){
    this.AccessInformationYearsList=this.CurrentYear;
    this.AccessInformationMonthsList=this.MONTH+1;
    }
    else{
      this.AccessInformationYearsList=this.CurrentYear;
    this.AccessInformationMonthsList=this.MONTH;
    }
    
    this.AccessInformationMonthDetails();
    this.AccessInformationDayDetails();

    this.GetAccessInformationMonthsList();
    this.GetAccessInformationYearsList();
   
    //this.accessInformationSelectDate = new Date();
   
    //this.accessInformationSelectDate = new Date();
   
   
    //  this.accessInformationSelectDate = new Date(this.accessInformationSelectDate);
    // this.accessInformationSelectDate.setMonth(this.accessInformationSelectDate.getMonth() + 1);
   
   // this.allReportByResponsibleEmployeeFromDate = new Date(this.allReportByResponsibleEmployeeToDate);

  }
//  public accessInformationGoButtonClick(){

//  }
/**Dropdown filter on years */
// selectionChange(value){
//   this.AccessInformationYearsList=value.YearID;
//   //this.AccessInformationMonthsList=value.MonthID;
//   this.CurrentMonth=this.months[this.AccessInformationMonthsList-1]
//   this.Currentyear=this.AccessInformationYearsList
//   this.AccessInformationMonthDetails();
//   this.AccessInformationDayDetails();
// }
/** The method is used to get the list of years */
  public GetAccessInformationYearsList(){
    

      var PostDataToService = {
       
      };
      this.commonhelperobj.PostData(Config.OASAccessInformationAppnURL + 'GetAccessInformationYearsModelList', PostDataToService).subscribe(serviceResponse => {
        if (this.isError(serviceResponse)) { return; }
        this.dataAccessInformationYearsList = serviceResponse;
      });
  
  }
/** The method is used to get the list of months */
  public GetAccessInformationMonthsList(){
    
    
    var PostDataToService = {
     
    };
    this.commonhelperobj.PostData(Config.OASAccessInformationAppnURL + 'GetAccessInformationMonthDetailsModel', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.dataAccessInformationMonthList = serviceResponse;
    });

 
}

  AccessInformationDayWiseDetails() {
    const selectedRows = this.AccessInformationDayWiseGrid.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;

    });
    this.daywisedetailsinfo = this.selectedRowsString;

  }
  /**The method is used to get the month wise details for selected date in a grid */
  public AccessInformationMonthDetails() {
    //NgModel Values are assigning to the variables to the backend for filtering purpose and the month is decremented by one to the NgModel value to get the selected current month
    this.CurrentMonth=this.months[this.AccessInformationMonthsList-1]
    this.Currentyear=this.AccessInformationYearsList
    this.AccessInformationDayDetails()
    /*** The Variables are used for filtering the lists  based on data entered on the respective fields **/
    // alert(this.allReportByResponsibleEmployeeFromDate);
    const PostDataToService = {
      Year : this.AccessInformationYearsList,
     Month : this.AccessInformationMonthsList,
    //  Month : 4,
    EmployeeInfoID:  this.EmployeeInfoID 

    };
    return this.commonhelperobj.PostData(Config.OASAccessInformationAppnURL + 'EmployeeAttendanceMonthDetails', PostDataToService)
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.AccessInformationrowData = response.EmployeeAttendanceMonthDetailsModelList;
        //this.EmployeeInfoId="";
        
      });
  }
    /**The method is used to get the day wise details for selected date in a grid */
  public AccessInformationDayDetails() {
    const PostDataToService = {
      /*** The Variables are used for filtering the lists  based on data entered on the respective fields **/
     // Year : this.CurrentYear,
     Year : this.AccessInformationYearsList,
     Month : this.AccessInformationMonthsList,
      //Month : this.MONTH,
    //  Month : 4,
    
    EmployeeInfoID:  this.EmployeeInfoID 

    };
    return this.commonhelperobj.PostData(Config.OASAccessInformationAppnURL + 'EmployeeAttendanceDayDetails', PostDataToService)
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.AccessInformationDayWiserowData = response.EmployeeAttendanceDayDetailsModellist;
        //this.EmployeeInfoId="";
      });
  }

  public todate = this.formatValue(this.DATE)
  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, 'd')}` : '';
  }
  
 
  refreshClickEvent(){
     this.AccessInformationDayDetails();
     this.selectedRowsString="";
   //this.AccessInformationDayWiserowData=[];
  }

  accessInformationViewClick() {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage('Please Select day');
      return false;
    }

    const dialogRef = this.dialogService.open({
      title: 'View Access Details',
      // Show component
      content: ViewAccessInformationPopupComponent,
      height: 698,
      width: 620,
      //width:770
    });
    dialogRef.content.instance.SelectedViewAccesInformation=this.EmployeeInfoID;
   dialogRef.content.instance.SelectedViewAccesInformationHide=this.FirstTimeLoad 
    dialogRef.content.instance.SelectedAccesInformation = this.selectedRowsString;
    //dialogRef.content.instance.SelectedAccesInformationforAdd = this.selectedRowsString;

    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        return;
      }

    });
  }

}
