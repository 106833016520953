import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { commonHelperService } from "../common/common.service";
import { Config } from "../config";

@Injectable({
  providedIn: "root",
})
export class TaskService extends commonHelperService {
  constructor(http: HttpClient) {
    super(http);
  }

  GetPracticeUsersEmailsData(postData = {}) {
    return this.PostData(
      Config.OASAppnURL + "OASGetPracticeUsersEmailsData",
      postData
    );
  }
  GetPracticeUsersData(postData = {}) {
    return this.PostData(
      Config.OASAppnURL + "OASGetPracticeUserNamesData",
      postData
    );
  }
  taskList(postData = {}) {
    return this.PostData(
      Config.OASOrganizationAppnURL + "EmployeeTaskListNew",
      postData
    );
  }
  //added by suman
  statisticsReportList(postData = {}) {
    return this.PostData(
      Config.OASOrganizationAppnURL + "StatisticsReportList",
      postData
    );
  }

  draftCommentsList(postData = {}) {
    return this.PostData(
      Config.OASOrganizationAppnURL + "GetTaskCommentsDraftList",
      postData
    );
  }

  draftCommentAttachment(postData = {}) {
    return this.PostData(
      Config.OASOrganizationAppnURL + "GetDraftedCommentDetails",
      postData
    );
  }

  //added by suman
  notRespondedReportList(postData = {}) {
    return this.PostData(
      Config.OASOrganizationAppnURL + "NotRespondedReportList",
      postData
    );
  }

  //added by suman
  ConsolidateReportList(postData = {}) {
    return this.PostData(
      Config.OASOrganizationAppnURL + "ConsilidatedReportFromAllBuckets",
      postData
    );
  }

  //added by suman
  gettaskList(postData = {}) {
    return this.PostData(
      Config.OASOrganizationAppnURL + "EHRtoCustomersTaskListNew",
      postData
    );
  }

  
  //added by suman
  getRepliedtaskList(postData = {}) {
    return this.PostData(
      Config.OASOrganizationAppnURL + "EmployeeRepliedTaskListNew",
      postData
    );
  }
  //added by suman
  getCustomerRepliedtaskList(postData = {}) {
    return this.PostData(
      Config.OASOrganizationAppnURL + "CustomerRepliedTaskListNew",
      postData
    );
  }
  
  //added by suman
  customerTicketCountbyPracticeWise(postData = {}) {
    return this.PostData(
      Config.OASReportsofTasksAppnURL + "CustomerTicketsCountByPracticeWise",
      postData
    );
  }

    //added by suman
    ticketsMonitoringReports(postData = {}) {
      return this.PostData(
        Config.OASReportsofTasksAppnURL + "TicketsMonitoringReports",
        postData
      );
    }
  TicketsMonitoringReports
  taskCount(postData = {}) {
    return this.PostData(
      Config.OASOrganizationAppnURL + "EmployeeTaskCount",
      postData
    );
  }

  teamTaskCount(postData = {}) {
    return this.PostData(
      Config.OASOrganizationAppnURL + "EmployeeTeamTasksCount",
      postData
    );
  }

  PracticesList(postData = {}) {
    return this.PostData(Config.OASAppnURL + "OASPracticesList", postData);
  }
  PracticeUsersList(postData = {}) {
    return this.PostData(
      Config.OASAppnURL + "OASPracticeUsersListForITS",
      postData
    );
  }

  taskStatusCount(postData) {
    const DataToSend: any = {};
    if (postData === "All") {
      DataToSend.TaskLatestStatusID = null;
      DataToSend.AssistOrSetbyMe = 0;
    } else if (postData === "Yet to Start") {
      DataToSend.TaskLatestStatusID = 1;
      DataToSend.AssistOrSetbyMe = 0;
    } else if (postData === "On Going") {
      DataToSend.TaskLatestStatusID = 2;
      DataToSend.AssistOrSetbyMe = 0;
    } else if (postData === "Completed") {
      DataToSend.TaskLatestStatusID = 3;
      DataToSend.AssistOrSetbyMe = 0;
    } else if (postData === "Review Pending") {
      DataToSend.TaskLatestStatusID = 5;
      DataToSend.AssistOrSetbyMe = 0;
    } else if (postData === "Assisting") {
      DataToSend.TaskLatestStatusID = null;
      DataToSend.AssistOrSetbyMe = 1;
    } else if (postData === "Set by me") {
      DataToSend.TaskLatestStatusID = null;
      DataToSend.AssistOrSetbyMe = 2;
    } else if (postData === "Recycle Bin") {
      DataToSend.TaskLatestStatusID = null;
      DataToSend.AssistOrSetbyMe = null;
    }
    return this.PostData(Config.OASAppnURL + "TasksStatusCount", DataToSend);
  }

  taskForwardToMeCount(postData = {}) {
    const DataToSend: any = {};

    return this.PostData(
      Config.OASAppnURL + "TaskForwardToMeCount",
      DataToSend
    );
  }

  RecycleBinTaskCount(postData = {}) {
    return this.PostData(Config.OASAppnURL + "DeletedTasksCount", postData);
  }

  taskTypeList() {
    return this.PostData(Config.OASAppnURL + "TaskTypeList", {});
  }

  supportRelatedList() {
    return this.PostData(Config.OASAppnURL + "OASIssueSupportGroupsListView", {});
  }

  taskReviewTeamLeadList(data: any) {
    return this.PostData(Config.OASAppnURL + "GetTaskReviewLeadDevList", data);
  }

  taskReviewInsertAPIAction(data: any) {
    return this.PostData(Config.OASAppnURL + "TaskReviewedDetailsInsert", data);
  }

  angularTaskReviewInsertAPIAction(data: any) {
    return this.PostData(
      Config.OASAppnURL + "AngularTaskReviewedDetailsInsert",
      data
    );
  }

  UITaskReviewInsertAPIAction(data: any) {
    return this.PostData(
      Config.OASAppnURL + "UITaskReviewedDetailsInsert",
      data
    );
  }

  QATaskReviewInsertAPIAction(data: any) {
    return this.PostData(
      Config.OASAppnURL + "QATaskReviewedDetailsInsert",
      data
    );
  }

  getTaskReviewAPIAction(data: any) {
    return this.PostData(Config.OASAppnURL + "TaskReviewedDetailsGet", data);
  }
}
