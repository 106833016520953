import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef } from '@angular/core';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { AgGridNg2 } from 'ag-grid-angular';
import { commonHelperService } from '../common/common.service';
import { commonhelper } from '../common/commonhelper';
import { Config } from '../config';
import { PraticesListComponent } from '../pratices-list/pratices-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskService } from '../services/task.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { ExcelService } from '../common/excel.service';
import { EasyFormTaskReportService } from './easy-form-task-report.service';

@Component({
  selector: 'app-easy-form-task-report',
  templateUrl: './easy-form-task-report.component.html',
  styleUrls: ['./easy-form-task-report.component.css']
})
export class EasyFormTaskReportComponent extends commonhelper implements OnInit {
  @ViewChild('easyFormTaskReportGrid') easyFormTaskReportGrid: AgGridNg2;
  @ViewChild('EasyFormTaskReport') EasyFormTaskReport: ElementRef;
  @ViewChild("EasyFormTaskReport", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  private commonhelperobj: commonHelperService;
  public easyFormTaskReportCurrentWorkingLocation: any;
  FromRecords: any;
  PrevLabelCount: any;
  NextLabelCount: any;
  RecordsPerPage: any;
  TotalCountValue: boolean = true;
  TotalCountValueIcon: boolean = false;
  easyFormTaskReportToDate: any;
  TotalLabelCount: any;
  TotalLabelCountValue: any;
  fromrecordsfromcount: any;
  prevnextbuttons: boolean = true;
  NextButtonDisabled: boolean = false;
  PreviousButtondisabled: boolean = false;
  public easyFormTaskReportSelectStatusDropDown: any = [];
  public NewCurrentWorkingStatusList: any;
  public easyFormTaskReportRowData = [];
  SearchStatusIDs: any = [];
  easyFormTaskReportFromDate: any;
  public practiceInformation: any = {};
  public responsiblePersonInfo: any = {};
  selectedTask: any;
  columnDefs = [
    { headerName: 'Task #', field: 'TaskNumber', width: 90, tooltipField: 'TaskNumber', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Practice Name', field: 'PracticeName', width: 150, tooltipField: 'PracticeName', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Subject', field: 'IssueSubject', minwidth: 460, maxwidth: 760, tooltipField: 'IssueSubject', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Current Working Status', field: 'TaskCurrentWorkingStatus', width: 210, tooltipField: 'TaskCurrentWorkingStatus', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    // { headerName: 'Additional Responsible', field: 'AdditionalResponsible', width: 200, tooltipField: 'AdditionalResponsible', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Created Date', field: 'IssueCreatedOn', width: 200, tooltipField: 'IssueCreatedOn', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Latest Modified Date', field: 'TasksModifiedDate', width: 200, tooltipField: 'TasksModifiedDate', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },

  ];
  onEasyFormTaskReportGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  public defaultCurrentWorkingStatusItem = { Task_Current_Working_Status: '-- Task Current Working Status --', Task_Current_Working_Status_InfoID: null };
  constructor(private http: HttpClient,private readonly _easyFormTaskReportService:EasyFormTaskReportService, private readonly _excelService: ExcelService, httpClient: HttpClient, private route: ActivatedRoute, private _router: Router, private intl: IntlService, private taskService: TaskService, private dialogService: DialogService) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }

  ngOnInit() {
    this.easyFormTaskReportFromDate = new Date(Date.now() - (31 * 24) * 60 * 60 * 1000);
    this.easyFormTaskReportToDate = new Date(Date.now());
    this.route.params.subscribe(params => {
      const data = this.getCommonData(params.info);
      if (this.hasValue(data) && this.hasValue(data.previouslySelectedSearchFilters)) {
        this.responsiblePersonInfo = data.previouslySelectedSearchFilters.responsiblePersonInfo;
        this.practiceInformation = data.previouslySelectedSearchFilters.practiceInformation;
        this.FromRecords = data.previouslySelectedSearchFilters.StatemaintainFromRecords;
        this.SearchStatusIDs = data.previouslySelectedSearchFilters.SearchStatusIDs;
        this.easyFormTaskReportCurrentWorkingLocation = data.previouslySelectedSearchFilters.easyFormTaskReportCurrentWorkingLocation;
        this.easyFormTaskReportFromDate = data.previouslySelectedSearchFilters.easyFormTaskReportFromDate;
        this.selectedTask = data.previouslySelectedTaskNumber;
      }
    });
    this.getTaskStatusList();
    this.easyFormTaskReportGetListClick(undefined, undefined, false)
    this.GetCurrentStatusList();
    // setTimeout(function () {
    //   document.getElementById("txtCustomerTicketsSearchTask").focus();
    //   },20);
    setTimeout(() => {
      this.statemaintainPreviousSelectedRecord();
    }, 100);
  }
  private getTaskStatusList() {
    this.commonhelperobj.PostData(Config.OASAppnURL + 'TaskStatusList', {}).subscribe(response => {
      if (this.isError(response)) { return; }

      this.easyFormTaskReportSelectStatusDropDown = [];

      response.forEach(element => {
        if (element.TaskStatusId != 4) {
          this.easyFormTaskReportSelectStatusDropDown.push({ TaskDescription: element.TaskDescription, TaskStatusId: element.TaskStatusId });
        }
      });


    });
  }
  private GetCurrentStatusList() {

    var PostDataToService = {};
    this.commonhelperobj.PostData(Config.OASAppnURL + 'NewCurrentWorkingStatusList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.NewCurrentWorkingStatusList = [];
      serviceResponse.newCurrentWorkingStatusListModelList.forEach(element => {
        if (element.Task_Current_Working_Status_InfoID == 18 || element.Task_Current_Working_Status_InfoID == 19 || element.Task_Current_Working_Status_InfoID == 20 || element.Task_Current_Working_Status_InfoID == 23 || element.Task_Current_Working_Status_InfoID == 24) {
          this.NewCurrentWorkingStatusList.push({ Task_Current_Working_Status: element.Task_Current_Working_Status, Task_Current_Working_Status_InfoID: element.Task_Current_Working_Status_InfoID });
        }
      });
      // this.NewCurrentWorkingStatusList = serviceResponse.newCurrentWorkingStatusListModelList;
    });


  }

  easyFormExportToExcelClick(exoportoexcel) {
    this.easyFormTaskReportGetListClick(undefined, undefined, exoportoexcel)
  }
  easyFormPrevNextClick(prevornext) {

    this.easyFormTaskReportGetListClick(undefined, prevornext, false)
  }
  easyFormTaskReportGetListClick(isfromsearch, prevornext, exoportoexcel) {
    if (!this.hasValue(this.easyFormTaskReportFromDate)) {
      this.ShowErrorMessage("Please Enter From Date")
      const elementFound = this.EasyFormTaskReport.nativeElement.querySelector('[name="' + "txtEasyFormTaskReportFromDate" + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    if (!this.hasValue(this.easyFormTaskReportToDate)) {
      this.ShowErrorMessage("Please Enter To Date")
      const elementFound = this.EasyFormTaskReport.nativeElement.querySelector('[name="' + "txtEasyFormTaskReportToDate" + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    if (this.easyFormTaskReportFromDateChange() == false) return false;
    if (this.easyFormTaskReportToDateChange() == false) return false;
    this.RecordsPerPage = 50;
    if (exoportoexcel == false) {
      if (isfromsearch == true && (prevornext == false || prevornext == undefined) && this.TotalCountValue == false) {
        this.TotalCountValueIcon = false;
        this.TotalCountValue = true;
      }
      if (!this.hasValue(this.FromRecords) || (this.hasValue(isfromsearch) && isfromsearch == true)) {
        this.FromRecords = 1;
      }
      switch (prevornext) {
        case 1:
          //if(this.hasValue(this.PreviousButtondisabled) && this.PreviousButtondisabled==true) return;
          this.FromRecords -= this.RecordsPerPage
          break;

        case 2:
          //if(this.hasValue(this.NextButtonDisabled) && this.NextButtonDisabled==true) return;
          this.FromRecords += this.RecordsPerPage
          break;
        case 3:
          this.fromrecordsfromcount = this.FromRecords;
          this.FromRecords = null;
      }
    }
    let statusids = this.SearchStatusIDs.map(item => item.TaskStatusId).join(',')
    const data = {
      FromRecords: this.hasValue(this.FromRecords) ? this.FromRecords : undefined,
      IsTaskCreatedEHR: null,
      TaskLatestStatusList: this.hasValue(statusids) ? statusids : undefined,
      ResponsibleUserID: this.hasValue(this.responsiblePersonInfo.EmployeeId) ? this.responsiblePersonInfo.EmployeeId : undefined,
      PracticeIDsList: this.hasValue(this.practiceInformation.PracticeId) ? this.practiceInformation.PracticeId.toString() : undefined,
      TaskCurrentWorkingStatusInfoIDs: this.hasValue(this.easyFormTaskReportCurrentWorkingLocation) ? [this.easyFormTaskReportCurrentWorkingLocation] : ["18", "19", "20", "23", "24"],
      tasksModifedToDate: this.hasValue(this.easyFormTaskReportToDate) ? this.formatValue(this.easyFormTaskReportToDate) : undefined,
      tasksModifedFromDate: this.hasValue(this.easyFormTaskReportFromDate) ? this.formatValue(this.easyFormTaskReportFromDate) : undefined,
    }
    if (exoportoexcel == true) {
      this.commonhelperobj.PostData(Config.OASReportsofTasksAppnURL + 'EasyFormTasksListExcelReport', data).subscribe(serviceResponse => {
        if (this.isError(serviceResponse)) { return; }
        if (this.hasValue(serviceResponse)&& this.hasValue(serviceResponse.ExcelReportList)&&serviceResponse.ExcelReportList.length>0) {
          this._excelService.exportAsExcelFile(this._easyFormTaskReportService.ExcelColumnsInfo, serviceResponse.ExcelReportList, "EasyFormTaskReport")
          // window.open(serviceResponse);
        }
      });
      return;
    }
    this.taskService.taskList(data).subscribe(response => {
      if (this.commonhelperobj.isError(response)) {
        return;
      }

      if (this.hasValue(response) && this.hasValue(response.Count) && response.Count > 0) {
        this.TotalCountValueIcon = true;
        this.TotalCountValue = false;
        this.TotalLabelCount = response.Count;
        this.FromRecords = this.fromrecordsfromcount;
        return;
      }
      this.easyFormTaskReportRowData = response.EmployeeTaskListModelList;
      this.PrevLabelCount = this.FromRecords
      if (this.FromRecords == 1) {
        this.NextLabelCount = response.EmployeeTaskListModelList.length
      }
      else {
        this.NextLabelCount = this.FromRecords + response.EmployeeTaskListModelList.length - 1;
      }
      if (this.FromRecords == 1 && response.EmployeeTaskListModelList.length < this.RecordsPerPage) {
        this.prevnextbuttons = true;
        this.TotalLabelCountValue = response.EmployeeTaskListModelList.length
      }
      else if (this.FromRecords == 1 && response.EmployeeTaskListModelList.length == this.RecordsPerPage) {
        this.prevnextbuttons = false;
        this.PreviousButtondisabled = true;
        this.NextButtonDisabled = false;
      }
      else if (response.EmployeeTaskListModelList.length < this.RecordsPerPage) {
        this.prevnextbuttons = false;
        this.NextButtonDisabled = true;
        this.PreviousButtondisabled = false;
      }
      else {

        this.prevnextbuttons = false;
        this.PreviousButtondisabled = false;
        this.NextButtonDisabled = false;
      }
    });


  }
  easyFormTaskReportSelectPracticeIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Customer',
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.practiceInformation = result;
      }
    });
  }
  easyFormTaskReportSelectResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select User(s)',
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {

        this.responsiblePersonInfo = result;
      }
    });
  }
  easyFormTaskReportRemoveSelectedResponsiblePersonClickEvent() {
    this.responsiblePersonInfo = {}
  }
  easyFormTaskReportRemoveSelectedPracticeClickEvent() {
    this.practiceInformation = {};
  }
  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, 'MM/dd/yyyy')}` : '';
  }
  easyFormViewClickEvent() {
    let selectedTask = this.getSelectedTaskInfo()
    if (!this.hasValue(selectedTask)) return;
    const PostDataService: any = {
      IsFromEdit: true,
      editmodedata: selectedTask,
      isfrom: 7,
    };
    // if (this.isFromSetByMe === true) {
    //   PostDataService.IsFromSetByMe = true;
    // }
    PostDataService.StatemaintainPreviouslySearchFilters = {
      responsiblePersonInfo: this.responsiblePersonInfo,
      practiceInformation: this.practiceInformation,
      StatemaintainFromRecords: this.FromRecords,
      SearchStatusIDs: this.SearchStatusIDs,
      easyFormTaskReportCurrentWorkingLocation: this.easyFormTaskReportCurrentWorkingLocation,
      easyFormTaskReportFromDate: this.easyFormTaskReportFromDate,
    };
    const outletObj = {};
    outletObj['main'] = ['app-view-full-task-information', this.setCommonData(PostDataService)];
    this._router.navigate(['/home', { outlets: outletObj }]);
  }
  getSelectedTaskInfo() {
    const selectedrow = this.easyFormTaskReportGrid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.ShowErrorMessage("Please Select Task.");
      document.getElementById("myAppointmentsSelectUser").focus();
      return 0;
    }
    return selectedrow[0];
  }
  easyFormTaskReportSelectAdditionalResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select User(s)',
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
  }
  statemaintainPreviousSelectedRecord() {
    let seletedtasknumber = this.selectedTask
    let storeGridInfo = this.easyFormTaskReportGrid.api
    setTimeout(() => {
      this.easyFormTaskReportGrid.api.forEachNode(function (node) {
        if (node.data.TaskNumber == seletedtasknumber) {
          node.setSelected(true);
          //Moving Scroll bar to Selected Node
          storeGridInfo.ensureIndexVisible(node.rowIndex + 1);
        }
      });
    }, 100);

  }
  easyFormTaskReportFromDateChange() {
    if (!(this.modifiedFromDateToDateValidations(this.easyFormTaskReportFromDate, "txtEasyFormTaskReportFromDate", true))) return false;
  }
  easyFormTaskReportToDateChange() {

    if (!(this.modifiedFromDateToDateValidations(this.easyFormTaskReportToDate, "txtEasyFormTaskReportToDate", false))) return false;
  }
  modifiedFromDateToDateValidations(modifiedfromandtoDate: Date, name: string, isfrom: boolean) {
    var today = new Date();
    var toDate = new Date(modifiedfromandtoDate);
    var showfocuselement = false;
    const daysDifference = this.DateDiffInDays(isfrom ? this.easyFormTaskReportToDate : today, isfrom ? this.easyFormTaskReportFromDate : this.easyFormTaskReportToDate);
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage('Invalid Day, Month or Year Range Detected.');
      showfocuselement = true;
    }
    if (this.hasValue(daysDifference) && daysDifference < 0) {
      if (isfrom)
        this.ShowErrorMessage('From Date Should be Less Than or Equal to To Date');
      else
        this.ShowErrorMessage('To Date Should be Less Than or Equal to Current Date');
      showfocuselement = true;
    }
    if (showfocuselement) {
      const elementFound = this.EasyFormTaskReport.nativeElement.querySelector('[name="' + name + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    return true;
  }
}
