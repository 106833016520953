import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { GridOptions, GridSizeChangedEvent } from 'ag-grid-community';
import { AgGridNg2 } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { commonHelperService } from '../common/common.service';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee } from '../edit-task-customization-based-on-users/edit-task-customization-based-on-users.component';
import { TaskService } from '../services/task.service';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { UncustomizedtaskCommentsComponent } from '../uncustomizedtask-comments/uncustomizedtask-comments.component';

@Component({
  selector: 'app-uncustomized-task-list',
  templateUrl: './uncustomized-task-list.component.html',
  styleUrls: ['./uncustomized-task-list.component.css'],
})
export class UncustomizedTaskListComponent extends commonhelper implements OnInit {
  @ViewChild('viewTaskGrid') viewTaskGrid: AgGridNg2;
  @ViewChild('ViewUncustomizedMailsTaskGrid') ViewUncustomizedMailsTaskGrid: AgGridNg2;
  // @ViewChild('UnCustomizedViewTaskGrid') UnCustomizedViewTaskGrid: AgGridNg2;

  public gridOptions: GridOptions;
  public unCustomizedGridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public selectedRowsString = undefined;
  public selectedUncustomizedMailRowsString = undefined;
  public overlayNoRowsTemplate;

  UncustomizedTaskListFromDate = undefined;
  UncustomizedTaskListToDate = undefined;

  private commonhelperobj: commonHelperService;
  public unCustomizedTaskListHide = false;

  public unCustomizedTaskListTask;
  public unCustomizedTaskListSubject;
  public viewTaskInfoSelectPracticeDropDown: any = [];
  SearchPracticeIDs: any = [];
  public ClickedTabItem: any;

  public viewModel = {
    searchTaskNumber: undefined,
    searchSubject: undefined,
  } as any;
  public subjectwidth;

  columnDefs = [
    // { headerName: 'Task ID', field: 'TaskInfoID', width: 200 },
    { headerName: '#', field: 'TaskNumber', width: 52, tooltipField: 'TaskNumber', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'right' } },
    { headerName: 'Practice', field: 'PracticeName', width: 233, tooltipField: 'PracticeName', cellClass: 'no-border cell-wrap-text', autoHeight: true },

    { headerName: 'Subject', field: 'IssueSubject', width: 180, tooltipField: 'IssueSubject', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Priority', field: 'SeverityDesc', width: 103, tooltipField: 'SeverityDesc', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Status', field: 'TaskLatestStatusName', width: 98, tooltipField: 'TaskLatestStatusName', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Created Date', field: 'IssueCreatedOn', width: 200, tooltipField: 'IssueCreatedOn', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellRenderer: this.newdateTimeFormatCellRenderer, cellStyle: { textAlign: 'center' } },
    { headerName: 'Created By', field: 'CreatedBy', width: 0, tooltipField: 'CreatedBy', cellClass: 'no-border cell-wrap-text', autoHeight: true, hide: true },
    { headerName: 'TaskTypeId', field: 'TaskTypeId', width: 0, tooltipField: 'TaskTypeId', cellClass: 'no-border cell-wrap-text', autoHeight: true, hide: true },
    { headerName: 'IsPublicTask', field: 'IsPublicTask', width: 0, tooltipField: 'IsPublicTask', cellClass: 'no-border cell-wrap-text', autoHeight: true, hide: true },


  ];
  
  onViewTaskGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  unCustomizedColumnDefs = [
    { headerName: '#', field: 'TaskNumber', width: 85, tooltipField: 'Number of task', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'right' } },
    //{ headerName: 'Practice', field: 'PracticeName', width: 140, tooltipField: 'Practice Name', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'From Email', field: 'CreatedBy', width: 250, tooltipField: 'Email Sent From', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Subject', field: 'IssueSubject', width: 200, tooltipField: 'IssueSubject', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Priority', field: 'SeverityDesc', width: 180, tooltipField: 'SeverityDesc', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Status', field: 'TaskLatestStatusName', width: 135, tooltipField: 'TaskLatestStatusName', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Created Date', field: 'IssueCreatedOn', width: 200, tooltipField: 'IssueCreatedOn', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellRenderer: this.newdateTimeFormatCellRenderer, cellStyle: { textAlign: 'center' } },
  ];
  onViewUncustomizedMailsTaskGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }



  // this array is used by the grid to render data in html
  public rowData = [];

  public unCustomizedrowData = [];


  constructor(private http: HttpClient,
    private _router: Router,
    private route: ActivatedRoute,
    private taskService: TaskService,
    private dialogService: DialogService) {
    super();
    this.overlayNoRowsTemplate = `<span style=\'Font-size:16px;
    color:#00529B;background-color:#BDE5F8;margin: 50px 0px;padding: 12px;border-radius: 4px;\'>No Data Available</span>`;

    this.commonhelperobj = new commonHelperService(http);
    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this
      }
    };
    this.unCustomizedGridOptions = <GridOptions>{
      context: {
        componentParent: this
      }
    };

    // this.unCustomizedGridOptions = <GridOptions>{};


  }

  mainButtonsTasksDivClick = function (item) {
    if (item === 1) {
      this.ClickedTabItem = item;
      this.unCustomizedTaskListHide = false;
      $('#divNotAssignedTasksToAnyOne').addClass('MainButtonsHoverClass');
      $('#divNotAssignedEmailsToAnyOne').removeClass('MainButtonsHoverClass');
      setTimeout(() => {
        document.getElementById('divSearchTask').focus();
      }, 50);
    } else {
      this.ClickedTabItem = item;
      this.unCustomizedTaskListHide = true;

      //  this._router.navigate(['/home', { outlets: { 'main': 'app-not-assigned-emails-to-anyone' }, }]);
      // const PostDataService: any = {
      //   // IsFromEdit: true,
      //   // editmodedata: this.selectedRowsString,
      // };

      // // PostDataService.SelectedTabItem = this.GetSelectedItem;
      // const outletObj = {};
      // outletObj['main'] = ['app-not-assigned-emails-to-anyone', this.setCommonData(PostDataService)];
      // this._router.navigate(['/home', { outlets: outletObj }]);

      $('#divNotAssignedEmailsToAnyOne').addClass('MainButtonsHoverClass');
      $('#divNotAssignedTasksToAnyOne').removeClass('MainButtonsHoverClass');
      setTimeout(() => {
        document.getElementById('divUnCustomizedTaskSearchTask').focus();
      }, 50);
    }

  };


  public unCustomizedTaskListPopulateTable() {

  }

  ngOnInit() {


    $('#divNotAssignedTasksToAnyOne').addClass('MainButtonsHoverClass');
    $('#divNotAssignedEmailsToAnyOne').removeClass('MainButtonsHoverClass');
    setTimeout(() => {
      document.getElementById('divSearchTask').focus();
    }, 50);
    this.practiceList();
    this.populateTable();
    this.populateUncustomizedMailsTable();

    for (let i = 0; i < this.columnDefs.length; i++) {
      switch (this.columnDefs[i].field) {
        case 'IssueSubject':
          if ($(window).width() > 1700) {
            this.columnDefs[2].width = 1000;
          } else if ($(window).width() < 1700 && $(window).width() >= 1670) {
            this.columnDefs[2].width = 920;
          } else if ($(window).width() < 1670 && $(window).width() >= 1480) {
            this.columnDefs[2].width = 500;
          } else if ($(window).width() < 1480 && $(window).width() >= 1400) {
            this.columnDefs[2].width = 480;
          } else if ($(window).width() < 1400 && $(window).width() >= 1300) {
            this.columnDefs[2].width = 400;
          } else if ($(window).width() < 1300 && $(window).width() >= 1200) {
            this.columnDefs[2].width = 250;
          } else if ($(window).width() < 1200 && $(window).width() >= 1100) {
            this.columnDefs[2].width = 120;
          } else if ($(window).width() < 1100 && $(window).width() >= 700) {
            this.columnDefs[2].width = 70;
          } else if ($(window).width() < 700 && $(window).width() >= 300) {
            this.columnDefs[2].width = 270;
          }
          break;
      }
    }

    for (let i = 0; i < this.unCustomizedColumnDefs.length; i++) {
      switch (this.unCustomizedColumnDefs[i].field) {
        case 'IssueSubject':
          if ($(window).width() > 1700) {
            this.unCustomizedColumnDefs[2].width = 740;
          } else if ($(window).width() < 1700 && $(window).width() >= 1670) {
            this.unCustomizedColumnDefs[2].width = 700;
          } else if ($(window).width() < 1670 && $(window).width() >= 1480) {
            this.unCustomizedColumnDefs[2].width = 700;
          } else if ($(window).width() < 1480 && $(window).width() >= 1400) {
            this.unCustomizedColumnDefs[2].width = 550;
          } else if ($(window).width() < 1400 && $(window).width() >= 1300) {
            this.unCustomizedColumnDefs[2].width = 480;
          } else if ($(window).width() < 1300 && $(window).width() >= 1200) {
            this.unCustomizedColumnDefs[2].width = 400;
          } else if ($(window).width() < 1200 && $(window).width() >= 1100) {
            this.unCustomizedColumnDefs[2].width = 250;
          } else if ($(window).width() < 1100 && $(window).width() >= 700) {
            this.unCustomizedColumnDefs[2].width = 120;
          } else if ($(window).width() < 700 && $(window).width() >= 300) {
            this.unCustomizedColumnDefs[2].width = 270;
          }
          break;
      }
    }

  }

  public practiceList() {
    let postData;

    postData = {
      PracticeId: undefined,
      PracticeName: undefined
    };

    this.taskService.PracticesList(postData).subscribe(response => {
      if (this.isError(response)) {
        return;
      }
      this.viewTaskInfoSelectPracticeDropDown = response;
    });
  }

  public populateTable() {
    this.selectedRowsString = undefined;
    const PracticeIDsListtoSearch = this.SearchPracticeIDs; //selected multiple practice IDs
    if (this.hasValue(PracticeIDsListtoSearch) && PracticeIDsListtoSearch.length > 0) {
      if (PracticeIDsListtoSearch.length > 5) {
        this.ShowErrorMessage('Please Select Only 5 Practices to Search.');
        return;
      }
    }

    const postData = {
      TaskNumber: this.viewModel.searchTaskNumber,
      IssueSubject: this.viewModel.searchSubject,
      PracticeIDsList: this.SearchPracticeIDs.map(item => item.PracticeId).join(',') //selected multiple practice IDs
    };

    this.commonhelperobj.PostData(Config.OASAppnURL + 'UncustomizedTaskList', postData)
      .subscribe(responce => {
        this.rowData = responce.OASIssueReportFromCustomerModelList;
        this.gridOptions.api.sizeColumnsToFit();
      });
    setTimeout(function () {
      document.getElementById("divSearchTask").focus();
    }, 100);
  }

  onPracticeSelectionChange() {
    // const PracticeIDsListtoSearch = this.SearchPracticeIDs; //selected multiple practice IDs
    // if (this.hasValue(PracticeIDsListtoSearch) && PracticeIDsListtoSearch.length > 0) {
    //   if (PracticeIDsListtoSearch.length > 2) {
    //     this.ShowErrorMessage('Please Select Only 5 Practices to Search.');
    //     return;
    //   }
    // }
  }


  // unCustomizedTaskListEditButtonClickEvent(event) {

  //   if (!this.selectedRowsString) {
  //     this.ShowErrorMessage('Please select a task to edit');
  //     return;
  //   }

  //   // object contains data that is to be sent to another route
  //   const dataToRoute = {
  //     data: this.selectedRowsString,
  //   };

  //   const outletObj = {};
  //   outletObj['main'] = ['app-edituncustomizedtasklist', this.setCommonData(dataToRoute)];
  //   this._router.navigate(['/home', { outlets: outletObj }]);
  // }

  public onAutoSyncButtonClickEvent(event: Event) {
    // Emails
    if (!this.UncustomizedTaskListFromDate) {
      this.ShowErrorMessage('Please enter auto sync start date');
      return;
    }

    if (!this.UncustomizedTaskListToDate) {
      this.ShowErrorMessage('Please enter auto sync end date');
      return;
    }

    if (new Date(this.UncustomizedTaskListFromDate) > new Date(this.UncustomizedTaskListToDate)) {
      this.ShowErrorMessage('From Date Must be Less than or Equal to To Date');
      return;
    }

    const postData = {
      AutoSyncFromDate: this.UncustomizedTaskListFromDate,
      AutoSyncToDate: this.UncustomizedTaskListToDate,
    };

    this.commonhelperobj.PostData(Config.OASPracticeURL + 'Emails', postData)
      .subscribe(responce => this.unCustomizedrowData = responce.OASIssueReportFromCustomerModelList);
  }


  public populateUncustomizedMailsTable() {

    const postData = {
      TaskNumber: this.unCustomizedTaskListTask,
      IssueSubject: this.unCustomizedTaskListSubject,
    };

    this.commonhelperobj.PostData(Config.OASPracticeURL + 'GetUncustomizedEmailList', postData)
      .subscribe(responce => this.unCustomizedrowData = responce.OASIssueReportFromCustomerModelList);
    setTimeout(function () {
      document.getElementById("divUnCustomizedTaskSearchTask").focus();
    }, 100);
  }

  UncustomizedTaskListFromDateChange(event) {

  }


  UncustomizedTaskListToDateChange(event: Event) {

    // if (!this.UncustomizedTaskListFromDate) {
    //   this.ShowErrorMessage('Please enter auto sync start date');
    //   return;
    // }

    // if (!this.UncustomizedTaskListToDate) {
    //   this.ShowErrorMessage('Please enter auto sync end date');
    //   return;
    // }

    // if (new Date(this.UncustomizedTaskListFromDate) > new Date(this.UncustomizedTaskListToDate)) {
    //   this.ShowErrorMessage('From date should be less than to date');
    //   return;
    // }


    // const postData = {
    //   AutoSyncFromDate: this.UncustomizedTaskListFromDate,
    //   AutoSyncToDate: this.UncustomizedTaskListToDate,
    // };
  }


  onEditButtonClickEvent(event: Event) {

    if (!this.selectedRowsString) {
      this.ShowErrorMessage('Please Select Request');
      setTimeout(function () {
        document.getElementById("divSearchTask").focus();
      }, 20);
      return;
    }



    // object contains data that is to be sent to another route
    const dataToRoute = {
      data: this.selectedRowsString,

    };

    const outletObj = {};
    outletObj['main'] = ['app-edituncustomizedtasklist', this.setCommonData(dataToRoute)];
    this._router.navigate(['/home', { outlets: outletObj }]);
  }


  unCustomizedTaskListEditButtonClickEvent(event: Event) {

    if (!this.selectedUncustomizedMailRowsString) {
      this.ShowErrorMessage('Please Select Request');
      setTimeout(function () {
        document.getElementById("divUnCustomizedTaskSearchTask").focus();
      }, 20);
      return;
    }

    if (this.hasValue(this.selectedUncustomizedMailRowsString)) {
      this.selectedUncustomizedMailRowsString.IsFromEmailUncustomizedList = true;
    }

    // object contains data that is to be sent to another route
    const dataToRoute = {
      data: this.selectedUncustomizedMailRowsString,
      // IsFromEmailUncustomizedList: true,
    };

    const outletObj = {};
    outletObj['main'] = ['app-edituncustomizedtasklist', this.setCommonData(dataToRoute)];
    this._router.navigate(['/home', { outlets: outletObj }]);
  }

  onSelectionChanged(event: Event) {
    const selectedRows = this.viewTaskGrid.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      if (index !== 0) {
        this.selectedRowsString = undefined;
      }
      this.selectedRowsString = selectedRow;

    });
  }

  // onEmailSelectionChanged(event: Event) {
  //   const selectedRows = this.emailGrid.api.getSelectedRows();

  //   selectedRows.forEach((selectedRow, index) => {
  //     if (index !== 0) {
  //       this.selectedEmailRow = undefined;
  //     }
  //     this.selectedEmailRow = selectedRow;

  //   });
  // }


  onUncustomizedMailTaskSelectionChanged(event: Event) {
    const selectedUncustomizedMailRows = this.ViewUncustomizedMailsTaskGrid.api.getSelectedRows();

    selectedUncustomizedMailRows.forEach((selectedRow, index) => {
      if (index !== 0) {
        this.selectedUncustomizedMailRowsString = undefined;
      }
      this.selectedUncustomizedMailRowsString = selectedRow;

    });
  }

  sendResponseToCustomerClickEvent() {
    if (!this.selectedRowsString) {
      this.ShowErrorMessage('Please Select a Task to Send Response');
      return;
    }
    //console.log(this.selectedRowsString);
    const dialogRef = this.dialogService.open({
      title: 'Send Response to Customer',
      // Show component
      content: UncustomizedtaskCommentsComponent,

      height: 500,
      width: 700
    });
    dialogRef.content.instance.TaskInfoID = this.selectedRowsString.TaskInfoID;
    dialogRef.content.instance.IsPublicComment = true;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {

      } else {
        var postData: any = result;
        if (this.hasValue(postData)) {
          postData.TaskInfoId = this.selectedRowsString.TaskInfoID;
          this.commonhelperobj.PostData(Config.OASAppnURL + 'InsertCommentsInformation', postData).subscribe(serviceResponse => {
            if (this.isError(serviceResponse)) { return; }
            this.ShowSuccesseMessage('Response Sent to Customer Successfully.');
            this.selectedRowsString = undefined;
            // this.myCommentsFiles = [];
            // this.viewModel.commentBody = '';
            // this.viewModel.attachments = [];
            // this.VieworLoadUncustomizedpreviousCommentsBlock = false;

            // this.GetTaskBodyList();

            // this.populateHistoryTable(); 
          });
        }
      }
      this.selectedRowsString = undefined;
      this.populateTable();
    });
  }


}
