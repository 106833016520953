import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';
import { IntlService } from '@progress/kendo-angular-intl';
@Component({
  selector: 'app-add-practice-check-list-item-popup',
  templateUrl: './add-practice-check-list-item-popup.component.html',
  styleUrls: ['./add-practice-check-list-item-popup.component.css']
})
export class AddPracticeCheckListItemPopupComponent extends commonhelper implements OnInit {
  @ViewChild('CheckListItem') CheckListItem: ElementRef;
  private commonhelperobj: commonHelperService;
  checkListItemInfoId: any;
  public addPracticeCheckListModel: any = {};
  public showhideSaveButton: boolean = false;
  public addpracticeCheckListCategoryItemsList = [];
  constructor(private intl: IntlService, private http: HttpClient, private dialogService: DialogService, httpClient: HttpClient, private dialog: DialogRef,) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }
  public defaultaddpracticeCheckListCategoryItem = { CheckListItemCategoryName: '-- Check List Category --', CheckListItemCategoryInfoID: null };
  ngOnInit() {

    this.GetCheckListCategoriesList();
    if (this.checkListItemInfoId > 0) {
      this.showhideSaveButton = true;
      this.getSelectedCheckListItemInfo();

    }
    setTimeout(() => {
      const elementFound = this.CheckListItem.nativeElement.querySelector('[name="' + 'addpracticeCheckListCategoryItem' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
    }, 50);
  }
  addPracticeCheckListItemPopupCancelClickEvent() {
    this.dialog.close();
  }
  private GetCheckListCategoriesList() {
    this.commonhelperobj.PostData(Config.OASPracticeCheckListItemsAppnURL + 'GetPracticeMasterCheckListCategoriesList', {}).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.addpracticeCheckListCategoryItemsList = serviceResponse.practiceMasterCheckListItemsOutputModels;
    });


  }
  getSelectedCheckListItemInfo() {
    var PostDataToService = {
      CheckListItemInfoID: this.checkListItemInfoId
    };
    this.commonhelperobj.PostData(Config.OASPracticeCheckListItemsAppnURL + 'GetPracticeCheckListItem', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.addPracticeCheckListModel = serviceResponse.addPracticeCheckListModel;
      this.addPracticeCheckListModel.CheckListItemEffectiveStartDate = this.parseDate(serviceResponse.addPracticeCheckListModel.CheckListItemEffectiveStartDate)
      this.addPracticeCheckListModel.CheckListItemEffectiveEndDate = this.parseDate(serviceResponse.addPracticeCheckListModel.CheckListItemEffectiveEndDate)
    });
  }
  private parseDate(dateString: string): Date {
    if (!this.hasValue(dateString) || dateString.indexOf('/') === dateString.lastIndexOf('/')) {
      return undefined;
    }

    const [month, day, year] = dateString.split('/').map(dateFragment => +dateFragment);
    return new Date(year, month - 1, day);
  }
  addPracticeCheckListItemPopupSavePlusClickEvent() {
    this.addPracticeCheckListItemPopupSaveClickEvent(true);

  }
  addPracticeCheckListItemPopupSaveClickEvent(isfromsaveplus) {
    if (this.checkMandatoryValidations()) return;
    var checkListModel = this.addPracticeCheckListModel
    checkListModel.CheckListItemEffectiveStartDate = this.hasValue(this.addPracticeCheckListModel.CheckListItemEffectiveStartDate) ? this.formatValue(this.addPracticeCheckListModel.CheckListItemEffectiveStartDate) : undefined
    checkListModel.CheckListItemEffectiveEndDate = this.hasValue(this.addPracticeCheckListModel.CheckListItemEffectiveEndDate) ? this.formatValue(this.addPracticeCheckListModel.CheckListItemEffectiveEndDate) : undefined
    this.commonhelperobj.PostData(Config.OASPracticeCheckListItemsAppnURL + 'InsertorUpdatePracticeMasterCheckListItems', checkListModel).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      if (!isfromsaveplus)
        this.dialog.close("Success");
      else
        this.addPracticeCheckListModel = {};
    });
  }
  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, 'd')}` : '';
  }

  checkMandatoryValidations() {
    if (!this.hasValue(this.addPracticeCheckListModel.CheckListItemCategoryInfoID) || this.addPracticeCheckListModel.CheckListItemCategoryInfoID <= 0) {
      this.ShowErrorMessage("Please Select Check List Category")
      const elementFound = this.CheckListItem.nativeElement.querySelector('[name="' + 'addpracticeCheckListCategoryItem' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return true;
    }
    if (!this.hasValue(this.addPracticeCheckListModel.CheckListItemName)) {
      this.ShowErrorMessage("Please Enter Check List Item Name")
      document.getElementById("addpracticeCheckListItemName").focus();
      return true;
    }
    //  if(!this.addPracticeCheckListModel.CheckListItemEffectiveStartDate){
    //   this.ShowErrorMessage("Please Select / Enter Effective Start Date")
    //   const elementFound = this.CheckListItem.nativeElement.querySelector('[name="' + 'txtAddPracticeCheckListItemPopupStartDate' + '"]');
    //       if (this.hasValue(elementFound)) {
    //         elementFound.children[0].children[0].children[0].children[0].focus();
    //       }
    //   return true;
    // }
    // if(!this.addPracticeCheckListModel.CheckListItemEffectiveEndDate){
    //   this.ShowErrorMessage("Please Select / Enter Effective End Date")
    //   const elementFound = this.CheckListItem.nativeElement.querySelector('[name="' + 'txtAddPracticeCheckListItemPopupEndDate' + '"]');
    //       if (this.hasValue(elementFound)) {
    //         elementFound.children[0].children[0].children[0].children[0].focus();
    //       }
    //   return true;
    // }
    if (this.hasValue(this.addPracticeCheckListModel.CheckListItemEffectiveEndDate)) {
      if (!this.hasValue(this.addPracticeCheckListModel.CheckListItemEffectiveStartDate)) {
        this.ShowErrorMessage("Please Select / Enter Effective Start Date")
        const elementFound = this.CheckListItem.nativeElement.querySelector('[name="' + 'txtAddPracticeCheckListItemPopupStartDate' + '"]');
        if (this.hasValue(elementFound)) {
          elementFound.children[0].children[0].children[0].children[0].focus();
        }
        return true;
      }
    }
    if (this.hasValue(this.addPracticeCheckListModel.CheckListItemEffectiveStartDate)) {
      if (this.addPracticeCheckListStartdateChange() == false) return true;
    }
    if (this.hasValue(this.addPracticeCheckListModel.CheckListItemEffectiveEndDate)) {

      if (this.addPracticeCheckListEndateChange() == false) return true;
    }
  }
  addPracticeCheckListStartdateChange() {
    if (this.hasValue(this.addPracticeCheckListModel.CheckListItemEffectiveStartDate))
      if (!(this.addPracticeCheckListStartandEndDateValidations(this.addPracticeCheckListModel.CheckListItemEffectiveStartDate, "txtAddPracticeCheckListItemPopupStartDate", true))) return false;
  }
  addPracticeCheckListEndateChange() {
    if (this.hasValue(this.addPracticeCheckListModel.CheckListItemEffectiveEndDate))
      if (!(this.addPracticeCheckListStartandEndDateValidations(this.addPracticeCheckListModel.CheckListItemEffectiveEndDate, "txtAddPracticeCheckListItemPopupEndDate", false))) return false;
  }
  addPracticeCheckListStartandEndDateValidations(modifiedfromandtoDate: Date, name: string, isfrom: boolean) {
    var today = new Date();
    var toDate = new Date(modifiedfromandtoDate);
    var showfocuselement = false;
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage('Invalid Day, Month or Year Range Detected.');
      const elementFound = this.CheckListItem.nativeElement.querySelector('[name="' + name + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    if (isfrom) {
      var daysDifference;
      daysDifference = this.DateDiffInDays(this.addPracticeCheckListModel.CheckListItemEffectiveStartDate, new Date());
      if (this.hasValue(daysDifference) && daysDifference < -1) {
        this.ShowErrorMessage('Check List Item Start Date Should be Greater Than or Equal to Current Date');
        const elementFound = this.CheckListItem.nativeElement.querySelector('[name="' + name + '"]');
        if (this.hasValue(elementFound)) {
          elementFound.children[0].children[0].children[0].children[0].focus();
        }
        return false;
      }
      daysDifference = this.DateDiffInDays(this.addPracticeCheckListModel.CheckListItemEffectiveEndDate, this.addPracticeCheckListModel.CheckListItemEffectiveStartDate);
      if (this.hasValue(daysDifference) && daysDifference < 0) {
        if (this.hasValue(this.addPracticeCheckListModel.CheckListItemEffectiveEndDate)) {
          this.ShowErrorMessage('Check List Item Start Date Should be Less Than or Equal to Check List Item End Date');
          const elementFound = this.CheckListItem.nativeElement.querySelector('[name="' + name + '"]');
          if (this.hasValue(elementFound)) {
            elementFound.children[0].children[0].children[0].children[0].focus();
          }
          return false;
        }
      }
    }
    return true;
  }
}
