import { Component, OnInit, ViewChild } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { TaskService } from '../services/task.service';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { commonHelperService } from '../common/common.service';
import { Observable } from 'rxjs';
import { Config } from '../config';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-change-password-popup-component',
  templateUrl: './change-password-popup-component.component.html',
  styleUrls: ['./change-password-popup-component.component.css']
})
export class ChangePasswordPopupComponentComponent extends commonhelper implements OnInit {
  private commonhelperobj: commonHelperService;
  public changePasswordDetails: any = {};
  constructor(httpClient: HttpClient, private dialogService: DialogService, private dialog: DialogRef, private _Router: Router) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }

  public userEmailAddress: any;

  ngOnInit() {
    this.changePasswordDetails.EmailAddress = this.userEmailAddress;
  }


  validatePassword(inputdata) {
    if (this.hasValue(inputdata)) {
      let re = new RegExp("^(?=.*[a-zA-Z])(?=.*?[0-9])(?=.*[!@#$%^&*+=]).{8,}$");
      if (re.test(inputdata)) {
        return null;
      }
      else {
        this.ShowErrorMessage('Password Should Contains Minimum Eight Characters, At least one letter, one number and one Special Character');
        document.getElementById("txtchangepasswordNewPassword").focus();
        return false;
      }
    }
  }

  saveChangePasswordDetails() {
    if (!this.hasValue(this.changePasswordDetails.newPassword)) {
      this.ShowErrorMessage('Please Enter New Password.');
      document.getElementById("txtchangepasswordNewPassword").focus();
      return false;
    }
    // this.validatePassword(this.changePasswordDetails.newPassword)
    if (this.validatePassword(this.changePasswordDetails.newPassword) == false) {
      return false;
    }

    if (!this.hasValue(this.changePasswordDetails.confirmPassword)) {
      this.ShowErrorMessage('Please Enter Confirm Password.');
      document.getElementById("txtchangepasswordConfirmPassword").focus();
      return false;
    }

    if (this.changePasswordDetails.newPassword != this.changePasswordDetails.confirmPassword) {
      this.ShowErrorMessage('New Password and Confirm Password Must be Same.');
      document.getElementById("txtchangepasswordConfirmPassword").focus();
      return false;
    }

    const postData = {
      EmployeeId: Config.OASOrganizationModel.LoggedUserID,
      Password: this.changePasswordDetails.newPassword
    }


    this.commonhelperobj.PostData(Config.OASOrganizationAppnURL + 'UpdateOasEmployeePassword', postData)
      .subscribe(serviceResponse => {

        if (this.isError(serviceResponse)) { return; }
        this.ShowSuccesseMessage('Employee Password Changed Successfully.');
        //this._Router.navigateByUrl('/');
        this.dialog.close();
      });

  }



  CancelClickEvent() {
    this.dialog.close();
  }

}
