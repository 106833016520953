
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { config } from 'rxjs';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';



@Injectable()
export class ViewAppointmentsPopupService extends commonHelperService {
    constructor(http: HttpClient) {
        super(http);
    }
    GetAppointmentsList(postData: any) {
        return this.PostData(Config.OASAppointmentsInformationAppnURL + 'GetAppointmentsList', postData).pipe(((response) => {
            return response;
        }));
    }
    AppointmetnCommentsList(postData: any) {
        return this.PostData(Config.OASAppointmentsInformationAppnURL + 'AppointmetnCommentsList', postData).pipe(((response) => {
            return response;
        }));
    }
    UpdateAppointmentStatusComments(postData: any){
        return this.PostData(Config.OASAppointmentsInformationAppnURL + 'UpdateAppointmentStatusComments', postData).pipe(((response) => {
            return response;
        }));
    }
    DeleteAppointmentCommentInfo(postData: any){
        return this.PostData(Config.OASAppointmentsInformationAppnURL + 'DeleteAppointmentCommentInfo', postData).pipe(((response) => {
            return response;
        }));
    }
    GetAppointmentCommentInfo(postData: any){
        return this.PostData(Config.OASAppointmentsInformationAppnURL + 'GetAppointmentCommentInfo', postData).pipe(((response) => {
            return response;
        }));
    }
}
