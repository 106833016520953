import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ElementRef,
} from "@angular/core";
import { GridOptions, GridSizeChangedEvent } from "ag-grid-community";
import { AgGridNg2 } from "ag-grid-angular";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { commonHelperService } from "../common/common.service";
import { commonhelper } from "../common/commonhelper";
import { Config } from "../config";
import { TaskService } from "../services/task.service";
import { element } from "@angular/core/src/render3/instructions";
import { TaskChangeEvent } from "../interfaces/TaskChangeEvent";
import { TaskEventTypeEnum } from "../enums/TaskHistoryChangeEvent.enum.";
import { delay, catchError } from "rxjs/operators";
import { forkJoin, of, throwError } from "rxjs";
import { stat } from "fs";
import { CSharpReviewDetailsPopupComponent } from "../csharp-review-details-popup/csharp-review-details-popup.component";
import {
  DialogCloseResult,
  DialogService,
} from "@progress/kendo-angular-dialog";
import { AngularReviewDetailsPopupComponent } from "../angular-review-details-popup/angular-review-details-popup.component";
import { UIReviewDetailsPopupComponent } from "../uireview-details-popup/uireview-details-popup.component";
import { QAReviewDetailsPopupComponent } from "../qareview-details-popup/qareview-details-popup.component";
import { IntlService, formatDate } from "@progress/kendo-angular-intl";
import { ResponsiblepersonUsersListComponent } from "../responsibleperson-users-list/responsibleperson-users-list.component";
import { PraticesListComponent } from "../pratices-list/pratices-list.component";
import { CreateNewTaskComponentComponent } from "../create-new-task-component/create-new-task-component.component";
import { ExcelService } from "../common/excel.service";
import { ViewTaskInformationService } from "./view-task-information.service";
//import { FilterableDropDownComponentBase } from '@progress/kendo-angular-dropdowns/dist/es2015/filterable-base.component';

@Component({
  selector: "app-view-task-information",
  templateUrl: "./view-task-information.component.html",
  styleUrls: ["./view-task-information.component.css"],
})
export class ViewTaskInformationComponent
  extends commonhelper
  implements OnInit
{
  @ViewChild("ViewTaskInformationID") ViewTaskInformationID: ElementRef;
  viewTaskInfoSelectedTaskType: any;
  public taskTypeList: any = [];
  addinternalpopupheight = 700;
  addinternalpopupwidth = 1500;
  SearchCurrentWorkingStatus: any;
  NewCurrentWorkingStatusList: any;
  PreviouslySelectedItem: any;
  IsFromBackButtonFromViewLoad: boolean = false;
  ForwardedtoMeCount: any;
  NeedtoShowAllTasks = false;
  HideIconFromallInternalTickets = false;
  IncomingData: any;
  public practiceInformation: any = {};
  SearchDeletedTaskSubject: any;
  SearchDeletedTaskNumber: any;
  SearchTaskSubject: any;
  SearchTaskNumber: any;
  SearchStatusIDs: any = [];
  SearchPracticeIDs: any = [];
  TaskLatestStatusList: any;
  practiceidslist: any;
  SelectedFromRecords: any;
  fromrecordsfromcount: any;
  forwardedtoUserId = 0;
  responsiblePersonUserId = 0;
  public showHideForwardedResponsiblePersonPopups: boolean = true;
  //public NextButtonDisabled: boolean = false;
  taskSearchStatusDetails: any;
  //public ListCount: any;
  //public FilterData:any ={};
  // public ResponsiblePersonsListInfo: any;
  private commonhelperobj: commonHelperService;
  public ShowGridContent = false;
  public HideConextMenuBasedOnTabSelection = false;
  public selected = 0;
  public taskcount: any;
  public ShowThisOnlyForCreatedPerson = true;
  public isFromSetByMe = false;
  public HideCommonGrid = false;
  public HideDeletedGrid = true;
  public HideIconsFromDeleted = false;
  public IconInDeleted = true;
  public GetSelectedItem: any;
  public AllTasksCount: any;
  public ModifiedTasksCount: any;
  public viewTaskInfoResponsiblePerson: any = {};
  public viewTaskInfoCreatedBy: any = {};
  responsiblePersonInfo: any = {};
  //public PageCount: any;
  public YettoStartTasksCount: any;
  prevnextbuttons: boolean = true;
  NextButtonDisabled: boolean = false;
  PreviousButtondisabled: boolean = false;
  FromRecords: any;
  PrevLabelCount: any;
  NextLabelCount: any;
  RecordsPerPage: any;
  TotalCountValue: boolean = true;
  TotalCountValueIcon: boolean = false;
  TotalLabelCount: any;
  TotalLabelCountValue: any;
  IsFromDelete: boolean = false;
  IsFromRecoverTask: boolean = false;
  StatemaintainFromRecords: any;
  TasksCountsInfo: any;
  count: any;
  isfromrecyclebin: boolean = false;
  showhideTaskModifiedFromandToDateFilters = true;
  tasksModifedFromDate: any;
  tasksModifedToDate: any;
  ECDDate: any;
  CreatedToDate: any;
  CreatedFromDate: any;
  public CreatedToTime: any = new Date();
  public CreatedFromTime: any = new Date("Tue Apr 11 2000 00:00:00 GMT+0530");
  //public count: any;
  //public PageCountFromDifferentNavigation: boolean
  //public First: any;
  //public Next: any;
  public OnGoingStartTasksCount: any;
  ReviewPendingTasksCount: any;
  CompletedTasksCount: any;
  AssistingTasksCount: any;
  SetByMeTasksCount: any;
  RecycledTasksCount: any;
  // declaring grid id block start
  @ViewChild("viewTaskGrid") viewTaskGrid: AgGridNg2;
  @ViewChild("DeletedListGrid") DeletedListGrid: AgGridNg2;
  // declaring grid id block end

  // declaring grid options block start
  public gridOptions: GridOptions;
  // public OngoinggridOptions: GridOptions;
  // public yetoStartGridOptions: GridOptions;
  // declaring grid options block end

  public gridApi;
  public gridColumnApi;
  //public PreviousButtondisabled: boolean = true;
  //public NextClickStatus: boolean = true;

  public selectedRowsString = undefined;
  public deleteselectedRowsString = undefined;
  public overlayNoRowsTemplate;
  viewTaskInfoFieldsDivHide = false;
  viewTaskInfoOngoingDivHide = true;
  viewTaskInfoAssistingDivHide = true;
  viewTaskInfoSentByMeDivHide = true;
  viewTaskInfoRecycleBinDivHide = true;
  viewTaskInfoYettoStartDivHide = true;
  public BindAllTasksCount: any;
  public OnGoingTaskListCount: any;
  public rowClassRules;
  public selectStatusDropDownHide = false;
  ForwardedtomeListInfo: any;
  public viewTaskInfoForwardedtome: any = {};
  public viewTaskQAPerson:any={};
  public viewTaskDBPerson:any={};
  public viewTaskJSPerson:any={};
  public viewTaskCSharpPerson:any={};
  firsttime: boolean;
  //public EmployeeTaskListRecordCount: any;
  //public countincrement: boolean;
  //public VieworEditinfo: any;

  public DeletedListgridOptions;
  public viewTaskInfoSelectStatusDropDown: Array<Status> = [];
  public viewTaskInfoSelectPracticeDropDown: any = [];
  public viewTaskInfoSelectUserRolesDropDown: any = [
    { UserRoleID: -1, UserRoleDescription: "Default Role" },
    { UserRoleID: 2, UserRoleDescription: "As Responsible" },
    { UserRoleID: 3, UserRoleDescription: "As Observer" },
    // { UserRoleID: 4, UserRoleDescription: 'As Participant' },
    { UserRoleID: 7, UserRoleDescription: "As Assistant" },
  ];
  public viewTaskInfoSelectCurrentWorkingStatusDropDown: any = [
    { CurrentWorkingStatusID: -1, CurrentWorkingStatus: "As XXXX" },
    { CurrentWorkingStatusID: 2, CurrentWorkingStatus: "As YYYY" },
  ];
  public contextMenuitems: any[] = [
    {
      text: "C# Review Details",
    },
    {
      text: "Angular Review Details",
    },
    {
      text: "UI Review Details",
    },
    {
      text: "QA Review Details",
    },
  ];
  public defaultCurrentWorkingStatusItem = {
    Task_Current_Working_Status: "-- Task Current Working Status --",
    Task_Current_Working_Status_InfoID: null,
  };
  // SearchCurrentWorkingStatus: any = { CurrentWorkingStatusID: -1, CurrentWorkingStatus: 'All' };

  public defaultResponsiblePersonsItem = {
    EmployeeFullName: "-- Search Responsible --",
    EmployeeId: null,
  };
  public ForwardedtomeItem = {
    EmployeeFullName: "-- Search Forwarded --",
    EmployeeId: null,
  };
  public defaultTaskTypeItem = {
    TaskTypeDescription: "-- Search Task Type --",
    TaskTypeId: null,
  };

  SearchUserRole: any = { UserRoleID: -1, UserRoleDescription: "All" };
  viewTaskInfoShowCountClickShowHideTabs: boolean = false;
  @ViewChild("ViewTaskInformationID", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  // dialogService: any;
  selectedTask: any;
  FiltersHideShowinYetToStart: boolean;
  constructor(
    private http: HttpClient,
    private readonly _viewTaskInformationService: ViewTaskInformationService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private readonly _excelService: ExcelService,
    private taskService: TaskService,
    private _router: Router,
    private intl: IntlService
  ) {
    super();
    this.SearchStatusIDs = [
      { TaskDescription: "Yet to Start", TaskStatusId: 1 },
      { TaskDescription: "On Going", TaskStatusId: 2 },
      { TaskDescription: "Denied by Customer", TaskStatusId: 7 },
      { TaskDescription: "On-hold", TaskStatusId: 8 },
    ];
    this.commonhelperobj = new commonHelperService(http);
    // tslint:disable-next-line:max-line-length
    this.overlayNoRowsTemplate =
      "<span style='Font-size:16px;color:#00529B;background-color:#BDE5F8;margin: 50px 0px;padding: 12px;border-radius: 4px;'>No Data Available</span>";
    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this,
      },
    };
  }
  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "MM/dd/yyyy")}` : "";
  }
  private formatIntValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "yyyyMMdd")}` : "";
  }
  public ResponsiblePersonsListInfo = [
    { ResponsiblePersonsFullName: "Rama Mocherla", ResponsiblePersonsId: "1" },
    { ResponsiblePersonsFullName: "Gayatri", ResponsiblePersonsId: "2" },
  ];

  public items: any = [
    {
      // disabled: true,
      tasktype: "All",
      taskMenutypeid: 1,
      myclass: "main-buttons-item-link",
    },
    {
      disabled: false,
      tasktype: "Tasks Modified",
      taskMenutypeid: 10,
      myclass: "main-buttons-item-link",
    },
    {
      disabled: false,
      tasktype: "Yet to Start",
      taskMenutypeid: 2,
      myclass: "main-buttons-item-link",
    },
    {
      disabled: false,
      tasktype: "On Going",
      taskMenutypeid: 3,
      myclass: "main-buttons-item-link",
    },
    {
      disabled: false,
      tasktype: "Review Pending",
      taskMenutypeid: 4,
      myclass: "main-buttons-item-link",
    },

    // {
    //   disabled: false,
    //   tasktype: 'Assisting',
    //   taskMenutypeid: 6,
    //   myclass: 'main-buttons-item-link',

    // },
    {
      disabled: false,
      tasktype: "Set by me",
      taskMenutypeid: 7,
      myclass: "main-buttons-item-link",
    },
    // {
    //   disabled: false,
    //   tasktype: 'Forwarded to me',
    //   taskMenutypeid: 9,
    //   myclass: 'main-buttons-item-link',

    // },
    {
      disabled: false,
      tasktype: "Completed",
      taskMenutypeid: 5,
      myclass: "main-buttons-item-link",
    },
    {
      disabled: false,
      tasktype: "Recycle Bin",
      taskMenutypeid: 8,
      myclass: "main-buttons-item-link",
    },
  ];
  public ViewSubjectwidth;

  viewTaskInfoSelectStatusDropDownInfo = {} as any;

  // for all tasks block start
  // tslint:disable-next-line:member-ordering
  columnDefs = [
    // { headerName: 'Task ID', field: 'TaskInfoID', width: 200 },
    {
      headerName: "Task #",
      field: "TaskNumber",
      width: 80,
      suppressSizeToFit: true,
      tooltipField: "Number of task",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "right" },
    }, //
    // {
    //   headerName: 'EHRPracticeID', field: 'EHRPracticeID', width: 0, tooltipField: 'EHRPracticeID',
    //   cellClass: 'no-border cell-wrap-text', autoHeight: true, hide: true
    // },
    {
      headerName: "Practice",
      field: "PracticeName",
      minWidth: 100,
      maxWidth: 250,
      tooltipField: "PracticeName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Subject",
      field: "IssueSubject",
      minWidth: 260,
      maxWidth: 350,
      tooltipField: "IssueSubject",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },

    {
      headerName: "Responsible Person",
      field: "ResponsiblePersonDesc",
      minWidth: 150,
      maxWidth: 200,
      tooltipField: "ResponsiblePersonDesc",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "QA Person",
      field: "QAPersonDesc",
      minWidth: 150,
      maxWidth: 200,
      tooltipField: "QAPersonDesc",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "C# Person",
      field: "CSharpPersonDesc",
      minWidth: 150,
      maxWidth: 200,
      tooltipField: "CSharpPersonDesc",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "DB Person",
      field: "DBPersonDesc",
      minWidth: 150,
      maxWidth: 200,
      tooltipField: "DBPersonDesc",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Angular JS Person",
      field: "JSPersonDesc",
      minWidth: 150,
      maxWidth: 200,
      tooltipField: "JSPersonDesc",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Task Status",
      field: "TaskLatestStatusName",
      minWidth: 80,
      maxWidth: 100,
      tooltipField: "TaskLatestStatusName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      // cellStyle: this.changeRowColor(event)
      // cellStyle: function (params) {
      //   if (params.node.data.TaskLatestStatusName === 'Yet to Start') {
      //     return { color: 'red', backgroundColor: 'green' };
      //   } else {
      //     return null;
      //   }
      // }
      // all odd rows assigned 'my-shaded-effect'
    },

   
    // {
    //   headerName: 'Task From', field: 'TaskFrom', width: 105, tooltipField: 'TaskFrom',
    //   cellClass: 'no-border cell-wrap-text', autoHeight: true,
    // },
    // {
    //   headerName: 'Task Current Working Status', field: 'TaskCurrentWorkingStatus', width: 200, tooltipField: 'TaskCurrentWorkingStatus',
    //   cellClass: 'no-border cell-wrap-text', autoHeight: true, hide: true
    // },



    // {
    //   headerName: 'ADDL.RESPONSIBLE PERSON ', field: 'ParticipantsDesc', width: 250, tooltipField: 'ParticipantsDesc',
    //   cellClass: 'no-border cell-wrap-text', autoHeight: true
    // },

    // {
    //   headerName: 'Expected Delivery Date',  field: 'ExpectedDate', minWidth: 160, maxWidth: 200, tooltipField: 'ExpectedDate',
    //   cellClass: 'no-border cell-wrap-text', autoHeight: true,
    // },
    {
      headerName: "Created Date",
      field: "IssueCreatedOn",
      minWidth: 145,
      maxWidth: 145,
      tooltipField: "IssueCreatedOn",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellRenderer: this.dateTimeFormatCellRenderer,
      hide: false,
      cellStyle: { textAlign: "center" },
    },

    {
      headerName: "Created By",
      field: "CreatedBy",
      minWidth: 150,
      maxWidth: 180,
      tooltipField: "CreatedBy",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "ECD",
      field: "ExpectedDate",
      hide: false,
      minWidth: 100,
      maxWidth: 150,
      tooltipField: "ExpectedDate",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Task Type",
      field: "TaskType",
      minWidth: 80,
      maxWidth: 100,
      tooltipField: "TaskType",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },

    {
      headerName: "Priority",
      field: "SeverityDesc",
      minWidth: 80,
      maxWidth: 85,
      tooltipField: "SeverityDesc",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Latest Responsed From",
      field: "LatestResponsedFrom",
      hide: false,
      minWidth: 180,
      maxWidth: 250,
      tooltipField: "Latest Responsed From",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    // {
    //   headerName: 'Forwarded to', field: 'AssistantsDesc', minWidth: 130, maxWidth: 130, tooltipField: 'AssistantsDesc',
    //   cellClass: 'no-border cell-wrap-text', autoHeight: true, hide: false
    // },
    {
      headerName: "RBD",
      field: "RequiredByDate",
      hide: true,
      minWidth: 110,
      maxWidth: 150,
      tooltipField: "RequiredByDate",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },


    {
      headerName: "Task Updated Date",
      headerTooltip: "Task Updated Date",
      field: "TaskCompletedDate",
      hide: true,
      minWidth: 110,
      maxWidth: 150,
      tooltipField: "TaskCompletedDate",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },

    // {
    //   headerName: 'Task Received From', field: 'TaskCamefromTypeDescription', width: 105, tooltipField: 'TaskCamefromTypeDescription',
    //   cellClass: 'no-border cell-wrap-text', autoHeight: true, hide: true
    // },
    // {
    //   headerName: 'Modified Date', field: 'TasksModifiedDate', minWidth: 100, maxWidth: 160, tooltipField: 'TasksModifiedDate',
    //   cellClass: 'no-border cell-wrap-text', autoHeight: true, hide: true
    // },
    // {
    //   headerName: 'ModifiedBy', field: 'ModifiedByName', minWidth: 100, maxWidth: 160, tooltipField: 'ModifiedByName',
    //   cellClass: 'no-border cell-wrap-text', autoHeight: true, hide: true
    // },
    // {
    //   headerName: 'Modified Description', field: 'TasksModifiedDescription', minWidth: 100, maxWidth: 160, tooltipField: 'TasksModifiedDescription',
    //   cellClass: 'no-border cell-wrap-text', autoHeight: true, hide: true
    // },
    // {
    //   headerName: 'IsPublicTask', field: 'IsPublicTask', width: 0, tooltipField: 'IsPublicTask',
    //   cellClass: 'no-border cell-wrap-text', autoHeight: true, hide: true
    // },
  ];

  onViewTaskInfoGridSizeChanged(params: GridSizeChangedEvent) {
    // params.api.sizeColumnsToFit();
  }
  // tslint:disable-next-line:member-ordering
  rowData = [];

  // changeRowColor(params) {
  //   if (params.node.data[4] === 100) {
  //     return { 'background-color': 'yellow' };
  //   }
  // }

  // deleted tasks grid
  DeletedListcolumnDefs = [
    // { headerName: 'Task ID', field: 'TaskInfoID', width: 200 },
    {
      headerName: "#",
      field: "TaskNumber",
      width: 210,
      tooltipField: "Task Number",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Subject",
      field: "IssueSubject",
      width: 1100,
      tooltipField: "Issue Subject",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Deleted On",
      field: "ModifiedDateID",
      width: 120,
      tooltipField: "ModifiedDateID",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Task Type",
      field: "TaskType",
      minWidth: 130,
      maxWidth: 130,
      tooltipField: "TaskType",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
  ];
  onViewTaskInfoDeletedListGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  // tslint:disable-next-line:member-ordering
  DeletedListrowData = [];

  // tslint:disable-next-line:member-ordering
  public defaultSearchStatus: { statusName: string; statusId: number } = {
    statusName: "Search Status",
    statusId: null,
  };
  // tslint:disable-next-line:member-ordering
  public dataChangeStatus = [
    { statusName: "Pending", statusId: 1 },
    { statusName: "Overdue", statusId: 2 },
    // { statusName: 'Completed', statusId: 3 },
  ];

  rowSelection = "single";

  // this.getTaskTypes().subscribe(() => {
  //   this.viewModel.TaskType = this.taskTypeList.find(task => +task.TaskTypeId === data.TaskTypeId);
  // });

  private GetTaskTypes() {
    this.taskService.taskTypeList().subscribe((response) => {
      if (this.isError(response)) {
        return;
      }
      this.taskTypeList = response;
    });
  }

  ngOnInit() {
    this.firsttime = true;
    this.tasksModifedFromDate = new Date(Date.now() - 24 * 60 * 60 * 1000);
    this.tasksModifedToDate = new Date(Date.now());

    this.route.params.subscribe((params) => {
      const data = this.getCommonData(params.info);
      this.HideIconFromallInternalTickets = this.NeedtoShowAllTasks =
        data.NeedtoShowAllTasks;
      if (!this.HideIconFromallInternalTickets) {
        this.viewTaskInfoResponsiblePerson.EmployeeId = [];
        this.viewTaskInfoResponsiblePerson.EmployeeId.push(
          Config.OASOrganizationModel.LoggedUserID
        );
        this.viewTaskInfoResponsiblePerson.EmployeeFullName = `${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}`;
      }
      this.commonhelperobj
        .PostData(Config.OASAppnURL + "TaskStatusList", {})
        .subscribe((response) => {
          if (this.isError(response)) {
            return;
          }

          this.viewTaskInfoSelectStatusDropDown = [];

          response.forEach((element) => {
            if (
              element.TaskStatusId == 1 ||
              element.TaskStatusId == 2 ||
              element.TaskStatusId == 3 ||
              element.TaskStatusId == 5 ||
              element.TaskStatusId == 8
            ) {
              this.viewTaskInfoSelectStatusDropDown.push({
                TaskDescription: element.TaskDescription,
                TaskStatusId: element.TaskStatusId,
              });
            }
          });
        });
      if (!this.hasValue(this.SearchStatusIDs)) {
        this.FromRecords = 1;
      }

      this.viewTaskGrid.getRowClass = function (params) {
        if (params.node.data.TaskLatestStatusName === "Yet to Start") {
          // return { background: 'red' };
          // return { color: 'red', backgroundColor: 'green' };
          return "my-shaded-effect";
        } else {
          return null;
        }
      };
      setTimeout(function () {
        document.getElementById("txtSearchTask").focus();
      }, 20);
      //console.log(this.AllTasksCount);
      for (let i = 0; i < this.columnDefs.length; i++) {
        switch (this.columnDefs[i].field) {
          case "IssueSubject":
            if ($(window).width() > 1700) {
              this.columnDefs[2].width = 650;
            } else if ($(window).width() < 1700 && $(window).width() >= 1670) {
              this.columnDefs[2].width = 400;
            } else if ($(window).width() < 1670 && $(window).width() >= 1610) {
              this.columnDefs[2].width = 430;
            } else if ($(window).width() < 1610 && $(window).width() >= 1480) {
              this.columnDefs[2].width = 300;
            } else if ($(window).width() < 1480 && $(window).width() >= 1400) {
              this.columnDefs[2].width = 170;
            } else if ($(window).width() < 1400 && $(window).width() >= 1300) {
              this.columnDefs[2].width = 200;
            } else if ($(window).width() < 1300 && $(window).width() >= 1200) {
              this.columnDefs[2].width = 170;
            } else if ($(window).width() < 1200 && $(window).width() >= 1100) {
              this.columnDefs[2].width = 200;
            } else if ($(window).width() < 1100 && $(window).width() >= 700) {
              this.columnDefs[2].width = 170;
            } else if ($(window).width() < 700 && $(window).width() >= 300) {
              this.columnDefs[2].width = 170;
            }
            break;
          case "ResponsiblePersonDesc":
            if ($(window).width() < 1300 && $(window).width() >= 1200) {
              this.columnDefs[7].width = 150;
            }
            if ($(window).width() < 1400 && $(window).width() >= 1300) {
              this.columnDefs[7].width = 150;
            }
            if ($(window).width() < 1200 && $(window).width() >= 1000) {
              this.columnDefs[7].width = 150;
            }
            if ($(window).width() < 1100 && $(window).width() >= 700) {
              this.columnDefs[7].width = 170;
            }
            if ($(window).width() < 700 && $(window).width() >= 300) {
              this.columnDefs[7].width = 170;
            }
            break;

          case "AssistantsDesc":
            if ($(window).width() < 1300 && $(window).width() >= 1200) {
              this.columnDefs[8].width = 150;
            }
            if ($(window).width() < 1400 && $(window).width() >= 1300) {
              this.columnDefs[8].width = 150;
            }
            if ($(window).width() < 1200 && $(window).width() >= 1000) {
              this.columnDefs[8].width = 150;
            }
            if ($(window).width() < 1100 && $(window).width() >= 700) {
              this.columnDefs[8].width = 170;
            }
            if ($(window).width() < 700 && $(window).width() >= 300) {
              this.columnDefs[8].width = 170;
            }
            break;
          case "CreatedBy":
            if ($(window).width() < 1300 && $(window).width() >= 1200) {
              this.columnDefs[6].width = 150;
            }
            if ($(window).width() < 1100 && $(window).width() >= 700) {
              this.columnDefs[6].width = 170;
            }
            if ($(window).width() < 700 && $(window).width() >= 300) {
              this.columnDefs[6].width = 150;
            }
            break;
        }
      }
      if (this.items != undefined && this.items.length > 0) {
        for (let i = 0; i < this.items.length; i++) {
          this.items[i].myclass = "main-buttons-item-link";
        }
      }

      if (
        this.hasValue(data) &&
        this.hasValue(data.previouslySelectedSearchFilters)
      ) {
        this.SearchTaskNumber =
          data.previouslySelectedSearchFilters.SearchTaskNumber;
        this.SearchTaskSubject =
          data.previouslySelectedSearchFilters["SearchTaskSubject"];
        this.SearchCurrentWorkingStatus =
          data.previouslySelectedSearchFilters.SearchCurrentWorkingStatus;
        this.SearchStatusIDs =
          data.previouslySelectedSearchFilters.SearchStatusIDs;
        this.practiceInformation =
          data.previouslySelectedSearchFilters.practiceInformation;
        this.viewTaskInfoResponsiblePerson =
          data.previouslySelectedSearchFilters.viewTaskInfoResponsiblePerson;
        this.viewTaskInfoForwardedtome =
          data.previouslySelectedSearchFilters.viewTaskInfoForwardedtome;
        (this.viewTaskInfoCreatedBy =
          data.previouslySelectedSearchFilters.viewTaskInfoCreatedBy),
          (this.viewTaskInfoSelectedTaskType =
            data.previouslySelectedSearchFilters.viewTaskInfoSelectedTaskType);
        this.FromRecords =
          data.previouslySelectedSearchFilters.StatemaintainFromRecords;
        this.selectedTask = data.previouslySelectedTaskNumber;
        this.CreatedFromDate =
          data.previouslySelectedSearchFilters.CreatedFromDate;
        this.CreatedToDate = data.previouslySelectedSearchFilters.CreatedToDate;
        this.CreatedToTime = data.previouslySelectedSearchFilters.CreatedToTime;
        this.CreatedFromTime =
          data.previouslySelectedSearchFilters.CreatedFromTime;
        this.ECDDate = data.previouslySelectedSearchFilters.ECDDate;
      }

      if (
        this.hasValue(data) &&
        this.hasValue(data.PreviouslySelectedTabItem)
      ) {
        // alert(data.PreviouslySelectedTabItem);
        this.IsFromBackButtonFromViewLoad = true;
        this.PreviouslySelectedItem = data.PreviouslySelectedTabItem;
        if (
          data.previouslySelectedSearchFilters
            .viewTaskInfoShowCountClickShowHideTabs == true
        )
          this.viewTaskInfoShowCountClickShowHideTabs = true;
        this.TasksCountInfo();
        this.items.forEach((element) => {
          if (element.tasktype == data.PreviouslySelectedTabItem.tasktype) {
            element.myclass = "MainButtonsHoverClass main-buttons-item-link";
          }
        });

        setTimeout(() => {
          //this.GetSelectedItem = data.PreviouslySelectedTabItem;

          this.mainButtonsDivClick(data.PreviouslySelectedTabItem);
          // this.items[4].myclass = 'MainButtonsHoverClass main-buttons-item-link';
          //this.employeeTaskListFromStatusFilter();
        }, 400);
        //this.StateMaintainPreviouslySelectedTab(this.GetSelectedItem);
      } else {
        // applying css to the first item by default
        this.items[0].myclass = "MainButtonsHoverClass main-buttons-item-link";

        this.IsFromBackButtonFromViewLoad = false;

        this.GetSelectedItem = { tasktype: "All" };
        this.SearchUserRole.UserRoleID = 0;
        this.employeeTaskListFromStatusFilter(undefined, undefined, false);
      }

      // this.employeeTaskListFromStatusFilter(undefined,undefined);
      this.practiceList();
      this.GetCurrentStatusList();
      this.employeeList();
      this.GetTaskTypes();
    });
    // setTimeout(() => {
    //   this.statemaintainPreviousSelectedRecord();
    // }, 100);
  }

  getRowStyle(params) {
    // console.log(params, "params");
    if (params.data.ReadorUnread === 1) {
      return { "font-weight": "900" };
    } else {
      return { "font-weight": "500" };
    }
  }

  OnClickOpenandClose(isTrueorFalse) {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage("Please Select Task Information");
      document.getElementById("txtSearchTask").focus();
      return;
    }
    // console.log(this.selectedRowsString,"")
    let selectedTask = this.selectedRowsString;
    if (selectedTask.ReadorUnread !== isTrueorFalse)
      this.OnclickMarkasReadorUnReadFN(
        selectedTask.TaskInfoID,
        isTrueorFalse,
        true
      );
  }

  OnclickMarkasReadorUnReadFN(selectedID, isTrueorFalse, isRefresh) {
    let payload = {
      TaskInfoID: selectedID,
      TicketReadorUnread: isTrueorFalse,
    };
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "UpdatedTicketReadandUnReadStatus", payload)
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
        if (isRefresh === true) {
          this.employeeTaskListFromStatusFilter(undefined, undefined, false);
        }
      });
  }

  employeeList() {
    const PostDataToService = {
      // EmployeeFullName: undefined,
    };
    return this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "OrganizationEmployeesList",
        PostDataToService
      )
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
        this.ResponsiblePersonsListInfo = response.EmployeeList;
        this.ForwardedtomeListInfo = response.EmployeeList;
      });
  }
  // ForwardedtomeList(){
  //   const PostDataToService = {

  //   };
  //   return this.commonhelperobj.PostData(Config.OASOrganizationAppnURL + 'OrganizationEmployeesList', PostDataToService)
  //     .subscribe(response => {
  //       if (this.isError(response)) {
  //         return;
  //       }
  //       this.ForwardedtomeListInfo = response.EmployeeList;

  //     });
  // }

  StateMaintainPreviouslySelectedTab(item) {
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].myclass = "main-buttons-item-link";
    }
    //this.GetSelectedItem = item;
    if (item.tasktype === "All") {
      item.myclass = "MainButtonsHoverClass main-buttons-item-link";
    } else if (item.tasktype === "Yet to Start") {
      // //hide context menu
      // if (this.HideConextMenuBasedOnTabSelection == true) {
      //   this.HideConextMenuBasedOnTabSelection = false;
      //   this.SearchUserRole.UserRoleID = -1;
      // }
      item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      // // yet to start
      // this.isFromSetByMe = false;
      // this.HideCommonGrid = false;
      // this.HideDeletedGrid = true;
      // this.HideIconsFromDeleted = false;
      // this.IconInDeleted = true;
      // this.selectedRowsString = undefined;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      // this.selectStatusDropDownHide = true;
      // document.getElementById("txtSearchTask").focus();
      // this.employeeTaskListFromStatusFilter();
    } else if (item.tasktype === "On Going") {
      // //hide context menu
      // if (this.HideConextMenuBasedOnTabSelection == true) {
      //   this.HideConextMenuBasedOnTabSelection = false;
      //   this.SearchUserRole.UserRoleID = -1;
      // }
      item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      // // on going
      // this.isFromSetByMe = false;
      // this.HideCommonGrid = false;
      // this.HideDeletedGrid = true;
      // this.HideIconsFromDeleted = false;
      // this.IconInDeleted = true;
      // this.selectedRowsString = undefined;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      // this.selectStatusDropDownHide = true;
      // document.getElementById("txtSearchTask").focus();
      // this.employeeTaskListFromStatusFilter();
    } else if (item.tasktype === "Review Pending") {
      // //hide context menu
      // if (this.HideConextMenuBasedOnTabSelection == true) {
      //   this.HideConextMenuBasedOnTabSelection = false;
      //   this.SearchUserRole.UserRoleID = -1;
      // }
      item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      // // on going
      // this.isFromSetByMe = false;
      // this.HideCommonGrid = false;
      // this.HideDeletedGrid = true;
      // this.HideIconsFromDeleted = false;
      // this.IconInDeleted = true;
      // this.selectedRowsString = undefined;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      // this.selectStatusDropDownHide = true;
      // document.getElementById("txtSearchTask").focus();
      // this.employeeTaskListFromStatusFilter();
    } else if (item.tasktype === "Completed") {
      // //hide context menu
      // if (this.HideConextMenuBasedOnTabSelection == true) {
      //   this.HideConextMenuBasedOnTabSelection = false;
      //   this.SearchUserRole.UserRoleID = -1;
      // }
      item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      // // completed
      // this.isFromSetByMe = false;
      // this.HideCommonGrid = false;
      // this.HideDeletedGrid = true;
      // this.HideIconsFromDeleted = false;
      // this.IconInDeleted = true;
      // this.selectedRowsString = undefined;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      // this.selectStatusDropDownHide = true;
      // document.getElementById("txtSearchTask").focus();
      // this.employeeTaskListFromStatusFilter();
    } else if (item.tasktype === "Assisting") {
      // //hide context menu
      // this.HideConextMenuBasedOnTabSelection = true;
      item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      // this.isFromSetByMe = false;
      // this.HideCommonGrid = false;
      // this.HideDeletedGrid = true;
      // this.HideIconsFromDeleted = false;
      // this.IconInDeleted = true;
      // this.selectedRowsString = undefined;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      // this.selectStatusDropDownHide = false;
      // document.getElementById("txtSearchTask").focus();
      // this.employeeTaskListFromStatusFilter();
    } else if (item.tasktype === "Set by me") {
      // //hide context menu
      // this.HideConextMenuBasedOnTabSelection = true;
      // // set by me
      // this.isFromSetByMe = true;
      // this.HideCommonGrid = false;
      // this.HideDeletedGrid = true;
      // this.selectedRowsString = undefined;
      item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      // this.HideIconsFromDeleted = false;
      // this.IconInDeleted = true;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      // this.selectStatusDropDownHide = false;
      // document.getElementById("txtSearchTask").focus();
      // this.employeeTaskListFromStatusFilter();
    } else if (item.tasktype === "Recycle Bin") {
      // //hide context menu
      // this.HideConextMenuBasedOnTabSelection = true;
      // this.HideIconsFromDeleted = true;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      // this.selectedRowsString = undefined;
      // this.GetDeletedTasksList();
      // this.deleteselectedRowsString = undefined;
      // this.isFromSetByMe = false;
      // this.HideCommonGrid = true;
      // this.HideDeletedGrid = false;
      // this.IconInDeleted = false;
      // this.selectStatusDropDownHide = false;
      // document.getElementById("txtSearchTask1").focus();
      item.myclass = "MainButtonsHoverClass main-buttons-item-link";
    } else if (item.tasktype === "Forwarded to me") {
      // //hide context menu
      // this.HideConextMenuBasedOnTabSelection = true;
      // // set by me
      // this.isFromSetByMe = true;
      // this.HideCommonGrid = false;
      // this.HideDeletedGrid = true;
      // this.selectedRowsString = undefined;
      item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      // this.HideIconsFromDeleted = false;
      // this.IconInDeleted = true;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      // this.selectStatusDropDownHide = false;
      // document.getElementById("txtSearchTask").focus();
      // this.SearchUserRole.UserRoleID = 7;
      // this.employeeTaskListFromStatusFilter();
    }
  }

  mainButtonsDivClick(item) {
    //this.countincrement = false;
    if (this.hasValue(this.PreviouslySelectedItem)) {
      if (item.tasktype != this.PreviouslySelectedItem.tasktype) {
        this.IsFromBackButtonFromViewLoad = false;
      }
    }

    if (this.IsFromBackButtonFromViewLoad == false) {
      this.viewTaskInfoShowHidePopups();
      this.FromRecords = 1;
      this.viewTaskInfoResponsiblePerson = {};
      this.TotalCountValueIcon = false;
      this.TotalCountValue = true;
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].myclass = "main-buttons-item-link";
      }
    }
    this.GetSelectedItem = item;
    // this.getLoggedUserResponsibleForwardtoTasksList(item);
    if (item.tasktype === "All") {
      //hide context menu
      this.FiltersHideShowinYetToStart = false;
      if (this.HideConextMenuBasedOnTabSelection == true) {
        this.HideConextMenuBasedOnTabSelection = false;
        this.SearchUserRole.UserRoleID = -1;
      }
      if (this.IsFromBackButtonFromViewLoad == false) {
        item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      }
      this.isfromrecyclebin = false;
      this.isFromSetByMe = false;
      this.HideCommonGrid = false;
      this.HideDeletedGrid = true;
      this.HideIconsFromDeleted = false;
      this.IconInDeleted = true;
      this.selectedRowsString = undefined;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      this.selectStatusDropDownHide = false;
      // if (this.hasValue(this.VieworEditinfo.previouslySelectedPageNumber)) {
      //   this.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount
      // } else {
      //   this.EmployeeTaskListRecordCount = 1;
      // }
      document.getElementById("txtSearchTask").focus();
      // this.taskType=true;
      this.employeeTaskListFromStatusFilter(undefined, undefined, false);
    } else if (item.tasktype === "Yet to Start") {
      //hide context menu
      this.FiltersHideShowinYetToStart = true;
      this.viewTaskInfoResponsiblePerson.EmployeeId = [];
      this.viewTaskInfoResponsiblePerson.EmployeeId.push(
        Config.OASOrganizationModel.LoggedUserID
      );
      if (this.HideConextMenuBasedOnTabSelection == true) {
        this.HideConextMenuBasedOnTabSelection = false;
        this.SearchUserRole.UserRoleID = -1;
      }
      if (this.IsFromBackButtonFromViewLoad == false) {
        item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      }
      // yet to start
      this.isfromrecyclebin = false;
      this.isFromSetByMe = false;
      this.HideCommonGrid = false;
      this.HideDeletedGrid = true;
      this.HideIconsFromDeleted = false;
      this.IconInDeleted = true;
      this.selectedRowsString = undefined;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      this.selectStatusDropDownHide = true;
      document.getElementById("txtSearchTask").focus();
      // if (this.hasValue(this.VieworEditinfo.previouslySelectedPageNumber)) {
      //   this.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount
      // } else {
      //   this.EmployeeTaskListRecordCount = 1;
      // }
      this.employeeTaskListFromStatusFilter(undefined, undefined, false);
    } else if (item.tasktype === "On Going") {
      this.FiltersHideShowinYetToStart = true;
      this.viewTaskInfoResponsiblePerson.EmployeeId = [];
      this.viewTaskInfoResponsiblePerson.EmployeeId.push(
        Config.OASOrganizationModel.LoggedUserID
      );
      //hide context menu
      if (this.HideConextMenuBasedOnTabSelection == true) {
        this.HideConextMenuBasedOnTabSelection = false;
        this.SearchUserRole.UserRoleID = -1;
      }
      if (this.IsFromBackButtonFromViewLoad == false) {
        item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      }
      // on going
      this.isfromrecyclebin = false;
      this.isFromSetByMe = false;
      this.HideCommonGrid = false;
      this.HideDeletedGrid = true;
      this.HideIconsFromDeleted = false;
      this.IconInDeleted = true;
      this.selectedRowsString = undefined;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      this.selectStatusDropDownHide = true;
      document.getElementById("txtSearchTask").focus();
      // if (this.hasValue(this.VieworEditinfo.previouslySelectedPageNumber)) {
      //   this.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount
      // } else {
      //   this.EmployeeTaskListRecordCount = 1;
      // }
      this.employeeTaskListFromStatusFilter(undefined, undefined, false);
    } else if (item.tasktype === "Review Pending") {
      this.FiltersHideShowinYetToStart = true;
      this.viewTaskInfoResponsiblePerson.EmployeeId = [];
      this.viewTaskInfoResponsiblePerson.EmployeeId.push(
        Config.OASOrganizationModel.LoggedUserID
      );
      //hide context menu
      if (this.HideConextMenuBasedOnTabSelection == true) {
        this.HideConextMenuBasedOnTabSelection = false;
        this.SearchUserRole.UserRoleID = -1;
      }
      if (this.IsFromBackButtonFromViewLoad == false) {
        item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      }
      // on going
      this.isfromrecyclebin = false;
      this.isFromSetByMe = false;
      this.HideCommonGrid = false;
      this.HideDeletedGrid = true;
      this.HideIconsFromDeleted = false;
      this.IconInDeleted = true;
      this.selectedRowsString = undefined;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      this.selectStatusDropDownHide = true;
      document.getElementById("txtSearchTask").focus();
      // if (this.hasValue(this.VieworEditinfo.previouslySelectedPageNumber)) {
      //   this.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount
      // } else {
      //   this.EmployeeTaskListRecordCount = 1;
      // }
      this.employeeTaskListFromStatusFilter(undefined, undefined, false);
    } else if (item.tasktype === "Completed") {
      this.FiltersHideShowinYetToStart = false;
      //hide context menu
      if (this.HideConextMenuBasedOnTabSelection == true) {
        this.HideConextMenuBasedOnTabSelection = false;
        this.SearchUserRole.UserRoleID = -1;
      }
      if (this.IsFromBackButtonFromViewLoad == false) {
        item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      }
      // completed
      this.isfromrecyclebin = false;
      this.isFromSetByMe = false;
      this.HideCommonGrid = false;
      this.HideDeletedGrid = true;
      this.HideIconsFromDeleted = false;
      this.IconInDeleted = true;
      this.selectedRowsString = undefined;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      this.selectStatusDropDownHide = true;
      document.getElementById("txtSearchTask").focus();
      // if (this.hasValue(this.VieworEditinfo.previouslySelectedPageNumber)) {
      //   this.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount
      // } else {
      //   this.EmployeeTaskListRecordCount = 1;
      // }
      this.employeeTaskListFromStatusFilter(undefined, undefined, false);
      // } else if (item.tasktype === 'Assisting') {
      //   //hide context menu
      //   this.HideConextMenuBasedOnTabSelection = true;
      //   if (this.IsFromBackButtonFromViewLoad == false) {
      //     item.myclass = 'MainButtonsHoverClass main-buttons-item-link';
      //   }
      //   this.isfromrecyclebin = false;
      //   this.isFromSetByMe = false;
      //   this.HideCommonGrid = false;
      //   this.HideDeletedGrid = true;
      //   this.HideIconsFromDeleted = false;
      //   this.IconInDeleted = true;
      //   this.selectedRowsString = undefined;
      //   // this.SearchTaskNumber = '';
      //   // this.SearchTaskSubject = '';
      //   this.selectStatusDropDownHide = false;
      //   document.getElementById("txtSearchTask").focus();
      //   // if (this.hasValue(this.VieworEditinfo.previouslySelectedPageNumber)) {
      //   //   this.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount
      //   // } else {
      //   //   this.EmployeeTaskListRecordCount = 1;
      //   // }
      //   this.employeeTaskListFromStatusFilter(undefined,undefined,false);
    } else if (item.tasktype === "Set by me") {
      this.FiltersHideShowinYetToStart = false;
      document.getElementById(
        "viewTaskInfoSelectCreatedPersonId"
      ).style.display = "none";
      this.showHideForwardedResponsiblePersonPopups = false;
      //hide context menu
      this.HideConextMenuBasedOnTabSelection = true;
      // set by me
      this.isfromrecyclebin = false;
      this.isFromSetByMe = true;
      this.HideCommonGrid = false;
      this.HideDeletedGrid = true;
      this.selectedRowsString = undefined;
      if (this.IsFromBackButtonFromViewLoad == false) {
        item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      }
      this.HideIconsFromDeleted = false;
      this.IconInDeleted = true;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      this.selectStatusDropDownHide = false;
      document.getElementById("txtSearchTask").focus();
      // this.viewTaskInfoCreatedBy.EmployeeId = Config.OASOrganizationModel.LoggedUserID;
      // this.viewTaskInfoCreatedBy.EmployeeFullName=`${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}`
      // if (this.hasValue(this.VieworEditinfo.previouslySelectedPageNumber)) {
      //   this.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount
      // } else {
      //   this.EmployeeTaskListRecordCount = 1;
      // }
      this.employeeTaskListFromStatusFilter(undefined, undefined, false);
    } else if (item.tasktype === "Recycle Bin") {
      this.FiltersHideShowinYetToStart = false;
      if (this.IsFromBackButtonFromViewLoad == false) {
        this.SearchDeletedTaskNumber = "";
        this.SearchDeletedTaskSubject = "";
      }
      this.HideConextMenuBasedOnTabSelection = true;
      this.HideIconsFromDeleted = true;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      this.selectedRowsString = undefined;
      // if (this.hasValue(this.VieworEditinfo.previouslySelectedPageNumber)) {
      //   this.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount
      // } else {
      //   this.EmployeeTaskListRecordCount = 1;
      // }
      this.isfromrecyclebin = true;
      this.GetDeletedTasksList(undefined, undefined);
      this.deleteselectedRowsString = undefined;
      this.isFromSetByMe = false;
      this.HideCommonGrid = true;
      this.HideDeletedGrid = false;
      this.IconInDeleted = false;
      this.selectStatusDropDownHide = false;
      document.getElementById("txtSearchTask1").focus();
      item.myclass = "MainButtonsHoverClass main-buttons-item-link";
    } else if (item.tasktype === "Forwarded to me") {
      this.FiltersHideShowinYetToStart = false;
      document.getElementById("viewTaskInfoSelectForwardedtoId").style.display =
        "none";
      this.showHideForwardedResponsiblePersonPopups = false;
      //hide context menu
      this.HideConextMenuBasedOnTabSelection = true;
      // set by me
      this.isFromSetByMe = true;
      this.HideCommonGrid = false;
      this.HideDeletedGrid = true;
      this.selectedRowsString = undefined;
      if (this.IsFromBackButtonFromViewLoad == false) {
        item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      }
      this.HideIconsFromDeleted = false;
      this.IconInDeleted = true;
      // this.SearchTaskNumber = '';
      // this.SearchTaskSubject = '';
      this.selectStatusDropDownHide = false;
      document.getElementById("txtSearchTask").focus();
      //this.SearchUserRole.UserRoleID = 7;
      this.forwardedtoUserId = Config.OASOrganizationModel.LoggedUserID;
      // this.viewTaskInfoForwardedtome.EmployeeId = Config.OASOrganizationModel.LoggedUserID;
      // this.viewTaskInfoForwardedtome.EmployeeFullName=`${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}`
      // if (this.hasValue(this.VieworEditinfo.previouslySelectedPageNumber)) {
      //   this.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount
      // } else {
      //   this.EmployeeTaskListRecordCount = 1;
      // }
      this.employeeTaskListFromStatusFilter(undefined, undefined, false);
    } else if (item.taskMenutypeid === 10) {
      this.FiltersHideShowinYetToStart = false;
      if (this.HideConextMenuBasedOnTabSelection == true) {
        this.HideConextMenuBasedOnTabSelection = false;
        this.SearchUserRole.UserRoleID = -1;
      }
      if (this.IsFromBackButtonFromViewLoad == false) {
        item.myclass = "MainButtonsHoverClass main-buttons-item-link";
      }
      this.isfromrecyclebin = false;
      this.isFromSetByMe = false;
      this.HideCommonGrid = false;
      this.HideDeletedGrid = true;
      this.HideIconsFromDeleted = false;
      this.selectedRowsString = undefined;
      this.IconInDeleted = true;
      this.selectStatusDropDownHide = false;
      document.getElementById("txtSearchTask").focus();
      this.employeeTaskListFromStatusFilter(undefined, undefined, false);
    }
  }

  onSelectionChanged(event) {
    const selectedRows = this.viewTaskGrid.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      if (index !== 0) {
        this.selectedRowsString = undefined;
      }
      this.selectedRowsString = selectedRow;
    });
    if (
      Config.OASOrgModel.LoggedUserID ===
        this.selectedRowsString.TaskCreatedByUserId &&
      !this.NeedtoShowAllTasks
    ) {
      this.ShowThisOnlyForCreatedPerson = false;
    } else {
      this.ShowThisOnlyForCreatedPerson = true;
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.http
    //   .get(
    //     'https://raw.githubusercontent.com/ag-grid/ag-grid/master/packages/ag-grid-docs/src/olympicWinnersSmall.json'
    //   )
    //   .subscribe(data => {
    //     this.rowData = data;
    //   });
  }

  ViewClickEvent() {
    // this._router.navigateByUrl('ViewFullTaskInformationComponent');

    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage("Please Select Task Information to View");
      document.getElementById("txtSearchTask").focus();
      return;
    }

    // const outletObj = {
    //   data: this.selectedRowsString
    // };
    // outletObj['main'] = ['app-view-full-task-information', this.setCommonData({})];
    // this._router.navigate(['/home', { outlets: outletObj }]);

    const PostDataService: any = {
      IsFromEdit: true,
      isform: 11,
      editmodedata: this.selectedRowsString,
      NeedtoShowAllTasks: this.NeedtoShowAllTasks,
      selectedTicketsList: this.rowData,
    };
    if (this.isFromSetByMe === true) {
      PostDataService.IsFromSetByMe = true;
    }
    PostDataService.SelectedTabItem = this.GetSelectedItem;
    // PostDataService.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount;
    PostDataService.StatemaintainPreviouslySearchFilters = {
      SearchTaskNumber: this.SearchTaskNumber,
      SearchTaskSubject: this.SearchTaskSubject,
      SearchCurrentWorkingStatus: this.SearchCurrentWorkingStatus,
      SearchStatusIDs: this.SearchStatusIDs,
      practiceInformation: this.practiceInformation,
      viewTaskInfoResponsiblePerson: this.viewTaskInfoResponsiblePerson,
      viewTaskInfoForwardedtome: this.viewTaskInfoForwardedtome,
      viewTaskInfoCreatedBy: this.viewTaskInfoCreatedBy,
      viewTaskInfoSelectedTaskType: this.viewTaskInfoSelectedTaskType,
      StatemaintainFromRecords: this.FromRecords,
      viewTaskInfoShowCountClickShowHideTabs:
        this.viewTaskInfoShowCountClickShowHideTabs,
      CreatedFromDate: this.CreatedFromDate,
      CreatedToDate: this.CreatedToDate,
      CreatedToTime: this.CreatedFromTime,
      CreatedFromTime: this.CreatedToTime,
      ECDDate: this.ECDDate,
    };
    const outletObj = {};
    outletObj["main"] = [
      "app-view-full-task-information",
      this.setCommonData(PostDataService),
    ];
    this._router.navigate(["/home", { outlets: outletObj }]);
    this.selectedRowsString = undefined;
  }

  // ViewClickEvent() {
  //   // this._router.navigateByUrl('ViewFullTaskInformationComponent');

  //   if (!this.hasValue(this.selectedRowsString)) {
  //     this.ShowErrorMessage('Please Select Task Information To View');
  //     return;
  //   }

  //   const outletObj = {};
  //   // outletObj['main'] = ['app-view-full-task-information', this.setCommonData(PostDataService)];
  //   // this._router.navigate(['/home', { outlets: outletObj }]);
  //   const clickedItem: any = {};
  //   clickedItem.routename = 'main';
  //   clickedItem.routepath = '/home';

  //   clickedItem.SelectedTaskInfo = this.selectedRowsString;
  //   // if (clickedItem.MenuInfoID === 58) {
  //   //   clickedItem.IsFromEmployeeOrShiftsInfo = true;
  //   // } else {
  //   //   clickedItem.IsFromEmployeeOrShiftsInfo = false;
  //   // }

  //   outletObj[clickedItem.routename] = ['app-view-full-task-information', this.setCommonData(clickedItem)];

  //   this._router.navigate([clickedItem.routepath, { outlets: outletObj }]);

  //   // this._router.navigate(['/home', { outlets: { 'main': 'app-view-full-task-information' }, }]);
  // }

  // GetTasksList() {
  //   const PostDataToService = {};
  //   // tslint:disable-next-line:max-line-length
  //   this.commonhelperobj.PostData(Config.OASOrganizationAppnURL +
  // 'OrganizationEmployeesList', PostDataToService).subscribe(serviceResponse => {
  //     if (this.isError(serviceResponse)) { return; }
  //   });
  // }

  public practiceList() {
    let postData;

    postData = {
      PracticeId: undefined,
      PracticeName: undefined,
    };

    this.taskService.PracticesList(postData).subscribe((response) => {
      if (this.isError(response)) {
        return;
      }
      this.viewTaskInfoSelectPracticeDropDown = response;
    });
  }

  // public employeeTaskList(data = {}) {

  //   let PostData: any = {};
  //   PostData = data;
  //   if (this.hasValue(this.taskSearchStatusDetails)) {
  //     PostData.TaskLatestStatusList = this.taskSearchStatusDetails.statusId;
  //   }

  //   if (this.hasValue(this.SearchTaskNumber)) {
  //     PostData.TaskNumber = this.SearchTaskNumber;
  //   }

  //   if (this.hasValue(this.SearchTaskSubject)) {
  //     PostData.IssueSubject = this.SearchTaskSubject;
  //   }
  //   //PostData.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount;

  //   this.taskService.taskList(data).subscribe(response => {
  //     if (this.commonhelperobj.isError(response)) {
  //       return;
  //     }

  //     response.OASIssueReportFromCustomerModelList.forEach(TaskListelement => {

  //       if (this.hasValue(TaskListelement.TaskUsersInfo)) {
  //         const SplittedUserData = TaskListelement.TaskUsersInfo.split(',');
  //         if (this.hasValue(SplittedUserData) && SplittedUserData.length > 0) {
  //           //TaskListelement.ParticipantsDesc = "";
  //           TaskListelement.AssistantsDesc = "";
  //           // tslint:disable-next-line:no-shadowed-variable
  //           SplittedUserData.forEach(element => {
  //             const getUserRolesData = element.split('|');
  //             if (this.hasValue(getUserRolesData) && getUserRolesData.length > 0) {
  //               if (getUserRolesData[2] === '2') {
  //                 TaskListelement.ResponsiblePersonDesc = getUserRolesData[1];
  //               }
  //               if (getUserRolesData[2] === '7') {
  //                 TaskListelement.AssistantsDesc += getUserRolesData[1] + ', ';
  //               }
  //             }
  //           });
  //           //TaskListelement.ParticipantsDesc = TaskListelement.ParticipantsDesc.substring(0, TaskListelement.ParticipantsDesc.length - 2);
  //           TaskListelement.AssistantsDesc = TaskListelement.AssistantsDesc.substring(0, TaskListelement.AssistantsDesc.length - 2);
  //         }

  //       }
  //     });
  //    // if (response.OASIssueReportFromCustomerModelList.length <= 50) {
  //       this.rowData = response.OASIssueReportFromCustomerModelList;
  //      // this.ListCount = response.OASIssueReportFromCustomerModelList.length;
  //     //}
  //     // if (response.OASIssueReportFromCustomerModelList.length <= 49) {
  //     //   this.NextButtonDisabled = true;
  //     //   this.ListCount = response.OASIssueReportFromCustomerModelList.length
  //     //   this.Next = this.EmployeeTaskListRecordCount + response.OASIssueReportFromCustomerModelList.length - 1;
  //     // }
  //     // else {
  //     //   this.NextButtonDisabled = false;
  //     //   this.Next = this.EmployeeTaskListRecordCount + 49
  //     // }
  //     this.gridOptions.api.sizeColumnsToFit();
  //     if (this.hasValue(response.OASIssueReportFromCustomerModelList) && response.OASIssueReportFromCustomerModelList.length > 0) {
  //       this.BindAllTasksCount = response.OASIssueReportFromCustomerModelList.length;
  //     }
  //   });

  // Testing purposes
  // this.rowData = [
  //   {
  //     IssueSubject: 'To Do List',
  //     TaskInfoID: '123456',
  //     IssueCreatedOn: '12/11/2017',
  //     CreatedBy: 'anusha.chiruvolu@adaptamed.org',
  //     SeverityDesc: 'High',
  //     TaskLatestStatusName: 'Pending'
  //   },
  //   {
  //     IssueSubject: 'OAS Changes', TaskInfoID: '789456', IssueCreatedOn: '11/12/2018',
  //     CreatedBy: 'lakshmi.bathina@adaptamed.org', SeverityDesc: 'Low',
  //     TaskLatestStatusName: 'Pending'
  //   },
  // ];

  // }
  EditButtonClickEvent(event) {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage("Please Select  to Edit");
      document.getElementById("txtSearchTask").focus();
      return;
    }
    //this.selectedRowsString.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount;
    // object contains data that is to be sent to another route
    const dataToRoute = {
      data: this.selectedRowsString,
    };

    if (!this.isEligibleToEdit()) {
      this.ShowErrorMessage(
        "You Are Not Allowed to Edit Task(s) Created by Others"
      );
      return;
    }

    if (this.isTaskCompleted(this.selectedRowsString)) {
      this.ShowErrorMessage("Cannot Edit Completed or Review Pending Tasks");
      return;
    }

    const outletObj = {};
    outletObj["main"] = [
      "app-edit-task-information",
      this.setCommonData(dataToRoute),
    ];
    this._router.navigate(["/home", { outlets: outletObj }]);
    this.selectedRowsString = undefined;
  }

  /**
   * Check if the current logged in user is allowed to edit the current task
   * @author Sanjay Idpuganti
   */
  isEligibleToEdit(): boolean {
    //#region VALIDATIONS
    if (
      !this.selectedRowsString ||
      !this.selectedRowsString.TaskCreatedByUserId
    ) {
      // handle
      return false;
    }

    if (!this.selectedRowsString.TaskCreatedByUserId) {
      // handle
      return false;
    }
    //#endregion

    // check the current logged in user id with selected task's user id
    return (
      Config.OASOrgModel.LoggedUserID ===
      this.selectedRowsString.TaskCreatedByUserId
    );
  }

  isTaskCompleted(selectedData: any): boolean {
    if (selectedData && selectedData.TaskLatestStatusName) {
      return ["Review Pending", "Completed"].some(
        (taskStatus) => taskStatus === selectedData.TaskLatestStatusName
      );
    }
  }
  ExportToExcelClick(exoportoexcel) {
    this.employeeTaskListFromStatusFilter(undefined, undefined, exoportoexcel);
  }
  viewTaskInfoPrevNextClick(prevornext) {
    if (this.hasValue(prevornext) && this.isfromrecyclebin == false) {
      this.employeeTaskListFromStatusFilter(undefined, prevornext, false);
    } else {
      this.GetDeletedTasksList(undefined, prevornext);
    }
  }
  employeeTaskListFromStatusFilter(isfromsearch, prevornext, exoportoexcel) {
    const data: any = {};
    if (exoportoexcel == false) {
      this.RecordsPerPage = 50;
      if (
        isfromsearch == true &&
        (prevornext == false || prevornext == undefined) &&
        this.TotalCountValue == false
      ) {
        this.TotalCountValueIcon = false;
        this.TotalCountValue = true;
      }
      if (
        !this.hasValue(this.FromRecords) ||
        (this.hasValue(isfromsearch) && isfromsearch == true)
      ) {
        this.FromRecords = 1;
      }
      // if(!this.hasValue(prevornext))
      // prevornext="";
      if (this.TotalCountValue == false && this.IsFromDelete == true) {
        prevornext = 3;
      }
      switch (prevornext) {
        case 1:
          //if(this.hasValue(this.PreviousButtondisabled) && this.PreviousButtondisabled==true) return;
          this.FromRecords -= this.RecordsPerPage;
          break;

        case 2:
          //if(this.hasValue(this.NextButtonDisabled) && this.NextButtonDisabled==true) return;
          this.FromRecords += this.RecordsPerPage;
          break;
        case 3:
          this.fromrecordsfromcount = this.FromRecords;
          this.FromRecords = null;
      }
    }
    // this.practiceidslist= this.practiceInformation.PracticeID.toString();//.SearchPracticeIDs.map(item => item.PracticeId).join(',');
    if (this.hasValue(this.practiceInformation.PracticeId))
      data.PracticeIDsList = this.practiceInformation.PracticeId.toString(); //selected multiple practice IDs
    // const PracticeIDsListtoSearch = this.SearchPracticeIDs; //selected multiple practice IDs
    // if (this.hasValue(PracticeIDsListtoSearch) && PracticeIDsListtoSearch.length > 0) {
    //   if (PracticeIDsListtoSearch.length > 5) {
    //     this.ShowErrorMessage('Please Select Only 5 Practices to Search.');
    //     return;
    //   }
    // }
    let responsiblePersonUserId: any = [];
    if (this.applyclassesforTasksModifiedStatusSelected(data) == false) return;
    if (this.applyclassesforTasksCreatedDatesSelected(data) == false) return;

    if (
      this.hasValue(this.viewTaskInfoResponsiblePerson) ||
      this.responsiblePersonUserId > 0
    )
      // console.log(this.viewTaskInfoResponsiblePerson.EmployeeId);
    responsiblePersonUserId.push(this.responsiblePersonUserId);
    data.ResponsibleUserIDs =
      this.responsiblePersonUserId > 0
        ? responsiblePersonUserId
        : this.viewTaskInfoResponsiblePerson.EmployeeId;
    if (
      this.hasValue(this.viewTaskInfoForwardedtome) ||
      this.forwardedtoUserId > 0
    )
      data.ForwardedToMeUserID =
        this.forwardedtoUserId > 0
          ? this.forwardedtoUserId
          : this.viewTaskInfoForwardedtome.EmployeeId;
    if (this.hasValue(this.viewTaskInfoCreatedBy))
      data.CreatedByUserIDs = this.viewTaskInfoCreatedBy.EmployeeId;
    if (this.hasValue(this.SearchCurrentWorkingStatus))
      data.TaskCurrentWorkingStatusInfoIDs = [this.SearchCurrentWorkingStatus];
    else data.TaskCurrentWorkingStatusInfoIDs = [];
    if (this.hasValue(this.ECDDate))
      data.ExpectedDate = this.formatIntValue(this.ECDDate);

    // if(this.hasValue(this.viewTaskInfoResponsiblePerson))
    // data.EmployeeId = this.viewTaskInfoResponsiblePerson;
    if (
      this.hasValue(this.SearchUserRole.UserRoleID) &&
      this.SearchUserRole.UserRoleID > 0
    )
      data.UserRole = this.SearchUserRole.UserRoleID;
    if (this.hasValue(this.viewTaskInfoSelectedTaskType))
      data.TaskTypeId = parseInt(this.viewTaskInfoSelectedTaskType);
    if (this.hasValue(this.FromRecords)) data.FromRecords = this.FromRecords;
    if (this.GetSelectedItem.tasktype === "All") {
      this.ShowThisOnlyForCreatedPerson = true;
      //Showing the Search Status Drop Down Box
      this.selectStatusDropDownHide = false;
      this.IconInDeleted = true;
      let PostData: any = {};
      PostData = data;
      this.TaskLatestStatusList = this.SearchStatusIDs.map(
        (item) => item.TaskStatusId
      ).join(","); //selected multiple status IDs
      if (this.hasValue(this.TaskLatestStatusList))
        data.TaskLatestStatusList = this.TaskLatestStatusList;
      //this.FilterData = data.TaskLatestStatusList
      //data.AssistOrSetbyMe = 0;
      if (this.hasValue(this.SearchTaskNumber)) {
        data.TaskNumber = this.SearchTaskNumber;
      }
      if (this.hasValue(this.SearchTaskSubject)) {
        data.IssueSubject = this.SearchTaskSubject;
      }

      if(this.hasValue(this.viewTaskQAPerson)){
        data.QAPerson=this.viewTaskQAPerson.EmployeeId
      }
      
      if(this.hasValue(this.viewTaskCSharpPerson)){
        data.CSharpPerson=this.viewTaskCSharpPerson.EmployeeId
      }
      
      if(this.hasValue(this.viewTaskDBPerson)){
        data.DBPerson=this.viewTaskDBPerson.EmployeeId
      }
      
      if(this.hasValue(this.viewTaskJSPerson)){
        data.JSPerson=this.viewTaskJSPerson.EmployeeId
      }
      
      // if (this.hasValue(this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId)) {
      //   data.TaskStatusId = this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId;
      // }
      this.rowClassRules = {
        // 'sick-days-warning': function (params) {
        //   // tslint:disable-next-line:prefer-const
        //   const statuspending = params.data.TaskLatestStatusName;
        //   return statuspending === 'Yet to Start';
        // },
        "sick-days-breach": function (params) {
          // tslint:disable-next-line:prefer-const
          const statuspending = params.data.TaskLatestStatusName;
          return statuspending === "On Going";
        },
        "sick-days-completed": function (params) {
          // tslint:disable-next-line:prefer-const
          const statuspending = params.data.TaskLatestStatusName;
          return statuspending === "Completed";
        },
        "sick-days-overdue": function (params) {
          // tslint:disable-next-line:prefer-const
          const statuspending = params.data.IsOverdueTask;
          return statuspending === true;
        },
      };
      this.taskcount = [];
    } else if (this.GetSelectedItem.tasktype === "Yet to Start") {
      this.ShowThisOnlyForCreatedPerson = true;
      // yet to start
      let PostData: any = {};
      PostData = data;
      data.TaskLatestStatusList = "1";
      //data.AssistOrSetbyMe = 0;
      if (this.hasValue(this.SearchTaskNumber)) {
        data.TaskNumber = this.SearchTaskNumber;
      }
      if (this.hasValue(this.SearchTaskSubject)) {
        data.IssueSubject = this.SearchTaskSubject;
      }
      // if (this.hasValue(this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId)) {
      //   data.TaskStatusId = this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId;
      // }
      this.rowClassRules = {
        "sick-days-warning": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-breach": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-completed": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-overdue": function (params) {
          // tslint:disable-next-line:prefer-const
          const statuspending = params.data.IsOverdueTask;
          return statuspending === true;
        },
      };
      this.taskcount = [];
    } else if (this.GetSelectedItem.tasktype === "On Going") {
      this.ShowThisOnlyForCreatedPerson = true;
      // on going
      let PostData: any = {};
      PostData = data;
      data.TaskLatestStatusList = "2";
      // data.AssistOrSetbyMe = 0;
      if (this.hasValue(this.SearchTaskNumber)) {
        data.TaskNumber = this.SearchTaskNumber;
      }
      if (this.hasValue(this.SearchTaskSubject)) {
        data.IssueSubject = this.SearchTaskSubject;
      }
      // if (this.hasValue(this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId)) {
      //   data.TaskStatusId = this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId;
      // }
      this.rowClassRules = {
        "sick-days-warning": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-breach": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-completed": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-overdue": function (params) {
          // tslint:disable-next-line:prefer-const
          const statuspending = params.data.IsOverdueTask;
          return statuspending === true;
        },
      };
      this.taskcount = [];
    } else if (this.GetSelectedItem.tasktype === "Completed") {
      this.ShowThisOnlyForCreatedPerson = true;
      // completed
      let PostData: any = {};
      PostData = data;
      data.TaskLatestStatusList = "3";
      //data.AssistOrSetbyMe = 0;
      if (this.hasValue(this.SearchTaskNumber)) {
        data.TaskNumber = this.SearchTaskNumber;
      }
      if (this.hasValue(this.SearchTaskSubject)) {
        data.IssueSubject = this.SearchTaskSubject;
      }
      // if (this.hasValue(this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId)) {
      //   data.TaskStatusId = this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId;
      // }
      this.rowClassRules = {
        "sick-days-warning": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-breach": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-completed": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-overdue": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
      };
      this.taskcount = [];
    } else if (this.GetSelectedItem.tasktype === "Review Pending") {
      this.ShowThisOnlyForCreatedPerson = true;

      let PostData: any = {};
      PostData = data;
      data.TaskLatestStatusList = "5";
      // data.AssistOrSetbyMe = 0;
      if (this.hasValue(this.SearchTaskNumber)) {
        data.TaskNumber = this.SearchTaskNumber;
      }
      if (this.hasValue(this.SearchTaskSubject)) {
        data.IssueSubject = this.SearchTaskSubject;
      }
      // if (this.hasValue(this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId)) {
      //   data.TaskStatusId = this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId;
      // }
      this.rowClassRules = {
        "sick-days-warning": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-breach": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-completed": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-overdue": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
      };
      this.taskcount = [];
    } else if (this.GetSelectedItem.tasktype === "Assisting") {
      // assisting
      this.ShowThisOnlyForCreatedPerson = true;
      let PostData: any = {};
      PostData = data;
      this.TaskLatestStatusList = this.SearchStatusIDs.map(
        (item) => item.TaskStatusId
      ).join(","); //selected multiple status IDs
      if (this.hasValue(this.TaskLatestStatusList))
        data.TaskLatestStatusList = this.TaskLatestStatusList;
      data.AssistOrSetbyMe = 1;
      if (this.hasValue(this.SearchTaskNumber)) {
        data.TaskNumber = this.SearchTaskNumber;
      }
      if (this.hasValue(this.SearchTaskSubject)) {
        data.IssueSubject = this.SearchTaskSubject;
      }
      // if (this.hasValue(this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId)) {
      //   data.TaskStatusId = this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId;
      // }
      this.rowClassRules = {
        "sick-days-warning": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-breach": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-completed": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-overdue": function (params) {
          // tslint:disable-next-line:prefer-const
          const statuspending = params.data.IsOverdueTask;
          return statuspending === true;
        },
      };
      this.taskcount = [];
    } else if (this.GetSelectedItem.tasktype === "Set by me") {
      this.ShowThisOnlyForCreatedPerson = false;
      // set by me
      let PostData: any = {};
      PostData = data;
      this.TaskLatestStatusList = this.SearchStatusIDs.map(
        (item) => item.TaskStatusId
      ).join(","); //selected multiple status IDs
      if (this.hasValue(this.TaskLatestStatusList))
        data.TaskLatestStatusList = this.TaskLatestStatusList;
      data.UserRole = 1;
      if (this.hasValue(this.SearchTaskNumber)) {
        data.TaskNumber = this.SearchTaskNumber;
      }
      if (this.hasValue(this.SearchTaskSubject)) {
        data.IssueSubject = this.SearchTaskSubject;
      }
      // if (this.hasValue(this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId)) {
      //   data.TaskStatusId = this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId;
      // }
      this.rowClassRules = {
        "sick-days-warning": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-breach": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-completed": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-overdue": function (params) {
          // tslint:disable-next-line:prefer-const
          const statuspending = params.data.IsOverdueTask;
          return statuspending === true;
          // return null;
        },
      };
      this.taskcount = [];
    } else if (this.GetSelectedItem.tasktype === "Forwarded to me") {
      this.ShowThisOnlyForCreatedPerson = true;
      // set by me
      let PostData: any = {};
      PostData = data;
      this.TaskLatestStatusList = this.SearchStatusIDs.map(
        (item) => item.TaskStatusId
      ).join(","); //selected multiple status IDs
      if (this.hasValue(this.TaskLatestStatusList))
        data.TaskLatestStatusList = this.TaskLatestStatusList;
      //data.AssistOrSetbyMe = 0;
      if (this.hasValue(this.SearchTaskNumber)) {
        data.TaskNumber = this.SearchTaskNumber;
      }
      if (this.hasValue(this.SearchTaskSubject)) {
        data.IssueSubject = this.SearchTaskSubject;
      }
      // if (this.hasValue(this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId)) {
      //   data.TaskStatusId = this.viewTaskInfoSelectStatusDropDownInfo.TaskStatusId;
      // }
      this.rowClassRules = {
        "sick-days-warning": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-breach": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-completed": function (params) {
          // tslint:disable-next-line:prefer-const
          return null;
        },
        "sick-days-overdue": function (params) {
          // tslint:disable-next-line:prefer-const
          const statuspending = params.data.IsOverdueTask;
          return statuspending === true;
          // return null;
        },
      };
      this.taskcount = [];
    }
    if (this.GetSelectedItem.taskMenutypeid === 10) {
      this.ShowThisOnlyForCreatedPerson = true;
      this.selectStatusDropDownHide = false;
      this.IconInDeleted = true;
      let PostData: any = {};
      PostData = data;
      this.TaskLatestStatusList = this.SearchStatusIDs.map(
        (item) => item.TaskStatusId
      ).join(","); //selected multiple status IDs
      if (this.hasValue(this.TaskLatestStatusList))
        data.TaskLatestStatusList = this.TaskLatestStatusList;
      if (this.hasValue(this.SearchTaskNumber)) {
        data.TaskNumber = this.SearchTaskNumber;
      }
      if (this.hasValue(this.SearchTaskSubject)) {
        data.IssueSubject = this.SearchTaskSubject;
      }

      this.rowClassRules = {
        // 'sick-days-warning': function (params) {
        //   // tslint:disable-next-line:prefer-const
        //   const statuspending = params.data.TaskLatestStatusName;
        //   return statuspending === 'Yet to Start';
        // },
        "sick-days-breach": function (params) {
          // tslint:disable-next-line:prefer-const
          const statuspending = params.data.TaskLatestStatusName;
          return statuspending === "On Going";
        },
        "sick-days-completed": function (params) {
          // tslint:disable-next-line:prefer-const
          const statuspending = params.data.TaskLatestStatusName;
          return statuspending === "Completed";
        },
        "sick-days-overdue": function (params) {
          // tslint:disable-next-line:prefer-const
          const statuspending = params.data.IsOverdueTask;
          return statuspending === true;
        },
      };
      this.taskcount = [];
    }
    //hiding the Recycle Bin Grid
    this.HideDeletedGrid = true;
    //Showning the Common Grid
    this.HideCommonGrid = false;
    data.IsTaskCreatedEHR = false;
    data.NeedtoShowAllTasks = this.NeedtoShowAllTasks ? 1 : 0;
    // this.FilterData=data;
    if (exoportoexcel == true) {
      this.commonhelperobj
        .PostData(
          Config.OASOrganizationAppnURL + "EmployeeTaskListExport",
          data
        )
        .subscribe((serviceResponse) => {
          if (this.isError(serviceResponse)) {
            return;
          }
          if (
            this.hasValue(serviceResponse) &&
            this.hasValue(serviceResponse.EmployeeTaskListModelList) &&
            serviceResponse.EmployeeTaskListModelList.length > 0
          ) {
            this._excelService.exportAsExcelFile(
              this._viewTaskInformationService.ExcelColumnsInfo,
              serviceResponse.EmployeeTaskListModelList,
              this.NeedtoShowAllTasks
                ? "AllInternalTicketsReport"
                : "InternalTicketsReport"
            );
            // window.open(serviceResponse.AttachmentsURL);
          }
        });
    } else {
      this.taskService.taskList(data).subscribe((response) => {
        if (this.commonhelperobj.isError(response)) {
          return;
        }
        if (
          this.hasValue(response) &&
          this.hasValue(response.Count) &&
          response.Count > 0
        ) {
          this.TotalCountValueIcon = true;
          this.TotalCountValue = false;
          this.TotalLabelCount = response.Count;
          this.FromRecords = this.fromrecordsfromcount;
        } else {
          response.EmployeeTaskListModelList.forEach((TaskListelement) => {
            if (this.hasValue(TaskListelement.TaskUsersInfo)) {
              const SplittedUserData = TaskListelement.TaskUsersInfo.split(",");
              if (
                this.hasValue(SplittedUserData) &&
                SplittedUserData.length > 0
              ) {
                //TaskListelement.ParticipantsDesc = "";
                TaskListelement.AssistantsDesc = "";
                // tslint:disable-next-line:no-shadowed-variable
                SplittedUserData.forEach((element) => {
                  const getUserRolesData = element.split("|");
                  if (
                    this.hasValue(getUserRolesData) &&
                    getUserRolesData.length > 0
                  ) {
                    if (getUserRolesData[2] === "2") {
                      TaskListelement.ResponsiblePersonDesc =
                        getUserRolesData[1];
                    }
                    if (getUserRolesData[2] === "8") {
                      TaskListelement.QAPersonDesc =
                        getUserRolesData[1];
                    }
                    if (getUserRolesData[2] === "9") {
                      TaskListelement.CSharpPersonDesc =
                        getUserRolesData[1];
                    }
                    if (getUserRolesData[2] === "10") {
                      TaskListelement.DBPersonDesc =
                        getUserRolesData[1];
                    }
                    if (getUserRolesData[2] === "11") {
                      TaskListelement.JSPersonDesc =
                        getUserRolesData[1];
                    }
                    if (getUserRolesData[2] === "7") {
                      // if (!this.hasValue(TaskListelement.ParticipantsDesc)) {
                      //   TaskListelement.ParticipantsDesc = "";
                      // }
                      // TaskListelement.ParticipantsDesc += getUserRolesData[1] + ', ';
                      TaskListelement.AssistantsDesc +=
                        getUserRolesData[1] + ", ";
                    }
                  }
                });
                // TaskListelement.ParticipantsDesc = TaskListelement.ParticipantsDesc.substring(0, TaskListelement.ParticipantsDesc.length - 2);
                TaskListelement.AssistantsDesc =
                  TaskListelement.AssistantsDesc.substring(
                    0,
                    TaskListelement.AssistantsDesc.length - 2
                  );
              }
            }
          });
          this.rowData = [];
          this.taskcount = [];
          this.viewTaskGrid.columnApi.setColumnsVisible(
            ["TasksModifiedDate", "TasksModifiedDescription", "ModifiedByName"],
            this.GetSelectedItem.taskMenutypeid === 10
          );
          this.viewTaskGrid.columnApi.setColumnsVisible(
            ["RequiredByDate", "TaskCompletedDate"],
            this.GetSelectedItem.taskMenutypeid === 5
          );
          //if (response.OASIssueReportFromCustomerModelList.length <= 50) {
          this.rowData = response.EmployeeTaskListModelList;
          this.PrevLabelCount = this.FromRecords;
          if (this.FromRecords == 1) {
            this.NextLabelCount = response.EmployeeTaskListModelList.length;
          } else {
            this.NextLabelCount =
              this.FromRecords + response.EmployeeTaskListModelList.length - 1;
          }
          if (
            this.FromRecords == 1 &&
            response.EmployeeTaskListModelList.length < this.RecordsPerPage
          ) {
            this.prevnextbuttons = true;
            this.TotalLabelCountValue =
              response.EmployeeTaskListModelList.length;
          } else if (
            this.FromRecords == 1 &&
            response.EmployeeTaskListModelList.length == this.RecordsPerPage
          ) {
            this.prevnextbuttons = false;
            this.PreviousButtondisabled = true;
            this.NextButtonDisabled = false;
          } else if (
            response.EmployeeTaskListModelList.length < this.RecordsPerPage
          ) {
            this.prevnextbuttons = false;
            this.NextButtonDisabled = true;
            this.PreviousButtondisabled = false;
          } else {
            this.prevnextbuttons = false;
            this.PreviousButtondisabled = false;
            this.NextButtonDisabled = false;
          }
          this.statemaintainPreviousSelectedRecord();
          this.gridOptions.api.sizeColumnsToFit();
          // if (this.hasValue(this.rowData) && this.rowData.length > 0) {
          //   // tslint:disable-next-line:no-shadowed-variable
          //   // this.rowData.forEach(data => {
          //   //   if (this.hasValue(data.TaskLatestStatusName) && data.TaskLatestStatusName === 'Yet to Start') {
          //   //     this.viewTaskGrid.rowStyle = { background: 'black' };
          //   //   }
          //   // });
          // }
        }
      });

      if (this.IsFromDelete == true && this.TotalCountValue == false) {
        this.FromRecords = this.StatemaintainFromRecords;
        this.IsFromDelete = false;
        this.employeeTaskListFromStatusFilter(undefined, undefined, false);
      }
    }
    setTimeout(function () {
      document.getElementById("txtSearchTask").focus();
    }, 50);
    this.selectedRowsString = undefined;
  }

  // delete task click event
  DeleteClickEvent() {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage("Please Select a Task to Delete");
      document.getElementById("txtSearchTask").focus();
      return;
    }

    if (
      this.selectedRowsString.TaskLatestStatusName === "Completed" ||
      this.selectedRowsString.TaskLatestStatusName === "Review Pending"
    ) {
      this.ShowErrorMessage("Cannot Delete Completed or Review Pending Tasks");
      return;
    }

    const postData = {
      TaskInfoID: this.selectedRowsString.TaskInfoID,
    };
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "DeleteTaskInformation", postData)
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.selectedRowsString = undefined;
        this.ShowSuccesseMessage("Task Deleted Succesfully.");
        // this.employeeTaskList();
        this.IsFromDelete = true;
        this.StatemaintainFromRecords = this.FromRecords;
        this.employeeTaskListFromStatusFilter(undefined, undefined, false);

        //this.initializeTaskCategoriesCount();
      });
  }
  private applyclassesforTasksModifiedStatusSelected(data: any) {
    if (this.GetSelectedItem.taskMenutypeid === 10) {
      this.showhideTaskModifiedFromandToDateFilters = false;

      data.isfromTasksModified = true;
      data.tasksModifedToDate = this.formatValue(this.tasksModifedToDate);
      data.tasksModifedFromDate = this.formatValue(this.tasksModifedFromDate);
      if (!this.hasValue(this.tasksModifedFromDate)) {
        this.ShowErrorMessage("Please Enter From Date");
        const elementFound =
          this.ViewTaskInformationID.nativeElement.querySelector(
            '[name="' + "viewModifedTasksInfoFromDate" + '"]'
          );
        if (this.hasValue(elementFound)) {
          elementFound.children[0].children[0].children[0].children[0].focus();
        }
        return false;
      }
      if (!this.hasValue(this.tasksModifedToDate)) {
        this.ShowErrorMessage("Please Enter To Date");
        const elementFound =
          this.ViewTaskInformationID.nativeElement.querySelector(
            '[name="' + "viewModifedTasksInfoToDate" + '"]'
          );
        if (this.hasValue(elementFound)) {
          elementFound.children[0].children[0].children[0].children[0].focus();
        }
        return false;
      }
      if (this.tasksModifiedFromDateChange() == false) return false;
      if (this.tasksModifiedToDateChange() == false) return false;
    } else {
      this.showhideTaskModifiedFromandToDateFilters = true;
    }
  }

  tasksModifiedFromDateChange() {
    if (
      !this.modifiedFromDateToDateValidations(
        this.tasksModifedFromDate,
        "viewModifedTasksInfoFromDate",
        true
      )
    )
      return false;
  }
  tasksModifiedToDateChange() {
    if (
      !this.modifiedFromDateToDateValidations(
        this.tasksModifedToDate,
        "viewModifedTasksInfoToDate",
        false
      )
    )
      return false;
  }

  modifiedFromDateToDateValidations(
    modifiedfromandtoDate: Date,
    name: string,
    isfrom: boolean
  ) {
    var today = new Date();
    var toDate = new Date(modifiedfromandtoDate);
    var showfocuselement = false;
    const daysDifference = this.DateDiffInDays(
      isfrom ? this.tasksModifedToDate : today,
      isfrom ? this.tasksModifedFromDate : this.tasksModifedToDate
    );
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage("Invalid Day, Month or Year Range Detected.");
      showfocuselement = true;
    }
    if (this.hasValue(daysDifference) && daysDifference < 0) {
      if (isfrom)
        this.ShowErrorMessage(
          "From Date Should be Less Than or Equal to To Date"
        );
      else
        this.ShowErrorMessage(
          "To Date Should be Less Than or Equal to Current Date"
        );
      showfocuselement = true;
    }
    if (showfocuselement) {
      const elementFound =
        this.ViewTaskInformationID.nativeElement.querySelector(
          '[name="' + name + '"]'
        );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    return true;
  }
  private initializeTaskCategoriesCount() {
    const source = forkJoin(
      this.taskService.taskStatusCount("All"),
      this.taskService.taskStatusCount("Yet to Start"),
      this.taskService.taskStatusCount("On Going"),
      this.taskService.taskStatusCount("Review Pending"),
      this.taskService.taskStatusCount("Completed"),
      this.taskService.taskStatusCount("Assisting"),
      this.taskService.taskStatusCount("Set by me"),
      this.taskService.RecycleBinTaskCount(),
      this.taskService.taskForwardToMeCount()
    );

    source.subscribe(
      (x) => {
        //console.log('Next: %s', x);
        this.AllTasksCount = x[0].TaskCount;
        this.YettoStartTasksCount = x[1].TaskCount;
        this.OnGoingStartTasksCount = x[2].TaskCount;
        this.ReviewPendingTasksCount = x[3].TaskCount;
        this.CompletedTasksCount = x[4].TaskCount;
        this.AssistingTasksCount = x[5].TaskCount;
        this.SetByMeTasksCount = x[6].TaskCount;
        this.RecycledTasksCount = x[7].TaskCount;
        this.ForwardedtoMeCount = x[8].TaskCount;
      },
      function (err) {
        //console.log('Error: %s', err);
      },
      function () {
        //console.log('Completed');
        //console.log(this.AllTasksCount);
      }
    );
  }

  GetDeletedTasksList(isfromsearch, prevornext) {
    this.RecordsPerPage = 50;

    if (
      !this.hasValue(this.FromRecords) ||
      (this.hasValue(isfromsearch) && isfromsearch == true)
    ) {
      this.FromRecords = 1;
    }
    // if(!this.hasValue(prevornext))
    // prevornext="";
    if (this.TotalCountValue == false && this.IsFromRecoverTask == true) {
      prevornext = 3;
    }
    switch (prevornext) {
      case 1:
        //if(this.hasValue(this.PreviousButtondisabled) && this.PreviousButtondisabled==true) return;
        this.FromRecords -= this.RecordsPerPage;
        break;

      case 2:
        //if(this.hasValue(this.NextButtonDisabled) && this.NextButtonDisabled==true) return;
        this.FromRecords += this.RecordsPerPage;
        break;
      case 3:
        this.fromrecordsfromcount = this.FromRecords;
        this.FromRecords = null;
    }
    const postData: any = {};
    if (this.hasValue(this.SearchDeletedTaskNumber)) {
      postData.TaskNumber = this.SearchDeletedTaskNumber;
    }

    this.deleteselectedRowsString = undefined;

    if (this.hasValue(this.SearchDeletedTaskSubject)) {
      postData.IssueSubject = this.SearchDeletedTaskSubject;
    }
    if (this.hasValue(this.FromRecords)) {
      postData.FromRecords = this.FromRecords;
    }
    // postData.EmployeeTaskListRecordCount = this.EmployeeTaskListRecordCount
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "EmployeeDeletedTaskListNew", postData)
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        //this.DeletedListrowData = [];
        this.taskcount = [];
        if (
          this.hasValue(serviceResponse) &&
          this.hasValue(serviceResponse.Count) &&
          serviceResponse.Count > 0
        ) {
          this.TotalCountValueIcon = true;
          this.TotalCountValue = false;
          this.TotalLabelCount = serviceResponse.Count;
          this.FromRecords = this.fromrecordsfromcount;
        } else {
          this.DeletedListrowData =
            serviceResponse.EmployeeDeletedTaskListModelList;
          this.PrevLabelCount = this.FromRecords;
          if (this.FromRecords == 1) {
            this.NextLabelCount =
              serviceResponse.EmployeeDeletedTaskListModelList.length;
          } else {
            this.NextLabelCount =
              this.FromRecords +
              serviceResponse.EmployeeDeletedTaskListModelList.length -
              1;
          }
          if (
            this.FromRecords == 1 &&
            serviceResponse.EmployeeDeletedTaskListModelList.length <
              this.RecordsPerPage
          ) {
            this.prevnextbuttons = true;
            this.TotalLabelCountValue =
              serviceResponse.EmployeeDeletedTaskListModelList.length;
          } else if (
            this.FromRecords == 1 &&
            serviceResponse.EmployeeDeletedTaskListModelList.length ==
              this.RecordsPerPage
          ) {
            this.prevnextbuttons = false;
            this.PreviousButtondisabled = true;
            this.NextButtonDisabled = false;
          } else if (
            serviceResponse.EmployeeDeletedTaskListModelList.length <
            this.RecordsPerPage
          ) {
            this.prevnextbuttons = false;
            this.NextButtonDisabled = true;
            this.PreviousButtondisabled = false;
          } else {
            this.prevnextbuttons = false;
            this.PreviousButtondisabled = false;
            this.NextButtonDisabled = false;
          }
          //  this.TotalLabelCount=serviceResponse.length
        }
        if (this.IsFromRecoverTask == true && this.TotalCountValue == false) {
          this.FromRecords = this.StatemaintainFromRecords;
          this.IsFromRecoverTask = false;
          this.GetDeletedTasksList(undefined, undefined);
        }
      });
    setTimeout(function () {
      document.getElementById("txtSearchTask1").focus();
    }, 50);
  }

  // GetTaskForwardToMeList() {
  //   const postData: any = {

  //   };
  //   if (this.hasValue(this.SearchDeletedTaskNumber)) {
  //     postData.TaskNumber = this.SearchDeletedTaskNumber;
  //   }

  //   this.deleteselectedRowsString = undefined;

  //   if (this.hasValue(this.SearchDeletedTaskSubject)) {
  //     postData.IssueSubject = this.SearchDeletedTaskSubject;
  //   }
  //   // tslint:disable-next-line:max-line-length
  //   this.commonhelperobj.PostData(Config.OASAppnURL + 'DeletedTasksList', postData).subscribe(serviceResponse => {
  //     if (this.isError(serviceResponse)) { return; }
  //     this.DeletedListrowData = [];
  //     this.taskcount = [];
  //     this.DeletedListrowData = serviceResponse;

  //   });
  //   setTimeout(function () {
  //     document.getElementById("txtSearchTask1").focus();
  //   }, 50);
  // }

  onSelectionDeletedListGridChanged(event) {
    const selectedRowsdeleted = this.DeletedListGrid.api.getSelectedRows();

    selectedRowsdeleted.forEach((selectedRow, index) => {
      if (index !== 0) {
        this.deleteselectedRowsString = undefined;
      }
      this.deleteselectedRowsString = selectedRow;
    });
  }

  RecoverTask() {
    if (!this.hasValue(this.deleteselectedRowsString)) {
      this.ShowErrorMessage("Please Select Task to Recover.");
      document.getElementById("txtSearchTask1").focus();
      return;
    }

    const postData = {
      TaskInfoId: this.deleteselectedRowsString.TaskInfoID,
      TaskLatestStatusID: 1,
    } as any;
    const taskChangeEvent: TaskChangeEvent = {
      Description: `${4} | ${"Deleted"}` + `--> ${1} | ${"Yet to Start"}`,
      EventId: TaskEventTypeEnum.StatusUpdated,
      TaskInfoId: this.deleteselectedRowsString.TaskInfoID,
    };
    postData.TaskChangeEvents = [taskChangeEvent];
    this.commonhelperobj
      .PostData(`${Config.OASAppnURL}/ChangeTaskStatus`, postData)
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
        this.ShowSuccesseMessage("Task is Recovered Succesfully.");
        this.IsFromRecoverTask = true;
        this.StatemaintainFromRecords = this.FromRecords;
        this.GetDeletedTasksList(undefined, undefined);
        this.deleteselectedRowsString = undefined;
        // this.initializeTaskCategoriesCount();
        // this.initialTaskStatus = this.taskStatusDetails;
      });
  }

  dropbtnClick() {
    $(".AllTasksContextMenu").show();
  }

  onRoleSelectionChanged(params) {
    //console.log(params);
    this.SearchUserRole.UserRoleID = params;
    if (this.SearchUserRole.UserRoleID == 2) {
      document.getElementById(
        "viewTaskInfoSelectResponsiblePersonId"
      ).style.display = "none";
      this.showHideForwardedResponsiblePersonPopups = false;

      this.responsiblePersonUserId = Config.OASOrganizationModel.LoggedUserID;
      // this.viewTaskInfoResponsiblePerson.EmployeeId=Config.OASOrganizationModel.LoggedUserID;
      // this.viewTaskInfoResponsiblePerson.EmployeeFullName=`${Config.OASOrganizationModel.FirstName} ${Config.OASOrganizationModel.LastName}`
      this.SearchUserRole.UserRoleID = -1;
    } else {
      this.viewTaskInfoShowHidePopups();
      this.viewTaskInfoResponsiblePerson = "";
    }
    //dropdown-content set display: none;
    this.employeeTaskListFromStatusFilter(undefined, undefined, false);
  }

  private GetCurrentStatusList() {
    var PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "NewCurrentWorkingStatusList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.NewCurrentWorkingStatusList =
          serviceResponse.newCurrentWorkingStatusListModelList;
      });
  }
  private TasksCountInfo() {
    var PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "EmployeeTasksCountInfo",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        if (serviceResponse.AllTaskCount > 0)
          this.AllTasksCount = serviceResponse.AllTaskCount;
        else {
          this.AllTasksCount = 0;
        }
        if (serviceResponse.YettoStartCount > 0)
          this.YettoStartTasksCount = serviceResponse.YettoStartCount;
        else {
          this.YettoStartTasksCount = 0;
        }
        if (serviceResponse.OnGoingCount > 0)
          this.OnGoingStartTasksCount = serviceResponse.OnGoingCount;
        else {
          this.OnGoingStartTasksCount = 0;
        }
        if (serviceResponse.ReviewPendingCount > 0)
          this.ReviewPendingTasksCount = serviceResponse.ReviewPendingCount;
        else {
          this.ReviewPendingTasksCount = 0;
        }
        if (serviceResponse.CompletedCount > 0)
          this.CompletedTasksCount = serviceResponse.CompletedCount;
        else {
          this.CompletedTasksCount = 0;
        }
        if (serviceResponse.TaskCount > 0)
          this.AssistingTasksCount = serviceResponse.TaskCount;
        else {
          this.AssistingTasksCount = 0;
        }
        if (serviceResponse.SetbymeCount > 0)
          this.SetByMeTasksCount = serviceResponse.SetbymeCount;
        else {
          this.SetByMeTasksCount = 0;
        }
        if (serviceResponse.RecycleBinCount > 0)
          this.RecycledTasksCount = serviceResponse.RecycleBinCount;
        else {
          this.RecycledTasksCount = 0;
        }

        if (serviceResponse.ForwardedtomeCount > 0)
          this.ForwardedtoMeCount = serviceResponse.ForwardedtomeCount;
        else {
          this.ForwardedtoMeCount = 0;
        }
        this.ModifiedTasksCount =
          serviceResponse.ModifiedTasksCount > 0
            ? serviceResponse.ModifiedTasksCount
            : 0;
      });
  }
  viewTaskInfoShowCountClick(showrefreshtabs) {
    if (showrefreshtabs == true) {
      this.viewTaskInfoShowCountClickShowHideTabs = true;
      this.TasksCountInfo();
    } else {
      if (this.viewTaskInfoShowCountClickShowHideTabs == false) {
        this.ShowErrorMessage("Please Select Counts");
        return;
      }
      this.TasksCountInfo();
    }
  }
  viewTaskInfoHideCountClick() {
    this.viewTaskInfoShowCountClickShowHideTabs = false;
  }
  public onViewTaskInformationContextMenuSelect({ item }): void {
    if (!this.selectedRowsString) {
      this.commonhelperobj.ShowErrorMessage("Select a Task to add Review");
      return;
    }

    if (item.text === "C# Review Details") {
      const dialogRef = this.dialogService.open({
        title: "C# Review Details",
        // Show component
        content: CSharpReviewDetailsPopupComponent,
        height: 800,
        width: 1140,
        appendTo: this.ContainerRef,
      });
      const cSharpComponent = dialogRef.content.instance;
      cSharpComponent.selectedCSharpTaskToAddReview = this.selectedRowsString;
    } else if (item.text === "Angular Review Details") {
      const angularReviewDialogRef = this.dialogService.open({
        title: "Angular Review Details",
        // Show component
        content: AngularReviewDetailsPopupComponent,
        height: 800,
        width: 1140,
        appendTo: this.ContainerRef,
      });

      const angularReviewComponent = angularReviewDialogRef.content.instance;
      angularReviewComponent.selectedAngularTaskToAddReview =
        this.selectedRowsString;
    } else if (item.text === "UI Review Details") {
      const uiReviewComponentDialogRef = this.dialogService.open({
        title: "UI Task Review Status by C# Team Lead",
        // Show component
        content: UIReviewDetailsPopupComponent,
        height: 800,
        width: 1140,
        appendTo: this.ContainerRef,
      });

      const UIReviewComponent = uiReviewComponentDialogRef.content.instance;
      UIReviewComponent.selectedUITaskToAddReview = this.selectedRowsString;
    } else if (item.text === "QA Review Details") {
      const QAReviewComponentdialogRef = this.dialogService.open({
        title: "Task Review Status by QA Team",
        // Show component
        content: QAReviewDetailsPopupComponent,
        height: 800,
        width: 1250,
        appendTo: this.ContainerRef,
      });

      const QAReviewComponent = QAReviewComponentdialogRef.content.instance;
      QAReviewComponent.selectedQATaskToAddReview = this.selectedRowsString;
    }
  }
  viewTaskInfoSelectResponsiblePersonIconClickEvent() {
    // console.log(this.viewTaskInfoShowCountClickShowHideTabs, "Using data");
    const dialogRef = this.dialogService.open({
      title: "Select Responsible Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      FromInternalTickets: true,
      IsSelectionType: "multiple",
    };
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        //   if(this.HideIconFromallInternalTickets){
        //   this.responsiblePersonInfo = result;
        //   if (this.hasValue(this.responsiblePersonInfo)) {
        //     this.viewTaskInfoResponsiblePerson.EmployeeId=[];
        //   this.viewTaskInfoResponsiblePerson.EmployeeFullName="";
        //   this.responsiblePersonInfo.forEach((ele)=>{
        //     this.viewTaskInfoResponsiblePerson.EmployeeFullName= this.viewTaskInfoResponsiblePerson.EmployeeFullName+","+ele.EmployeeFullName;
        //     this.viewTaskInfoResponsiblePerson.EmployeeId.push(ele.EmployeeId)
        //   })        }
        //   console.log(this.responsiblePersonInfo,"if")
        // }else{
        this.viewTaskInfoResponsiblePerson.EmployeeId = [];
        this.viewTaskInfoResponsiblePerson.EmployeeFullName = "";
        this.responsiblePersonInfo = result;
        this.responsiblePersonInfo.forEach((ele) => {
          this.viewTaskInfoResponsiblePerson.EmployeeFullName =
            this.viewTaskInfoResponsiblePerson.EmployeeFullName +
            "," +
            ele.EmployeeFullName;
          this.viewTaskInfoResponsiblePerson.EmployeeId.push(ele.EmployeeId);
        });

      }
      // }
    });
  }
  viewTaskInfoSelectForwardedtoIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select ForwaredTo Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.responsiblePersonInfo = result;
        if (this.hasValue(this.responsiblePersonInfo)) {
          this.viewTaskInfoForwardedtome.EmployeeId =
            this.responsiblePersonInfo.EmployeeId;
          this.viewTaskInfoForwardedtome.EmployeeFullName =
            this.responsiblePersonInfo.EmployeeFullName;
        }
      }
    });
  }

//#region  New
viewTaskInfoSelectQAClickEvent() {
  const dialogRef = this.dialogService.open({
    title: "Select QA Person",
    // Show component
    content: ResponsiblepersonUsersListComponent,
    height: 698,
    width: 550,
    appendTo: this.ContainerRef,
  });
  dialogRef.result.subscribe((result:any) => {
    if (result instanceof DialogCloseResult) {
    } else {
      // console.log(result,"QA")
      if (this.hasValue(result)) {
        this.viewTaskQAPerson.EmployeeId =
        result.EmployeeId;
        this.viewTaskQAPerson.EmployeeFullName =
        result.EmployeeFullName;
      }
    }
  });
}

viewTaskInfoRemoveSelectedQAIconClickEvent() {
  this.viewTaskQAPerson = {};
}


viewTaskInfoSelectCSharpClickEvent() {
  const dialogRef = this.dialogService.open({
    title: "Select CSharp Person",
    // Show component
    content: ResponsiblepersonUsersListComponent,
    height: 698,
    width: 550,
    appendTo: this.ContainerRef,
  });
  dialogRef.result.subscribe((result:any) => {
    if (result instanceof DialogCloseResult) {
    } else {
      // console.log(result,"CSharp")
      if (this.hasValue(result)) {
        this.viewTaskCSharpPerson.EmployeeId =
        result.EmployeeId;
        this.viewTaskCSharpPerson.EmployeeFullName =
        result.EmployeeFullName;
      }
    }
  });
}

viewTaskInfoRemoveSelectedCSharpIconClickEvent() {
  this.viewTaskCSharpPerson = {};
}

viewTaskInfoSelectDBClickEvent() {
  const dialogRef = this.dialogService.open({
    title: "Select DB Person",
    // Show component
    content: ResponsiblepersonUsersListComponent,
    height: 698,
    width: 550,
    appendTo: this.ContainerRef,
  });
  dialogRef.result.subscribe((result:any) => {
    if (result instanceof DialogCloseResult) {
    } else {
      // console.log(result,"DB")
      if (this.hasValue(result)) {
        this.viewTaskDBPerson.EmployeeId =
        result.EmployeeId;
        this.viewTaskDBPerson.EmployeeFullName =
        result.EmployeeFullName;
      }
    }
  });
}

viewTaskInfoRemoveSelectedDBIconClickEvent() {
  this.viewTaskDBPerson = {};
}

viewTaskInfoSelectJSClickEvent() {
  const dialogRef = this.dialogService.open({
    title: "Select Angular JS Person",
    // Show component
    content: ResponsiblepersonUsersListComponent,
    height: 698,
    width: 550,
    appendTo: this.ContainerRef,
  });
  dialogRef.result.subscribe((result:any) => {
    if (result instanceof DialogCloseResult) {
    } else {
      // console.log(result,"JS")
      if (this.hasValue(result)) {
        this.viewTaskJSPerson.EmployeeId =
        result.EmployeeId;
        this.viewTaskJSPerson.EmployeeFullName =
        result.EmployeeFullName;
      }
    }
  });
}

viewTaskInfoRemoveSelectedJSIconClickEvent() {
  this.viewTaskJSPerson = {};
}

//#endregion

  viewTaskInfoSelectCreatedbyIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select CreatedBy Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      FromInternalTickets: true,
      IsSelectionType: "multiple",
    };
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        //   if(this.HideIconFromallInternalTickets){
        //   this.responsiblePersonInfo = result;
        //   this.viewTaskInfoCreatedBy.EmployeeId=[];
        //   if (this.hasValue(this.responsiblePersonInfo)) {
        //     this.viewTaskInfoCreatedBy.EmployeeId.push(this.responsiblePersonInfo.EmployeeId);
        //     this.viewTaskInfoCreatedBy.EmployeeFullName = this.responsiblePersonInfo.EmployeeFullName;
        //   }
        // }else{
        this.viewTaskInfoCreatedBy.EmployeeId = [];
        this.viewTaskInfoCreatedBy.EmployeeFullName = "";
        this.responsiblePersonInfo = result;
        this.responsiblePersonInfo.forEach((ele) => {
          this.viewTaskInfoCreatedBy.EmployeeFullName =
            this.viewTaskInfoCreatedBy.EmployeeFullName +
            "," +
            ele.EmployeeFullName;
          this.viewTaskInfoCreatedBy.EmployeeId.push(ele.EmployeeId);
        });
        // console.log(
        //   this.responsiblePersonInfo,
        //   "elase",
        //   this.viewTaskInfoResponsiblePerson
        // );
        // }
      }
    });
  }

  viewTaskInfoSelectPracticeIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Practice",
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.practiceInformation = result;
        // if (this.hasValue(this.practiceInformation)) {
        //     this.addEditAppointmentsModel.SelectedPracticeName = this.practiceInformation.PracticeName;
        //     this.addEditAppointmentsModel.PracticeId = this.practiceInformation.PracticeId;
        // }
      }
    });
  }
  viewTaskInfoRemoveSelectedPracticeClickEvent() {
    this.practiceInformation = {};
  }

  viewTaskInfoRemoveSelectedResponsiblePersonIconClickEvent() {
    this.viewTaskInfoResponsiblePerson = {};
  }

  viewTaskInfoRemoveSelectedForwardedtoIconClickEvent() {
    this.viewTaskInfoForwardedtome = {};
  }

  viewTaskInfoRemoveSelectedCreatedbyIconClickEvent() {
    this.viewTaskInfoCreatedBy = {};
  }
  private viewTaskInfoShowHidePopups() {
    this.showHideForwardedResponsiblePersonPopups = true;
    this.forwardedtoUserId = 0;
    this.responsiblePersonUserId = 0;
    document.getElementById(
      "viewTaskInfoSelectResponsiblePersonId"
    ).style.display = "block";
    // document.getElementById("viewTaskInfoSelectForwardedtoId").style.display = "block";
    document.getElementById("viewTaskInfoSelectCreatedPersonId").style.display =
      "block";
  }
  statemaintainPreviousSelectedRecord() {
    let seletedtasknumber = this.selectedTask;

    setTimeout(() => {
      let storeGridInfo = this.viewTaskGrid.api;
      storeGridInfo.forEachLeafNode(function (node) {
        if (node.data.TaskNumber == seletedtasknumber) {
          node.setSelected(true);
          //Moving Scroll bar to Selected Node
          storeGridInfo.ensureIndexVisible(node.rowIndex + 1);
        }
      });
    }, 500);
  }

  AddInternalTicketClickEvent() {
    this.addinternalpopupmethodCalling();
    const dialogRef = this.dialogService.open({
      title: "Create New Task",
      // Show component
      content: CreateNewTaskComponentComponent,
      width: this.addinternalpopupwidth,
      height: this.addinternalpopupheight,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.fromInternalTicketNavigation = true;
    dialogRef.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult)) {
        if (result) {
          this.employeeTaskListFromStatusFilter(undefined, undefined, false);
        }
      }
    });
  }
  private addinternalpopupmethodCalling() {
    if ($(window).width() >= 1920) {
      this.addinternalpopupwidth = 1850;
      this.addinternalpopupheight = 900;
    } else if ($(window).width() >= 1680 && $(window).width() <= 1720) {
      this.addinternalpopupwidth = 1600;
      this.addinternalpopupheight = 800;
    }
  }
  // getLoggedUserResponsibleForwardtoTasksList(selectedTabItem){
  //   if(selectedTabItem.taskMenutypeid==10||selectedTabItem.taskMenutypeid==2||selectedTabItem.taskMenutypeid==3||selectedTabItem.taskMenutypeid==4){
  //   this.responsiblePersonUserId=Config.OASOrganizationModel.LoggedUserID;
  //   this.forwardedtoUserId=Config.OASOrganizationModel.LoggedUserID;
  //   }
  //   else{
  //     this.responsiblePersonUserId=0;
  //     this.forwardedtoUserId=0;
  //   }
  //   }

  //Added By Suman
  tasksCreatedFromDateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedFromDate,
        "CreatedFromDate",
        true
      )
    )
      return false;
  }
  tasksCreatedTODateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedToDate,
        "CreatedToDate",
        false
      )
    )
      return false;
  }

  createdFromDateToDateValidations(
    createfromandtoDate: Date,
    name: string,
    isfrom: boolean
  ) {
    var today = new Date();
    var toDate = new Date(createfromandtoDate);
    var showfocuselement = false;
    const daysDifference = this.DateDiffInDays(
      isfrom ? this.CreatedToDate : today,
      isfrom ? this.CreatedFromDate : this.CreatedToDate
    );
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage("Invalid Day, Month or Year Range Detected.");
      showfocuselement = true;
    }
    if (this.hasValue(daysDifference) && daysDifference < 0) {
      if (isfrom) {
        this.ShowErrorMessage(
          "Created From Date Should be Less Than or Equal to Created To Date"
        );
        this.CreatedFromDate = "";
      } else {
        this.CreatedToDate = "";
        this.ShowErrorMessage("Future Dates Not allowed");
      }
      showfocuselement = true;
    }
    if (showfocuselement) {
      const elementFound =
        this.ViewTaskInformationID.nativeElement.querySelector(
          '[name="' + name + '"]'
        );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    return true;
  }

  private applyclassesforTasksCreatedDatesSelected(data: any) {
    if (this.hasValue(this.CreatedFromDate)) {
      if (!this.hasValue(this.CreatedToDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created To Date.");
        return false;
      }
    }
    if (this.hasValue(this.CreatedToDate)) {
      if (!this.hasValue(this.CreatedFromDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created From Date.");
        return false;
      }
    }
    // {
    //   console.log(
    //     this.hasValue(this.CreatedFromDate) && this.hasValue(this.CreatedToDate)
    //   );
    // }

    if (this.hasValue(this.CreatedFromDate)) {
      let fromTime = this.CreatedFromTime
        ? formatDate(this.CreatedFromTime, "HH:mm:ss", "en-US", "+0530")
        : "00:00:00";
      data.createdFromDate =
        this.formatValue(this.CreatedFromDate) + " " + fromTime;
    } else {
      data.createdFromDate = "";
    }
    if (this.hasValue(this.CreatedToDate)) {
      let toTime = this.CreatedToTime
        ? formatDate(this.CreatedToTime, "HH:mm:ss", "en-US", "+0530")
        : "00:00:00";
      data.createdToDate = this.formatValue(this.CreatedToDate) + " " + toTime;
    } else {
      data.createdToDate = "";
    }
    if (this.tasksCreatedFromDateChange() == false) return false;
    if (this.tasksCreatedTODateChange() == false) return false;
  }
  viewTicketsRemoveSelectedDatesClickEvent(number) {
    if (number === 1) {
      this.CreatedFromDate = "";
      this.CreatedFromTime = "";
    } else if (number === 2) {
      this.CreatedToDate = "";
      this.CreatedToTime = "";
    } else {
      this.ECDDate = "";
    }
  }
}
export class Status {
  TaskStatusId: string;
  TaskDescription: string;
}
