import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { GridOptions } from 'ag-grid-community';
import { AgGridNg2 } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { commonHelperService } from '../common/common.service';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee } from '../edit-task-customization-based-on-users/edit-task-customization-based-on-users.component';
import { TaskService } from '../services/task.service';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';

// import { AllTasksSummaryByForwardedToMeBucketComponent } from '../all-tasks-summary-by-forwarded-to-me-bucket/all-tasks-summary-by-forwarded-to-me-bucket.component';

@Component({
  selector: 'app-all-tasks-summary-by-forwarded-to-me-bucket',
  templateUrl: './all-tasks-summary-by-forwarded-to-me-bucket.component.html',
  styleUrls: ['./all-tasks-summary-by-forwarded-to-me-bucket.component.css']
})
export class AllTasksSummaryByForwardedToMeBucketComponent extends commonhelper implements OnInit {


  @ViewChild('AllTasksSummaryByForwardedToMeBucket') AllTasksSummaryByForwardedToMeBucket: AgGridNg2;

  public gridOptions: GridOptions;
  public unCustomizedGridOptions: GridOptions;
  public AllTasksSummaryByForwardedToMeBucketGridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public selectedRowsString = undefined;
  public selectedUncustomizedMailRowsString = undefined;
  public overlayNoRowsTemplate;
  // public defaultAllTasksSummaryByForwardedToMeBucketTaskType = { TaskTypeName: '-- Task Type --', TaskTypeId: null }
  AllTasksSummaryColumnDefs = [
    // { headerName: '#', field: 'TaskNumber', width: 85, tooltipField: 'Number of task', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'right' } },
    { headerName: 'Employee Name', field: 'EmployeeName', width: 286, tooltipField: 'EmployeeName', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'No. of Tasks in forward to me bucket of Employee', field: 'Total', width: 316, tooltipField: 'Total', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Enhancements', field: 'Enhancement_New_Requirement', width: 180, tooltipField: 'Enhancement_New_Requirement', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Bug / Issue', field: 'Bug_Issue', width: 135, tooltipField: 'Bug_Issue', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Enquiry', field: 'Enquiry', width: 135, tooltipField: 'Enquiry', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Form Changes', field: 'Form_Change', width: 140, tooltipField: 'Form_Change', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: '', field: '', width: 402, cellClass: 'no-border cell-wrap-text', autoHeight: true },
  ];



  // this array is used by the grid to render data in html
  public rowData = [];

  public AllTasksSummaryrowData = [];
  private commonhelperobj: commonHelperService;
  searchEmployeeName: any;

  /*** Declare all of the services in the constructor method * */
  constructor(private dialogService: DialogService, http: HttpClient) {
    super();

    this.commonhelperobj = new commonHelperService(http);

  }

  // public dataAllTasksSummaryByForwardedToMeBucketTaskTypeList = [
  //  {"TaskTypeName":"Task Name","TaskTypeId":1},
  // ]
  /** It will call all of the methods when the window loads */
  ngOnInit() {
    $("#txtAllTasksSummaryByForwardedToMeBucketSearchEmployee").focus();

    this.forwardedtasklist();
  }
  /** This method is used to bind the list to the Grid **/
  public forwardedtasklist() {
    const PostDataToService = {
      /*** The Variable is used for filtering the list  based on the entered data  **/
      Employee_Name: this.searchEmployeeName,
    };
    return this.commonhelperobj.PostData(Config.OASOrganizationAppnURL + 'ForwardedTaskList', PostDataToService)
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.AllTasksSummaryrowData = response.ForwardedTaskListModelList;

      });

  }
}
