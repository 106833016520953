import { Injectable } from '@angular/core';


@Injectable()
export class EasyFormTaskReportService {
    constructor() { }
    ExcelColumnsInfo = [
        { header: "Task #", key: "TaskNumber", width: 10 },
        { header: "Practice Name", key: "PracticeName", width: 30 },
        { header: "Subject", key: "IssueSubject", width: 40 },
        { header: "Current Working Status", key: "TaskCurrentWorkingStatus", width: 50 },
        { header: "Created Date", key: "IssueCreatedOn", width: 20 },
        { header: "Latest Modified Date", key: "TasksModifiedDate", width: 25 },
    ]

}

