


export enum FileTypeEnum {
  body = 1,
  png = 2,
  jpeg = 3,
  jpg = 4,
  pdf = 5,
  XLSX = 6,
  XLS = 7,
  DOC = 8,
  DOCX = 9,
MP4=10
}
