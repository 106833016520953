import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { GridOptions } from 'ag-grid-community';
import { AgGridNg2 } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { commonHelperService } from '../common/common.service';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee } from '../edit-task-customization-based-on-users/edit-task-customization-based-on-users.component';
import { TaskService } from '../services/task.service';
import { DialogCloseResult, DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { AgGridModule } from 'ag-grid-angular';
import { IntlService } from '@progress/kendo-angular-intl';
import { EditAccessDetailsPopupComponent } from '../edit-access-details-popup/edit-access-details-popup.component';

@Component({
  selector: 'app-view-access-information-popup',
  templateUrl: './view-access-information-popup.component.html',
  styleUrls: ['./view-access-information-popup.component.css']
})
export class ViewAccessInformationPopupComponent extends commonhelper implements OnInit {
  @ViewChild('ViewAccessInformationPopup') ViewAccessInformationPopup: AgGridNg2;
  @ViewChild('ViewAccessInformationPopupGrid') ViewAccessInformationPopupGrid: AgGridNg2;


  public gridOptions: GridOptions;
  public ViewAccessInformationPopupGridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public selectedRowsString = undefined;
  public overlayNoRowsTemplate;
  public ViewAccessInformationPopupRowData = [];
  public ActionTimeIdIn: any;
  public ActionDateID: any;
  public DATE = new Date();
  public DeviceID: any = {};
  public Model: any = {};
  public SelectedAccesInformation: any;
  public SelectedViewAccesInformation: any;
  public DeleteAccessInformation: any = {};
  public AccessInformationModel: any = {};
  public ActionTimeIdOut: any;
  public TodayDetails: any;
  public SelectedAccessInformationforAdd: any;
  public SelectedViewAccesInformationHide: any;
  // public AccessInformationModelList:any={};
  //public AccessInformationModel=[];
  // public AccessInformationModelList
  public AccessInformationModelList: Array<string> = [];
  // public TommorowDate = new Date(Date.now() + 24 * 60 * 60 * 1000);
  public YesterdayDate = new Date(Date.now() - 24 * 60 * 60 * 1000);
  // public TommorowGetTime = this.TommorowDate.getHours() + ":" + this.TommorowDate.getMinutes() + ":" + this.TommorowDate.getSeconds()
  // public TommorowGetTime = this.TommorowDate.getTime();
  public TodayTime = this.DATE.getHours();

  public viewAccessInformationPopupIconsHide = true;

  //public TodayDate = new Date(Date.now() - 24 * 60 * 60 * 1000);

  ViewAccessInformationPopupColumnDefs = [
    { headerName: 'Date', field: 'DateToConsider', width: 100, tooltipField: 'DateToConsider', cellClass: 'no-border cell-wrap-text', autoHeight: true, },
    { headerName: 'Check in Time', field: 'CheckInTime', width: 150, tooltipField: 'CheckInTime', cellClass: 'no-border cell-wrap-text', autoHeight: true, },
    { headerName: 'Check out Time', field: 'CheckOutTime', width: 150, tooltipField: 'CheckOutTime', cellClass: 'no-border cell-wrap-text', autoHeight: true, },
    // { headerName: 'Total Hours', field: 'TotalHours', width: 150, tooltipField: 'TotalHours', cellClass: 'no-border cell-wrap-text', autoHeight: true, },
    { headerName: 'Device Group', field: 'AccessDeviceGroup', width: 170, tooltipField: 'AccessDeviceGroup', cellClass: 'no-border cell-wrap-text', autoHeight: true, },
  ];
  // ViewAccessInformationPopupRowData = [
  //   { Date: '20/01/2020', CheckinTime: '10.05', CheckOutTIme: '0.00', },
  // ];

  public rowData = [];
  private commonhelperobj: commonHelperService;
  DayViewAccessInfo: any;
  NextDate: any;
  DatetoConsider: any;
  constructor(private httpClient: HttpClient, http: HttpClient,
    private dialogService: DialogService,
    private dialog: DialogRef, private intl: IntlService) {
    super();

    this.commonhelperobj = new commonHelperService(http);

  }
  public todate = this.formatValue(this.DATE)
  // public Tommorowdate = this.formatValue(this.TommorowDate)
  public Yesterdaydate = this.formatValue(this.YesterdayDate)
  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, 'MM/dd/yyyy')}` : '';
  }
  AccessInformation() {
    const selectedRows = this.ViewAccessInformationPopupGrid.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;

    });
  }
  ngOnInit() {
    $("#spnViewAccessInfoAdd").focus();
    //this.SelectedViewAccesInformationHide=true;
    if (this.SelectedViewAccesInformationHide == true) {
      this.viewAccessInformationPopupIconsHide = false;
    }
    else {
      this.viewAccessInformationPopupIconsHide = true;
    }


    if (this.hasValue(this.SelectedAccesInformation)) {
      this.DayViewAccessInfo = this.SelectedAccesInformation;
    }
    if (this.DayViewAccessInfo.DateToConsider == this.todate) {
      this.AccessInformationDayDetailsForToday();
      this.TodayDetails = 1;

    }
    else if (this.DayViewAccessInfo.DateToConsider == this.Yesterdaydate) {
      if (this.TodayTime <= 8) {
        this.AccessInformationDayDetailsForToday();
        this.TodayDetails = 1;
      }
      else {

        this.AccessInformationDayDetailsGet();

      }
    }
    else {

      this.AccessInformationDayDetailsGet();

    }

  }

  viewAccessInformationPopupCancelClickEvent() {
    this.dialog.close();


  }

  /** This method is used to bind the list to the Grid **/
  public AccessInformationDayDetailsGet() {
    // alert(this.allReportByResponsibleEmployeeFromDate);
    debugger;
    const PostDataToService = {
      /*** The Variables are used for filtering the lists  based on data entered on the respective fields **/
      DateToConsider: this.DayViewAccessInfo.DateToConsider,
      EmployeeInfoID: this.DayViewAccessInfo.EmployeeID
    };
    return this.commonhelperobj.PostData(Config.OASAccessInformationAppnURL + 'EmployeeAttendanceDayDetailsGet', PostDataToService)
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.ViewAccessInformationPopupRowData = response.EmployeeAttendanceDayDetailsModellist;

      });
  }
  public AccessInformationDayDetailsForToday() {
    const PostDataToService = {
      DateToConsider: this.DayViewAccessInfo.DateToConsider,
      EmployeeInfoID: this.DayViewAccessInfo.EmployeeID
      //DateToConsider:
    }
    return this.commonhelperobj.PostData(Config.OASAccessInformationAppnURL + 'EmployeeAttendanceDayDetailsToday', PostDataToService)
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.ViewAccessInformationPopupRowData = response.EmployeeAttendanceDayDetailsModellist;

      });
  }

  viewAccessInfoAddClick() {
    const dialogRef = this.dialogService.open({
      title: 'Add Access Details',
      // Show component 
      content: EditAccessDetailsPopupComponent,
      // height: 698, 
      // width: 620, 
      width: 400
    });

    dialogRef.content.instance.SelectedAccesInformation = "";
    dialogRef.content.instance.SelectedAccessInformationforAdd = this.SelectedAccesInformation;
    dialogRef.result.subscribe((result) => {
      if (this.TodayDetails == 1) {
        this.AccessInformationDayDetailsForToday();
      }
      else {
        this.AccessInformationDayDetailsGet();
      }
      this.selectedRowsString = "";
    });
  }

  viewAccessInformationEditClick() {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage('Please Select to Edit');
      return false;
    }
    const dialogRef = this.dialogService.open({
      title: 'Edit Access Details',
      // Show component 
      content: EditAccessDetailsPopupComponent,
      // height: 698, 
      // width: 620, 
      width: 400
    });

    dialogRef.content.instance.SelectedAccesInformation = this.selectedRowsString;


    dialogRef.result.subscribe((result) => {
      if (this.TodayDetails == 1) {
        this.AccessInformationDayDetailsForToday();
      }
      else {
        this.AccessInformationDayDetailsGet();
      }
      this.selectedRowsString = "";
    });
  }
  viewAccessInformationDeleteClick() {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage('Please Select to Delete');

      return;
    }

    const dialog: DialogRef = this.dialogService.open({
      title: 'Please Confirm',
      content: 'Are You Sure to Delete Access Information?',
      actions: [
        { text: 'No' },
        { text: 'Yes', primary: true }
      ],
      width: 450,
      height: 250,
      minWidth: 250
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        //console.log('close');
      } else {
        if (this.hasValue(result)) {
          if (result.text === 'No') {
            return;
          } else {

            this.NextDate = new Date(this.selectedRowsString.DateToConsider);

            this.NextDate.setDate(this.NextDate.getDate() + 1);
            // this.DateToConsider = this.parseDate(NextDate)
            this.DatetoConsider = this.formatValue(this.NextDate);
            this.DatetoConsider = this.DatetoConsider.split('/');
            var Month = this.DatetoConsider[0];
            var date = this.DatetoConsider[1];
            var Year = this.DatetoConsider[2];

            var datetimeid = Year + Month + date;
            this.DatetoConsider = parseInt(datetimeid);
            // var Date = new Date(NextDate).toISOString().slice(0,10); 
            // public viewAccessInformationPopupIconsHide = true;
            // this.NextDate = new Date(this.selectedRowsString.DateToConsider).getDate()+1;
            // this.NextDate.setDate(this.selectedRowsString.DateToConsider.getDate() + 1);


            if (this.hasValue(this.selectedRowsString.CheckInTime)) {
              this.ActionTimeIdIn = this.selectedRowsString.CheckInTimeID
              this.ActionDateID = this.selectedRowsString.DateToConsiderID
            }


            if (this.hasValue(this.selectedRowsString.CheckOutTime)) {
              this.ActionTimeIdOut = this.selectedRowsString.CheckOutTimeID
              this.ActionDateID = this.selectedRowsString.DateToConsiderID
            }
            if (this.selectedRowsString.DeviceGroupID == 1) {
              if (this.hasValue(this.selectedRowsString.CheckInTimeID)) {
                this.selectedRowsString.CheckinTimeID = parseInt(this.selectedRowsString.CheckInTimeID)
                if (this.selectedRowsString.CheckinTimeID <= 80000) {

                  this.ActionDateID = this.DatetoConsider
                }
                else {
                  this.ActionDateID = this.selectedRowsString.DateToConsiderID
                }
                this.AccessInformationModel = {
                  DeviceID: 1,

                  //ActionDateID: this.selectedRowsString.DateToConsiderID,
                  ActionDateID: this.ActionDateID,
                  ActionTimeId: this.ActionTimeIdIn,
                  DateToConsider: this.selectedRowsString.DateToConsider,
                  DateToConsiderID: this.selectedRowsString.DateToConsiderID,
                  DeviceGroupID: this.selectedRowsString.DeviceGroupID,
                  EmployeeID: this.selectedRowsString.EmployeeID,
                  ActionDateid: 1
                };
                this.AccessInformationModelList.push(this.AccessInformationModel);

              }

              if (this.hasValue(this.selectedRowsString.CheckOutTimeID)) {
                this.selectedRowsString.CheckoutTimeID = parseInt(this.selectedRowsString.CheckOutTimeID)
                if (this.selectedRowsString.CheckoutTimeID <= 80000) {

                  this.ActionDateID = this.DatetoConsider
                }
                else {
                  this.ActionDateID = this.selectedRowsString.DateToConsiderID
                }
                this.AccessInformationModel = {
                  DeviceID: 2,
                  // ActionDateID: this.selectedRowsString.DateToConsiderID,
                  ActionDateID: this.ActionDateID,
                  ActionTimeId: this.ActionTimeIdOut,
                  DateToConsider: this.selectedRowsString.DateToConsider,
                  DeviceGroupID: this.selectedRowsString.DeviceGroupID,
                  DateToConsiderID: this.selectedRowsString.DateToConsiderID,
                  EmployeeID: this.selectedRowsString.EmployeeID,
                  ActionDateid: 1
                };
                this.AccessInformationModelList.push(this.AccessInformationModel);

              }


            }
            if (this.selectedRowsString.DeviceGroupID == 2) {
              if (this.hasValue(this.selectedRowsString.CheckInTimeID)) {
                this.selectedRowsString.CheckinTimeID = parseInt(this.selectedRowsString.CheckInTimeID)
                if (this.selectedRowsString.CheckinTimeID <= 80000) {

                  this.ActionDateID = this.DatetoConsider
                }
                else {
                  this.ActionDateID = this.selectedRowsString.DateToConsiderID
                }
                this.AccessInformationModel = {
                  DeviceID: 3,
                  // ActionDateID: this.selectedRowsString.DateToConsiderID,
                  ActionDateID: this.ActionDateID,
                  ActionTimeId: this.ActionTimeIdIn,
                  DateToConsider: this.selectedRowsString.DateToConsider,
                  DeviceGroupID: this.selectedRowsString.DeviceGroupID,
                  DateToConsiderID: this.selectedRowsString.DateToConsiderID,
                  EmployeeID: this.selectedRowsString.EmployeeID,
                  ActionDateid: 1
                };
                this.AccessInformationModelList.push(this.AccessInformationModel);

              }

              if (this.hasValue(this.selectedRowsString.CheckOutTimeID)) {
                this.selectedRowsString.CheckoutTimeID = parseInt(this.selectedRowsString.CheckOutTimeID)
                if (this.selectedRowsString.CheckoutTimeID <= 80000) {

                  this.ActionDateID = this.DatetoConsider
                }
                else {
                  this.ActionDateID = this.selectedRowsString.DateToConsiderID
                }
                this.AccessInformationModel = {
                  DeviceID: 4,
                  // ActionDateID: this.selectedRowsString.DateToConsiderID,
                  ActionDateID: this.ActionDateID,
                  ActionTimeId: this.ActionTimeIdOut,
                  DateToConsider: this.selectedRowsString.DateToConsider,
                  DeviceGroupID: this.selectedRowsString.DeviceGroupID,
                  DateToConsiderID: this.selectedRowsString.DateToConsiderID,
                  EmployeeID: this.selectedRowsString.EmployeeID,
                  ActionDateid: 1
                };
                this.AccessInformationModelList.push(this.AccessInformationModel);

              }
            }
            if (this.AccessInformationModelList.length > 0) {
              this.DeleteAccessInformation.AccessInformationModelList = this.AccessInformationModelList
            }
            const postData = {

            };
            this.commonhelperobj.PostData(Config.OASAccessInformationAppnURL + 'UpdateAccessInformation', this.DeleteAccessInformation).subscribe(serviceResponse => {
              if (this.isError(serviceResponse)) {
                this.AccessInformationModelList = [];
                this.DeleteAccessInformation.AccessInformationModelList = [];
                return;
              }
              this.ShowSuccesseMessage('Deleted Successfully.');
              this.selectedRowsString = "";
              // this.GetTaskCustomizationListBasedOnUserNames();
              //this.GetEmployeeAddInfoList();
              if (this.TodayDetails == 1) {
                this.AccessInformationDayDetailsForToday();

              }
              else {
                this.AccessInformationDayDetailsGet();

              }
              this.AccessInformationModelList = [];
              this.DeleteAccessInformation.AccessInformationModelList = [];


            });
          }
        }

      }

    });

  }

}

