import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { AgGridNg2 } from "ag-grid-angular";
import { commonHelperService } from "../common/common.service";
import { commonhelper } from "../common/commonhelper";
import { PraticesListComponent } from "../pratices-list/pratices-list.component";
import { ResponsiblepersonUsersListComponent } from "../responsibleperson-users-list/responsibleperson-users-list.component";
import { IntlService } from "@progress/kendo-angular-intl";
import { HttpClient } from "@angular/common/http";
import { TaskService } from "../services/task.service";
import {
  DialogCloseResult,
  DialogService,
} from "@progress/kendo-angular-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Config } from "../config";
import { GridSizeChangedEvent } from "ag-grid-community";

@Component({
  selector: "app-ticket-monitoring-report",
  templateUrl: "./ticket-monitoring-report.component.html",
  styleUrls: ["./ticket-monitoring-report.component.css"],
})
export class TicketMonitoringReportComponent
  extends commonhelper
  implements OnInit
{

  @ViewChild("TicketMonitoringReportTicketsGrid")
  TicketMonitoringReportTicketsGrid: AgGridNg2;
  @ViewChild("TicketMonitoringReportTickets", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;

  @ViewChild("TicketMonitoringReportTickets")
  TicketMonitoringReportTickets: ElementRef;


  isFilterTypeList=[
      {
      FilterType:"First Response Sent by Support but Status is Ongoing",
      FilterID:1,
    },
    {
      FilterType:"Latest Response From Customer but Status is Ongoing",
      FilterID:2,
    },  {
      FilterType:"If any Ticket ExeededMore than 4 Responses but Status is ongoing",
      FilterID:3,
    }
  ]
  columnDefs=[
    {
      headerName: "Task #",
      field: "TaskNumber",
      width: 90,
      tooltipField: "TaskNumber",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Customer Name",
      field: "PracticeName",
      width: 180,
      tooltipField: "PracticeName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Task Subject",
      field: "IssueSubject",
      width: 240,
      tooltipField: "IssueSubject",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Priority",
      field: "SeverityDesc",
      width: 90,
      tooltipField: "SeverityDesc",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Task Status",
    //   field: "TaskLatestStatusName",
    //   width: 100,
    //   tooltipField: "TaskLatestStatusName",
    //   cellClass: "no-border cell-wrap-text",
    //   autoHeight: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Responsible Person",
      field: "TaskUsersInfo",
      width: 180,
      tooltipField: "ResponsiblePerson",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Created Date",
      field: "IssueCreatedOn",
      width: 150,
      tooltipField: "IssueCreatedOn",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      hide: false,
      cellStyle: { textAlign: "center" },
    },

    {
      headerName: "Created By",
      field: "CreatedBy",
      width: 180,
      tooltipField: "CreatedBy",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
  ]

  ticketMonitoringReportTicketsSearchTaskNumber: any;
  practiceInformation: any = {};
  IsFilterType:any=1;
  CreatedFromDate: any = new Date();
  CreatedToDate: any = new Date();
  CreatedToTime: any = new Date();
  CreatedFromTime: any = new Date("Tue Apr 11 2000 00:00:00 GMT+0530");
  NextButtonDisabled: boolean = false;
  PreviousButtondisabled: boolean = false;
  private commonhelperobj: commonHelperService;
  FromRecords: any;
  PrevLabelCount: any;
  NextLabelCount: any;
  RecordsPerPage: any;
  TotalCountValue: boolean = true;
  TotalCountValueIcon: boolean = false;
  TotalLabelCount: any;
  TotalLabelCountValue: any;
  fromrecordsfromcount: any;
  prevnextbuttons: boolean = true;
  responsiblePersonInfo: any = {};
  TicketMonitoringReportTicketsRowData = [];
  selectedTask: any;
  clickedtab: number = 0;
  EHRPracticeID: number = 0;
  items: any;

  constructor(
    private intl: IntlService,
    private httpClient: HttpClient,
    private taskService: TaskService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private _router: Router
  ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const data = this.getCommonData(params.info);
      if (
        this.hasValue(data) &&
        this.hasValue(data.previouslySelectedSearchFilters)
      ) {
        this.ticketMonitoringReportTicketsSearchTaskNumber =
          data.previouslySelectedSearchFilters.statisticsReportTicketsSearchTaskNumber;
        this.responsiblePersonInfo =
          data.previouslySelectedSearchFilters.responsiblePersonInfo;
        this.practiceInformation =
          data.previouslySelectedSearchFilters.practiceInformation;
        this.CreatedFromDate =
          data.previouslySelectedSearchFilters.CreatedFromDate;
        this.CreatedToDate = data.previouslySelectedSearchFilters.CreatedToDate;
        this.FromRecords =
          data.previouslySelectedSearchFilters.StatemaintainFromRecords;
        this.selectedTask = data.previouslySelectedTaskNumber;
        this.IsFilterType =
        data.previouslySelectedSearchFilters.IsFilterType;
      } else {
      }
    });

    this.GetMonitoringTicketsDetails(undefined, undefined, false);
    setTimeout(() => {
      document
        .getElementById("txtTicketMonitoringReportTicketsSearchTask")
        .focus();
      this.statemaintainPreviousSelectedRecord();
    }, 100);
  }

  onTicketMonitoringReportTicketsGridSizeChanged(params: GridSizeChangedEvent) {
    // params.api.sizeColumnsToFit();
  }

  tasksCreatedFromDateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedFromDate,
        "CreatedFromDate",
        true
      )
    )
      return false;
  }

  tasksCreatedTODateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedToDate,
        "CreatedToDate",
        false
      )
    )
      return false;
  }

  createdFromDateToDateValidations(
    modifiedfromandtoDate: Date,
    name: string,
    isfrom: boolean
  ) {
    var today = new Date();
    var toDate = new Date(modifiedfromandtoDate);
    var showfocuselement = false;
    const daysDifference = this.DateDiffInDays(
      isfrom ? this.CreatedToDate : today,
      isfrom ? this.CreatedFromDate : this.CreatedToDate
    );
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage("Invalid Day, Month or Year Range Detected.");
      showfocuselement = true;
    }
    if (this.hasValue(daysDifference) && daysDifference < 0) {
      if (isfrom) {
        this.ShowErrorMessage(
          "Created From Date Should be Less Than or Equal to Created To Date"
        );
        this.CreatedFromDate = "";
      } else {
        this.CreatedToDate = "";
        this.ShowErrorMessage("Future Dates Not allowed");
      }
      showfocuselement = true;
    }
    if (showfocuselement) {
      const elementFound =
        this.TicketMonitoringReportTickets.nativeElement.querySelector(
          '[name="' + name + '"]'
        );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    return true;
  }

  ClearFromandTODate(number) {
    if (number === 1) {
      this.CreatedFromDate = "";
    } else if (number === 2) {
      this.CreatedToDate = "";
    }
  }

  OnclickOpenPracticeDetaisPopup() {
    const dialogRef = this.dialogService.open({
      title: "Select Pratice",
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.practiceInformation = result;
        // console.log(this.practiceInformation);
        this.EHRPracticeID = this.practiceInformation.PracticeId;
      }
    });
  }
  OnclickRemovePracticeInfo() {
    this.practiceInformation = {};
    this.EHRPracticeID = 0;
  }

  OnclickOpenResponsiblePersionPopUp() {
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.responsiblePersonInfo = result;
      }
    });
  }
  OnclickRemoveResponsibleInfo() {
    this.responsiblePersonInfo = {};
  }

  statemaintainPreviousSelectedRecord() {
    let seletedtasknumber = this.selectedTask;
    let storeGridInfo = this.TicketMonitoringReportTicketsGrid.api;
    setTimeout(() => {
      this.TicketMonitoringReportTicketsGrid.api.forEachLeafNode(function (
        node
      ) {
        if (node.data.TaskNumber == seletedtasknumber) {
          node.setSelected(true);
          //Moving Scroll bar to Selected Node
          storeGridInfo.ensureIndexVisible(node.rowIndex + 1);
        }
      });
    }, 500);
  }

  OnClickTicketsPrevNext(prevornext) {
    this.GetMonitoringTicketsDetails(undefined, prevornext, false);
  }
  TicketMonitoringReportTicketsViewClickEvent() {
    let selectedTask = this.getSelectedTaskInfo();

    if (!this.hasValue(selectedTask) || selectedTask <= 0) return;
    const PostDataService: any = {
      IsFromEdit: true,
      editmodedata: selectedTask,
      isfrom: 12, //by ticktes Monitoring
      selectedTicketsList: this.TicketMonitoringReportTicketsRowData,
    };
    PostDataService.StatemaintainPreviouslySearchFilters = {
      ticketMonitoringReportTicketsSearchTaskNumber:
        this.ticketMonitoringReportTicketsSearchTaskNumber,
      responsiblePersonInfo: this.responsiblePersonInfo,
      practiceInformation: this.practiceInformation,
      CreatedFromDate: this.CreatedFromDate,
      CreatedToDate: this.CreatedToDate,
      StatemaintainFromRecords: this.FromRecords,
      IsFilterType:
        this.IsFilterType,
    };
    const outletObj = {};
    outletObj["main"] = [
      "app-view-full-task-information",
      this.setCommonData(PostDataService),
    ];
    this._router.navigate(["/home", { outlets: outletObj }]);
  }

  getSelectedTaskInfo() {
    const selectedrow =
      this.TicketMonitoringReportTicketsGrid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.ShowErrorMessage("Please Select Task.");
      document.getElementById("myAppointmentsSelectUser").focus();
      return 0;
    }
    return selectedrow[0];
  }

  GetMonitoringTicketsDetails(isfromsearch, prevornext, exoportoexcel) {
    this.RecordsPerPage = 50;
    // if (exoportoexcel == false) {
    //   if (
    //     isfromsearch == true &&
    //     (prevornext == false || prevornext == undefined) &&
    //     this.TotalCountValue == false
    //   ) {
    //     this.TotalCountValueIcon = false;
    //     this.TotalCountValue = true;
    //   }
    //   if (
    //     !this.hasValue(this.FromRecords) ||
    //     (this.hasValue(isfromsearch) && isfromsearch == true)
    //   ) {
    //     this.FromRecords = 1;
    //   }
    //   switch (prevornext) {
    //     case 1:
    //       //if(this.hasValue(this.PreviousButtondisabled) && this.PreviousButtondisabled==true) return;
    //       this.FromRecords -= this.RecordsPerPage;
    //       break;

    //     case 2:
    //       //if(this.hasValue(this.NextButtonDisabled) && this.NextButtonDisabled==true) return;
    //       this.FromRecords += this.RecordsPerPage;
    //       break;
    //     case 3:
    //       this.fromrecordsfromcount = this.FromRecords;
    //       this.FromRecords = null;
    //   }
    // }
    const data: any = {};

    if (this.applyclassesforTasksCreatedDatesSelected(data) == false) return;
    data.FromRecords=this.FromRecords;
    (data.IsFilterType = this.IsFilterType),
    (data.TaskNumber = this.ticketMonitoringReportTicketsSearchTaskNumber),
      (data.ResponsiblePersonID = this.hasValue(
        this.responsiblePersonInfo.EmployeeId
      )
        ? this.responsiblePersonInfo.EmployeeId
        : undefined),
      (data.PracticeID = this.hasValue(this.practiceInformation.PracticeId)
        ? this.practiceInformation.PracticeId
        : undefined),
      this.taskService.ticketsMonitoringReports(data).subscribe((response) => {
        if (this.commonhelperobj.isError(response)) {
          return;
        }
        if (
          this.hasValue(response) &&
          this.hasValue(response.Count) &&
          response.Count > 0
        ) {
          this.TotalCountValueIcon = true;
          this.TotalCountValue = false;
          this.TotalLabelCount = response.Count;
          // this.FromRecords = this.fromrecordsfromcount;
          return;
        }
        // response.EmployeeTaskListModelList.forEach((TaskListelement) => {
        //   if (this.hasValue(TaskListelement.TaskUsersInfo)) {
        //     const SplittedUserData = TaskListelement.TaskUsersInfo.split(",");
        //     if (
        //       this.hasValue(SplittedUserData) &&
        //       SplittedUserData.length > 0
        //     ) {
        //       //TaskListelement.ParticipantsDesc = "";
        //       TaskListelement.AssistantsDesc = "";
        //       // tslint:disable-next-line:no-shadowed-variable
        //       SplittedUserData.forEach((element) => {
        //         const getUserRolesData = element.split("|");
        //         if (
        //           this.hasValue(getUserRolesData) &&
        //           getUserRolesData.length > 0
        //         ) {
        //           if (getUserRolesData[2] === "2") {
        //             TaskListelement.ResponsiblePersonDesc = getUserRolesData[1];
        //           }
        //           if (getUserRolesData[2] === "7") {
        //             // if (!this.hasValue(TaskListelement.ParticipantsDesc)) {
        //             //   TaskListelement.ParticipantsDesc = "";
        //             // }
        //             // TaskListelement.ParticipantsDesc += getUserRolesData[1] + ', ';
        //             TaskListelement.AssistantsDesc +=
        //               getUserRolesData[1] + ", ";
        //           }
        //         }
        //       });
        //       // TaskListelement.ParticipantsDesc = TaskListelement.ParticipantsDesc.substring(0, TaskListelement.ParticipantsDesc.length - 2);
        //       TaskListelement.AssistantsDesc =
        //         TaskListelement.AssistantsDesc.substring(
        //           0,
        //           TaskListelement.AssistantsDesc.length - 2
        //         );
        //     }
        //   }
        // });
        this.TicketMonitoringReportTicketsRowData =
          response.MonitoringTicketsDetailsList;

        // this.PrevLabelCount = this.FromRecords;
        // if (this.FromRecords == 1) {
        //   this.NextLabelCount = response.EmployeeTaskListModelList.length;
        // } else {
        //   this.NextLabelCount =
        //     this.FromRecords + response.EmployeeTaskListModelList.length - 1;
        // }
        // if (
        //   this.FromRecords == 1 &&
        //   response.EmployeeTaskListModelList.length < this.RecordsPerPage
        // ) {
        //   this.prevnextbuttons = true;
        //   this.TotalLabelCountValue = response.EmployeeTaskListModelList.length;
        // } else if (
        //   this.FromRecords == 1 &&
        //   response.EmployeeTaskListModelList.length == this.RecordsPerPage
        // ) {
        //   this.prevnextbuttons = false;
        //   this.PreviousButtondisabled = true;
        //   this.NextButtonDisabled = false;
        // } else if (
        //   response.EmployeeTaskListModelList.length < this.RecordsPerPage
        // ) {
        //   this.prevnextbuttons = false;
        //   this.NextButtonDisabled = true;
        //   this.PreviousButtondisabled = false;
        // } else {
        //   this.prevnextbuttons = false;
        //   this.PreviousButtondisabled = false;
        //   this.NextButtonDisabled = false;
        // }
        //this.statemaintainPreviousSelectedRecord();
      });
  }
  private applyclassesforTasksCreatedDatesSelected(data: any) {
    if (this.hasValue(this.CreatedFromDate)) {
      if (!this.hasValue(this.CreatedToDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created To Date.");
        return false;
      }
    }
    if (this.hasValue(this.CreatedToDate)) {
      if (!this.hasValue(this.CreatedFromDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created From Date.");
        return false;
      }
    }
    data.CreatedFromDate = this.CreatedFromDate;
    data.CreatedToDate=this.CreatedToDate;
    if (this.tasksCreatedFromDateChange() == false) return false;
    if (this.tasksCreatedTODateChange() == false) return false;
  }
}
