import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer,
  ViewContainerRef,
} from "@angular/core";
import { Config } from "../config";
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "@progress/kendo-angular-notification";
import { commonHelperService } from "../common/common.service";
import { commonhelper } from "../common/commonhelper";
import {
  DialogService,
  DialogCloseResult,
  DialogRef,
} from "@progress/kendo-angular-dialog";
import { PraticesListComponent } from "../pratices-list/pratices-list.component";
import { KendoButtonService } from "@progress/kendo-angular-buttons/dist/es2015/button/button.service";
import { TaskService } from "../services/task.service";
import { PracticeUsersListComponent } from "../practice-users-list/practice-users-list.component";
import { LeftNavService } from "../common/Leftnav.service";
import { ResponsiblepersonUsersListComponent } from "../responsibleperson-users-list/responsibleperson-users-list.component";
import { AdditionalParticipantAndObserversPopupComponentComponent } from "../additional-participant-and-observers-popup-component/additional-participant-and-observers-popup-component.component";
import { PracticeUsersCustomizationComponent } from "../practice-users-customization/practice-users-customization.component";
import { SelectModulePopupComponentComponent } from "../select-module-popup-component/select-module-popup-component.component";
@Component({
  selector: "app-add-task-customization-information",
  templateUrl: "./add-task-customization-information.component.html",
  styleUrls: ["./add-task-customization-information.component.css"],
})
export class AddTaskCustomizationInformationComponent
  extends commonhelper
  implements OnInit
{
  SelectedObserversPersonNames: string;
  SelectedObserversInfo: any;
  SelectedAdditionalResponsiblePersonName: string;
  SelectedAdditionalResponsiblePersonInfo: any;
  selectedAdditionalResponsiblePersons: any;
  @ViewChild("AddTaskCustomztnEmailAddress")
  AddTaskCustomztnEmailAddress: ElementRef;
  @ViewChild("AddTaskCustomztnEmailAddress", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  // @ViewChild('spanSelectPracticeIcon') spanSelectPracticeIcon: ElementRef;
  isCheckedAllPractices = false;
  isCheckedAllUsers = false;
  isCheckedAllModule = false;
  SelectedAssistantPersonInfo: any = {};
  IsresponsiblePersonAndFwdToSame: boolean = false;
  PopupDataFromMultiSelectUserSelectionPopup: any;
  SelectedAssistantPerson: any = [];
  SelectedAssistantPersonName: string = "";
  private commonhelperobj: commonHelperService;
  public TaskCustomizationModel: any = {};
  public SelectedPracticeUserBasedOn: any = {};
  public projectsList: Array<string> = [];
  public supportGroupsList: Array<string> = [];
  public ModulesList: Array<string> = [];
  public SelectedParticpants: any = [];
  public SelectedObservers: any = [];
  public SelectedResponsiblePerson: any = {};
  public responsiblePersonInfo: any = {};
  public EmployeeListInfo: any;
  public EmployeeObserversListInfo: any;
  public EmployeeResponsbilePersonListInfo: any;
  public showPracticeUsers = true;
  public showPracticeUsersEmailIDs = true;
  public practiceInformation: any;
  public practiceUsersList: any = [];
  public SelectedPracticeUser: any = [];
  public enteredEmailIDs: any = [];
  public PracticeUserTypeList = [
    // { text: 'Select User Based on', value: 0 },
    { text: "User Name", value: 1 },
    { text: "Based On Email ID", value: 2 },
  ];
  private fieldArray: Array<any> = [];
  private newAttribute: any = {};
  public EmployeesAndRolesModelList: Array<string> = [];
  public EmployeesAndRolesModel: any = {};
  public PracticeUsersModel: any = {};
  public SelectedPracticeUserList: any = [];
  public selectedUsersList: Array<any> = [];
  public usersmodel: any = {};
  public practiceDiv: HTMLElement;
  public divEmailAddress: HTMLElement;
  public divIssueSupportGroupID: HTMLElement;
  public divSelectedResponsiblePerson: HTMLElement;
  public divSelectedParticpants: HTMLElement;
  public divSelectedObservers: HTMLElement;
  public disableOnDefault = true;
  public divPracticeInformationFocus: any;
  public usersList: any;
  public selectPracticeIcon: HTMLElement;
  public spanSelectPracticeIcon: HTMLElement;

  public defaultSupportGroupItem = {
    IssueSupportGroupDesc: "-- Select --",
    IssueSupportGroupID: null,
  };

  public defaultAddTaskResponsiblePersonItem = {
    EmployeeFullName: "-- Select --",
    EmployeeId: null,
  };
  // tslint:disable-next-line:max-line-length
  constructor(
    httpClient: HttpClient,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private dialog: DialogRef,
    private taskService: TaskService,
    private LeftNav: LeftNavService,
    private renderer: Renderer
  ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }

  ngOnInit() {
    this.spanSelectPracticeIcon =
      this.AddTaskCustomztnEmailAddress.nativeElement.querySelector(
        "#spanSelectPracticeIcon"
      );
    this.GetProjectsList();
    // this.GetSupportGroupsList();
    // this.GetModulesList();
    this.GetEmployeesList();
    // setTimeout(() => {
    //     // const elementFound = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('[name="' + 'divPracticeInformation' + '"]');
    //     // if (this.hasValue(elementFound)) {
    //     //     elementFound.children[0].focus();
    //     // }
    //     // $("#spanSelectPracticeIcon").focus();
    //   },200);
  }
  GetProjectsList() {
    const PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASProjectsAppnURL + "GetProjectsList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.projectsList = serviceResponse.ProjectList;
      });
  }


  GetEmployeesList() {
    const PostDataToService = {};
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "OrganizationEmployeesList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        // if (this.hasValue(serviceResponse)) {
        //   serviceResponse.EmployeeList.unshift({ EmployeeFullName: ' - Select - ', EmployeeId: -1 });
        // }
        this.EmployeeListInfo = serviceResponse.EmployeeList;
        this.EmployeeObserversListInfo = serviceResponse.EmployeeList;
        this.EmployeeResponsbilePersonListInfo = serviceResponse.EmployeeList;
      });
  }
  practiceIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Practice",
      // Show component
      content: PraticesListComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.TaskCustomizationModel.PracticeName)) {
        } else {
          this.TaskCustomizationModel.PracticeName = "";
        }
      } else {
        this.practiceInformation = result;
        if (this.hasValue(this.practiceInformation)) {
          this.TaskCustomizationModel.PracticeName =
            this.practiceInformation.PracticeName;
          this.TaskCustomizationModel.PracticeId =
            this.practiceInformation.PracticeId;
          this.SelectedPracticeUserList = [];

        }

      }
    });
  }

  getPracticeUsersList() {
    const PostData = {
      PracticeId: this.practiceInformation.PracticeId,
      IssueSupportGroupID: this.TaskCustomizationModel.IssueSupportGroupID,
    };
    this.taskService.PracticeUsersList(PostData).subscribe((response) => {
      if (this.isError(response)) {
        return;
      }
      this.practiceUsersList = response;
    });
  }

  public onTabSelect(e) {}

  CancelClickEvent() {
    this.dialog.close();
  }
  AddMoreEmailAddress() {
    $("#textboxDiv").append("<div><br><input type='text'/><br></div>");
  }
  RemoveMoreEmailAddress() {
    $("#textboxDiv").children().last().remove();
  }

  DeleteClickEvent(this) {
    this.parent().remove();
  }
  AddClickEvent() {
    $(".Option:last").after($(".Option:last").clone());
  }

  addFieldValue() {
    this.fieldArray.push(this.newAttribute);
    this.newAttribute = {};
  }

  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
  }

  SaveTaskCustomizationInfo() {
    if (this.isCheckedAllPractices === false) {
      if (!this.hasValue(this.TaskCustomizationModel.PracticeName)) {
        this.ShowErrorMessage("Please Select Practice.");
        const elementFound =
          this.AddTaskCustomztnEmailAddress.nativeElement.querySelector(
            '[id="' + "spanSelectPracticeIcon" + '"]'
          );
        // this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('#spanSelectPracticeIcon').focus();
        if (this.hasValue(elementFound)) {
          elementFound.focus();
        }
        return false;
      }
    } else {
      this.TaskCustomizationModel.PracticeId = -1;
    }

    if (this.isCheckedAllModule === false) {
      if (!this.hasValue(this.TaskCustomizationModel.IssueSupportGroupDesc)) {
        this.ShowErrorMessage("Please Select EHR Module.");
      // tslint:disable-next-line:max-line-length
        const elementFound =
          this.AddTaskCustomztnEmailAddress.nativeElement.querySelector(
            '[name="' + "divIssueSupportGroupName" + '"]'
          );
        if (this.hasValue(elementFound)) {
          elementFound.children[0].focus();
        }
        return false;
      }
    } else {
      this.TaskCustomizationModel.IssueSupportGroupID = -1;
    }
    // if (!this.hasValue(this.SelectedPracticeUserList) && this.SelectedPracticeUserList.length <= 0) {
    //     this.ShowErrorMessage('Please Select User.');
    //     return false;
    // }
    //debugger;
    if (
      !this.hasValue(this.SelectedResponsiblePerson) ||
      !this.hasValue(this.SelectedResponsiblePerson.EmployeeId)
    ) {
      this.ShowErrorMessage("Please Select Responsible Person.");
      // tslint:disable-next-line:max-line-length
      const elementFound =
        this.AddTaskCustomztnEmailAddress.nativeElement.querySelector(
          '[name="' + "divIssueCustResponsiblePersonName" + '"]'
        );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return false;
    }

    // if (!this.hasValue(this.SelectedParticpants) && this.SelectedParticpants.length <= 0) {
    //     this.ShowErrorMessage('Please Select  Participants.');
    //     this.divSelectedParticpants = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('#divSelectedParticpants');
    //     this.divSelectedParticpants.focus();
    //     return false;
    // }

    // if (!this.hasValue(this.SelectedObservers) && this.SelectedObservers.length <= 0) {
    //     this.ShowErrorMessage('Please Select Observers.');
    //     this.divSelectedObservers = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('#divSelectedObservers');
    //     this.divSelectedObservers.focus();
    //     return false;
    // }

    if (
      this.hasValue(this.selectedAdditionalResponsiblePersons) &&
      this.selectedAdditionalResponsiblePersons.length > 0
    ) {
      this.selectedAdditionalResponsiblePersons.forEach((element) => {
        this.EmployeesAndRolesModel = {
          EmployeeID: element.EmployeeId,
          RoleID: 4,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }

    if (
      this.hasValue(this.SelectedObservers) &&
      this.SelectedObservers.length > 0
    ) {
      this.SelectedObservers.forEach((element) => {
        this.EmployeesAndRolesModel = {
          EmployeeID: element.EmployeeId,
          RoleID: 3,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }
    if (this.hasValue(this.SelectedResponsiblePerson)) {
      this.EmployeesAndRolesModel = {
        EmployeeID: this.SelectedResponsiblePerson.EmployeeId,
        RoleID: 2,
      };
      this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
    }

    if (
      this.hasValue(this.SelectedAssistantPerson) &&
      this.SelectedAssistantPerson.length > 0
    ) {
      this.SelectedAssistantPerson.forEach((Assistantselement) => {
        this.EmployeesAndRolesModel = {
          EmployeeId: Assistantselement.EmployeeId,
          RoleID: 7,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }

    if (this.EmployeesAndRolesModelList.length > 0) {
      this.TaskCustomizationModel.EmployeesAndRolesModelList =
        this.EmployeesAndRolesModelList;
    }
    if (
      this.isCheckedAllUsers === false &&
      this.isCheckedAllPractices === false
    ) {
      if (
        this.hasValue(this.SelectedPracticeUserList) &&
        this.SelectedPracticeUserList.length > 0
      ) {
        this.SelectedPracticeUserList.forEach((element) => {
          this.usersmodel = {
            EHRUserIDs: element.UserID,
            EmployeeID: element.EmployeeID,
          };

          this.selectedUsersList.push(this.usersmodel);
        });
      }
    } else {
      this.selectedUsersList.push({
        EHRUserIDs: -1,
        EmployeeID: -1,
      });
    }

    if (
      this.hasValue(this.selectedUsersList) &&
      this.selectedUsersList.length > 0
    ) {
      this.TaskCustomizationModel.selectedUsersList = this.selectedUsersList;
    }
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "InsertUserCustomizationInformationByUsers",
        this.TaskCustomizationModel
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.ShowSuccesseMessage("Customization Details Saved Successfully.");
        this.dialog.close("save");
      });
  }

  // RetreiveClickEvent() {
  //   $('.Option input').each(function (i, e) {
  //     alert($(e).val());
  //   });
  // }

  // $("#Add").on("click", function() {
  //   $("#textboxDiv").append("<div><br><input type='text'/><br></div>");
  // });

  deleteUserValue(index) {
    this.SelectedPracticeUserList.splice(index, 1);
  }
  // user selection
  userIconClickEvent() {
    if (!this.hasValue(this.TaskCustomizationModel.PracticeName)) {
      this.ShowErrorMessage("Please Select Practice.");
      return false;
    }

    if(!this.isCheckedAllModule){
    if (!this.hasValue(this.TaskCustomizationModel.IssueSupportGroupID)) {
          this.ShowErrorMessage('Please Select EHR Module.');

      return false;
    }
    }

    // let senddatamodel = {};
    // senddatamodel = {
    //     PracticeId: this.TaskCustomizationModel.PracticeId,
    //     IssueSupportGroupID: this.TaskCustomizationModel.IssueSupportGroupID,
    //     UsersArray: this.SelectedPracticeUserList
    // };
    // setTimeout(() => {
    //     this.LeftNav.GetEditModeData(senddatamodel);
    // }, 10);

    const dialogRef = this.dialogService.open({
      title: "Practice Users Information",
      content: PracticeUsersCustomizationComponent, //PracticeUsersListComponent,
      height: 698,
      width: 700,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      StateMaintainUsersData: this.SelectedPracticeUserList,
      PracticeId: this.TaskCustomizationModel.PracticeId,
      IssueSupportGroupID: this.TaskCustomizationModel.IssueSupportGroupID,
    };
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.SelectedPracticeUserList = [];
        this.SelectedPracticeUserList = result;
      }
    });
  }

  deleteAllUsers() {
    if (this.SelectedPracticeUserList.length <= 0) {
      this.ShowErrorMessage("Please Select User to Delete.");
      return false;
    }

    if (this.SelectedPracticeUserList.length > 0) {
      this.SelectedPracticeUserList = [];
    }
  }
  DeletePracticeInfo() {
    if (!this.hasValue(this.TaskCustomizationModel.PracticeName)) {
      this.ShowErrorMessage("Please Select Practice Info to Delete.");
      return false;
    }

    if (this.hasValue(this.TaskCustomizationModel.PracticeName)) {
      this.TaskCustomizationModel.PracticeName = "";
      this.TaskCustomizationModel.PracticeId = undefined;
      this.SelectedPracticeUserList = [];
    }
  }


  DeleteModuleInfo() {
    if (!this.hasValue(this.TaskCustomizationModel.IssueSupportGroupDesc)) {
      this.ShowErrorMessage("Please Select EHR Module Info to Delete.");
      return false;
    }

    if (this.hasValue(this.TaskCustomizationModel.IssueSupportGroupDesc)) {
      this.TaskCustomizationModel.IssueSupportGroupDesc = "";
      this.TaskCustomizationModel.IssueSupportGroupID = undefined;

    }
  }
  supportGroupsChange(event) {
    this.SelectedPracticeUserList = [];
  }

  responsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Responsible Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      // debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedResponsiblePerson)) {
        } else {
        }
      } else {
        this.responsiblePersonInfo = result;
        if (this.hasValue(this.responsiblePersonInfo)) {
          this.SelectedResponsiblePerson.EmployeeId =
            this.responsiblePersonInfo.EmployeeId;
          this.SelectedResponsiblePerson.EmployeeFullName =
            this.responsiblePersonInfo.EmployeeFullName;
        }
      }
    });
  }

  RemoveResponsiblePersonInfo() {
    this.SelectedResponsiblePerson.EmployeeId = undefined;
    this.SelectedResponsiblePerson.EmployeeFullName = "";
  }

  UpdateModuleDetails(TaskInfoID) {
    // console.log(this.isCheckedAllModule)
    const dialogRef = this.dialogService.open({
      title: "Select Module",
      // Show component
      content: SelectModulePopupComponentComponent,
      width: 550,
      height: 680,
      appendTo: this.ContainerRef,
    });
    // dialogRef.content.instance.datatoPopup = {
    //   Value: ModuleName.trim("")!==""?ModuleName:undefined,
    //   TaskInfoID:TaskInfoID
    // };
    dialogRef.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult)) {
        if (result !== undefined) {
          let datafromPopUp:any=result;
          this.TaskCustomizationModel.IssueSupportGroupDesc=datafromPopUp.IssueSupportGroupDesc;
          this.TaskCustomizationModel.IssueSupportGroupID=datafromPopUp.IssueSupportGroupID;
          // console.log(result);
          //this.UpdateModuleData(result, TaskInfoID);
        }
      }
    });
  }

  AdditionalResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    //dialogRef.content.instance.IsFromForwardToPopup = true;
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData =
      this.selectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData =
      this.SelectedObservers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData =
      this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
        } else {
          this.SelectedAdditionalResponsiblePersonInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        //********assigning addl responsible resons info************
        this.SelectedAdditionalResponsiblePersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.selectedAdditionalResponsiblePersons =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );
          this.SelectedAdditionalResponsiblePersonName =
            this.SelectedAdditionalResponsiblePersonName.substring(
              0,
              this.SelectedAdditionalResponsiblePersonName.length - 2
            );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        //*****assigning observers data****************
        this.SelectedObserversInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.SelectedObservers =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedObserversPersonNames =
            this.SelectedObserversPersonNames.substring(
              0,
              this.SelectedObserversPersonNames.length - 2
            );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        this.SelectedAssistantPersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        this.SelectedAssistantPerson =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedAssistantPersonName =
            this.SelectedAssistantPersonName.substring(
              0,
              this.SelectedAssistantPersonName.length - 2
            );
        } else {
          this.SelectedAssistantPersonName = "";
        }
      }
    });
  }

  RemoveAdditionalResponsiblePersonInfo() {
    this.SelectedAdditionalResponsiblePersonName = "";
    this.selectedAdditionalResponsiblePersons = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }

  ObserversIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    // dialogRef.content.instance.IsFromForwardToPopup = true;
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData =
      this.selectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData =
      this.SelectedObservers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData =
      this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedObserversInfo)) {
        } else {
          this.SelectedObserversInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        //********assigning addl responsible resons info************
        this.SelectedAdditionalResponsiblePersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.selectedAdditionalResponsiblePersons =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );
          this.SelectedAdditionalResponsiblePersonName =
            this.SelectedAdditionalResponsiblePersonName.substring(
              0,
              this.SelectedAdditionalResponsiblePersonName.length - 2
            );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        //*****assigning observers data****************
        this.SelectedObserversInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.SelectedObservers =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedObserversPersonNames =
            this.SelectedObserversPersonNames.substring(
              0,
              this.SelectedObserversPersonNames.length - 2
            );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        this.SelectedAssistantPersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        this.SelectedAssistantPerson =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedAssistantPersonName =
            this.SelectedAssistantPersonName.substring(
              0,
              this.SelectedAssistantPersonName.length - 2
            );
        } else {
          this.SelectedAssistantPersonName = "";
        }
      }
    });
  }

  RemoveObserversIconClickEvent() {
    this.SelectedObserversPersonNames = "";
    this.SelectedObservers = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }

  assistantPersonIconClickEvent() {
    //this.IsresponsiblePersonAndFwdToSame = false;
    const dialogRef = this.dialogService.open({
      title: "Select Person to Forward Task",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    // dialogRef.content.instance.IsFromForwardToPopup = true;
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData =
      this.selectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData =
      this.SelectedObservers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData =
      this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;

      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
        } else {
          this.SelectedAssistantPersonInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        //********assigning addl responsible resons info************
        this.SelectedAdditionalResponsiblePersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.selectedAdditionalResponsiblePersons =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );
          this.SelectedAdditionalResponsiblePersonName =
            this.SelectedAdditionalResponsiblePersonName.substring(
              0,
              this.SelectedAdditionalResponsiblePersonName.length - 2
            );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        // //*****assigning observers data****************
        this.SelectedObserversInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.SelectedObservers =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedObserversPersonNames =
            this.SelectedObserversPersonNames.substring(
              0,
              this.SelectedObserversPersonNames.length - 2
            );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        this.SelectedAssistantPersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        this.SelectedAssistantPerson =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName +=
              selectedRow.EmployeeFullName + ", ";
          });
          this.SelectedAssistantPersonName =
            this.SelectedAssistantPersonName.substring(
              0,
              this.SelectedAssistantPersonName.length - 2
            );
        } else {
          this.SelectedAssistantPersonName = "";
        }
      }
    });
  }

  RemoveAssistantPersonInfo() {
    this.SelectedAssistantPersonName = "";
    this.SelectedAssistantPerson = undefined;
    this.IsresponsiblePersonAndFwdToSame = false;
  }
}
