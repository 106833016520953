import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { commonHelperService } from "src/app/common/common.service";
import { Config } from "src/app/config";

@Injectable()
export class PracticeUserCcPersonPopupService extends commonHelperService {
    constructor(http: HttpClient) {
        super(http);
    }

    getPracticeUsersList(postData) {
        return this.PostData(`${Config.OASAppnURL}OASPracticeUsersListForITS`, postData).pipe(((response) => {
            return response;
        }));
    }
}
