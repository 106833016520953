import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { AgGridNg2 } from 'ag-grid-angular';
import { commonHelperService } from '../common/common.service';
import { formatDate, IntlService } from '@progress/kendo-angular-intl';
import { HttpClient } from '@angular/common/http';
import { ExcelService } from '../common/excel.service';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GetContextMenuItemsParams, GridOptions, GridSizeChangedEvent, MenuItemDef } from 'ag-grid-community';
import { Config } from '../config';
import { PraticesListComponent } from '../pratices-list/pratices-list.component';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';
import { PracticeUsersListComponent } from '../practice-users-list/practice-users-list.component';
import { AddTaskTypePopupComponent } from '../add-task-type-popup/add-task-type-popup.component';
import { SelectModulePopupComponentComponent } from '../select-module-popup-component/select-module-popup-component.component';
import { TaskService } from '../services/task.service';
import { RepliedAllTicketsToCustomerService } from './replied-all-tickets-to-customer.service';

@Component({
  selector: 'app-replied-all-tickets-to-customer',
  templateUrl: './replied-all-tickets-to-customer.component.html',
  styleUrls: ['./replied-all-tickets-to-customer.component.css']
})
export class RepliedAllTicketsToCustomerComponent extends commonhelper
implements OnInit
{
  @ViewChild("repliedCustomerTicketsid") repliedCustomerTicketsid: AgGridNg2;
  @ViewChild("repliedCustomerTicketss", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;

  @ViewChild("repliedCustomerTicketss") repliedCustomerTicketss: ElementRef;
  private commonhelperobj: commonHelperService;
  public taskTypeList: any;
  FromRecords: any;
  IssueSupportGroupDesc: any;
  PrevLabelCount: any;
  NextLabelCount: any;
  RecordsPerPage: any;
  TotalCountValue: boolean = true;
  TotalCountValueIcon: boolean = false;
  TotalLabelCount: any;
  TotalLabelCountValue: any;
  fromrecordsfromcount: any;
  prevnextbuttons: boolean = true;
  ECDDate: any;
  ModuleId: any;
  ModuleName: any;
  CreatedFromDate: any;
  CreatedToDate: any;
  public CreatedToTime: any = new Date();
  public CreatedFromTime: any = new Date("Tue Apr 11 2000 00:00:00 GMT+0530");
  NextButtonDisabled: boolean = false;
  PreviousButtondisabled: boolean = false;
  public NewCurrentWorkingStatusList: any = [];
  public repliedCustomerTicketssSelectStatusDropDown = [];
  public repliedCustomerTicketssCurrentWorkingStatus: any = [];
  public repliedCustomerTicketssSelectedTaskType: any;
  public practiceInformation: any = {};
  public responsiblePersonInfo: any = {};
  assignedByInfo: any = {};
  public repliedCustomerTicktesRowData = [];
  SearchStatusIDs: any = [];
  public repliedCustomerTicketssSearchTaskNumber: any;
  public repliedCustomerTicketssSearchTaskSubject: any;
  selectedTask: any;
  clickedtab: number = 0;
  EHRPracticeID: number = 0;

  columnDefs = [
    {
      headerName: "Task #",
      field: "TaskNumber",
      width: 90,
      tooltipField: "TaskNumber",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Customer Name",
      field: "PracticeName",
      width: 180,
      tooltipField: "PracticeName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Module Name",
      field: "ModuleName",
      width: 200,
      tooltipField: "ModuleName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Task Type",
      field: "TaskType",
      width: 150,
      tooltipField: "TaskType",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Task Subject",
      field: "IssueSubject",
      width: 240,
      tooltipField: "IssueSubject",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Priority",
      field: "SeverityDesc",
      width: 90,
      tooltipField: "SeverityDesc",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Task Status",
      field: "TaskLatestStatusName",
      width: 100,
      tooltipField: "TaskLatestStatusName",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Responsible Person",
      field: "ResponsiblePersonDesc",
      width: 180,
      tooltipField: "ResponsiblePerson",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Created Date",
      field: "IssueCreatedOn",
      width: 150,
      tooltipField: "IssueCreatedOn",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
      hide: false,
      cellStyle: { textAlign: "center" },
    },

    {
      headerName: "Created By",
      field: "CreatedBy",
      width: 180,
      tooltipField: "CreatedBy",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Latest Responsed From",
      field: "LatestResponsedFrom",
      hide: false,
      minWidth: 180,
      maxWidth: 250,
      tooltipField: "Latest Responsed From",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Assigned By",
      field: "RoutingDoneBy",
      width: 130,
      tooltipField: "RoutingDoneBy",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },

    {
      headerName: "ECD",
      field: "ExpectedDate",
      minWidth: 100,
      maxWidth: 200,
      tooltipField: "ExpectedDate",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Rating",
      field: "Rating",
      width: 100,
      tooltipField: "Rating",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Reminders Sent to Customer",
      field: "CustomerReminder_Sent_Count",
      width: 150,
      tooltipField: "CustomerReminder_Sent_Count",
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
  ];
  gridApi: any;
  gridOptions: GridOptions = {};
  gridColumnApi: any;
  onrepliedCustomerTicketsidSizeChanged(params: GridSizeChangedEvent) {
    // params.api.sizeColumnsToFit();
  }
  public defaultCurrentWorkingStatusItem = {
    Task_Current_Working_Status: "-- Task Current Working Status --",
    Task_Current_Working_Status_InfoID: null,
  };
  public defaultTaskTypeItem = {
    TaskTypeDescription: "-- Search Task Type --",
    TaskTypeId: null,
  };

  constructor(
    private intl: IntlService,
    private http: HttpClient,
    private readonly _excelService: ExcelService,
    private readonly _repliedCustomerTicketssService: RepliedAllTicketsToCustomerService,
    httpClient: HttpClient,
    private taskService: TaskService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private _router: Router
  ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }

  ngOnInit() {
    this.getTaskStatusList();
    this.GetTaskTypes();
    this.GetCurrentStatusList();
    this.route.params.subscribe((params) => {
      const data = this.getCommonData(params.info);
      if (
        this.hasValue(data) &&
        this.hasValue(data.previouslySelectedSearchFilters)
      ) {
        // console.log(data.previouslySelectedSearchFilters, "data");
        this.repliedCustomerTicketssSearchTaskNumber =
          data.previouslySelectedSearchFilters.repliedCustomerTicketssSearchTaskNumber;
        this.repliedCustomerTicketssSearchTaskSubject =
          data.previouslySelectedSearchFilters.repliedCustomerTicketssSearchTaskSubject;
        this.responsiblePersonInfo =
          data.previouslySelectedSearchFilters.responsiblePersonInfo;
        this.assignedByInfo =
          data.previouslySelectedSearchFilters.assignedByInfo;
        this.practiceInformation =
          data.previouslySelectedSearchFilters.practiceInformation;
        this.FromRecords =
          data.previouslySelectedSearchFilters.StatemaintainFromRecords;
        this.SearchStatusIDs =
          data.previouslySelectedSearchFilters.SearchStatusIDs;
        this.repliedCustomerTicketssCurrentWorkingStatus =
          data.previouslySelectedSearchFilters.repliedCustomerTicketssCurrentWorkingStatus;
        this.repliedCustomerTicketssSelectedTaskType =
          data.previouslySelectedSearchFilters.repliedCustomerTicketssSelectedTaskType;
        this.CreatedFromDate =
          data.previouslySelectedSearchFilters.CreatedFromDate;
        this.CreatedToDate = data.previouslySelectedSearchFilters.CreatedToDate;
        this.CreatedFromTime =
          data.previouslySelectedSearchFilters.CreatedFromTime;
        this.CreatedToTime = data.previouslySelectedSearchFilters.CreatedToTime;
        this.ECDDate = data.previouslySelectedSearchFilters.ECDDate;
        this.ModuleId = data.previouslySelectedSearchFilters.ModuleId;
        // this.isfromStatemaintainFilter=true;
 
        this.selectedTask = data.previouslySelectedTaskNumber;
      } 
    });
    this.repliedCustomerTicketssGetMajorTopicsListClick(undefined, undefined, false);
    setTimeout(() => {
      document.getElementById("txtrepliedCustomerTicketssSearchTask").focus();
      this.statemaintainPreviousSelectedRecord();
    }, 100);
  }
  repliedCustomerTicketssExportToExcelClick(exoportoexcel) {
    this.repliedCustomerTicketssGetMajorTopicsListClick(
      undefined,
      undefined,
      exoportoexcel
    );
  }
  repliedCustomerTicketssPrevNextClick(prevornext) {
    this.repliedCustomerTicketssGetMajorTopicsListClick(
      undefined,
      prevornext,
      false
    );
  }
  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "yyyyMMdd")}` : "";
  }
  private formatDateValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "MM/dd/yyyy")}` : "";
  }

  // rowClicked(params) {
  //   console.log(params.data)
  //   if(params.data.dirty===false){
  //   params.node.setData({
  //     ...params.data,
  //     dirty: true
  //   });}
  //   else{
  //     params.node.setData({
  //       ...params.data,
  //       dirty: false
  //     });
  //   }
  // }
  getRowStyle(params) {
    // console.log(params, "params");
    if (params.data.ReadorUnread === 1) {
      return { "font-weight": "900" };
    } else {
      return { "font-weight": "500" };
    }
  }

  repliedCustomerTicketssGetMajorTopicsListClick(
    isfromsearch,
    prevornext,
    exoportoexcel
  ) {
    this.RecordsPerPage = 50;
    if (exoportoexcel == false) {
      if (
        isfromsearch == true &&
        (prevornext == false || prevornext == undefined) &&
        this.TotalCountValue == false
      ) {
        this.TotalCountValueIcon = false;
        this.TotalCountValue = true;
      }
      if (
        !this.hasValue(this.FromRecords) ||
        (this.hasValue(isfromsearch) && isfromsearch == true)
      ) {
        this.FromRecords = 1;
      }
      switch (prevornext) {
        case 1:
          //if(this.hasValue(this.PreviousButtondisabled) && this.PreviousButtondisabled==true) return;
          this.FromRecords -= this.RecordsPerPage;
          break;

        case 2:
          //if(this.hasValue(this.NextButtonDisabled) && this.NextButtonDisabled==true) return;
          this.FromRecords += this.RecordsPerPage;
          break;
        case 3:
          this.fromrecordsfromcount = this.FromRecords;
          this.FromRecords = null;
      }
    }
    let statusids = this.SearchStatusIDs.map((item) => item.TaskStatusId).join(
      ","
    );

    // console.log(this.clickedtab, "DataSTore");
    const data: any = {};
    let Responsible = "";
      data.ResponsibleUserIDs = this.hasValue(
        this.responsiblePersonInfo.EmployeeId
      )
        ? this.responsiblePersonInfo.EmployeeId
        : undefined;
  
      data.ResponsibleUserID = this.hasValue(
        this.responsiblePersonInfo.EmployeeId
      )
        ? this.responsiblePersonInfo.EmployeeId
        : undefined;
    
    // console.log(Responsible,this.clickedtab,"test")
    // this.clickedtab===1?data.ResponsibleUserID:data.ResponsibleUserIDs
    if (this.applyclassesforTasksCreatedDatesSelected(data) == false) return;

    (data.FromRecords = this.hasValue(this.FromRecords)
      ? this.FromRecords
      : undefined),
      (data.TaskNumber = this.repliedCustomerTicketssSearchTaskNumber),
      (data.IssueSubject = this.repliedCustomerTicketssSearchTaskSubject),
      (data.TaskLatestStatusList = this.hasValue(statusids)
        ? statusids
        : undefined),
      (data.SupportGroupIDs = [this.ModuleId]),
      (data.SupportGroupID = this.ModuleId),
      (data.RoutingDoneBy = this.hasValue(this.assignedByInfo.EmployeeId)
        ? this.assignedByInfo.EmployeeId
        : undefined),
      (data.PracticeIDsList = this.hasValue(this.practiceInformation.PracticeId)
        ? this.practiceInformation.PracticeId.toString()
        : undefined),
      (data.TaskCurrentWorkingStatusInfoIDs = this.hasValue(
        this.repliedCustomerTicketssCurrentWorkingStatus
      )
        ? [this.repliedCustomerTicketssCurrentWorkingStatus]
        : []),
      (data.TaskTypeId = this.repliedCustomerTicketssSelectedTaskType),
      (data.NeedtoShowAllTasks = 1),
      (data.ExpectedDate = this.hasValue(this.ECDDate)
        ? this.formatValue(this.ECDDate)
        : null);

    if (exoportoexcel == true) {
      let CallingCommonHelperOBj: any;
   
        (data.IsTaskCreatedEHR = true),
          (CallingCommonHelperOBj = this.commonhelperobj.PostData(
            Config.OASOrganizationAppnURL + "EmployeeRepliedTaskListNew",
            data
          ));  
        
      CallingCommonHelperOBj.subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        // console.log(serviceResponse);
        if (
          this.hasValue(serviceResponse) &&
          this.hasValue(serviceResponse.EmployeeTaskListModelList) &&
          serviceResponse.EmployeeTaskListModelList.length > 0
        ) {
          this._excelService.exportAsExcelFile(
            this._repliedCustomerTicketssService.ExcelColumnsInfo,
            serviceResponse.EmployeeTaskListModelList,
          
              "RepliedCustomerTicketssReport"
              
          );
          //  window.open(serviceResponse.AttachmentsURL);
        }
      });
      return;
    }

    let CallingAPi: any;
        (CallingAPi = this.taskService.getRepliedtaskList(data));
    
    CallingAPi.subscribe((response) => {
      if (this.commonhelperobj.isError(response)) {
        return;
      }
      if (
        this.hasValue(response) &&
        this.hasValue(response.Count) &&
        response.Count > 0
      ) {
        this.TotalCountValueIcon = true;
        this.TotalCountValue = false;
        this.TotalLabelCount = response.Count;
        console.log(this.TotalCountValue)
        this.FromRecords = this.fromrecordsfromcount;
        return;
      }
      response.EmployeeTaskListModelList.forEach((TaskListelement) => {
        if (this.hasValue(TaskListelement.TaskUsersInfo)) {
          const SplittedUserData = TaskListelement.TaskUsersInfo.split("],");
          if (this.hasValue(SplittedUserData) && SplittedUserData.length > 0) {
            //TaskListelement.ParticipantsDesc = "";
            TaskListelement.AssistantsDesc = "";
            // tslint:disable-next-line:no-shadowed-variable
            SplittedUserData.forEach((element) => {
              const getUserRolesData = element.split("|");
              if (
                this.hasValue(getUserRolesData) &&
                getUserRolesData.length > 0
              ) {
                if (getUserRolesData[2] === "2") {
                  TaskListelement.ResponsiblePersonDesc = getUserRolesData[1];
                }
                if (getUserRolesData[2] === "7") {
                  // if (!this.hasValue(TaskListelement.ParticipantsDesc)) {
                  //   TaskListelement.ParticipantsDesc = "";
                  // }
                  // TaskListelement.ParticipantsDesc += getUserRolesData[1] + ', ';
                  TaskListelement.AssistantsDesc += getUserRolesData[1] + ", ";
                }
              }
            });
            // TaskListelement.ParticipantsDesc = TaskListelement.ParticipantsDesc.substring(0, TaskListelement.ParticipantsDesc.length - 2);
            TaskListelement.AssistantsDesc =
              TaskListelement.AssistantsDesc.substring(
                0,
                TaskListelement.AssistantsDesc.length - 2
              );
          }
        }
      });
      this.repliedCustomerTicktesRowData = response.EmployeeTaskListModelList;

      this.PrevLabelCount = this.FromRecords;
      if (this.FromRecords == 1) {
        this.NextLabelCount = response.EmployeeTaskListModelList.length;
      } else {
        this.NextLabelCount =
          this.FromRecords + response.EmployeeTaskListModelList.length - 1;
      }
      if (
        this.FromRecords == 1 &&
        response.EmployeeTaskListModelList.length < this.RecordsPerPage
      ) {
        this.prevnextbuttons = true;
        this.TotalLabelCountValue = response.EmployeeTaskListModelList.length;
      } else if (
        this.FromRecords == 1 &&
        response.EmployeeTaskListModelList.length == this.RecordsPerPage
      ) {
        this.prevnextbuttons = false;
        this.PreviousButtondisabled = true;
        this.NextButtonDisabled = false;
      } else if (
        response.EmployeeTaskListModelList.length < this.RecordsPerPage
      ) {
        this.prevnextbuttons = false;
        this.NextButtonDisabled = true;
        this.PreviousButtondisabled = false;
      } else {
        this.prevnextbuttons = false;
        this.PreviousButtondisabled = false;
        this.NextButtonDisabled = false;
      }
      //this.statemaintainPreviousSelectedRecord();
    });
  }
  private GetCurrentStatusList() {
    var PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "NewCurrentWorkingStatusList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.NewCurrentWorkingStatusList =
          serviceResponse.newCurrentWorkingStatusListModelList;
      });
  }
  private GetTaskTypes() {
    this.taskService.taskTypeList().subscribe((response) => {
      if (this.isError(response)) {
        return;
      }
      this.taskTypeList = response;
    });
  }
  private getTaskStatusList() {
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "TaskStatusList", {})
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }

        this.repliedCustomerTicketssSelectStatusDropDown = [];

        response.forEach((element) => {
          if (element.TaskStatusId != 4) {
            this.repliedCustomerTicketssSelectStatusDropDown.push({
              TaskDescription: element.TaskDescription,
              TaskStatusId: element.TaskStatusId,
            });
          }
        });
      });
  }

  OnclickMarkasReadorUnReadFN(selectedID, isTrueorFalse, isRefresh) {
    let payload = {
      TaskInfoID: selectedID,
      TicketReadorUnread: isTrueorFalse,
    };
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "UpdatedTicketReadandUnReadStatus", payload)
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
        if (isRefresh === true) {
          this.repliedCustomerTicketssGetMajorTopicsListClick(
            undefined,
            undefined,
            false
          );
        }
      });
  }

  OnClickOpenandClose(isTrueorFalse) {
    let selectedTask = this.getSelectedTaskInfo();
    if (selectedTask.ReadorUnread !== isTrueorFalse)
      this.OnclickMarkasReadorUnReadFN(
        selectedTask.TaskInfoID,
        isTrueorFalse,
        true
      );
  }

  repliedCustomerTicketssViewClickEvent() {
    let selectedTask = this.getSelectedTaskInfo();

    //This Function is used for Mark As Read
    // console.log(selectedTask.ReadorUnread,"read")
    if (selectedTask.ReadorUnread === 1) {
      this.OnclickMarkasReadorUnReadFN(selectedTask.TaskInfoID, false, false);
    }

    if (!this.hasValue(selectedTask) || selectedTask <= 0) return;
    const PostDataService: any = {
      IsFromEdit: true,
      editmodedata: selectedTask,
      isfrom: 13, //by all customertickets
      selectedTicketsList: this.repliedCustomerTicktesRowData,
    };
    // if (this.isFromSetByMe === true) {
    //   PostDataService.IsFromSetByMe = true;
    // }
    PostDataService.StatemaintainPreviouslySearchFilters = {
      repliedCustomerTicketssSearchTaskNumber:
        this.repliedCustomerTicketssSearchTaskNumber,
      repliedCustomerTicketssSearchTaskSubject:
        this.repliedCustomerTicketssSearchTaskSubject,
      responsiblePersonInfo: this.responsiblePersonInfo,
      assignedByInfo: this.assignedByInfo,
      practiceInformation: this.practiceInformation,
      StatemaintainFromRecords: this.FromRecords,
      SearchStatusIDs: this.SearchStatusIDs,
      repliedCustomerTicketssSelectedTaskType:
        this.repliedCustomerTicketssSelectedTaskType,
      repliedCustomerTicketssCurrentWorkingStatus:
        this.repliedCustomerTicketssCurrentWorkingStatus,
      CreatedFromDate: this.CreatedFromDate,
      CreatedToDate: this.CreatedToDate,
      ECDDate: this.ECDDate,
      ModuleId: this.ModuleId,
      CreatedToTime: this.CreatedToTime,
      CreatedFromTime: this.CreatedFromTime,
    };
    const outletObj = {};
    outletObj["main"] = [
      "app-view-full-task-information",
      this.setCommonData(PostDataService),
    ];
    this._router.navigate(["/home", { outlets: outletObj }]);
  }

  getSelectedTaskInfo() {
    const selectedrow = this.repliedCustomerTicketsid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.ShowErrorMessage("Please Select Task.");
      document.getElementById("myAppointmentsSelectUser").focus();
      return 0;
    }
    return selectedrow[0];
  }

  repliedCustomerTicketssSelectPracticeIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Pratice",
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.practiceInformation = result;
        // console.log(this.practiceInformation);
        this.EHRPracticeID = this.practiceInformation.PracticeId;
      }
    });
  }
  
  repliedCustomerTicketssRemoveSelectedPracticeClickEvent(isNumber) {
    // console.log(isNumber);
    if (isNumber === 1) {
      this.practiceInformation = {};
      this.EHRPracticeID = 0;
    }
  }
  repliedCustomerTicketssRemoveSelectedDatesClickEvent(number) {
    if (number === 1) {
      this.CreatedFromDate = "";
      this.CreatedFromTime = "";
    } else if (number === 2) {
      this.CreatedToDate = "";
      this.CreatedToTime = "";
    } else {
      this.ECDDate = "";
    }
  }
  repliedCustomerTicketssSelectResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      FromInternalTickets: true,
      IsSelectionType: "multiple",
    };
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        let responsiblePersonInfo: any = result;
        this.responsiblePersonInfo.EmployeeId = [];
        this.responsiblePersonInfo.EmployeeFullName = "";
        responsiblePersonInfo.forEach((ele) => {
          this.responsiblePersonInfo.EmployeeFullName =
            this.responsiblePersonInfo.EmployeeFullName +
            "," +
            ele.EmployeeFullName;
          this.responsiblePersonInfo.EmployeeId.push(ele.EmployeeId);
        });
      }
    });
  }
  repliedCustomerTicketssRemoveSelectedResponsiblePersonClickEvent(isnumber) {
    if (isnumber === 1) this.responsiblePersonInfo = {};
  }
  statemaintainPreviousSelectedRecord() {
    let seletedtasknumber = this.selectedTask;
    let storeGridInfo = this.repliedCustomerTicketsid.api;
    setTimeout(() => {
      this.repliedCustomerTicketsid.api.forEachLeafNode(function (node) {
        if (node.data.TaskNumber == seletedtasknumber) {
          node.setSelected(true);
          //Moving Scroll bar to Selected Node
          storeGridInfo.ensureIndexVisible(node.rowIndex + 1);
        }
      });
    }, 500);
  }
  repliedCustomerTicketssSelectAssignedByIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Assigned By",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.assignedByInfo = result;
      }
    });
  }
  repliedCustomerTicketssRemoveSelectedAssignedByClickEvent() {
    this.assignedByInfo = {};
  }


  //Added By Suman
  tasksCreatedFromDateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedFromDate,
        "CreatedFromDate",
        true
      )
    )
      return false;
  }

  tasksCreatedTODateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedToDate,
        "CreatedToDate",
        false
      )
    )
      return false;
  }

  createdFromDateToDateValidations(
    modifiedfromandtoDate: Date,
    name: string,
    isfrom: boolean
  ) {
    var today = new Date();
    var toDate = new Date(modifiedfromandtoDate);
    var showfocuselement = false;
    const daysDifference = this.DateDiffInDays(
      isfrom ? this.CreatedToDate : today,
      isfrom ? this.CreatedFromDate : this.CreatedToDate
    );
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage("Invalid Day, Month or Year Range Detected.");
      showfocuselement = true;
    }
    if (this.hasValue(daysDifference) && daysDifference < 0) {
      if (isfrom) {
        this.ShowErrorMessage(
          "Created From Date Should be Less Than or Equal to Created To Date"
        );
        this.CreatedFromDate = "";
      } else {
        this.CreatedToDate = "";
        this.ShowErrorMessage("Future Dates Not allowed");
      }
      showfocuselement = true;
    }
    if (showfocuselement) {
      const elementFound = this.repliedCustomerTicketss.nativeElement.querySelector(
        '[name="' + name + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    return true;
  }

  private applyclassesforTasksCreatedDatesSelected(data: any) {
    if (this.hasValue(this.CreatedFromDate)) {
      if (!this.hasValue(this.CreatedToDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created To Date.");
        return false;
      }
    }
    if (this.hasValue(this.CreatedToDate)) {
      if (!this.hasValue(this.CreatedFromDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created From Date.");
        return false;
      }
    }
    if (this.hasValue(this.CreatedFromDate)) {
      // let fromTime = this.CreatedFromTime
      //   ? formatDate(this.CreatedFromTime, "HH:mm:ss", "en-US", "+0530")
      //   : "00:00";
      data.createdFromDate =
        this.formatDateValue(this.CreatedFromDate) ;//+ " " + fromTime;
    } else {
      data.createdFromDate = "";
    }
    if (this.hasValue(this.CreatedToDate)) {
      // let toTime = this.CreatedToTime
      //   ? formatDate(this.CreatedToTime, "HH:mm:ss", "en-US", "+0530")
      //   : "00:00";
      data.createdToDate =
        this.formatDateValue(this.CreatedToDate);// + " " + toTime;
    } else {
      data.createdToDate = "";
    }
    if (this.tasksCreatedFromDateChange() == false) return false;
    if (this.tasksCreatedTODateChange() == false) return false;
  }

  getContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    // console.log(params)
    if (
      params.node.data.TaskLatestStatusName === "Yet to Start" ||
      params.node.data.TaskLatestStatusName === "On Going"
    ) {
      var result: (string | MenuItemDef)[] = [
        {
          // custom item
          name: "Update Task Type",
          action: function () {
            params.context.UpdateTaskTypeDetails(
              params.node.data.TaskType,
              params.node.data.TaskInfoID
            );
          },

          cssClasses: ["redFont", "bold"],
        },
        {
          // custom item
          name: "Change Module Name",
          action: function () {
            params.context.UpdateModuleDetails(params.node.data.TaskInfoID);
          },

          cssClasses: ["redFont", "bold"],
        },
      ];
    }
    return result;
  }

  OnBtnClickUpdateTask() {
    let selectedrow: any = this.repliedCustomerTicketsid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.ShowErrorMessage("Please Select Task.");
      document.getElementById("txtCustomerTicketsSearchTask").focus();
      return;
    }
    // console.log(selectedrow, "SelectedRow");

    this.UpdateTaskTypeDetails(
      selectedrow[0].TaskType,
      selectedrow[0].TaskInfoID
    );
  }

  OnBtnClickUpdateModule() {
    const selectedrow = this.repliedCustomerTicketsid.api.getSelectedRows();
    if (!this.hasValue(selectedrow)) {
      this.ShowErrorMessage("Please Select Task.");
      document.getElementById("txtCustomerTicketsSearchTask").focus();
      return;
    }
    this.UpdateModuleDetails(selectedrow[0].TaskInfoID);
  }

  UpdateTaskTypeDetails(TaskName, TaskInfoID) {
    const dialogRef = this.dialogService.open({
      title: "Update Task Type",
      // Show component
      content: AddTaskTypePopupComponent,
      width: 400,
      height: 300,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      Value: TaskName.trim("") !== "" ? TaskName : undefined,
      TaskInfoID: TaskInfoID,
    };
    dialogRef.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult)) {
        if (result !== undefined) {
          this.repliedCustomerTicketssGetMajorTopicsListClick(
            true,
            undefined,
            false
          );
        }
      }
    });
  }

  UpdateModuleDetails(TaskInfoID) {
    const dialogRef = this.dialogService.open({
      title: "Change Module Name",
      // Show component
      content: SelectModulePopupComponentComponent,
      width: 550,
      height: 680,
      appendTo: this.ContainerRef,
    });
    // dialogRef.content.instance.datatoPopup = {
    //   Value: ModuleName.trim("")!==""?ModuleName:undefined,
    //   TaskInfoID:TaskInfoID
    // };
    dialogRef.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult)) {
        if (result !== undefined) {
          // console.log(result);
          this.UpdateModuleData(result, TaskInfoID);
        }
      }
    });
  }

  UpdateModuleData(SelectedItem: any, TaskInfoID: number) {
    let Payload = {
      ModuleID: SelectedItem.IssueSupportGroupID,
      TaskInfoID: TaskInfoID,
    };

    this.commonhelperobj
      .PostData(Config.OASOrganizationAppnURL + "UpdateModuleDetails", Payload)
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          this.ShowErrorMessage(serviceResponse);
          return;
        } else {
          this.repliedCustomerTicketssGetMajorTopicsListClick(
            true,
            undefined,
            false
          );
        }
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridOptions.context = this;
  }

  CreateTaskmoduleIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Module",
      // Show component
      content: SelectModulePopupComponentComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.IssueSupportGroupDesc)) {
        } else {
          this.IssueSupportGroupDesc = "";
        }
      } else {
        let moduleDetails: any = result;
        if (this.hasValue(result)) {
          this.ModuleId = moduleDetails.IssueSupportGroupID;
          this.ModuleName = moduleDetails.IssueSupportGroupDesc;

          // console.log(this.ModuleId);
        }
      }
    });
  }
  CreateTaskRemoveModuleInfo() {
    this.ModuleId = undefined;
    this.ModuleName = "";
    document.getElementById("spanSelectModuleIcon").focus();
  }
}