import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';
import { commonhelper } from '../common/commonhelper';
import { GridOptions, GridSizeChangedEvent } from 'ag-grid-community';
import { AgGridNg2 } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { commonHelperService } from '../common/common.service';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee } from '../edit-task-customization-based-on-users/edit-task-customization-based-on-users.component';
import { TaskService } from '../services/task.service';
import { DialogCloseResult, DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { AgGridModule } from 'ag-grid-angular';
import { AddEmployeeInfoPopupComponent } from '../add-employee-info-popup/add-employee-info-popup.component';
import { OasMainDashboardComponentComponent } from '../oas-main-dashboard-component/oas-main-dashboard-component.component';
import { MonthSummaryReportComponent } from '../month-summary-report/month-summary-report.component';
// import { IntlService } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-employee-info-component',
  templateUrl: './employee-info-component.component.html',
  styleUrls: ['./employee-info-component.component.css']
})
export class EmployeeInfoComponentComponent extends commonhelper implements OnInit {
  @ViewChild('EmployeeInformation') EmployeeInformation: AgGridNg2
  @ViewChild('agGrid') agGrid: AgGridNg2;
  public gridOptions: GridOptions
  public overlayNoRowsTemplate;
  public gridApi;
  public gridColumnApi;
  selectedRow: any;
  datasource: any;
  public SelectedEmployeeAccessInformation: any;
  public Employeeinformationmodelist: any;
  employeeInformationLastName: any;
  employeeInformationFirstName: any;
  employeeInformationEmail: any;
  private commonhelperobj: commonHelperService;
  public selectedRowsString = undefined;
  constructor(private route: ActivatedRoute, private http: HttpClient, httpClient: HttpClient,
    private _Router: Router, private _router: Router,
    private taskService: TaskService,
    private dialogService: DialogService,private oasMainDashboardComponentComponent:OasMainDashboardComponentComponent ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
    this.overlayNoRowsTemplate = '<span style=\'Font-size:16px;color:#00529B;background-color:#BDE5F8;margin: 50px 0px;padding: 12px;border-radius: 4px;\'>No Data Available</span>';
    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this
      }
 
    }
  }

  title = 'app';
  rowData: any;

  // tslint:disable-next-line:member-ordering
  columnDefs = [
    { headerName: 'Last Name', field: 'LastName', width: 261, tooltipField: 'LastName', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'First Name', field: 'FirstName', width: 289, tooltipField: 'FirstName', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'DOB', field: 'DateOfBirth', width: 200, tooltipField: 'DateOfBirth', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Gender', field: 'GenderDescription', width: 200, tooltipField: 'GenderDescription', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Designation', field: 'Designation', width: 272, tooltipField: 'Designation', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Email Address', field: 'ProfessionalEmailAddress', width: 358, tooltipField: 'ProfessionalEmailAddress', cellClass: 'no-border cell-wrap-text', autoHeight: true }
  ];
  onEmployeeInfoGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }


  // rowData = [
  //   // tslint:disable-next-line:max-line-length
  //   { EmployeeName: 'Anusha', DOB: '22/09/1993', EmailID: 'anusha.chiruvolu@adaptamed.org', Designation: 'Software Programmer', EmployeeFirstName: 'Chiruvolu', Gender: 'Female' },
  //   // tslint:disable-next-line:max-line-length
  //   { EmployeeName: 'Lakshmi', DOB: '22/09/1992', EmailID: 'lakshmi.bathina@adaptamed.com', Designation: 'Software Programmer', EmployeeFirstName: 'Bathina', Gender: 'Female' },
  //   // tslint:disable-next-line:max-line-length
  //   { EmployeeName: 'Sanjay', DOB: '22/09/1996', EmailID: 'Sanjay@adaptamed.com', Designation: 'Software Programmer', EmployeeFirstName: 'Idpuganti', Gender: 'Male' },
  // ];

  ngOnInit() {
    // fetch('https://api.myjson.com/bins/15psn9')

    this.rowData = this.http.get('https://api.myjson.com/bins/15psn9');

    //this.agGrid.gridOptions.rowModelType = 'infinite';
    this.gridOptions = <GridOptions>{};

    this.gridOptions = {
      //paginationAutoPageSize: true
    };
    this.GetEmployeeAddInfoList();
    // this.gridOptions.datasource = this.http.get('https://api.myjson.com/bins/15psn9')
    $("#divEmployeeInformationLastName").focus();

  }
  onSelectionChanged(event) {
    //   const selectedRows = this.agGrid.api.getSelectedRows();
    //   let selectedRowsString = '';
    //   selectedRows.forEach((selectedRow, index) => {
    //     if (index !== 0) {
    //       selectedRowsString += ', ';
    //     }
    //     selectedRowsString += selectedRow.make;
    //   });
    //   if (selectedRows.length >= 5) {
    //     selectedRowsString += ' - and ' + (selectedRows.length - 5) + ' others';
    //   }
    //   // this.selectedRow = selectedRows;

    //   document.querySelector('#selectedRows').innerHTML = selectedRowsString;
    // }

    const selectedRows = this.agGrid.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;

    });
  }


  onRowSelected(event) {
    // window.alert('row ' + event.node.data.make + ' selected = ' + event.node.selected);
  }
  public GetEmployeeAddInfoList() {

    var PostDataToService = {
      LastName: this.employeeInformationLastName,
      FirstName: this.employeeInformationFirstName,
      ProfessionalEmailAddress: this.employeeInformationEmail

    };
    this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'EmployeesInformationList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.rowData = serviceResponse.EmployeeInformationModelList ;
      this.gridOptions.api.sizeColumnsToFit();
      this.Employeeinformationmodelist = serviceResponse.EmployeeInformationModelList;
    });

  }

  employeeInfoAddClick() {
    const dialogRef = this.dialogService.open({

      title: 'Employee Info',
      // Show component
      content: AddEmployeeInfoPopupComponent,
      height: 698,
      width: 700

    });
    // dialogRef.content.instance.SelectedEmployeeListInformation = this.rowData ;
    dialogRef.content.instance.SelectedEmployeeEditInformation = "";
    dialogRef.content.instance.EmployeeInformationModellist = this.Employeeinformationmodelist
    dialogRef.result.subscribe((result) => {
      this.GetEmployeeAddInfoList();
      this.selectedRowsString = "";
    });
  }
  employeeInfoEditClick() {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage('Please Select to Edit');
      return false;
    }
    const dialogRef = this.dialogService.open({
      title: 'Employee Info',
      // Show component
      content: AddEmployeeInfoPopupComponent,
      height: 698,
      width: 700
    });
    dialogRef.content.instance.DisabledButton = false;
    dialogRef.content.instance.SelectedEmployeeEditInformation = this.selectedRowsString;
    dialogRef.result.subscribe((result) => {
      this.GetEmployeeAddInfoList();
      this.selectedRowsString = "";
    });
  }
  employeeInfoDeleteClick() {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage('Please Select to Delete');
      // setTimeout(function () {
      //   document.getElementById("txtTaskCustomizationUserName").focus();
      // }, 20);
      return;
    }
    // if (this.hasValue(this.usersListInfo) && !this.hasValue(this.usersCustomizationListInfo)) {
    //   this.ShowErrorMessage('Please Select User to Delete Customization.');
    //   setTimeout(function () {
    //     document.getElementById("txtTaskCustomizationUserName").focus();
    //   }, 20);
    //   return;
    // }
    // if (this.hasValue(this.selectedRowsString)) {
    //   this.ShowErrorMessage('Are U Sure to Delete');

    //   return;
    // }
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please Confirm',
      content: 'Are You Sure to Delete Employee Information?',
      actions: [
        { text: 'No' },
        { text: 'Yes', primary: true }
      ],
      width: 450,
      height: 250,
      minWidth: 250
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        //console.log('close');
      } else {
        if (this.hasValue(result)) {
          if (result.text === 'No') {
            return;
          } else {

            const postData = {
              EmployeeInfoID: this.selectedRowsString.EmployeeInfoID,
              // IssueSupportGroupID: '2018090706023475'
            };
            this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'DeleteEmployeeInformation', postData).subscribe(serviceResponse => {
              if (this.isError(serviceResponse)) { return; }
              this.ShowSuccesseMessage('Deleted Successfully.');
              // this.GetTaskCustomizationListBasedOnUserNames();
              this.GetEmployeeAddInfoList();
              this.selectedRowsString = "";
            });
          }
        }

      }

    });

    // const postData = {
    //   EmployeeInfoID: this.selectedRowsString.EmployeeInfoID,
    //   // IssueSupportGroupID: '2018090706023475'
    // };
    // // tslint:disable-next-line:max-line-length
    // this.commonhelperobj.PostData(Config.OASEmployeesInformationAppnURL + 'DeleteEmployeeInformation', postData).subscribe(serviceResponse => {
    //   if (this.isError(serviceResponse)) { return; }
    //   this.ShowSuccesseMessage('Deleted Successfully.');
    //   // this.GetTaskCustomizationListBasedOnUserNames();
    //   this.GetEmployeeAddInfoList();
    //   this.selectedRowsString="";
    // });
  }
  EmployeeInformationEditAccessDetailsClick() {

    
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage("Please Select Employee to View Access Details");
      return;
    }
    const dataToRoute = {
      data: this.selectedRowsString,
    };

    

    this.oasMainDashboardComponentComponent.LoadAccessInfoFromEmployeeForm(dataToRoute)


    // const outletObj = {};
    // outletObj['main'] = ['access-information.component', this.setCommonData(dataToRoute)];
    // this._router.navigate(['/home', { outlets: outletObj }]);
    // this.selectedRowsString = undefined;
    //  this._Router.navigate(['/home', { outlets: { 'main': 'access-information.component' }}]);
    //  this.selectedRowsString = undefined;



  }

  EmployeeInformationMonthSummaryReportClick(){
    const outletObj = {};
    this._Router.navigate(['/home', { outlets: { 'main': 'month-summary-report.component' } }]);
  }
} 
