import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-message-info',
  templateUrl: './common-message-info.component.html',
  styleUrls: ['./common-message-info.component.css']
})
export class CommonMessageInfoComponent implements OnInit {
  public commonInformationMessage: string;
  constructor() { }
  ngOnInit() {
    this.commonInformationMessage = 'CLICK ON LEFT SIDE FOLDERS LIST';
  }

}
