import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-by-sub-topics',
  templateUrl: './by-sub-topics.component.html',
  styleUrls: ['./by-sub-topics.component.css']
})
export class BySubTopicsComponent implements OnInit {
  columnDefs = [{ field: "Sub Topics Name" }, { field: "Sub Topics ID" }, { field: "Sub Topics Status" }];
  
  rowData = [
  ];
  constructor() { }

  ngOnInit() {
  }

}
