
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { config } from 'rxjs';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';



@Injectable()
export class CustomerCallLogViewCommentsPopupService extends commonHelperService {
    constructor(http: HttpClient) {
        super(http);
    }
    
    CallLogsCommentsList(postData: any) {
        return this.PostData(Config.OASCallLogsInformationAppnURL + 'CallLogsCommentsList', postData).pipe(((response) => {
            return response;
        }));
    }
    UpdateCallLogsStatusComments(postData: any){
        return this.PostData(Config.OASCallLogsInformationAppnURL + 'UpdateCallLogsStatusComments', postData).pipe(((response) => {
            return response;
        }));
    }
    GetCallLogsCommentInfo(postData: any){
        return this.PostData(Config.OASCallLogsInformationAppnURL + 'GetCallLogsCommentInfo', postData).pipe(((response) => {
            return response;
        }));
    }
    DeleteCallLogsCommentInfo(postData: any){
        return this.PostData(Config.OASCallLogsInformationAppnURL + 'DeleteCallLogsCommentInfo', postData).pipe(((response) => {
            return response;
        }));
    }
}

