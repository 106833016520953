import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';


@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // //console.log('Request intercepted...');

        // const authReq = req.clone(
        //     {
        //         headers: req.headers.set("Org", "KJSystems")
        //     }
        // );

        // console.log("Sending request with new header now ...");

        // const element = $('#divRowAjaxBusyIndicator');
        // element.show();
        // element.addClass('busyloaderadded');
        // send the newly created request
        return next.handle(req).catch((error, caught) => {
            // intercept the respons error and displace it to the console
            // console.log("Error Occurred");
            // console.log(error);
            // return the error to the method that called it
            return Observable.throw(error);

        }).finally(() => {
            // element.hide();
            // element.removeClass('busyloaderadded');
        }) as any;
    }


    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //     const element = $('#divRowAjaxBusyIndicator');
    //     element.show();
    //     element.addClass('busyloaderadded');

    //     return next.handle(request).do((event: HttpEvent<any>) => {
    //         element.hide();
    //         element.removeClass('busyloaderadded');
    //         if (event instanceof HttpResponse) {
    //             // do stuff with response if you want
    //             return Observable.create(event);
    //         }
    //     }, (err: any) => {
    //         element.hide();
    //         element.removeClass('busyloaderadded');
    //         if (err instanceof HttpErrorResponse) {
    //             if (err.status === 401) {
    //                 // this.auth.collectFailedRequest(request);

    //                 return Observable.throw(err);
    //             }
    //         }

    //     });
    // }

}
