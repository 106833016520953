import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { AgGridNg2 } from 'ag-grid-angular';
import { AddPracticeCheckListItemPopupComponent } from '../add-practice-check-list-item-popup/add-practice-check-list-item-popup.component';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';
@Component({
  selector: 'app-practice-status-check-list-items-popup',
  templateUrl: './practice-status-check-list-items-popup.component.html',
  styleUrls: ['./practice-status-check-list-items-popup.component.css']
})
export class PracticeStatusCheckListItemsPopupComponent extends commonhelper implements OnInit {
  @ViewChild('practiceStatusCheckListItemsPopupGrid') practiceStatusCheckListItemsPopupGrid: AgGridNg2;
  private commonhelperobj: commonHelperService;
  public rowData=[]
  columnDefs = [
    { headerName: 'Check List Category', field: 'CheckListItemCategoryName', tooltipField: 'CheckListItemCategoryName', width: 180, suppressNavigable: true, cellClass: 'cell-wrap-text', autoHeight: true },
    { headerName: 'Check List Name', field: 'CheckListItemName',  tooltipField: 'CheckListItemName',width:500, suppressNavigable: true, cellClass: 'cell-wrap-text', autoHeight: true },
    { headerName: 'Effective Start Date', field: 'CheckListItemEffectiveStartDate', tooltipField: 'CheckListItemEffectiveStartDate', width: 200, suppressNavigable: true, cellClass: 'cell-wrap-text', autoHeight: true },
    { headerName: 'Effective End Date', field: 'CheckListItemEffectiveEndDate', tooltipField: 'CheckListItemEffectiveEndDate', width: 200, suppressNavigable: true, cellClass: 'cell-wrap-text', autoHeight: true },
   ];
   opracticeStatusCheckListItemsPopupGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
   @ViewChild("PracticeStatusCheckListItemsPopup", { read: ViewContainerRef })
   ContainerRef: ViewContainerRef;
   practiceCheckListItemsPopupSearchCheckListName:any;
   public addpracticeCheckListCategoryItemsList=[];
   CheckListItemCategoryInfoID:any;
  constructor(private http: HttpClient,private dialogService: DialogService,httpClient: HttpClient, private dialog: DialogRef,) {super();
    this.commonhelperobj = new commonHelperService(httpClient); }
    public defaultaddpracticeCheckListCategoryItem = { CheckListItemCategoryName: '-- Check List Category --', CheckListItemCategoryInfoID: null };
  
    ngOnInit() {
     this.getPracticeStatusCheckListItemsMasterList();
     this.GetCheckListCategoriesList();
    
  }
  private GetCheckListCategoriesList() {
    this.commonhelperobj.PostData(Config.OASPracticeCheckListItemsAppnURL + 'GetPracticeMasterCheckListCategoriesList', {}).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.addpracticeCheckListCategoryItemsList = serviceResponse.practiceMasterCheckListItemsOutputModels;
    });


  }
  getPracticeStatusCheckListItemsMasterList(){
   
    const PostDataToService={
      CheckListItemName:this.practiceCheckListItemsPopupSearchCheckListName,
      CheckListItemCategoryInfoID:this.CheckListItemCategoryInfoID,
    }
    return this.commonhelperobj.PostData(Config.OASPracticeCheckListItemsAppnURL + 'GetPracticeMasterCheckListItemsList',PostDataToService)
    .subscribe(response => {
      if (this.isError(response)) {
        return;
      }
      this.rowData = response.practiceCheckMasterListItemsOutputModels;
      setTimeout(function () {
        document.getElementById("divpracticeCheckListItemsPopupSearchCheckListName").focus();
        },20);
    });
  
  }
  getSelectedItem(){
    const selectedrow= this.practiceStatusCheckListItemsPopupGrid.api.getSelectedRows();
    if(!this.hasValue(selectedrow) || !this.hasValue(selectedrow[0].CheckListItemInfoID)||selectedrow[0].CheckListItemInfoID<=0){
      this.ShowErrorMessage("Please Select Check List Item.");
      return 0;
    }
    return  selectedrow[0].CheckListItemInfoID;
  }
  practiceStatusCheckListItemsPopupCancelClickEvent() {
    this.dialog.close();
  }
  practiceStatusCheckListItemsPopupAddClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Add Practice Check List Item',
      // Show component
      content: AddPracticeCheckListItemPopupComponent,
      height: 450,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
      this.ShowSuccesseMessage("Check List Item Saved Successfully");
      this.getPracticeStatusCheckListItemsMasterList();
      }
    }
    });
  }
  practiceStatusCheckListItemsPopupEditClickEvent() {
    let checkListItemId=0;
    if (!(checkListItemId = this.getSelectedItem())) return;
    const dialogRef = this.dialogService.open({
      title: 'Edit Practice Check List Item',
      // Show component
      content: AddPracticeCheckListItemPopupComponent,
      height: 450,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.checkListItemInfoId = checkListItemId;
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        this.getPracticeStatusCheckListItemsMasterList();
      }
      else {
        if (this.hasValue(result)) {
      this.ShowSuccesseMessage("check List Item Updated Successfully");
      this.getPracticeStatusCheckListItemsMasterList();
      }
    }
    });
  }
  practiceStatusCheckListItemsPopupDeleteClickEvent(){
    let checkListItemId=0;
    if (!(checkListItemId = this.getSelectedItem())) return;
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please Confirm',
      content: 'Are You Sure to Delete Check List Item?',
      actions: [
        { text: 'No' },
        { text: 'Yes', primary: true }
      ],
      width: 450,
      height: 250,
      minWidth: 250,
      appendTo: this.ContainerRef
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        //console.log('close');
      } else {
        if (this.hasValue(result)) {
          if (result.text === 'No') return;
    this.deleteSelectedCheckListItem(checkListItemId);
        }

}

});

}
deleteSelectedCheckListItem(checkListItemId){
  const inputDatatoDeleteAppointment = {
    CheckListItemInfoID : checkListItemId,
  };
   this.commonhelperobj.PostData(Config.OASPracticeCheckListItemsAppnURL + 'DeletePracticeMasterCheckListItems', inputDatatoDeleteAppointment)
  .subscribe(response => {
    if (this.isError(response)) {
      return;
    }
    this.ShowSuccesseMessage("Check List Item Deleted Successfully");
    this.getPracticeStatusCheckListItemsMasterList();
  });


}
}
