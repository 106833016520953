import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { TaskService } from '../services/task.service';
import { commonhelper } from '../common/commonhelper';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { commonHelperService } from '../common/common.service';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-csharp-review-details-popup',
  templateUrl: './csharp-review-details-popup.component.html',
  styleUrls: ['./csharp-review-details-popup.component.css']
})
export class CSharpReviewDetailsPopupComponent implements OnInit {

  @ViewChild('cSharpAddReviewForm') cSharpAddReviewForm: ElementRef;
  @ViewChild('txtCSharpreviewDetailsTaskAvailabilityScore') availabilityScoreKendoElement: NumericTextBoxComponent;
  @ViewChild('txtCSharpreviewDetailsNoofIssuesReportedbyQAforthisTask') noOfIssuesReportedKendoElement: NumericTextBoxComponent;
  @ViewChild('txtCSharpreviewDetailsTaskQualityScoreIncludingScenariosNaviagationVerification') qualityScoreKendoElement: NumericTextBoxComponent;
  
  cSharpReviewFormGroup: FormGroup;

  cSharpreviewDetailsSelectTeamLeadList: any[];
  cSharpreviewDetailsSelectDeveloperList: any[];

  public cSharpReviewDefaultTeamLeadItem = { EmployeeFullName: '-- Select Team Lead --', EmployeeId: null };
  public cSharpReviewDefaultDeveloperItem = { EmployeeFullName: '-- Select Developer --', EmployeeId: null };

  selectedCSharpTaskToAddReview: any;
  
  constructor(
    private dialog: DialogRef,
    private taskService: TaskService,
    private commonHelper: commonhelper,
    private commonHelperService: commonHelperService) { }

  async ngOnInit() {
    this.initializeEmptyCSharpGroup();

    await this.GetCSharpTeamLeadData();
    await this.GetCSharpDeveloperData();

    await this.getCSharpSavedReview()
  }


  // Initializing the empty FormGroup
  private initializeEmptyCSharpGroup() {
    this.cSharpReviewFormGroup = new FormGroup({
      cSharpFormGroupTaskNameFormControl: new FormControl(this.selectedCSharpTaskToAddReview.IssueSubject, Validators.required),
      cSharpFormGroupTeamLeadFormControl: new FormControl(null, Validators.required),
      cSharpFormGroupDeveloperFormControl: new FormControl(null, Validators.required),
      cSharpFormGroupNoOfIssuesReportedFormControl: new FormControl(null, Validators.required),
      cSharpFormGroupTaskQualityScoreFormControl: new FormControl(null, Validators.required),
      cSharpFormGroupTaskAvailabilityScoreFormControl: new FormControl(null, Validators.required),
      cSharpFormGroupTaskCodingStandardsFormControl: new FormControl(null, Validators.required),
      cSharpFormGroupTeamLeadCommentsFormControl: new FormControl(null, Validators.required),
    })
  }

  // API Call to get CSharp Team lead Data
  private async GetCSharpTeamLeadData() {
    const data = {
      IsTaskReviewLeadEmployee: false,
      TaskReviewDepartmentInfoID: 1
    }
    const response = await this.taskService.taskReviewTeamLeadList(data).toPromise()
    if (this.commonHelper.isError(response)) {
      return;
    }
    this.cSharpreviewDetailsSelectTeamLeadList = response;
  }

  // API Call to get CSharp Developer Data
  private async GetCSharpDeveloperData() {
    const data = {
      IsTaskReviewLeadEmployee: true,
      TaskReviewDepartmentInfoID: 1
    }
    const response = await this.taskService.taskReviewTeamLeadList(data).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }
    this.cSharpreviewDetailsSelectDeveloperList = response;
  }

  private async getCSharpSavedReview() {

    const preparePayloadToGetTaskReview = {
      TaskNumber: this.selectedCSharpTaskToAddReview.TaskNumber,
      TaskDoneByTeamLeadId: 2020040206074757,
      TaskDoneByEmployeeId: 2019022822551956,
    }
    const response = await this.taskService.getTaskReviewAPIAction(preparePayloadToGetTaskReview).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }
  }

  checkAllMandatoryFieldsAreFilled() {
    if (this.cSharpReviewFormGroup.get('cSharpFormGroupTaskNameFormControl').invalid) {
      this.cSharpAddReviewForm.nativeElement.querySelector(`#cSharpFormGroupTaskNameFormControl`).focus();    
      this.commonHelperService.ShowErrorMessage('Fill Task Name same as Devops name.');
      return true;
    }
    
    if (this.cSharpReviewFormGroup.get('cSharpFormGroupTeamLeadFormControl').invalid) {
      const elementFound = this.cSharpAddReviewForm.nativeElement.querySelector('[name="' + 'cSharpreviewDetailsSelectTeamLeadDropdown' + '"]');
      elementFound.children[0].focus();
      this.commonHelperService.ShowErrorMessage('Add Team Lead.');
      return true;
    }

    if (this.cSharpReviewFormGroup.get('cSharpFormGroupDeveloperFormControl').invalid) {
      const elementFound = this.cSharpAddReviewForm.nativeElement.querySelector('[name="' + 'cSharpreviewDetailsSelectDeveloperDropdown' + '"]');
      elementFound.children[0].focus();
      this.commonHelperService.ShowErrorMessage('Add Developer.');
      return true;
    }

    if (this.cSharpReviewFormGroup.get('cSharpFormGroupNoOfIssuesReportedFormControl').invalid) {
      this.noOfIssuesReportedKendoElement.focus()
      this.commonHelperService.ShowErrorMessage('Add No. of Issues Reported by QA.');
      return true;
    }

    if (this.cSharpReviewFormGroup.get('cSharpFormGroupTaskQualityScoreFormControl').invalid) {
      this.qualityScoreKendoElement.focus()
      this.commonHelperService.ShowErrorMessage('Add Task Quality Score.');
      return true;
    }
    
    if (this.cSharpReviewFormGroup.get('cSharpFormGroupTaskAvailabilityScoreFormControl').invalid) {
      this.availabilityScoreKendoElement.focus()
      this.commonHelperService.ShowErrorMessage('Add Task Availibility Score.');
      return true;
    }

    if (this.cSharpReviewFormGroup.get('cSharpFormGroupTaskCodingStandardsFormControl').invalid) {
      this.cSharpAddReviewForm.nativeElement.querySelector(`#txtCSharpreviewDetailsTaskCodingStandardsCommentingScore`).focus();    
      this.commonHelperService.ShowErrorMessage('Add Task Coding Standerds.');
      return true;
    }

    if (this.cSharpReviewFormGroup.get('cSharpFormGroupTeamLeadCommentsFormControl').invalid) {
      this.cSharpAddReviewForm.nativeElement.querySelector(`#txtCSharpreviewDetailsTeamLeadOverallCommentsaboutcurrenttask`).focus();    
      this.commonHelperService.ShowErrorMessage('Add Team Lead Comments.');
      return true;
    }    
  }

  async cSharpreviewDetailsSaveClick() {
    if (this.checkAllMandatoryFieldsAreFilled()) {
      return;
    }

    const preparePayloadForInsertReview = {
      TaskInfoId: this.selectedCSharpTaskToAddReview.TaskInfoID,
      TaskNameFromDevops: this.cSharpReviewFormGroup.value.cSharpFormGroupTaskNameFormControl,
      TaskReviewDepartmentInfoId: 1, // For CSharp Review id is 1
      CSharpTaskDonebyTeamLeadID: this.cSharpReviewFormGroup.value.cSharpFormGroupTeamLeadFormControl,
      CSharpTaskDonebyEmployeeID: this.cSharpReviewFormGroup.value.cSharpFormGroupDeveloperFormControl,
      NoIssuesReportedByQa: this.cSharpReviewFormGroup.value.cSharpFormGroupNoOfIssuesReportedFormControl,
      TaskQualityScore: this.cSharpReviewFormGroup.value.cSharpFormGroupTaskQualityScoreFormControl,
      TaskAvailabilityScore: this.cSharpReviewFormGroup.value.cSharpFormGroupTaskAvailabilityScoreFormControl,
      TaskCodingStandardsAndCommenting: this.cSharpReviewFormGroup.value.cSharpFormGroupTaskCodingStandardsFormControl,
      TeamLeadOverAllComments: this.cSharpReviewFormGroup.value.cSharpFormGroupTeamLeadCommentsFormControl
    }

    const response = await this.taskService.taskReviewInsertAPIAction(preparePayloadForInsertReview).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }

    this.commonHelperService.ShowErrorMessage('Task Review Added Successfully.');
    this.dialog.close();
  }

  cSharpreviewDetailsCancelClickEvent() {
    this.dialog.close();
  }
}