import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@progress/kendo-angular-notification';
import { commonhelper } from '../common/commonhelper';
import { DialogService, DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { PraticesListComponent } from '../pratices-list/pratices-list.component';
import { KendoButtonService } from '@progress/kendo-angular-buttons/dist/es2015/button/button.service';
import { TaskService } from '../services/task.service';
import { LeftNavService } from '../common/Leftnav.service';
// tslint:disable-next-line:max-line-length
import { EnterMultipleEmailAddressPopupComponent } from '../enter-multiple-email-address-popup/enter-multiple-email-address-popup.component';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';

@Component({
  selector: 'app-add-task-customization-based-on-email-address',
  templateUrl: './add-task-customization-based-on-email-address.component.html',
  styleUrls: ['./add-task-customization-based-on-email-address.component.css']
})
export class AddTaskCustomizationBasedOnEmailAddressComponent extends commonhelper implements OnInit {
  public multiple_emails: HTMLElement;
  private EMRDataFromPopupLocal: any;
  public emailinformation: any;
  public PushEntriedEmailAddress: Array<any> = [];
  public emailslist: any = {};
  public getEnteredEmailAddress: any;
  public usersModel: any = {};
  public EmailAddress: any;

  @Input()
  set EMRDataFromPopup(data: any) {
    this.EMRDataFromPopupLocal = data;
  }

  get EMRDataFromPopup(): any { return this.EMRDataFromPopupLocal; }

  @ViewChild('AddTaskCustomztnEmailAddress') AddTaskCustomztnEmailAddress: ElementRef;
  private commonhelperobj: commonHelperService;
  public TaskCustomizationModel: any = {};
  public SelectedPracticeUserBasedOn: any = {};
  public projectsList: Array<string> = [];
  public supportGroupsList: any = [];
  public ModulesList: Array<string> = [];
  public SelectedAdditionalResponsiblePersons: any = [];
  public SelectedObservers: any = [];
  public SelectedResponsiblePerson: any = {};
  public responsiblePersonInfo: any = {};
  public EmployeeListInfo: any;
  public EmployeeObserversListInfo: any;
  public EmployeeResponsbilePersonListInfo: any;
  public showPracticeUsers = true;
  public showPracticeUsersEmailIDs = true;
  public practiceInformation: any;
  public practiceUsersList: any = [];
  public SelectedPracticeUser: any = [];
  public enteredEmailIDs: any = [];
  public PracticeUserTypeList = [
    // { text: 'Select User Based on', value: 0 },
    { text: 'User Name', value: 1 },
    { text: 'Based On Email ID', value: 2 },
  ];
  public source: Array<{ text: string, value: number }> = [
    { text: 'Small', value: 1 },
    { text: 'Medium', value: 2 },
    { text: 'Large', value: 3 }
  ];
  public data: Array<{ text: string, value: number }>;


  public fieldArray: Array<any> = [];
  private newAttribute: any = {};
  public EmployeesAndRolesModelList: Array<string> = [];
  public EmployeesAndRolesModel: any = {};
  public PracticeUsersModel: any = {};
  public SelectedPracticeUserList: Array<any> = [];
  public selectedUsersList: Array<any> = [];
  public usersmodel: any = {};
  public practiceDiv: HTMLElement;
  public divEmailAddress: HTMLElement;
  public divIssueSupportGroupID: HTMLElement;
  public divSelectedResponsiblePerson: HTMLElement;
  public divSelectedAdditionalResponsiblePersons: HTMLElement;
  public divSelectedObservers: HTMLElement;
  public editmodedata: any;
  public BindSaveButton = 'Save';
  public SinglefieldArray: Array<any> = [];
  public EnteredEmailAddress: Array<any> = [];
  // tslint:disable-next-line:max-line-length
  constructor(httpClient: HttpClient, private notificationService: NotificationService, private dialogService: DialogService, private dialog: DialogRef, private taskService: TaskService, private LeftNav: LeftNavService) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
    this.data = [];
  }

  ngOnInit() {
    setTimeout(function () {
      document.getElementById("divEmailAddress").focus();
    }, 10);
    // this.LeftNav.data.subscribe(this.GetEditModeDataList);
    this.GetProjectsList();
    this.GetSupportGroupsList();
    this.GetModulesList();
    this.GetEmployeesList();
    this.EnteredEmailAddress = [];

    //
    // this.TaskCustomizationModel.IssueSupportGroupID = -1;
    // this.SelectedPracticeUserBasedOn = 0;
    // this.SelectedResponsiblePerson = -1;
    // this.SelectedAdditionalResponsiblePersons = -1;
    // this.SelectedObservers = -1;
  }

  GetProjectsList() {
    const PostDataToService = {};



    this.commonhelperobj.PostData(Config.OASProjectsAppnURL + 'GetProjectsList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      // if (this.hasValue(serviceResponse)) {
      //   serviceResponse.ProjectList.unshift({ Description: ' - Select Project - ', ProjectId: -1 });
      // }
      this.projectsList = serviceResponse.ProjectList;
    });
  }

  // getEditModeData(item) {
  //   this.BindSaveButton = 'Update';
  //   this.editmodedata = item;
  //   this.GetEditModeDataList(item);
  //   // if (this.hasValue(this.editmodedata)) {
  //   // this.TaskCustomizationModel.PracticeName = this.editmodedata.PracticeName;
  //   // this.TaskCustomizationModel.IssueSupportGroupDesc = this.editmodedata.IssueSupportGroupName;
  //   // this.TaskCustomizationModel.IssueSupportGroupID = this.editmodedata.IssueSupportGroupID;
  //   // }
  // }
  // GetEditModeDataList(item) {
  //   const PostDataToService = {
  //     PracticeId: item.PracticeId,
  //     IssueSupportGroupID: item.IssueSupportGroupID
  //   };

  //   this.taskService.GetPracticeUsersEmailsData(PostDataToService).subscribe(response => {
  //     if (this.isError(response)) {
  //       return;
  //     }
  //     this.TaskCustomizationModel = response;
  //     alert(response);
  //   });
  //   // tslint:disable-next-line:max-line-length
  //   this.commonhelperobj.PostData(Config.OASAppnURL + 'OASGetPracticeUsersEmailsData', PostDataToService).subscribe(serviceResponse => {
  //     if (this.isError(serviceResponse)) { return; }
  //     // if (this.hasValue(serviceResponse)) {
  //     //   serviceResponse.unshift({ IssueSupportGroupDesc: ' - Select Support Group - ', IssueSupportGroupID: -1 });
  //     // }
  //     this.supportGroupsList = serviceResponse;
  //   });
  // }

  GetSupportGroupsList() {
    const PostDataToService = {};
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj.PostData(Config.OASAppnURL + 'OASIssueSupportGroupsListView', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      // if (this.hasValue(serviceResponse)) {
      //   serviceResponse.unshift({ IssueSupportGroupDesc: ' - Select Support Group - ', IssueSupportGroupID: -1 });
      // }
      this.supportGroupsList = serviceResponse;
    });
  }
  GetModulesList() {
    const PostDataToService = {};
    this.commonhelperobj.PostData(Config.OASModulesAppnURL + 'GetProjectModules', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      // if (this.hasValue(serviceResponse)) {
      //   serviceResponse.ModuleList.unshift({ Description: ' - Select Module - ', ModuleId: -1 });
      // }
      this.ModulesList = serviceResponse.ModuleList;
    });
  }
  GetEmployeesList() {
    const PostDataToService = {};
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj.PostData(Config.OASOrganizationAppnURL + 'OrganizationEmployeesList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      // if (this.hasValue(serviceResponse)) {
      //   serviceResponse.EmployeeList.unshift({ EmployeeFullName: ' - Select - ', EmployeeId: -1 });
      // }
      this.EmployeeListInfo = serviceResponse.EmployeeList;
      this.EmployeeObserversListInfo = serviceResponse.EmployeeList;
      this.EmployeeResponsbilePersonListInfo = serviceResponse.EmployeeList;
    });
  }
  practiceIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Practices',
      // Show component
      content: PraticesListComponent,
      height: 650,
      width: 550
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.TaskCustomizationModel.PracticeName)) {

        } else {
          this.TaskCustomizationModel.PracticeName = '';
        }
      } else {
        this.practiceInformation = result;
        if (this.hasValue(this.practiceInformation)) {
          this.TaskCustomizationModel.PracticeName = this.practiceInformation.PracticeName;
          this.TaskCustomizationModel.PracticeId = this.practiceInformation.PracticeId;
          this.getPracticeUsersList();
        }
      }
    });
  }

  getPracticeUsersList() {
    const PostData = {
      PracticeId: this.practiceInformation.PracticeId
    };
    this.taskService.PracticeUsersList(PostData).subscribe(response => {
      if (this.isError(response)) {
        return;
      }
      this.practiceUsersList = response;
    });
  }

  public onTabSelect(e) {
  }

  SaveClickEvent() {

  }

  CancelClickEvent() {
    this.dialog.close();
  }
  changeUserType() {
    if (this.SelectedPracticeUserBasedOn === 1) {
      this.showPracticeUsers = false;
      this.showPracticeUsersEmailIDs = true;
    } else if (this.SelectedPracticeUserBasedOn === 2) {
      this.showPracticeUsers = true;
      this.showPracticeUsersEmailIDs = false;
    } else {
      this.showPracticeUsers = true;
      this.showPracticeUsersEmailIDs = true;
    }
  }

  AddMoreEmailAddress() {
    $('#textboxDiv').append('<div><br><input type=\'text\'/><br></div>');
  }
  RemoveMoreEmailAddress() {
    $('#textboxDiv').children().last().remove();
  }

  DeleteClickEvent(this) {
    this.parent().remove();
  }
  AddClickEvent() {
    $('.Option:last').after($('.Option:last').clone());
  }



  addFieldValue() {
    this.fieldArray.push(this.newAttribute);
    this.newAttribute = {};
  }


  SaveTaskCustomizationInfo() {


    if (!this.hasValue(this.EmailAddress) && this.PushEntriedEmailAddress.length <= 0) {
      this.ShowErrorMessage('Please Enter Email Address.');
      const elementFound2 = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('[id="' + 'divEmailAddress' + '"]');
      elementFound2.focus();
      return false;
    }
    if (this.hasValue(this.EmailAddress) || this.PushEntriedEmailAddress.length > 0) {
      const elementFound2 = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('[id="' + 'divEmailAddress' + '"]');
      if ((this.validateEmail(elementFound2)) === false) {
        return false;
      } else {
        this.ValidateEmailAddress();
      }
    }
    // this.RemainingValidationsForSaving();
  }


  RemainingValidationsForSaving() {
    // if (!this.hasValue(this.TaskCustomizationModel.IssueSupportGroupID)) {
    //   this.ShowErrorMessage('Please Select Support Group.');
    //   const elementFound = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('[name="' + 'divIssueSupportGroupID' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].focus();
    //   }
    //   return false;
    // }

    if (!this.hasValue(this.SelectedResponsiblePerson)) {
      this.ShowErrorMessage('Please Select Responsible Person.');
      // tslint:disable-next-line:max-line-length
      const elementFound = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('[name="' + 'divSelectedResponsiblePerson' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return false;
    }

    if (this.hasValue(this.SelectedAdditionalResponsiblePersons) && this.SelectedAdditionalResponsiblePersons.length > 0) {
      this.SelectedAdditionalResponsiblePersons.forEach(element => {
        this.EmployeesAndRolesModel = {
          EmployeeID: element,
          RoleID: 4,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }

    if (this.hasValue(this.SelectedObservers) && this.SelectedObservers.length > 0) {
      this.SelectedObservers.forEach(element => {
        this.EmployeesAndRolesModel = {
          EmployeeID: element,
          RoleID: 3,
        };

        this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);
      });
    }
    //debugger;
    if (this.hasValue(this.SelectedResponsiblePerson)) {
      this.EmployeesAndRolesModel = {
        EmployeeID: this.SelectedResponsiblePerson.EmployeeId,
        RoleID: 2,
      };
      this.EmployeesAndRolesModelList.push(this.EmployeesAndRolesModel);

    }

    if (this.EmployeesAndRolesModelList.length > 0) {
      this.TaskCustomizationModel.EmployeesAndRolesModelList = this.EmployeesAndRolesModelList;
    }

    if (this.hasValue(this.PushEntriedEmailAddress) && this.PushEntriedEmailAddress.length > 0) {
      if (this.hasValue(this.EmailAddress)) {
        const mainEmailAddressModel = {
          EHREMailIDs: this.EmailAddress
        };

        this.PushEntriedEmailAddress.push(mainEmailAddressModel);
      }
      this.TaskCustomizationModel.EmailsModelList = this.PushEntriedEmailAddress;
    }
    if (!this.hasValue(this.PushEntriedEmailAddress) && this.PushEntriedEmailAddress.length <= 0) {
      if (this.hasValue(this.EmailAddress)) {
        const singleEmailAddressModel = {
          EHREMailIDs: this.EmailAddress
        };
        this.SinglefieldArray.push(singleEmailAddressModel);
        this.TaskCustomizationModel.EmailsModelList = this.SinglefieldArray;
      }
    }

    // tslint:disable-next-line:max-line-length
    this.commonhelperobj.PostData(Config.OASAppnURL + 'InsertUsersEmailAddressCustomizationInformation', this.TaskCustomizationModel)
      .subscribe(serviceResponse => {
        if (this.isError(serviceResponse)) { return; }
        this.ShowSuccesseMessage('Customization Details Saved SuccessFully.');
        this.TaskCustomizationModel = {};
        this.EmployeesAndRolesModelList = [];
        this.SelectedObservers = undefined;
        this.SelectedAdditionalResponsiblePersons = undefined;
        this.SelectedResponsiblePerson = undefined;
        this.SelectedPracticeUserList = [];
        this.fieldArray = [];
        this.dialog.close('save');
      });
  }

  handleFilter(value) {
    if (this.hasValue(value)) {
      this.supportGroupsList = this.supportGroupsList
        .filter((s) => s.IssueSupportGroupDesc.toLowerCase().indexOf(value.toLowerCase()) !== -1)
        .filter((element, index) => index < 2);
    } else {
      this.supportGroupsList = this.supportGroupsList;
    }
  }
  ValidateEmailAddress() {
    const postData: any = {
      EHREMailIDs: this.EmailAddress
    };
    this.commonhelperobj.PostData(Config.OASAppnURL + 'ValidateEmailCustomization', postData)
      .subscribe(serviceResponse => {
        if (this.isError(serviceResponse)) {
          const elementFound2 = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('[id="' + 'divEmailAddress' + '"]');
          elementFound2.focus();
          return false;
        } else {
          this.RemainingValidationsForSaving();
        }
      });
  }

  deleteAllEmails() {
    this.PushEntriedEmailAddress = [];
    this.EmailAddress = '';
  }
  deleteFieldValue(index) {
    this.PushEntriedEmailAddress.splice(index, 1);
  }
  ValidateEmailCustomizationAddress(elementFound) {
    const postData: any = {
      EHREMailIDs: this.EmailAddress
    };
    this.commonhelperobj.PostData(Config.OASAppnURL + 'ValidateEmailCustomization', postData)
      .subscribe(serviceResponse => {
        if (this.isError(serviceResponse)) {
          const elementFound2 = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('[id="' + 'divEmailAddress' + '"]');
          elementFound2.focus();
          return false;
        } else {
          if (this.hasValue(this.EmailAddress)) {
            if (this.hasValue(this.PushEntriedEmailAddress) && this.PushEntriedEmailAddress.length > 0) {
              for (let i = 0; i < this.PushEntriedEmailAddress.length; i++) {
                if (this.PushEntriedEmailAddress[i].EHREMailIDs === this.EmailAddress) {
                  this.ShowErrorMessage('You have Already Entered this Email.');
                  const elementFound2 = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('[id="' + 'divEmailAddress' + '"]');
                  elementFound2.focus();
                  return false;
                } else {
                  this.emailslist = {
                    EHREMailIDs: this.EmailAddress
                  };
                  this.PushEntriedEmailAddress.push(this.emailslist);
                  setTimeout(() => {
                    if (this.PushEntriedEmailAddress.length > 0) {
                      this.EmailAddress = '';
                      elementFound.focus();
                    }
                  }, 10);
                }
              }
            } else {
              this.emailslist = {
                EHREMailIDs: this.EmailAddress
              };
              this.PushEntriedEmailAddress.push(this.emailslist);
              setTimeout(() => {
                if (this.PushEntriedEmailAddress.length > 0) {
                  this.EmailAddress = '';
                  elementFound.focus();
                }
              }, 10);
            }
          }
        }
      });
  }



  AddEmailAddressClickEvent() {
    this.EnteredEmailAddress = [];
    const dialogRef = this.dialogService.open({
      title: 'Enter Email Address',
      // Show component
      content: EnterMultipleEmailAddressPopupComponent,
      height: 720,
      width: 550
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {

        if (this.hasValue(result)) {

          this.enteredEmailIDs = result;
          this.enteredEmailIDs.forEach(element => {
            this.usersmodel = {
              EHREMailIDs: element.EHREMailIDs,
            };
            this.EnteredEmailAddress.push(this.usersmodel);
          });
        }
        // this.EnteredEmailAddress.push(result);
      }
    });
  }
  SaveFieldValue() {
    if (!this.hasValue(this.EmailAddress)) {
      this.ShowErrorMessage('Please Enter Email Address.');
      const elementFound2 = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('[id="' + 'divEmailAddress' + '"]');
      elementFound2.focus();
    }
    const elementFound = this.AddTaskCustomztnEmailAddress.nativeElement.querySelector('#divEmailAddress');
    if ((this.validateEmail(elementFound)) === false) {
      return false;
    } else {

      this.ValidateEmailCustomizationAddress(elementFound);
    }

  }

  responsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Responsible Person',
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedResponsiblePerson)) {

        } else {

        }
      } else {
        this.responsiblePersonInfo = result;
        if (this.hasValue(this.responsiblePersonInfo)) {
          this.SelectedResponsiblePerson.EmployeeId = this.responsiblePersonInfo.EmployeeId;
          this.SelectedResponsiblePerson.EmployeeFullName = this.responsiblePersonInfo.EmployeeFullName;

        }
      }
    });
  }

  RemoveResponsiblePersonInfo() {
    this.SelectedResponsiblePerson.EmployeeId = undefined;
    this.SelectedResponsiblePerson.EmployeeFullName = '';
  }
  // public responsiblePersonClose(): void {
  //   this.SaveTaskCustomizationInfo();
  //   // this.employeeList().subscribe(() => { });
  // }
  // emailAddressBlurEvent() {
  //   this.SaveFieldValue();
  // }
}

