import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { extend } from 'jquery';
import { commonhelper } from '../common/commonhelper';
import { TaskEmployeeType } from '../enums/task-employee-type.enum';
import { IRolesModel } from './select-role-to-add-popup.interface';

@Component({
  selector: 'app-select-role-to-add-popup',
  templateUrl: './select-role-to-add-popup.component.html',
  styleUrls: ['./select-role-to-add-popup.component.css']
})
export class SelectRoleToAddPopupComponent extends commonhelper implements OnInit {

  constructor(private readonly dialog: DialogRef,) { super(); }
  rolesList: IRolesModel[] = [
    { id: TaskEmployeeType.AdditionalResponsiblePerson, roleName: 'Additional Responsible', isChecked: false },
    { id: TaskEmployeeType.Observers, roleName: 'Observer', isChecked: false },
    { id: TaskEmployeeType.None, roleName: 'None', isChecked: false },
  ]
  ngOnInit(): void {
  }
  selectRoletoAddPopupOkClickEvent(): void {
    const SelectedRole: IRolesModel[] = this.rolesList.filter(item => { if (item.isChecked) { return item; } });
    if (SelectedRole.length <= 0) {
      this.ShowErrorMessage('Please Select Role.');
      return;
    }
    this.dialog.close(SelectedRole[0].id);
  }
  selectRoletoAddPopupCancelClickEvent(): void {
    this.dialog.close();
  }
  roleChangeEvent(event: { target: { checked: boolean; }; }, selectedItem: IRolesModel): void {
    selectedItem.isChecked = event.target.checked;
    if (event.target.checked) {
      this.rolesList.forEach(item => { if (item.id !== selectedItem.id) { item.isChecked = false } });
    }
  }
}

