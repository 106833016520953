import { Component, Injectable } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { delay } from 'rxjs/operators/delay';
import { commonhelper } from './common/commonhelper';
import { EventManager } from '@angular/platform-browser';
import { NotificationService } from '@progress/kendo-angular-notification';
import { config } from 'rxjs';
import { Config } from './config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends commonhelper {
  ViewReportedIssues: boolean;
  title = 'OASWEBAPPLICATION-LATEST';


  private scriptstoDownload: Array<string> = [
    'assets/theme/assets/plugins/summernote/dist/summernote.min.js'
  ];

  constructor(private _router: Router, private _EventManager: EventManager,
    private notificationService: NotificationService) {

    super();
    // Config.OASAutoLoadModulesInformation = {};
    // Config.ResetOASOrganizationModel();

    var url = window.location.href;

    //alert("jjja");
    if (url.indexOf('?') > 0) {
      var url1 = url.substring(url.indexOf('?') + 1);

    }
    //alert(url1)

    debugger;

    if (this.hasValue(url1)) {
      var QueryParamsFromUrl = atob(url1);

      var QueryParamsFromUrlJsonObject = JSON.parse(QueryParamsFromUrl);
      //debugger;
      //$scope.loginDataQueryString = QueryParamsFromUrlJsonObject["qeuryString"];
    }

    if (this.hasValue(QueryParamsFromUrlJsonObject)) {
      if (QueryParamsFromUrlJsonObject["NavigationFrom"] == 1) {
        this.ViewReportedIssues = false;
      }
      else {
        this.ViewReportedIssues = true;
      }

      if (this.hasValue(QueryParamsFromUrlJsonObject["PracticeModel"])) {
        Config.EMRPracticeModelForOAS = QueryParamsFromUrlJsonObject["PracticeModel"];
        // console.log(Config.EMRPracticeModelForOAS);
      }
    }



    if (this.hasValue(QueryParamsFromUrlJsonObject)) {
      this._router.navigateByUrl('/home');
    }
    else {
      this._router.navigateByUrl('/');
    }
    //this._router.navigateByUrl('/');



    this._EventManager.addGlobalEventListener('document', 'showErrorMessage', (event: any) => {
      this.notificationService.show({
        content: event.detail,
        animation: { type: 'slide', duration: 100 },
        position: { horizontal: 'center', vertical: 'top' },
        type: { style: 'error', icon: true },
        closable: false,
        hideAfter: 5000
      });

    });

    this._EventManager.addGlobalEventListener('document', 'ShowSuccesseMessage', (event: any) => {

      this.notificationService.show({
        content: event.detail,
        animation: { type: 'slide', duration: 100 },
        position: { horizontal: 'center', vertical: 'top' },
        type: { style: 'success', icon: true },
        closable: false,
        hideAfter: 5000
      });

    });

  }







  hasValue(obj: any) {

    if (obj != null && obj !== undefined &&
      obj !== '' && obj.toString().length > 0) {
      return true;
    } else {
      return false;
    }
  }
  // this method is useful in downoalding the all the scripts synchronoulsy
  downloadScriptsSychronously(scriptstoDownload: any, index: number) {


    const anchor = window.document.getElementsByTagName('head')[0];

    if (this.hasValue(scriptstoDownload) && this.hasValue(scriptstoDownload[index])) {

      const elementItemScript = scriptstoDownload[index];

      const element = document.createElement('script');

      element.src = elementItemScript;

      element.onload = (params) => {
        this.downloadScriptsSychronously(this.scriptstoDownload, index + 1);
      };
      element.onabort = (params) => {
        this.downloadScriptsSychronously(this.scriptstoDownload, index + 1);
      };
      element.onerror = (params) => {
        this.downloadScriptsSychronously(this.scriptstoDownload, index + 1);
      };
      anchor.appendChild(element);
    }

    // else {

    //   if (this.hasValue(Config.OASAutoLoadModulesInformation)
    //     && this.hasValue(Config.OASAutoLoadModulesInformation.ModuleID)) {

    //     if (Config.OASAutoLoadModulesInformation.ModuleID === '2018041011032393159'
    //       || Config.OASAutoLoadModulesInformation.ModuleID === '2018041019300017098') {


    //       try {

    //         (document.querySelector('.page-wrapper') as HTMLElement).style.marginLeft = '0px';
    //         (document.querySelector('header') as HTMLElement).style.display = 'none';
    //         (document.querySelector('.container-fluid') as HTMLElement).style.padding = '0 !important';

    //       } catch (error) {

    //       }



    //       // this._router.navigateByUrl('/home'); // , { queryParams: { info: "dss" } }
    //       // this._router.navigate(['/home', { outlets: { 'main': 'app-add-new-task' } }]);
    //       const PostDataToService = {};
    //       const outletObj = {};
    //       outletObj['main'] = ['app-add-new-task', this.setCommonData(PostDataToService)];
    //       this._router.navigate(['/home', { outlets: outletObj }]);

    //       // const menuItem: any = {
    //       //   // tslint:disable-next-line:max-line-length
    //       //   MenuComponentName: Config.OASAutoLoadModulesInformation.ModuleID
    //       //  === '2018041019300017098' ? 'app-view-tasks-list' : 'app-add-new-task',
    //       //   FormID: Config.OASAutoLoadModulesInformation.ModuleID
    //       // };

    //       // this.OnListClick(menuItem, undefined, undefined);

    //       // const filteredListItem = this.grep(this.homePageNavMenusList, (itemInLoop) => {
    //       //   return itemInLoop.FormID === Config.OASAutoLoadModulesInformation.ModuleID;
    //       // }, undefined);

    //       // if (this.hasValue(filteredListItem) && filteredListItem.length > 0) {
    //       //   this.OnListClick(filteredListItem[0], undefined, undefined);
    //       // }

    //     }

    //   }
    // }
  }



}

@Injectable()
export class UploadInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === 'saveUrl') {
      const events: Observable<HttpEvent<any>>[] = [0, 30, 60, 100].map((x) => of(<HttpProgressEvent>{
        type: HttpEventType.UploadProgress,
        loaded: x,
        total: 100
      }).pipe(delay(1000)));

      const success = of(new HttpResponse({ status: 200 })).pipe(delay(1000));
      events.push(success);

      return concat(...events);
    }

    if (req.url === 'removeUrl') {
      return of(new HttpResponse({ status: 200 }));
    }

    return next.handle(req);
  }
}
