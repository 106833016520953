import { Injectable } from '@angular/core';


@Injectable()
export class AllAppointmentsService {
    constructor() { }
    ExcelColumnsInfo = [
        { header: "Customer Name", key: "PracticeName", width: 30 },
        { header: "Appt. Date (Customer)", key: "MeetingScheduledPracticeStartDate", width: 20 },
        { header: "Appt. Time (Customer)", key: "MeetingScheduledPracticeStartTime", width: 20 },
        { header: "Appt. IST Date", key: "MeetingScheduledStartDate", width: 15 },
        { header: "Appt. IST Time", key: "MeetingScheduledStartTime", width: 15 },
        { header: "Duration", key: "MeetingScheduledDuration", width: 15 },
        { header: "Status", key: "MeetingScheduledStatus", width:10 },
    ]

}

