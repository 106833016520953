import { Component, OnInit } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
// tslint:disable-next-line:max-line-length
import { AddTaskCustomizationInformationComponent } from '../add-task-customization-information/add-task-customization-information.component';

@Component({
    selector: 'app-project-hierarchy-info',
    templateUrl: './project-hierarchy-info.component.html',
    styleUrls: ['./project-hierarchy-info.component.css']
})
export class ProjectHierarchyInfoComponent implements OnInit {

    constructor(private dialogService: DialogService) { }

    ngOnInit() {
    }

    addProjectClickEvent() {

    }

}
