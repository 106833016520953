import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { commonhelper } from '../common/commonhelper';

@Component({
  selector: 'app-scheduled-meeting-change-status-popup',
  templateUrl: './scheduled-meeting-change-status-popup.component.html',
  styleUrls: ['./scheduled-meeting-change-status-popup.component.css']
})
export class ScheduledMeetingChangeStatusPopupComponent extends commonhelper implements OnInit {
  @ViewChild('ScheduledMeetingChangeStatusPopup') ScheduledMeetingChangeStatusPopup: ElementRef;
  constructor(private dialogService: DialogService, private dialog: DialogRef,) { super(); }
  public scheduledMeetingChangeStatusPopupSelectStatusDefaultItem = { Status: '-- Select --', StatusId: null };
  public ScheduledMettingsModel: any = {};
  public scheduledMeetingChangeStatusPopupStatusList: any = [];
  StatusList = [];
  ngOnInit() {
    this.scheduledMeetingChangeStatusPopupStatusList = this.StatusList;
  }
  scheduledMeetingChangeStatusPopupCancelClickEvent() {
    this.dialog.close();
  }
  scheduledMeetingChangeStatusPopupSaveClickEvent() {
    if (this.checkMandatoryValidations()) return;
    this.dialog.close(this.ScheduledMettingsModel);
  }
  checkMandatoryValidations() {
    if (!this.hasValue(this.ScheduledMettingsModel.Status) || this.ScheduledMettingsModel.Status <= 0) {
      this.ShowErrorMessage("Please Select Status")
      return true;
    }
  }
  setFocusonStatusField() {
    setTimeout(() => {
      const elementFound = this.ScheduledMeetingChangeStatusPopup.nativeElement.querySelector('[name="' + 'scheduledMeetingChangeStatusPopupSelectStatusDropdown' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
    }, 100);
  }
}
