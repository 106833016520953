import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { AgGridNg2 } from 'ag-grid-angular';
import { DialogCloseResult, DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';
import { PraticesListComponent } from '../pratices-list/pratices-list.component';
import { formatDate } from '@angular/common';
import { ViewAppointmentsPopupComponent } from '../view-appointments-popup/view-appointments-popup.component';
import { ScheduledMeetingChangeStatusPopupComponent } from '../scheduled-meeting-change-status-popup/scheduled-meeting-change-status-popup.component';
import { ScheduledMeetingAddCommentsPopupComponent } from '../scheduled-meeting-add-comments-popup/scheduled-meeting-add-comments-popup.component';
import { ExcelService } from '../common/excel.service';
import { AllAppointmentsService } from './all-appointments.service';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';

@Component({
  selector: 'app-all-appointments',
  templateUrl: './all-appointments.component.html',
  styleUrls: ['./all-appointments.component.css']
})
export class AllAppointmentsComponent extends commonhelper implements OnInit {
  @ViewChild('allAppointmentsGrid') allAppointmentsGrid: AgGridNg2;
  @ViewChild('AllAppointments') AllAppointments: ElementRef;
  @ViewChild("AllAppointments", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  public practiceInformation: any = {};
  CreatedByInfo: any = {};
  addEditAppointmentsStatusList: any = [
    { StatusId: 1, Status: 'Pending' },
    { StatusId: 2, Status: 'Completed' },
    { StatusId: 3, Status: 'Cancelled' },

  ];
  private commonhelperobj: commonHelperService;
  columnDefs = [
    { headerName: 'Customer Name', headerTooltip: 'Customer Name', field: 'PracticeName', Width: 200, tooltipField: 'PracticeName', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'center' } },
    {
      headerName: 'Appt. Date (Customer)', headerTooltip: 'Appt. Date (Customer)',
      // cellRenderer: function (params) {
      //   if (params.data.MeetingScheduledPracticeStartDate && params.data.MeetingScheduledPracticeEndDate)
      //     return params.data.MeetingScheduledPracticeStartDate + " - " + params.data.MeetingScheduledPracticeEndDate
      //   else
      //     return params.data.MeetingScheduledPracticeStartDate
      // },
      field: 'MeetingScheduledPracticeStartDate', width: 150, tooltipField: 'MeetingScheduledPracticeStartDate', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'center' }
    },
    {
      headerName: 'Appt. Time (Customer)', headerTooltip: 'Appt. Time (Customer)', width: 150,
      //   cellRenderer: function (model) {
      //     if(model.data.MeetingScheduledPracticeStartTime && model.data.MeetingScheduledPracticeEndTime)
      //     return model.data.MeetingScheduledPracticeStartTime+" - "+model.data.MeetingScheduledPracticeEndTime
      //     else
      //     return model.data.MeetingScheduledPracticeStartTime
      // },
      field: 'MeetingScheduledPracticeStartTime', tooltipField: 'MeetingScheduledPracticeStartTime', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'center' }
    },

    {
      headerName: 'Appt. IST Date', headerTooltip: 'Appt. IST Date',
      // cellRenderer: function (params) {
      //   if (params.data.MeetingScheduledStartDate && params.data.MeetingScheduledEndDate)
      //     return params.data.MeetingScheduledStartDate + " - " + params.data.MeetingScheduledEndDate
      //   else
      //     return params.data.MeetingScheduledStartDate
      // },
      field: 'MeetingScheduledStartDate', width: 100, tooltipField: 'MeetingScheduledStartDate', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'center' }
    },

    // { headerName: 'Practice User(s)', field: 'MeetingScheduledUserName', Width: 60, tooltipField: 'MeetingScheduledUserName', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    // { headerName: 'Participants', field: 'ParticipentEmployeeName', Width: 60, tooltipField: 'ParticipentEmployeeName', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    // { headerName: 'Duration', field: 'MeetingScheduledDuration', Width: 60,tooltipField: 'MeetingScheduledDuration', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },

    {
      headerName: 'Appt. IST Time', headerTooltip: 'Appt. IST Time',
      //   cellRenderer: function (model) {  
      //     if(model.data.MeetingScheduledStartTime && model.data.MeetingScheduledEndTime)
      //     return model.data.MeetingScheduledStartTime+" - "+model.data.MeetingScheduledEndTime
      //     else
      //     return model.data.MeetingScheduledStartTime
      // },
      field: 'MeetingScheduledStartTime', width: 100, tooltipField: 'MeetingScheduledStartTime', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'center' }
    },
    { headerName: 'Duration', headerTooltip: 'Duration', field: 'MeetingScheduledDuration', width: 100, tooltipField: 'MeetingScheduledDuration', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'center' } },
    // { headerName: 'Purpose', field: 'MeetingScheduledPurpose', width: 260, suppressSizeToFit: true, tooltipField: 'MeetingScheduledPurpose', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    // {
    //   headerName: 'Comments', field: 'MeetingScheduledComments',
    //   cellRenderer: function (params) {
    //     params.value = params.value ? params.value : "Enter Comments"
    //     return '<a>' + params.value + '</a>';
    //   },
    //   width: 240, suppressSizeToFit: true, tooltipField: 'MeetingScheduledComments', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' }
    // },
    {
      headerName: 'Status', headerTooltip: 'Status',
      cellRenderer: function (params) {
        params.value = params.value ? params.value : "Select Status"
        return '<a>' + params.value + '</a>';
      },
      field: 'MeetingScheduledStatus', width: 100, tooltipField: 'MeetingScheduledStatus', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'center' }
    },
    { headerName: 'Created By', headerTooltip: 'Created By',  field: 'CreatedByName', tooltipField: 'CreatedByName', width: 140, cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Created Date', headerTooltip: 'Created Date', field: 'CreatedDate', tooltipField: 'CreatedDate', width: 140, cellClass: 'no-border cell-wrap-text', autoHeight: true },
  ];

  onAllAppointmentsGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  public allAppointmentsFromDate: any;
  public allAppointmentsToDate: any;
  public appointmentInformationRowData = [];
  constructor(private http: HttpClient, private readonly _excelService: ExcelService, private readonly _allAppointmentsService: AllAppointmentsService, httpClient: HttpClient, private dialogService: DialogService) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }

  ngOnInit() {
    this.allAppointmentsFromDate = new Date(Date.now() - (31 * 24) * 60 * 60 * 1000);
    this.allAppointmentsToDate = new Date(Date.now());
    this.customerEmployeeAppointmentsList();
    setTimeout(function () {
      document.getElementById("allAppointmentsSelectCustomer").focus();
    }, 20);
  }
  onCellClicked(event: any) {
    if (event.column.colId == "MeetingScheduledStatus") {
      this.allAppointmentsUpdateStatusClickEvent();
    }
  }
  allAppointmentsUpdateStatusClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Status',
      // Show component
      content: ScheduledMeetingChangeStatusPopupComponent,
      height: 320,
      width: 500,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.StatusList = this.addEditAppointmentsStatusList
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.UpdateAppointmentsStatusComments(result, "Status Updated Successfully");
        }
      }
    });
  }
  MyappointmentsAddCommentsClick() {
    let AppointmentId = 0;
    if (!(AppointmentId = this.getSelectedAppointment())) return;
    const dialogRef = this.dialogService.open({
      title: 'Add Comments',
      // Show component
      content: ScheduledMeetingAddCommentsPopupComponent,
      height: 230,
      width: 500,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.UpdateAppointmentsStatusComments({ Comments: result }, "Comments Added Successfully");
        }
      }
    });
  }
  customerEmployeeAppointmentsList() {
    if (!this.hasValue(this.allAppointmentsFromDate)) {
      this.ShowErrorMessage("Please Enter From Date")
      const elementFound = this.AllAppointments.nativeElement.querySelector('[name="' + "txtAllAppointmentsFromDate" + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return
    }
    if (!this.hasValue(this.allAppointmentsToDate)) {
      this.ShowErrorMessage("Please Enter To Date")
      const elementFound = this.AllAppointments.nativeElement.querySelector('[name="' + "txtAllAppointmentsToDate" + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return
    }
    if (this.allAppointmentsFromDateChange() == false) return false;
    if (this.allAppointmentsToDateChange() == false) return false;
    const datatoGetAppointmentsList = {
      practiceId: this.practiceInformation.PracticeId,
      CreatedBy: this.CreatedByInfo.EmployeeId,
      FromDate: formatDate(this.allAppointmentsFromDate, 'MM/dd/yyy', 'en-US', '+0530'),
      ToDate: formatDate(this.allAppointmentsToDate, 'MM/dd/yyy', 'en-US', '+0530'),
      NeedtoShowAllTasks: 1//to get all appointments
    }
    return this.commonhelperobj.PostData(Config.OASAppointmentsInformationAppnURL + 'GetAppointmentsList', datatoGetAppointmentsList)
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.appointmentInformationRowData = response.meetingScheduledOutputModels;
        setTimeout(function () {
          document.getElementById("myAppointmentsSelectUser").focus();
        }, 20);
      });

  }
  AllAppointmentsExportToExcelClick() {
    if (this.appointmentInformationRowData.length <= 0) return;
    this._excelService.exportAsExcelFile(this._allAppointmentsService.ExcelColumnsInfo, this.appointmentInformationRowData, "AllAppointmentsReport");
  }
  allAppointmentsFromDateChange() {
    if (!(this.allAppointmentsFromDateToDateValidations(this.allAppointmentsFromDate, "txtAllAppointmentsFromDate", true))) return false;
  }
  allAppointmentsToDateChange() {

    if (!(this.allAppointmentsFromDateToDateValidations(this.allAppointmentsToDate, "txtAllAppointmentsToDate", false))) return false;
  }
  allAppointmentsFromDateToDateValidations(modifiedfromandtoDate: Date, name: string, isfrom: boolean) {
    var today = new Date();
    var toDate = new Date(modifiedfromandtoDate);
    var showfocuselement = false;
    const daysDifference = this.DateDiffInDays(this.allAppointmentsToDate, this.allAppointmentsFromDate);
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage('Invalid Day, Month or Year Range Detected.');
      showfocuselement = true;
    }
    if (this.hasValue(daysDifference) && daysDifference < 0) {
      if (isfrom) {
        this.ShowErrorMessage('From Date Should be Less Than or Equal to To Date');
        showfocuselement = true;
      }
    }
    if (showfocuselement) {
      const elementFound = this.AllAppointments.nativeElement.querySelector('[name="' + name + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    return true;
  }
  allAppointmentsSelectCustomerIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Practice',
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.practiceInformation = result;
      }
    });
  }
  allAppointmentsRemoveSelectedCustomerIconClickEvent() {
    this.practiceInformation = {}
  }
  allAppointmentsViewClickEvent() {
    let AppointmentId = 0;
    if (!(AppointmentId = this.getSelectedAppointment())) return;
    const dialogRef = this.dialogService.open({
      title: 'View Appointments',
      // Show component
      content: ViewAppointmentsPopupComponent,
      height: 698,
      width: 620,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.SelectedAppointmentInfo = this.allAppointmentsGrid.api.getSelectedRows()[0];
  }
  UpdateAppointmentsStatusComments(result, message) {
    const postData = result;
    postData.MeetingScheduledInfoID = this.getSelectedAppointment()
    this.commonhelperobj.PostData(Config.OASAppointmentsInformationAppnURL + 'UpdateAppointmentStatusComments', postData)
      .subscribe(response => {
        if (this.isError(response)) return;
        this.ShowSuccesseMessage(message)
        this.customerEmployeeAppointmentsList();
      });

  }
  getSelectedAppointment() {
    const selectedrow = this.allAppointmentsGrid.api.getSelectedRows();
    if (!this.hasValue(selectedrow) || !this.hasValue(selectedrow[0].MeetingScheduledInfoID) || selectedrow[0].MeetingScheduledInfoID <= 0) {
      this.ShowErrorMessage("Please Select Appointment.");
      document.getElementById("myAppointmentsSelectUser").focus();
      return 0;
    }
    return selectedrow[0].MeetingScheduledInfoID;
  }
  allAppointmentsSelectCreatedByIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Created By',
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.CreatedByInfo = result;
      }
    });
  }
  allAppointmentsRemoveSelectedCreatedByIconClickEvent() {
    this.CreatedByInfo = {};
  }
}
