
import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DialogCloseResult, DialogService,DialogRef } from '@progress/kendo-angular-dialog';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';
import { CheckListItemPopupComponent } from '../check-list-item-popup/check-list-item-popup.component';


@Component({
  selector: 'app-check-list-item',
  templateUrl: './check-list-item.component.html',
  styleUrls: ['./check-list-item.component.css']
})

export class CheckListItemComponent extends commonhelper implements OnInit {
  private commonhelperobj: commonHelperService;
  @ViewChild('CheckListItem') CheckListItem: ElementRef;
  checkListItemInfoId: any;
  PracticeID: any;
  public addPracticeCheckListModel: any = {};
  constructor(httpClient: HttpClient,private dialogService: DialogService, private dialog: DialogRef) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }
  public checkListItemSelectStatusDefaultItem = { CheckListItemStatusDescription: '-- Select --', CheckListItemStatus_InfoID: null };
  @ViewChild("checkListItem", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  selectedCheckListItemInfo: any;
  public checkListItemStatusList: any = [
    // { StatusId: 1, Status: 'Pending / Yet to Start' },
    // { StatusId: 2, Status: 'Completed' },
    // { StatusId: 3, Status: 'In Process' },
    // { StatusId: 4, Status: 'N / A' },

  ];
  ngOnInit() {
    this.getPracticeStatusList();
    if (this.checkListItemInfoId > 0) {
      this.getPracticeStatusCheckListItemInfo();
    }
  }
  checkListItemPopupCancelClickEvent() {
    this.dialog.close();
  }

  public getPracticeStatusList() {
    this.commonhelperobj.PostData(Config.OASPracticeCheckListAppnURL + 'GetPracticeCheckListItemStatusList', {}).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.checkListItemStatusList = serviceResponse.practiceCheckListItemsStatus;
    });
  }
  public getPracticeStatusCheckListItemInfo() {
    var postdatatoserice = {
      PracticeCheckListItemLinkedInfoID: this.checkListItemInfoId
    }
    this.commonhelperobj.PostData(Config.OASPracticeCheckListAppnURL + 'GetPracticeCheckListItem', postdatatoserice).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.addPracticeCheckListModel = serviceResponse.addPracticeCheckListModel;
    });
  }
  checkListItemPopupSaveClickEvent() {
    if (this.checkMandatoryValidations()) return;
    var PostDataToService = this.addPracticeCheckListModel;
    PostDataToService.PracticeID = this.PracticeID;
    this.commonhelperobj.PostData(Config.OASPracticeCheckListAppnURL + 'InsertorUpdatePracticeCheckListItems', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.dialog.close("Success");
    });
  }
  checkMandatoryValidations() {
    if (!this.hasValue(this.addPracticeCheckListModel.CheckListItemInfoID) || this.addPracticeCheckListModel.CheckListItemInfoID <= 0) {
      this.ShowErrorMessage("Please Select Check List Item")
      document.getElementById("checkListItemSelectCheckListItem").focus();
      return true;
    }
    if (!this.hasValue(this.addPracticeCheckListModel.CheckListItemLatestStatus) || this.addPracticeCheckListModel.CheckListItemLatestStatus <= 0) {
      this.ShowErrorMessage("Please Select Check List Status")
      const elementFound = this.CheckListItem.nativeElement.querySelector('[name="' + 'checkListItemSelectStatusDropdown' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return true;
    }
  }
  checkListItemSelectCheckListItemClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Check List Item',
      // Show component
      content: CheckListItemPopupComponent,
      height: 530,
      width: 390,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.selectedCheckListItemInfo = result;
        this.addPracticeCheckListModel.checkListItemName = this.selectedCheckListItemInfo.CheckListItemName;
        this.addPracticeCheckListModel.CheckListItemInfoID = this.selectedCheckListItemInfo.CheckListItemInfoID;
      }
    });
  }

  checkListItemRemoveSelectedCheckListItemClickEvent() {
    this.addPracticeCheckListModel.checkListItemName = "";
    this.addPracticeCheckListModel.CheckListItemInfoID = 0;
  }

}
