import { Component, OnInit } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ResponsiblepersonUsersListComponent } from 'src/app/responsibleperson-users-list/responsibleperson-users-list.component';

@Component({
  selector: 'app-responsible-person-popup',
  templateUrl: './responsible-person-popup.component.html',
  styleUrls: ['./responsible-person-popup.component.css']
})
export class ResponsiblePersonPopupComponent implements OnInit {

  dataToPopup: any
  constructor(private dialog: DialogRef, private dialogService: DialogService,) { }

  ngOnInit() {
  }
  selectResponsiblePersonCancelClickEvent() {
    this.dialog.close();
  }
  responsiblePersonPopupIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Responsible Person',
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550
    });
  }
}
