import { Injectable } from "@angular/core";

@Injectable()
export class AllBucketsConsolidateReportService {
  constructor() {}
  ChildRow = [
    "Created Date",
    "Bugs",
    "Concerns",
    "New Login",
    "Inactive/Suspended",
    "Bugs",
    "Concerns",
    "New Easy Form Design",
    "Existing Form Changes",
    "HCI Mapping",
    "Form Forwarding",
    "Easy Form Permissions",
    "Bugs",
    "Concerns",
    "EPCS Enabling",
    "Bugs",
    "Concerns",
    "Bugs",
    "Concerns",
    "Interface",
    "Bugs",
    "Concerns",
    "New Report",
    "Existing Report Changes",
    "Bugs",
    "Concerns",
    "New Remainders",
    "Ex. Remainders Changes",
    "Bugs",
    "Concerns",
    "New Fax Integration",
    "Bugs",
    "Concerns",
    "Bugs",
    "Concerns",
    "Bugs",
    "Concerns",
    "Bugs",
    "Concerns",
    "Enhacments",
    "Bugs",
    "Concerns",
    "Bugs",
    "Concerns",
    "Bugs",
    "Concerns",
    "Bugs",
    "Concerns",
    "Bugs",
    "Concerns",

  ];
}
