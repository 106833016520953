import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[integer]'
})
export class IntegerDirective {

  constructor() { }

  /**
   * Remove any character from input field except digits 0-9
   * @param event Event when user enters keyboard characters
   */
  @HostListener('keyup', ['$event'])
  onKeyPress(event: Event) {
    const inputElement: HTMLInputElement = event.target as HTMLInputElement;

    if (inputElement.value) {
      inputElement.value = inputElement.value.replace(/[^0-9]+/, '');
    }

  }

}
