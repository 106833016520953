import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-ecd-and-rbd-selection-popup',
  templateUrl: './ecd-and-rbd-selection-popup.component.html',
  styleUrls: ['./ecd-and-rbd-selection-popup.component.css']
})
export class EcdAndRbdSelectionPopupComponent implements OnInit {

  constructor(private dialog: DialogRef) { }

  ngOnInit() {
  }
  ecdAndRbdSelectionCancelClickEvent(){
    this.dialog.close();
  }
}
