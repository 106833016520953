import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { TaskService } from '../services/task.service';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-angular-review-details-popup',
  templateUrl: './angular-review-details-popup.component.html',
  styleUrls: ['./angular-review-details-popup.component.css']
})
export class AngularReviewDetailsPopupComponent implements OnInit {

  @ViewChild('angularReviewForm') angularReviewForm: ElementRef;
  
  @ViewChild('txtAngularReviewDetailsNoofIssuesReportedbyQAforthisTask') txtAngularReviewDetailsNoofIssuesReportedKendoElement: NumericTextBoxComponent;
  @ViewChild('txtAngularReviewDetailsTaskQualityScore') txtAngularReviewDetailsTaskQualityScoreKendoElement: NumericTextBoxComponent;
  @ViewChild('txtangularReviewDetailsTaskAvailabilityScore') txtangularReviewDetailsTaskAvailabilityScoreKendoElement: NumericTextBoxComponent;
  @ViewChild('txtangularReviewDetailsTaskCodingStandardsCommentingScore') txtangularReviewDetailsTaskCodingStandardsCommentingScoreKendoElement: NumericTextBoxComponent;
  
  angularReviewFormGroup: FormGroup;

  angularReviewDetailsSelectTeamLeadList: any[];
  angularReviewDetailsSelectDeveloperList: any[];

  public angularReviewDefaultTeamLeadItem = { EmployeeFullName: '-- Select Team Lead --', EmployeeId: null };
  public angularReviewDefaultDeveloperItem = { EmployeeFullName: '-- Select Developer --', EmployeeId: null };

  selectedAngularTaskToAddReview: any

  constructor(
    private dialog: DialogRef,
    private taskService: TaskService,
    private commonHelper: commonhelper,
    private commonHelperService: commonHelperService) { }

  async ngOnInit() {
    this.initializeEmptyFormGroup();
    
    await this.getAngularTeamLeadData();
    await this.getAngularDeveloperData();
    await this.getSavedAngularReview();
  }

  private initializeEmptyFormGroup() {
    this.angularReviewFormGroup = new FormGroup({
      angularReviewDetailsEnterTaskName: new FormControl(this.selectedAngularTaskToAddReview.IssueSubject, Validators.required),
      angularReviewDetailsNoofIssuesReportedbyQAforthisTask: new FormControl(null, Validators.required),
      angularReviewDetailsTaskQualityScoreIncludingScenariosNaviagationVerification: new FormControl(null, Validators.required),
      agularReviewDetailsTaskAvailabilityScore: new FormControl(null, Validators.required),
      angularReviewDetailsTaskCodingStandardsCommentingScore: new FormControl(null, Validators.required),
      angularReviewDetailsTeamLeadOverallCommentsaboutcurrenttask: new FormControl(null, Validators.required),
      angularReviewDetailsEnterTaskDescription: new FormControl(null, Validators.required),
      angularReviewDetailsSelectTeamLead: new FormControl(null, Validators.required),
      angularReviewDetailsSelectDeveloper: new FormControl(null, Validators.required),
      angularReviewDetailsSelectDateModel: new FormControl(null, Validators.required)
    })
  }

  // API Call to get Angular Team lead Data
  private async getAngularTeamLeadData() {
    const data = {
      IsTaskReviewLeadEmployee: false,
      TaskReviewDepartmentInfoID: 3
    }
    const response = await this.taskService.taskReviewTeamLeadList(data).toPromise()
    if (this.commonHelper.isError(response)) {
      return;
    }
    this.angularReviewDetailsSelectTeamLeadList = response;
  }

  // API Call to get Angular Developer Data
  private async getAngularDeveloperData() {
    const data = {
      IsTaskReviewLeadEmployee: true,
      TaskReviewDepartmentInfoID: 3
    }
    const response = await this.taskService.taskReviewTeamLeadList(data).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }
    this.angularReviewDetailsSelectDeveloperList = response;
  }

  private async getSavedAngularReview() {

    const preparePayloadToGetTaskReview = {
      TaskNumber: this.selectedAngularTaskToAddReview.TaskInfoID,
      TaskDoneByTeamLeadId: 2020040206074757,
      TaskDoneByEmployeeId: 2019022822551956,
    }
    const response = await this.taskService.getTaskReviewAPIAction(preparePayloadToGetTaskReview).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }
  }

  checkAllMandatoryFieldsAreFilled() {
 
    if (this.angularReviewFormGroup.get('angularReviewDetailsEnterTaskName').invalid) {
      this.angularReviewForm.nativeElement.querySelector(`#txtAngularreviewDetailsEnterTaskName`).focus();    
      this.commonHelperService.ShowErrorMessage('Fill Task Name same as Devops name.');
      return true;
    }
    
    if (this.angularReviewFormGroup.get('angularReviewDetailsNoofIssuesReportedbyQAforthisTask').invalid) {
      this.txtAngularReviewDetailsNoofIssuesReportedKendoElement.focus()
      this.commonHelperService.ShowErrorMessage('Add task availability score.');
      return true;
    }

    if (this.angularReviewFormGroup.get('angularReviewDetailsTaskQualityScoreIncludingScenariosNaviagationVerification').invalid) {
      this.txtAngularReviewDetailsTaskQualityScoreKendoElement.focus()
      this.commonHelperService.ShowErrorMessage('Add task availability score.');
      return true;
    }

    if (this.angularReviewFormGroup.get('agularReviewDetailsTaskAvailabilityScore').invalid) {
      this.txtangularReviewDetailsTaskAvailabilityScoreKendoElement.focus()
      this.commonHelperService.ShowErrorMessage('Add task availability score.');
      return true;
    }

    if (this.angularReviewFormGroup.get('angularReviewDetailsTaskCodingStandardsCommentingScore').invalid) {
      this.txtangularReviewDetailsTaskCodingStandardsCommentingScoreKendoElement.focus()
      this.commonHelperService.ShowErrorMessage('Add Task Coding Standards commenting score.');
      return true;
    }
    
    if (this.angularReviewFormGroup.get('angularReviewDetailsTeamLeadOverallCommentsaboutcurrenttask').invalid) {
      this.angularReviewForm.nativeElement.querySelector(`#txtangularReviewDetailsTeamLeadOverallCommentsaboutcurrenttask`).focus();    
      this.commonHelperService.ShowErrorMessage('Add Overall Comments.');
      return true;
    }

    if (this.angularReviewFormGroup.get('angularReviewDetailsEnterTaskDescription').invalid) {
      this.angularReviewForm.nativeElement.querySelector(`#txtAngularreviewDetailsEnterTaskDescription`).focus();    
      this.commonHelperService.ShowErrorMessage('Add Task Description.');
      return true;
    }

    if (this.angularReviewFormGroup.get('angularReviewDetailsSelectTeamLead').invalid) {
      const elementFound = this.angularReviewForm.nativeElement.querySelector('[name="' + 'angularReviewDetailsSelectTeamLeadDropdown' + '"]');
      elementFound.children[0].focus();
      this.commonHelperService.ShowErrorMessage('Add Team Lead.');
      return true;
    }

    if (this.angularReviewFormGroup.get('angularReviewDetailsSelectDeveloper').invalid) {
      const elementFound = this.angularReviewForm.nativeElement.querySelector('[name="' + 'angularReviewDetailsSelectDeveloperDropdown' + '"]');
      elementFound.children[0].focus();
      this.commonHelperService.ShowErrorMessage('Add Developer.');
      return true;
    } 
    
    if (this.angularReviewFormGroup.get('angularReviewDetailsSelectDateModel').invalid) {
      const elementFound = this.angularReviewForm.nativeElement.querySelector('[name="' + 'angularReviewDetailsSelectDate' + '"]');
      elementFound.children[0].focus();    
      this.commonHelperService.ShowErrorMessage('Select Date.');
      return true;
    }  
  }

  async angularReviewDetailsSaveClick() {
    if (this.checkAllMandatoryFieldsAreFilled()) {
      return;
    }

    const preparePayloadForInsertReview = {
      TaskInfoId: this.selectedAngularTaskToAddReview.TaskInfoID,
      TaskNameFromDevops: this.angularReviewFormGroup.value.angularReviewDetailsEnterTaskName,
      TaskReviewDepartmentInfoId: 3, // For Angular Review id is 3
      AngularTaskDonebyTeamLeadID: this.angularReviewFormGroup.value.angularReviewDetailsSelectTeamLead,
      AngularTaskDonebyEmployeeID: this.angularReviewFormGroup.value.angularReviewDetailsSelectDeveloper,
      NoIssuesReportedByQa: this.angularReviewFormGroup.value.angularReviewDetailsNoofIssuesReportedbyQAforthisTask,
      TaskQualityScore: this.angularReviewFormGroup.value.angularReviewDetailsTaskQualityScoreIncludingScenariosNaviagationVerification,
      TaskAvailabilityScore: this.angularReviewFormGroup.value.agularReviewDetailsTaskAvailabilityScore,
      TaskCodingStandardsAndCommenting: this.angularReviewFormGroup.value.angularReviewDetailsTaskCodingStandardsCommentingScore,
      TeamLeadOverAllComments: this.angularReviewFormGroup.value.angularReviewDetailsTeamLeadOverallCommentsaboutcurrenttask,
      TaskDescription: this.angularReviewFormGroup.value.angularReviewDetailsEnterTaskDescription,
      TaskDate: this.angularReviewFormGroup.value.angularReviewDetailsSelectDateModel ? this.angularReviewFormGroup.value.angularReviewDetailsSelectDateModel.toLocaleDateString() : '',
    }

    // console.log(preparePayloadForInsertReview);
    const response = await this.taskService.taskReviewInsertAPIAction(preparePayloadForInsertReview).toPromise();
    if (this.commonHelper.isError(response)) {
      return;
    }

    this.commonHelperService.ShowErrorMessage('Task Review Added Successfully.');
    this.dialog.close();
  }
  

  angularReviewDetailsSelectDate(event: any) {  }

  angularReviewDetailsCancelClickEvent() {
    this.dialog.close();
  }
}
