import { Injectable } from "@angular/core";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable()
export class ExcelService {
  constructor() {}
  public exportAsExcelFile(
    excelColumns: any[],
    excelData: any[],
    fileName: string
  ): void {
    let workbook: any = new Workbook();
    let worksheet = workbook.addWorksheet(fileName);

    worksheet.columns = excelColumns;
    // worksheet.mergeCells("A1:C1");
    // worksheet.mergeCells("D1:F1");
    // worksheet.mergeCells("G1:I1");
    // worksheet.addRow([3, 'Sam','nnn','aaa','aaaa','eeee','rrrr','rrrrr','tttt'])
  //  console.log(worksheet, "WorkSheet");
    worksheet.addRows(excelData, "n");
    for (let rowIndex = 1; rowIndex <= worksheet.rowCount; rowIndex++) {
      if (rowIndex == 1) {
        worksheet.getRow(rowIndex).font = {
          name: "Times New Roman",
          family: 4,
          size: 14,
          bold: true,
          color: { argb: "FFFFFF" },
        };
        worksheet.getRow(rowIndex).alignment = {
          wrapText: true,
          horizontal: "center",
        };
        worksheet.getRow(rowIndex).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "0069d9 " },
        };
      }
      // else if(rowIndex==2){
      //   worksheet.getRow(rowIndex).font = {
      //     name: "Times New Roman",
      //     family: 4,
      //     size: 14,
      //     bold: true,
      //     color: { argb: "FFFFFF" },
      //   };
      //   worksheet.getRow(rowIndex).alignment = {
      //     wrapText: true,
      //     horizontal: "center",
      //   };
      //   worksheet.getRow(rowIndex).fill = {
      //     type: "pattern",
      //     pattern: "solid",
      //     fgColor: { argb: "0069d9 " },
      //   };
      // }
      else {
        worksheet.getRow(rowIndex).alignment = {
          horizontal: "left",
          wrapText: true,
        };
      }
    }
    workbook.xlsx.writeBuffer().then((excelData) => {
      let blob = new Blob([excelData], { type: EXCEL_TYPE });
      // console.log(blob,"blob");
      saveAs(blob, fileName + "_" + new Date().getTime() + EXCEL_EXTENSION);

    });
  }

  public exportAsExcelForConsolidateFile(
    excelData: any[],
    fileName: string,
    childRow: any[]
  ): void {
    let workbook: any = new Workbook();
    let worksheet = workbook.addWorksheet(fileName);

    worksheet.getCell("A1").value = "";
    worksheet.mergeCells("B1:E1");
    worksheet.getCell("B1").value = "Login";

    worksheet.mergeCells("F1:L1");
    worksheet.getCell("F1").value = "Easy Form Requets";

    worksheet.mergeCells("M1:O1");
    worksheet.getCell("N1").value = "ERX Reports";

    worksheet.mergeCells("P1:Q1");
    worksheet.getCell("Q1").value = "Order Rx/Medications";

    worksheet.mergeCells("R1:S1");
    worksheet.getCell("S1").value = "Labs/X-Rays/Vaccines";

    worksheet.mergeCells("U1:X1");
    worksheet.getCell("V1").value = "Reports";

    worksheet.mergeCells("Y1:AB1");
    worksheet.getCell("Y1").value = "Remainders";

    worksheet.mergeCells("AC1:AE1");
    worksheet.getCell("AC1").value = "Fax/Document Auto Uploader";

    worksheet.mergeCells("AF1:AG1");
    worksheet.getCell("AF1").value = "Document Management";

    worksheet.mergeCells("AH1:AI1");
    worksheet.getCell("AH1").value = "Appointment Scheduler (1 to 1 & Group)";

    worksheet.mergeCells("AJ1:AK1");
    worksheet.getCell("AJ1").value = "CCDA/MIPS/Quality Measures";

    worksheet.mergeCells("AL1:AM1");
    worksheet.getCell("AL1").value = "Billing";

    worksheet.mergeCells("AN1");
    worksheet.getCell("AN1").value = "New Enhacments";

    worksheet.mergeCells("AO1:AP1");
    worksheet.getCell("AP1").value = "Admission Details";

    worksheet.mergeCells("AQ1:AR1");
    worksheet.getCell("AQ1").value = "Bed Census";

    worksheet.mergeCells("AS1:AT1");
    worksheet.getCell("AS1").value = "Demographics";

    worksheet.mergeCells("AU1:AV1");
    worksheet.getCell("AU1").value = "New Client Registation";

    worksheet.mergeCells("AW1:AX1");
    worksheet.getCell("AX1").value = "Others";

    worksheet.getRow(2).values = childRow;
    //#region "Coloumn Widths Custom"

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 10;1
    worksheet.getColumn(3).width = 12;2
    worksheet.getColumn(4).width = 18;3
    worksheet.getColumn(5).width = 30;4
    worksheet.getColumn(6).width = 12;5
    worksheet.getColumn(7).width = 12;6
    worksheet.getColumn(8).width = 28;7
    worksheet.getColumn(9).width = 28;8
    worksheet.getColumn(10).width = 28;9
    worksheet.getColumn(11).width = 20;10
    worksheet.getColumn(12).width = 25;11
    worksheet.getColumn(13).width = 10;12
    worksheet.getColumn(14).width = 12;13
    worksheet.getColumn(15).width = 25;14
    worksheet.getColumn(16).width = 10;15
    worksheet.getColumn(17).width = 12;16
    worksheet.getColumn(18).width = 10;17
    worksheet.getColumn(19).width = 12;18
    worksheet.getColumn(20).width = 12;19
    worksheet.getColumn(21).width = 10;20
    worksheet.getColumn(22).width = 12;21
    worksheet.getColumn(23).width = 22;22
    worksheet.getColumn(24).width = 30;23
    worksheet.getColumn(25).width = 10;24
    worksheet.getColumn(26).width = 12;25
    worksheet.getColumn(27).width = 22;26
    worksheet.getColumn(28).width = 30;27
    worksheet.getColumn(29).width = 10;28
    worksheet.getColumn(30).width = 12;29
    worksheet.getColumn(31).width = 25;30
    worksheet.getColumn(32).width = 10;31
    worksheet.getColumn(33).width = 12;32
    worksheet.getColumn(34).width = 10;33
    worksheet.getColumn(35).width = 12;34
    worksheet.getColumn(36).width = 10;35
    worksheet.getColumn(37).width = 12;36
    worksheet.getColumn(38).width = 10;37
    worksheet.getColumn(39).width = 12;38
    worksheet.getColumn(40).width = 22;39
    worksheet.getColumn(41).width = 10;40
    worksheet.getColumn(42).width = 12;41
    worksheet.getColumn(43).width = 10;42
    worksheet.getColumn(44).width = 12;43
    worksheet.getColumn(45).width = 10;
    worksheet.getColumn(46).width = 12;
    worksheet.getColumn(47).width = 10;
    worksheet.getColumn(48).width = 12;
    worksheet.getColumn(49).width = 10;
    worksheet.getColumn(50).width = 12;
    //#endregion
    // worksheet.columns=excelColumns;
    // console.log(worksheet, "WorkSheet");

    let data: Array<any> = [];
    excelData.forEach((ele) => {
      data.push(Object.values(ele));
    });
    // console.log(data, "Just Testing");
    worksheet.addRows(data, "n");
    for (let rowIndex = 1; rowIndex <= worksheet.rowCount; rowIndex++) {
      if (rowIndex == 1) {
        worksheet.getRow(rowIndex).font = {
          name: "Times New Roman",
          family: 4,
          size: 16,
          bold: true,
          color: { argb: "ffff2d" },
        };
        worksheet.getRow(rowIndex).border = {
          // top: {style:'double', color: {argb:'FF00FF00'}},
          left: { style: "thin", color: { argb: "ebebeb" } },
          bottom: { style: "thin", color: { argb: "ffffff" } },
          right: { style: "thin", color: { argb: "ebebeb" } },
        };
        worksheet.getRow(rowIndex).alignment = {
          wrapText: true,
          horizontal: "center",
        };

        worksheet.getRow(rowIndex).height=30;

        worksheet.getRow(rowIndex).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "4a8ff0" },
        };
      } else if (rowIndex == 2) {
        worksheet.getRow(rowIndex).font = {
          // name: "Times New Roman",
          // family: 4,
          size: 12,
          bold: true,
          color: { argb: "FFFFFF" },
        };
        worksheet.getRow(rowIndex).alignment = {
          wrapText: true,
          horizontal: "center",
        };
        worksheet.getRow(rowIndex).border = {
          // top: {style:'double', color: {argb:'FF00FF00'}},
          left: { style: "thin", color: { argb: "ebebeb" } },
          bottom: { style: "thin", color: { argb: "ffffff" } },
          right: { style: "thin", color: { argb: "ebebeb" } },
        };
        worksheet.getRow(rowIndex).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "0069d9 " },
        };
      } else {
        worksheet.getRow(rowIndex).alignment = {
          horizontal: "right",
          wrapText: true,
        };
      }
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      saveAs(blob, fileName + "_" + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
}
