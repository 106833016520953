import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { CustomerAddTicketPopupComponent } from '../customer-add-ticket-popup/customer-add-ticket-popup.component';

@Component({
  selector: 'app-ticket-information',
  templateUrl: './ticket-information.component.html',
  styleUrls: ['./ticket-information.component.css']
})
export class TicketInformationComponent implements OnInit {
  @ViewChild("CustomerAddTicketPopup", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  columnDefs = [
    { headerName: 'Ticket #', field: 'TicketNumber', width: 90, tooltipField: 'TicketNumber', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Subject', field: 'Subject', width: 300, tooltipField: 'Subject', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Reported Date', field: 'ReportedDate', width: 150, tooltipField: 'ReportedDate', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Severity', field: 'Severity', width: 120, tooltipField: 'Severity', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Status', field: 'Status', width: 100, tooltipField: 'Status', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Last Response Date', field: 'LastResponseDate', width: 150, tooltipField: 'LastResponseDate', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Total Responses', field: 'TotalResponses', width: 210, tooltipField: 'TotalResponses', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
  ];
  onTicketInformationGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  public ticketInformationSelectedTicketsStatus ={ TicketsStatus: 'Open Tickets', TicketsStatusID: null };
  public ticketInformationUserRoledefaultItem ={ UserRole: 'Show All', UserRoleID: null };
  public ticketInformationTicketsStatusList: any = [
    { TicketsStatusID: 1, TicketsStatus: 'Closed Tickets' },
  
  ];
  public ticketInformationSelectUserRoleList: any = [
    { UserRoleID: 1, UserRole: 'Created by Me' },
    { UserRoleID: 2, UserRole: 'CC to Me' },
  
  ];
  constructor(private http: HttpClient,httpClient: HttpClient,private dialogService: DialogService ) { }

  ngOnInit() {
  }
  
  ticketInformationAddClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Add Ticket',
      // Show component
      content: CustomerAddTicketPopupComponent,
      height: 710,
      width: 1400,
      appendTo: this.ContainerRef
    });
  }
}
