import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { GridOptions } from 'ag-grid-community';
import { AgGridNg2 } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { commonHelperService } from '../common/common.service';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee } from '../edit-task-customization-based-on-users/edit-task-customization-based-on-users.component';
import { TaskService } from '../services/task.service';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { IntlService } from '@progress/kendo-angular-intl';
@Component({
  selector: 'app-report-by-responsible-employee',
  templateUrl: './report-by-responsible-employee.component.html',
  styleUrls: ['./report-by-responsible-employee.component.css']
})
export class ReportByResponsibleEmployeeComponent extends commonhelper implements OnInit {
  @ViewChild('AllReportByResponsibleEmployee') AllReportByResponsibleEmployee: AgGridNg2;

  public gridOptions: GridOptions;
  public unCustomizedGridOptions: GridOptions;
  public allReportByResponsibleEmployeeGridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public selectedRowsString = undefined;
  public selectedUncustomizedMailRowsString = undefined;
  public overlayNoRowsTemplate;
  allReportByResponsibleEmployeeColumnDefs = [
    { headerName: 'Responsible Employee Name', field: 'EmployeeName', width: 282, tooltipField: 'EmployeeName', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Task ID', field: 'TaskID', width: 150, tooltipField: 'TaskID', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Task Description', field: 'TaskDescription', width: 407, tooltipField: 'TaskDescription', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Latest Forwarded to me', field: 'LatestForwardedToMe', width: 170, tooltipField: 'LatestForwardedToMe', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'No. of days from date of task assigned', field: 'NoOfDays', width: 250, tooltipField: 'NoOfDays', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Current Working Status', field: 'CurrentWorkingStatus', width: 321, tooltipField: 'CurrentWorkingStatus', cellClass: 'no-border cell-wrap-text', autoHeight: true },
    // { headerName: '', field: '', width: 154, cellClass: 'no-border cell-wrap-text', autoHeight: true },
  ];



  // this array is used by the grid to render data in html
  public rowData = [];
  private commonhelperobj: commonHelperService;
  public allReportByResponsibleEmployeerowData = [];
  AllReportByResponsibleEmployeeName: any;
  AllReportByResponsibleEmployeeTaskID: any;
  AllReportByResponsibleEmployeeSearchCurrentWorkingStatusType: any;
  dataAllTasksSummaryByForwardedToMeBucketTaskTypeList: any;
  allReportByResponsibleEmployeeToDate: Date;
  allReportByResponsibleEmployeeFromDate: Date;


  /*** Declare all of the services in the constructor method * */
  constructor(private dialogService: DialogService, http: HttpClient, private intl: IntlService) {
    super();

    this.commonhelperobj = new commonHelperService(http);

  }
  public defaultAllReportByResponsibleEmployeeSearchCurrentWorkingStatusType = { Task_Current_Working_Status: '--Select Current Working Status --', Task_Current_Working_Status_InfoID: undefined }
  /** It will call all of the methods when the window loads **/
  ngOnInit() {
    $("#txtAllReportByResponsibleEmployeeSearchEmployee").focus();

    this.GetCurrentStatusList();
    this.allReportByResponsibleEmployeeToDate = new Date();
    this.allReportByResponsibleEmployeeFromDate = new Date(this.allReportByResponsibleEmployeeToDate);
    this.allReportByResponsibleEmployeeFromDate.setMonth(this.allReportByResponsibleEmployeeToDate.getMonth() - 1);

    this.ResponsibleEmployeelist();

  }
  /** This method is used to bind the list to the Grid **/
  public ResponsibleEmployeelist() {
    // alert(this.allReportByResponsibleEmployeeFromDate);
    const PostDataToService = {
      /*** The Variables are used for filtering the lists  based on data entered on the respective fields **/
      Employee_Name: this.AllReportByResponsibleEmployeeName,
      TaskNumber: this.AllReportByResponsibleEmployeeTaskID,
      Task_Current_Working_Status_InfoID: this.AllReportByResponsibleEmployeeSearchCurrentWorkingStatusType,
      ToDate: this.formatValue(this.allReportByResponsibleEmployeeToDate),
      FromDate: this.formatValue(this.allReportByResponsibleEmployeeFromDate)

    };
    return this.commonhelperobj.PostData(Config.OASOrganizationAppnURL + 'ResponsibleEmployeeList', PostDataToService)
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.allReportByResponsibleEmployeerowData = response.ResponsibleEmployeeModelList;

      });
  }

  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, 'd')}` : '';
  }
  /*** The method is used to bind the list to the currentworkingstatus **/
  private GetCurrentStatusList() {

    var PostDataToService = {

    };
    this.commonhelperobj.PostData(Config.OASAppnURL + 'NewCurrentWorkingStatusList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.dataAllTasksSummaryByForwardedToMeBucketTaskTypeList = serviceResponse.newCurrentWorkingStatusListModelList;
    });

  }

}

