import { Injectable } from '@angular/core';


@Injectable()
export class CustomerTicketsService {
    constructor() { }
    ExcelColumnsInfo = [
        { header: "Task #", key: "TaskNumber", width: 10 },
        { header: "Customer Name", key: "PracticeName", width: 30 },
        { header: "Module Name", key: "ModuleName", width: 30 },
        { header: "Task Type", key: "TaskType", width: 30 },
        { header: "Task Subject", key: "IssueSubject", width: 60 },
        { header: "Priority", key: "SeverityDesc", width: 10 },
        { header: "Task Status", key: "TaskLatestStatusName", width: 20 },
        { header: "Responsible Person", key: "ResponsbilerPerson", width: 30 },
        { header: "Created Date", key: "IssueCreatedOn", width: 25 },
        { header: "Created By", key: "CreatedBy", width: 30 },
        // { header: "RBD", key: "RequiredByDate", width: 25 },
        // { header: "ECD", key: "ExpectedDate", width: 25 },
        // { header: "Task Updated Date", key: "TaskCompletedDate", width: 25 },
    ]

}

