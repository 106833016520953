import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
} from "@angular/core";
import { GridOptions, GridSizeChangedEvent } from "ag-grid-community";
// tslint:disable-next-line:max-line-length
import { AddTaskCustomizationInformationComponent } from "../add-task-customization-information/add-task-customization-information.component";
import {
  DialogService,
  DialogCloseResult,
  DialogComponent,
  DialogRef,
} from "@progress/kendo-angular-dialog";
// tslint:disable-next-line:max-line-length
import { AddTaskCustomizationBasedOnEmailAddressComponent } from "../add-task-customization-based-on-email-address/add-task-customization-based-on-email-address.component";
import { Config } from "../config";
import { commonhelper } from "../common/commonhelper";
import { commonHelperService } from "../common/common.service";
import { HttpClient } from "@angular/common/http";
import { AgGridNg2 } from "ag-grid-angular";
import { LeftNavService } from "../common/Leftnav.service";
// tslint:disable-next-line:max-line-length
import { EditTaskInformationBasedOnEmailAddressComponent } from "../edit-task-information-based-on-email-address/edit-task-information-based-on-email-address.component";
// tslint:disable-next-line:max-line-length
import { EditTaskCustomizationBasedOnUsersComponent } from "../edit-task-customization-based-on-users/edit-task-customization-based-on-users.component";

@Component({
  selector: "app-task-customization-information",
  templateUrl: "./task-customization-information.component.html",
  styleUrls: ["./task-customization-information.component.css"],
})
export class TaskCustomizationInformationComponent
  extends commonhelper
  implements OnInit
{
  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private LeftNav: LeftNavService
  ) {
    super();
    this.commonhelperobj = new commonHelperService(http);
    // tslint:disable-next-line:max-line-length
    this.overlayNoRowsTemplate =
      "<span style='Font-size:16px;color:#00529B;background-color:#BDE5F8;margin: 50px 0px;padding: 12px;border-radius: 4px;'>No Data Available</span>";
    // tslint:disable-next-line:max-line-length
    this.overlaySecondNoRowsTemplate =
      "<span style='Font-size:16px;color:#00529B;background-color:#BDE5F8;margin: 50px 0px;padding: 12px;border-radius: 4px;'>Click on Left Side Link.</span>";
  }
  @ViewChild("agGridPracticeNamesEmail") agGridPracticeNamesEmail: AgGridNg2;
  @ViewChild("agGridPracticeNamesUserName")
  agGridPracticeNamesUserName: AgGridNg2;
  @ViewChild("agGridPracticeNamesEmailsList")
  agGridPracticeNamesEmailsList: AgGridNg2;
  @ViewChild("agGridPracticeNamesUserNamesFullInfo")
  agGridPracticeNamesUserNamesFullInfo: AgGridNg2;
  @ViewChild("TaskCustomizationBasedOnUserName")
  TaskCustomizationBasedOnUserName: ElementRef;

  @ViewChild("TaskCustomizationBasedOnUserName", { read: ViewContainerRef })
  public containerRef: ViewContainerRef;

  private commonhelperobj: commonHelperService;
  private overlayNoRowsTemplate;
  selectedRow: any;
  datasource: any;
  private gridOptions: GridOptions;
  taskCustomizationGridOptions: any;
  public result;
  public getEmailAddressListInfo: any;
  public usersListInfo: any;
  public usersCustomizationListInfo: any;
  public selectedRowsString: {};
  public gridApi;
  public gridColumnApi;
  public usersListInfo1: any;
  public SearchPracticeID: any;
  SearchPracticeName: any;
  SearchUserName: any;
  SearchResponsiblePerson: any = "";
  public departmentNameField: HTMLElement;
  private overlaySecondNoRowsTemplate;
  // tslint:disable-next-line:member-ordering
  public ViewSubjectwidth;
  public ParticipantsWidth;
  public ObserversWidth;
  public SearchEmailName: any;

  // email address grids
  getPracticeNamesBasedOnEmailAddressColumns = [
    { headerName: "Email ID", field: "EHREMailIDs", width: 400 },
    // { headerName: 'Support Group', field: 'IssueSupportGroupName', width: 200 },
    {
      headerName: "Responsible Person",
      field: "ReponsibleEmployee",
      width: 310,
      suppressNavigable: true,
      cellClass: "no-border",
    },
    {
      headerName: "Additional Responsible Persons",
      field: "Participants",
      width: 310,
      suppressNavigable: true,
      cellClass: "no-border",
    },
    {
      headerName: "Observers",
      field: "Observers",
      width: 310,
      suppressNavigable: true,
      cellClass: "no-border",
    },
  ];
  onPracticeNamesEmailGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  getPracticeNamesBasedOnEmailAddress = [];

  agGridPracticeNamesEmailsColumnDefs = [
    { headerName: "Email ID", field: "EHREMailIDs" },
  ];
  getPracticeNamesBasedOnUserNamesColumns = [
    {
      headerName: "Practice ID",
      field: "PracticeId",
      width: 110,
      suppressNavigable: true,
      cellClass: "cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Practice Name",
      field: "PracticeName",
      width: 330,
      suppressNavigable: true,
      cellClass: "cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Support Group",
      field: "IssueSupportGroupName",
      width: 180,
      suppressNavigable: true,
      cellClass: "cell-wrap-text",
      autoHeight: true,
    },
  ];
  onPracticeNamesUserNameGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  getPracticeNamesBasedOnUserNames = [];
  agGridPracticeNamesEmailsDataSource = [];

  getUserNamesOnTaskSelectcolumnDefs = [
    {
      headerName: "User Name",
      field: "EmployeeName",
      width: 250,
      suppressNavigable: true,
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    // tslint:disable-next-line:max-line-length
    {
      headerName: "Responsible Person",
      field: "ReponsibleEmployee",
      width: 240,
      suppressNavigable: true,
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Additional Responsible Persons",
      field: "Participants",
      width: 200,
      suppressNavigable: true,
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    {
      headerName: "Observers",
      field: "Observers",
      width: 180,
      suppressNavigable: true,
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
    // {
    //   headerName: '',
    //   field: 'icon',
    //   width: 80,
    //   suppressNavigable: true,
    //   cellClass: 'no-border',
    //   cellRenderer: this.ageCellRendererFunc(),
    // }
  ];

  onPracticeNamesUserNamesFullInfoGridSizeChanged(
    params: GridSizeChangedEvent
  ) {
    params.api.sizeColumnsToFit();
  }
  // tslint:disable-next-line:member-ordering
  getUserNamesOnTaskSelectDataSource = [];
  // getPracticeNamesBasedOnEmailAddress = [
  //   // tslint:disable-next-line:max-line-length
  //   { Practice: 'Anusha', PracticeName: 'anusha.chiruvolu@adaptamed.org' },
  //   // tslint:disable-next-line:max-line-length
  //   { Practice: 'Lakshmi', PracticeName: 'lakshmi.bathina@adaptamed.com' },
  //   // tslint:disable-next-line:max-line-length
  //   { Practice: 'Sanjay', PracticeName: 'Sanjay@adaptamed.com' },
  // ];
  rowSelection = "single";
  // ageCellRendererFunc() {
  //   // tslint:disable-next-line:max-line-length
  //   // return `<span (click)="editClickEventOfGrid($index);"><a class="k-icon k-i-hyperlink-open"
  //   // style="cursor:pointer" (click)="editClickEventOfGrid($index);"></a></span>`;
  // }
  // ageCellRendererFunc() {
  //   const eSpan = document.createElement('button');
  //   eSpan.innerHTML = 'Edit';
  //   eSpan.addEventListener('click', function () {
  //     console.log('button clicked');
  //   });
  //   return eSpan.innerHTML;
  // }

  ngOnInit() {
    setTimeout(() => {
      this.departmentNameField =
        this.TaskCustomizationBasedOnUserName.nativeElement.querySelector(
          "#divSearchPracticeName"
        );
      this.departmentNameField.focus();
    }, 200);
    // this.SearchPracticeID = '';
    this.SearchPracticeName = "";
    this.SearchUserName = "";
    this.SearchResponsiblePerson = "";
    this.GetTaskCustomizationListBasedOnUserNames();
    this.GetTaskCustomizationListBasedOnEmailAddress();
    for (
      let i = 0;
      i < this.getPracticeNamesBasedOnUserNamesColumns.length;
      i++
    ) {
      switch (this.getPracticeNamesBasedOnUserNamesColumns[i].field) {
        case "PracticeName":
          if ($(window).width() > 1700) {
            this.getPracticeNamesBasedOnUserNamesColumns[1].width = 330;
          } else if ($(window).width() < 1700 && $(window).width() >= 1670) {
            this.getPracticeNamesBasedOnUserNamesColumns[1].width = 1040;
          } else if ($(window).width() < 1670 && $(window).width() >= 1610) {
            this.getPracticeNamesBasedOnUserNamesColumns[1].width = 1000;
          } else if ($(window).width() < 1610 && $(window).width() >= 1480) {
            this.getPracticeNamesBasedOnUserNamesColumns[1].width = 900;
          } else if ($(window).width() < 1480 && $(window).width() >= 1400) {
            this.getPracticeNamesBasedOnUserNamesColumns[1].width = 780;
          } else if ($(window).width() < 1400 && $(window).width() >= 1300) {
            this.getPracticeNamesBasedOnUserNamesColumns[1].width = 700;
          } else if ($(window).width() < 1300 && $(window).width() >= 1200) {
            this.getPracticeNamesBasedOnUserNamesColumns[1].width = 620;
          } else if ($(window).width() < 1200 && $(window).width() >= 1100) {
            this.getPracticeNamesBasedOnUserNamesColumns[1].width = 480;
          } else if ($(window).width() < 1100 && $(window).width() >= 1000) {
            this.getPracticeNamesBasedOnUserNamesColumns[1].width = 330;
          } else if ($(window).width() < 1000 && $(window).width() >= 700) {
            this.getPracticeNamesBasedOnUserNamesColumns[1].width = 340;
          } else if ($(window).width() < 700 && $(window).width() >= 300) {
            this.getPracticeNamesBasedOnUserNamesColumns[1].width = 280;
          }
          break;

        // case"Participants":
        // if ($(window).width() > 1700) {
        // this.getUserNamesOnTaskSelectcolumnDefs[2].width = 200;
        // }
        // else if ($(window).width() < 1700 && $(window).width() >= 1670) {
        //   this.getUserNamesOnTaskSelectcolumnDefs[2].width = 900;
        // }
        // else if ($(window).width() < 1670 && $(window).width() >= 1610) {
        //   this.getUserNamesOnTaskSelectcolumnDefs[2].width = 900;
        // }
        // else if ($(window).width() < 1610 && $(window).width() >= 1480) {
        //   this.getUserNamesOnTaskSelectcolumnDefs[2].width = 900;
        // }
        // else if ($(window).width() < 1480 && $(window).width() >= 1400) {
        //   this.getUserNamesOnTaskSelectcolumnDefs[2].width = 900;
        // }
        // else if ($(window).width() < 1400 && $(window).width() >= 1300) {
        //   this.getUserNamesOnTaskSelectcolumnDefs[2].width = 900;
        // }
        // else if ($(window).width() < 1300 && $(window).width() >= 1200) {
        //   this.getUserNamesOnTaskSelectcolumnDefs[2].width = 900;
        // }
        // else if ($(window).width() < 1200 && $(window).width() >= 1100) {
        //   this.getUserNamesOnTaskSelectcolumnDefs[2].width = 900;
        // }
        // else if ($(window).width() < 1100 && $(window).width() >= 700) {
        //   this.getUserNamesOnTaskSelectcolumnDefs[2].width = 900;
        // }
        // else if ($(window).width() < 700 && $(window).width() >= 300) {
        //   this.getUserNamesOnTaskSelectcolumnDefs[2].width = 900;
        // }
        // break;

        // case"Observers":
        // if ($(window).width() > 1700) {
        //   this.getUserNamesOnTaskSelectcolumnDefs[3].width = 180;
        //   }
        //   else if ($(window).width() < 1700 && $(window).width() >= 1670) {
        //     this.getUserNamesOnTaskSelectcolumnDefs[3].width = 900;
        //   }
        //   else if ($(window).width() < 1670 && $(window).width() >= 1610) {
        //     this.getUserNamesOnTaskSelectcolumnDefs[3].width = 900;
        //   }
        //   else if ($(window).width() < 1610 && $(window).width() >= 1480) {
        //     this.getUserNamesOnTaskSelectcolumnDefs[3].width = 900;
        //   }
        //   else if ($(window).width() < 1480 && $(window).width() >= 1400) {
        //     this.getUserNamesOnTaskSelectcolumnDefs[3].width = 450;
        //   }
        //   else if ($(window).width() < 1400 && $(window).width() >= 1300) {
        //     this.getUserNamesOnTaskSelectcolumnDefs[3].width = 200;
        //   }
        //   else if ($(window).width() < 1300 && $(window).width() >= 1200) {
        //     this.getUserNamesOnTaskSelectcolumnDefs[3].width = 170;
        //   }
        //   else if ($(window).width() < 1200 && $(window).width() >= 1100) {
        //     this.getUserNamesOnTaskSelectcolumnDefs[3].width = 200;
        //   }
        //   else if ($(window).width() < 1100 && $(window).width() >= 700) {
        //     this.getUserNamesOnTaskSelectcolumnDefs[3].width = 170;
        //   }
        //   else if ($(window).width() < 700 && $(window).width() >= 300) {
        //     this.getUserNamesOnTaskSelectcolumnDefs[3].width = 170;
        //   }
        // break;
      }
    }
  }

  public addTaskCustomizationClickEvent() {
    const dialogRef = this.dialogService.open({
      // title: 'Add Customization Information',
      content: AddTaskCustomizationInformationComponent,

      height: 680,
      width: 850,
      appendTo: this.containerRef,
    });
    dialogRef.result.subscribe((result) => {
      // this.getPracticeNamesBasedOnUserNames = [];
      // this.getUserNamesOnTaskSelectDataSource = [];
      if (result instanceof DialogCloseResult) {
        return;
      } else {
        this.GetTaskCustomizationListBasedOnUserNames();
        this.getUserNamesOnTaskSelectDataSource = [];
      }

      // this.result = JSON.stringify(result);
    });
    // this.GetTaskCustomizationListBasedOnUserNames();
  }

  public addTaskCustomizationBasedOnEmailAddressClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Add Customization Information",
      content: AddTaskCustomizationBasedOnEmailAddressComponent,

      height: 750,
      width: 850,
      appendTo: this.containerRef,
    });
    dialogRef.result.subscribe((result) => {
      // this.getPracticeNamesBasedOnUserNames = [];
      // this.getUserNamesOnTaskSelectDataSource = [];
      if (result instanceof DialogCloseResult) {
        return;
      } else {
        this.GetTaskCustomizationListBasedOnEmailAddress();
      }
    });
  }

  // get Email Address List
  GetTaskCustomizationListBasedOnEmailAddress() {
    const postData = {
      EHREMailIDs: this.SearchEmailName,
      ReponsibleEmployee: this.SearchResponsiblePerson,
    };
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "PracticeNamesListBasedOnEmailAddress",
        postData
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        if (this.hasValue(serviceResponse) && serviceResponse.length > 0) {
          this.getPracticeNamesBasedOnEmailAddress = serviceResponse;
          this.getEmailAddressListInfo = "";
        } else {
          this.getPracticeNamesBasedOnEmailAddress = [];
          this.getEmailAddressListInfo = "";
        }
      });
  }

  editTaskCustomizationBasedOnEmailAddressClickEvent() {
    if (!this.hasValue(this.getEmailAddressListInfo)) {
      this.ShowErrorMessage("Please Select Task to Edit.");
      return false;
    }
    this.LeftNav.GetEditModeData(this.getEmailAddressListInfo);

    const senddatamodel = {
      PracticeId: null,
      IssueSupportGroupID: this.getEmailAddressListInfo.IssueSupportGroupID,
      OASPracticeEmailID: this.getEmailAddressListInfo.OASPracticeEmailID,
      EHREMailIDs: this.getEmailAddressListInfo.EHREMailIDs,
    };
    setTimeout(() => {
      this.LeftNav.GetEditModeData(senddatamodel);
    }, 10);
    const dialogRef: DialogRef = this.dialogService.open({
      title: "Edit Customization Information",
      content: EditTaskInformationBasedOnEmailAddressComponent,
      height: 680,
      width: 850,
      appendTo: this.containerRef,
    });
    dialogRef.result.subscribe((result) => {
      // this.getPracticeNamesBasedOnUserNames = [];
      // this.getUserNamesOnTaskSelectDataSource = [];
      if (result instanceof DialogCloseResult) {
        return;
      } else {
        this.GetTaskCustomizationListBasedOnEmailAddress();
      }
    });
  }

  deleteTaskEmailCustomizationClickEvent() {
    if (!this.hasValue(this.getEmailAddressListInfo)) {
      this.ShowErrorMessage("Please Select Task to Delete.");
      return false;
    }
    const postData = {
      OASPracticeEmailID: this.getEmailAddressListInfo.OASPracticeEmailID,
      // IssueSupportGroupID: '2018090706023475'
    };
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "DeleteCustomizationInformationForUserEmail",
        postData
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.ShowSuccesseMessage("User Customization Deleted Successfully.");
        // this.GetTaskCustomizationListBasedOnUserNames();
        this.GetTaskCustomizationListBasedOnEmailAddress();
      });
  }

  getPracticeNamesBasedOnEmailAddressChanged() {
    const selectedRows = this.agGridPracticeNamesEmail.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;
    });
    this.getEmailAddressListInfo = this.selectedRowsString;

    this.GetEmailIDsList();

    // const selectedRows = this.agGridPracticeNamesEmail.api.getSelectedRows();
    // let selectedRowsString = '';
    // selectedRows.forEach(function (selectedRow, index) {
    //   if (index !== 0) {
    //     selectedRowsString += ', ';
    //   }
    //   selectedRowsString += selectedRow;
    // });
    // this.getEmailAddressListInfo = selectedRowsString;
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  getPracticeNamesBasedOnEmailAddressRowSelected(event) {
    //console.log(event);
  }

  onRowSelected(event) {}

  // get User Names List
  GetTaskCustomizationListBasedOnUserNames() {
    const postData: any = {};

    postData.PracticeId = this.SearchPracticeID;

    postData.PracticeName = this.SearchPracticeName;

    // postData = {
    //   PracticeId: this.SearchPracticeID,
    //   PracticeName: this.SearchPracticeName
    // };

    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "PracticeNamesListBasedOnUserNames",
        postData
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }

        if (this.hasValue(serviceResponse) && serviceResponse.length > 0) {
          this.getPracticeNamesBasedOnUserNames = serviceResponse;
          this.usersListInfo = "";
          this.usersCustomizationListInfo = "";
          this.GetTaskUsersList();
          this.getPracticeNamesBasedOnUserNames.forEach((x) => {
            if (x.PracticeId == -1) {
              x.PracticeId = "";
            }
          });
        } else {
          this.getPracticeNamesBasedOnUserNames = [];
        }
      });
    // setTimeout(() => {
    //   document.getElementById("divSearchPracticeID").focus();
    // }, 50);
  }

  GetEmailIDsList() {
    const postData = {
      PracticeId: this.getEmailAddressListInfo.PracticeId,
      IssueSupportGroupID: this.getEmailAddressListInfo.IssueSupportGroupID,
      // IssueSupportGroupID: '2018090706023475'
    };
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "PracticeNamesEmailsAddressList", postData)
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }

        if (this.hasValue(serviceResponse)) {
          this.agGridPracticeNamesEmailsDataSource = serviceResponse;
        } else {
          this.agGridPracticeNamesEmailsDataSource = [];
        }
      });
  }
  getPracticeNamesBasedOnUserNamesChanged() {
    const selectedRows = this.agGridPracticeNamesUserName.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;
    });
    this.usersListInfo = this.selectedRowsString;

    this.SearchUserName = "";
    this.SearchResponsiblePerson = "";

    this.GetTaskUsersList();
  }

  getUserNamesOnTaskSelectSelectionChanged() {
    const selectedRows =
      this.agGridPracticeNamesUserNamesFullInfo.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;
    });
    this.usersCustomizationListInfo = this.selectedRowsString;
  }

  public editTaskCustomizationClickEvent() {
    // if (this.hasValue(this.usersListInfo)) {
    //   this.ShowErrorMessage('Practice Info Can not be Edited.');
    //   return false;
    // }
    if (
      !this.hasValue(this.usersCustomizationListInfo) &&
      this.hasValue(this.usersListInfo)
    ) {
      this.ShowErrorMessage("Please Select User Details to Edit.");
      setTimeout(function () {
        document.getElementById("txtTaskCustomizationUserName").focus();
      }, 20);
      return false;
    }

    if (!this.hasValue(this.usersCustomizationListInfo)) {
      this.ShowErrorMessage("Please Select User Info to Edit.");
      return false;
    }

    const senddatamodel = {
      PracticeId: this.usersCustomizationListInfo.PracticeId==""?-1:this.usersCustomizationListInfo.PracticeId,
      IssueSupportGroupID: this.usersCustomizationListInfo.IssueSupportGroupID,
      EHRUserID: this.usersCustomizationListInfo.EHRUserID,
      OASPracticeUserID: this.usersCustomizationListInfo.OASPracticeUserID,
    };

    // setTimeout(() => {
    //   this.LeftNav.GetEditModeData(senddatamodel);
    // }, 10);
    // setTimeout(() => {
    const dialogRef = this.dialogService.open({
      title: "Edit Customization Information",
      // Show component
      content: EditTaskCustomizationBasedOnUsersComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 688,
      width: 850,
      appendTo: this.containerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      senddatamodel: senddatamodel,
    };
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        return;
      } else {
        this.GetTaskCustomizationListBasedOnUserNames();
        // this.GetTaskUsersList();
        this.getUserNamesOnTaskSelectDataSource = [];
      }
      // this.result = JSON.stringify(result);
    });
    // }, 20);

    // const userInfo = dialogRef.content.instance;
    // userInfo.name = 'admin';
    // userInfo.age = 42;
  }

  GetTaskUsersList() {
    const postData = {
      PracticeId: this.usersListInfo.PracticeId!=""?this.usersListInfo.PracticeId:-1,
      IssueSupportGroupID: this.usersListInfo.IssueSupportGroupID,
      EHRUserName: this.SearchUserName,
      ReponsibleEmployee: this.SearchResponsiblePerson,
      // IssueSupportGroupID: '2018090706023475'
    };
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "CustomizedPracticeUserNamesList", postData)
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.usersCustomizationListInfo = "";
        if (this.hasValue(serviceResponse)) {
          this.getUserNamesOnTaskSelectDataSource = serviceResponse;
        } else {
          this.getUserNamesOnTaskSelectDataSource = [];
        }
      });

    // setTimeout(() => {
    //   document.getElementById("txtTaskCustomizationUserName").focus();
    // },10);
  }
  getPracticeNamesBasedOnUserNamesRowSelected() {}

  refreshClickEvent() {
    this.GetTaskCustomizationListBasedOnUserNames();
    this.getUserNamesOnTaskSelectDataSource = [];
  }

  // delete customization

  deleteTaskCustomizationClickEvent(isFrom) {
    if (!this.hasValue(this.usersListInfo)) {
      this.ShowErrorMessage(
        "Please Select Practice Information to Delete Customization."
      );
      setTimeout(function () {
        document.getElementById("divSearchPracticeID").focus();
      }, 20);
      return;
    }
    if (
      this.hasValue(this.usersCustomizationListInfo) &&
      !this.hasValue(this.usersListInfo)
    ) {
      this.ShowErrorMessage(
        "Please Select Practice Information to Delete Customization."
      );
      setTimeout(function () {
        document.getElementById("divSearchPracticeID").focus();
      }, 20);
      return;
    }

    const dialog: DialogRef = this.dialogService.open({
      title: "Please Confirm",
      content:
        "Users are Linked for this Practice, Still you want to Delete this Practice Customization.",
      actions: [{ text: "No" }, { text: "Yes", primary: true }],
      width: 450,
      height: 250,
      minWidth: 250,
      appendTo: this.containerRef,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        //console.log('close');
      } else {
        if (this.hasValue(result)) {
          if (result.text === "No") {
            return;
          } else {
            const selectedRows =
              this.agGridPracticeNamesUserName.api.getSelectedRows();
            selectedRows.forEach((selectedRow, index) => {
              if (index !== 0) {
                this.selectedRowsString = {};
              }
              this.selectedRowsString = selectedRow;
            });
            this.usersListInfo1 = this.selectedRowsString;

            const postData = {
              PracticeId: this.usersListInfo1.PracticeId!=""?this.usersListInfo1.PracticeId:-1,
              IssueSupportGroupID: this.usersListInfo1.IssueSupportGroupID,
              // IssueSupportGroupID: '2018090706023475'
            };
            // tslint:disable-next-line:max-line-length
            this.commonhelperobj
              .PostData(
                Config.OASPracticeURL +
                  "DeletePracticeCustomizationInformation",
                postData
              )
              .subscribe((serviceResponse) => {
                if (this.isError(serviceResponse)) {
                  return;
                }
                this.ShowSuccesseMessage("Customization Deleted Successfully.");
                this.GetTaskCustomizationListBasedOnUserNames();
                this.GetTaskUsersList();
                this.usersListInfo = [];
              });
          }
        }
      }
    });
  }

  deleteTaskUserCustomizationClickEvent() {
    if (!this.hasValue(this.usersCustomizationListInfo)) {
      this.ShowErrorMessage("Please Select User to Delete Customization.");
      setTimeout(function () {
        document.getElementById("txtTaskCustomizationUserName").focus();
      }, 20);
      return;
    }
    if (
      this.hasValue(this.usersListInfo) &&
      !this.hasValue(this.usersCustomizationListInfo)
    ) {
      this.ShowErrorMessage("Please Select User to Delete Customization.");
      setTimeout(function () {
        document.getElementById("txtTaskCustomizationUserName").focus();
      }, 20);
      return;
    }
    const postData = {
      OASPracticeUserID: this.usersCustomizationListInfo.OASPracticeUserID,
      // IssueSupportGroupID: '2018090706023475'
    };
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "DeleteCustomizationInformation", postData)
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.ShowSuccesseMessage("User Customization Deleted Successfully.");
        // this.GetTaskCustomizationListBasedOnUserNames();
        this.GetTaskUsersList();
      });
  }

  onlyNumberKey(event) {
    return event.charCode === 8 || event.charCode === 0
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }

  public onTabSelect(e) {
    if (e.title === "Customization Based on User Names") {
      this.GetTaskCustomizationListBasedOnUserNames();
      setTimeout(() => {
        document.getElementById("divSearchPracticeID").focus();
      }, 200);
    } else {
      this.GetTaskCustomizationListBasedOnEmailAddress();
      setTimeout(() => {
        document.getElementById("divSearchEmailID").focus();
      }, 200);
    }
    this.SearchPracticeName = "";
    this.SearchUserName = "";
    this.SearchResponsiblePerson = "";
    this.getEmailAddressListInfo = "";
  }
}
