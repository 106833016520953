
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { config } from 'rxjs';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';



@Injectable()
export class OverDueReportService extends commonHelperService {
    constructor(http: HttpClient) {
        super(http);
    }
    ExcelColumnsInfo = [
        { header: "Task #", key: "TaskNumber", width: 10 },
        { header: "Customer Name", key: "PracticeName", width: 30 },
        { header: "Task Type", key: "TaskType", width: 30 },
        { header: "Task Subject", key: "TaskSubject", width: 40 },
        { header: "Priority", key: "Priority", width: 10 },
        { header: "Task Status", key: "TaskLatestStatusName", width: 15 },
        { header: "Responsible Person", key: "ResponsiblePersonDesc", width: 35 },
        { header: "Created Date", key: "CreatedDate", width: 20 },
        { header: "Created By", key: "CreatedBy", width: 35 },
        { header: "Over Due Days", key: "Days", width: 20 }
    ]
    GetOverDueTasksList(postData: any) {
        return this.PostData(Config.OASOverDueURL + 'GetOverDueTasksList', postData).pipe(((response) => {
            return response;
        }));
    }
    NewCurrentWorkingStatusList(postData: any) {
        return this.PostData(Config.OASAppnURL + 'NewCurrentWorkingStatusList', postData).pipe(((response) => {
            return response;
        }));
    }
    TaskStatusList(postData: any) {
        return this.PostData(Config.OASAppnURL + 'TaskStatusList', postData).pipe(((response) => {
            return response;
        }));
    }

}
