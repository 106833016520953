
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { commonHelperService } from '../common/common.service';


@Injectable()
export class CustomerCallLogService extends commonHelperService {
    constructor(http: HttpClient) {
        super(http);
    }
    ExcelColumnsInfo = [
        { header: "Call ID", key: "CustomerCallLogsID", width: 10 },
        { header: "Practice Name", key: "PracticeName", width: 35 },
        { header: "Call From", key: "ParticipentUserNames", width: 35 },
        { header: "Call Answered Date", key: "CustomerCallLogAnsweredDate", width: 20 },
        { header: "Call Answered By", key: "CallAnsweredBy", width: 25 },
        { header: "Call Transferred To", key: "CallTransferedTo", width: 25 },
        { header: "Status", key: "CustomerCallLogStatus", width:13 },
        { header: "Duration", key: "Duration", width:15 },
        { header: "Purpose", key: "CustomerCallLogPurpose", width:30 },
    ]
    GetCustomerCallLogsList(postData: any) {
        return this.PostData(Config.OASCallLogsInformationAppnURL + 'GetCustomerCallLogsList', postData).pipe(((response) => {
            return response;
        }));
    }

    DeleteCustomerCallLog(postData: any) {
        return this.PostData(Config.OASCallLogsInformationAppnURL + 'DeleteCustomerCallLog', postData).pipe(((response) => {
            return response;
        }));
    }


}