export enum TaskEmployeeType {
    ResponsiblePerson = 1,
    AdditionalResponsiblePerson = 2,
    Observers = 3,
    None = 4,
    QAPerson=8,
    CSharpPerson=9,
    DBPerson=10,
    JsPerson=11

}