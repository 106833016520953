import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AgGridNg2 } from 'ag-grid-angular';
import { TaskService } from '../services/task.service';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { AddPracticeCheckListItemCommentsPopupComponent } from '../add-practice-check-list-item-comments-popup/add-practice-check-list-item-comments-popup.component';
import { AddPracticeCheckListItemStatusPopupComponent } from '../add-practice-check-list-item-status-popup/add-practice-check-list-item-status-popup.component';
import { CheckListItemComponent } from '../check-list-item/check-list-item.component';
import { PracticeStatusCheckListItemHistoryPopupComponent } from '../practice-status-check-list-item-history-popup/practice-status-check-list-item-history-popup.component';
import { PracticeStatusCheckListItemsPopupComponent } from '../practice-status-check-list-items-popup/practice-status-check-list-items-popup.component';
import { commonhelper } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { Config } from '../config';
@Component({
  selector: 'app-practice-status-check-list',
  templateUrl: './practice-status-check-list.component.html',
  styleUrls: ['./practice-status-check-list.component.css']
})
export class PracticeStatusCheckListComponent extends commonhelper implements OnInit {
  private commonhelperobj: commonHelperService;
  @ViewChild('practiceStatusCheckListSelectCheckListGrid') practiceStatusCheckListSelectCheckListGrid: AgGridNg2;
  columnDefs = [
    { headerName: 'Practice ID', field: 'PracticeId', width: 140, cellClass: 'no-border cell-wrap-text', autoHeight: true },
    { headerName: 'Practice Name', field: 'PracticeName', width: 360, cellClass: 'no-border cell-wrap-text', autoHeight: true },
  ];
  onPracticeStatusCheckListSelectPracticeGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  practiceStatusCheckListSelectCheckListcolumnDefs = [

    {
      field: 'RowSelect',
      headerName: ' ',
      checkboxSelection: true,
      suppressMenu: true,
      width: 55,
      headerCheckboxSelection: true,
      // headerCheckboxSelectionFilteredOnly: true,
    },
    { headerName: 'Check List Item', field: 'CheckListItemName', tooltipField: 'CheckListItemName', width: 200, },
    { headerName: 'Status', field: 'CheckListItemStatusDescription', width: 150, tooltipField: 'CheckListItemStatusDescription', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
    { headerName: 'Latest Comments', field: 'CheckListItemLstestStatusComments', width: 400, tooltipField: 'CheckListItemLstestStatusComments', cellClass: 'no-border cell-wrap-text', autoHeight: true, cellStyle: { textAlign: 'left' } },
  ];
  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  @ViewChild('practiceStatusCheckListGrid') practiceStatusCheckListGrid: AgGridNg2;
  @ViewChild('practiceStatusCheckListSelectPracticeGrid') practiceStatusCheckListSelectPracticeGrid: AgGridNg2;
  @ViewChild("MyAppointments", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  public practiceStatusCheckListPracticeID: any;
  public practiceStatusCheckListPracticeName: any;
  public unCustomizedrowData = [];
  public rowData = [
    // { CheckListItem: 'Toyota', Status: 'Celica', LatestComments: 35000 },
    // { CheckListItem: 'Ford', Status: 'Mondeo', LatestComments: 32000 },
    // { CheckListItem: 'Porsche', Status: 'Boxter', LatestComments: 72000}
  ];

  public contextMenuitems: any[] = [{
    text: 'Practice Status Check List Items'
  }];
  constructor(private taskService: TaskService, private http: HttpClient, private dialogService: DialogService, httpClient: HttpClient,) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
  }
  CheckListItemLatestStatus: any;
  practiceStatusCheckListSearchCheckListItem: any;
  public checkListItemSelectStatusDefaultItem = { CheckListItemStatusDescription: '-- Select --', CheckListItemStatus_InfoID: null };
  public checkListItemStatusList: any = [
    // { StatusId: 1, Status: 'Pending' },
    // { StatusId: 2, Status: 'Completed' },
    // { StatusId: 3, Status: 'Cancelled' },

  ];
  ngOnInit() {
    this.practiceStatusCheckListGetPracticeList(true);

    this.getPracticeStatusList();
  }
  onSelectionChanged() {
    this.getPracticeStatusCheckList();
  }
  public getPracticeStatusList() {
    this.commonhelperobj.PostData(Config.OASPracticeCheckListAppnURL + 'GetPracticeCheckListItemStatusList', {}).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.checkListItemStatusList = serviceResponse.practiceCheckListItemsStatus;
    });
  }
  practiceStatusCheckListGetPracticeList(isfrom) {
    var postData = {
      PracticeId: this.practiceStatusCheckListPracticeID || undefined,
      PracticeName: this.practiceStatusCheckListPracticeName || undefined
    };

    this.taskService.PracticesList(postData).subscribe(response => {
      if (this.isError(response)) {
        return;
      }
      this.unCustomizedrowData = response;
      if (isfrom)
        document.getElementById("divpracticeStatusCheckListPracticeName").focus();
      setTimeout(() => {
        this.practiceStatusCheckListSelectPracticeGrid.api.forEachNode(function (node, index) {
          node.setSelected(index == 0);
        });
        // this.getPracticeStatusCheckList();
      }, 100);

    });
  }
  getPracticeStatusCheckList() {
    let selectrow = this.practiceStatusCheckListSelectPracticeGrid.api.getSelectedRows()[0];
    // if (!this.hasValue(selectrow)) {
    //   this.ShowErrorMessage('Please Select Pracrice');
    //   document.getElementById("checkListItemPopupSearchCheckListItem").focus();
    //   return false;
    // }
    const postdatatoserice = {
      PracticeId: selectrow.PracticeId,
      CheckListItemLatestStatus: this.CheckListItemLatestStatus,
      CheckListItemName: this.practiceStatusCheckListSearchCheckListItem
    }
    this.commonhelperobj.PostData(Config.OASPracticeCheckListAppnURL + 'GetPracticeCheckListItemsList', postdatatoserice).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.rowData = serviceResponse.practiceCheckListItems;
    });
  }
  practiceStatusCheckListAddClickEvent() {
    let selectrow = this.practiceStatusCheckListSelectPracticeGrid.api.getSelectedRows()[0];
    if (!this.hasValue(selectrow)) {
      this.ShowErrorMessage('Please Select Practice to Add Check List');
      document.getElementById("divpracticeStatusCheckListPracticeName").focus();
      return false;
    }
    const dialogRef = this.dialogService.open({
      title: 'Add Practice Status Check list',
      // Show component
      content: CheckListItemComponent,
      height: 400,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.PracticeID = this.practiceStatusCheckListSelectPracticeGrid.api.getSelectedRows()[0].PracticeId
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.ShowSuccesseMessage("Check List Item Saved Successfully");
          this.getPracticeStatusCheckList();
        }
      }
    });
  }
  practiceStatusCheckListEditClickEvent() {
    let selectrow = this.practiceStatusCheckListSelectCheckListGrid.api.getSelectedRows();
    if (!this.hasValue(selectrow)) {
      this.ShowErrorMessage('Please Select Check List Item');
      document.getElementById("checkListItemPopupSearchCheckListItem").focus();
      return false;
    }
    if (this.hasValue(selectrow) && selectrow.length > 1) {
      this.ShowErrorMessage('Please Select Single Record to Edit');
      document.getElementById("checkListItemPopupSearchCheckListItem").focus();
      return false;
    }
    const dialogRef = this.dialogService.open({
      title: 'Edit Practice Status Check list',
      // Show component
      content: CheckListItemComponent,
      height: 400,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.checkListItemInfoId = selectrow[0].PracticeCheckListItemLinkedInfoID;
    dialogRef.content.instance.PracticeID = this.practiceStatusCheckListSelectPracticeGrid.api.getSelectedRows()[0].PracticeId
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.ShowSuccesseMessage("Check List Item Updated Successfully");
          this.getPracticeStatusCheckList();
        }
      }
    });
  }
  onPracticeStatusCheckListContextMenuSelect() {
    const dialogRef = this.dialogService.open({
      title: 'Practice Status Check List Items',
      // Show component
      content: PracticeStatusCheckListItemsPopupComponent,
      height: 600,
      width: 1000,
      appendTo: this.ContainerRef
    });

  }
  practiceStatusCheckListViewHistoryClick() {
    let selectrow = this.practiceStatusCheckListSelectCheckListGrid.api.getSelectedRows();
    if (!this.hasValue(selectrow)) {
      this.ShowErrorMessage('Please Select Check List Item');
      document.getElementById("checkListItemPopupSearchCheckListItem").focus();
      return false;
    }
    if (this.hasValue(selectrow) && selectrow.length > 1) {
      this.ShowErrorMessage('Please Select Single Record to View History');
      document.getElementById("checkListItemPopupSearchCheckListItem").focus();
      return false;
    }
    const dialogRef = this.dialogService.open({
      title: 'View History',
      // Show component
      content: PracticeStatusCheckListItemHistoryPopupComponent,
      height: 560,
      width: 900,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.checkListItemInfoId = selectrow[0].PracticeCheckListItemLinkedInfoID;
    dialogRef.content.instance.PracticeID = this.practiceStatusCheckListSelectPracticeGrid.api.getSelectedRows()[0].PracticeId
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
    });
  }
  practiceStatusCheckListAddCommentsClick() {
    let selectrow = this.practiceStatusCheckListSelectCheckListGrid.api.getSelectedRows();
    if (!this.hasValue(selectrow)) {
      this.ShowErrorMessage('Please Select Check List Item');
      document.getElementById("checkListItemPopupSearchCheckListItem").focus();
      return false;
    }
    const dialogRef = this.dialogService.open({
      title: 'Add Comments',
      // Show component
      content: AddPracticeCheckListItemCommentsPopupComponent,
      height: 230,
      width: 500,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.checkListItemInfoId = this.getselectedrowids(selectrow);
    dialogRef.content.instance.PracticeID = this.practiceStatusCheckListSelectPracticeGrid.api.getSelectedRows()[0].PracticeId
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.ShowSuccesseMessage("Comments Added Successfully");
          this.getPracticeStatusCheckList();
        }
      }
    });
  }
  getselectedrowids(selectedItemlist) {
    let selectedCheckListItemIds = [];
    selectedItemlist.forEach(element => {
      selectedCheckListItemIds.push(element.PracticeCheckListItemLinkedInfoID)
    });
    return selectedCheckListItemIds;
  }
  practiceStatusCheckListStatusClick() {
    let selectrow = this.practiceStatusCheckListSelectCheckListGrid.api.getSelectedRows();
    if (!this.hasValue(selectrow)) {
      this.ShowErrorMessage('Please Select Check List Item');
      document.getElementById("checkListItemPopupSearchCheckListItem").focus();
      return false;
    }
    const dialogRef = this.dialogService.open({
      title: 'Status',
      // Show component
      content: AddPracticeCheckListItemStatusPopupComponent,
      height: 230,
      width: 500,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.checkListItemInfoId = this.getselectedrowids(selectrow);
    dialogRef.content.instance.PracticeID = this.practiceStatusCheckListSelectPracticeGrid.api.getSelectedRows()[0].PracticeId
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      }
      else {
        if (this.hasValue(result)) {
          this.ShowSuccesseMessage("Status Updated Successfully");
          this.getPracticeStatusCheckList();
        }
      }
    });
  }
  practiceStatusCheckListDeleteClickEvent() {
    let selectrow = this.practiceStatusCheckListSelectCheckListGrid.api.getSelectedRows();
    if (!this.hasValue(selectrow)) {
      this.ShowErrorMessage('Please Select Check List Item');
      document.getElementById("checkListItemPopupSearchCheckListItem").focus();
      return false;
    }
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please Confirm',
      content: 'Are You Sure to Delete Check List Item?',
      actions: [
        { text: 'No' },
        { text: 'Yes', primary: true }
      ],
      width: 450,
      height: 250,
      minWidth: 250,
      appendTo: this.ContainerRef
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        //console.log('close');
      } else {
        if (this.hasValue(result)) {
          if (result.text === 'No') return;
          this.deleteSelectedCheckListItem(selectrow);
        }

      }

    });

  }
  deleteSelectedCheckListItem(selectrow) {
    const inputDatatoDeleteAppointment = {
      PracticeCheckListItemLinkedInfoID: this.getselectedrowids(selectrow),
      PracticeId: this.practiceStatusCheckListSelectPracticeGrid.api.getSelectedRows()[0].PracticeId
    };
    this.commonhelperobj.PostData(Config.OASPracticeCheckListAppnURL + 'DeletePracticeCheckListItems', inputDatatoDeleteAppointment)
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.ShowSuccesseMessage("Check List Item Deleted Successfully");
        this.getPracticeStatusCheckList();
      });


  }
  getSelectedItem() {
    const selectedrow = this.practiceStatusCheckListSelectCheckListGrid.api.getSelectedRows();
    if (!this.hasValue(selectedrow) || !this.hasValue(selectedrow[0].PracticeCheckListItemLinkedInfoID) || selectedrow[0].PracticeCheckListItemLinkedInfoID <= 0) {
      this.ShowErrorMessage("Please Select Check List Item.");
      return 0;
    }
    return selectedrow[0].PracticeCheckListItemLinkedInfoID;
  }
}
