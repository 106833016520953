export enum TaskEventTypeEnum {
  // Task is created
  TaskCreated = 1,

  // Task subject is modified
  SubjectUpdated = 2,

  // Task status is modified
  StatusUpdated = 3,

  // Task severity is modified
  SeverityUpdated = 4,

  // Task responsible person is modified
  ResponsiblePerson = 5,

  // Task project is modified
  ProjectUpdated = 6,

  // Task participants are modified
  Participants = 7,

  // Task observers are modified
  Observers = 8,

  // Task module is changed
  ModuleUpdated = 9,

  // Task delivery date is updated
  RequiredByDateUpdated = 10,

  // Task description is updated
  DescriptionUpdated = 11,

  // Comments are added
  Comments = 12,

  // Attachments are added or modified
  Attachments = 13,

  // When a task is assigned
  TaskAssigned = 14,

  //Task Forward To
  TaskForwardTo = 15,

  //Task Forward To
  TaskCurrentWorkingStatus = 16,

  RBD = 17,

  ECD = 18,
  ExternalReminder = 19,

    // Task responsible person is modified
    QAPerson = 20,

      // Task responsible person is modified
  CSharpPerson = 21,

    // Task responsible person is modified
    DBPerson = 22,

      // Task responsible person is modified
  JSPerson = 23,
}
