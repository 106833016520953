import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
} from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { AgGridNg2 } from "ag-grid-angular";
import * as $ from "jquery";
import { Router, ActivatedRoute } from "@angular/router";
import { commonhelper, innerHTMLExists } from "../common/commonhelper";
import { commonHelperService } from "../common/common.service";
import { HttpClient } from "@angular/common/http";
import { Config } from "../config";
import { element } from "protractor";
import {
  FileRestrictions,
  SelectEvent,
  ClearEvent,
  RemoveEvent,
  FileInfo,
} from "@progress/kendo-angular-upload";
import {
  DialogRef,
  DialogService,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import { TaskChangeEvent } from "../interfaces/TaskChangeEvent";
import { TaskEventTypeEnum } from "../enums/TaskHistoryChangeEvent.enum.";
import { EmployeeService } from "../employee.service";
import { map } from "rxjs/operators";
import { pipe, forkJoin } from "rxjs";
import { Observable, observable } from "rxjs";
import { reject } from "q";
import { PreventableEvent } from "@progress/kendo-angular-layout/dist/es2015/tabstrip/tabstrip-events";
import { AttachmentService } from "../services/attachment.service";
import { Attachment } from "../interfaces/attachment";
import {
  FileUploadProgressService,
  CommentFileUploadProgressService,
} from "../services/file-upload-progress.service";
import { UploadState } from "../enums/upload-state.enum";
import { DomSanitizer } from "@angular/platform-browser";
import { isEqual, differenceWith } from "lodash";
import { ResponsiblepersonUsersListComponent } from "../responsibleperson-users-list/responsibleperson-users-list.component";
import { UncustomizedtaskCommentsComponent } from "../uncustomizedtask-comments/uncustomizedtask-comments.component";
// import { CreateTaskInformationPdfPopupComponent } from '../create-task-information-pdf-popup/create-task-information-pdf-popup.component';
// import { CreateTaskInformationPdfPopupComponent } from '../create-task-information-pdf-popup/create-task-information-pdf-popup.component';
// declare var $: any;
// declare var jQuery: any;
import { AdditionalParticipantAndObserversPopupComponentComponent } from "../additional-participant-and-observers-popup-component/additional-participant-and-observers-popup-component.component";
import { formatDate, IntlService } from "@progress/kendo-angular-intl";
import {
  CreateTaskService,
  Iemployee,
} from "../shared/services/create-ticket.service";
import * as _ from "lodash";
import { TaskEmployeeType } from "../enums/task-employee-type.enum";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import { ViewFullTaskInformationService } from "./view-full-task-information.service";
import { AppDateAdapter, APP_DATE_FORMATS } from "../common/format-datepicker";
import { EmployeesCustomizationComponent } from "../employees-customization/employees-customization.component";
import { SelectRoleToAddPopupComponent } from "../select-role-to-add-popup/select-role-to-add-popup.component";
import { CloneService } from "../shared/services/clone.service";
import { PracticeUsersListComponent } from "../practice-users-list/practice-users-list.component";
import { ViewDraftCommentsComponent } from "../view-draft-comments/view-draft-comments.component";
import { TaskService } from "../services/task.service";
import { ResponsibleHistoryDetailsComponent } from "../responsible-history-details/responsible-history-details.component";

@Component({
  selector: "app-view-full-task-information",
  templateUrl: "./view-full-task-information.component.html",
  styleUrls: ["./view-full-task-information.component.css"],
  providers: [
    FileUploadProgressService,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class ViewFullTaskInformationComponent
  extends commonhelper
  implements OnInit {
  public mask = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d+/],
  };
  selectedTaskCurrentWorkingStatus: any = {};
  public selectedTaskCurrentWorkingStatusID: any;
  initialTaskCurrentWorkingStatus: any;
  PopupDataFromMultiSelectUserSelectionPopup: any;
  viewFullTaskInfoPreviousselectedObservers: Employee[];
  viewFullTaskInfoPreviousselectedParticipants: Employee[];
  SelectedObserversPersonNames: string;
  IsObserversInfoChanged: boolean = false;
  ReplaceSaveTOUpdate:boolean=false;
  TaskCommentID:any;
  SelectedObserversInfo: any;
  IssueSeverityID = 0;
  PreviousSelectedCurrentWorkingStatusId = 0;
  TaskRBD: any;
  startDate: string;
  observersview: any = [];
  SelectedAdditionalResponsiblePersonName: string;
  IsAdditionalResponsiblePersonInfoChanged: boolean = false;
  SelectedAdditionalResponsiblePersonInfo: any;
  IsresponsiblePersonAndFwdToSame: boolean = false;
  SelectedAssistantPersonName: string = "";
  IsAssistantPersonInfoChanged: boolean = false;
  PreviousSelectedResponsiblePerson: Employee;
  PreviousSelectedQAPerson: Employee;
  PreviousSelectedDBPerson: Employee;
  PreviousSelectedCSharpPerson: Employee;
  PreviousSelectedJSPerson: Employee;
  IsResponsiblePersonChanged: boolean = false;
  IsEHRResponsiblePersonChanged: boolean = false;
  IsTaskCreatedfromEHR: Boolean = false;
  IsTaskFrom: number = 0;
  ThanksandRegards: any;
  TimeTaken:number;
  ViewAttachmentHeader: any;
  TaskTypeDesc: any;
  SupportGroupDesc: any;
  IsTaskCreatedByMe = false;
  TaskResponsiblePersonID: any;
  EmployeeObserversListInfo: any;
  EmployeeListInfo: any;
  commentFilesCount = 0;
  public SelectedResponsiblePerson: any = {};
  responsiblePersonview: any = {};

  initialQAPerson: any = {};
  SelectedQAPerson: any = {};

  initialCSharpPerson: any = {};
  SelectedCSharpPerson: any = {};

  initialDBPerson: any = {};
  SelectedDBPerson: any = {};

  initialJSPerson: any = {};
  SelectedJSPerson: any = {};

  QAPersonView: any = {};
  DBPersonView: any = {};
  JsPersonView: any = {};
  CsharppPersonView: any = {};

  hideAttachments=true;

  SelectedResponsiblePersonInfo: any = {};
  public SelectedAssistantPerson: any = [];
  SelectedAssistantPersonInfo: any = {};
  taskId: any;
  previouslySelectedEmployees: Array<Employee> = [];
  public externalcommentscount: any;
  public count: any;
  public isFromExternalComment: boolean = false;
  initialTaskStatus: any = {};
  viewFullTaskInfoChangeStatus: any;
  public BindStartButtonHtml: any = "Start";
  public hideFromSetByMe = false;
  public hideFinishButtonAfterFinishing = false;
  public viewFullTaskInfoResponsiblePersonHide = true;
  // intially selected participants array for this task
  private initialParticipants: Array<any> = [];
  public IsPublicComment: any = true;
  public IsSaveAsDraft: boolean = false;
  public TaskInfoID: any;
  isfrompageinit: boolean = true;
  CommentDescription: string;
  public viewModel = {
    CommentBody: "",
    IsPublicComment: true,
  } as any;
  public commentAttachmentContainer: any = {};

  taskCommentID: number;
  // intially selected observers array for this selected task
  private initialObservers: Array<any> = [];

  private PreviousSelectedAssistantPerson: Array<any> = [];

  // initially selected responsible person for this task
  private initialResponsiblePerson: any = {};

  public usersdivHideShowBasedonResponsiblePerson = true;
  public TaskacceptorDeclineButtonsHide = true;

  public usersdivHideShowBasedonQAPerson = true;





  isTaskInCompletedState = false;
  //Hiding Icons by Default(Brief View)
  public HiddenInDetailView: boolean = true;
  @ViewChild("ViewFullTask") ViewFullTask: ElementRef;
  @ViewChild("viewHistoryGrid") viewHistoryGrid: AgGridNg2;
  @ViewChild("rbdDate") _rbdDate: ElementRef;
  @ViewChild("ecdDate") _ecdDate: ElementRef;

  public gridOptions: GridOptions;

  ////Test
  public gridApi;

  public gridColumnApi;

  public selectedRowsString: {};

  public EmployeesAndRolesModelList: Array<any> = [];

  public viewFullTaskInfoSelectedAdditionalResponsiblePersons: any = [];

  additionalPersonsView: any = [];

  public viewFullTaskInfoSelectedObservers: any = [];

  public isTaskCompleted = false;
  public viewExpectedDeliveryDate: boolean = true;
  public viewFullTaskInfoExpectedDate: any;

  public IsResponsiblePersonDisabled = false;
  rightSidePaneEmployeesViewList = [
    { Id: TaskEmployeeType.ResponsiblePerson, List: "responsiblePersonview" },
    {
      Id: TaskEmployeeType.AdditionalResponsiblePerson,
      List: "additionalPersonsView",
    },
    { Id: TaskEmployeeType.Observers, List: "observersview" },

    { Id: TaskEmployeeType.QAPerson, List: "QAPersonView" },

    { Id: TaskEmployeeType.DBPerson, List: "DBPersonView" },
    { Id: TaskEmployeeType.CSharpPerson, List: "CsharppPersonView" },
    { Id: TaskEmployeeType.JsPerson, List: "JsPersonView" },
  ];
  rowData = [
    {
      DateID: "20/06/1998",
      CreatedBy: "ssss",
      UpdateDescription: "Update Description",
      Update: "anusha.chiruvolu@adaptamed.org",
    },
  ];

  rowSelection = "single";

  CommentsImagesAttachmentsList: any = [];

  TaskParticipantsList: any = [];

  TaskObserversList: any = [];

  TaskAssistantsList: any = [];

  public dialogOpened = false;

  public opened = true;

  TaskResponsiblePerson = "Select Responsibler Person";

  TaskForwardTo: any;

  TaskUserInfo: any;

  TaskCreatedBy: any;

  PracticeName: any = "";

  TaskAddCommentsInfoModel: any = {};

  // AttachmentsModelList: any = [];

  public imgeurl: any;

  AttachmentsModel: { AttachmentBase64Format: string; AttachmentType: number };

  @ViewChild("ViewFullTask") ViewFullTaskInfo: ElementRef;

  @ViewChild("ViewFullTask", { read: ViewContainerRef })
  public ContainerRef: ViewContainerRef;
  myCommentsFiles: Array<FileInfo> = [];

  TaskDeadline: any;

  private commonhelperobj: commonHelperService;

  private formInformation: any;

  filteredFormsInfo: any;

  public headinInfo: any;

  public htmlContent: {};

  showEditorOnClick = true;

  showCommentOnClick = false;

  public TaskSubject: any;
  public AcknowledgmentSent: any;
  public showHideAcknowledgementSent: boolean = true;
  public taskNumber: any;
  public UserId: any;
  public EHRPracticeId: any;
  public TaskCreatedOn: any;

  public TaskPriority: any;

  public TaskStatus: any;
  public TaskLatestStatusID: any;

  public fullattachmentsList:any;
  public CommentedfullattachmentsList: any;

  public taskBodyList: any;

  public SearchType: any;

  public TaskDescription: string;

  public AttachmentsList: any = [];

  public CommentedAttachmentsList: any = [];

  public urlsrcslist = [];

  public srcextractedlist = [];

  public imagesattachmentslist = [];

  public commentedimagesattachmentslist = [];

  public firstcommentsattachmentslist = [];

  public messagesList = [];

  public MainBodyAttachmentsList: any;

  public CommentsAttachmentsList: any;

  public MainBodyImagesAttachmentsList: any;

  public imagesmainbodyattachmentslist = [];

  public commentsattachmentslist = [];

  public commentscount: any;

  public CommentsBodyList: any;
  selectedTicketsList: Array<any> = [];
  SelectedTickedID: number;
  isPreviousHide: boolean = false;
  isNextHide: boolean = true;
  public result: any;

  VieworLoadpreviousCommentsBlock = false;

  ifCommentsCountisLessThanOne = false;

  public commentsHide = false;

  public showHistoryGrid = false;

  public AttachmentsHeaderHide = true;

  public viewFullTaskInfoBreifDetailView = true;
  public viewFullTaskInfoTitle = "Detail View";
  public taskImageSRC: any = [];

  public showhideSendResponsetoCustomerButtom = false;

  uploadSaveUrl = "saveUrl"; // should represent an actual API endpoint

  uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint

  taskStatusDetails: any = {};

  historicalEventsCount: number;
  isfromWindow: number;
  public dataChangeStatus = [
    { statusName: "Pending", statusId: 1 },
    { statusName: "Overdue", statusId: 2 },
    // { statusName: 'Completed', statusId: 3 },
  ];

  public defaultViewFullTaskCurrentTaskItem = {
    Task_Current_Working_Status: "--Task Current Working Status --",
    Task_Current_Working_Status_InfoID: undefined,
  };

  public items: any[] = [
    {
      text: "Delegate",
      // items: [{ text: 'Item1.1' }, { text: 'Item1.2' }]
    },
  ];

  public MoreOptionItems: any[] = this.items;
  hideStatusChangeButtons = true;
  responsiblePersonValueWhenOpened: Employee;
  filesCount: number = 0;
  pdfAttachments: Attachment[] = [];
  CommentedpdfAttachments: Attachment[] = [];
  ViewNewCurrentWorkingStatusList: any;
  addCommentsShowHide: boolean;

  constructor(
    private taskService: TaskService,
    private http: HttpClient,
    private _router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private employeeService: EmployeeService,
    private uploadStateService: FileUploadProgressService,
    private domSanitizer: DomSanitizer,
    private intl: IntlService,
    private attachmentsService: AttachmentService,
    private commentFileUploadStateService: CommentFileUploadProgressService,
    private readonly _createNewTaskService: CreateTaskService,
    private readonly _viewFullTaskInformationService: ViewFullTaskInformationService,
    private readonly clonerService: CloneService
  ) {
    super();
    this.commonhelperobj = new commonHelperService(http);

    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this,
      },
      defaultColDef: {
        resizable: true,
      },
    };

    // constructor(private http: HttpClient, private _router: Router, private route: ActivatedRoute) {
    //   super();
    //   this.commonhelperobj = new commonHelperService(http);
  }
  public viewFullTaskInfoResposiblePerson: any;

  columnDefs: any = [];

  DelegateClick() {
    this.viewFullTaskInfoResponsiblePersonHide = false;
  }

  private sanitizedTaskDescription(str: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(str);
  }

  cloneObject(data) {
    return JSON.parse(JSON.stringify(data));
  }

  public ViewFullTaskCurrentTaskList = [
    { ViewFullTaskCurrentTaskDesc: "Pending", ViewFullTaskCurrentTaskID: 1 },
    { ViewFullTaskCurrentTaskDesc: "Completed", ViewFullTaskCurrentTaskID: 2 },
  ];

  ngOnInit() {
    // $('#div-task-history').addClass('CommentsBackgroungClass');
    //$('#div-task-external-comments').addClass('CommentsBackgroungClass');
    $("#div-task-comments").addClass("tabsActiveColorClass");
    // console.log(this.route.params);
    this.route.params.subscribe((params) => {
      // console.log(params.info);
      const dataCame = this.getCommonData(params.info);
      this.formInformation = dataCame;
      this.headinInfo = dataCame.editmodedata;
      this.selectedTicketsList = dataCame.selectedTicketsList;
      this.isfromWindow = dataCame.isfrom;
      // console.log(this.isfromWindow, "ON POP UP");
      this.SelectedTickedID = this.headinInfo.TaskInfoID;
      // console.log(this.selectedTicketsList.length)
      if (this.selectedTicketsList.length <= 1) {
        this.isNextHide = false;
      } else {
        this.isNextHide = true;
      }
      // this.isNextHide = this.selectedTicketsList.length === 0 ? false : true;

      // this.isPreviousHide = indexID === 0 ? false : true;
      // this.isNextHide = indexID >= 0 ? true : false;

      this.ViewFullTaskInformation(this.headinInfo);
    });

    // this.isFromExternalComment=true;
    // this.GetTaskBodyList();
    this.populateHistoryTable(false);

    this.GetEmployeesList();

    this.addCommentsShowHide = false;
  }

  private ViewFullTaskInformation(data) {
    var postData = {
      TaskInfoID: data.TaskInfoID,
    };
    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "EmployeeTaskInformationGet",
        postData
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        if (this.hasValue(serviceResponse)) {
          if (
            serviceResponse.ReadorUnread === 1 ||
            serviceResponse.ReadorUnread === true
          ) {
            this.OnclickMarkasReadorUnReadFN(serviceResponse.TaskInfoID);
          }

          this.TaskInformationtostatemaintain(serviceResponse);
        }

        //this.initializeTaskCategoriesCount();
      });
  }

  OnclickMarkasReadorUnReadFN(selectedID) {
    let payload = {
      TaskInfoID: selectedID,
      TicketReadorUnread: false,
    };
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "UpdatedTicketReadandUnReadStatus", payload)
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
      });
  }

  private TaskInformationtostatemaintain(data) {
    this.formInformation.editmodedata = data;
    this.usersdivHideShowBasedonResponsiblePerson = true;
    this.IsTaskCreatedByMe = false;
    this.isTaskInCompletedState = false;
    this.hideFromSetByMe = false;
    this.IsResponsiblePersonDisabled = false;
    this.TaskacceptorDeclineButtonsHide = true;
    this.hideFinishButtonAfterFinishing = false;
    this.hideStatusChangeButtons = true;
    this.taskStatusDetails = {};
    this.initialTaskCurrentWorkingStatus;
    this.taskId = this.headinInfo.TaskInfoID;
    this.additionalPersonsView = [];
    this.observersview = [];
    this.isfrompageinit = true;
    (this.selectedTaskCurrentWorkingStatus = {}),
      (this.headinInfo.IsTaskCreatedByEmployee = data.IsTaskCreatedByEmployee);
    if (
      Config.OASOrgModel.LoggedUserID ===
      parseInt(this.headinInfo.TaskCreatedByUserId)
    ) {
      this.IsTaskCreatedByMe = true;
    }
    // console.log(data.TaskLatestStatusID);
    // console.log((this.taskStatusDetails["statusId"] = data.TaskLatestStatusID));
    this.taskStatusDetails["statusId"] = data.TaskLatestStatusID;
    this.initialTaskCurrentWorkingStatus = data.TaskCurrentWorkingStatusInfoID;
    this.IsTaskCreatedfromEHR = data.IsTaskCreatedfromEHR;
    this.IsTaskFrom = data.IsTaskFrom;
    if (data.IsTaskCreatedfromEHR == false && this.IsTaskFrom === 1) {
      this.showhideSendResponsetoCustomerButtom = true;
    } else {
      this.showhideSendResponsetoCustomerButtom = false;
    }

    // console.log(this.showhideSendResponsetoCustomerButtom)

    if (
      this.taskStatusDetails.statusId === 1 &&
      this.headinInfo.CreatedBy === data.ResponsiblePersonDesc
    ) {
      this.TaskacceptorDeclineButtonsHide = true;
      this.hideFromSetByMe = false;
      this.hideFinishButtonAfterFinishing = false;
    } else {
      // status if review complete and task created by viewing user
      if (
        this.taskStatusDetails.statusId === 5 &&
        this.IsTaskCreatedByMe === true
      ) {
        this.TaskacceptorDeclineButtonsHide = false;
        this.hideFromSetByMe = true;
        this.hideFinishButtonAfterFinishing = true;
      } else {
        this.TaskacceptorDeclineButtonsHide = true;
        this.hideFromSetByMe = true;
        this.hideFinishButtonAfterFinishing = true;
      }
    }
    // console.log(this.formInformation);
    if (
      this.hasValue(this.formInformation) &&
      (this.formInformation.editmodedata.TaskLatestStatusName === "Completed" ||
        this.formInformation.editmodedata.TaskLatestStatusName ===
        "Review Pending" ||
        this.formInformation.editmodedata.TaskLatestStatusName ===
        "Accepted by Customer")
    ) {
      this.isTaskInCompletedState = true;
      this.IsResponsiblePersonDisabled = true;
    }

    if (
      this.hasValue(this.formInformation) &&
      this.formInformation.editmodedata.TaskLatestStatusName === "Completed"
    ) {
      this.hideFromSetByMe = true;
      this.hideFinishButtonAfterFinishing = true;
    } else {
      if (
        this.taskStatusDetails.statusId !== 5 &&
        this.IsTaskCreatedByMe === false
      ) {
        this.hideFromSetByMe = false;
        this.hideFinishButtonAfterFinishing = false;
        // this.TaskacceptorDeclineButtonsHide = false;
      }
    }
    if (
      this.hasValue(this.formInformation) &&
      this.formInformation.IsFromSetByMe === true
    ) {
      this.hideFromSetByMe = true;
    }
    this.taskNumber = data.TaskNumber;
    this.UserId = data.UserId;
    this.EHRPracticeId = data.EHRPracticeID;
    this.initialTaskStatus = this.taskStatusDetails =
      this.dataChangeStatus.find(
        (status) => status.statusId === +this.taskStatusDetails.statusId
      );
    if (this.hasValue(data)) {
      this.BindStartButtonHtml = "Start";
      // console.log(data, this.headinInfo, "from sets");
      this.TaskSubject = "Task #" + data.TaskNumber + "-" + data.IssueSubject;
      this.TaskCreatedOn = data.IssueCreatedOn;
      this.TaskPriority = data.SeverityDesc;
      this.IssueSeverityID = this.calculateSeverityId(data.SeverityDesc);
      this.TaskStatus = data.TaskLatestStatusName;
      this.TaskCreatedBy = this.headinInfo.CreatedBy;
      this._rbdDate.nativeElement.value = this.TaskRBD = data.RequiredByDate;
      this._ecdDate.nativeElement.value = this.TaskDeadline = data.ExpectedDate;
      this.TaskUserInfo = data.TaskUsersInfo;
      this.TaskTypeDesc = data.TaskType;
      this.SupportGroupDesc = data.SupportGroup;
      this.PracticeName = data.PracticeName;
      this.TaskLatestStatusID = data.TaskLatestStatusID;
      this.AcknowledgmentSent =
        data.acknowledgementsent == 1 ? "Sent" : "Not Sent";
      if (this.TaskLatestStatusID == 2) {
        this.BindStartButtonHtml = "Hold";
      } else if (this.TaskLatestStatusID == 8) {
        this.BindStartButtonHtml = "Restart";
      }
    }
    const {
      responsiblePerson,
      taskObservers,
      taskParticipants,
      taskAssistant,
      taskQAPerson, taskCSharpPerson, taskDbPerson, taskJsPerson,
    } = this.employeeService.parseEmployeeRoles(this.TaskUserInfo + ",");

    //Show only when the statusID are 1 - Yet to Start 2 - On Going
    //showing status buttons only for Responsible/Participant. Hiding for the rest.
    if (
      this.TaskLatestStatusID === 1 ||
      this.TaskLatestStatusID === 2 ||
      this.TaskLatestStatusID === 7 ||
      this.TaskLatestStatusID === 8
    ) {
      if (this.IsTaskFrom !== 3) {
        this.hideStatusChangeButtons =
          !this.isCurrentViewerResponsibleOrAssistant(
            responsiblePerson,
            taskAssistant
          );
      }
      if (this.IsTaskFrom === 3 && this.TaskLatestStatusID !== 5) {
        // console.log("this.Data Calling");
        this.hideStatusChangeButtons = false;
        //   !this.isCreatedBy(
        //   this.headinInfo.TaskCreatedByUserId
        // );
      }
    }
    if (this.IsTaskFrom === 3 && this.TaskLatestStatusID === 5) {
      this.hideStatusChangeButtons = true;
    }
    // else {
    //   this.hideStatusChangeButtons = false;
    // }
    //Show only when the statusID is 5 - Review Pending and the task is created by the logged in user
    if (this.TaskLatestStatusID === 5 && this.IsTaskCreatedByMe === true) {
      this.TaskacceptorDeclineButtonsHide = false;
    }
    if (this.hasValue(taskParticipants)) {
      this.viewFullTaskInfoPreviousselectedParticipants =
        this.clonerService.deepClone(taskParticipants);
      this.viewFullTaskInfoSelectedAdditionalResponsiblePersons =
        this.clonerService.deepClone(
          this.viewFullTaskInfoPreviousselectedParticipants
        );
      this.additionalPersonsView = this.clonerService.deepClone(
        this.viewFullTaskInfoSelectedAdditionalResponsiblePersons
      );
    }

    if (this.hasValue(taskObservers)) {
      this.viewFullTaskInfoPreviousselectedObservers =
        this.clonerService.deepClone(taskObservers);
      this.viewFullTaskInfoSelectedObservers = this.clonerService.deepClone(
        this.viewFullTaskInfoPreviousselectedObservers
      );
      this.observersview = this.clonerService.deepClone(
        this.viewFullTaskInfoSelectedObservers
      );
    }

    // bind selected responsible pserson to view and store initial data in an object
    this.initialResponsiblePerson =
      this.clonerService.deepClone(responsiblePerson);
    this.SelectedResponsiblePerson = this.clonerService.deepClone(
      this.initialResponsiblePerson
    );
    this.responsiblePersonview = this.clonerService.deepClone(
      this.SelectedResponsiblePerson
    );



    if (this.hasValue(taskQAPerson)) {
      this.initialQAPerson =
        this.clonerService.deepClone(taskQAPerson);
      this.SelectedQAPerson = this.clonerService.deepClone(
        this.initialQAPerson
      );
      this.QAPersonView = this.clonerService.deepClone(this.SelectedQAPerson[0])
    }



    if (this.hasValue(taskCSharpPerson)) {
      this.initialCSharpPerson =
        this.clonerService.deepClone(taskCSharpPerson);
      this.SelectedCSharpPerson = this.clonerService.deepClone(
        this.initialCSharpPerson

      );
      this.CsharppPersonView = this.clonerService.deepClone(this.SelectedCSharpPerson[0])
    }

    if (this.hasValue(taskDbPerson)) {
      this.initialDBPerson =
        this.clonerService.deepClone(taskDbPerson);
      this.SelectedDBPerson = this.clonerService.deepClone(
        this.initialDBPerson

      );
      this.DBPersonView = this.clonerService.deepClone(this.SelectedDBPerson[0])
    }
    if (this.hasValue(taskJsPerson)) {
      this.initialJSPerson =
        this.clonerService.deepClone(taskJsPerson);
      this.SelectedJSPerson = this.clonerService.deepClone(
        this.initialJSPerson

      );
      this.JsPersonView = this.clonerService.deepClone(this.SelectedJSPerson[0])
    }



    this.TaskResponsiblePersonID = this.SelectedResponsiblePerson.EmployeeId;
    //debugger;
    if (this.hasValue(taskAssistant)) {
      this.SelectedAssistantPerson = taskAssistant;
    }

    if (
      !this.hasValue(this.SelectedAssistantPerson) ||
      this.SelectedAssistantPerson.length <= 0
    ) {
      this.SelectedAssistantPerson = undefined;
      this.SelectedAssistantPersonName = "";
    } else {
      if (
        this.hasValue(this.SelectedAssistantPerson) &&
        this.SelectedAssistantPerson.length > 0
      ) {
        this.SelectedAssistantPerson.forEach((selectedRow, index) => {
          if (selectedRow.EmployeeId == Config.OASOrgModel.LoggedUserID) {
            //this.hideStatusChangeButtons = false;
            return;
          }
        });
      }
    }
    if (
      Config.OASOrgModel.LoggedUserID === parseInt(this.TaskResponsiblePersonID)
    ) {
      this.usersdivHideShowBasedonResponsiblePerson = false;
    }

    if (
      Config.OASOrgModel.LoggedUserID === this.headinInfo.TaskCreatedByUserId
    ) {
      this.usersdivHideShowBasedonResponsiblePerson = false;
    }

    if (this.isAssistantPerson(taskAssistant) == true) {
      this.IsResponsiblePersonDisabled = true;
      this.usersdivHideShowBasedonResponsiblePerson = false;
    }

    if (this.isParticipant(taskParticipants) == true) {
      this.usersdivHideShowBasedonResponsiblePerson = false;
    }
    if (this.isParticipant(taskQAPerson) == true) {
      this.usersdivHideShowBasedonResponsiblePerson = false;
    }
    if (this.isParticipant(taskCSharpPerson) == true) {
      this.usersdivHideShowBasedonResponsiblePerson = false;
    }
    if (this.isParticipant(taskDbPerson) == true) {
      this.usersdivHideShowBasedonResponsiblePerson = false;
    }
    if (this.isParticipant(taskJsPerson) == true) {
      this.usersdivHideShowBasedonResponsiblePerson = false;
    }
    // console.log(
    //   this.usersdivHideShowBasedonResponsiblePerson,
    //   "User is responsibble"
    // );
    this.GetCurrentStatusList(data);
  }

  private parseDate(dateString: string): Date {
    if (
      !this.hasValue(dateString) ||
      dateString.indexOf("/") === dateString.lastIndexOf("/")
    ) {
      return undefined;
    }

    const [month, day, year] = dateString
      .split("/")
      .map((dateFragment) => +dateFragment);
    return new Date(year, month - 1, day);
  }

  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "d")}` : "";
  }

  OnCurrentWorkingStatusList(value) {
    this.selectedTaskCurrentWorkingStatus.Task_Current_Working_Status =
      value.Task_Current_Working_Status;

    if (
      this.selectedTaskCurrentWorkingStatus.Task_Current_Working_Status ==
      "Review-postponed"
    ) {
      this.viewExpectedDeliveryDate = false;
      this.viewFullTaskInfoExpectedDate = this.parseDate(
        this.headinInfo.ExpectedDate
      );
    } else {
      this.viewExpectedDeliveryDate = true;
    }
  }

  viewFullTaskInfoExpectedDeliveryDate(value: Date) {
    // let dateeee = this.formatValue(this.TaskAddInfoModel.ExpectedDate);

    // //return value ? ` - ${this.intl.formatDate(value, 'd')}` : '';
    // console.log(dateeee);
    // console.log(this.TaskAddInfoModel.ExpectedDate);

    if (!this.hasValue(this.viewFullTaskInfoExpectedDate)) {
      this.viewFullTaskInfoExpectedDate = "";
      this.ViewFullTask.nativeElement.querySelector(
        "#viewFullTaskInfoExpectedDeliveryDate"
      ).value = "";
      //   this.ShowErrorMessage('Invalid Day, Month or Year Range Detected.');
      const elementFound = this.ViewFullTask.nativeElement.querySelector(
        '[name="' + "viewFullTaskInfoExpectedDeliveryDatename" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].value = "";
      }
      // this.TaskAddInfoModel.ExpectedDate = undefined;
      return;
    }

    // if (this.hasValue(this.TaskAddInfoModel.ExpectedDate) && this.TaskAddInfoModel.ExpectedDate <= 0) {
    //   this.TaskAddInfoModel.ExpectedDate = '';
    //   return;
    // }
    var today = new Date();
    var expectedDate = new Date(this.viewFullTaskInfoExpectedDate);
    const daysDifference = this.DateDiffInDays(
      today,
      this.viewFullTaskInfoExpectedDate
    );
    const yearsDifference = expectedDate.getFullYear() - today.getFullYear();
    // if (this.hasValue(daysDifference) && daysDifference > 0) {
    //   this.ShowErrorMessage('Expected Delivery Date should be greater than or equal to current date.');
    //   const elementFound = this.CreateTaskInfo.nativeElement.querySelector('[name="' + 'TaskAddNewExpectedDeliveryDatename' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].children[0].children[0].children[0].focus();
    //   }
    //   return;
    // }
    if (this.hasValue(yearsDifference) && yearsDifference > 100) {
      this.ShowErrorMessage("Invalid Day, Month or Year Range Detected.");
      const elementFound = this.ViewFullTask.nativeElement.querySelector(
        '[name="' + "viewFullTaskInfoExpectedDeliveryDatename" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return;
    }
  }

  private GetCurrentStatusList(data) {
    this.PreviousSelectedCurrentWorkingStatusId =
      data.TaskCurrentWorkingStatusInfoID;
    var PostDataToService = {};
    this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "NewCurrentWorkingStatusList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.ViewNewCurrentWorkingStatusList =
          serviceResponse.newCurrentWorkingStatusListModelList;

        this.ViewNewCurrentWorkingStatusList.forEach((selectedRow, index) => {
          if (
            data.TaskCurrentWorkingStatusInfoID ==
            selectedRow.Task_Current_Working_Status_InfoID
          ) {
            this.selectedTaskCurrentWorkingStatus = selectedRow;
            if (
              this.selectedTaskCurrentWorkingStatus
                .Task_Current_Working_Status == "Review-postponed"
            ) {
              this.viewExpectedDeliveryDate = false;
              this.viewFullTaskInfoExpectedDate = this.parseDate(
                data.ExpectedDate
              );
            }
            return;
          }
        });
      });
  }

  isCurrentViewerResponsibleOrParticipant(
    responsiblePerson: Employee,
    taskParticipants: Employee[]
  ): boolean {
    if (
      this.isParticipant(taskParticipants) ||
      this.isResponsiblePerson(responsiblePerson)
    ) {
      return true;
    }
    return false;
  }

  isCurrentViewerResponsibleOrAssistant(
    responsiblePerson: Employee,
    taskAssistants: Employee[]
  ): boolean {
    if (
      this.isAssistantPerson(taskAssistants) ||
      this.isResponsiblePerson(responsiblePerson)
    ) {
      return true;
    }
    return false;
  }

  isCurrentViewerOnlyObserver(
    responsiblePerson: Employee,
    taskObservers: Employee[],
    taskParticipants: Employee[]
  ): boolean {
    if (
      !this.isParticipant(taskParticipants) &&
      !this.isResponsiblePerson(responsiblePerson)
    ) {
      return this.isObserver(taskObservers);
    }
    return false;
  }

  isParticipant(taskParticipants: Employee[]) {
    //debugger;
    const currentLoggedUserId = Config.OASOrgModel.LoggedUserID;

    for (const participant of taskParticipants) {
      if (+participant.EmployeeId === currentLoggedUserId) {
        return true;
      }
    }
    return false;
  }

  isCreatedBy(isCreatedBY: number) {
    const currentLoggedUserId = Config.OASOrgModel.LoggedUserID;
    return +currentLoggedUserId === +isCreatedBY;
  }

  isResponsiblePerson(responsiblePerson: Employee) {
    //debugger;
    const currentLoggedUserId = Config.OASOrgModel.LoggedUserID;

    return +currentLoggedUserId === +responsiblePerson.EmployeeId;
  }

  isAssistantPerson(TaskAssistantPersons: Employee[]) {
    //debugger;
    const currentLoggedUserId = Config.OASOrgModel.LoggedUserID;

    for (const Assistant of TaskAssistantPersons) {
      if (+Assistant.EmployeeId === currentLoggedUserId) {
        return true;
      }
    }
    return false;
  }

  isObserver(taskObservers: Employee[]) {
    //debugger;
    const currentLoggedUserId = Config.OASOrgModel.LoggedUserID;

    for (const observer of taskObservers) {
      if (+observer.EmployeeId === currentLoggedUserId) {
        return true;
      }
    }
    return false;
  }

  isEligibleToEdit(): boolean {
    //#region VALIDATIONS
    if (!this.TaskResponsiblePersonID) {
      // handle
      return false;
    }

    // check the current logged in user id with selected task's user id
    if (Config.OASOrgModel.LoggedUserID === this.TaskResponsiblePersonID) {
      return true;
    }
  }

  populateHistoryTable(isfromhistory) {
    this.columnDefs = [
      {
        headerName: "Date",
        field: "CreatedOn",
        tooltipField: "CreatedOn",
        minWidth: 80,
        maxWidth: 80,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
        cellRenderer: this.dateTimeFormatCellRenderer,
      },
      {
        headerName: "Created By",
        field: "CreatedBy",
        tooltipField: "CreatedBy",
        minWidth: 140,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
      },
      {
        headerName: "Update Disposition",
        field: "EventName",
        tooltipField: "EventName",
        minWidth: 160,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
      },
      {
        headerName: "Update",
        field: "Description",
        tooltipField: "Description",
        width: 530,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
      },
    ];

    this.commonhelperobj
      .PostData(`${Config.OASAppnURL}TaskHistoryList`, {
        TaskInfoID: this.headinInfo.TaskInfoID,
      })
      .subscribe((response) => {
        const history = response.TaskHistoryList as Array<any>;

        history.forEach((event) => {
          event.Description = this.parseEventDescription(event.Description);
        });

        this.rowData = history;
        this.gridOptions.onGridReady = () => {
          // this.gridOptions.columnApi.autoSizeAllColumns();
          // this.gridOptions.api.sizeColumnsToFit();
          // window.onresize = (() =>
          //   this.gridOptions.api.sizeColumnsToFit()
          // )
        };
        this.historicalEventsCount = response.TaskHistoryList.length;
        if (!isfromhistory) this.commentsclick();
      });
  }

  public onSelectionChanged(event) {
    // console.log(event);
  }

  /**
   * @author Sanjay Idpuganti
   */
  private parseEventDescription(description: string): string {
    if (!description.includes(description)) {
      return description;
    }

    if (!description.includes("-->")) {
      return description;
    }

    const [previous, changed] = description
      .split("-->")
      .map((value) => value.trim());

    const previousContents: Array<string> = [];
    const changedContents: Array<string> = [];

    if (previous) {
      // contains only one value
      if (previous.includes(",")) {
        previous
          .split(",")
          .map((content) => previousContents.push(content.split("|")[1]));
      } else {
        previousContents.push(previous.split("|")[1]);
      }
    }

    if (changed) {
      // contains only one value
      if (changed.includes(",")) {
        changed
          .split(",")
          .map((content) => changedContents.push(content.split("|")[1]));
      } else {
        changedContents.push(changed.split("|")[1]);
      }
    }

    let previousDescription = "";

    if (previousContents.length === 1) {
      previousDescription = previousContents[0];
    } else if (previousContents.length > 1) {
      previousDescription = previousContents.join(",");
    }

    let changedDescription = "";

    if (changedContents.length === 1) {
      changedDescription = changedContents[0];
    } else if (changedContents.length > 1) {
      changedDescription = changedContents.join(",");
    }

    if (!changedDescription && previousDescription) {
      return `Removed ${previousDescription}`;
    }

    if (changedDescription && !previousDescription) {
      return `Added ${changedDescription}`;
    }

    return previousDescription + " --> " + changedDescription;
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    // ...
    // let elemtInstance: any = this.ViewFullTaskInfo.nativeElement.querySelector('#summernoteeditor');
    // elemtInstance = $(elemtInstance);
    // if (this.hasValue(elemtInstance) && elemtInstance.summernote) {
    //   elemtInstance.summernote('destroy');
    // }
  }

  hasValue(item: any) {
    return (
      item !== null &&
      item !== undefined &&
      item !== "" &&
      item.toString().length !== 0
    );
  }

  commentsclick() {
    this.IsTaskFrom = this.IsTaskFrom;
    this.isFromExternalComment = false;
    this.GetTaskBodyList();
    this.VieworLoadpreviousCommentsBlock = false;
    this.showHistoryGrid = false;
    this.commentsHide = false;
    this.addCommentsShowHide = false;

    $("#div-task-history").removeClass("tabsActiveColorClass");
    $("#div-task-comments").addClass("tabsActiveColorClass");
    $("#div-task-external-comments").removeClass("tabsActiveColorClass");
    if (this.hasValue(this.formInformation.isfrom) && this.isfrompageinit) {
      setTimeout(() => {
        this.externalcommentsclick();
      }, 1000);
      this.showHideAcknowledgementSent = false;
      this.isfrompageinit = false;
    }
  }

  externalcommentsclick() {
    this.IsTaskFrom = this.IsTaskFrom;
    this.isFromExternalComment = true;
    this.GetTaskBodyList();
    this.VieworLoadpreviousCommentsBlock = false;
    this.showHistoryGrid = false;
    this.commentsHide = false;
    this.addCommentsShowHide = false;
    $("#div-task-history").removeClass("tabsActiveColorClass");
    $("#div-task-comments").removeClass("tabsActiveColorClass");
    $("#div-task-external-comments").addClass("tabsActiveColorClass");
  }

  historyclick() {
    this.populateHistoryTable(true);
    this.showHistoryGrid = true;
    this.commentsHide = true;
    this.addCommentsShowHide = true;
    $("#div-task-comments").removeClass("tabsActiveColorClass");
    $("#div-task-history").addClass("tabsActiveColorClass");
    $("#div-task-external-comments").removeClass("tabsActiveColorClass");
  }

  addCommentClickEvent() {
    this.showEditorOnClick = false;
    this.showCommentOnClick = true;
  }

  cancelCommentClickEvent() {
    this.TaskAddCommentsInfoModel.SelectedCommentBody = "";
    this.myCommentsFiles = [];
    this.showEditorOnClick = true;
    this.showCommentOnClick = false;
  }

  // backClickEvent() {
  //   this._router.navigate(['/home', { outlets: { 'main': 'app-view-task-information' }, }]);
  // }
  BackButtonClickEvent() {
    // this._router.navigateByUrl('ViewFullTaskInformationComponent');

    this.viewFullTaskRouteToRespectiveTab();

    // if (clickedItem.MenuInfoID === 58) {
    //   clickedItem.IsFromEmployeeOrShiftsInfo = true;
    // } else {
    //   clickedItem.IsFromEmployeeOrShiftsInfo = false;
    // }

    // outletObj[clickedItem.routename] = ['app-view-task-information', this.setCommonData(clickedItem)];
  }

  viewFullTaskRouteToRespectiveTab() {
    const outletObj = {};
    const clickedItem: any = {};
    clickedItem.routename = "main";
    clickedItem.routepath = "/home";
    // && this.hasValue(this.formInformation.SelectedTabItem)
    if (this.hasValue(this.formInformation)) {
      clickedItem.PreviouslySelectedTabItem =
        this.formInformation.SelectedTabItem;
      clickedItem.previouslySelectedSearchFilters =
        this.formInformation.StatemaintainPreviouslySearchFilters;
      clickedItem.previouslySelectedTaskNumber =
        this.formInformation.editmodedata.TaskNumber;
      clickedItem.previouslySelectedEditModeData =
        this.formInformation.editmodedata;
      clickedItem.NeedtoShowAllTasks = this.formInformation.NeedtoShowAllTasks;
      //clickedItem.previouslySelectedPageNumber =  this.formInformation.EmployeeTaskListRecordCount;
    }
    switch (this.formInformation.isfrom) {
      case 1:
        outletObj[clickedItem.routename] = [
          "by-status.component",
          this.setCommonData(clickedItem),
        ];
        break;
      case 2:
        outletObj[clickedItem.routename] = [
          "by-customer.component",
          this.setCommonData(clickedItem),
        ];
        break;
      case 3:
        outletObj[clickedItem.routename] = [
          "by-implementer.component",
          this.setCommonData(clickedItem),
        ];
        break;
      case 4:
        outletObj[clickedItem.routename] = [
          "by-repsonsible-person.component",
          this.setCommonData(clickedItem),
        ];
        break;
      case 5:
        outletObj[clickedItem.routename] = [
          "by-major-topics.component",
          this.setCommonData(clickedItem),
        ];
        break;
      case 6:
        outletObj[clickedItem.routename] = [
          "customer-tickets.component",
          this.setCommonData(clickedItem),
        ];
        break;
      case 7:
        outletObj[clickedItem.routename] = [
          "easy-form-task-report.component",
          this.setCommonData(clickedItem),
        ];
        break;
      case 8:
        outletObj[clickedItem.routename] = [
          "all-customer-tickets.component",
          this.setCommonData(clickedItem),
        ];
        break;
      case 9:
        outletObj[clickedItem.routename] = [
          "over-due-report.component",
          this.setCommonData(clickedItem),
        ];
        break;
      case 10:
        outletObj[clickedItem.routename] = [
          "statistics-report.component",
          this.setCommonData(clickedItem),
        ];
        break;
      case 11:
        outletObj[clickedItem.routename] = [
          "not-responded-tickets-report-list.component",
          this.setCommonData(clickedItem),
        ];
        break;
      case 12:
        outletObj[clickedItem.routename] = [
          "ticket-monitoring-report.component",
          this.setCommonData(clickedItem),
        ];
        break;
        case 13:
          outletObj[clickedItem.routename] = [
            "app-replied-all-tickets-to-customer.component",
            this.setCommonData(clickedItem),
          ];
          break;
          case 14:
            outletObj[clickedItem.routename] = [
              "app-customer-replied-morethan-three-comments-fortickets",
              this.setCommonData(clickedItem),
            ];
            break;
      default:
        outletObj[clickedItem.routename] = [
          "app-view-task-information",
          this.setCommonData(clickedItem),
        ];
        break;
    }
    this._router.navigate([clickedItem.routepath, { outlets: outletObj }]);
  }

  GetEmployeesList() {
    const PostDataToService = {};
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "OrganizationEmployeesList",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.viewFullTaskInfoResposiblePerson = serviceResponse.EmployeeList;
        this.EmployeeListInfo = serviceResponse.EmployeeList;
        this.EmployeeObserversListInfo = serviceResponse.EmployeeList;
      });
  }

  // tslint:disable-next-line:no-shadowed-variable
  // encodeImageFileAsURL(element) {
  //   const file = element.files[0];
  //   const reader = new FileReader();
  //   reader.onloadend = function () {
  //     console.log('RESULT', reader.result);
  //   };
  //   reader.readAsDataURL(file);
  // }

  viewFullTaskInfoBreifDetailViewClick() {
    if (this.viewFullTaskInfoBreifDetailView == true) {
      this.viewFullTaskInfoTitle = "Brief View";
      $("#btnViewFullTaskInfoMoreOptions")
        .removeClass("DetailIconBlackColor")
        .addClass("BriefIconBlackColor");
      this.HiddenInDetailView = false;
      this.viewFullTaskInfoBreifDetailView = false;
      this.assignParticipantsNamestongModel(
        this.SelectedAssistantPerson,
        "SelectedAssistantPersonName",
        "EmployeeFullName"
      );
      this.assignParticipantsNamestongModel(
        this.viewFullTaskInfoSelectedAdditionalResponsiblePersons,
        "SelectedAdditionalResponsiblePersonName",
        "EmployeeFullName"
      );
      this.assignParticipantsNamestongModel(
        this.viewFullTaskInfoSelectedObservers,
        "SelectedObserversPersonNames",
        "EmployeeFullName"
      );
    } else {
      this.viewFullTaskInfoTitle = "Detail View";
      $("#btnViewFullTaskInfoMoreOptions")
        .removeClass("BriefIconBlackColor")
        .addClass("DetailIconBlackColor");
      this.HiddenInDetailView = true;
      this.viewFullTaskInfoBreifDetailView = true;
    }
  }

  getAllUrlParams(url) {
    // get query string from url (optional) or window
    let queryString = url ? url.split("?")[1] : window.location.search.slice(1);

    // we'll store the parameters here
    const obj = {};

    // if query string exists
    if (queryString) {
      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split("#")[0];

      // split our query string into its component parts
      const arr = queryString.split("&");

      for (let i = 0; i < arr.length; i++) {
        // separate the keys and the values
        const a = arr[i].split("=");

        // in case params look like: list[]=thing1&list[]=thing2
        // tslint:disable-next-line:no-unnecessary-initializer
        let paramNum = undefined;
        let paramName = a[0].replace(/\[\d*\]/, function (v) {
          paramNum = v.slice(1, -1);
          return "";
        });

        // set parameter value (use 'true' if empty)
        let paramValue = typeof a[1] === "undefined" ? true : a[1];

        // (optional) keep case consistent
        paramName = paramName.toLowerCase();
        paramValue = paramValue.toLowerCase();

        // if parameter name already exists
        if (obj[paramName]) {
          // convert value to array (if still string)
          if (typeof obj[paramName] === "string") {
            obj[paramName] = [obj[paramName]];
          }
          // if no array index number specified...
          if (typeof paramNum === "undefined") {
            // put the value on the end of the array
            obj[paramName].push(paramValue);
          } else {
            // put the value at that index number
            obj[paramName][paramNum] = paramValue;
          }
        } else {
          obj[paramName] = paramValue;
        }
      }
    }

    return obj;
  }

  url_content(url) {
    return $.get(url);
  }

  GetTaskBodyList() {
    const PostDataToService = {
      TaskInfoID: this.headinInfo.TaskInfoID,
      LoggedInFromPractice: this.isFromExternalComment,
      isnotfromEhr: true,
    };

    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "EmployeeTaskAttachmentList1",
        PostDataToService
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        this.fullattachmentsList = serviceResponse.AttachmentsModelList;
        if (this.isFromExternalComment) {
          // setTimeout(() => {
          this.count = this.externalcommentscount =
            this.fullattachmentsList[0].CommentsCount;
          if (parseInt(this.count) > 1) {
            this.ifCommentsCountisLessThanOne = true;
          } else this.ifCommentsCountisLessThanOne = false;
          // }, 10);
          // if(this.isfrompageinit){
          // this.isfrompageinit=false;
          // return;
          // }
        } else {
          // setTimeout(() => {
          this.count = this.commentscount =
            this.fullattachmentsList[0].CommentsCount;
          // tslint:disable-next-line:radix
          if (parseInt(this.count) > 1) {
            this.ifCommentsCountisLessThanOne = true;
          } else this.ifCommentsCountisLessThanOne = false;
          // }, 100);
          // if(this.isFromExternalComment&&this.isfrompageinit){
          //   return;
          // }
        }
        this.taskBodyList = this.fullattachmentsList.filter(
          (item) =>
            item.AttachmentType === 1 && item.AttachmentUrlSequence === 0
        );

        this.commonhelperobj
          .GetStringBytesFromSignedURL(
            this.taskBodyList[0].AttachmentURL,
            "text/html"
          )
          .subscribe(async (successdata2) => {
            // MainBodyAttachmentsList contains all the html data which came from http request

            // this.taskDescription = successdata2;

            this.MainBodyAttachmentsList = "<div>" + successdata2 + "</div>";

            // unused object
            this.MainBodyImagesAttachmentsList = [];

            // this array is used to hold attachments of the main body
            this.imagesattachmentslist = [];

            // array to hold main body's attachment
            this.AttachmentsList = [];

            // from MainBodyAttachmentsList which contains html data convert it to jquery object
            const GetMainBodyAttachmentsList = $(this.MainBodyAttachmentsList);

            // collectionofAttachments holds all the images in html
            const collectionofAttachments =
              GetMainBodyAttachmentsList.find("img");

            // as images are in collectionofAttachments object remove images from original html
            //GetMainBodyAttachmentsList.find('img').remove();

            // from all images get count of images
            const collectionofimgsLength2 = collectionofAttachments.length;
            const elementfieldmainbody = {
              Imgaetag: null,
              attachmentType: null,
              AttachmentName: "",
            };

            // mainbodyinfo holds html except images
            let mainbodyinfo = GetMainBodyAttachmentsList.html();

            mainbodyinfo = mainbodyinfo
              .replace("SafeValue must use [property]=binding:", "")
              .replace("(see http://g.co/ng/security#xss)", "");
            // this global object is used to display html of task body
            this.TaskDescription = this.sanitizedTaskDescription(
              mainbodyinfo
            ) as any;
         

            this.getAttachments().then(() => this.getCommentsAttachments());
          });
      });
  }

  // public async downloadPdf(pdf: any) {
  //   const pdfSource = await this.attachmentService.sourceFromUrl(pdf.AttachmentURL);

  //   this.attachmentService.downloadPdf(pdfSource);
  // }

  // get attachments
  async getAttachments() {
    let abc = [];
    // assigning image attachments
    this.imagesattachmentslist = this.attachmentsService.imageAttachments(
      this.fullattachmentsList
    );

    this.pdfAttachments = this.attachmentsService.pdfAttachments(
      this.fullattachmentsList
    );
    // console.log(this.imagesattachmentslist.length, "length");
    if (this.imagesattachmentslist.length > 0) {
      let newAttchmentsList = [];
      for (let index = 0; index < this.imagesattachmentslist.length; index++) {
        const attachmentType = this.imagesattachmentslist[index].AttachmentType;

        const sourceUrl = this.imagesattachmentslist[index].AttachmentURL;
        let AttachmentName = "";
        if (this.hasValue(this.imagesattachmentslist[index].AttachmentName)) {
          AttachmentName = this.imagesattachmentslist[index].AttachmentName;
        } else {
          AttachmentName = "";
        }

        const source = await this.attachmentsService.sourceFromUrl(sourceUrl);
        const element = {
          Imgaetag: "data:image/png;base64" + "," + source,
          attachmentType: attachmentType,
          AttachmentName: AttachmentName,
        };
        newAttchmentsList.push(element);
        this.AttachmentsList = newAttchmentsList;
        // console.log(this.AttachmentsList, "Attachement");
      }
    }
  }

  getCommentsAttachments() {
    return this.getCommentsText()
      .then(() => this.renderCommentAttachments())
      .catch(() => console.log("No Comments"));
  }

  getCommentsText() {
    // setting comments list
    this.messagesList = this.fullattachmentsList.filter(
      (item) => item.AttachmentType === 1 && item.AttachmentUrlSequence >= 1
    );

    return new Promise((resolve) => {
      const subscriptions = [];
      if (this.messagesList.length > 0) {
        const MessagesLength = this.messagesList.length;

        for (let index = 0; index < MessagesLength; index++) {
          subscriptions.push(
            this.commonhelperobj.GetStringBytesFromSignedURL(
              this.messagesList[index].AttachmentURL,
              "text/html"
            )
          );
        }

        forkJoin(subscriptions).subscribe((response) => {
          for (
            let responseIndex = 0;
            responseIndex < response.length;
            responseIndex++
          ) {
            const responseData = response[responseIndex];
            this.CommentsAttachmentsList = "<div>" + responseData + "</div>";

            const GetCommentsAttachmentsList = $(this.CommentsAttachmentsList);
            const collectionofAttachments =
              GetCommentsAttachmentsList.find("img");
            GetCommentsAttachmentsList.find("img").remove();
            let collectionofimgsLength2: any;
            collectionofimgsLength2 = collectionofAttachments.length;
            this.messagesList[responseIndex].commentsattachmentslist = [];
            const elementfield2 = {
              Imgaetag: null,
              AttachmentName: "",
              attachmentType: null
            };

            for (let index2 = 0; index2 < collectionofimgsLength2; index2++) {
              elementfield2.Imgaetag =
                collectionofAttachments[index2].attributes["src"].value;
              let AttachmentName = "";
              if (
                this.hasValue(collectionofAttachments[index2].AttachmentName)
              ) {
                AttachmentName = collectionofAttachments[index2].AttachmentName;
              } else {
                AttachmentName = "";
              }
              elementfield2.AttachmentName = AttachmentName;
              elementfield2.attachmentType = collectionofAttachments[index2].AttachmentType
              this.messagesList[responseIndex].commentsattachmentslist.push(
                this.cloneObject(elementfield2)
              );
            }
            // console.log(this.messagesList,"MSS")

            const commentsinfo = GetCommentsAttachmentsList.html();
            this.messagesList[responseIndex].messageinfo = commentsinfo;
            this.messagesList[responseIndex].CommentCreatedOn =
              this.formatDateTime(
                new Date(this.messagesList[responseIndex].CommentCreatedOn),
                "MM/dd/yyyy hh:mm tt"
              );
            // this.messagesList[responseIndex].CommentCreatedOn = this.messagesList[responseIndex].CommentCreatedOn;

            if (responseIndex === response.length - 1) {
              resolve();
            }
          }
        });
      } else {
        reject();
      }
    });
  }

  /**
   * Render comment images and attachments of the current task in HTML
   * @author Sanjay Idpuganti
   */
  private renderCommentAttachments() {
    /**
     * check if there are any attachments to render images
     */
    let doNotRenderImages = function () {
      return !(
        this.hasValue(this.messagesList) &&
        this.messagesList.length > 0 &&
        ((this.hasValue(commentAttachments) && commentAttachments.length > 0) ||
          (this.hasValue(pdfAttachments) && pdfAttachments.length > 0))
      );
    };

    /**
     * From all the attachments return the attachments that are in comments of the current task
     */
    let getCommentImageAttachments = function () {
      return this.fullattachmentsList.filter(
        (item) =>
          item.AttachmentType === 2 ||
          item.AttachmentType === 3 || item.AttachmentType === 10 ||
          (item.AttachmentType === 4 && item.AttachmentUrlSequence >= 1)
      );
    };

    let getCommentPdfAttachments = function () {
      return this.fullattachmentsList.filter(
        (item) =>
          (item.AttachmentType === 5 ||
            item.AttachmentType === 6 ||
            item.AttachmentType === 6 ||
            item.AttachmentType === 7 ||
            item.AttachmentType === 8 ||
            item.AttachmentType === 9) &&
          item.AttachmentUrlSequence >= 1
      );
    };

    /**
     * Check if attachments array of task comment has commentAttachment
     * @param comment Task comment
     * @param commentAttachment Attachment of any comment
     * @param element object containing ImgaeTag property which is later user to bind to html
     */
    // tslint:disable-next-line:no-shadowed-variable
    let imageAlreadyExistsInComment = function (
      comment,
      commentAttachment,
      element
    ) {
      const result = this.grep(
        comment.commentsattachmentslist,
        function (item) {
          return (
            item.AttachmentType === commentAttachment.AttachmentType &&
            item.AttachmentUrlSequence ===
            commentAttachment.AttachmentUrlSequence &&
            item.Imgaetag === element.Imgaetag
          );
        },
        null
      );

      return result.length > 0;
    };

    getCommentImageAttachments = getCommentImageAttachments.bind(this);
    doNotRenderImages = doNotRenderImages.bind(this);
    imageAlreadyExistsInComment = imageAlreadyExistsInComment.bind(this);
    getCommentPdfAttachments = getCommentPdfAttachments.bind(this);

    // array of attachment that belong to comments of the current attachment
    const commentAttachments = getCommentImageAttachments();

    const pdfAttachments = getCommentPdfAttachments();

    // console.log('--- PDF Attachmemnts ---');
    //console.log(pdfAttachments);

    if (doNotRenderImages()) {
      return;
    }

    //console.log(this.messagesList);
    for (const comment of this.messagesList) {
      // console.log(comment);

      for (const commentAttachment of commentAttachments) {
        if (
          comment.AttachmentUrlSequence ===
          commentAttachment.AttachmentUrlSequence
        ) {
          const sourceUrl = commentAttachment.AttachmentURL;

          let AttachmentName = "";
          if (this.hasValue(commentAttachment.AttachmentName)) {
            AttachmentName = commentAttachment.AttachmentName;
          } else {
            AttachmentName = "";
          }

          this.attachmentsService
            .sourceFromUrl(sourceUrl)
            .then((imageSource) => {
              const element = {
                Imgaetag: "data:image/png;base64" + "," + imageSource,
                AttachmentName,
                attachmentType: commentAttachment.AttachmentType,
              };

              if (
                !imageAlreadyExistsInComment(
                  comment,
                  commentAttachment,
                  element
                )
              ) {
                comment.commentsattachmentslist.push(this.cloneObject(element));
                this.AttachmentsHeaderHide = true;
              }
            });
        }
      }
      // console.log(pdfAttachments)
      for (const pdfAttachment of pdfAttachments) {
        // console.log(pdfAttachment)
        if (
          comment.AttachmentUrlSequence === pdfAttachment.AttachmentUrlSequence
        ) {
          if (!comment.pdfAttachments) {
            comment.pdfAttachments = [];
          }
          comment.pdfAttachments.push(pdfAttachment);
          this.AttachmentsHeaderHide = true;
        }
      }
    }
  }

  // /**
  //  * If the url is valid return the response in form of promise
  //  * @param sourceUrl The google data store url which returns base64 image
  //  */
  // sourceFromUrl = function (sourceUrl: string): Promise<string> | undefined {

  //   if (!sourceUrl) {
  //     return undefined;
  //   }

  //   return $.get(sourceUrl).promise();
  // };

  // getCommentsImagesAndAttachments() {
  //   // assigning image attachments
  //   const elementfield = {
  //     Imgaetag: null,
  //   };

  //   this.CommentsImagesAttachmentsList = this.fullattachmentsList
  //     .filter(
  //       item => item.AttachmentType === 2 || item.AttachmentType === 3 ||
  //         item.AttachmentType === 4 && item.AttachmentUrlSequence >= 1
  //     );

  //   if (this.hasValue(this.messagesList) &&
  //     this.messagesList.length > 0 && this.hasValue(this.CommentsImagesAttachmentsList) &&
  //     this.CommentsImagesAttachmentsList.length > 0) {
  //     this.messagesList.forEach(MessageElement => {
  //       this.CommentsImagesAttachmentsList.forEach(Attachmentelement => {
  //         if (MessageElement.AttachmentUrlSequence === Attachmentelement.AttachmentUrlSequence) {
  //           const urlsrcslist = Attachmentelement.AttachmentURL;

  //           $.get(urlsrcslist).then(success => {
  //             this.srcextractedlist = success;
  //             elementfield.Imgaetag = 'data:image/png;base64' + ',' + this.srcextractedlist;

  //             const onlyfields = this.grep(MessageElement.commentsattachmentslist, function (item) {
  //               return item.AttachmentType === Attachmentelement.AttachmentType &&
  //                 item.AttachmentUrlSequence === Attachmentelement.AttachmentUrlSequence
  //                 && item.Imgaetag === elementfield.Imgaetag;
  //             }, null);
  //             if (onlyfields.length <= 0) {
  //               MessageElement.commentsattachmentslist.push(this.cloneObject(elementfield));
  //             }
  //             // console.log(this.AttachmentsList);
  //           });
  //         }
  //       });
  //     });
  //   }
  // }

  onUpdateButtonClickEvent() {
    const postData = {
      TaskInfoID: this.taskId,
      TaskLatestStatusID: 2,
    } as any;

    if (
      this.taskStatusDetails.statusName !== this.initialTaskStatus.statusName
    ) {
      const taskChangeEvent: TaskChangeEvent = {
        Description:
          `${this.initialTaskStatus.statusId} | ${this.initialTaskStatus.statusName}` +
          `--> ${this.taskStatusDetails.statusId} | ${this.taskStatusDetails.statusName}`,
        EventId: TaskEventTypeEnum.StatusUpdated,
        TaskInfoId: this.taskId,
      };

      postData.TaskChangeEvents = [taskChangeEvent];

      this.commonhelperobj
        .PostData(`${Config.OASAppnURL}/ChangeTaskStatus`, postData)
        .subscribe((response) => {
          if (this.isError(response)) {
            return;
          }

          this.initialTaskStatus = this.taskStatusDetails;
        });
    } else {
      postData.TaskChangeEvents = [];
    }
  }

  public selectEventHandler(e: SelectEvent): void {
    this.uploadStateService.setUploadState(UploadState.uploadInProgress);
    this.filesCount += e.files.length;
  }

  public completeEventHandler(event: Event) {
    this.uploadStateService.setUploadState(UploadState.uploadComplete);
  }

  public removeEventHandler(event: RemoveEvent) {
    this.filesCount--;
    if (this.filesCount === 0) {
      this.uploadStateService.setUploadState(UploadState.removedAllFiles);
    }
  }


  draftComments() {
    const dialogRef = this.dialogService.open({
      title: "Draft Comments",
      // Show component
      content: ViewDraftCommentsComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      
      TaskInfoID: this.taskId,
    };
   
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.getCommentedDocumentDetails(result);
        this.AddDraftCommentClickEvent(result);
      
      }
    });
  }

  /**
   * Triggers when user click send button in comments section
   */
  async SendCommentsClickEvent() {
    if (!this.uploadStateService.isValidState()) {
      this.ShowErrorMessage("Please Wait till the files are uploaded");
      return;
    }

    let commentSectionAttachmentsContainer: Array<Attachment> =
      await this.attachmentsService.processUploadAttachments(
        this.myCommentsFiles
      );

    if (
      this.attachmentsService.duplicatesExists(
        commentSectionAttachmentsContainer as Array<Attachment>
      )
    ) {
      this.ShowErrorMessage(
        "Duplicates Exists in Uploaded Attachments Please Try Again."
      );
      return;
    }

    if (
      !this.attachmentsService.uploadedAttachmentsCompatable(
        commentSectionAttachmentsContainer
      )
    ) {
      this.ShowErrorMessage(
        "Uploaded attachments contain files other than JPG,JPEG,PNG,DocX,Doc,XLSX,XLs and PDF files."
      );
      return;
    }

    if (
      !this.hasValue(this.TaskAddCommentsInfoModel.SelectedCommentBody) ||
      !innerHTMLExists(this.TaskAddCommentsInfoModel.SelectedCommentBody, "p")
    ) {
      this.ShowErrorMessage("Please Enter Comment Details");
      const elementFound = this.ViewFullTaskInfo.nativeElement.querySelector(
        '[name="' + "viewtaskinfoSendCommentsDesc" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[1].children[0].focus();
      }
      return;
    }

    if (this.hasValue(this.TaskAddCommentsInfoModel.SelectedCommentBody)) {
      const commentBody = {
        AttachmentBase64Format: window.btoa(
          unescape(
            encodeURIComponent(
              this.TaskAddCommentsInfoModel.SelectedCommentBody
            )
          )
        ),
        AttachmentType: 1,
      };

      commentSectionAttachmentsContainer.push(commentBody);

      if (
        this.hasValue(this.formInformation) &&
        this.hasValue(this.formInformation.editmodedata)
      ) {
        this.TaskAddCommentsInfoModel.TaskInfoID =
          this.formInformation.editmodedata.TaskInfoID;
      }

      if (commentSectionAttachmentsContainer.length > 0) {
        this.TaskAddCommentsInfoModel.AttachmentsModelList =
          commentSectionAttachmentsContainer;
      }

      const taskChangeEvent: TaskChangeEvent = {
        Description: "Comment Added",
        EventId: TaskEventTypeEnum.Comments,
        TaskInfoId: this.taskId,
      };

      this.TaskAddCommentsInfoModel.TaskChangeEvents = [taskChangeEvent];

      // tslint:disable-next-line:max-line-length
      this.commonhelperobj
        .PostData(
          Config.OASAppnURL + "InsertCommentsInformation",
          this.TaskAddCommentsInfoModel
        )
        .subscribe((serviceResponse) => {
          if (this.isError(serviceResponse)) {
            return;
          }
          this.ShowSuccesseMessage("Task Comments Added SuccessFully.");
          this.myCommentsFiles = [];
          this.TaskAddCommentsInfoModel = {};
          this.showEditorOnClick = true;
          this.showCommentOnClick = false;
          this.VieworLoadpreviousCommentsBlock = false;
          // this.GetTaskBodyList();

          this.populateHistoryTable(false);
        });
    }
  }

  LoadPreviousCommentsInformation() {
    if (
      this.hasValue(this.formInformation) &&
      this.hasValue(this.formInformation.editmodedata)
    ) {
      this.TaskAddCommentsInfoModel.TaskInfoID =
        this.formInformation.editmodedata.TaskInfoID;
      this.TaskAddCommentsInfoModel.isnotfromEhr = true;
    }
    this.TaskAddCommentsInfoModel.LoggedInFromPractice =
      this.isFromExternalComment;
    // tslint:disable-next-line:max-line-length
    this.commonhelperobj
      .PostData(
        Config.OASOrganizationAppnURL + "EmployeeTaskAttachmentList2",
        this.TaskAddCommentsInfoModel
      )
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }

        this.fullattachmentsList = serviceResponse.AttachmentsModelList;

        this.getCommentsAttachments();
        // this.getCommentsImagesAndAttachments();
      });
  }

  loadPreviousCommentsClick(IsLoadPreviousComments) {
    if (IsLoadPreviousComments === true) {
      this.VieworLoadpreviousCommentsBlock = true;
      this.LoadPreviousCommentsInformation();
    } else {
      this.VieworLoadpreviousCommentsBlock = false;
      this.GetTaskBodyList();
    }
  }

  getFullImageOnClick(attachment) {
    const dialog: DialogRef = this.dialogService.open({
      title: "Please confirm",
      content: "Are you sure?",
      actions: [{ text: "No" }, { text: "Yes", primary: true }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        //console.log('close');
      } else {
        //console.log('action', result);
      }

      this.result = JSON.stringify(result);
    });
  }

  open(component, imgeurl, ImageInfo) {
    if (ImageInfo.attachmentType === 10) {
      const downloadLink = document.createElement("a");
      downloadLink.href = imgeurl;
      downloadLink.download = ImageInfo.AttachmentName;
      downloadLink.click();
      // console.log(imgeurl);
    } else {
      this[component + "Opened"] = true;
      //console.log(imgeurl);
      let AttachmentName = "";
      if (this.hasValue(ImageInfo) && this.hasValue(ImageInfo.AttachmentName)) {
        AttachmentName = ImageInfo.AttachmentName;
      } else {
        AttachmentName = "View Attachment";
      }
      this.imgeurl = imgeurl;
      this.TasksLoadImageURL(this.imgeurl, AttachmentName);
    }
  }

  TasksLoadImageURL(imageURL, AttachmentName) {
    // let elemtInstance: any = this.ViewFullTaskInfo.nativeElement.querySelector('#divTaskAttachment');
    // elemtInstance = $(elemtInstance);
    // elemtInstance.append('<img src=\'' + imageURL + '\' alt=\'Task Attachment\' id=\'TaskAttachment\' />');
    this.ViewAttachmentHeader = AttachmentName;
    this.taskImageSRC = imageURL;
  }
  close(component) {
    this[component + "Opened"] = false;
  }

  updateButtonClick() { }

  StartButtonClickEvent() {
    if (
      this.BindStartButtonHtml === "Start" ||
      this.BindStartButtonHtml === "Restart"
    ) {
      if (
        !this.hasValue(this._ecdDate.nativeElement.value) ||
        this._ecdDate.nativeElement.value === "" ||
        this._ecdDate.nativeElement.value === "__/__/____"
      ) {
        this.ShowErrorMessage("Please Select / Enter ECD");
        this.FocusonMatField("_ecdDate");
        return;
      }
      const postData = {
        TaskInfoID: this.taskId,
        TaskLatestStatusID: 2,
      } as any;

      const taskChangeEvent: TaskChangeEvent = {
        Description:
          (this.BindStartButtonHtml === "Start"
            ? `${1} | ${"Yet to Start"}`
            : `${8} | ${"On-hold"}`) + `--> ${2} | ${"On Going"}`,
        EventId: TaskEventTypeEnum.StatusUpdated,
        TaskInfoId: this.taskId,
      };
      this.BindStartButtonHtml = "Hold";
      if (!this.hasValue(this.taskStatusDetails)) this.taskStatusDetails = {};
      this.TaskLatestStatusID = this.taskStatusDetails.statusId = 2;
      this.TaskStatus = "On Going";
      postData.TaskChangeEvents = [taskChangeEvent];
      this.commonhelperobj
        .PostData(`${Config.OASAppnURL}/ChangeTaskStatus`, postData)
        .subscribe((response) => {
          if (this.isError(response)) {
            return;
          }
          this.ShowSuccesseMessage("Task is in In-Process.");
          // this.GetTaskBodyList();

          this.populateHistoryTable(
            $("#div-task-external-comments") &&
            $("#div-task-external-comments").hasClass("tabsActiveColorClass")
          );
          // this.initialTaskStatus = this.taskStatusDetails;
        });

      // this.commonhelperobj.PostData(Config.OASAppnURL + 'ChangeTaskStatus', postData).subscribe(serviceResponse => {
      //   if (this.isError(serviceResponse)) { return; }
      // //   this.ShowSuccesseMessage('Customization Details Saved SuccessFully.');
      // });
    } else {
      this.BindStartButtonHtml = "Restart";
      this.TaskStatus = "On-hold";
      if (!this.hasValue(this.taskStatusDetails)) this.taskStatusDetails = {};
      this.TaskLatestStatusID = this.taskStatusDetails.statusId = 8;
      const postData = {
        TaskInfoID: this.taskId,
        TaskLatestStatusID: 8,
      } as any;

      const taskChangeEvent: TaskChangeEvent = {
        Description: `${2} | ${"On Going"}` + `--> ${8} | ${"On-hold"}`,
        EventId: TaskEventTypeEnum.StatusUpdated,
        TaskInfoId: this.taskId,
      };
      postData.TaskChangeEvents = [taskChangeEvent];
      this.commonhelperobj
        .PostData(`${Config.OASAppnURL}/ChangeTaskStatus`, postData)
        .subscribe((response) => {
          if (this.isError(response)) {
            return;
          }
          this.ShowSuccesseMessage("Task is in Pending.");
          // this.GetTaskBodyList();
          this.populateHistoryTable(
            $("#div-task-external-comments") &&
            $("#div-task-external-comments").hasClass("tabsActiveColorClass")
          );
          // this.initialTaskStatus = this.taskStatusDetails;
        });
    }
  }
  FinishButtonClickEvent() {
    let TaskLatestStatusID =
      this.headinInfo.CreatedBy === this.headinInfo.ResponsiblePersonDesc
        ? 3
        : this.headinInfo.IsTaskCreatedByEmployee
          ? 5
          : 3;

    if (this.IsTaskCreatedfromEHR == true || this.IsTaskFrom == 3) {
      TaskLatestStatusID = 5;
    }

    const postData = {
      TaskInfoID: this.taskId,
      TaskLatestStatusID,
    } as any;

    let Description = "";

    if (TaskLatestStatusID == 3) {
      this.TaskStatus = Description = "Completed";
    } else {
      Description =
        `${2} | ${"On Going"}` + `--> ${3} | ${"Supposedly completed"}`;
      this.TaskStatus = "Review Pending";
    }
    this.isTaskInCompletedState = true;
    this.IsResponsiblePersonDisabled = true;
    const taskChangeEvent: TaskChangeEvent = {
      Description: Description,
      EventId: TaskEventTypeEnum.StatusUpdated,
      TaskInfoId: this.taskId,
    };
    postData.TaskChangeEvents = [taskChangeEvent];
    this.commonhelperobj
      .PostData(`${Config.OASAppnURL}/ChangeTaskStatus`, postData)
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
        this.ShowSuccesseMessage("Task is Supposedly completed.");
        if (this.isFromExternalComment) {
          this.selectedTaskCurrentWorkingStatus = {
            Task_Current_Working_Status_InfoID: "11",
            Task_Current_Working_Status: "Status Updated to Client",
          };
          this.TaskCurrentWorkingStatusUpdateClick(false);
        }
        this.InsertTaskCompletedStatusUpdatedToClientDate();
        this.hideFromSetByMe = true;
        this.hideFinishButtonAfterFinishing = true;
        // this.GetTaskBodyList()

        this.populateHistoryTable(
          $("#div-task-external-comments") &&
          $("#div-task-external-comments").hasClass("tabsActiveColorClass")
        );
        this.hideStatusChangeButtons = true;
        // this.initialTaskStatus = this.taskStatusDetails;
      });
  }

  private calculateTaskChanges(): Array<TaskChangeEvent> {
    const taskChangeEvents: Array<TaskChangeEvent> = [];

    if (this.observersChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.Observers,
        TaskInfoId: this.taskId,
        Description: this.populateObserversChangeDescription(),
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    if (this.responsiblePersonChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.ResponsiblePerson,
        TaskInfoId: this.taskId,
        Description: this.populateResponsiblePersonChangeDescription(),
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    if (this.participantsChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.Participants,
        TaskInfoId: this.taskId,
        Description: this.populateParticipantsChangeDescription(),
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    return taskChangeEvents;
  }

  private observersChanged(): boolean {
    // if (this.hasValue(this.viewFullTaskInfoSelectedObservers)) {
    //   if (this.initialObservers.length !== this.viewFullTaskInfoSelectedObservers.length) {
    //     return true;
    //   }

    //   for (const observer of this.initialObservers) {
    //     // undifined when not found
    //     const found = this.viewFullTaskInfoSelectedObservers.find(o => o.EmployeeFullName === observer.EmployeeFullName);

    //     if (!found) {
    //       return true;
    //     }
    //   }

    //   for (const observer of this.viewFullTaskInfoSelectedObservers) {
    //     const found = this.initialObservers.find(o => o.EmployeeFullName === observer.EmployeeFullName);
    //     if (!found) {
    //       return true;
    //     }
    //   }
    // }
    if (this.IsObserversInfoChanged == true) {
      return true;
    } else {
      return false;
    }
  }

  private participantsChanged(): boolean {
    // if (this.hasValue(this.viewFullTaskInfoSelectedAdditionalResponsiblePersons)) {
    //   if (this.initialParticipants.length !== this.viewFullTaskInfoSelectedAdditionalResponsiblePersons.length) {
    //     return true;
    //   }

    //   for (const participant of this.initialParticipants) {
    //     // undifined when not found
    //     const found = this.viewFullTaskInfoSelectedAdditionalResponsiblePersons.find(o => o.EmployeeFullName === participant.EmployeeFullName);

    //     if (!found) {
    //       return true;
    //     }
    //   }

    //   for (const participant of this.viewFullTaskInfoSelectedAdditionalResponsiblePersons) {
    //     const found = this.initialParticipants.find(o => o.EmployeeFullName === participant.EmployeeFullName);

    //     if (!found) {
    //       return true;
    //     }
    //   }
    // }

    if (this.IsAdditionalResponsiblePersonInfoChanged == true) {
      return true;
    } else {
      return false;
    }
  }

  private responsiblePersonChanged(): boolean {
    if (this.IsResponsiblePersonChanged == true) {
      return true;
    } else {
      return false;
    }
  }

  private populateObserversChangeDescription(): string {
    if (
      this.hasValue(this.viewFullTaskInfoSelectedObservers) &&
      this.viewFullTaskInfoSelectedObservers.length > 0
    ) {
      if (!this.hasValue(this.viewFullTaskInfoPreviousselectedObservers))
        this.viewFullTaskInfoPreviousselectedObservers = [];
      let description = this.viewFullTaskInfoPreviousselectedObservers
        .sort(this.byName)
        .map(
          (observer) => `${observer.EmployeeId} | ${observer.EmployeeFullName}`
        )
        .join(",");

      description += " --> ";

      description += this.viewFullTaskInfoSelectedObservers
        .sort(this.byName)
        .map(
          (observer) => `${observer.EmployeeId} | ${observer.EmployeeFullName}`
        )
        .join(",");

      return description;
    }
    // if (this.hasValue(this.viewFullTaskInfoSelectedObservers)) {
    //   let description =
    //     this.initialObservers
    //       .sort(this.byName)
    //       .map(observer => `${observer.EmployeeId} | ${observer.EmployeeFullName}`)
    //       .join(',');

    //   description += ' --> ';

    //   description += this.viewFullTaskInfoSelectedObservers
    //     .sort(this.byName)
    //     .map(observer => `${observer.EmployeeId} | ${observer.EmployeeFullName}`)
    //     .join(',');

    //   return description;
    // }
  }

  private populateParticipantsChangeDescription(): string {
    if (
      this.hasValue(
        this.viewFullTaskInfoSelectedAdditionalResponsiblePersons
      ) &&
      this.viewFullTaskInfoSelectedAdditionalResponsiblePersons.length > 0
    ) {
      if (!this.hasValue(this.viewFullTaskInfoPreviousselectedParticipants))
        this.viewFullTaskInfoPreviousselectedParticipants = [];
      let description = this.viewFullTaskInfoPreviousselectedParticipants
        .sort(this.byName)
        .map(
          (observer) => `${observer.EmployeeId} | ${observer.EmployeeFullName}`
        )
        .join(",");

      description += " --> ";

      description += this.viewFullTaskInfoSelectedAdditionalResponsiblePersons
        .sort(this.byName)
        .map(
          (observer) => `${observer.EmployeeId} | ${observer.EmployeeFullName}`
        )
        .join(",");

      return description;
    }
    // if (this.hasValue(this.viewFullTaskInfoSelectedAdditionalResponsiblePersons)) {
    //   let description =
    //     this.initialParticipants
    //       .sort(this.byName)
    //       .map(participant => `${participant.EmployeeId} | ${participant.EmployeeFullName}`)
    //       .join(',');

    //   description += ' --> ';

    //   description += this.viewFullTaskInfoSelectedAdditionalResponsiblePersons
    //     .sort(this.byName)
    //     .map(participant => `${participant.EmployeeId} | ${participant.EmployeeFullName}`)
    //     .join(',');

    //   return description;
    // }
  }

  private populateResponsiblePersonChangeDescription(): string {
    if (this.hasValue(this.SelectedResponsiblePerson)) {
      if (this.hasValue(this.PreviousSelectedResponsiblePerson)) {
        return (
          `${this.PreviousSelectedResponsiblePerson.EmployeeId} | ${this.PreviousSelectedResponsiblePerson.EmployeeFullName}` +
          ` --> ${this.SelectedResponsiblePerson.EmployeeId} | ${this.SelectedResponsiblePerson.EmployeeFullName}`
        );
      } else {
        return ` --> ${this.SelectedResponsiblePerson.EmployeeId} | ${this.SelectedResponsiblePerson.EmployeeFullName}`;
      }
    }
  }

  // private populateForwardToChangeDescription(): string {
  //   if (this.hasValue(this.SelectedAssistantPerson)) {
  //     const { responsiblePerson, taskObservers, taskParticipants, taskAssistant } = this.employeeService.parseEmployeeRoles(this.TaskUserInfo);
  //     this.PreviousSelectedAssistantPerson = taskAssistant;
  //     if (this.hasValue(this.PreviousSelectedAssistantPerson)) {
  //       return `${this.PreviousSelectedAssistantPerson.EmployeeId} | ${this.PreviousSelectedAssistantPerson.EmployeeFullName}` +
  //         ` --> ${this.SelectedAssistantPerson.EmployeeId} | ${this.SelectedAssistantPerson.EmployeeFullName}`;
  //     }
  //     else {
  //       return ` --> ${this.SelectedAssistantPerson.EmployeeId} | ${this.SelectedAssistantPerson.EmployeeFullName}`;
  //     }
  //   }
  // }

  // private populateForwardToChangeDescription(): string {
  //   if (this.hasValue(this.SelectedAssistantPerson) && this.SelectedAssistantPerson.length > 0) {

  //     const { responsiblePerson, taskObservers, taskParticipants, taskAssistant } = this.employeeService.parseEmployeeRoles(this.TaskUserInfo);
  //     this.PreviousSelectedAssistantPerson = taskAssistant;
  //     // let description = "Task Forward To ";
  //     let description =
  //       this.PreviousSelectedAssistantPerson
  //         .sort(this.byName)
  //         .map(observer => `${observer.EmployeeId} | ${observer.EmployeeFullName}`)
  //         .join(',');

  //     description += ' --> ';

  //     description += this.SelectedAssistantPerson
  //       .sort(this.byName)
  //       .map(observer => `${observer.EmployeeId} | ${observer.EmployeeFullName}`)
  //       .join(',');

  //     return description;
  //   }
  // }

  private byName(employee1, employee2) {
    if (employee1.EmployeeFullName < employee2.EmployeeFullName) {
      return -1;
    }
    if (employee1.EmployeeFullName > employee2.EmployeeFullName) {
      return 1;
    }
    return 0;
  }

  onResponsiblePersonDropdownSelectionChangeEvent(event) {
    //console.log(event);
  }

  onResponsiblePersonDropdownValueChangeEvent(event) {
    if (!this.hasValue(event)) {
      this.SelectedResponsiblePerson = this.responsiblePersonValueWhenOpened;
    }
  }

  onResponsiblePersonDropdownOpenEvent(event) {
    this.responsiblePersonValueWhenOpened = this.cloneObject(
      this.SelectedResponsiblePerson
    );
  }

  onAdditionalResponsiblePersonsChange() {
    this.viewFullTaskInfoSelectedAdditionalResponsiblePersons =
      this.employeeService.filterDuplicateEmployees(
        this.viewFullTaskInfoSelectedAdditionalResponsiblePersons
      );
  }

  onObserversChange() {
    this.viewFullTaskInfoSelectedObservers =
      this.employeeService.filterDuplicateEmployees(
        this.viewFullTaskInfoSelectedObservers
      );
  }

  UpdateTaskUserRolesInformation(
    navigatetoMainPage: boolean,
    assignRespectiveValues: boolean
  ) {
    // console.log(navigatetoMainPage, assignRespectiveValues);
    if (
      this.PreviousSelectedCurrentWorkingStatusId.toString() !==
      this.selectedTaskCurrentWorkingStatus
        .Task_Current_Working_Status_InfoID &&
      assignRespectiveValues
    ) {
      if (this.TaskCurrentWorkingStatusUpdateClick(false) == true) return;
    }
    if (!assignRespectiveValues) {
      this.viewFullTaskInfoPreviousselectedParticipants =
        this.clonerService.deepClone(
          this.viewFullTaskInfoSelectedAdditionalResponsiblePersons
        );
      this.PreviousSelectedResponsiblePerson = this.clonerService.deepClone(
        this.SelectedResponsiblePerson
      );
      this.viewFullTaskInfoPreviousselectedObservers =
        this.clonerService.deepClone(this.viewFullTaskInfoSelectedObservers);
      this.viewFullTaskInfoSelectedAdditionalResponsiblePersons =
        this.clonerService.deepClone(this.additionalPersonsView);
      this.viewFullTaskInfoSelectedObservers = this.clonerService.deepClone(
        this.observersview
      );
      this.SelectedResponsiblePerson = this.clonerService.deepClone(
        this.responsiblePersonview
      );
      this.SelectedQAPerson = this.clonerService.deepClone(this.QAPersonView);

      this.SelectedCSharpPerson = this.clonerService.deepClone(this.CsharppPersonView);
      this.SelectedDBPerson = this.clonerService.deepClone(this.DBPersonView);
      this.SelectedJSPerson = this.clonerService.deepClone(this.JsPersonView)
    }
    if (
      !this.hasValue(this.SelectedResponsiblePerson) ||
      !this.hasValue(this.SelectedResponsiblePerson.EmployeeId)
    ) {
      this.ShowErrorMessage("Please Select Responsible Person");
      return;
    }
    // else if (
    //   !this.hasValue(this.SelectedQAPerson) ||
    //   !this.hasValue(this.SelectedQAPerson.EmployeeId)
    // ) {
    //   this.ShowErrorMessage("Please Select QA Person");
    //   return;
    // }

    // else if (
    //   !this.hasValue(this.SelectedCSharpPerson) ||
    //   !this.hasValue(this.SelectedCSharpPerson.EmployeeId)
    // ) {
    //   this.ShowErrorMessage("Please Select C# Person Person");
    //   return;
    // }

    // if (this.hasValue(this.SelectedAssistantPerson) && this.SelectedAssistantPerson.length > 0) {
    //   this.SelectedAssistantPerson.forEach((selectedRow, index) => {
    //     if (this.SelectedResponsiblePerson.EmployeeId == selectedRow.EmployeeId) {
    //       this.IsresponsiblePersonAndFwdToSame = true;
    //     }
    //   });
    // }

    // if (this.IsresponsiblePersonAndFwdToSame == true) {
    //   this.ShowErrorMessage('Responsible Person and Forward To Should not be Same.');
    //   return;
    // }

    const postData: any = {};

    postData.TaskInfoID = this.taskId;
    if (
      this.IsTaskCreatedfromEHR == true &&
      this.IsResponsiblePersonChanged == true
    ) {
      postData.sendEmailtoResponsiblePerson = true;
      postData.UserId = this.UserId;
      postData.EHRPracticeID = this.EHRPracticeId;
      postData.TaskNumber = this.taskNumber;
      postData.ResponsiblePersonId = this.SelectedResponsiblePerson.EmployeeId;
    }
    if (this.IsTaskFrom === 3 && this.IsEHRResponsiblePersonChanged === true) {
      postData.IsSentEmailtoResponsiblePerson = true;
    }

    if (
      this.hasValue(
        this.viewFullTaskInfoSelectedAdditionalResponsiblePersons
      ) &&
      this.viewFullTaskInfoSelectedAdditionalResponsiblePersons.length > 0
    ) {
      // tslint:disable-next-line:no-shadowed-variable
      this.viewFullTaskInfoSelectedAdditionalResponsiblePersons.forEach(
        (element) => {
          const EmployeesAndRolesModel = {
            EmployeeId: element.EmployeeId,
            RoleID: 4,
          };

          this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
        }
      );
    }

    if (
      this.hasValue(this.viewFullTaskInfoSelectedObservers) &&
      this.viewFullTaskInfoSelectedObservers.length > 0
    ) {
      this.viewFullTaskInfoSelectedObservers.forEach((Observerselement) => {
        let RoleID = 3;
        if (this.IsTaskFrom === 3) {
          RoleID = 5;
        }
        const EmployeesAndRolesModel = {
          EmployeeId: Observerselement.EmployeeId,
          RoleID: RoleID,
        };

        this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
      });
    }

    if (this.hasValue(this.SelectedResponsiblePerson)) {
      const EmployeesAndRolesModel = {
        EmployeeId: this.SelectedResponsiblePerson.EmployeeId,
        RoleID: 2,
      };
      this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
    }
    if (assignRespectiveValues) {
      if (
        this.hasValue(this.initialResponsiblePerson) &&
        this.hasValue(this.SelectedResponsiblePerson)
      ) {
        if (
          +this.initialResponsiblePerson.EmployeeId !==
          +this.SelectedResponsiblePerson.EmployeeId
        ) {
          const EmployeesAndRolesModel = {
            EmployeeId: this.initialResponsiblePerson.EmployeeId,
            RoleID: 3,
          };
          this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
        }
      }
    }

    if (this.hasValue(this.SelectedQAPerson.EmployeeId)) {
      const EmployeesAndRolesModel = {
        EmployeeId: this.SelectedQAPerson.EmployeeId,
        RoleID: 8,
      };
      this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
    }

    if (this.hasValue(this.SelectedCSharpPerson.EmployeeId)) {
      const EmployeesAndRolesModel = {
        EmployeeId: this.SelectedCSharpPerson.EmployeeId,
        RoleID: 9,
      };
      this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
    }

    if (this.hasValue(this.SelectedDBPerson.EmployeeId)) {
      const EmployeesAndRolesModel = {
        EmployeeId: this.SelectedDBPerson.EmployeeId,
        RoleID: 10,
      };
      this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
    }

    if (this.hasValue(this.SelectedJSPerson.EmployeeId)) {
      const EmployeesAndRolesModel = {
        EmployeeId: this.SelectedJSPerson.EmployeeId,
        RoleID: 11,
      };
      this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
      // console.log(this.EmployeesAndRolesModelList, "list")
    }
    // if (this.hasValue(this.SelectedAssistantPerson) && this.SelectedAssistantPerson.length > 0) {
    //   this.SelectedAssistantPerson.forEach(Assistantselement => {
    //     const EmployeesAndRolesModel = {
    //       EmployeeId: Assistantselement.EmployeeId,
    //       RoleID: 7,
    //     };

    //     this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
    //   });
    // }

    if (this.EmployeesAndRolesModelList.length > 0) {
      postData.EmployeesAndRolesModelList = this.EmployeesAndRolesModelList;
    }
    postData.TaskChangeEvents = this.calculateTaskChanges();

    // if (this.IsAssistantPersonInfoChanged == true) {
    //   const taskChangeEvent: TaskChangeEvent = {
    //     EventId: TaskEventTypeEnum.TaskForwardTo,
    //     TaskInfoId: this.taskId,
    //     Description: this.populateForwardToChangeDescription()
    //   };
    //   if (this.hasValue(postData.taskChangeEvents)) {
    //     postData.taskChangeEvents.push(taskChangeEvent);
    //   }
    //   else {
    //     postData.taskChangeEvents = [];
    //     postData.taskChangeEvents.push(taskChangeEvent);
    //   }
    // }
    postData.IsTaskFrom = this.IsTaskFrom;
    postData.EHRPracticeID = this.EHRPracticeId;
    postData.TaskNumber = this.taskNumber;
    this.commonhelperobj
      .PostData(`${Config.OASAppnURL}/UpdateTaskUsersInformation`, postData)
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }

        this.ShowSuccesseMessage("Update Successfull");
        this.sendInternalTicketNotificationtoEmployees();
        this.updateTaskUsersandClearChangeEventFlags();
        if (navigatetoMainPage) {
          this.viewFullTaskRouteToRespectiveTab();
          
        }
        // this.ShowSuccesseMessage('Task is in Pending.');
        this.initialTaskStatus = this.taskStatusDetails;
        this.VieworLoadpreviousCommentsBlock = false;
      });
  }

  cancelTaskUserRolesInfoClickEvent() {
    this.viewFullTaskInfoSelectedObservers = [];
    this.viewFullTaskInfoSelectedAdditionalResponsiblePersons = [];
    this.SelectedResponsiblePerson = {};
    this.SelectedAssistantPerson = {};
  }

  ReturnForCompletionClickEvent() {
    const postData = {
      TaskInfoID: this.taskId,
      TaskLatestStatusID: 1,
    } as any;
    this.TaskStatus = "Yet to Start";
    const taskChangeEvent: TaskChangeEvent = {
      Description:
        `${5} | ${"Supposedly completed"}` + `--> ${2} | ${"Yet to Start"}`,
      EventId: TaskEventTypeEnum.StatusUpdated,
      TaskInfoId: this.taskId,
    };
    postData.TaskChangeEvents = [taskChangeEvent];
    this.commonhelperobj
      .PostData(`${Config.OASAppnURL}/ChangeTaskStatus`, postData)
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
        // this.ShowSuccesseMessage('Task is Supposedly completed.');
        this.hideFromSetByMe = true;
        this.hideFinishButtonAfterFinishing = true;
        this.TaskacceptorDeclineButtonsHide = true;
        // this.initialTaskStatus = this.taskStatusDetails;
      });
  }

  AcceptButtonClickEvent() {
    const postData = {
      TaskInfoID: this.taskId,
      TaskLatestStatusID: 3,
    } as any;
    this.TaskStatus = "Completed";
    const taskChangeEvent: TaskChangeEvent = {
      Description:
        `${5} | ${"Supposedly completed"}` + `--> ${3} | ${"Completed"}`,
      EventId: TaskEventTypeEnum.StatusUpdated,
      TaskInfoId: this.taskId,
    };
    postData.TaskChangeEvents = [taskChangeEvent];
    this.commonhelperobj
      .PostData(`${Config.OASAppnURL}/ChangeTaskStatus`, postData)
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
        // this.ShowSuccesseMessage('Task is Supposedly completed.');
        this.hideFromSetByMe = true;
        this.hideFinishButtonAfterFinishing = true;
        this.TaskacceptorDeclineButtonsHide = true;
        // this.initialTaskStatus = this.taskStatusDetails;
      });
  }

  responsiblePersonIconClickEvent() {
    //alert(this.taskStatusDetails.statusId);

    if (
      this.hasValue(this.taskStatusDetails) &&
      this.hasValue(this.taskStatusDetails.statusId) &&
      this.taskStatusDetails.statusId == 2
    ) {
      this.ShowErrorMessage(
        "Responsible Person for On Going Task Cannot be Changed ."
      );
      return;
    }

    const dialogRef = this.dialogService.open({
      title: "Select Responsible Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,

      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    this.PreviousSelectedResponsiblePerson = this.SelectedResponsiblePerson;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (this.isTaskInCompletedState || this.IsResponsiblePersonDisabled)
        return;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedResponsiblePersonInfo)) {
        } else {
          this.SelectedResponsiblePersonInfo = "";
        }
      } else {
        this.SelectedResponsiblePersonInfo = result;
        if (this.hasValue(this.SelectedResponsiblePersonInfo)) {
          this.IsResponsiblePersonChanged = true;
          this.SelectedResponsiblePerson.EmployeeId =
            this.SelectedResponsiblePersonInfo.EmployeeId;
          this.SelectedResponsiblePerson.EmployeeFullName =
            this.SelectedResponsiblePersonInfo.EmployeeFullName;
        }
      }
    });
  }

  RemoveResponsiblePersonInfo() {
    if (
      this.hasValue(this.taskStatusDetails) &&
      this.hasValue(this.taskStatusDetails.statusId) &&
      this.taskStatusDetails.statusId == 2
    ) {
      this.ShowErrorMessage(
        "Responsible Person for On Going Task Cannot be Changed ."
      );
      return;
    }
    this.SelectedResponsiblePerson.EmployeeId = undefined;
    this.SelectedResponsiblePerson.EmployeeFullName = "";
  }

  // assistantPersonIconClickEvent() {
  //   this.IsresponsiblePersonAndFwdToSame = false;
  //   const dialogRef = this.dialogService.open({
  //     title: 'Select Person to Forward Task',
  //     // Show component
  //     content: AdditionalParticipantAndObserversPopupComponentComponent,

  //     height: 720,
  //     width: 1140,
  //   });
  //   dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData = this.viewFullTaskInfoPreviousselectedParticipants = this.viewFullTaskInfoSelectedAdditionalResponsiblePersons;
  //   dialogRef.content.instance.StateMaintainObserverPersonsData = this.viewFullTaskInfoPreviousselectedObservers = this.viewFullTaskInfoSelectedObservers;
  //   dialogRef.content.instance.StateMaintainAssistantPersonsData = this.SelectedAssistantPerson;
  //   dialogRef.result.subscribe((result) => {
  //     //debugger;
  //     if (this.isTaskInCompletedState) return;
  //     if (result instanceof DialogCloseResult) {
  //       if (this.hasValue(this.SelectedAssistantPersonInfo)) {

  //       } else {
  //         this.SelectedAssistantPersonInfo = '';
  //       }
  //     } else {
  //       //assign popup result data to global variable
  //       this.PopupDataFromMultiSelectUserSelectionPopup = result;

  //       this.SelectedAdditionalResponsiblePersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;;
  //       this.IsAdditionalResponsiblePersonInfoChanged = true;
  //       this.viewFullTaskInfoSelectedAdditionalResponsiblePersons = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;;
  //       if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
  //         this.SelectedAdditionalResponsiblePersonName = "";
  //         this.SelectedAdditionalResponsiblePersonInfo.forEach((selectedRow, index) => {
  //           this.SelectedAdditionalResponsiblePersonName += selectedRow.EmployeeFullName + ', ';
  //         });
  //         this.SelectedAdditionalResponsiblePersonName = this.SelectedAdditionalResponsiblePersonName.substring(0, this.SelectedAdditionalResponsiblePersonName.length - 2);
  //       }
  //       else {
  //         this.SelectedAdditionalResponsiblePersonName = "";
  //       }

  //       //*****assigning observers data****************
  //       this.SelectedObserversInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
  //       this.IsObserversInfoChanged = true;
  //       this.viewFullTaskInfoSelectedObservers = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
  //       if (this.hasValue(this.SelectedObserversInfo)) {
  //         this.SelectedObserversPersonNames = "";
  //         this.SelectedObserversInfo.forEach((selectedRow, index) => {
  //           this.SelectedObserversPersonNames += selectedRow.EmployeeFullName + ', ';
  //         });

  //         this.SelectedObserversPersonNames = this.SelectedObserversPersonNames.substring(0, this.SelectedObserversPersonNames.length - 2);
  //       }
  //       else {
  //         this.SelectedObserversPersonNames = "";
  //       }

  //       //************assigning assistant persons info*************************
  //       this.SelectedAssistantPersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
  //       this.IsAssistantPersonInfoChanged = true;
  //       this.SelectedAssistantPerson = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
  //       if (this.hasValue(this.SelectedAssistantPersonInfo)) {
  //         this.SelectedAssistantPersonName = "";
  //         this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
  //           this.SelectedAssistantPersonName += selectedRow.EmployeeFullName + ', ';
  //         });

  //         this.SelectedAssistantPersonName = this.SelectedAssistantPersonName.substring(0, this.SelectedAssistantPersonName.length - 2);
  //       }
  //       else {
  //         this.SelectedAssistantPersonName = "";
  //       }

  //     }
  //   });
  // }

  // RemoveAssistantPersonInfo() {
  //   this.SelectedAssistantPersonName = "";
  //   this.SelectedAssistantPerson = undefined;
  //   this.IsresponsiblePersonAndFwdToSame = false;
  // }

  onColumnResized() {
    //console.log('onColumnResized');
    this.gridOptions.api.resetRowHeights();
  }

  addCommentsClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Add Internal Comments",
      // Show component
      content: UncustomizedtaskCommentsComponent,

      height: 500,
      width: 700,
    });
    dialogRef.content.instance.TaskInfoID =
      this.formInformation.editmodedata.TaskInfoID;
    dialogRef.content.instance.IsPublicComment = false;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
      } else {
        var postData: any = result;
        if (this.hasValue(postData)) {
          postData.TaskInfoId = this.formInformation.editmodedata.TaskInfoID;
          this.commonhelperobj
            .PostData(Config.OASAppnURL + "InsertCommentsInformation", postData)
            .subscribe((serviceResponse) => {
              if (this.isError(serviceResponse)) {
                return;
              }
              this.ShowSuccesseMessage("Task Comments Added SuccessFully.");
              this.myCommentsFiles = [];
              this.TaskAddCommentsInfoModel.SelectedCommentBody = "";
              this.TaskAddCommentsInfoModel.AttachmentsModelList = [];
              this.VieworLoadpreviousCommentsBlock = false;

              // this.GetTaskBodyList();

              this.populateHistoryTable(false);
            });
        }
      }
    });
  }

  sendResponseToCustomerClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Send Response to Customer",
      // Show component
      content: UncustomizedtaskCommentsComponent,

      height: 500,
      width: 700,
    });
    dialogRef.content.instance.TaskInfoID =
      this.formInformation.editmodedata.TaskInfoID;
    dialogRef.content.instance.IsPublicComment = true;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
      } else {
      }
    });
  }

  ViewFullTaskInfoAdditionalResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select User(s)",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData =
      this.viewFullTaskInfoSelectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData =
      this.viewFullTaskInfoSelectedObservers;
    // dialogRef.content.instance.StateMaintainAssistantPersonsData = this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
        } else {
          this.SelectedAdditionalResponsiblePersonInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        this.SelectedAdditionalResponsiblePersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.IsAdditionalResponsiblePersonInfoChanged = true;
        this.viewFullTaskInfoSelectedAdditionalResponsiblePersons =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );
          this.SelectedAdditionalResponsiblePersonName =
            this.SelectedAdditionalResponsiblePersonName.substring(
              0,
              this.SelectedAdditionalResponsiblePersonName.length - 2
            );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        //*****assigning observers data****************
        this.SelectedObserversInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.IsObserversInfoChanged = true;
        this.viewFullTaskInfoSelectedObservers =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });

          this.SelectedObserversPersonNames =
            this.SelectedObserversPersonNames.substring(
              0,
              this.SelectedObserversPersonNames.length - 2
            );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        // this.SelectedAssistantPersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        // this.IsAssistantPersonInfoChanged = true;
        // this.SelectedAssistantPerson = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        // if (this.hasValue(this.SelectedAssistantPersonInfo)) {
        //   this.SelectedAssistantPersonName = "";
        //   this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
        //     this.SelectedAssistantPersonName += selectedRow.EmployeeFullName + ', ';
        //   });

        //   this.SelectedAssistantPersonName = this.SelectedAssistantPersonName.substring(0, this.SelectedAssistantPersonName.length - 2);
        // }
        // else {
        //   this.SelectedAssistantPersonName = "";
        // }
      }
    });
  }

  ViewFullTaskInfoRemoveAdditionalResponsiblePersonInfo() {
    this.SelectedAdditionalResponsiblePersonName = "";
    this.viewFullTaskInfoSelectedAdditionalResponsiblePersons = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }

  ViewFullTaskinfoObserversIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Users(s)",
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData =
      this.viewFullTaskInfoSelectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData =
      this.viewFullTaskInfoSelectedObservers;
    // dialogRef.content.instance.StateMaintainAssistantPersonsData = this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedObserversInfo)) {
        } else {
          this.SelectedObserversInfo = "";
        }
      } else {
        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        this.SelectedAdditionalResponsiblePersonInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        this.IsAdditionalResponsiblePersonInfoChanged = true;
        this.viewFullTaskInfoSelectedAdditionalResponsiblePersons =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach(
            (selectedRow, index) => {
              this.SelectedAdditionalResponsiblePersonName +=
                selectedRow.EmployeeFullName + ", ";
            }
          );
          this.SelectedAdditionalResponsiblePersonName =
            this.SelectedAdditionalResponsiblePersonName.substring(
              0,
              this.SelectedAdditionalResponsiblePersonName.length - 2
            );
        } else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }

        //*****assigning observers data****************
        this.SelectedObserversInfo =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.IsObserversInfoChanged = true;
        this.viewFullTaskInfoSelectedObservers =
          this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames +=
              selectedRow.EmployeeFullName + ", ";
          });

          this.SelectedObserversPersonNames =
            this.SelectedObserversPersonNames.substring(
              0,
              this.SelectedObserversPersonNames.length - 2
            );
        } else {
          this.SelectedObserversPersonNames = "";
        }

        //************assigning assistant persons info*************************
        // this.SelectedAssistantPersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        // this.IsAssistantPersonInfoChanged = true;
        // this.SelectedAssistantPerson = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;
        // if (this.hasValue(this.SelectedAssistantPersonInfo)) {
        //   this.SelectedAssistantPersonName = "";
        //   this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
        //     this.SelectedAssistantPersonName += selectedRow.EmployeeFullName + ', ';
        //   });

        //   this.SelectedAssistantPersonName = this.SelectedAssistantPersonName.substring(0, this.SelectedAssistantPersonName.length - 2);
        // }
        // else {
        //   this.SelectedAssistantPersonName = "";
        // }
      }
    });
  }

  ViewFullTaskinfoRemoveObserversIconClickEvent() {
    this.SelectedObserversPersonNames = "";
    this.viewFullTaskInfoSelectedObservers = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }

  private populateCurrentStatusChangeEventDescription(): string {
    if (this.selectedTaskCurrentWorkingStatus) {
      return `Set ${this.selectedTaskCurrentWorkingStatus.Task_Current_Working_Status} as Task Current Working Status`;
    }
  }

  TaskCurrentWorkingStatusUpdateClick(updateClick) {
    // if (
    //   !this.hasValue(
    //     this.selectedTaskCurrentWorkingStatus.Task_Current_Working_Status_InfoID
    //   )
    // ) {
    //   this.ShowErrorMessage("Please Select Current Working Status");
    //   //this.CreateTaskInfo.nativeElement.querySelector('#TaskAddNewCurrentWokringStatus').focus();
    //   const elementFound = this.ViewFullTask.nativeElement.querySelector(
    //     '[name="' + "viewFullTaskInfoCurrentStatus" + '"]'
    //   );
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].focus();
    //   }
    //   return true;
    // }
    // const daysDifference = this.DateDiffInDays(new Date(), this.viewFullTaskInfoExpectedDate);
    const date = this.parseDate(this.headinInfo.ExpectedDate);
    const DaysDifference = this.DateDiffInDays(
      date,
      this.viewFullTaskInfoExpectedDate
    );
    if (
      this.selectedTaskCurrentWorkingStatus.Task_Current_Working_Status ==
      "Review-postponed"
    ) {
      if (this.hasValue(DaysDifference) && DaysDifference >= 0) {
        this.ShowErrorMessage(
          "Expected Delivery Date Should be Greater than Previous Expected Delivery Date"
        );
        const elementFound = this.ViewFullTask.nativeElement.querySelector(
          '[name="' + "viewFullTaskInfoExpectedDeliveryDatename" + '"]'
        );
        if (this.hasValue(elementFound)) {
          elementFound.children[0].children[0].children[0].children[0].focus();
        }
        return true;
      }
    }

    var postData: any = {
      TaskInfoID: this.taskId,
    };
    // if(this.selectedTaskCurrentWorkingStatus.Task_Current_Working_Status=="Review-postponed"){
    if (this.hasValue(this.viewFullTaskInfoExpectedDate)) {
      this.viewFullTaskInfoExpectedDate = this.formatValue(
        this.viewFullTaskInfoExpectedDate
      );
      postData.ExpectedDeliveryDate = this.viewFullTaskInfoExpectedDate;
    } else {
      postData.ExpectedDeliveryDate = this.headinInfo.ExpectedDate;
    }
    // }
    if (this.hasValue(this.selectedTaskCurrentWorkingStatus)) {
      postData.TaskCurrentWorkingStatusInfoID =
        this.selectedTaskCurrentWorkingStatus.Task_Current_Working_Status_InfoID;
    }

    const taskChangeEvents: Array<TaskChangeEvent> = [];
    const taskChangeEvent: TaskChangeEvent = {
      EventId: TaskEventTypeEnum.TaskCurrentWorkingStatus,
      TaskInfoId: this.taskId,
      Description: this.populateCurrentStatusChangeEventDescription(),
    };

    taskChangeEvents.push(taskChangeEvent);

    postData.TaskChangeEvents = taskChangeEvents;

    this.commonhelperobj
      .PostData(Config.OASAppnURL + "NewCurrentWorkingStatusUpdate", postData)
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        if (updateClick) {
          if (this.viewExpectedDeliveryDate == true) {
            this.ShowSuccesseMessage(
              "Task Current Working Status Saved SuccessFully."
            );
          } else {
            this.ShowSuccesseMessage(
              "Task Current Working Status and Expected Delivery Date Saved SuccessFully."
            );
          }
        }
      });
  }

  AddNewCommentClickEvent() {
    this.addCommentsShowHide = true;
    setTimeout(function () {
      document.getElementById("divsendComments").focus();
      var namedsad = document.getElementsByClassName("ql-editor ql-blank")[0];
      $(namedsad).trigger("focus");

    }, 20);


  }


  AddDraftCommentClickEvent(result: any) {
    this.addCommentsShowHide = true;
    setTimeout(function () {
      document.getElementById("divSaveasDraft").focus();
      var namedsad = document.getElementsByClassName("ql-editor ql-blank")[0];
      $(namedsad).trigger("focus");
      // console.log(result)

    }, 20);
    this.viewModel.CommentBody = result.BodyContent;
    this.viewModel.ThanksandRegards = result.Signature;

  }

  viewFullTaskInfoCancelCommentClickEvent() {
    this.addCommentsShowHide = false;
    this.myCommentsFiles = [];
    this.viewModel.CommentBody = "";
    this.ReplaceSaveTOUpdate=false;
    this.CommentedAttachmentsList=[];
    this.viewModel.ThanksandRegards="";
    this.viewModel.TimeTaken=0;
    this.viewModel.CommentBody="";

  }

  async viewFullTaskInfoSendButtonClick(isSaveAsDraft: boolean) {
    if (!this.commentFileUploadStateService.isValidState()) {
      this.ShowErrorMessage("Please Wait till the files are uploaded");
      return;
    }

    const postData = {
      IsDraft: isSaveAsDraft,
      BodyContent: this.viewModel.CommentBody,
      IsPublicComment: this.isFromExternalComment,
      TaskInfoID: this.formInformation.editmodedata.TaskInfoID,
      AttachmentsModelList: [],
    } as any;
    //debugger;
    if (this.isFromExternalComment == true) {
      this.CommentDescription = "Send Response to Customer";
    } else {
      this.CommentDescription = "Internal Comments Added";
    }

    if (
      !this.hasValue(this.viewModel.CommentBody) ||
      this.isTextorImageExistinCommentsBody()
    ) {
      this.ShowErrorMessage("Please Enter Comment Details");
      return;
    } else {
      this.commentAttachmentContainer =
        await this.attachmentsService.processUploadAttachments(
          this.myCommentsFiles
        );
    }

    //console.log(commentAttachmentContainer);

    if (
      !this.hasValue(this.viewModel.CommentBody) ||
      !innerHTMLExists(this.viewModel.CommentBody, "p")
    ) {
      this.ShowErrorMessage("Please Enter Comment Details");
      const elementFound = this.ViewFullTask.nativeElement.querySelector(
        '[name="' + "CommentsDesc" + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[1].children[0].focus();
      }
      return;
    }
    if (this.hasValue(this.viewModel.TimeTaken)) {
      postData.TimeTaken=this.viewModel.TimeTaken;
    } else {
      this.ShowErrorMessage("Please Enter Time taken In minutes.");
      return;
    }
    //Added SUman
    if (this.hasValue(this.viewModel.ThanksandRegards)) {
      postData.CommentsThanksAttachment = window.btoa(
        unescape(encodeURIComponent(this.viewModel.ThanksandRegards))
      );
    } else {
      this.ShowErrorMessage("Please Enter Signature.");
      return;
    }
    
    // console.log("check commentbody", this.viewModel.CommentBody)
    if (this.hasValue(this.viewModel.CommentBody)) {
      const attachment = {
        AttachmentBase64Format: window.btoa(
          unescape(encodeURIComponent(this.viewModel.CommentBody))
        ),
        AttachmentType: 1,
      };

      this.commentAttachmentContainer.push(attachment);

      if (
        this.attachmentsService.duplicatesExists(
          this.commentAttachmentContainer as Array<Attachment>
        )
      ) {
        this.ShowErrorMessage(
          "Duplicates Exists in Uploaded Attachments Please Try Again."
        );
        this.myCommentsFiles = [];
        return;
      }
      if (
        !this.attachmentsService.uploadedAttachmentsCompatable(
          this.commentAttachmentContainer
        )
      ) {
        this.ShowErrorMessage(
          "Uploaded attachments contain files other than JPG,JPEG,PNG,DocX,Doc,XLSX,XLs and PDF files."
        );
        this.myCommentsFiles = [];
        return;
      }

      postData.AttachmentsModelList = this.commentAttachmentContainer;

      const taskChangeEvent: TaskChangeEvent = {
        Description: this.CommentDescription,
        EventId: TaskEventTypeEnum.Comments,
        TaskInfoId: this.formInformation.editmodedata.TaskInfoID,
      };

      postData.TaskChangeEvents = [taskChangeEvent];
      //debugger;
      this.myCommentsFiles = [];
      this.viewModel.CommentBody = "";
      this.viewModel.IsPublicComment = true;
      this.insertCommentsInformation(postData,isSaveAsDraft);
    }
  }
  insertCommentsInformation(result,isSaveAsDraft) {
    var postData: any = result;
    if (this.isFromExternalComment) {
      postData.isFromNewCustomerMails = true;
      postData.UserId = this.UserId;
      postData.EHRPracticeID = this.EHRPracticeId;
      if (this.IsTaskFrom === 3) {
        postData.TaskCreatedBy = this.headinInfo.ResponsiblePersonDesc;
        // console.log(this.headinInfo,"Header INFo")
      } else {
        postData.TaskCreatedBy = this.TaskCreatedBy;
      }
      // postData.TaskCreatedBy = this.TaskCreatedBy;
    } else {
      postData.TaskCreatedByEmployeeId = this.headinInfo.TaskCreatedByUserId;
      postData.isTaskCreatedByMe = this.IsTaskCreatedByMe;
    }
    if (this.hasValue(postData)) {
      postData.TaskNumber = this.taskNumber;
      postData.TaskInfoId = this.formInformation.editmodedata.TaskInfoID;
      postData.IsTaskFrom = this.IsTaskFrom;
      postData.ResponsiblePersonId = this.SelectedResponsiblePerson.EmployeeId;
      // console.log("calling is this from intimation")
      this.commonhelperobj
        .PostData(Config.OASAppnURL + "InsertCommentsInformation", postData)
        .subscribe((serviceResponse) => {
          if (this.isError(serviceResponse)) {
            return;
          }
          if (this.isFromExternalComment == true) {
            if(isSaveAsDraft==true){
              this.ShowSuccesseMessage("Task Comments Drafted.");
            }else{
            this.ShowSuccesseMessage("Response Sent to Customer SuccessFully.");
            }
          } else {
            if(isSaveAsDraft==true){
              this.ShowSuccesseMessage("Task Comments Drafted.");
            }
            else {
            this.ShowSuccesseMessage("Task Comments Added SuccessFully.");
            }
          }
          this.viewModel.ThanksandRegards = "";
          this.viewModel.TimeTaken;
          this.myCommentsFiles = [];
          this.TaskAddCommentsInfoModel.SelectedCommentBody = "";
          this.TaskAddCommentsInfoModel.AttachmentsModelList = [];
          this.VieworLoadpreviousCommentsBlock = false;
          if (this.isFromExternalComment) this.externalcommentsclick();
          this.populateHistoryTable(this.isFromExternalComment);
          this.viewFullTaskInfoCancelCommentClickEvent();
        });
    }
  }


//#region " UPdate Comment Info"
async updateCommentInfoSendButtonClick(isSaveAsDraft: boolean) {
  if (!this.commentFileUploadStateService.isValidState()) {
    this.ShowErrorMessage("Please Wait till the files are uploaded");
    return;
  }

  const postData = {
    IsDraft: isSaveAsDraft,
    TaskCommentID:this.TaskCommentID,
    BodyContent: this.viewModel.CommentBody,
    IsPublicComment: this.isFromExternalComment,
    TaskInfoID: this.formInformation.editmodedata.TaskInfoID,
    AttachmentsModelList: [],
  } as any;
  //debugger;
  if (this.isFromExternalComment == true) {
    this.CommentDescription = "Send Response to Customer";
  } else {
    this.CommentDescription = "Internal Comments Added";
  }

  if (
    !this.hasValue(this.viewModel.CommentBody) ||
    this.isTextorImageExistinCommentsBody()
  ) {
    this.ShowErrorMessage("Please Enter Comment Details");
    return;
  } else {
    this.commentAttachmentContainer =
      await this.attachmentsService.processUploadAttachments(
        this.myCommentsFiles
      );
  }

  //console.log(commentAttachmentContainer);

  if (
    !this.hasValue(this.viewModel.CommentBody) ||
    !innerHTMLExists(this.viewModel.CommentBody, "p")
  ) {
    this.ShowErrorMessage("Please Enter Comment Details");
    const elementFound = this.ViewFullTask.nativeElement.querySelector(
      '[name="' + "CommentsDesc" + '"]'
    );
    if (this.hasValue(elementFound)) {
      elementFound.children[1].children[0].focus();
    }
    return;
  }

  if (this.hasValue(this.viewModel.TimeTaken)) {
    postData.TimeTaken=this.viewModel.TimeTaken;
  } else {
    this.ShowErrorMessage("Please Enter Time taken In minutes.");
    return;
  }
  //Added SUman
  if (this.hasValue(this.viewModel.ThanksandRegards)) {
    postData.CommentsThanksAttachment = window.btoa(
      unescape(encodeURIComponent(this.viewModel.ThanksandRegards))
    );
  } else {
    this.ShowErrorMessage("Please Enter Signature.");
    return;
  }


  // console.log("check commentbody", this.viewModel.CommentBody)
  if (this.hasValue(this.viewModel.CommentBody)) {
    const attachment = {
      AttachmentBase64Format: window.btoa(
        unescape(encodeURIComponent(this.viewModel.CommentBody))
      ),
      AttachmentType: 1,
    };

    this.commentAttachmentContainer.push(attachment);

    if (
      this.attachmentsService.duplicatesExists(
        this.commentAttachmentContainer as Array<Attachment>
      )
    ) {
      this.ShowErrorMessage(
        "Duplicates Exists in Uploaded Attachments Please Try Again."
      );
      this.myCommentsFiles = [];
      return;
    }
    if (
      !this.attachmentsService.uploadedAttachmentsCompatable(
        this.commentAttachmentContainer
      )
    ) {
      this.ShowErrorMessage(
        "Uploaded attachments contain files other than JPG,JPEG,PNG,DocX,Doc,XLSX,XLs and PDF files."
      );
      this.myCommentsFiles = [];
      return;
    }

  

  
      if (this.hasValue(this.CommentedAttachmentsList)) {
        const attachmentModels = this.CommentedAttachmentsList.map(attachment => this.attachmentsService.convertCommentToAttachmentModel(attachment));
        // const pdfAttachmentModels = await this.processpdfAttachments();
  
        this.commentAttachmentContainer = this.commentAttachmentContainer.concat(attachmentModels);//.concat(pdfAttachmentModels);
      }

    if (this.hasValue(this.CommentedpdfAttachments)) {

      //const pdfAttachmentModels = await this.processpdfAttachments();
      this.commentAttachmentContainer = this.commentAttachmentContainer.concat(this.CommentedpdfAttachments);

    }

    postData.AttachmentsModelList = this.commentAttachmentContainer;

    // console.log(this.commentAttachmentContainer,"Attment COnatai")

    const taskChangeEvent: TaskChangeEvent = {
      Description: this.CommentDescription,
      EventId: TaskEventTypeEnum.Comments,
      TaskInfoId: this.formInformation.editmodedata.TaskInfoID,
    };

    postData.TaskChangeEvents = [taskChangeEvent];
    //debugger;
    this.myCommentsFiles = [];
    this.viewModel.CommentBody = "";
    this.viewModel.IsPublicComment = true;
    this.updateCommentsInformation(postData,isSaveAsDraft);
  }
}
updateCommentsInformation(result,isSaveAsDraft) {
  var postData: any = result;
  if (this.isFromExternalComment) {
    postData.isFromNewCustomerMails = true;
    postData.UserId = this.UserId;
    postData.EHRPracticeID = this.EHRPracticeId;
    if (this.IsTaskFrom === 3) {
      postData.TaskCreatedBy = this.headinInfo.ResponsiblePersonDesc;
      // console.log(this.headinInfo,"Header INFo")
    } else {
      postData.TaskCreatedBy = this.TaskCreatedBy;
    }
    // postData.TaskCreatedBy = this.TaskCreatedBy;
  } else {
    postData.TaskCreatedByEmployeeId = this.headinInfo.TaskCreatedByUserId;
    postData.isTaskCreatedByMe = this.IsTaskCreatedByMe;
  }
  if (this.hasValue(postData)) {
    postData.TaskNumber = this.taskNumber;
    postData.TaskInfoId = this.formInformation.editmodedata.TaskInfoID;
    postData.IsTaskFrom = this.IsTaskFrom;
    postData.ResponsiblePersonId = this.SelectedResponsiblePerson.EmployeeId;
    // console.log("calling is this from intimation")
    this.commonhelperobj
      .PostData(Config.OASAppnURL + "UpdateCommentsInformation", postData)
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
        if (this.isFromExternalComment == true) {
          if(isSaveAsDraft==true){
            this.ShowSuccesseMessage("Task Comments Drafted.");
          }else{
          this.ShowSuccesseMessage("Response Sent to Customer SuccessFully.");
          }
        } else {
          if(isSaveAsDraft==true){
            this.ShowSuccesseMessage("Task Comments Drafted.");
          }
          else {
          this.ShowSuccesseMessage("Task Comments Added SuccessFully.");
          }
        }
        this.viewModel.ThanksandRegards = "";
        this.myCommentsFiles = [];
        this.viewModel.TimeTaken;
        this.TaskAddCommentsInfoModel.SelectedCommentBody = "";
        this.TaskAddCommentsInfoModel.AttachmentsModelList = [];
        this.VieworLoadpreviousCommentsBlock = false;
        if (this.isFromExternalComment) this.externalcommentsclick();
        this.populateHistoryTable(this.isFromExternalComment);
        this.viewFullTaskInfoCancelCommentClickEvent();
      });
  }
}
//#endregion

  commentSelectEventHandler(event) {
    // console.log(event, "Save")
    if (!this.hasValue(this.viewModel.CommentBody)) {
      this.ShowErrorMessage("Please Enter Comments Before Uploading the file");
    } else {
      this.commentFileUploadStateService.setUploadState(
        UploadState.uploadInProgress
      );
      this.commentFilesCount += (<any>event).files.length;
    }
  }
  commentRemoveEventHandler(event) {
    // console.log(event, "Remove")
    this.commentFilesCount--;
    if (this.commentFilesCount === 0) {
      this.commentFileUploadStateService.setUploadState(
        UploadState.removedAllFiles
      );
    }
  }
  commentCompleteEventHandler(event) {
    // console.log(event, "complete")
    this.commentFileUploadStateService.setUploadState(
      UploadState.uploadComplete
    );
  }
  /**
   * TODO: Replace it with data from database
   * Return id of severity
   * @param severityDescription from description string return it's respective id
   */
  private calculateSeverityId(severityDescription: "High" | "Normal" | "Low") {
    if (severityDescription === "High") {
      return 1;
    } else if (severityDescription === "Normal") {
      return 2;
    } else if (severityDescription === "Low") {
      return 3;
    } else if (severityDescription === "Urgent") {
      return 4;
    }
  }

  private sendInternalTicketNotificationtoEmployees(): void {  
    if (!this.IsResponsiblePersonChanged) return;
    if (
      this.hasValue(this.PreviousSelectedResponsiblePerson) &&
      this.hasValue(this.PreviousSelectedResponsiblePerson.EmployeeId)
    ) {
      if (
        this.SelectedResponsiblePerson.EmployeeId ===
        this.PreviousSelectedResponsiblePerson.EmployeeId.toString()
      )
        return;
    }
    this._createNewTaskService
      .sendInternalTicketNotificationtoEmployees({
        EmployeeIds: [this.SelectedResponsiblePerson.EmployeeId],
        TaskNumber: this.taskNumber,
        IssueSeverityID: this.IssueSeverityID,
      })
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) {
          return;
        }
      });
  }  

  //#region " Responsible "
  responsibleEHRUserPersonIconClickEvent() {
    // console.log(this.EHRPracticeID <= 0);
    if (this.EHRPracticeId <= 0) {
      this.ShowErrorMessage("Please Select Practice Dertails.");
      return;
    }
    const dialogRef = this.dialogService.open({
      title: "Select Responsible Person",
      // Show component
      content: PracticeUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.content.instance.datatoPopup = {
      PracticeId: this.EHRPracticeId,
    };
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedResponsiblePersonInfo)) {
        } else {
          this.responsiblePersonview.EmployeeFullName = "";
        }
      } else {
        this.IsEHRResponsiblePersonChanged = true;
        this.responsiblePersonview = result;
        this.responsiblePersonview.EmployeeFullName = result["EmployeeName"];
        this.responsiblePersonview.EmployeeId = result["UserID"];

        // console.log(result, "Result");
        this.UpdateTaskUserRolesInformation(false, false);

        // if (this.hasValue(this.SelectedResponsiblePersonInfo)) {
        //   this.IsResponsiblePersonChanged = true;
        //   this.SelectedResponsiblePerson.EmployeeId = this.SelectedResponsiblePersonInfo.EmployeeId;
        //   this.SelectedResponsiblePerson.EmployeeFullName = this.SelectedResponsiblePersonInfo.EmployeeFullName;
        // }
        // this.SelectedResponsiblePersonInfo.EmployeeId = this.SelectedResponsiblePersonInfo.UserID;
        // this.SelectedResponsiblePersonInfo.EmployeeFullName = this.SelectedResponsiblePersonInfo.EmployeeName;
      }
    });
  }

  // RemoveResponsibleEHRUserPersonInfo() {
  //   this.TaskAddInfoModel.SelectedResponsiblePerson = undefined;
  //   this.SelectedResponsiblePersonInfo.EmployeeId = undefined;
  //   this.SelectedResponsiblePersonInfo.EmployeeFullName = "";
  // }
  //#endregion

  //#region  NEw Logic
  viewTaskInfoSelectQAClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select QA Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
      } else {
        // console.log(result, "QA")
        if (this.hasValue(result)) {
          this.QAPersonView.EmployeeId =
            result.EmployeeId;
          this.QAPersonView.EmployeeFullName =
            result.EmployeeFullName;
          this.UpdateTaskUserRolesInformation(false, false)
        }
      }
    });
  }

  viewTaskInfoSelectCSharpClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select C# Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
      } else {
        // console.log(result, "QA")
        if (this.hasValue(result)) {
          this.CsharppPersonView.EmployeeId =
            result.EmployeeId;
          this.CsharppPersonView.EmployeeFullName =
            result.EmployeeFullName;
          this.UpdateTaskUserRolesInformation(false, false)
        }
      }
    });
  }


  viewTaskInfoSelectDBClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select C# Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
      } else {
        // console.log(result, "QA")
        if (this.hasValue(result)) {
          this.DBPersonView.EmployeeId =
            result.EmployeeId;
          this.DBPersonView.EmployeeFullName =
            result.EmployeeFullName;
          this.UpdateTaskUserRolesInformation(false, false)
        }
      }
    });
  }

  viewTaskInfoSelectJSClickEvent() {
    const dialogRef = this.dialogService.open({
      title: "Select Angular JS Person",
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
      } else {
        // console.log(result, "QA")
        if (this.hasValue(result)) {
          this.JsPersonView.EmployeeId =
            result.EmployeeId;
          this.JsPersonView.EmployeeFullName =
            result.EmployeeFullName;
          this.UpdateTaskUserRolesInformation(false, false)
        }
      }
    });
  }
  //#endregion

  viewFullTaskResponsiblePersonPopupOpenClick(
    selectedEmployeeType: number,
    popupHeading: string
  ) {
    if (this.IsTaskFrom !== 3) {
      if (selectedEmployeeType === TaskEmployeeType.ResponsiblePerson) {
        if (
          this.hasValue(this.taskStatusDetails) &&
          this.hasValue(this.taskStatusDetails.statusId) &&
          this.taskStatusDetails.statusId == 2
        ) {
          this.ShowErrorMessage(
            "Responsible Person for On Going Task Cannot be Changed ."
          );
          return;
        }
      }
    }
    const dialogRef = this.dialogService.open({
      title: "Select " + popupHeading,
      // Show component
      content:
        selectedEmployeeType !== TaskEmployeeType.ResponsiblePerson
          ? EmployeesCustomizationComponent
          : ResponsiblepersonUsersListComponent,
      height: 698,
      width:
        selectedEmployeeType !== TaskEmployeeType.ResponsiblePerson ? 700 : 550,
      appendTo: this.ContainerRef,
    });
    if (selectedEmployeeType !== TaskEmployeeType.ResponsiblePerson) {
      dialogRef.content.instance.IsFromForwardToPopup = true;
      dialogRef.content.instance.StateMaintainUsersData =
        selectedEmployeeType === TaskEmployeeType.AdditionalResponsiblePerson
          ? this.additionalPersonsView
          : this.observersview;
    } else {
      this.PreviousSelectedResponsiblePerson = this.SelectedResponsiblePerson;

    }
    dialogRef.result.subscribe((result: Iemployee) => {
      if (!(result instanceof DialogCloseResult))
        if (this.hasValue(result)) {
          this.callFunctionsBasedonSelectedEmployeeType(
            result,
            selectedEmployeeType
          );
        }
    });
  }
  private callFunctionsBasedonSelectedEmployeeType(
    selectedData: any,
    employeeType: number
  ): void {
    if (employeeType === TaskEmployeeType.ResponsiblePerson) {
      if (
        +this.PreviousSelectedResponsiblePerson.EmployeeId !==
        +selectedData.EmployeeId
      ) {
        this.openPreviousResponsibleRoleSelectionPopup(selectedData);
      }
    } else {
      this.assignSelectedEmployeeRoles(selectedData, employeeType);
    }
  }
  openPreviousResponsibleRoleSelectionPopup(selectedData: any) {
    const dialogRef = this.dialogService.open({
      content: SelectRoleToAddPopupComponent,
      height: 275,
      width: 385,
      appendTo: this.ContainerRef,
    });
    dialogRef.result.subscribe((result: number | DialogCloseResult) => {
      if (!(result instanceof DialogCloseResult))
        if (this.hasValue(result)) {
          this.responsiblePersonview = selectedData;
          this.IsResponsiblePersonChanged = true;
          this.assignSelectedEmployeeRoles(
            this.PreviousSelectedResponsiblePerson,
            result
          );
        }
    });
  }
  private assignSelectedEmployeeRoles(selectedData: any, employeeType: number) {
    switch (employeeType) {
      case TaskEmployeeType.AdditionalResponsiblePerson:
        if (Array.isArray(selectedData)) {
          this.additionalPersonsView = selectedData;
        } else {
          this.additionalPersonsView.push(selectedData);
        }
        this.IsAdditionalResponsiblePersonInfoChanged = true;
        break;
      case TaskEmployeeType.Observers:
        if (Array.isArray(selectedData)) {
          this.observersview = selectedData;
        } else {
          this.observersview.push(selectedData);
        }
        this.IsObserversInfoChanged = true;
        break;
    }
    this.UpdateTaskUserRolesInformation(
      this.methodtoCheckTicketAssignedtoLoggedUserorNot(),
      false
    );
  }

  assignParticipantsNamestongModel(usersList, assignto, filteritem) {
    if (!this.hasValue(usersList)) return;
    this[assignto] = usersList
      .map(function (item) {
        return item[filteritem];
      })
      .join(", ");
  }
  ViewFullTaskInfoRemoveUserinfo(
    userChanged: string,
    userList: string,
    index: number
  ) {
    this[userList] = this[userList].filter(
      (item, itemindex) => itemindex != index
    );
    this[userChanged] = true;
    this.UpdateTaskUserRolesInformation(
      this.methodtoCheckTicketAssignedtoLoggedUserorNot(),
      false
    );
  }
  updateTaskUsersandClearChangeEventFlags() {
    this.EmployeesAndRolesModelList = [];
    this.IsAdditionalResponsiblePersonInfoChanged = false;
    this.IsObserversInfoChanged = false;
    this.IsResponsiblePersonChanged = false;
  }
  addECDDateEvent() {
    if (this._ecdDate.nativeElement.value === "__/__/____") {
      this._ecdDate.nativeElement.value = "";
    }
    if (this._ecdDate.nativeElement.value.length > 10) {
      this._ecdDate.nativeElement.value =
        this._ecdDate.nativeElement.value.slice(0, 10);
    }
    if (this.TaskDeadline == this._ecdDate.nativeElement.value) {
      return;
    }
    if (this.hasValue(this._ecdDate.nativeElement.value)) {
      if (this.validateMatDate("_ecdDate")) return;
      if (
        this.checkdateRangeValidation(
          formatDate(
            new Date(this.TaskCreatedOn),
            "MM/dd/yyy",
            "en-US",
            "+0530"
          ),
          this._ecdDate.nativeElement.value
        )
      ) {
        this.ShowErrorMessage(
          "ECD Should be Greater Than or Equal to Created Date"
        );
        this.FocusonMatField("_ecdDate");
        return;
      }
    }
    this.storePreviousDateandUpdateCurrentDate(
      "_ecdDate",
      "TaskDeadline",
      true
    );
  }
  private storePreviousDateandUpdateCurrentDate(
    currentName: string,
    previousName: string,
    isfromECD: boolean
  ) {
    this.updateRBDAndECDDate(isfromECD);
    this[previousName] = this[currentName].nativeElement.value;
  }
  addRBDDateEvent() {
    if (this._rbdDate.nativeElement.value === "__/__/____") {
      this._rbdDate.nativeElement.value = "";
    }
    if (this._rbdDate.nativeElement.value.length > 10) {
      this._rbdDate.nativeElement.value =
        this._rbdDate.nativeElement.value.slice(0, 10);
    }
    if (this.TaskRBD == this._rbdDate.nativeElement.value) {
      return;
    }
    if (this.hasValue(this._rbdDate.nativeElement.value)) {
      if (this.validateMatDate("_rbdDate")) return;
      if (
        this.checkdateRangeValidation(
          formatDate(
            new Date(this.TaskCreatedOn),
            "MM/dd/yyy",
            "en-US",
            "+0530"
          ),
          this._rbdDate.nativeElement.value
        )
      ) {
        this.ShowErrorMessage(
          "RBD Should be Greater Than or Equal to Created Date"
        );
        this.FocusonMatField("_rbdDate");
        return;
      }
      if (
        this.checkdateRangeValidation(
          formatDate(new Date(), "MM/dd/yyy", "en-US", "+0530"),
          this._rbdDate.nativeElement.value
        )
      ) {
        this.ShowErrorMessage(
          "RBD Should be Greater Than or Equal to Current Date"
        );
        this.FocusonMatField("_rbdDate");
        return;
      }
    }
    this.storePreviousDateandUpdateCurrentDate("_rbdDate", "TaskRBD", false);
  }
  private checkdateRangeValidation(FromDate, toDate) {
    const daysDifference = this.DateDiffInDays(toDate, FromDate);
    if (this.hasValue(daysDifference) && daysDifference < 0) {
      return true;
    }
    return false;
  }
  private updateRBDAndECDDate(isfromECD: boolean) {
    let postData: any = {
      TaskInfoID: this.headinInfo.TaskInfoID,
      RBD: this._rbdDate.nativeElement.value,
      ECD: this._ecdDate.nativeElement.value,
    };
    const previousDate = isfromECD ? this.TaskDeadline : this.TaskRBD;
    const CurrentDate = isfromECD
      ? this._ecdDate.nativeElement.value
      : this._rbdDate.nativeElement.value;
    const taskChangeEvent: TaskChangeEvent = {
      Description: this.populateTaskRBDECDChangeDescription(
        previousDate,
        CurrentDate
      ),
      EventId: isfromECD ? TaskEventTypeEnum.ECD : TaskEventTypeEnum.RBD,
      TaskInfoId: this.taskId,
    };
    postData.TaskChangeEvents = [taskChangeEvent];
    this._viewFullTaskInformationService
      .UpdateRBDAndECD(postData)
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) return;
        this.ShowSuccesseMessage("Updated Successfully");
      });
  }
  private InsertTaskCompletedStatusUpdatedToClientDate() {
    this._viewFullTaskInformationService
      .InsertTaskCompletedStatusUpdatedToClientDate({
        TaskInfoID: this.headinInfo.TaskInfoID,
        LatestUpdatedClientDate: this.formatValue(new Date()),
      })
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) return;
      });
  }
  private populateTaskRBDECDChangeDescription(
    previousDate: string,
    currentDate: string
  ): string {
    if (this.hasValue(previousDate) && this.hasValue(currentDate)) {
      return (
        `${Config.OASOrgModel.LoggedUserID} |${previousDate}` +
        ` --> ${Config.OASOrgModel.LoggedUserID} |${currentDate}`
      );
    } else if (this.hasValue(previousDate)) {
      return `${Config.OASOrgModel.LoggedUserID} | ${previousDate}-->`;
    } else {
      return ` -->${Config.OASOrgModel.LoggedUserID} | ${currentDate}`;
    }
  }
  private methodtoCheckTicketAssignedtoLoggedUserorNot(): boolean {
    let isassigned = false;
    this.rightSidePaneEmployeesViewList.forEach((item) => {
      if (item.Id == TaskEmployeeType.ResponsiblePerson) {
        if (!this.hasValue(this[item.List])) return;
        if (Config.OASOrgModel.LoggedUserID == this[item.List].EmployeeId) {
          isassigned = true;
        }
      }
      else {
        if (this[item.List].length == 2 || 3) return;
        this[item.List].forEach((element) => {
          if (Config.OASOrgModel.LoggedUserID == element.EmployeeId) {
            isassigned = true;
          }
        });
      }
    });

    if (!isassigned) {
      isassigned = this.IsTaskCreatedByMe;
    }
    return !isassigned;
  }

  OnClickNext() {
    // console.log("Is Calling Next");

    let indexID = this.selectedTicketsList.findIndex(
      (ele) => ele.TaskInfoID === this.SelectedTickedID
    );

    if (indexID + 1 < this.selectedTicketsList.length) {
      let nextId = this.selectedTicketsList[indexID + 1];
      // console.log(
      //   this.selectedTicketsList,
      //   this.SelectedTickedID,
      //   "sss",
      //   nextId.TaskInfoID
      // );

      this.ViewFullTaskInformation(nextId);
      this.headinInfo = nextId;
      this.SelectedTickedID = nextId.TaskInfoID;
      // console.log(indexID, "let index data base");
      this.populateHistoryTable(false);
      // this.isPreviousHide = true;
    } else {
      this.ShowErrorMessage("No Records FOund");
      // this.isPreviousHide = false;
    }
  }

  OnclickRemoveDetails(ClickFrom) {

    if (ClickFrom === 8) {
      this.QAPersonView.EmployeeId= null;
      this.QAPersonView.EmployeeFullName=null;
    } else if (ClickFrom === 9) {
      this.CsharppPersonView.EmployeeId = null;
      this.CsharppPersonView.EmployeeFullName=null;
    } else if (ClickFrom === 10) {
      this.DBPersonView.EmployeeId = null;
      this.DBPersonView.EmployeeFullName=null;
    }
    else if (ClickFrom === 11) {
      this.JsPersonView.EmployeeId = null;
      this.JsPersonView.EmployeeFullName=null;
    }

    this.UpdateTaskUserRolesInformation(false, false);

  }

  OnClickPrev() {
    // console.log("Is Calling Next");

    let indexID = this.selectedTicketsList.findIndex(
      (ele) => ele.TaskInfoID === this.SelectedTickedID
    );

    if (indexID > 0) {
      let PreviousId = this.selectedTicketsList[indexID - 1];
      // console.log(
      //   this.selectedTicketsList,
      //   this.SelectedTickedID,
      //   "sss",
      //   PreviousId.TaskInfoID
      // );
      this.ViewFullTaskInformation(PreviousId);
      this.headinInfo = PreviousId;
      this.SelectedTickedID = PreviousId.TaskInfoID;
      // console.log(indexID, "let index data base");
      this.populateHistoryTable(false);
    } else {
      this.ShowErrorMessage("No Records FOund");
    }
  }

  viewTaskSendReminderClick() {
    const dialog: DialogRef = this.dialogService.open({
      title: "Please Confirm",
      content: "Are You Sure to Send Reminder to Customer?",
      actions: [{ text: "No" }, { text: "Yes", primary: true }],
      width: 450,
      height: 250,
      minWidth: 250,
      appendTo: this.ContainerRef,
    });
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult))
        if (this.hasValue(result)) {
          if (result.text === "No") return;
          this.sendRemindertoCustomers();
        }
    });
  }
  private sendRemindertoCustomers() {
    this._viewFullTaskInformationService
      .sendRemindertoCustomers({
        TaskInfoID: this.headinInfo.TaskInfoID,
        TaskCreatedBy: this.TaskCreatedBy,
        TaskNumber: this.taskNumber,
      })
      .subscribe((serviceResponse) => {
        if (this.isError(serviceResponse)) return;
        this.ShowSuccesseMessage(
          serviceResponse && serviceResponse.ErrorMessage
            ? serviceResponse.ErrorMessage
            : "Reminder Send Successfully"
        );
      });
  }

  private isTextorImageExistinCommentsBody(): boolean {
    var span = document.createElement("span");
    span.innerHTML = this.viewModel.CommentBody;
    var enteredText = span.textContent || span.innerText;
    if (
      (enteredText && enteredText.trim()) ||
      this.viewModel.CommentBody.includes("<img")
    ) {
      return false;
    }
    return true;
  }


  getCommentedDocumentDetails(result: any) {
    let postData: any = {};
    this.TaskCommentID=result.TaskCommentID,
    postData.TaskCommentID = result.TaskCommentID,
      this.taskService.draftCommentAttachment(postData).subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.ReplaceSaveTOUpdate=true;
        this.getCommentAttachments(response);
        // console.log(response, "Details");
      });
  }

  async getCommentAttachments(response) {
    // console.log(response,"Comment")
    // assigning image attachments
    this.commentedimagesattachmentslist = this.attachmentsService.imageAttachments(
      response.DraftCommentDetails
    );

    this.CommentedpdfAttachments = this.attachmentsService.pdfAttachments(
      response.DraftCommentDetails
    );
    // console.log(this.imagesattachmentslist.length, "length");
    if (this.commentedimagesattachmentslist.length > 0) {
      let newAttchmentsList = [];
      for (let index = 0; index < this.commentedimagesattachmentslist.length; index++) {
        const attachmentType = this.commentedimagesattachmentslist[index].AttachmentType;

        const sourceUrl = this.commentedimagesattachmentslist[index].AttachmentURL;
        let AttachmentName = "";
        if (this.hasValue(this.commentedimagesattachmentslist[index].AttachmentName)) {
          AttachmentName = this.commentedimagesattachmentslist[index].AttachmentName;
        } else {
          AttachmentName = "";
        }

        const source = await this.attachmentsService.sourceFromUrl(sourceUrl);
        const element = {
          Imgaetag: "data:image/png;base64" + "," + source,
          attachmentType: attachmentType,
          AttachmentName: AttachmentName,
        };
        newAttchmentsList.push(element);
        this.CommentedAttachmentsList = newAttchmentsList;

        if(this.CommentedAttachmentsList && this.CommentedAttachmentsList.length>0){
          this.hideAttachments = false;
        }
        // console.log(this.CommentedAttachmentsList, "Attachement");

        
      }
    }

    if (this.CommentedpdfAttachments && this.CommentedpdfAttachments.length > 0) {
      this.hideAttachments = false;
    }
  }

  onImageCloseIconClickEvent(attachmentIndex: number) {
    // validate attachmentIndex
    if (attachmentIndex > this.CommentedAttachmentsList.length - 1 && typeof attachmentIndex !== 'number') {
      throw new Error('Invalid attachment index');
    }

    // delete the selected index
    this.CommentedAttachmentsList.splice(attachmentIndex, 1);
    // this.AttachmentsModelList.splice(attachmentIndex, 1);

  }
  onPdfAttachmentDeleteClickEvent(pdfAttachmentIndex) {
    this.CommentedpdfAttachments.splice(pdfAttachmentIndex, 1);

  }

  openResponsibleHX() {
  
    const dialogRef = this.dialogService.open({
      title: "Responible Persons HX",
      // Show component
      content: ResponsibleHistoryDetailsComponent,
    
      width: 990,
      appendTo: this.ContainerRef,
    });
console.log(this.TaskInfoID,"hid")
    dialogRef.content.instance.datatoPopup = {
      TaskInfoID: this.headinInfo.TaskInfoID,
    };
  
  }

}

export interface Employee {
  EmployeeId: string;
  EmployeeFullName: string;
}
