import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { commonhelper, innerHTMLExists } from '../common/commonhelper';
import { commonHelperService } from '../common/common.service';
import { FileUploadProgressService, CommentFileUploadProgressService } from '../services/file-upload-progress.service';
import { AttachmentService } from '../services/attachment.service';
import { TaskService } from '../services/task.service';
import { UploadState } from '../enums/upload-state.enum';
import { FileInfo } from '@progress/kendo-angular-upload';
import { Attachment } from '../interfaces/attachment';
import { Config } from '../config';
import { TaskEventTypeEnum } from '../enums/TaskHistoryChangeEvent.enum.';
import { TaskChangeEvent } from '../interfaces/TaskChangeEvent';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-uncustomizedtask-comments',
  templateUrl: './uncustomizedtask-comments.component.html',
  styleUrls: ['./uncustomizedtask-comments.component.css']
})
export class UncustomizedtaskCommentsComponent extends commonhelper implements OnInit {

  CommentDescription: string;
  constructor(private uploadStateService: FileUploadProgressService,
    private commentFileUploadStateService: CommentFileUploadProgressService,
    private attachmentsService: AttachmentService,
    private taskService: TaskService,
    private dialog: DialogRef) {
    super();
  }

  @ViewChild('UncustomizedTaskComment') UncustomizedTaskComment: ElementRef;

  // mainly used to post data to backend
  private commonhelperobj: commonHelperService;

  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint

  public uploadedFiles = {} as any;
  filesCount: number;
  public commentAttachmentContainer:any={};

  commentFilesCount = 0;
  myCommentsFiles: Array<FileInfo> = [];

  public viewModel = {
    CommentBody: '',
    IsPublicComment: true
  } as any;

  public TaskInfoID: any;
  public IsPublicComment: any = true;

  ngOnInit() {
    setTimeout(function () {
      var namedsad = document.getElementsByClassName("ql-editor ql-blank")[0];
      $(namedsad).trigger('focus');
    }, 20);
  }
  

  commentSelectEventHandler(event) {
    if(!this.hasValue(this.viewModel.CommentBody)){
      this.ShowErrorMessage('Please Enter Comments Before Uploading the file');
    }
      else {
    this.commentFileUploadStateService.setUploadState(UploadState.uploadInProgress);
    this.commentFilesCount += (<any>event).files.length;
    }
  }
  
  commentRemoveEventHandler(event) {
    this.commentFilesCount--;
    if (this.commentFilesCount === 0) {
      this.commentFileUploadStateService.setUploadState(UploadState.removedAllFiles);
    }
  }

  commentCompleteEventHandler(event) {
    this.commentFileUploadStateService.setUploadState(UploadState.uploadComplete);
  }

  async onCommentSendButtonClick() {

    if (!this.commentFileUploadStateService.isValidState()) {
      this.ShowErrorMessage('Please Wait till the files are uploaded');
      return;
    }

    const postData = {
      IsPublicComment: this.IsPublicComment,
      TaskInfoID: this.TaskInfoID,
      AttachmentsModelList: [],
    } as any;
    //debugger;
    if (this.IsPublicComment == true) {
      this.CommentDescription = "Send Response to Customer";
    }
    else {
      this.CommentDescription = "Internal Comments Added";
    }

if( !this.hasValue(this.viewModel.CommentBody))
{
  this.ShowErrorMessage('Please Enter Comment Details');
  return;
}
else{
 this.commentAttachmentContainer = await this.attachmentsService.processUploadAttachments(this.myCommentsFiles);
}

    //console.log(commentAttachmentContainer);

    if (
      !this.hasValue(this.viewModel.CommentBody)
      || !innerHTMLExists(this.viewModel.CommentBody, 'p')
    ) {
      this.ShowErrorMessage('Please Enter Comment Details');
      const elementFound = this.UncustomizedTaskComment.nativeElement.querySelector('[name="' + 'CommentsDesc' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[1].children[0].focus();
      }
      return;
    }

    if (this.hasValue(this.viewModel.CommentBody)) {

      const attachment = {
        AttachmentBase64Format: window.btoa(unescape(encodeURIComponent(this.viewModel.CommentBody))),
        AttachmentType: 1,
      };

      this.commentAttachmentContainer.push(attachment);

      if (this.attachmentsService.duplicatesExists(this.commentAttachmentContainer as Array<Attachment>)) {
        this.ShowErrorMessage('Duplicates Exists in Uploaded Attachments Please Try Again.');
        this.myCommentsFiles = [];
        return;
      }
      if (!this.attachmentsService.uploadedAttachmentsCompatable(this.commentAttachmentContainer)) {
        this.ShowErrorMessage('Uploaded attachments contain files other than JPG,JPEG,PNG,DocX,Doc,XLSX,XLs and PDF files.');
        this.myCommentsFiles = [];
        return;
      }

      postData.AttachmentsModelList = this.commentAttachmentContainer;

      const taskChangeEvent: TaskChangeEvent = {
        Description: this.CommentDescription,
        EventId: TaskEventTypeEnum.Comments,
        TaskInfoId: this.TaskInfoID
      };



      postData.TaskChangeEvents = [taskChangeEvent];
      //debugger;
      this.myCommentsFiles = [];
      this.viewModel.CommentBody = '';
      this.viewModel.IsPublicComment = true;
      this.dialog.close(postData);

      /*this.commonhelperobj.PostData(Config.OASAppnURL + 'InsertCommentsInformation', postData).subscribe(serviceResponse => {
        if (this.isError(serviceResponse)) { return; }
        this.ShowSuccesseMessage('Task Comments Added SuccessFully.');
        this.myCommentsFiles = [];
        this.viewModel.commentBody = '';
        this.viewModel.attachments = [];

      });*/
    }

  }

  cancelUncustomizedCommentClickEvent() {
    this.dialog.close();
  }

}
