import { Component, OnInit, ViewChild } from '@angular/core';
import { commonHelperService } from '../common/common.service';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { TaskService } from '../services/task.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { commonhelper } from '../common/commonhelper';
import { LeftNavService } from '../common/Leftnav.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-practiceusersinformationcomponent',
  templateUrl: './practiceusersinformationcomponent.component.html',
  styleUrls: ['./practiceusersinformationcomponent.component.css']
})
export class PracticeusersinformationcomponentComponent extends commonhelper implements OnInit {

  public singleSelectioncolumnDefs;
  private commonhelperobj: commonHelperService;
  @ViewChild('PracticeUsersInformationGrid') PracticeUsersInformationGrid: AgGridNg2;
  @ViewChild('singleSelectionusersgrid') singleSelectionusersgrid: AgGridNg2;

  public practiceListGridOptions: GridOptions;
  public gridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public selectedRowsString: {};
  private fieldArray: Array<any> = [];
  public userIDs: any = [];
  public rowSelection;
  rowData: any = [];
  public usersModel: any = {};
  private editmodedata: any = {};
  public UsersList: Array<string> = [];
  public overlayNoRowsTemplate;
  public SearchUserName: any;
  public defaultColDef;
  public singleselectioncolumnDefs;
  public SelectedUserIDs: any = '';

  public IsFromSingleSelectionToPopup: any = false;

  constructor(private taskService: TaskService, private dialog: DialogRef, private LeftNav: LeftNavService, private http: HttpClient) {
    super();


    // tslint:disable-next-line:max-line-length
    this.overlayNoRowsTemplate = '<span style=\'Font-size:16px;color:#00529B;background-color:#BDE5F8;margin: 50px 0px;padding: 12px;border-radius: 4px;\'>Users does not Exist in this Practice.</span>';

    this.singleselectioncolumnDefs = [
      // { headerName: 'User ID', field: 'PracticeId' },
      {
        headerName: 'User Name',
        field: 'EmployeeName',
        width: 280,
        cellClass: 'no-border cell-wrap-text', autoHeight: true
      },
    ];

    this.rowSelection = 'multiple';
    this.defaultColDef = { width: 100 };


  }

  ngOnInit() {
    this.LeftNav.currentSelected.subscribe(value => {
      this.editmodedata = value;
      this.practiceUsersList();
    });
    setTimeout(function () {
      document.getElementById("txtPracticeUsersSearchUserName").focus();
    }, 50);
  }

  onSelectionChanged(event) {
    const selectedRows = this.PracticeUsersInformationGrid.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      //debugger;
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;
      //this.SelectedResponsiblePersonName = selectedRow.EmployeeFullName;

    });
    // console.log(this.selectedRowsString);
  }



  onSingleSelectionChanged(event) {
    const selectedRows = this.singleSelectionusersgrid.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      //debugger;
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;
      // this.SelectedModuleInfo = selectedRow.Description;

    });
    // console.log(this.selectedRowsString);
  }



  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.http
      .get(
        'https://raw.githubusercontent.com/ag-grid/ag-grid/master/packages/ag-grid-docs/src/olympicWinnersSmall.json'
      )
      .subscribe(data => {
        this.rowData = data;
      });
  }

  practiceUsersList() {
    if (this.hasValue(this.editmodedata.UsersArray) && this.editmodedata.UsersArray.length > 0) {
      this.SelectedUserIDs = '';
      this.SelectedUserIDs = $.map(this.editmodedata.UsersArray, function (item) {
        return item.UserID;
      }).join(', ');
    }

    const postData = {
      PracticeId: this.editmodedata.PracticeId,
      IssueSupportGroupID: this.editmodedata.IssueSupportGroupID,
      EmployeeName: this.SearchUserName,
      SelectedUserIDs: this.SelectedUserIDs
    };

    this.taskService.PracticeUsersList(postData).subscribe(response => {
      if (this.isError(response)) {
        return;
      }
      if (this.hasValue(response) && response.length > 0) {
        this.rowData = response;
      } else {
        this.rowData = [];
      }

    });
  }
  OKClickEvent() {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage('Please Select User(s).');
      return false;
    }
    if (this.hasValue(this.selectedRowsString)) {
      setTimeout(() => {
        this.dialog.close(this.selectedRowsString);
      });
    }
  }


  CancelClickEvent() {
    this.dialog.close();
  }

}
