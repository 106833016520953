import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { commonhelper } from "../common/commonhelper";
import { IntlService, formatDate } from "@progress/kendo-angular-intl";
import { AgGridNg2 } from "ag-grid-angular";
import { ColDef, GridSizeChangedEvent } from "ag-grid-community";
import { group } from "console";
import { commonHelperService } from "../common/common.service";
import { TaskService } from "../services/task.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ExcelService } from "../common/excel.service";
import * as moment from "moment";
import { AllBucketsConsolidateReportService } from "./all-buckets-consolidate-report.service";
import {
  DialogCloseResult,
  DialogService,
} from "@progress/kendo-angular-dialog";
import { ViewConsolidatedTaskdetailsComponent } from "../view-consolidated-taskdetails/view-consolidated-taskdetails.component";
@Component({
  selector: "app-all-buckets-consolidate-report",
  templateUrl: "./all-buckets-consolidate-report.component.html",
  styleUrls: ["./all-buckets-consolidate-report.component.css"],
})
export class AllBucketsConsolidateReportComponent extends commonhelper
  implements OnInit {
  @ViewChild("AllBucketsConsolidatedTickets")
  AllBucketsConsolidatedTickets: ElementRef;

  @ViewChild("ReportTicketsGrid") ReportTicketsGrid: AgGridNg2;
  @ViewChild("ReportTicketsGrid", { read: ViewContainerRef })
  ContainerRef: ViewContainerRef;
  CreatedFromDate: any = new Date();

  public CreatedToDate: any = new Date();
  public CreatedToTime: any = new Date();
  public CreatedFromTime: any = new Date("Tue Apr 11 2000 00:00:00 GMT+0530");
  public format = "MM/dd/yyyy HH:mm";
  columnDefs = [
    {
      headerName: "Created Date",
      field: "CreatedDate",
      width: 120,
    },
    {
      headerName: "Login",
      marryChildren: true,
      children: [
        { headerName: "Bugs", TBID: 1, TBCID: 1, field: "LBug" },
        { headerName: "Concerns", TBID: 1, TBCID: 2, field: "LConcerns" },
        { headerName: "New Login", TBID: 1, TBCID: 3, field: "LNewLogin" },
        {
          headerName: "Inactive/Suspended",
          TBID: 1,
          TBCID: 4,
          field: "LUsersInactivateorSuspended",
        },
      ],
    },
    {
      headerName: "Easy Form",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 2,
          TBCID: 1,
          field: "EBug",
        },
        {
          headerName: "Concerns",
          TBID: 2,
          TBCID: 2,
          field: "EConcerns",
        },
        // {
        //   headerName: "Easy Forms",
        //   TBID: 2,
        //   TBCID: 5,
        //   field: "EEasyFormsRequests",
        // },
        {
          headerName: "New Easy Form Design",
          TBID: 2,
          TBCID: 6,
          field: "ENewEasyFormDesign",
        },
        {
          headerName: "Existing Form Changes",
          TBID: 2,
          TBCID: 7,
          field: "EExistingFormChanges",
        },

        {
          headerName: "HCI Mapping",
          TBID: 2,
          TBCID: 8,
          field: "EHCIMapping",
        },
        {
          headerName: "Form Forwarding",
          TBID: 2,
          TBCID: 9,
          field: "EFormForwarding",
        },
        {
          headerName: "Easy Form Permissions",
          TBID: 2,
          TBCID: 10,
          field: "EEasyFormPermissions",
        },
      ],
    },
    {
      headerName: "ERX Request",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 3,
          TBCID: 1,
          field: "ErxBug",
        },
        {
          headerName: "Concerns",
          TBID: 3,
          TBCID: 2,
          field: "ErxConcerns",
        },
        {
          headerName: "EPCS Enabling",
          TBID: 3,
          TBCID: 11,
          field: "ErxErxorEPCSEnabling",
        },
      ],
    },
    {
      headerName: "Order Rx/Medications",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 14,
          TBCID: 1,
          field: "ORXBug",
        },
        {
          headerName: "Concerns",
          TBID: 14,
          TBCID: 2,
          field: "ORXConcerns",
        },
      ],
    },
    {
      headerName: "Labs/X-Rays/Vaccines",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 4,
          TBCID: 1,
          field: "LabsBug",
        },
        {
          headerName: "Concerns",
          TBID: 4,
          TBCID: 2,
          field: "LabsConcerns",
        },
        {
          headerName: "Interface",
          TBID: 4,
          TBCID: 12,
          field: "LabsInterface",
        },
      ],
    },
    {
      headerName: "Reports",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 5,
          TBCID: 1,
          field: "ReportsBug",
        },
        {
          headerName: "Concerns",
          TBID: 5,
          TBCID: 1,
          field: "ReportsConcerns",
        },
        {
          headerName: "New Report",
          TBID: 5,
          TBCID: 13,
          field: "ReportNewReport",
        },

        {
          width: 150,
          headerName: "Existing Report Changes",
          TBID: 5,
          TBCID: 14,
          field: "ReportExistingReportChanges",
        },
      ],
    },
    {
      headerName: "Remainders",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 6,
          TBCID: 1,
          field: "RemaindersBug",
        },
        {
          headerName: "Concerns",
          TBID: 6,
          TBCID: 1,
          field: "RemaindersConcerns",
        },
        {
          headerName: "New Remainders",
          TBID: 6,
          TBCID: 15,
          field: "RemainderNewReport",
        },
        {
          width: 150,
          headerName: "Existing Remainders Changes",
          TBID: 6,
          TBCID: 16,
          field: "RemaindersExistingRemaindersChanges",
        },
      ],
    },
    {
      headerName: "Fax/Document Auto Uploader",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 7,
          TBCID: 1,
          field: "FaxBug",
        },
        {
          headerName: "Concerns",
          TBID: 7,
          TBCID: 1,
          field: "FaxConcerns",
        },
        {
          headerName: "New Fax Integration",
          TBID: 7,
          TBCID: 7,
          field: "FaxNewFaxIntegartion",
        },
      ],
    },
    {
      headerName: "Document Management",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 8,
          TBCID: 1,
          field: "DocBug",
        },
        {
          headerName: "Concerns",
          TBID: 8,
          TBCID: 2,
          field: "DocConcerns",
        },
      ],
    },
    {
      headerName: "Appointment Scheduler (1 to 1 & Group)",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 9,
          TBCID: 1,
          field: "ApptBug",
        },
        {
          headerName: "Concerns",
          TBID: 9,
          TBCID: 2,
          field: "ApptConcerns",
        },
      ],
    },
    {
      headerName: "CCDA/MIPS/Quality Measures",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 10,
          TBCID: 1,
          field: "CMQBug",
        },
        {
          headerName: "Concerns",
          TBID: 10,
          TBCID: 2,
          field: "CMQConcerns",
        },
      ],
    },
    {
      headerName: "Billing",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 11,
          TBCID: 1,
          field: "BillingBug",
        },
        {
          headerName: "Concerns",
          TBID: 11,
          TBCID: 2,
          field: "BillingConcerns",
        },
      ],
    },
    {
      headerName: "New Enhacments",
      marryChildren: true,

      children: [
        {
          headerName: "Enhacments",
          TBID: 12,
          TBCID: 18,
          field: "NEBug",
        },
      ],
    },
    // {
    //   headerName: "Others",
    //   marryChildren: true,

    //   children: [
    //     {
    //       headerName: "Bugs",
    //       TBID: 13,
    //       TBCID: 1,
    //       field: "OBug",
    //     },
    //     {
    //       headerName: "Concerns",
    //       TBID: 13,
    //       TBCID: 2,
    //       field: "OConcerns",
    //     },
    //   ],
    // },
    {
      headerName: "Admission Details",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 15,
          TBCID: 1,
          field: "AdminBug",
        },
        {
          headerName: "Concerns",
          TBID: 15,
          TBCID: 2,
          field: "AdminConcerns",
        },
      ],
    },
    {
      headerName: "Bed Census",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 16,
          TBCID: 1,
          field: "BedBug",
        },
        {
          headerName: "Concerns",
          TBID: 16,
          TBCID: 2,
          field: "BedConcerns",
        },
      ],
    },
    {
      headerName: "Demographics",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 17,
          TBCID: 1,
          field: "DemoBug",
        },
        {
          headerName: "Concerns",
          TBID: 17,
          TBCID: 2,
          field: "DemoConcerns",
        },
      ],
    },

    {
      headerName: "New Client Registation",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 18,
          TBCID: 1,
          field: "NewClientBug",
        },
        {
          headerName: "Concerns",
          TBID: 18,
          TBCID: 2,
          field: "NewClientConcerns",
        },
      ],
    },
    {
      headerName: "Others",
      marryChildren: true,

      children: [
        {
          headerName: "Bugs",
          TBID: 13,
          TBCID: 1,
          field: "OBug",
        },
        {
          headerName: "Concerns",
          TBID: 13,
          TBCID: 2,
          field: "OConcerns",
        },
      ],
    },
  ];

  ReportTicketsRowData = [];

  defaultColDef = {
    width: 100,
  };
  // onReportTicketsGridSizeChanged(params: GridSizeChangedEvent) {
  //   params.api.sizeColumnsToFit();
  // }

  TaskBucketID: number = 0;
  TaskBUcketCategory: number = 0;
  taskCreatedDate: string;
  SelectedRowDetails: any;
  constructor(
    private intl: IntlService,
    private taskService: TaskService,
    private commonhelperobj: commonHelperService,
    private _router: Router,
    private route: ActivatedRoute,
    private _allBucketsConsolidateReportService: AllBucketsConsolidateReportService,
    private readonly _excelService: ExcelService,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const data = this.getCommonData(params.info);
      // console.log(data, "from Back");
      if (
        this.hasValue(data) &&
        this.hasValue(data.previouslySelectedSearchFilters)
      ) {
        this.CreatedFromDate =
          data.previouslySelectedSearchFilters.CreatedFromDate;
        this.CreatedFromTime =
          data.previouslySelectedSearchFilters.CreatedFromTime;
        this.CreatedToDate = data.previouslySelectedSearchFilters.CreatedToDate;
        this.CreatedToTime = data.previouslySelectedSearchFilters.CreatedToTime;
        this.SelectedRowDetails = data.SelctedGridData;
        this.statemaintainPreviousSelectedRecord();
      } else {
        // this.CustomerTicketsButtonClick(this.items[0], true);
      }
    });

    this.OnClickGetConsolidatedData();
  }
  tasksCreatedFromDateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedFromDate,
        "CreatedFromDate",
        true
      )
    )
      return false;
  }

  tasksCreatedTODateChange() {
    // console.log("is calling");
    if (
      !this.createdFromDateToDateValidations(
        this.CreatedToDate,
        "CreatedToDate",
        false
      )
    )
      return false;
  }

  createdFromDateToDateValidations(
    modifiedfromandtoDate: Date,
    name: string,
    isfrom: boolean
  ) {
    var today = new Date();
    var toDate = new Date(modifiedfromandtoDate);
    var showfocuselement = false;
    const daysDifference = this.DateDiffInDays(
      isfrom ? this.CreatedToDate : today,
      isfrom ? this.CreatedFromDate : this.CreatedToDate
    );
    const yearsDifference = toDate.getFullYear() - today.getFullYear();
    if (this.hasValue(yearsDifference) && Math.abs(yearsDifference) > 150) {
      this.ShowErrorMessage("Invalid Day, Month or Year Range Detected.");
      showfocuselement = true;
    }
    if (this.hasValue(daysDifference) && daysDifference < 0) {
      if (isfrom) {
        this.ShowErrorMessage(
          "Created From Date Should be Less Than or Equal to Created To Date"
        );
        this.CreatedFromDate = "";
      } else {
        this.CreatedToDate = "";
        this.ShowErrorMessage("Future Dates Not allowed");
      }
      showfocuselement = true;
    }
    if (showfocuselement) {
      const elementFound = this.AllBucketsConsolidatedTickets.nativeElement.querySelector(
        '[name="' + name + '"]'
      );
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return false;
    }
    return true;
  }

  private applyclassesforTasksCreatedDatesSelected(data: any) {
    if (this.hasValue(this.CreatedFromDate)) {
      if (!this.hasValue(this.CreatedToDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created To Date.");
        return false;
      }
    }
    if (this.hasValue(this.CreatedToDate)) {
      if (!this.hasValue(this.CreatedFromDate)) {
        // console.log("data");
        this.ShowErrorMessage("Enter Created From Date.");
        return false;
      }
    }
    // console.log(this.CreatedFromTime, this.CreatedToTime, "TImes");
    data.createdFromDate =
      this.formatDateValue(this.CreatedFromDate) +
      " " +
      formatDate(this.CreatedFromTime, "HH:mm:ss", "en-US", "+0530");
    data.createdToDate =
      this.formatDateValue(this.CreatedToDate) +
      " " +
      formatDate(this.CreatedToTime, "HH:mm:ss", "en-US", "+0530");

    if (this.tasksCreatedFromDateChange() == false) return false;
    if (this.tasksCreatedTODateChange() == false) return false;
  }

  removeSelectedDatesClickEvent(number) {
    if (number === 1) {
      this.CreatedFromDate = "";
    } else if (number === 2) {
      this.CreatedToDate = "";
    }
  }

  private formatDateValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "MM/dd/yyyy")}` : "";
  }
  private formatDateNUmberValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, "yyyyMMdd")}` : "";
  }
  OnClickGetConsolidatedData() {
    const data: any = {};

    if (!this.hasValue(this.CreatedFromDate)) {
      this.ShowErrorMessage("Enter Created From Date.");
      return false;
    }
    if (!this.hasValue(this.CreatedToDate)) {
      this.ShowErrorMessage("Enter Created To Date.");
      return false;
    }
    if (this.applyclassesforTasksCreatedDatesSelected(data) == false) return;

    this.taskService.ConsolidateReportList(data).subscribe((response) => {
      if (this.commonhelperobj.isError(response)) {
        return;
      }
      if (response.ReportsRawList.length >= 0) {
        this.ReportTicketsRowData = response.ReportsRawList;
      }
      // console.log(response, "This is final call");
      //this.statemaintainPreviousSelectedRecord();
    });
  }
  AgGridApi: any;
  GridReday(params: any) {
    this.AgGridApi = params.api;
  }
  onCellClicked(event) {
    // console.log(event, "hey Iam CLicked");
    if (event.value > 0) {
      this.TaskBucketID = event.colDef.TBID;
      this.TaskBUcketCategory = event.colDef.TBCID;
      this.taskCreatedDate = event.data.CreatedDate;
      this.ViewMoreDetails();
    } else {
      this.ShowErrorMessage("Please select the cell with non zero. ");
      // alert("no data");
    }
  }

  ViewMoreDetails() {
    if (this.TaskBucketID > 0 && this.TaskBUcketCategory > 0) {
      const dialogRef = this.dialogService.open({
        title: "Task Number Details",
        // Show component
        content: ViewConsolidatedTaskdetailsComponent,
        height: 698,
        width: 550,
        appendTo: this.ContainerRef,
      });

      let DateinNumner = +moment(this.taskCreatedDate).format("yyyyMMDD");
      let FromTime;
      let ToTime;
      // console.log(
      //   DateinNumner,
      //   " >",
      //   +this.formatDateNUmberValue(this.CreatedFromDate),
      //   "&&",
      //   DateinNumner,
      //   " <",
      //   +this.formatDateNUmberValue(this.CreatedToDate)
      // );
      if (
        DateinNumner >= +this.formatDateNUmberValue(this.CreatedFromDate) &&
        DateinNumner < +this.formatDateNUmberValue(this.CreatedToDate)
      ) {
        FromTime = "00:00:00";
        // console.log(FromTime);
      } else {
        FromTime = formatDate(
          this.CreatedFromTime,
          "HH:mm:ss",
          "en-US",
          "+0530"
        );
        // console.log(FromTime, "here");
      }

      if (
        DateinNumner > +this.formatDateNUmberValue(this.CreatedFromDate) &&
        DateinNumner < +this.formatDateNUmberValue(this.CreatedToDate)
      ) {
        ToTime = "23:59:59";
        // console.log(ToTime, "New cha");
      } else {
        ToTime = formatDate(this.CreatedToDate, "HH:mm:ss", "en-US", "+0530");
      }
      dialogRef.content.instance.datatoPopup = {
        TaskBucketID: this.TaskBucketID,
        TaskBUcketCategory: this.TaskBUcketCategory,
        taskCreatedDate: this.taskCreatedDate,
        taskCreatedFromTime: this.taskCreatedDate + " " + FromTime,
        taskCreatedToTime: this.taskCreatedDate + " " + ToTime,
      };
      dialogRef.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
        } else {
          // this.assignedByInfo = result;
        }
      });
      // const PostDataService: any = {
      //   TaskBucketID: this.TaskBucketID,
      //   TaskBUcketCategory: this.TaskBUcketCategory,
      //   taskCreatedDate: this.taskCreatedDate,
      // };
      // PostDataService.StatemaintainPreviouslySearchFilters = {
      //   CreatedFromDate: this.CreatedFromDate,
      //   CreatedToDate: this.CreatedToDate,
      //   CreatedFromTime: this.CreatedFromTime,
      //   CreatedToTime: this.CreatedToTime,
      // };
      // const outletObj = {};
      // outletObj["main"] = [
      //   "view-consolidated-taskdetails.component",
      //   this.setCommonData(PostDataService),
      // ];
      // this._router.navigate(["/home", { outlets: outletObj }]);
    } else {
      this.ShowErrorMessage("Please select the cell with non zero. ");
    }
  }

  statemaintainPreviousSelectedRecord() {
    let CreatedDate = this.SelectedRowDetails.taskCreatedDate;
    // console.log(CreatedDate);
    let storeGridInfo = this.ReportTicketsGrid.api;
    setTimeout(() => {
      this.ReportTicketsGrid.api.forEachLeafNode(function (node) {
        if (node.data.CreatedDate === CreatedDate) {
          node.setSelected(true);
          //Moving Scroll bar to Selected Node
          storeGridInfo.ensureIndexVisible(node.rowIndex + 1);
        }
      });
    }, 500);
  }

  OnclickExportExcel() {
    const data: any = {};

    if (!this.hasValue(this.CreatedFromDate)) {
      this.ShowErrorMessage("Enter Created From Date.");
      return false;
    }
    if (!this.hasValue(this.CreatedToDate)) {
      this.ShowErrorMessage("Enter Created To Date.");
      return false;
    }
    if (this.applyclassesforTasksCreatedDatesSelected(data) == false) return;

    this.taskService.ConsolidateReportList(data).subscribe((response) => {
      if (this.commonhelperobj.isError(response)) {
        return;
      }
      if (response.ReportsRawList.length >= 0) {
        this.ReportTicketsRowData = response.ReportsRawList;
      }
      this._excelService.exportAsExcelForConsolidateFile(
        response.ReportsRawList,
        "ConsilidatedReportFromAllBuckets",
        this._allBucketsConsolidateReportService.ChildRow
      );
      //this.statemaintainPreviousSelectedRecord();
    });
  }
}
