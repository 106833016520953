import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { AgGridNg2 } from "ag-grid-angular";
import { GridOptions } from "ag-grid-community";
import { commonHelperService } from "../common/common.service";
import { commonhelper } from "../common/commonhelper";
import { DeleteSelectedPersonRendererComponent } from "../delete-selected-person-renderer/delete-selected-person-renderer.component";
import { IpopuModel } from "../practice-users-customization/practice-users-customization.interface";
import { TaskService } from "../services/task.service";

@Component({
  selector: "app-additional-and-observers-for-practice-popup",
  templateUrl: "./additional-and-observers-for-practice-popup.component.html",
  styleUrls: ["./additional-and-observers-for-practice-popup.component.css"],
})
export class AdditionalAndObserversForPracticePopupComponent
  extends commonhelper
  implements OnInit {
  @ViewChild("additionalParticipantAndObserversgrid")
  additionalParticipantAndObserversgrid: AgGridNg2;
  @ViewChild("additionalParticipantAndObserversAdditionalResponsiblePersongrid")
  additionalParticipantAndObserversAdditionalResponsiblePersongrid: AgGridNg2;
  @ViewChild("additionalParticipantAndObserversAssistantsgrid")
  additionalParticipantAndObserversAssistantsgrid: AgGridNg2;
  @ViewChild("additionalParticipantAndObserversObserversgrid")
  additionalParticipantAndObserversObserversgrid: AgGridNg2;
  additionalParticipantAndObserversGridOptions: GridOptions;
  gridApi;
  gridColumnApi;
  datatoPopup: IpopuModel;
  selectedRowsString: {};
  SearchEmployeeName: any = "";
  SelectedResponsiblePersonName: any = "";
  IsFromForwardToPopup: any = false;
  StateMaintainUsersData: any;
  rowSelection;
  columnDefs;
  singleselectioncolumnDefs;
  ResponsiblePersoncolumnDefs;
  AdditionalResponsiblePersoncolumnDefs;
  ObserverscolumnDefs;
  defaultColDef;
  rowData: any = [];
  AdditionalResponsiblePersonrowData: any = [];
  ObserversrowData: any = [];
  AssistantsrowData: any = [];
  gridOptions: GridOptions;
  getContextMenuItems;
  StateMaintainAddlResponsiblePersonsData: any;
  StateMaintainObserverPersonsData: any;
  StateMaintainAssistantPersonsData: any;
  EmployeeDesignationType: number = 0;
  frameworkComponents;
  private Observerscontext;
  context;
  SelectedEmployeeID: any;
  AdditionalResponsiblePersonsgridApi;
  ObserversgridApi;
  AssistantsgridApi;
  EmployeeAddlResponsiblePersonsList: any = [];
  AssistantscolumnDefs;
  IsMasterList: boolean;
  enableSingleOrMultiple: string;
  constructor(
    private taskService: TaskService,
    private dialog: DialogRef,
    private httpClient: HttpClient,
    private commonhelperobj: commonHelperService
  ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this,
      },
      defaultColDef: {
        // resizable: true
      },
    };

    //Grid block start
    this.singleselectioncolumnDefs = [
      {
        headerName: "Employee ID",
        field: "EmployeeId",
        width: 0,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
        hide: true,
      },
      {
        headerName: "Person",
        field: "EmployeeName",
        width: "570",
        cellClass: "no-border cell-wrap-text",
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        autoHeight: true,
      },
    ];
    //Grid block end

    //Responsible Person Grid block start
    this.AdditionalResponsiblePersoncolumnDefs = [
      {
        headerName: "Additional Responsible Person",
        field: "EmployeeName",
        width: 400,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
        //height: "200px",
      },
      {
        headerName: "X",
        field: "icon",
        width: 40,
        cellRenderer: "DeleteSelectedPersonRendererComponent",
        colId: "params",
        // cellRenderer: params => {
        //   var tempDiv = document.createElement("div");
        //   tempDiv.innerHTML =
        //     '<span><i (click)="DeleteSelectedAddlResponsiblePerson();" style="color:red;font-size:20px;cursor:pointer" class="fa fa-trash"></i></span>';
        //   return tempDiv
        // }
      },
    ];

    //Responsible Person  Grid block end

    //Additional Responsible Person Grid block start
    this.ObserverscolumnDefs = [
      {
        headerName: "Observers",
        field: "EmployeeName",
        width: 400,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
        //height: "200px",
      },
      {
        headerName: "X",
        field: "icon",
        width: 40,
        cellRenderer: "DeleteSelectedPersonRendererComponent",
        colId: "params",
      },
    ];
    //Additional Responsible Person  Grid block end

    //Observers Grid block start
    this.AssistantscolumnDefs = [
      {
        headerName: "Forward To",
        field: "EmployeeName",
        width: 400,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
        //height: "200px",
      },
      {
        headerName: "X",
        field: "icon",
        width: 40,
        cellRenderer: "DeleteSelectedPersonRendererComponent",
        colId: "params",
      },
    ];

    //Observers  Grid block end
    this.context = { componentParent: this };
    this.Observerscontext = { componentParent: this };
    this.frameworkComponents = {
      DeleteSelectedPersonRendererComponent: DeleteSelectedPersonRendererComponent,
    };

    this.defaultColDef = {
      resizable: true,
      width: 100,
    };

    this.rowSelection = "multiple";
  }

  ngOnInit() {
    //state maintain selected Additional responsible persons information
    if (this.hasValue(this.StateMaintainAddlResponsiblePersonsData)) {
      this.AdditionalResponsiblePersonrowData = this.StateMaintainAddlResponsiblePersonsData;
    }

    //state maintain selected observers information
    if (this.hasValue(this.StateMaintainObserverPersonsData)) {
      this.ObserversrowData = this.StateMaintainObserverPersonsData;
    }

    ////state maintain selected Assistant persons information
    if (this.hasValue(this.StateMaintainAssistantPersonsData)) {
      this.AssistantsrowData = this.StateMaintainAssistantPersonsData;
    }

    this.practiceUsersList();
    //debugger;
    setTimeout(function () {
      document
        .getElementById("divAdditionalParticipantAndObserversSearchUser")
        .focus();
      $("#anchAdditionalParticipantAndObserversDBStaff").addClass(
        ".AdditionalParticipantsAndObserversRedColorClass"
      );
    }, 10);
    $("#anchAdditionalParticipantAndObserversCommonList").addClass(
      "AdditionalParticipantsAndObserversRedColorClass"
    );
    $("#anchAdditionalParticipantAndObserversMasterList").removeClass(
      "AdditionalParticipantsAndObserversRedColorClass"
    );
  }

  DeleteSelectedAddlResponsiblePerson(selectedvalue) {
    // const selectedRows = this.additionalParticipantAndObserversAdditionalResponsiblePersongrid.api.getSelectedRows();
    //alert(selectedvalue);
    var selectedDataforAddlresponsiblePersons = this.AdditionalResponsiblePersonsgridApi.getSelectedRows();
    if (this.hasValue(selectedDataforAddlresponsiblePersons)) {
      this.AdditionalResponsiblePersonsgridApi.updateRowData({
        remove: selectedDataforAddlresponsiblePersons,
      });
    }

    var selectedDataforObserverPersons = this.ObserversgridApi.getSelectedRows();
    if (this.hasValue(selectedDataforObserverPersons)) {
      this.ObserversgridApi.updateRowData({
        remove: selectedDataforObserverPersons,
      });
    }
  }

  additionalParticipantAndObserversLoadEmployeesList(
    IsFromEmployeeDesignationType
  ) {
    if (IsFromEmployeeDesignationType == 1) {
      this.EmployeeDesignationType = 1;
    } else if (IsFromEmployeeDesignationType == 2) {
      this.EmployeeDesignationType = 2;
    } else if (IsFromEmployeeDesignationType == 3) {
      this.EmployeeDesignationType = 3;
    } else if (IsFromEmployeeDesignationType == 4) {
      this.EmployeeDesignationType = 4;
    } else if (IsFromEmployeeDesignationType == 5) {
      this.EmployeeDesignationType = 5;
    } else if (IsFromEmployeeDesignationType == 6) {
      this.EmployeeDesignationType = 6;
    } else if (IsFromEmployeeDesignationType == 7) {
      this.EmployeeDesignationType = 7;
    } else if (IsFromEmployeeDesignationType == 8) {
      this.EmployeeDesignationType = 8;
    } else {
      this.EmployeeDesignationType = undefined;
    }

    $(event.currentTarget)
      .parent()
      .parent()
      .find(".AdditionalParticipantsAndObserversRedColorClass")
      .removeClass("AdditionalParticipantsAndObserversRedColorClass");
    if (
      $(event.currentTarget).hasClass(
        "AdditionalParticipantsAndObserversRedColorClass"
      )
    ) {
      $(event.currentTarget).removeClass(
        "AdditionalParticipantsAndObserversRedColorClass"
      );
    } else {
      $(event.currentTarget).addClass(
        "AdditionalParticipantsAndObserversRedColorClass"
      );
    }
    this.practiceUsersList();
  }

  practiceUsersList() {
    const postData = {
      PracticeId: this.datatoPopup.PracticeId,
      EmployeeName: this.SearchEmployeeName,
    };

    this.taskService.PracticeUsersList(postData).subscribe((response) => {
      if (this.isError(response)) {
        return;
      }
      if (this.hasValue(response) && response.length > 0) {
        this.rowData = response;
      } else {
        this.rowData = [];
      }
    });
  }

  UpdateEmployeeInformationForMasterOrCommon() {
    alert("okay");
  }

  onSelectionChanged(event) {
    const selectedRows = this.additionalParticipantAndObserversgrid.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      //debugger;
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;
      this.SelectedResponsiblePersonName = selectedRow.EmployeeName;
      this.SelectedEmployeeID = selectedRow.EmployeeID;
    });
    // console.log(this.selectedRowsString);
  }

  additionalParticipantAndObserversCancelClickEvent() {
    this.dialog.close();
  }

  AdditionalResponsiblePersononGridReady(params) {
    this.AdditionalResponsiblePersonsgridApi = params.api;
    //this.gridColumnApi = params.columnApi;
  }

  ObserversGridReady(params) {
    this.ObserversgridApi = params.api;
    //this.gridColumnApi = params.columnApi;
  }

  additionalParticipantAndObserversAdditionalResponsiblePersonMove() {
    // var selectedpersonRows = [];
    // this.AdditionalResponsiblePersonsgridApi.forEachNode(node => selectedpersonRows.push(node.data));

    var selectedpersonRows = this.additionalParticipantAndObserversgrid.api.getSelectedRows();

    if (!this.hasValue(selectedpersonRows) || selectedpersonRows.length <= 0) {
      this.ShowErrorMessage("Please select Row to Move.");
      return;
    }
    let RecordFound = false;

    if (this.hasValue(selectedpersonRows)) {
      var rowData = [];
      this.AdditionalResponsiblePersonsgridApi.forEachNode((node) =>
        rowData.push(node.data)
      );
      //selectedpersonRows.forEach((selectedPerson, index) => {
      for (
        let selectedpersonElement = 0;
        selectedpersonElement < selectedpersonRows.length;
        selectedpersonElement++
      ) {
        for (
          let rowDataElement = 0;
          rowDataElement < rowData.length;
          rowDataElement++
        ) {
          // rowData.forEach((selectedRow, index) => {
          if (
            rowData[rowDataElement].EmployeeID ==
            selectedpersonRows[selectedpersonElement].EmployeeID
          ) {
            //this.ShowErrorMessage('Selected Record Already Exist.');
            RecordFound = true;
            break;
          }
        }
      }
      if (RecordFound == true) {
        this.ShowErrorMessage("Selected Record Already Exist.");
        return;
      }
      if (RecordFound == false) {
        selectedpersonRows.forEach((selectedPersontoMove, index) => {
          var newItem = selectedPersontoMove;
          this.AdditionalResponsiblePersonsgridApi.updateRowData({
            add: [newItem],
          });
        });
      }
    }

    this.additionalParticipantAndObserversgrid.api.deselectAll();
  }

  additionalParticipantAndObserversObserversMove() {
    var selectedpersonRows = this.additionalParticipantAndObserversgrid.api.getSelectedRows();

    if (!this.hasValue(selectedpersonRows)) {
      this.ShowErrorMessage("Please select Row to Move.");
      return;
    }
    let RecordFound = false;

    if (this.hasValue(selectedpersonRows)) {
      var rowData = [];
      this.ObserversgridApi.forEachNode((node) => rowData.push(node.data));
      for (
        let selectedpersonElement = 0;
        selectedpersonElement < selectedpersonRows.length;
        selectedpersonElement++
      ) {
        for (
          let rowDataElement = 0;
          rowDataElement < rowData.length;
          rowDataElement++
        ) {
          // rowData.forEach((selectedRow, index) => {
          if (
            rowData[rowDataElement].EmployeeID ==
            selectedpersonRows[selectedpersonElement].EmployeeID
          ) {
            //this.ShowErrorMessage('Selected Record Already Exist.');
            RecordFound = true;
            break;
          }
        }
      }
      if (RecordFound == true) {
        this.ShowErrorMessage("Selected Record Already Exist.");
        return;
      }
      if (RecordFound == false) {
        selectedpersonRows.forEach((selectedPersontoMove, index) => {
          var newItem = selectedPersontoMove;
          this.ObserversgridApi.updateRowData({ add: [newItem] });
        });
      }
    }

    this.additionalParticipantAndObserversgrid.api.deselectAll();
  }
  additionalParticipantAndObserversOKClickEvent() {
    var selectedAddlResponsiblepersonRows = [];
    this.AdditionalResponsiblePersonsgridApi.forEachNode((node) =>
      selectedAddlResponsiblepersonRows.push(node.data)
    );

    var selectedObserverspersonRows = [];
    this.ObserversgridApi.forEachNode((node) =>
      selectedObserverspersonRows.push(node.data)
    );

    // var selectedAssistantpersonRows = [];
    // this.AssistantsgridApi.forEachNode(node => selectedAssistantpersonRows.push(node.data));
    // //
    // const selectedAddlResponsiblepersonRows = this.additionalParticipantAndObserversAdditionalResponsiblePersongrid.api.getSelectedRows();
    //const selectedObserverspersonRows = this.additionalParticipantAndObserversObserversgrid.api.getSelectedRows();
    // const selectedAssistantpersonRows = this.additionalParticipantAndObserversAssistantsgrid.api.getSelectedRows();

    if (
      !this.hasValue(selectedAddlResponsiblepersonRows) &&
      !this.hasValue(selectedObserverspersonRows)
    ) {
      this.ShowErrorMessage("Please Select User(s).");
      return;
    }

    const DatatoParentWindow = {
      SelectedAddlResponsiblePersonsInfo: selectedAddlResponsiblepersonRows,
      SelectedObserversInfo: selectedObserverspersonRows,
      SelectedAssistantPersonsInfo: [],
    };
    setTimeout(() => {
      this.dialog.close(DatatoParentWindow);
    });
  }
}
