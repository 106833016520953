import { formatDate } from '@angular/common/src/common';
import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { IntlService } from '@progress/kendo-angular-intl';
import { commonhelper } from '../common/commonhelper';

@Component({
  selector: 'app-select-no-days',
  templateUrl: './select-no-days.component.html',
  styleUrls: ['./select-no-days.component.css']
})
export class SelectNoDaysComponent extends commonhelper implements OnInit {
  public defaultItem = {
    name: "-- Select No of Days --",
    id: null,
  };
  IsCommentItems = [{ name: "Internal Comments", id: 1 }, { name: "External Comments", id: 2 }];
  IsCommentAddedItems = [{ name: "Comments Added in", id: 1 }, { name: "Comments Not Added in", id: 2 }];
  public noOfDays: Array<any> = [{ name: "3 Days", id: 3 }, { name: "5 Days", id: 5 }, { name: "7 Days", id: 7 },
  { name: "10 Days", id: 10 }, { name: "15 Days", id: 15 }, { name: "Select Date Range", id: -1 }];
  FromDate: any = new Date();
  ToDate: any = new Date();
  IsComment: any = { id: null };
  IsCommentAdded: any = { id: null };
  noOfDaysFormField: any;
  constructor(private intl: IntlService, private readonly dialog: DialogRef,) {
    super();
  }

  ngOnInit() {
  }

  clearDates(number) {
    if (number === 1) {
      this.FromDate = "";
    } else if (number === 2) {
      this.ToDate = "";
    }
  }

  OKClickEvent() {

    console.log(this.IsComment, this.IsCommentAdded, this.noOfDaysFormField)
    if (this.IsComment == undefined || this.IsComment.id == null) {
      this.ShowErrorMessage("Please Check Internal/External Comments.");
    }
    else if (this.IsCommentAdded == undefined || this.IsCommentAdded.id == null) {
      this.ShowErrorMessage("Please Check Comments Added/ Not Added box.");
    }
    else if (this.noOfDaysFormField == undefined || this.noOfDaysFormField.id == null) {
      this.ShowErrorMessage("Please Check Select Last Days.");
    }

    if (this.hasValue(this.noOfDaysFormField) && this.noOfDaysFormField.id >= 1) {
      this.FromDate.setDate(this.FromDate.getDate() - this.noOfDaysFormField.id);
    }

    if (this.hasValue(this.IsComment.id)
      && this.hasValue(this.IsCommentAdded.id)
      && this.hasValue(this.noOfDaysFormField)
    ) {
      let popudata = {
        FromDate: this.intl.formatDate(this.FromDate, "MM/dd/yyyy"),
        ToDate: this.intl.formatDate(this.ToDate, "MM/dd/yyyy"),
        IsComment: this.IsComment.id,
        IsReplied: this.IsCommentAdded.id
      }
      this.dialog.close(popudata);
    }

    // if (this.hasValue(this.selectedRowsString)) {
    //   setTimeout(() => {
    //     this.dialog.close(this.selectedRowsString);
    //   });
    // }

  }

  CancelClickEvent() {
    this.dialog.close();
  }

  onclickCommentAddOrremove(event: any, item: any) {
    console.log(event.target.checked)
    if (event.target.checked)
      this.IsComment = item;
    else
      this.IsComment = null;
  }

}
