import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Config } from '../config';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@progress/kendo-angular-notification';
import { commonHelperService } from '../common/common.service';
import { commonhelper } from '../common/commonhelper';
import { DialogService, DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { PraticesListComponent } from '../pratices-list/pratices-list.component';
import { KendoButtonService } from '@progress/kendo-angular-buttons/dist/es2015/button/button.service';
import { TaskService } from '../services/task.service';
import { LeftNavService } from '../common/Leftnav.service';
import * as $ from 'jquery';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SelectEvent, FileInfo } from '@progress/kendo-angular-upload';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';

@Component({
  selector: 'app-edit-task-information-based-on-email-address',
  templateUrl: './edit-task-information-based-on-email-address.component.html',
  styleUrls: ['./edit-task-information-based-on-email-address.component.css']
})
export class EditTaskInformationBasedOnEmailAddressComponent extends commonhelper implements OnInit {
  FirstTimeLoad: boolean;
  private EMRDataFromPopupLocal: any;
  // set EMRDataFromPopup(data: any) {
  //   this.EMRDataFromPopupLocal = data;
  //   console.log('hello world');
  //   console.log(data);
  // }
  // get EMRDataFromPopup(): any { return this.EMRDataFromPopupLocal; }

  @ViewChild('EditTaskCustomztnEmailAddress') EditTaskCustomztnEmailAddress: ElementRef;
  private commonhelperobj: commonHelperService;
  public editmodedata: any = {};
  public TaskCustomizationModel: any = {};
  // this objects holds the employees selected as participants while creating the view
  public selectedParticpants;
  public responsiblePerson: Employee;
  public responsiblePersonInfo: any = {};
  private initialResponsiblePerson: any = {};
  // this objects holds the employees selected as observers while creating the view
  public selectedObservers;
  public PracticeID: any;
  public PracticeName: any;
  public supportgroup: any;
  public username: any;
  organizationEmployees: any;
  public editmodedatafromlist: any = {};
  public EmployeesAndRolesModelList: Array<any> = [];
  public selectedUsersList: Array<any> = [];
  public SelectedPracticeUserList: Array<any> = [];
  public divSelectedResponsiblePerson: HTMLElement;
  public UserEmailId: any;

  // tslint:disable-next-line:max-line-length
  constructor(httpClient: HttpClient, private notificationService: NotificationService, private dialogService: DialogService, private dialog: DialogRef, private taskService: TaskService, private LeftNav: LeftNavService) {
    super();
    // this.editmodedata = '';
    this.commonhelperobj = new commonHelperService(httpClient);
    // this.LeftNav.data.subscribe(value => {
    //   this.editmodedata = value;
    // });
    // alert(this.editmodedata);
  }


  ngOnInit() {
    this.LeftNav.currentSelected.subscribe(value => {
      this.editmodedata = value;
      this.FirstTimeLoad = true;
      this.GetEditModeDataList();
    });

    // this.GetEditModeDataList();
  }

  GetEditModeDataList() {
    const PostDataToService = {
      PracticeId: null,
      // IssueSupportGroupID: this.editmodedata.IssueSupportGroupID,
      OASPracticeEmailID: this.editmodedata.OASPracticeEmailID,
      EHREMailIDs: this.editmodedata.EHREMailIDs,
    };
    // const PostDataToService = {
    //   PracticeId: 999,
    //   IssueSupportGroupID: '2018090706023096',
    //   EHRUserID: 8,
    //   OASPracticeUserID: '2018101008515333',
    // };
    this.taskService.GetPracticeUsersEmailsData(PostDataToService).subscribe(response => {
      if (this.isError(response)) {
        return;
      }
      if (this.hasValue(response)) {
        this.editmodedatafromlist = response[0];
        // assigning practice name
        this.supportgroup = response[0].IssueSupportGroupName;
        this.UserEmailId = response[0].EHREMailIDs;
        // auto populate module dropdown
        // tslint:disable-next-line:max-line-length
        // tslint:disable-next-line:label-position
        // tslint:disable-next-line:max-line-length

        // this list contains all the empooyees related to this particluar task
        // Responsible Person, Observer, Participant
        const taskRelatedEmployees = response[0].EmailInfo;
        const { responsiblePerson, taskObservers, taskParticipants } = this.parseEmployeeRoles(taskRelatedEmployees);

        //debugger;
        this.selectedParticpants = taskParticipants;
        this.selectedObservers = taskObservers;
        this.responsiblePerson = this.initialResponsiblePerson = responsiblePerson;
        this.employeeList().subscribe(() => { });
      }


    });
  }
  parseEmployeeRoles(commaSeparatedEmployees: string) {

    const ROLE = 3;
    // declaration
    let responsiblePerson: Employee;
    const [taskObservers, taskParticipants]: [Employee[], Employee[]] = [[], []];

    // get all employee details as list
    const allEmployees = commaSeparatedEmployees.split(',');

    // group employees by roles
    for (const relatedEmployee of allEmployees) {
      const employeeDetails = relatedEmployee.split('|');
      if (employeeDetails[ROLE] === 'Responsible Person]') {
        responsiblePerson = this.parseEmployee(employeeDetails);
      } else if (employeeDetails[ROLE] === 'Observer]') {
        taskObservers.push(this.parseEmployee(employeeDetails));
      } else {
        taskParticipants.push(this.parseEmployee(employeeDetails));
      }
    }

    return {
      responsiblePerson,
      taskObservers,
      taskParticipants
    };
  }
  parseEmployee(employeeDetails: string[]): Employee {
    const EMPLOYEE_ID = 0;
    const EMPLOYEE_NAME = 1;

    return {
      EmployeeId: employeeDetails[EMPLOYEE_ID].replace('[', ''),
      EmployeeFullName: employeeDetails[EMPLOYEE_NAME]
    };
  }
  CancelClickEvent() {
    this.dialog.close();
  }

  employeeList(): Observable<Array<any>> {
    const PostDataToService = {};
    return this.commonhelperobj.PostData(Config.OASOrganizationAppnURL + 'OrganizationEmployeesList', PostDataToService)
      .pipe((map(response => this.checkForErrorAndAssingToEmployeeListAndReturn(response))));
  }
  public checkForErrorAndAssingToEmployeeListAndReturn(response) {
    // check for response error
    if (this.isError(response)) { return []; }
    // assign to respective list
    this.organizationEmployees = response.EmployeeList;
    // and return
    return this.organizationEmployees;
  }
  public dropdownclose(): void {
    if (this.FirstTimeLoad === true && !this.hasValue(this.responsiblePerson)) {
      this.responsiblePerson = this.initialResponsiblePerson;
      this.FirstTimeLoad = false;
    }
    //console.log(this.responsiblePerson);
  }

  // public responsiblePersonClose(): void {
  //   this.GetEditModeDataList();
  //   // this.employeeList().subscribe(() => { });
  // }

  UpdateEmailTaskCustomizationInfo() {

    // tslint:disable-next-line:no-unused-expression
    if (!this.hasValue(this.responsiblePerson)) {
      this.ShowErrorMessage('Please Select Responsible Person.');
      // tslint:disable-next-line:max-line-length
      const elementFound = this.EditTaskCustomztnEmailAddress.nativeElement.querySelector('[name="' + 'selectedResponsiblePersonName' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      // tslint:disable-next-line:max-line-length
      // tslint: disable - next - line: max - line - length
      return false;
    }

    if (this.hasValue(this.selectedParticpants) && this.selectedParticpants.length > 0) {
      this.selectedParticpants.forEach(element => {
        const EmployeesAndRolesModel = {
          EmployeeID: element.EmployeeId,
          RoleID: 4,
        };

        this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
      });
    }

    if (this.hasValue(this.selectedObservers) && this.selectedObservers.length > 0) {
      this.selectedObservers.forEach(element => {
        const EmployeesAndRolesModel = {
          EmployeeID: element.EmployeeId,
          RoleID: 3,
        };

        this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
      });
    }
    if (this.hasValue(this.responsiblePerson)) {
      const EmployeesAndRolesModel = {
        EmployeeID: this.responsiblePerson.EmployeeId,
        RoleID: 2,
      };
      this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);

    }

    if (this.EmployeesAndRolesModelList.length > 0) {
      this.TaskCustomizationModel.EmployeesAndRolesModelList = this.EmployeesAndRolesModelList;
    }
    if (this.hasValue(this.editmodedatafromlist.OASPracticeEmailID)) {
      this.TaskCustomizationModel.OASPracticeEmailID = this.editmodedatafromlist.OASPracticeEmailID;
    }
    if (this.hasValue(this.editmodedatafromlist.PracticeId)) {
      this.TaskCustomizationModel.PracticeId = null;
    }
    if (this.hasValue(this.editmodedatafromlist.IssueSupportGroupID)) {
      this.TaskCustomizationModel.IssueSupportGroupID = this.editmodedatafromlist.IssueSupportGroupID;
    }
    this.SelectedPracticeUserList = [];

    // if (this.hasValue(this.editmodedatafromlist.EHRUserID)) {
    //   const singleEmailAddressModel = {
    //     UserID: this.editmodedatafromlist.EHRUserID
    //   };
    //   this.SelectedPracticeUserList.push(singleEmailAddressModel);
    // }
    if (this.hasValue(this.editmodedatafromlist.EHREMailIDs)) {
      this.SelectedPracticeUserList.push(this.editmodedatafromlist.EHREMailIDs);
    }

    if (this.hasValue(this.SelectedPracticeUserList) && this.SelectedPracticeUserList.length > 0) {

      this.SelectedPracticeUserList.forEach(element => {
        const usersmodel = {
          EHREMailIDs: element,
        };

        this.selectedUsersList.push(usersmodel);
      });
    }

    if (this.hasValue(this.selectedUsersList) && this.selectedUsersList.length > 0) {
      this.TaskCustomizationModel.EmailsModelList = this.selectedUsersList;
    }


    // tslint:disable-next-line:max-line-length
    this.commonhelperobj.PostData(Config.OASAppnURL + 'InsertUsersEmailAddressCustomizationInformation', this.TaskCustomizationModel).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.ShowSuccesseMessage('Customization Details Updated Successfully.');
      this.TaskCustomizationModel = {};
      this.EmployeesAndRolesModelList = [];
      this.selectedObservers = '';
      this.selectedParticpants = undefined;
      this.selectedObservers = undefined;
      this.responsiblePerson = undefined;
      this.editmodedatafromlist = [];
      this.dialog.close('update');
      this.SelectedPracticeUserList = [];
    });
  }

  responsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Responsible Person',
      // Show component
      content: ResponsiblepersonUsersListComponent,
      height: 698,
      width: 550
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.responsiblePerson)) {

        } else {

        }
      } else {
        this.responsiblePersonInfo = result;
        if (this.hasValue(this.responsiblePersonInfo)) {
          this.responsiblePerson.EmployeeId = this.responsiblePersonInfo.EmployeeId;
          this.responsiblePerson.EmployeeFullName = this.responsiblePersonInfo.EmployeeFullName;

        }
      }
    });
  }

  RemoveResponsiblePersonInfo() {
    this.responsiblePerson.EmployeeId = undefined;
    this.responsiblePerson.EmployeeFullName = '';
  }
}


export interface Employee {
  EmployeeId: string;
  EmployeeFullName: string;
}

